<template>
  <CCard>
    <CCardBody>
      <CRow>
        <CCol class="mb-3"><h5>게시글 관리</h5></CCol>
      </CRow>
      <CRow>
        <CCol>
          <table class="table">
            <tbody>
            <tr>
              <th class="bg-light text-dark">검색</th>
              <td>
                <CSelect
                  class="col-2 float-left px-0 mb-0 mr-2"
                  :options="searchTypeOptions"
                  :value.sync="searchTypeSelected"
                  @change="onChangeSearchTypeOption"
                />
                <CSelect
                  class="col-2 float-left px-0 mb-0"
                  :options="searchOptions"
                  :value.sync="searchSelected"
                  @change="onChangeSearchOption"
                />
                <CInput
                  class="col-6 float-left mb-0"
                  placeholder="검색어를 입력해주세요."
                  v-model="externalParams.searchKeyword"
                  @input="onChangeSearchKeyword"
                  @keypress.enter.prevent="onClickSearch"
                />
                <CButton color="dark" class="text-white" @click="onClickSearch">
                  <CIcon name="cil-search" class="mr-2" />검색
                </CButton>
                <CButton
                  variant="outline"
                  color="secondary"
                  class="ml-2"
                  @click="onClickReset"
                >
                  <CIcon name="cil-filter-x" />
                </CButton>
              </td>
            </tr>
            <tr>
              <td colspan="2" class="border-bottom">
                  <span class="float-left mt-2 mr-3">
                    <strong class="mr-2">&#183; 등록일시</strong>
                  </span>
                <b-form-radio-group
                  inline
                  class="float-left mt-2"
                  v-model="searchDateSelected"
                  :options="searchDateOptions"
                  @change="onChangeSearchDate"
                />
                <CInput
                  type="date"
                  class="float-left mr-2 mb-0"
                  v-model="externalParams.keywordCreatedAtBgn"
                />
                <CInput
                  type="date"
                  class="float-left mb-0"
                  v-model="externalParams.keywordCreatedAtEnd"
                />
              </td>
            </tr>
            </tbody>
          </table>
        </CCol>
      </CRow>
      <CRow>
        <CCol class="col-auto mr-auto align-self-center">
          총&nbsp;<strong>{{ articleTableItems.totalElements }}</strong>건&nbsp;({{ currentPage }}&nbsp;/&nbsp;{{ articleTableItems.totalPages }}&nbsp;페이지)
        </CCol>
        <CCol class="col-auto">
          <CSelect
            class="float-left mr-2 mb-2"
            :options="pageOptions"
            :value.sync="perPage"
            @change="onPaging"
          />
          <CButton color="dark" class="text-white" @click="onClickRegister">
            <CIcon name="cil-library-add" class="mr-2" />신규등록
          </CButton>
        </CCol>
      </CRow>


      <b-table
        id="articleTable"
        thead-class="text-center"
        :busy="isBusy"
        :items="articleTableItems.content"
        :fields="articleTableFields"
        :items-per-page="perPage"
        show-empty
        empty-text="조회된 내역이 없습니다."
      >
        <!-- NO -->
        <template #cell(index)="data">
          {{ data.index + 1 + (currentPage - 1) * perPage }}
        </template>

        <!-- 등록일 -->
        <template #cell(createdAt)="data">
          {{ data.item.createdAt | moment("YYYY-MM-DD") }}
        </template>

        <!-- 작품명 -->
        <template #cell(title)="data">
          <a
            class="text-info"
            style="cursor: pointer;"
            @click="onClickArticleTitle(data.item.idx)"
          >
            {{ data.item.title }}
          </a>
        </template>

        <!-- Actions -->
        <template v-slot:cell(actions)="data">
          <CButton
            variant="outline"
            color="info"
            size="sm"
            class="mx-2"
            @click="onClickModify(data.item.idx)"
          >
            <CIcon name="cil-pencil" />
          </CButton>
        </template>
      </b-table>
      <b-pagination
        aria-controls="productTable"
        align="center"
        hide-ellipsis
        limit="10"
        v-model="currentPage"
        :total-rows="articleTableItems.totalElements"
        :per-page="perPage"
        :current-page="currentPage"
        v-on:input="onPaging"
      ></b-pagination>
    </CCardBody>
  </CCard>
</template>

<script>
  import { createNamespacedHelpers } from "vuex";
  const articleHelper = createNamespacedHelpers("article");

  export default {
    name: "ArticleList",
    components: {},
    data: function() {
      return {
        isBusy: false,
        perPage: 10,
        pageOptions: [
          { value: 10, label: "10 개씩 보기" },
          { value: 20, label: "20 개씩 보기" },
          { value: 30, label: "30 개씩 보기" },
          { value: 50, label: "50 개씩 보기" }
        ],
        currentPage: 1,
        sort: [
          { id: "createdAt", desc: "desc" },
          { id: "idx", desc: "desc" }
        ],
        sortBy: "",
        sortDesc: false,
        // 검색
        searchSelected: "keywordTitle",
        searchTypeSelected: "",
        searchOptions: [
          { value: "keywordTitle", label: "제목" }
        ],
        searchTypeOptions: [
          { value: "", label: "전체" },
          { value: "FeaturedArtist", label: "Featured artist" },
          { value: "WEB3", label: "Web 3.0" },
          { value: "NEWS", label: "News" },
          { value: "EVENT", label: "Event" },
        ],
        searchDateSelected: "",
        searchDateOptions: [
          { value: "", text: "전체" },
          { value: "LAST_1_WEEK", text: "최근 1주일" },
          { value: "LAST_1_MONTH", text: "최근 1개월" }
        ],
        externalParams: {
          searchType: "",
          searchKeyword: "",
          keywordArticleTitle: "",
          keywordCreatedAtBgn: "",
          keywordCreatedAtEnd: ""
        }
      };
    },
    computed: {
      ...articleHelper.mapState(["articleTableItems", "articleTableFields"])
    },
    mounted() {
      this.requestArticleItems();
    },
    methods: {
      ...articleHelper.mapActions(["getArticles"]),
      onPaging() {
        this.requestArticleItems();
      },
      onChangeSearchTypeOption() {
        this.externalParams.searchType = this.searchTypeSelected;
      },
      onChangeSearchOption() {
        this.setSearchConditions(this.externalParams.searchKeyword);
      },
      onChangeSearchKeyword(keyword) {
        this.setSearchConditions(keyword);
      },
      onChangeSearchDate(value) {
        this.$log.info("onChangeSearchDate() ", value);
        console.log(this.searchDateSelected)
        if (this.searchDateSelected === "") {
          this.externalParams.keywordCreatedAtBgn = "";
          this.externalParams.keywordCreatedAtEnd = "";
        } else if (this.searchDateSelected === "LAST_1_WEEK") {
          const today = new Date();
          this.externalParams.keywordCreatedAtBgn = this.$moment(today)
            .add(-7, "days")
            .format("YYYY-MM-DD");
          this.externalParams.keywordCreatedAtEnd = this.$moment(today).format(
            "YYYY-MM-DD"
          );
        } else if (this.searchDateSelected === "LAST_1_MONTH") {
          const today = new Date();
          this.externalParams.keywordCreatedAtBgn = this.$moment(today)
            .add(-1, "month")
            .format("YYYY-MM-DD");
          this.externalParams.keywordCreatedAtEnd = this.$moment(today).format(
            "YYYY-MM-DD"
          );
        }
      },
      setSearchConditions(keyword) {
        if (this.searchSelected === "keywordTitle") {
          this.externalParams.keywordArticleTitle = keyword;
        }
      },
      resetSearchConditions() {
        this.searchTypeSelected = "";
        this.externalParams.searchType = "";
        this.externalParams.searchKeyword = "";
        this.externalParams.keywordArticleTitle = "";
        this.externalParams.keywordCreatedAtBgn = "";
        this.externalParams.keywordCreatedAtEnd = "";
      },
      onClickSearch() {
        this.requestArticleItems();
      },
      onClickReset() {
        this.resetSearchConditions();
        this.requestArticleItems();
      },
      requestArticleItems() {
        this.$log.info(
          "requestArticleItems() this.externalParams=",
          this.externalParams
        );
        this.isBusy = true;
        this.getArticles(
          this.getPagingParams(
            {
              page: this.currentPage - 1,
              pageSize: this.perPage,
              sorted: this.sort,
              filtered: []
            },
            this.externalParams
          )
        )
          .then(data => {
            this.$log.log(data);
            this.isBusy = false;
          })
          .catch(error => {
            this.isBusy = false;
            this.$log.error(error);
          });
      },
      onClickRegister() {
        this.$log.info("onClickRegister");
        this.$router.push("/management/article-save/0");
      },
      onClickModify(idx) {
        this.$log.info("click onClickModify() idx:{}", idx);
        this.$router.push("/management/article-save/" + idx);
      },
      onClickArticleTitle(idx) {
        this.$log.info("click onClickArticleName() idx:{}", idx);
        this.$router.push("/management/article-detail/" + idx);
      },
      getPagingParams({ page, pageSize, sorted, filtered }, externalParams) {
        const params = {
          page,
          size: pageSize,
          ...externalParams
        };

        // sorting parameter
        let sorts = [];
        for (let i = 0, length = sorted.length; i < length; i++) {
          const sort = sorted[i];
          sorts.push(`${sort.id},${sort.desc ? "desc" : "asc"}`);
        }
        params["sort"] = sorts;

        // filtering parameter
        for (let i = 0, length = filtered.length; i < length; i++) {
          const filter = filtered[i];
          params[filter.id] = filter.value;
        }

        return params;
      }
    }
  };
</script>
