<template>
  <div>
    <b-modal
      id="payment-kaikas-modal-2"
      ref="payment-kaikas-modal-2"
      centered
      hide-header
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      body-class="text-black"
      @hide="closeModal"
    >
      <CRow>
        <CCol class="col-12">
          <div class="d-flex justify-content-end">
            <img
              class="cursor-pointer"
              src="/img/threespace/icon/close.png"
              alt="close"
              @click="$refs['payment-kaikas-modal-2'].hide()"
            />
          </div>
        </CCol>
      </CRow>

      <div class="w-100 d-flex justify-content-center align-items-center">
        <!-- <img
          src="/img/threespace/company/kaia-wallet.png"
          width="37"
          class="float-left mr-1"
        /> -->
        <!-- <h5 class="ml-2 mb-0">{{ $t("wallet.modal.kaikasTitle") }}</h5> -->
        <p
          style="
            color: #000;
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 6px;
          "
        >
          {{ $t("wallet.modal.kaikasTitle") }}
        </p>
      </div>
      <div class="w-100 d-flex justify-content-center py-3">
        카이아 트랜젝션 확인 후 결제를 진행해 주세요.
      </div>
      <div class="w-100 text-center">
        <p>
          <span class="text-black mr-2 font-weight-bold">{{
            $t("wallet.modal.remainTime")
          }}</span>
          <span class="text-danger font-weight-bold">{{ time }}</span>
        </p>
      </div>
    </b-modal>

    <PaymentKaikas3
      :payment-result-info="paymentResultInfo"
      ref="paymentKaikas3"
    />
  </div>
</template>

<script>
import Caver from "caver-js";
import { createNamespacedHelpers, mapActions, mapState } from "vuex";
const paymentHelper = createNamespacedHelpers("payment");

import PaymentKaikas3 from "@/views/threespace/payment/PaymentKaikas3";

export default {
  name: "PaymentKaikas2",
  components: {
    PaymentKaikas3,
  },
  props: {
    kaikasPaymentInfo: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      isTimeout: false,
      date: this.$moment(60 * 3 * 1000),
      paymentResultInfo: {},
      paymentType: "",
      resultStatus: "",
    };
  },
  mounted() {},
  computed: {
    ...mapState("gallery", ["paymentProductInfo"]),
    ...mapState("util", ["slackInfo"]),
    time: function () {
      return this.date.format("mm : ss");
    },
    isMobile() {
      return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    },
  },
  methods: {
    ...paymentHelper.mapActions([
      "completePaymentKlayPaycontract",
      "prepareTokenPayment",
      "updatePaymentKlay",
    ]),
    ...mapActions("util", ["sendSlack"]),
    showKaikasPayment() {
      this.prepareTokenPayment({
        payment: this.kaikasPaymentInfo.idxProduct,
        currency: "KLAY",
      })
        .then((receipt) => {
          this.$log.info("[prepareTokenPayment] receipt :", receipt);
          this.sendNext(receipt);
        })
        .catch((error) => {
          this.$log.error(error);
          alert(this.$t("payment.payError.fail"));
        });
    },
    sendNext(receipt) {
      if (receipt.buyable) {
        const caver = new Caver(window.klaytn);
        const data = caver.klay.abi.encodeFunctionCall(
          {
            name: "purchase",
            type: "function",
            inputs: [
              {
                internalType: "address",
                name: "contractAddr",
                type: "address",
              },
              {
                internalType: "uint256",
                name: "tokenId",
                type: "uint256",
              },
            ],
          },
          [receipt.nftContract, receipt.tokenId]
        );

        this.isTimeout = false;
        this.date = this.$moment(60 * 3 * 1000); // 3분
        clearInterval(this.totalTimer);
        // 결제 대기 타이머
        this.setTotalTimer();
        this.$refs["payment-kaikas-modal-2"].show();

        const result = caver.klay
          .sendTransaction({
            type: "SMART_CONTRACT_EXECUTION",
            from: receipt.buyerAddress,
            to: process.env.VUE_APP_KLAYTN_PAY_CONTRACT,
            value: receipt.amount,
            gas: 100000,
            data,
          })
          .on("error", (error) => {
            this.hideReserveCancel();
            this.$log.error(error);
            alert(this.$t("payment.payError.fail"));
          })
          .on("receipt", (receipt) => {
            if (receipt.status) {
              clearInterval(this.totalTimer);
              this.isTimeout = true;
              this.requestComplate(receipt);
            } else {
              this.hideReserveCancel();
              alert(this.$t("payment.payError.fail"));
            }
          });
      } else {
        let errorMessage =
          "현재 카이아 네트워크 상태가 원활하지 않습니다. 잠시 후 다시 시도해 주세요.";
        if (receipt.sold) {
          errorMessage = "이미 팔린 작품입니다.";
        } else if (!receipt.buyable) {
          errorMessage = "현재 구매할 수 없는 작품입니다";
        }
        this.hideReserveCancel();
        alert(errorMessage);
      }
    },
    setTotalTimer() {
      if (!this.isTimeout) {
        this.totalTimer = setInterval(() => {
          this.date = this.date.clone().subtract(1, "seconds");
          if (this.time === "00 : 00") {
            clearInterval(this.totalTimer);
            this.$log.log("timeout..."); //TODO
            this.hideReserveCancel();
          }
        }, 1000);
      }
    },
    requestComplate() {
      this.completePaymentKlayPaycontract(this.kaikasPaymentInfo)
        .then((data) => {
          //this.$log.log("[completePaymentKlayPaycontract] >> ", data);
          this.paymentResultInfo = data;
          this.$refs["paymentKaikas3"].show();
          this.$refs["payment-kaikas-modal-2"].hide();

          // 판매 법인 정보 삭제
          localStorage.removeItem(
            "referralCorpSeller_" + this.paymentResultInfo.idxProduct
          );
        })
        .catch((error) => {
          this.$log.error(error);
          this.$refs["payment-kaikas-modal-2"].hide();
          alert(this.$t("payment.payError.waiting"));
        });
    },
    show(paymentType) {
      this.isTimeout = false;
      this.paymentType = paymentType;
      this.showKaikasPayment();
    },
    hideReserveCancel() {
      const requestInfo = {
        idxProduct: this.kaikasPaymentInfo.idxProduct,
        idxOrderPayment: this.kaikasPaymentInfo.idxOrderPayment,
        status: "RESERVE_CANCEL",
      };
      // 작품 상태(예약취소, 판매중) 업데이트
      this.updatePaymentKlay(requestInfo)
        .then((data) => {
          this.$refs["payment-kaikas-modal-2"].hide();
        })
        .catch((error) => {
          this.$log.error(error);
          this.$refs["payment-kaikas-modal-2"].hide();
        });
    },
    hideConfirm() {
      if (confirm(this.$t("payment.payCancelMsg"))) {
        this.hideReserveCancel();
      }
    },
    closeModal() {
      clearInterval(this.totalTimer);
    },
  },
  destroyed() {
    clearInterval(this.totalTimer);
  },
};
</script>
