<template>
  <div>
    <template v-if="idxArtpool < 3 && idxArtpool >=1">
      <NftPoolStakeV1 :idxArtpool="idxArtpool" />
    </template>
    <template v-else-if="idxArtpool >= 3">
      <NftPoolStakeV2 :idxArtpool="idxArtpool" />
    </template>
<!--    <template v-if="version === 1">-->
<!--      <NftPoolStakeV1 :idxArtpool="idxArtpool" />-->
<!--    </template>-->
<!--    <template v-else-if="version === 2">-->
<!--      <NftPoolStakeV2 :idxArtpool="idxArtpool" />-->
<!--    </template>-->
<!--    <template v-else-if="version === 0">-->
<!--      &lt;!&ndash; 잘못된 요청 &ndash;&gt;-->
<!--    </template>-->
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import NftPoolStakeV1 from "@/views/threespace/artpool/NftPoolStakeV1.vue";
import NftPoolStakeV2 from "@/views/threespace/artpool/NftPoolStakeV2.vue";

export default {
  name: "NftPoolStake",
  props: {
    idxArtpool: {
      type: Number,
      default: 0
    }
  },
  components: {
    NftPoolStakeV1,
    NftPoolStakeV2,
  },
  metaInfo() {
    return {
      title: this.$t("meta.artPool.title") + " | 3space Art",
      meta: [
        { vmid: "title", name: "title", content: this.$t("meta.artPool.title") + " | 3space Art" },
        { vmid: "description", name: "description", content: this.$t("meta.artPool.description") },
        { vmid: "og:title", property: "og:title", content: this.$t("meta.artPool.title") + " | 3space Art" },
        { vmid: "og:description", property: "og:description", content: this.$t("meta.artPool.description") },
        { vmid: "og:url", property: "og:url", content: window.location.origin + this.$route.path }
      ],
    }
  },
  data: function () {
    return {
      version: 0
    }
  },
  created() {
    this.getArtpoolSimple(this.idxArtpool).then(() => {
      let versionObj = this.artpoolVersionOptions.find(el => el.roundNo === this.artpoolSimpleInfo.roundNo)
      this.version = versionObj.version;
    });
  },
  computed: {
    ...mapState("artpool",["artpoolVersionOptions", "artpoolSimpleInfo"]),
  },
  methods: {
    ...mapActions("artpool",["getArtpoolSimple"]),
  },
}
</script>
