<template>
  <CSidebar
    :minimize="minimize"
    unfoldable
    :show="show"
    @update:show="value => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand class="d-md-down-none" to="/">
      <span class="c-sidebar-brand-full">
        <CImg
          class="c-sidebar-brand-full mr-2"
          alt="logo"
          size="custom-size"
          :height="35"
          viewBox="0 0 642 134"
          src="/img/threespace/logo/logo_white_thin.png"
        />
        <span><b>3space Admin</b></span>
      </span>
      <CImg
        class="c-sidebar-brand-minimized"
        alt="logo"
        size="custom-size"
        :height="35"
        viewBox="0 0 110 134"
        src="/img/threespace/logo/logo_white_thin.png"
      />
    </CSidebarBrand>
    <CRenderFunction flat :contentToRender="isAdminRole ? sidebarItemsAdmin : sidebarItemsCorpAdmin" />
    <CSidebarMinimizer
      class="c-d-md-down-none"
      @click.native="$store.commit('toggle', 'sidebarMinimize')"
    />
  </CSidebar>
</template>

<script>
import SidebarItems from "@/containers/SidebarItems";
import {mapState} from "vuex";

export default {
  name: "Sidebar",
  extends: SidebarItems,
  computed: {
    ...mapState({
      me: state => {
        return state.auth.me || {};
      }
    }),
    show() {
      return this.$store.state.sidebarShow;
    },
    minimize() {
      return this.$store.state.sidebarMinimize;
    },
    isAdminRole() {
      if (
        this.me.roles !== undefined &&
        this.me.roles.length > 0 &&
        (this.me.roles.includes("ROLE_SUPERVISOR") || this.me.roles.includes("ROLE_OPERATOR"))
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>
