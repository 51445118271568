<template>
  <div>
    <template v-if="version === 1">
      <NftPoolVoteV1 :idxArtpool="idxArtpool" />
    </template>
    <template v-else-if="version === 2">
      <NftPoolVoteV2 :idxArtpool="idxArtpool" />
    </template>
    <template v-else-if="version === 0">
      <!-- 잘못된 요청 -->
    </template>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import NftPoolVoteV1 from "@/views/threespace/artpool/NftPoolVoteV1.vue";
import NftPoolVoteV2 from "@/views/threespace/artpool/NftPoolVoteV2.vue";

export default {
  name: "NftPoolVote",
  props: {
    idxArtpool: {
      type: Number,
      default: 0
    }
  },
  components: {
    NftPoolVoteV1,
    NftPoolVoteV2,
  },
  metaInfo() {
    return {
      title: this.$t("meta.artPool.title") + " | 3space Art",
      meta: [
        { vmid: "title", name: "title", content: this.$t("meta.artPool.title") + " | 3space Art" },
        { vmid: "description", name: "description", content: this.$t("meta.artPool.description") },
        { vmid: "og:title", property: "og:title", content: this.$t("meta.artPool.title") + " | 3space Art" },
        { vmid: "og:description", property: "og:description", content: this.$t("meta.artPool.description") },
        { vmid: "og:url", property: "og:url", content: window.location.origin + this.$route.path }
      ],
    }
  },
  data: function () {
    return {
      version: 0
    }
  },
  created() {
    this.getArtpoolSimple(this.idxArtpool).then(() => {
      // let versionObj = this.artpoolVersionOptions.find(el => el.roundNo === this.artpoolSimpleInfo.roundNo)
      // this.version = versionObj.version;
      this.version = this.idxArtpool >= 3 ? 2 : 1
    });
  },
  computed: {
    ...mapState("artpool",["artpoolVersionOptions", "artpoolSimpleInfo"]),
  },
  methods: {
    ...mapActions("artpool",["getArtpoolSimple"]),
  },
}
</script>
