<script src="../../../store/management.member.js"></script>
<template>
  <CCard>
    <CCardBody>
      <CRow>
        <CCol class="mb-3"><h5>작가 등록/수정</h5></CCol>
      </CRow>
      <CForm>
        <CRow>
          <CCol col="8">
            <b-table-simple>
              <colgroup>
                <col style="width:20%;" />
                <col style="width:80%;" />
              </colgroup>
              <b-tbody>
                <b-tr v-if="isAdminRole">
                  <b-th variant="secondary">작가(그룹)</b-th>
                  <b-td>
                    <b-form-radio-group
                      id="artistGroup"
                      name="artistGroup"
                      :options="artistGroupOptions"
                      v-model="artistInfo.artistGroup"
                      :disabled="idxArtistGroup !== '0' ? true : false"
                    />
                  </b-td>
                </b-tr>
                <b-tr v-if="isAdminRole">
                  <b-th variant="secondary">작가(그룹) 번호</b-th>
                  <b-td>{{ artistInfo.idx || "(정보없음)" }}</b-td>
                </b-tr>
                <b-tr v-if="isAdminRole && artistInfo.artistGroup === 'false'">
                  <b-th variant="secondary">멤버 회원번호</b-th>
                  <b-td>
                    {{ artistInfo.idxMember || "(정보없음)" }}
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-th variant="secondary">
                    작가명<template v-if="artistInfo.artistGroup === 'true'">(그룹명)</template>
                  </b-th>
                  <b-td>
                    <div class="row align-items-center">
                      <div class="col-lg-1 col-md-1 pr-0">
                        영문&nbsp;<span class="text-danger font-lg">*</span>
                      </div>
                      <div class="col-11">
                        <CInput
                          class="mb-0"
                          placeholder="작가명(영문)을 입력하세요."
                          :lazy="false"
                          :value.sync="$v.artistInfo.artistNameEn.$model"
                          :isValid="checkIfValid('artistNameEn')"
                          invalidFeedback="필수 정보입니다."
                        />
                      </div>
                    </div>
                    <div class="row align-items-center mt-1">
                      <div class="col-lg-1 col-md-1 pr-0">
                        한글
                      </div>
                      <div class="col-11">
                        <CInput
                          class="mb-0"
                          placeholder="작가명을 입력하세요."
                          v-model="artistInfo.artistName"
                        />
                      </div>
                    </div>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-th variant="secondary">
                    사용자이름&nbsp;<span class="text-danger font-lg">*</span><br/>(닉네임)
                  </b-th>
                  <b-td>
                    <CInput
                      class="mb-0"
                      placeholder="@사용자 이름"
                      :lazy="false"
                      :value.sync="$v.artistInfo.nickname.$model"
                      :isValid="checkIfValid('nickname')"
                      invalidFeedback="필수 정보입니다."
                    />
                  </b-td>
                </b-tr>
                <b-tr v-if="artistInfo.artistGroup === 'false'">
                  <b-th variant="secondary">이름&nbsp;<span class="text-danger font-lg">*</span></b-th>
                  <b-td>
                    <CInput
                      class="mb-0"
                      placeholder="이름을 입력하세요."
                      :lazy="false"
                      :value.sync="$v.artistInfo.name.$model"
                      :isValid="checkIfValid('name')"
                      invalidFeedback="필수 정보입니다."
                    />
                  </b-td>
                </b-tr>
                <b-tr v-if="artistInfo.artistGroup === 'false'">
                  <b-th variant="secondary">연락처&nbsp;<span class="text-danger font-lg">*</span></b-th>
                  <b-td>
                    <CInput
                      class="mb-0"
                      placeholder="'-' 없이 숫자만 입력"
                      :lazy="false"
                      :value.sync="$v.artistInfo.mobile.$model"
                      :isValid="checkIfValid('mobile')"
                      invalidFeedback="필수 정보입니다."
                    />
                  </b-td>
                </b-tr>
                <b-tr v-if="artistInfo.artistGroup === 'false'">
                  <b-th variant="secondary">이메일계정&nbsp;<span class="text-danger font-lg">*</span></b-th>
                  <b-td>
                    <CInput
                      class="mb-0"
                      placeholder="이메일계정을 입력하세요."
                      :lazy="false"
                      :value.sync="$v.artistInfo.email.$model"
                      :isValid="checkIfValid('email')"
                      invalidFeedback="필수 정보입니다."
                    />
                  </b-td>
                </b-tr>
                <b-tr v-if="artistInfo.artistGroup === 'false'">
                  <b-th variant="secondary">비밀번호</b-th>
                  <b-td>
                    <!--
                    <CInput
                      :lazy="false"
                      :value.sync="$v.artistInfo.password.$model"
                      :isValid="checkIfValid('password')"
                      placeholder="비밀번호(필요시 설정, 최소 8자 이상)"
                      invalidFeedback="비밀번호(최소 8자 이상)"
                      class="mb-0"
                    />
                    <CInput
                      :lazy="false"
                      :value.sync="$v.artistInfo.confirmPassword.$model"
                      :isValid="checkIfValid('confirmPassword')"
                      placeholder="비밀번호 확인(필요시 설정, 최소 8자 이상)"
                      invalidFeedback="비밀번호와 동일하게 입력해주세요."
                      class="mb-0 mt-1"
                    />
                    -->
                    <CInput
                      placeholder="비밀번호(필요시 설정, 최소 8자 이상)"
                      v-model="artistInfo.password"
                      class="mb-0"
                      :class="{ 'is-invalid': validation.invalidPassword }"
                    />
                    <div class="invalid-feedback">비밀번호(최소 8자 이상), 비밀번호 확인을 한번더 입력하세요.</div>
                    <CInput
                      placeholder="비밀번호 확인(필요시 설정, 최소 8자 이상)"
                      v-model="artistInfo.confirmPassword"
                      class="mb-0 mt-1"
                      :class="{ 'is-invalid': validation.invalidConfirmPassword }"
                    />
                    <div class="invalid-feedback">비밀번호와 동일하게 입력하세요.</div>
                    <div class="mt-1">
                      <CIcon name="cil-info" class="text-info mr-1" />
                      <span class="text-info">작가 신규등록 & 비밀번호 미입력시 디폴드값 설정( 3Spaceart! )</span>
                    </div>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-th variant="secondary">작가 소개</b-th>
                  <b-td>
                    <div class="row align-items-center">
                      <div class="col-lg-1 col-md-1 pr-0">
                        영문&nbsp;<span class="text-danger font-lg">*</span>
                      </div>
                      <div class="col-11">
                        <CTextarea
                          class="mb-0"
                          placeholder="작가 소개(영문)를 입력하세요."
                          rows="4"
                          :lazy="false"
                          :value.sync="$v.artistInfo.introduceEn.$model"
                          :isValid="checkIfValid('introduceEn')"
                          invalidFeedback="필수 정보입니다."
                        />
                      </div>
                    </div>
                    <div class="row align-items-center mt-1">
                      <div class="col-lg-1 col-md-1 pr-0">
                        한글
                      </div>
                      <div class="col-11">
                        <CTextarea
                          class="mb-0"
                          placeholder="작가 소개(약력)를 입력하세요."
                          rows="4"
                          v-model="artistInfo.introduce"
                        />
                      </div>
                    </div>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-th variant="secondary">작가 이력</b-th>
                  <b-td>
                    <div class="row align-items-center">
                      <div class="col-lg-1 col-md-1 pr-0">
                        영문
                      </div>
                      <div class="col-11">
                        <CTextarea
                          class="mb-0"
                          placeholder="작가 이력(영문)를 입력하세요."
                          rows="4"
                          v-model="artistInfo.careerEn"
                        />
                      </div>
                    </div>
                    <div class="row align-items-center mt-1">
                      <div class="col-lg-1 col-md-1 pr-0">
                        한글
                      </div>
                      <div class="col-11">
                        <CTextarea
                          class="mb-0"
                          placeholder="작가 이력을 입력하세요."
                          rows="4"
                          v-model="artistInfo.career"
                        />
                      </div>
                    </div>
                  </b-td>
                </b-tr>
                <b-tr v-if="artistInfo.artistGroup === 'false'">
                  <b-th variant="secondary">위치</b-th>
                  <b-td>
                    <CInput
                      class="mb-0"
                      placeholder="Seoul, South Korea"
                      v-model="artistInfo.location"
                    />
                  </b-td>
                </b-tr>
                <b-tr v-if="artistInfo.artistGroup === 'false'">
                  <b-th variant="secondary">SNS</b-th>
                  <b-td>
                    <div class="d-flex align-items-center">
                      <CButton
                        name="instagram"
                        color="instagram"
                        size="sm"
                        :disabled="true"
                        class="cursor-default"
                      >
                        <CIcon name="cib-instagram" />
                      </CButton>
                      <span class="pl-2">https://intagram.com/</span>
                      <CInput
                        class="mb-0 w-100"
                        placeholder="인스타그램 계정"
                        v-model="artistInfo.instagram"
                      />
                    </div>
                    <div class="d-flex align-items-center mt-1">
                      <CButton
                        name="twitter"
                        color="twitter"
                        size="sm"
                        :disabled="true"
                        class="cursor-default"
                      >
                        <CIcon name="cib-twitter" />
                      </CButton>
                      <span class="pl-2">https://twitter.com/</span>
                      <CInput
                        class="mb-0 w-100"
                        placeholder="트위터 계정"
                        v-model="artistInfo.twitter"
                      />
                    </div>
                    <div class="d-flex align-items-center mt-1">
                      <CButton
                        name="homepage"
                        color="secondary"
                        size="sm"
                        :disabled="true"
                        class="cursor-default"
                      >
                        <CIcon name="cil-home" />
                      </CButton>
                      <CInput
                        class="mb-0 w-100 pl-2"
                        placeholder="웹사이트(개인 홈페이지)"
                        v-model="artistInfo.homepage"
                      />
                    </div>
                  </b-td>
                </b-tr>
                <b-tr v-if="artistInfo.artistGroup === 'false'">
                  <b-th variant="info">소속 법인</b-th>
                  <b-td>
                    <div class="d-flex">
                      <CInput placeholder="법인 선택" class="mb-0 w-75" :readonly="true" v-model="artistInfo.companyName" />
                      <template v-if="isAdminRole">
                        <CButton color="primary" class="ml-2 text-white" @click="onClickCompanySelect">
                          <CIcon name="cil-magnifying-glass"/>
                        </CButton>
                        <CButton color="secondary" class="ml-2" @click="onClickCompanyDelete" v-if="selectedCompany">
                          <CIcon name="cil-trash"/>
                        </CButton>
                      </template>
                    </div>
                    <div class="mt-1">
                      <CIcon name="cil-info" class="text-info mr-1" />
                      <span class="text-info">
                      <template v-if="isAdminRole">
                      소속 법인을 선택한 경우, 하위 항목은 법인에 등록된 정보로 설정됩니다.
                      </template>
                      <template v-else>
                      하위 항목은 소속 법인에 등록된 정보로 자동 설정됩니다. 변경이 필요한 경우 세번째공간 관리자에게 문의하세요.
                      </template>
                    </span>
                    </div>
                  </b-td>
                </b-tr>
                <b-tr v-if="artistInfo.artistGroup === 'false'">
                  <b-th variant="info">정산 은행계좌</b-th>
                  <b-td>
                    <CSelect
                      class="col-3 float-left px-0 mb-0"
                      :options="bankCodeOptions"
                      :value.sync="artistInfo.bankCode"
                      :disabled="selectedCompany || !isAdminRole"
                    />
                    <CInput
                      class="col-9 float-left pr-0 mb-0"
                      placeholder="정산 받으실 은행 계좌를 입력하세요."
                      v-model="artistInfo.bankAccount"
                      :readonly="selectedCompany || !isAdminRole"
                    />
                  </b-td>
                </b-tr>
                <b-tr v-if="artistInfo.artistGroup === 'false'">
                  <b-th variant="info">정산 카이아 지갑주소</b-th>
                  <b-td>
                    <CInput
                      class="mb-0"
                      placeholder="카이아 지갑주소를 입력하세요."
                      v-model="artistInfo.klaytnAddr"
                      :readonly="selectedCompany || !isAdminRole"
                    />
                  </b-td>
                </b-tr>
                <b-tr v-if="artistInfo.artistGroup === 'false'">
                  <b-th variant="info">정산 이더리움 지갑주소</b-th>
                  <b-td>
                    <CInput
                      class="mb-0"
                      placeholder="이더리움 지갑주소를 입력하세요."
                      v-model="artistInfo.ethereumAddr"
                      :readonly="selectedCompany || !isAdminRole"
                    />
                  </b-td>
                </b-tr>
                <b-tr v-if="artistInfo.artistGroup === 'false'">
                  <b-th variant="info">정산 폴리곤 지갑주소</b-th>
                  <b-td>
                    <CInput
                      class="mb-0"
                      placeholder="폴리곤 지갑주소를 입력하세요."
                      v-model="artistInfo.polygonAddr"
                      :readonly="selectedCompany || !isAdminRole"
                    />
                  </b-td>
                </b-tr>
                <b-tr v-if="artistInfo.artistGroup === 'false'">
                  <b-th variant="info">정산 BTC 지갑주소</b-th>
                  <b-td>
                    <CInput
                      class="mb-0"
                      placeholder="BTC 지갑주소를 입력하세요."
                      v-model="artistInfo.btcAddr"
                      :readonly="selectedCompany || !isAdminRole"
                    />
                  </b-td>
                </b-tr>
                <b-tr v-if="artistInfo.artistGroup === 'false'">
                  <b-th variant="info" class="border-bottom">
                    판매수수료 비율<br/>(총 100%)
                  </b-th>
                  <b-td class="border-bottom">
                    <CRow>
                      <CCol col="3" class="pr-0">
                        <CInput
                          label="작가"
                          type="number"
                          class="mb-0"
                          placeholder="90"
                          v-model="artistInfo.salesFeeRatioArtist"
                          :readonly="selectedCompany || !isAdminRole"
                        />
                      </CCol>
                      <CCol col="3" class="pr-0">
                        <CInput
                          label="세번째공간"
                          type="number"
                          class="mb-0"
                          placeholder="10"
                          v-model="artistInfo.salesFeeRatioThreespace"
                          :readonly="selectedCompany || !isAdminRole"
                        />
                      </CCol>
                    </CRow>
                  </b-td>
                </b-tr>
                <b-tr v-if="artistInfo.artistGroup === 'true'">
                  <b-th class="border-bottom">작가(그룹) 멤버</b-th>
                  <b-td class="border-bottom">
                    <CButton color="light" @click="onClickArtistMemberSelect">
                      <CIcon name="cil-plus" class="mr-2" />멤버추가
                    </CButton>
                    <table class="table mt-2">
                      <thead>
                        <b-tr>
                          <b-th class="text-center bg-gray-100 text-dark py-2">
                            회원번호
                          </b-th>
                          <b-th class="text-center bg-gray-100 text-dark py-2">
                            작가명
                          </b-th>
                          <b-th class="text-center bg-gray-100 text-dark py-2">
                            작가 사용자이름(닉네임)
                          </b-th>
                          <b-th class="bg-gray-100"></b-th>
                        </b-tr>
                      </thead>
                      <tbody>
                        <template v-for="(item, index) in artistInfo.childArtists">
                          <b-tr :key="'item' + index">
                            <b-td class="py-0">{{ item.idxMember }}</b-td>
                            <b-td class="py-0">{{ item.artistName }}</b-td>
                            <b-td class="py-0">{{ item.nickname }}</b-td>
                            <b-td class="py-0">
                              <CButton
                                variant="ghost"
                                color="right"
                                @click="onClickArtistMemberDelete(item.idxMember)"
                              >
                                <CIcon name="cil-x" />
                              </CButton>
                            </b-td>
                          </b-tr>
                        </template>
                      </tbody>
                    </table>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </CCol>
          <CCol col="4">
            <div>
              <strong>프로필 이미지 <span class="text-info font-lg">*</span></strong>
              <b-form-file
                v-model="profileImage"
                placeholder="프로필 이미지를 선택하세요."
                accept=".jpg, .jpeg, .png"
                class="mt-2"
                @change="onClickProfileImage"
                :disabled="isBusy"
              ></b-form-file>
              <div class="mt-2">
                <span class="text-success">{{ artistInfo.profileImage || '(정보없음)' }}</span>
                <div v-if="artistInfo.profileImage !== undefined">
                  <CImg
                    height="100px"
                    :src="artistInfo.profileImageUrl"
                    class="mt-1"
                  />
                </div>
              </div>
              <div class="mt-2 text-gray">
                &#183; 가로X세로 200px, 50KB 정도, 파일형식: jpg, jpeg, png
              </div>
            </div>
            <div class="mt-3">
              <strong>배경(배너) 이미지 <span class="text-info font-lg">*</span></strong>
              <b-form-file
                v-model="bannerImage"
                placeholder="배경(배너) 이미지를 선택하세요."
                accept=".jpg, .jpeg, .png"
                class="mt-2"
                @change="onClickBannerImage"
                :disabled="isBusy"
              ></b-form-file>
              <div class="mt-2">
                <span class="text-success">{{ artistInfo.bannerImage || '(정보없음)' }}</span>
                <div v-if="artistInfo.bannerImage !== undefined">
                  <CImg
                    height="100px"
                    :src="artistInfo.bannerImageUrl"
                    class="mt-1"
                  />
                </div>
              </div>
            </div>
            <div class="mt-3">
              <strong>클립 그룹아이콘 <span class="text-info font-lg">*</span></strong>
              <b-form-file
                v-model="groupIcon"
                placeholder="클립 그룹아이콘을 선택하세요."
                accept=".jpg, .jpeg, .png"
                class="mt-2"
                @change="onClickGroupIcon"
                :disabled="isBusy"
              ></b-form-file>
              <div class="mt-2">
                <span class="text-success">{{ artistInfo.groupIcon || '(정보없음)' }}</span>
                <div v-if="artistInfo.groupIcon !== undefined">
                  <CImg
                    height="100px"
                    :src="artistInfo.groupIcon"
                    class="mt-1"
                  />
                </div>
                <div class="mt-2 text-info">
                  &#183; 선택한 파일은 클립 AWS 서버에 저장됩니다.<br/>
                  &#183; 클립 NFT 발행시 그룹아이콘(대표이미지)으로 사용됩니다.<br/>
                  &#183; 가로X세로 1024px 이상, 5MB 이하, 파일형식: jpg, jpeg, png
                </div>
              </div>
            </div>
            <div class="mt-3" v-if="artistInfo.artistGroup === 'false'">
              <strong>신분증 이미지</strong>
              <b-form-file
                v-model="idCard"
                placeholder="신분증 이미지를 선택하세요."
                accept=".jpg, .jpeg, .png, .gif"
                class="mt-2"
                @change="onClickIdCardImage"
                :disabled="isBusy"
              ></b-form-file>
              <div class="mt-2 text-success">{{ artistInfo.idCard || '(정보없음)' }}</div>
            </div>
            <div class="progress-group mt-5">
              <div class="progress-group-header">
                <CIcon name="cil-brush-alt" class="progress-group-icon"/>
                <span class="title font-weight-bold">ARTISTS 화면 설정</span>
              </div>
              <div class="progress-group-bars">
                <CProgress
                  class="progress-xs"
                  :value="100"
                  color="warning"
                />
              </div>
            </div>
            <div class="mt-3">
              <strong>ARTISTS 노출</strong>
              <b-form-checkbox
                id="display"
                v-model="artistInfo.display"
                name="display"
                value="true"
                unchecked-value="false"
                class="mt-2"
              >
                ARTISTS 화면에 노출
              </b-form-checkbox>
            </div>
            <div class="mt-3">
              <strong>전문 분야</strong>
              <b-form-group class="mb-0 mt-1">
                <b-form-checkbox-group
                    v-model="artistInfo.skills"
                    :options="commonCodes['ARTIST_SKILL']"
                ></b-form-checkbox-group>
              </b-form-group>
            </div>
            <div class="mt-3">
              <strong>작품 대표이미지</strong>
              <b-form-file
                v-model="artworkImage"
                placeholder="작품 대표 이미지를 선택하세요."
                accept=".jpg, .jpeg, .png"
                class="mt-2"
                @change="onClickArtworkImage"
                :disabled="isBusy"
              ></b-form-file>
              <div class="mt-2">
                <span class="text-success">{{ artistInfo.artworkImage || '(정보없음)' }}</span>
                <div v-if="artistInfo.artworkImage !== undefined">
                  <CImg
                    height="100px"
                    :src="artistInfo.artworkImageUrl"
                    class="mt-1"
                  />
                </div>
              </div>
              <div class="mt-2 text-gray">
                &#183; 가로X세로 512px, 1MB 정도, 파일형식: jpg, jpeg, png
              </div>
            </div>
            <div class="mt-3">
              <strong>Trending Artist</strong>
              <b-form-checkbox
                id="trending"
                v-model="artistInfo.trending"
                name="trending"
                value="true"
                unchecked-value="false"
                class="mt-2"
              >
                Trending Artists 여부
              </b-form-checkbox>
            </div>
          </CCol>
        </CRow>
        <CRow>
          <CCol col="12" class="text-center my-4">
            <CButton color="light" @click="onClickCancel()" class="mr-2">
              <CIcon name="cil-x-circle" class="mr-2" />취소
            </CButton>
            <CButton color="secondary" @click="onClickDelete()" class="mr-2" :disabled="isBusy" v-if="idxArtist !== '0'">
              <CIcon name="cil-trash" class="mr-2" />삭제
            </CButton>
            <CButton color="dark" @click="onClickSave()" class="text-white" :disabled="isBusy">
              <CIcon name="cil-save" class="mr-2" />저장
            </CButton>
          </CCol>
        </CRow>
      </CForm>

      <ArtistSelectPopup
        ref="artistSelectPopup"
        @artist-selected="selectedArtistMemberInfo"
        v-if="isAdminRole"
      />
      <CompanySelectPopup
        ref="companySelectPopup"
        @company-selected="selectedCompanyInfo"
        v-if="isAdminRole"
      />
    </CCardBody>
  </CCard>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import { createNamespacedHelpers } from "vuex";
const authHelper = createNamespacedHelpers("auth");
const companyHelper = createNamespacedHelpers("company");
const memberHelper = createNamespacedHelpers("member");
const codeHelper = createNamespacedHelpers("code");

import ArtistSelectPopup from "@/views/management/artist/ArtistSelectPopup";
import CompanySelectPopup from "@/views/management/company/CompanySelectPopup";

export default {
  name: "ArtistSave",
  components: {
    ArtistSelectPopup,
    CompanySelectPopup
  },
  props: {
    idxArtist: {
      type: String,
      default: "0"
    }
  },
  data: function() {
    return {
      isBusy: true,
      artistInfo: this.getInitArtistInfo(),
      profileImage: null,
      bannerImage: null,
      idCard: null,
      groupIcon: null,
      artworkImage: null,
      artistGroupOptions: [
        { value: "false", text: "개인" },
        { value: "true", text: "그룹" }
      ],
      // TODO
      bankCodeOptions: [
        { value: "", label: "선택" },
        { value: "003", label: "기업은행" },
        { value: "004", label: "국민은행" },
        { value: "020", label: "우리은행" },
        { value: "081", label: "하나은행" },
        { value: "088", label: "신한은행" },
        { value: "027", label: "씨티은행" },
        { value: "011", label: "NH농협은행" },
        { value: "090", label: "카카오뱅크" },
        { value: "092", label: "토스뱅크" },
      ],
      selectedCompany: false,
      validation: {
        invalidPassword: false,
        invalidConfirmPassword: false
      }
    };
  },
  computed: {
    ...authHelper.mapState(["me"]),
    ...companyHelper.mapState(["myCompanyInfo"]),
    ...codeHelper.mapState(["commonCodes"]),
    isAdminRole() {
      if (
        this.me.roles !== undefined &&
        this.me.roles.length > 0 &&
        (this.me.roles.includes("ROLE_SUPERVISOR") || this.me.roles.includes("ROLE_OPERATOR"))
      ) {
        return true;
      } else {
        return false;
      }
    },
    isValid() {
      return !this.$v.artistInfo.$invalid && !this.validation.invalidPassword && !this.validation.invalidConfirmPassword;
    },
    isDirty() {
      return this.$v.artistInfo.$anyDirty;
    },
    idxArtistGroup() {
      return this.idxArtist;
    }
  },
  mixins: [validationMixin],
  validations() {
    if (this.artistInfo.artistGroup === "true") {
      return {
        artistInfo: {
          artistNameEn: { required },
          nickname: { required },
          introduceEn: { required }
        }
      };
    } else {
      return {
        artistInfo: {
          artistNameEn: { required },
          nickname: { required },
          introduceEn: { required },
          name: { required },
          mobile: { required },
          email: { required, email },
          //password: { minLength: minLength(8) },
          //confirmPassword: { sameAsPassword: sameAs("password") }
        }
      };
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.requestChildCodes(["ARTIST_SKILL"]);
    });
  },
  methods: {
    ...companyHelper.mapActions(["getMyCompany"]),
    ...memberHelper.mapActions(["addArtist", "updateArtist", "getArtist", "uploadArtistProfileImage", "deleteArtist", "deleteArtistCompany"]),
    ...codeHelper.mapActions(["getChildMultiCodes"]),
    requestChildCodes(codes) {
      this.getChildMultiCodes(codes).then(() => {
        this.requestArtistItem();
      });
    },
    requestArtistItem() {
      if (this.idxArtist && this.idxArtist !== "0") {
        this.getArtist(this.idxArtist)
          .then(data => {
            let _artistInfo = data;
            if (_artistInfo.artistGroup === undefined || _artistInfo.artistGroup === null || _artistInfo.artistGroup === "") {
              _artistInfo.artistGroup = "false";
            } else {
              _artistInfo.artistGroup = _artistInfo.artistGroup === true ? "true" : "false";
            }
            // validation check
            if (_artistInfo.artistNameEn === undefined) {
              _artistInfo.artistNameEn = "";
            }
            if (_artistInfo.nickname === undefined) {
              _artistInfo.nickname = "";
            }
            if (_artistInfo.introduceEn === undefined) {
              _artistInfo.introduceEn = "";
            }
            if (_artistInfo.artistGroup === "false") {
              if (_artistInfo.name === undefined) {
                _artistInfo.name = "";
              }
              if (_artistInfo.mobile === undefined) {
                _artistInfo.mobile = "";
              }
              if (_artistInfo.email === undefined) {
                _artistInfo.email = "";
              }
              // 소속 법인 정보가 존재하는 경우
              if (_artistInfo.idxCompany !== undefined && _artistInfo.idxCompany > 0) {
                this.selectedCompany = true;
              }
            }
            // 작가 전문분야
            let skills = [];
            if (_artistInfo.skills !== undefined && _artistInfo.skills !== null && _artistInfo.skills.length > 0) {
              _artistInfo.skills.forEach(item => {
                skills.push(item.codeValue);
              });
            }
            _artistInfo.skills = skills;

            this.artistInfo = Object.assign({}, this.artistInfo, _artistInfo);
            this.$v.$reset();
            this.isBusy = false;
            this.$log.info(this.artistInfo); //TODO
          })
          .catch(error => {
            alert("작가 정보 조회 오류가 발생하였습니다. 관리자에게 문의하세요.");
            this.$log.error(error);
          });
      } else {
        this.artistInfo = this.getInitArtistInfo();
        // 법인 관리자인 경우
        if (!this.isAdminRole) {
          this.getMyCompany(this.idxArtist).then(() => {
            //this.$log.info(this.myCompanyInfo); //TODO
            if (this.myCompanyInfo !== undefined) {
              this.artistInfo.idxCompany = this.myCompanyInfo.idx;
              this.artistInfo.companyName = this.myCompanyInfo.companyName;
              this.artistInfo.bankCode = this.myCompanyInfo.bankCode;
              this.artistInfo.bankAccount = this.myCompanyInfo.bankAccount;
              this.artistInfo.klaytnAddr = this.myCompanyInfo.klaytnAddr;
              this.artistInfo.ethereumAddr = this.myCompanyInfo.ethereumAddr;
              this.artistInfo.btcAddr = this.myCompanyInfo.btcAddr;
              this.artistInfo.salesFeeRatioArtist = this.myCompanyInfo.salesFeeRatioArtist;
              this.artistInfo.salesFeeRatioThreespace = this.myCompanyInfo.salesFeeRatioThreespace;
              this.selectedCompany = true;
              this.isBusy = false;
            }
          });
        } else {
          this.isBusy = false;
        }
      }
    },
    onClickArtistMemberSelect() {
      this.$refs["artistSelectPopup"].show();
    },
    selectedArtistMemberInfo(member) {
      let isExist = false;
      for (let i = 0; i < this.artistInfo.childArtists.length; i++) {
        const item = this.artistInfo.childArtists[i];
        //this.$log.info(item.idxMember, member.idx); //TODO
        if (item.idxMember === member.idx) {
          isExist = true;
          break;
        }
      }
      if (!isExist) {
        this.artistInfo.childArtists.push({
          idx: null,
          idxMember: member.idxMember,
          artistNameEn: member.artistNameEn,
          artistName: member.artistName,
          nickname: member.nickname
        });
      }
    },
    onClickArtistMemberDelete(idxMember) {
      const _idxMember = this.artistInfo.childArtists.findIndex(
        item => item.idxMember === idxMember
      );
      if (_idxMember > -1) this.artistInfo.childArtists.splice(_idxMember, 1);
    },
    onClickCompanySelect() {
      this.$refs["companySelectPopup"].show();
    },
    selectedCompanyInfo(company) {
      //this.$log.info("selectedCompanyInfo() company: {}", company); //TODO
      if (company !== undefined) {
        this.artistInfo.idxCompany = company.idx;
        this.artistInfo.companyName = company.companyName;
        this.artistInfo.bankCode = company.bankCode;
        this.artistInfo.bankAccount = company.bankAccount;
        this.artistInfo.klaytnAddr = company.klaytnAddr;
        this.artistInfo.ethereumAddr = company.ethereumAddr;
        this.artistInfo.btcAddr = company.btcAddr;
        this.artistInfo.salesFeeRatioArtist = company.salesFeeRatioArtist;
        this.artistInfo.salesFeeRatioThreespace = company.salesFeeRatioThreespace;
        this.selectedCompany = true;
      }
    },
    onClickCompanyDelete() {
      if (confirm("소속 법인을 정말 삭제하겠습니까? 정산 관련 정보가 모두 초기화됩니다.")) {
        this.deleteArtistCompany({ idx: this.idxArtist })
          .then(() => {
            alert("소속 법인 정보가 삭제되었습니다. 정산 정보를 재설정 해주세요.");
            this.$router.go(this.$router.currentRoute);
          })
          .catch(error => {
            this.$log.error(error);
            alert("소속 법인 정보 삭제 오류가 발생하였습니다. 관리자에게 문의하세요.");
          });
      }
    },
    onClickCancel() {
      if (this.isAdminRole) {
        this.$router.push("/management/artist-list");
      } else {
        this.$router.push("/corp-management/artist-list");
      }
    },
    onClickDelete() {
      if (confirm("정말 삭제하시겠습니까?")) {
        this.deleteArtist({ idx: this.idxArtist })
          .then(() => {
            alert("삭제 되었습니다.");
            if (this.isAdminRole) {
              this.$router.push("/management/artist-list");
            } else {
              this.$router.push("/corp-management/artist-list");
            }
          })
          .catch(error => {
            this.$log.error(error);
            alert("작가 삭제 오류가 발생하였습니다. 관리자에게 문의하세요.");
          });
      }
    },
    onClickSave() {
      this.$log.log("onClickSave()", this.artistInfo); //TODO

      // 비밀번호 Validation
      this.validation.invalidPassword = false;
      this.validation.invalidConfirmPassword = false;
      const password = this.artistInfo.password;
      const confirmPassword = this.artistInfo.confirmPassword;
      if (password !== null && password != "") {
        if (password.length <= 7 || password != confirmPassword) {
          this.validation.invalidPassword = true;
        }
      }
      if (confirmPassword !== null && confirmPassword != "") {
        if (confirmPassword != password) {
          this.validation.invalidConfirmPassword = true;
        }
      }

      if (this.isValid) {
        // 판매수수료 비율
        let salesFeeRatioArtist = this.artistInfo.salesFeeRatioArtist !== undefined && this.artistInfo.salesFeeRatioArtist !== "" ? this.artistInfo.salesFeeRatioArtist : 0;
        let salesFeeRatioThreespace = this.artistInfo.salesFeeRatioThreespace !== undefined && this.artistInfo.salesFeeRatioThreespace !== "" ? this.artistInfo.salesFeeRatioThreespace : 0;
        const ratio = parseInt(salesFeeRatioArtist) + parseInt(salesFeeRatioThreespace);
        if (ratio !== 0 && ratio !== 100) {
          alert("판매수수료 비율은 총 100%로 설정해주세요.");
          return false;
        }

        // 작가 정보 저장
        if (this.idxArtist && this.idxArtist !== "0") {
          // 작가 수정
          this.updateArtist(this.artistInfo)
              .then(() => {
            // 프로필/신분증/대표이미지 파일 수정
            this.updateProfileImage(this.idxArtist);
          })
          .catch(error => {
            this.$log.log(error);
          });
        } else {
          // TODO 초기 비밀번호
          if (password === null || password === "") {
            this.artistInfo.password = "3Spaceart!";
          }
          // 작가 등록
          this.addArtist(this.artistInfo)
            .then(data => {
              this.artistInfo.password = "";
              // 프로필/신분증/대표이미지 파일 등록
              this.updateProfileImage(data.idx);
            })
            .catch(error => {
              this.artistInfo.password = "";
              this.$log.log(error);
              let errorMessage = "작가등록 오류가 발생하였습니다. 관리자에게 문의해 주세요.";
              if (error.data.error === "BAD_REQUESTED" && error.data.resource === "EXISTS_ACCOUNT") {
                errorMessage = "이미 등록된 이메일계정 입니다. 확인 후 다시 시도해 주세요.";
              }
              alert(errorMessage);
            });
        }
      } else {
        this.$v.$touch();
      }
    },
    updateProfileImage(idxArtist) {
      // 프로필/신분증 파일 업로드
      if (!!this.profileImage || !!this.bannerImage || !!this.artworkImage || !!this.idCard || !!this.groupIcon) {
        const formData = new FormData();
        formData.append("idxArtist", idxArtist);
        formData.append("profileImage", this.profileImage);
        formData.append("bannerImage", this.bannerImage);
        formData.append("artworkImage", this.artworkImage);
        formData.append("idCard", this.idCard);
        formData.append("groupIcon", this.groupIcon);
        this.uploadArtistProfileImage(formData)
          .then(() => {
            alert("저장 되었습니다.");
            if (this.isAdminRole) {
              this.$router.push("/management/artist-list");
            } else {
              this.$router.push("/corp-management/artist-list");
            }
          })
          .catch(error => {
            alert("작가 수정 오류가 발생하였습니다. 관리자에게 문의하세요.");
            this.$log.error(error);
          });
      } else {
        alert("저장 되었습니다.");
        if (this.isAdminRole) {
          this.$router.push("/management/artist-list");
        } else {
          this.$router.push("/corp-management/artist-list");
        }
      }
    },
    onClickProfileImage(event) {
      this.profileImage = event.target.files[0];
    },
    onClickBannerImage(event) {
      this.bannerImage = event.target.files[0];
    },
    onClickIdCardImage(event) {
      this.idCard = event.target.files[0];
    },
    onClickGroupIcon(event) {
      this.groupIcon = event.target.files[0];
    },
    onClickArtworkImage(event) {
      this.artworkImage = event.target.files[0];
    },
    checkIfValid(fieldName) {
      const field = this.$v.artistInfo[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    getInitArtistInfo() {
      return {
        artistGroup: "false",
        childArtists: [],
        artistName: "",
        artistNameEn: "",
        nickname: "",
        name: "",
        mobile: "",
        email: "",
        password: "",
        confirmPassword: "",
        introduce: "",
        introduceEn: "",
        salesFeeRatioArtist: "",
        salesFeeRatioThreespace: ""
      }
    }
  }
};
</script>
