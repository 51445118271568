<template>
  <b-modal
    id="collection-select-modal"
    ref="collection-select-modal"
    centered
    no-close-on-esc
    no-close-on-backdrop
    hide-footer
    body-class="text-center text-black px-4"
    size="lg"
    title="컬렉션 선택"
  >
    <!-- 검색 -->
    <div class="row">
      <div class="col">
        <b-input-group>
          <template #prepend>
            <b-form-select
              v-model="externalParams.searchTypeOption"
              :options="searchTypeOptions"
            ></b-form-select>
          </template>
          <b-form-input
            placeholder="검색어를 입력해주세요."
            v-model="externalParams.searchKeyword"
            @keypress.enter.prevent="onClickSearch"
          ></b-form-input>
          <template #append>
            <b-button size="sm" variant="info" @click="onClickSearch">
              <CIcon name="cil-search" class="m-0" />
            </b-button>
            <b-button size="sm" variant="secondary" @click="onClickReset">
              <CIcon name="cil-filter-x"/>
            </b-button>
          </template>
        </b-input-group>
      </div>
    </div>

    <!-- 목록 -->
    <div class="row mt-3 border-bottom">
      <div class="col">
        <b-table
          id="collectionTable"
          thead-class="text-center"
          :busy="isBusy"
          :items="collectionTableItems.content"
          :fields="collectionTableFields"
          :items-per-page="perPage"
          show-empty
          empty-text="조회된 내역이 없습니다."
        >
          <!-- NO -->
          <template #cell(index)="data">
            {{ data.index + 1 + (currentPage - 1) * perPage }}
          </template>
          
          <!-- Actions -->
          <template v-slot:cell(actions)="data">
            <template v-if="galleryCollections.findIndex(el => el.idx === data.item.idx) === -1">
              <b-form-checkbox-group class="m-0" v-model="collectionSelected">
                <b-form-checkbox class="m-0" :value="data.item"></b-form-checkbox>
              </b-form-checkbox-group>
            </template>
            <template v-else>
              <v-icon>mdi-check-underline</v-icon>
            </template>
          </template>
        </b-table>

        <b-pagination
          aria-controls="productTable"
          align="center"
          hide-ellipsis
          limit="10"
          v-model="currentPage"
          :total-rows="collectionTableItems.totalElements"
          :per-page="perPage"
          :current-page="currentPage"
          v-on:input="onPaging"
        ></b-pagination>
      </div>
    </div>

    <div class="row py-3">
      <div class="col text-center">
        <CButton @click="hide()" color="light" class="mr-2">
          <CIcon name="cil-x-circle" class="mr-2" />취소
        </CButton>
        <CButton @click="onSubmit()" color="primary" class="mr-2 text-light">
          <CIcon name="cil-check" class="mr-2" />확인
        </CButton>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "CollectionSelectPopup",
  props: {
    galleryCollections: {
      type: Array,
      default: () => []
    }
  },
  data: function() {
    return {
      collectionSelected: [],
      isBusy: false,
      perPage: 7,
      pageOptions: [
        { value: 10, label: "10 개씩 보기" },
        { value: 20, label: "20 개씩 보기" },
        { value: 30, label: "30 개씩 보기" },
        { value: 50, label: "50 개씩 보기" }
      ],
      currentPage: 1,
      sort: [
        { id: "idx", desc: "desc" }
      ],
      sortBy: "",
      sortDesc: false,
      // 검색
      searchTypeOptions: [
        { value: null, text: "전체" },
        { value: "TITLE", text: "작품명" },
        { value: "OWNER", text: "소유주" },
        { value: "RFC", text: "RFC" },
        { value: "NFT", text: "NFT명" },
      ],
      externalParams: {
        searchKeyword: "",
        searchTypeOption: null,
        isChainEthereum: true,
        isChainKlaytn: true,
        isFileTypeGif: true,
        isFileTypeImage: true,
        isFileTypeMp4: true,
        isVisibleAll: true,
      },
      collectionTableFields: [
        { key: "idx", label: "컬렉션 번호", class: "text-center"},
        { key: "nft.name", label: "NFT", class: "text-center"},
        { key: "title", label: "컬렉션 명" },
        { key: "owner", label: "소유주", class: "text-center" },
        { key: "nft.rfc", label: "RFC", class: "text-center" },
        { key: "actions", label: "Actions", class: "text-center", thStyle: "width:50px" }
      ],
    };
  },
  computed: {
    ...mapState("collectionManager", ["collectionTableItems"]),
  },
  mounted() {
  },
  methods: {
    ...mapActions("collectionManager", ["getCollections"]),
    onSubmit() {
      if (this.collectionSelected.length > 0) {
        this.$emit("onSelectedCollection", this.collectionSelected);
        this.hide();
      } else {
        alert("선택된 컬렉션이 없습니다.");
      }
    },
    onPaging() {
      this.requestCollectionItems();
      this.collectionSelected = [];
    },
    resetSearchConditions() {
      this.currentPage = 1;
      this.externalParams.searchKeyword = ""
      this.externalParams.searchTypeOption = null
      this.externalParams.isChainEthereum = true
      this.externalParams.isChainKlaytn = true
      this.externalParams.isFileTypeGif = true
      this.externalParams.isFileTypeImage = true
      this.externalParams.isFileTypeMp4 = true
      this.externalParams.isVisibleAll = true
    },
    onClickSearch() {
      this.requestCollectionItems();
    },
    onClickReset() {
      this.resetSearchConditions();
      this.requestCollectionItems();
    },
    requestCollectionItems() {
      this.isBusy = true;
      this.getCollections(
        this.getPagingParams(
          {
            page: this.currentPage - 1,
            pageSize: this.perPage,
            sorted: this.sort,
            filtered: []
          },
          this.externalParams
        )
      )
        .then(data => {
          this.$log.log(data);
          this.isBusy = false;
        })
        .catch(error => {
          this.isBusy = false;
          this.$log.error(error);
        });
    },
    onClickImportNft() {
      this.getNfts(
        this.getPagingParams(
          {
            page: this.currentPage - 1,
            pageSize: 50,
            sorted: this.sort,
            filtered: []
          },
          {}
        )
      )
        .then(() => {
          this.$refs["collectionImportNftModal"].show();
        })
        .catch(error => {
          this.$log.error(error);
        });
    },
    onClickMember(idxMember) {
      let routeData = this.$router.resolve({name: 'MemberSave', params: {idxMember: idxMember}});
      window.open(routeData.href, '_blank');
    },
    onClickModify(idx) {
      this.$router.push("/management/collection-detail/" + idx);
    },
    getPagingParams({ page, pageSize, sorted, filtered }, externalParams) {
      const params = {
        page,
        size: pageSize,
        ...externalParams
      };
      // sorting parameter
      let sorts = [];
      for (let i = 0, length = sorted.length; i < length; i++) {
        const sort = sorted[i];
        sorts.push(`${sort.id},${sort.desc ? "desc" : "asc"}`);
      }
      params["sort"] = sorts;

      // filtering parameter
      for (let i = 0, length = filtered.length; i < length; i++) {
        const filter = filtered[i];
        params[filter.id] = filter.value;
      }

      return params;
    },
    show() {
      this.requestCollectionItems();
      this.$refs["collection-select-modal"].show();
    },
    hide() {
      this.$refs["collection-select-modal"].hide();
      this.resetSearchConditions();
      this.collectionSelected = [];
    },
  }
};
</script>
