import Vue from "vue";
import VueI18n from "vue-i18n";

const requireLang = require.context("@/lang", true, /\.json$/);

const messages = {};

for (const file of requireLang.keys()) {
  if (file === "./index.js") continue; // index.js 파일 제외
  const path = file.replace(/(\.\/|\.json$)/g, "").split("/");
  path.reduce((o, s, i) => {
    if (o[s]) return o[s];
    o[s] = i + 1 === path.length ? requireLang(file) : {};
    return o[s];
  }, messages);
}

Vue.use(VueI18n);

export default new VueI18n({
  locale: getBrowserLocale(), // 기본 locale
  fallbackLocale: "en", // locale 설정 실패시 사용할 locale
  messages, // 다국어 메시지
  silentTranslationWarn: true // 메시지 코드가 없을때 나오는 console 경고 off
});

function getBrowserLocale() {
  let _lang = "en";
  let navigatorLocale = localStorage.getItem("language");
  if (
    navigatorLocale === undefined ||
    navigatorLocale === null ||
    navigatorLocale === ""
  ) {
    navigatorLocale =
      navigator.languages !== undefined
        ? navigator.languages[0]
        : navigator.language;

    if (navigatorLocale) {
      _lang = navigatorLocale.trim().split(/-|_/)[0];
      if (_lang !== undefined && _lang === "ko") {
        // TODO 현재 지원 언어: ko, en
      } else {
        _lang = "en";
      }
    }
  } else {
    if (navigatorLocale == "ko") {
      _lang = "ko";
    }
  }
  localStorage.setItem("language", _lang);
  return _lang;
}
