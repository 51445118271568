<template>
  <CCard>
    <CCardBody>
      <CRow>
        <CCol class="mb-3"><h5>아트풀 관리</h5></CCol>
      </CRow>
      <CRow>
        <CCol class="col-auto mr-auto align-self-center">
          총&nbsp;<strong>{{ artpoolTableItems.totalElements }}</strong>건&nbsp;({{ currentPage }}&nbsp;/&nbsp;{{ artpoolTableItems.totalPages }}&nbsp;페이지)
        </CCol>
        <CCol class="col-auto">
          <CSelect
            class="float-left mr-2 mb-2"
            :options="pageOptions"
            :value.sync="perPage"
            @change="onPaging"
          />
          <CButton color="dark" @click="onClickRegister" class="text-white">
            <CIcon name="cil-library-add" class="mr-2" />신규등록
          </CButton>
        </CCol>
      </CRow>
      <b-table
        id="artpoolTable"
        thead-class="text-center"
        :busy="isBusy"
        :items="artpoolTableItems.content"
        :items-per-page="perPage"
        :fields="artpoolTableFields"
        show-empty
        empty-text="조회된 내역이 없습니다."
      >
        <!-- NO -->
        <template #cell(index)="data">
          {{ data.index + 1 + (currentPage - 1) * perPage }}
        </template>

        <!-- 아트풀 명 -->
        <template #cell(title)="data">
          <a class="text-info cursor-pointer" @click="onClickArtpoolTitle(data.item.idx)">
            {{ data.item.title }}
          </a>
        </template>
        <!-- 시작일 -->
        <template #cell(startDate)="data">
          {{ data.value | moment("YY-MM-DD HH:mm") }}
        </template>
        <!-- 종료일 -->
        <template #cell(endDate)="data">
          {{ data.value | moment("YY-MM-DD HH:mm") }}
        </template>
        <!-- 투표 시작일 -->
        <template #cell(voteStartDate)="data">
          {{ data.value | moment("YY-MM-DD HH:mm") }}
        </template>
        <!-- 투표 종료일 -->
        <template #cell(voteEndDate)="data">
          {{ data.value | moment("YY-MM-DD HH:mm") }}
        </template>

        <!-- Actions -->
        <template v-slot:cell(actions)="data">
          <CButton
            variant="outline"
            color="dark"
            size="sm"
            class="mr-1 px-1"
            @click="onClickModify(data.item.idx)"
          >
            <CIcon name="cil-pencil" />
          </CButton>
          <CButton
            variant="outline"
            color="dark"
            class="mr-1 px-1"
            @click="onClickRound(data.item.idx)"
          >
            Round
          </CButton>
          <CButton
            variant="outline"
            color="dark"
            class="mr-1 px-1"
            @click="onClickVote(data.item.idx)"
          >
            Vote
          </CButton>
          <CButton
            variant="outline"
            color="dark"
            class="mr-1 px-1"
            @click="onClickWhitelist(data.item.idx)"
          >
            W/L
          </CButton>
          <CButton
            variant="outline"
            color="dark"
            class="px-1"
            @click="onClickCollection(data.item.idx)"
          >
            컬렉션
          </CButton>
        </template>
      </b-table>
      <b-pagination
        aria-controls="artistTable"
        align="center"
        hide-ellipsis
        limit="10"
        v-model="currentPage"
        :total-rows="artpoolTableItems.totalElements"
        :per-page="perPage"
        :current-page="currentPage"
        v-on:input="onPaging"
      ></b-pagination>
    </CCardBody>
  </CCard>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const authHelper = createNamespacedHelpers("auth");
const artpoolHelper = createNamespacedHelpers("artpoolManager");

export default {
  name: "ArtpoolList",
  components: {},
  data: function() {
    return {
      isBusy: false,
      perPage: 10,
      pageOptions: [
        { value: 10, label: "10 개씩 보기" },
        { value: 20, label: "20 개씩 보기" },
        { value: 30, label: "30 개씩 보기" },
        { value: 50, label: "50 개씩 보기" }
      ],
      currentPage: 1,
      sort: [
        { id: "idx", desc: "desc" }
      ],
      sortBy: "",
      sortDesc: false,
      // 검색
      searchSelected: "",
      searchOptions: [
        { value: "", label: "전체" }
      ],
      externalParams: this.getInitExternalParams()
    };
  },
  computed: {
    ...authHelper.mapState(["me"]),
    ...artpoolHelper.mapState(["artpoolTableItems", "artpoolTableFields"]),
    isAdminRole() {
      if (
        this.me.roles !== undefined &&
        this.me.roles.length > 0 &&
        (this.me.roles.includes("ROLE_SUPERVISOR") || this.me.roles.includes("ROLE_OPERATOR"))
      ) {
        return true;
      } else {
        return false;
      }
    }
  },
  mounted() {
    this.requestArtistItems();
  },
  methods: {
    ...artpoolHelper.mapActions(["getArtpools"]),
    onPaging() {
      this.requestArtistItems();
    },
    requestArtistItems() {
      this.isBusy = true;
      this.getArtpools(
        this.getPagingParams(
          {
            page: this.currentPage - 1,
            pageSize: this.perPage,
            sorted: this.sort,
            filtered: []
          },
          this.externalParams
        )
      )
        .then(data => {
          this.isBusy = false;
        })
        .catch(error => {
          this.isBusy = false;
          this.$log.error(error);
        });
    },
    onClickRegister() {
      this.$router.push("/management/artpool-save/0");
    },
    onClickModify(idx) {
      this.$router.push("/management/artpool-save/" + idx);
    },
    onClickRound(idx) {
      this.$router.push("/management/artpool-round/" + idx);
    },
    onClickVote(idx) {
      this.$router.push("/management/artpool-vote/" + idx);
    },
    onClickWhitelist(idx) {
      this.$router.push("/management/artpool-whitelist/" + idx);
    },
    onClickCollection(idx) {
      this.$router.push("/management/artpool-collection/" + idx);
    },
    onClickArtpoolTitle(idx) {
      this.$router.push("/management/artpool-detail/" + idx);
    },
    getPagingParams({ page, pageSize, sorted, filtered }, externalParams) {
      const params = {
        page,
        size: pageSize,
        ...externalParams
      };

      // sorting parameter
      let sorts = [];
      for (let i = 0, length = sorted.length; i < length; i++) {
        const sort = sorted[i];
        sorts.push(`${sort.id},${sort.desc ? "desc" : "asc"}`);
      }
      params["sort"] = sorts;

      // filtering parameter
      for (let i = 0, length = filtered.length; i < length; i++) {
        const filter = filtered[i];
        params[filter.id] = filter.value;
      }

      return params;
    },
    getInitExternalParams() {
      return {
        searchKeyword: "",
        keywordIdxArtpool: "",
      };
    }
  }
};
</script>
