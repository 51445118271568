<template>
  <CCard>
    <CCardBody>
      <CRow>
        <CCol class="mb-3"><h5>아트풀 (온라인갤러리) 컬렉션 설정</h5></CCol>
      </CRow>
      <CRow>
        <CCol>
          <b-table-simple>
            <colgroup>
              <col style="width:12%;" />
              <col style="width:38%;" />
              <col style="width:12%;" />
              <col style="width:38%;" />
            </colgroup>
            <b-tbody>
              <b-tr>
                <b-th variant="secondary">아트풀 번호</b-th>
                <b-td>
                  {{ artpoolInfo.idx || "(정보없음)" }}
                </b-td>
                <b-th variant="secondary">
                  회차(라운드)
                </b-th>
                <b-td>
                  <div class="row align-items-center">
                    <div class="col-lg-3 col-md-3 pr-0">
                      아트풀 <span class="text-danger font-lg">*</span>
                    </div>
                    <div class="col-9">
                      {{ artpoolInfo.roundNo }}
                    </div>
                  </div>
                  <div class="row align-items-center mt-1">
                    <div class="col-lg-3 col-md-3 pr-0">
                      클레이튼
                    </div>
                    <div class="col-9">
                      {{ artpoolInfo.roundNoKip }}
                    </div>
                  </div>
                  <div class="row align-items-center mt-1">
                    <div class="col-lg-3 col-md-3 pr-0">
                      이더리움
                    </div>
                    <div class="col-9">
                      {{ artpoolInfo.roundNoErc }}
                    </div>
                  </div>
                </b-td>
              </b-tr>
              <b-tr class="border-bottom">
                <b-th variant="secondary">
                  아트풀 명
                </b-th>
                <b-td>
                  {{ artpoolInfo.title || artpoolInfo.titleEn }}
                </b-td>
                <b-th variant="secondary">
                  아트풀 기간
                </b-th>
                <b-td>
                  {{ artpoolInfo.startDate }} ~ {{ artpoolInfo.endDate }}
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </CCol>
      </CRow>

      <CRow>
        <CCol col="12" class="d-flex justify-content-between">
          <div>
            <b-button
              squared
              :class="{'bg-black text-white' : currentPlace === 0}"
              @click="onClickPlaceTotal()"
            >
              전체
            </b-button>
            <b-button
              v-for="(item, i) in galleryCollections"
              :key="i"
              :class="{'bg-black text-white' : currentPlace === item.artpoolPlace.idx}"
              @click="onClickPlaceTab(item)"
              squared
            >
              {{ item.artpoolPlace.placeName }}
            </b-button>
            <b-dropdown squared variant="link" toggle-class="text-decoration-none" no-caret>
              <template #button-content>
                <v-icon class="m-0">mdi-plus</v-icon>
              </template>
              <template v-for="(item, i) in placeList">
                <b-dropdown-item-button
                  :key="i"
                  @click="onClickPlaceAdd(item)"
                  v-if="aleadyPlace(item)"
                >
                  {{ item.placeName }}
                </b-dropdown-item-button>
              </template>
            </b-dropdown>
          </div>
          <div>
            <ArtpoolPlaceSetting />
          </div>
        </CCol>
      </CRow>
      <CRow>
        <CCol col="12">
          <div class="border p-3">
            <div class="text-right mb-2">
              <b-form-checkbox inline v-model="showImage">
                컬렉션 이미지 보기
              </b-form-checkbox>
              <CButton
                size="sm"
                class="bg-accent text-white mr-2"
                @click="onClickFindCollection"
                v-if="currentPlace !== 0"
              >
                컬렉션 추가 <CIcon name="cil-plus" />
              </CButton>
              <CButton
                size="sm"
                class="bg-danger text-white"
                @click="onClickDeletePlaceAndCollection()"
                v-if="currentPlace !== 0"
              >
                공간 삭제 <CIcon name="cil-trash" />
              </CButton>
            </div>
            <b-table-simple class="text-center">
              <b-thead>
                <b-th variant="secondary">No</b-th>
                <b-th variant="secondary">번호(idx)</b-th>
                <b-th variant="secondary">NFT</b-th>
                <b-th variant="secondary">이미지(비디오)</b-th>
                <b-th variant="secondary">컬렉션 명</b-th>
                <b-th variant="secondary">소유주</b-th>
                <b-th variant="secondary">RFC</b-th>
                <b-th variant="secondary">삭제</b-th>
              </b-thead>
              <b-tbody v-if="currentCollections.length > 0">
                <template v-for="(item, index) in currentCollections">
                  <b-tr :key="'klaytn' + index">
                    <b-td>{{ index + 1 }}</b-td>
                    <b-td>{{ item.idx }}</b-td>
                    <b-td>{{ item.nft.name }}</b-td>
                    <b-td>
                      <!-- 이미지 -->
                      <template v-if="showImage">
                        <template v-if="!item.video">
                          <b-img class="ac_list_image" :src="item.imageUrl"></b-img>
                        </template>
                        <template v-else>
                          <video
                            :poster="replaceDisplayPosterUrl(item)"
                            :src="replaceDisplayCardUrl(item)"
                            class="ac_list_video"
                            type="video/webm"
                            muted
                            loop
                            autoplay
                            playsinline
                          ></video>
                        </template>
                      </template>
                      <template v-else>
                        <div class="ac_media_box">
                          <v-icon class="cursor-pointer">mdi-image-search-outline</v-icon>
                          <div class="hover_box">
                            <template v-if="!item.video">
                              <b-img class="ac_list_image" :src="item.imageUrl"></b-img>
                            </template>
                            <template v-else>
                              <video
                                :poster="replaceDisplayPosterUrl(item)"
                                :src="replaceDisplayCardUrl(item)"
                                class="ac_list_video"
                                type="video/webm"
                                muted
                                loop
                                autoplay
                                playsinline
                              ></video>
                            </template>
                          </div>
                        </div>
                      </template>
                    </b-td>
                    <b-td>{{ item.title }}</b-td>
                    <b-td>{{ item.owner }}</b-td>
                    <b-td>{{ item.nft.rfc }}</b-td>
                    <b-td>
                      <CButton
                        color="right"
                        @click="onClickDeleteCollection(item.idx)"
                      >
                        <CIcon name="cil-x" />
                      </CButton>
                    </b-td>
                  </b-tr>
                </template>
              </b-tbody>
              <b-tbody v-else>
                <b-tr>
                  <b-td colspan="7">
                    <p class="text-gray my-5">
                      + 클릭 > 전시공간 추가 > 컬렉션 찾기
                    </p>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>
        </CCol>
      </CRow>

      <CRow>
        <CCol col="12" class="text-center my-4">
          <CButton color="light" @click="onClickCancel()" class="mr-2">
            <CIcon name="cil-x-circle" class="mr-2" />취소
          </CButton>
          <CButton color="dark" @click="onClickSave()" class="text-white" :disabled="isBusy">
            <CIcon name="cil-save" class="mr-2" />저장
          </CButton>
        </CCol>
      </CRow>

      <CollectionSelectPopup 
        ref="collectionSelectPopup"
        :galleryCollections="currentCollections"
        @onSelectedCollection="onSelectedCollection"
      />
    </CCardBody>
  </CCard>
</template>

<script>
import { createNamespacedHelpers, mapActions, mapState } from "vuex";
import ArtpoolPlaceSetting from './ArtpoolPlaceSetting.vue';
const artpoolHelper = createNamespacedHelpers("artpoolManager");

import CollectionSelectPopup from './CollectionSelectPopup.vue';


export default {
  name: "ArtpoolCollection",
  components: {
    CollectionSelectPopup,
    ArtpoolPlaceSetting
  },
  props: {
    idxArtpool: {
      type: String,
      default: "0"
    }
  },
  data: function() {
    return {
      isBusy: false,
      galleryCollections: [],
      showImage: false,
      currentPlace: 0,
    };
  },
  computed: {
    ...artpoolHelper.mapState([
      "artpoolInfo", 
      "artpoolGalleryCollections", 
      "artpoolGalleryCollectionsTotal"
    ]),
    ...mapState("artpoolPlaceManager", ["placeList"]),
    currentCollections: {
      get: function () {
        if (this.currentPlace === 0) {
          return this.artpoolGalleryCollectionsTotal
        } else {
          let galleryCollection = this.galleryCollections.find(el => el.artpoolPlace.idx === this.currentPlace)
          return galleryCollection.collections
        }
      },
      set: function (newVal) {
        let galleryCollection = this.galleryCollections.find(el => el.artpoolPlace.idx === this.currentPlace)
        galleryCollection.collections = [...newVal];
      },
    },
  },
  mounted() {
    this.requestArtpoolItem();
    this.getplaces();
  },
  methods: {
    ...artpoolHelper.mapActions([
      "getArtpool", 
      "addArtpoolWhitelist", 
      "updateArtpoolGalleryCollection",
      "getArtpoolGalleryCollection",
      "getArtpoolGalleryCollectionTotal"
    ]),
    ...mapActions("artpoolPlaceManager", ["getplaces"]),
    requestArtpoolItem() {
      if (this.idxArtpool && this.idxArtpool !== "0") {
        this.isBusy = true;
        this.getArtpool(this.idxArtpool)
          .then(() => {
            this.isBusy = false;
            this.getArtpoolGalleryCollectionTotal(this.idxArtpool).then(() => {
              this.getArtpoolGalleryCollection(this.idxArtpool).then(() => {
                if (this.artpoolGalleryCollections.length > 0) {
                  this.galleryCollections = [...this.artpoolGalleryCollections];
                }
              });
            });
          })
          .catch(error => {
            alert("아트풀 정보 조회 오류가 발생하였습니다. 관리자에게 문의하세요.");
            this.$log.error(error);
            this.isBusy = false;
          });
      }
    },
    aleadyPlace(item) {
      let index = this.galleryCollections.findIndex(el => el.artpoolPlace.idx === item.idx);
      if (index === -1) {
        return true
      } else {
        return false
      }
    },
    onClickPlaceAdd(artpoolPlace) {
      this.currentPlace = artpoolPlace.idx;
      let galleryCollection = {
        artpoolPlace,
        collections: []
      }
      this.galleryCollections.push(galleryCollection);
    },
    onClickPlaceTab(galleryCollection) {
      this.currentPlace = galleryCollection.artpoolPlace.idx;
    },
    onClickPlaceTotal() {
      this.currentPlace = 0;
      this.getArtpoolGalleryCollectionTotal().then();
    },
    onClickFindCollection() {
      this.$refs["collectionSelectPopup"].show();
    },
    onClickCancel() {
      this.$router.push("/management/artpool-list");
    },
    onSelectedCollection(collectionSelected) {
      this.currentCollections = [...this.currentCollections, ...collectionSelected]
    },
    onClickSave() {
      let params = {
        idx: this.idxArtpool, 
        galleryCollections: this.galleryCollections
      };
      this.updateArtpoolGalleryCollection(params).then(() => {
        alert("저장이 완료되었습니다.")
        this.requestArtpoolItem();
      }).catch(() => {
        alert("저장에 실패하였습니다.")
      });
    },
    onClickDeleteCollection(idx) {
      let index = this.currentCollections.findIndex(el => el.idx === idx)
      if (index !== -1) {
        this.currentCollections.splice(index, 1);
      }
    },
    onClickDeletePlaceAndCollection() {
      if (confirm("현재 페이지에 설정된 전시공간과 컬렉션이 모두 삭제 됩니다. \n계속 진행하시겠습니까?\n(저장 시에만 삭제 적용)")) {
        let index = this.galleryCollections.findIndex(el => el.artpoolPlace.idx === this.currentPlace)
        if (index !== -1) {
          this.galleryCollections.splice(index, 1);
          this.currentPlace = 0;
        }
      }
    },
    replaceDisplayPosterUrl(item) {
      if (item.idxProduct !== undefined && item.idxProduct !== null && item.idxProduct !== "") {
        if (
          item.product.displayPoster !== undefined &&
          item.product.displayPoster !== null &&
          item.product.displayPoster !== "" &&
          item.product.displayPoster === "THREESPACE"
        ) {
          return item.product.displayPosterUrlThreespace; // 3space S3 URL
        } else {
          if (item.product.displayPosterUrl !== undefined && item.product.displayPosterUrl.startsWith("https://ipfs.io/")) {
            return item.product.displayPosterUrl.replace("https://ipfs.io/", "https://ipfs.3space.art/");
          }
          return item.product.displayPosterUrl;
        }
      } else {
        return item.imageUrl
      }
    },
    replaceDisplayCardUrl(item) {
      if (item.idxProduct !== undefined && item.idxProduct !== null && item.idxProduct !== "") {
        if (
          item.product.displayCardFile !== undefined &&
          item.product.displayCardFile !== null &&
          item.product.displayCardFile !== "" &&
          item.product.displayCardFile === "THREESPACE"
        ) {
          return item.product.displayCardUrlThreespace; // 3space S3 URL
        } else {
          if (item.product.displayCardUrl !== undefined && item.product.displayCardUrl.startsWith("https://ipfs.io/")) {
            return item.product.displayCardUrl.replace("https://ipfs.io/", "https://ipfs.3space.art/");
          }
          return item.product.displayCardUrl;
        }
      } else {
        return item.imageUrl
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.ac_list_image,
.ac_list_video {
  width: 100px;
  height: 100px;
  border: 1px solid #ddd;
  object-fit: contain;
}
.ac_media_box {
  position: relative;
  cursor: pointer;
  margin: 0px auto;
  display: inline-block;
}
.ac_media_box .hover_box {
  position: absolute;
  bottom: 0px;
  right: 30px;
  width: 100px;
  height: 100px;
  border: 1px solid #ddd;
  background-color: #fff;
  z-index: 10;
  opacity: 0;
  transition: all 0.3s;
}
.ac_media_box:hover .hover_box {
  opacity: 1;
}
.ac_media_box .hover_box img,
.ac_media_box .hover_box video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>