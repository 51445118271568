<template>
  <div>
    <div id="EventWrap">
      <div class="event_main_wrap">
        <div class="text_box">
          <h2>세번째 공간 X 클레이튼</h2>
          <h1>
            회원가입만 해도<br />
            3KLAY가 내 지갑에?!
          </h1>
          <p>
            국내 최초 온라인 디지털아트 갤러리<br />
            <span>
              한정판 NFT, 50인 추첨 지급!
            </span>
          </p>
        </div>
        <!--
        <div class="button_box">
          <b-button @click="onClickEventSubmit">
            이벤트 참여하기
          </b-button>
          <b-button @click="onClickShareLink" :disabled="true">
            이벤트 공유하기
          </b-button>
          <CInput type="hidden" v-model="eventLink" id="hiddenEventLink" />
        </div>
        <div class="info_box">
          <p>
            행사대상: 본 이벤트를 통해 세번째 공간 웹사이트에 신규 회원가입한
            회원 (하단 유의사항 참고)
          </p>
          <p>
            이벤트 참여 기간: 2021.07.26(월) ~ 2021.08.06(금) (14일간)
          </p>
          <p>
            증정일시: 이벤트 종료 이후 사이트 및 카톡채널을 통해 별도 공지
          </p>
          <p>
            이벤트 참여자 전원 클레이 지급(소진 시까지)
          </p>
        </div>
         -->
      </div>
      <div class="event_winner_notice" id="EventWinnerNotice">
        <div class="title">
          <h2>
            한정판 NFT 행성
          </h2>
          <h1>
            SAOO 시리즈 당첨자 공지
          </h1>
          <p>
            <span>세번째 공간이 발행한 최초 NFT시리즈, 행성을 드립니다!</span
            ><br />
            SA(Space-Art)시리즈는 SA01부터 SA50까지 총 50개 행성으로 이루어져
            있으며<br class="second" />
            앞으로 만나보실 수 있는 '갤러리 - 필터 - Series'를 통해 모든 행성을
            감상하실 수 있습니다.<br class="last" />
            이벤트에 참여해주신 모든분들께 감사드립니다.
          </p>
        </div>
        <div class="winner_list">
          <p>lj0917</p>
          <p>popo</p>
          <p>redmato</p>
          <p>kknf439</p>
          <p>Luckkk</p>
          <p>simon</p>
          <p>doak274</p>
          <p>sasoone2</p>
          <p>Untitled</p>
          <p>nadayo</p>
          <p>kyoung</p>
          <p>solzreed1</p>
          <p>startotr</p>
          <p>choo</p>
          <p>k018613</p>
          <p>won</p>
          <p>098funny</p>
          <p>bluebird777</p>
          <p>mellun</p>
          <p>Dy55</p>
          <p>no1medic</p>
          <p>boss</p>
          <p>vivisoda</p>
          <p>tirimi</p>
          <p>jjinu81</p>
          <p>baek2508</p>
          <p>jacobs83</p>
          <p>inkyoung</p>
          <p>Wonseok2</p>
          <p>iameros81</p>
          <p>Sparrow</p>
          <p>top5752</p>
          <p>Euh281</p>
          <p>vngltsz3123</p>
          <p>masami87</p>
          <p>nanamjada1</p>
          <p>phillipLee</p>
          <p>sakid</p>
          <p>westsaid</p>
          <p>sjyoon23</p>
          <p>RichDevil9</p>
          <p>pkill2000</p>
          <p>hytk7004</p>
          <p>jizorn</p>
          <p>Lee123</p>
          <p>d5476004</p>
          <p>kimzeus2616</p>
          <p>ralsls1541</p>
          <p>free8259</p>
          <p>ultrasun</p>
        </div>
      </div>
      <div class="event_apply_wrap">
        <div class="text_box">
          <h2>세번째 공간?</h2>
          <p>
            세번째 공간은 국내 최초 디지털 무빙 아트 전문 갤러리로<br
              class="d_none"
            />
            다양한 기술을 접목시킨 미술 작품들을 감상하고 소장할 수 있습니다.<br />
            기술의 발전으로 새로운 창조 과정이 구축되고 일상과 미술의 경계가
            사라지고 있는 가운데 <br class="d_none" />
            나에게 맞는 디지털 작품들을 발견하고 보존할 수 있는 공간입니다.
          </p>
          <p class="italic_box">
            “Wake up Artists...”
          </p>
          <div class="button_box">
            <b-button @click="onClickApply">
              아티스트로 참여하기
            </b-button>
          </div>
        </div>
      </div>
      <div class="event_notice_wrap">
        <div class="text_box">
          <h1>
            이벤트 유의사항 :<br />
            <span>클레이 지급 및 한정판 NFT 지급 유의사항!</span>
          </h1>
          <p>
            1. 클레이튼 지갑 주소를 입력하여야 클레이 및 한정판 NFT가
            지급됩니다.<br />
            2. 클레이 지급 시점까지 클립 지갑등록 및 회원상태를 유지해야 이벤트
            혜택 정상지급이 가능합니다.<br />
            3. 클립 지갑주소 등록방법은 세번째공간 카카오톡 채널을
            참고해주세요.<br />
            4. 이벤트는 당사의 사정에 따라 사전 고지 없이 변경되거나 조기 종료될
            수 있습니다.<br />
            5. 부정한 방법으로 참여하거나 이벤트의 원활한 운영에 방해가 되는
            행위를 하는 참여자는 이벤트 혜택 대상에서 제외될 수 있습니다.<br />
            6. 이벤트 참여자가 커뮤니티, SNS 등에 공유한 링크로 인해 발생한 일에
            대해 당사는 책임지지 않습니다.
          </p>
        </div>
      </div>
    </div>

    <!-- 사전 등록하기(이벤트 참여) 완료 -->
    <b-modal
      ref="event-success-modal"
      hide-header
      hide-footer
      centered
      no-close-on-esc
      no-close-on-backdrop
      body-class="text-center text-black py-5"
    >
      <b-img src="/img/threespace/logo/logo_100x100.png"></b-img>
      <p class="font-lg font-weight-bold">
        {{ eventSuccessMessage }}
      </p>
      <p>
        클레이 지급을 위해서 지갑 등록과정을 완료해주세요!
      </p>
      <p>
        <a href="http://pf.kakao.com/_tRkTs" target="_blank">
          <u>지갑 등록 알아보기</u>
        </a>
      </p>
      <b-button
        variant="primary"
        size="lg"
        squared
        class="mt-3 px-5"
        @click="hideEventSuccessModal()"
      >
        <span class="text-white">확인</span>
      </b-button>
    </b-modal>

    <!-- 이벤트 회원가입 팝업 -->
    <EventJoinPopup ref="eventJoinModal" :event-info="eventInfo" />

    <!-- 이벤트 공유하기 -->
    <CToaster :position="'bottom-center'" :autohide="2000">
      <template v-for="toast in toasts">
        <CToast :key="'toast' + toast" :show="true" class="text-center"
          >해당 링크가 복사되었습니다.</CToast
        >
      </template>
    </CToaster>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createNamespacedHelpers } from "vuex";

const eventHelper = createNamespacedHelpers("event");
import EventJoinPopup from "@/views/threespace/event/EventJoinPopup.vue";

export default {
  name: "Event01",
  components: {
    EventJoinPopup
  },
  data: function() {
    return {
      form: {
        idxEvent: 1,
        idxMember: null
      },
      eventSuccessMessage: "이벤트 참여가 완료되었습니다 !",
      eventLink: window.document.location.href,
      toasts: 0
    };
  },
  computed: {
    ...eventHelper.mapState(["eventInfo", "eventResultInfo"]),
    ...mapState({
      me: state => {
        return state.auth.me || {};
      }
    }),
    isParticipatedEvent() {
      return this.$store.state.isParticipatedEvent;
    },
    isMobile() {
      return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    },
    isWebShareSupported() {
      return navigator.share;
    }
  },
  mounted() {
    this.scrollToElement();
    this.requestEvent();
    this.requestEventResult();
  },
  methods: {
    ...eventHelper.mapActions([
      "getEvent",
      "getEventResult",
      "addEventResult",
      "addEventShareLink"
    ]),
    scrollToElement() {
      const el = document.getElementById("EventWinnerNotice");

      if (el) {
        // Use el.scrollIntoView() to instantly scroll to the element
        el.scrollIntoView({ behavior: "smooth" });
      }
    },
    requestEvent() {
      // 이벤트 정보 조회
      this.getEvent(this.form).then(() => {
        if (process.env.NODE_ENV === "production") {
          if (
            this.eventInfo.status !== undefined &&
            this.eventInfo.status === "EVENT_END"
          ) {
            this.$store.commit("set", ["isParticipatedEvent", true]);
          }
        }
      });
    },
    requestEventResult() {
      // 이벤트 결과 정보 조회(사용자가 로그인 상태인 경우)
      if (
        this.me.idx !== undefined &&
        this.me.idx !== null &&
        this.me.idx !== "" &&
        this.me.idx !== "0"
      ) {
        // 로그인 상태인 경우 이벤트 참여결과 조회
        this.form.idxMember = this.me.idx;
        this.getEventResult(this.form).then(() => {
          // 이벤트 기 참여여부
          if (
            this.eventResultInfo.participated !== undefined &&
            this.eventResultInfo.participated === true
          ) {
            this.$store.commit("set", ["isParticipatedEvent", true]);
          }
        });
      }
    },
    onClickEventSubmit() {
      //this.$log.log(this.eventInfo);
      const eventStatus = this.eventInfo.status;
      if (eventStatus === "EVENT_PREPARING") {
        alert(
          "이벤트 준비중입니다. 이벤트 기간내에 참여 가능합니다. 감사합니다."
        );
      } else if (eventStatus === "EVENT_EARLY_END") {
        alert(
          "클레이가 전량 소진되어 한정판 NFT 추첨 이벤트 참여만 가능합니다. 감사합니다."
        );
      } else if (eventStatus === "EVENT_END") {
        alert(
          "이벤트가 종료되었습니다. 현재 순차적으로 클레이를 지급하고 있습니다. 감사합니다."
        );
      }
      if (eventStatus === "EVENT_PREPARING" || eventStatus === "EVENT_END") {
        return false;
      }
      if (
        this.me.idx !== undefined &&
        this.me.idx !== null &&
        this.me.idx !== "" &&
        this.me.idx !== "0"
      ) {
        this.form.idxMember = this.me.idx;
        this.getEventResult(this.form).then(() => {
          if (
            this.eventResultInfo.participated !== undefined &&
            this.eventResultInfo.participated === true
          ) {
            // 이벤트 기 참여완료
            this.$store.commit("set", ["isParticipatedEvent", true]);
            this.eventSuccessMessage = "이미 이벤트 참여가 완료되었습니다.";
            this.$refs["event-success-modal"].show();
          } else {
            // 이벤트 참여하기
            const eventResult = {
              idxEvent: this.form.idxEvent,
              idxMember: this.me.idx,
              participated: true,
              acceptTermsService: true,
              acceptPrivacyPolicy: true,
              acceptPromotion: true
            };
            this.addEventResult(eventResult)
              .then(() => {
                this.$store.commit("set", ["isParticipatedEvent", true]);
                this.eventSuccessMessage = "이벤트 참여가 완료되었습니다 !";
                this.$refs["event-success-modal"].show();
              })
              .catch(() => {
                alert(
                  "이벤트 참여 오류가 발생하였습니다. 나중에 다시 시도해 주세요."
                );
              });
          }
        });
      } else {
        this.$refs["eventJoinModal"].show();
      }
    },
    onClickShareLink() {
      // 이벤트 공유하기 이력 저장
      const eventShareLinkInfo = {
        idxEvent: this.form.idxEvent,
        idxMember: this.me.idx,
        shared: true
      };

      if (this.isMobile) {
        const title = "3space Art";
        if (this.isWebShareSupported) {
          navigator
            .share({
              title: title,
              text:
                "(세번째공간 X 클레이튼) 가입만해도 무조건 지급! 공유하고 한정판 NFT 받기!",
              url: this.eventLink
            })
            .then(() => {
              this.addEventShareLink(eventShareLinkInfo);
            });
        } else {
          alert(
            "공유하기를 지원하지 않는 환경입니다.\r\n모바일 기기에서 이용해 주세요."
          );
        }
      } else {
        let hiddenEventLink = document.querySelector("#hiddenEventLink");
        hiddenEventLink.setAttribute("type", "text");
        hiddenEventLink.select();
        try {
          document.execCommand("copy");
          this.toasts++;

          this.addEventShareLink(eventShareLinkInfo);
        } catch (e) {}
        hiddenEventLink.setAttribute("type", "hidden");
        window.getSelection().removeAllRanges();
      }
    },
    onClickApply() {
      this.$router.push("/apply");
    },
    hideEventSuccessModal() {
      this.$refs["event-success-modal"].hide();
    }
  }
};
</script>
