<template>
  <CCard>
    <CCardBody>
      <CRow>
        <CCol class="mb-3"><h5>주문 상세조회</h5></CCol>
      </CRow>
      <CForm>
        <CRow>
          <CCol col="12">
            <!-- 작품 / 구매자 정보 -->
            <div class="mb-2">
              <v-icon>mdi-arrow-right-circle</v-icon>
              <span class="ml-2 font-weight-bold">작품 / 구매자 정보</span>
            </div>
            <b-table-simple small responsive="true">
              <colgroup>
                <col />
                <col />
                <col />
                <col />
              </colgroup>
              <b-tbody>
                <b-tr>
                  <b-th>작품명</b-th>
                  <b-td>{{ orderInfo.product.productName }}</b-td>
                  <b-th>작품번호</b-th>
                  <b-td>{{ orderInfo.idxProduct }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th>구매자명</b-th>
                  <b-td>{{ orderInfo.buyerName }}</b-td>
                  <b-th>회원번호</b-th>
                  <b-td>{{ orderInfo.idxMember }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th class="border-bottom">구매자 연락처</b-th>
                  <b-td class="border-bottom">{{ orderInfo.buyerTel }}</b-td>
                  <b-th class="border-bottom">구매자 이메일</b-th>
                  <b-td class="border-bottom">{{ orderInfo.buyerEmail }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>

            <!-- 주문 정보 -->
            <div class="mt-4 mb-2">
              <v-icon>mdi-arrow-right-circle</v-icon>
              <span class="ml-2 font-weight-bold">주문 정보</span>
            </div>
            <b-table-simple small responsive="true">
              <tbody>
                <b-tr>
                  <b-th>상품명</b-th>
                  <b-td>{{ orderInfo.goodsName }}</b-td>
                  <b-th>상품가격</b-th>
                  <b-td>{{ orderInfo.amt | currency }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th>주문일시</b-th>
                  <b-td>{{ orderInfo.orderDate }}</b-td>
                  <b-th>PG사</b-th>
                  <b-td>{{ orderInfo.pg }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th>주문번호</b-th>
                  <b-td>{{ orderInfo.moid }}</b-td>
                  <b-th>결제구분</b-th>
                  <b-td>{{ orderInfo.payType }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th>거래 ID</b-th>
                  <b-td>{{ orderInfo.tid }}</b-td>
                  <b-th>상점 ID</b-th>
                  <b-td>{{ orderInfo.mid }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th>전문 생성일시</b-th>
                  <b-td>{{ orderInfo.ediDate }}</b-td>
                  <b-th>처리일시</b-th>
                  <b-td>{{ orderInfo.authDate }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th>가상계좌 은행코드</b-th>
                  <b-td>{{ orderInfo.vbankBankCode }}</b-td>
                  <b-th>입금 계좌번호</b-th>
                  <b-td>{{ orderInfo.vbankNum }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th>입금 예금주명</b-th>
                  <b-td>{{ orderInfo.vbankAccountName }}</b-td>
                  <b-th>입금 마감일자/시간</b-th>
                  <b-td>{{ orderInfo.vbankExpDate }} {{ orderInfo.vbankExpTime }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th>현금영수증 용도구분</b-th>
                  <b-td>{{ orderInfo.receiptType }}</b-td>
                  <b-th>현금영수증 발급번호</b-th>
                  <b-td>{{ orderInfo.receiptTypeNo }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th class="border-bottom">결과 코드</b-th>
                  <b-td class="border-bottom">{{ orderInfo.resultCode }}</b-td>
                  <b-th class="border-bottom">결과 메시지</b-th>
                  <b-td class="border-bottom">{{ orderInfo.resultMsg }}</b-td>
                </b-tr>
              </tbody>
            </b-table-simple>

            <!-- 결제 정보 -->
            <div class="mt-4 mb-2">
              <v-icon>mdi-arrow-right-circle</v-icon>
              <span class="ml-2 font-weight-bold">결제 정보</span>
            </div>
            <b-table-simple small responsive="true" class="mb-0">
              <tbody>
                <b-tr>
                  <b-th>승인번호</b-th>
                  <b-td>{{ orderInfo.payAuthCode }}</b-td>
                  <b-th>입금일시</b-th>
                  <b-td>{{ orderInfo.payAuthDate }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th>은행명(은행코드)</b-th>
                  <b-td>{{ orderInfo.fnName }}({{ orderInfo.fnCd }})</b-td>
                  <b-th></b-th>
                  <b-td></b-td>
                </b-tr>
                <b-tr>
                  <b-th>현금영수증 승인번호</b-th>
                  <b-td>{{ orderInfo.rcptAuthCode }}</b-td>
                  <b-th>현금영수증 TID</b-th>
                  <b-td>{{ orderInfo.rcptTid }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th>결과 메시지(코드)</b-th>
                  <b-td>{{ orderInfo.payResultMsg }}({{ orderInfo.payResultCode }})</b-td>
                  <b-th>거래상태</b-th>
                  <b-td>{{ orderInfo.stateCd }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th class="border-bottom">가상계좌 예금주명</b-th>
                  <b-td class="border-bottom">{{ orderInfo.vbankInputName }}</b-td>
                  <b-th class="border-bottom">가상계좌 은행명</b-th>
                  <b-td class="border-bottom">{{ orderInfo.vbankName }}</b-td>
                </b-tr>
              </tbody>
            </b-table-simple>
            <div class="mt-2 p-2 border-dark">
              <p>
                <b>관리자 메모</b><br/>
                {{ orderInfo.adminMemo || '(정보없음)' }}
              </p>
              <p class="mb-0">
                <b>Payload</b><br/>
                {{ orderInfo.payload || '(정보없음)' }}
              </p>
            </div>

            <!-- 취소/환불 -->
            <template v-if="orderInfo.stateCd === '0'">
              <div class="mt-4 mb-2">
                <v-icon>mdi-arrow-right-circle</v-icon>
                <span class="ml-2 font-weight-bold text-info">취소/환불</span>
              </div>
              <b-table-simple small responsive="true" class="mb-0">
                <tbody>
                  <b-tr>
                    <b-th>취소 금액</b-th>
                    <b-td>{{ orderInfo.amt | currency }}</b-td>
                    <b-th>환불 계좌은행</b-th>
                    <b-td>
                      <CSelect
                        class="mb-0"
                        placeholder="환불 계좌은행을 선택해주세요."
                        :options="bankOptions"
                        :value.sync="$v.orderPaymentCancelInfo.refundBankCd.$model"
                        :isValid="checkIfValid('refundBankCd')"
                        invalidFeedback="필수 정보입니다."
                      />
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-th>환불 계좌번호</b-th>
                    <b-td>
                      <CInput
                        class="mb-0"
                        placeholder="환불 계좌번호를 입력해주세요. ('-' 없이 입력)"
                        :lazy="false"
                        :value.sync="$v.orderPaymentCancelInfo.refundAcctNo.$model"
                        :isValid="checkIfValid('refundAcctNo')"
                        invalidFeedback="필수 정보입니다."
                      />
                    </b-td>
                    <b-th>환불 계좌 예금주명</b-th>
                    <b-td>
                      <CInput
                        class="mb-0"
                        placeholder="환불 계좌 예금주명을 입력해주세요."
                        :lazy="false"
                        :value.sync="$v.orderPaymentCancelInfo.refundAcctNm.$model"
                        :isValid="checkIfValid('refundAcctNm')"
                        invalidFeedback="필수 정보입니다."
                      />
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-th>취소 사유</b-th>
                    <b-td colspan="3">
                      <CTextarea
                        class="mb-0"
                        placeholder="취소 사유를 입력하세요."
                        rows="2"
                        :lazy="false"
                        :value.sync="$v.orderPaymentCancelInfo.cancelMsg.$model"
                        :isValid="checkIfValid('cancelMsg')"
                        invalidFeedback="필수 정보입니다."
                      />
                    </b-td>
                  </b-tr>
                </tbody>
              </b-table-simple>
              <div class="text-danger">
                <CIcon name="cil-info" class="mr-2" />
                환불은 등록한 계좌번호로 별도의 확인 절차없이 지급됩니다. 은행명, 예금주, 계좌번호를 한번더 확인해 주세요.
              </div>
              <div class="mt-2">
                <CButton color="info" @click="onClickCancelInfoSave()" class="text-white mr-2" :disabled="orderInfo.status === 'MANAGER_CANCEL_SUCCESS'">
                  취소/환불 정보 저장
                </CButton>
                <CButton color="danger" @click="onClickCancelRequest()" class="text-white" :disabled="orderInfo.status === 'MANAGER_CANCEL_SUCCESS'">
                  취소/환불 요청
                </CButton>
              </div>

              <template v-if="orderPaymentCancelInfo.idx !== undefined && orderPaymentCancelInfo.idx > 0">
                <b-table-simple small responsive="true" class="mt-3 mb-0">
                  <tbody>
                  <b-tr>
                    <b-th>요청일시</b-th>
                    <b-td>{{ orderInfo.orderPaymentCancel.requestDate }}</b-td>
                    <b-th>거래ID</b-th>
                    <b-td>{{ orderInfo.orderPaymentCancel.tid }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-th>주문번호</b-th>
                    <b-td>{{ orderInfo.orderPaymentCancel.moid }}</b-td>
                    <b-th>취소금액</b-th>
                    <b-td>{{ orderInfo.orderPaymentCancel.cancelAmt }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-th>결과 메시지(코드)</b-th>
                    <b-td>{{ orderInfo.orderPaymentCancel.resultMsg }}({{ orderInfo.orderPaymentCancel.resultCode }})</b-td>
                    <b-th>오류 메시지(코드)</b-th>
                    <b-td>{{ orderInfo.orderPaymentCancel.errorMsg }}({{ orderInfo.orderPaymentCancel.errorCd }})</b-td>
                  </b-tr>
                  <b-tr>
                    <b-th>취소일시</b-th>
                    <b-td>{{ orderInfo.orderPaymentCancel.cancelDate }} {{ orderInfo.orderPaymentCancel.cancelTime }}</b-td>
                    <b-th>취소번호</b-th>
                    <b-td>{{ orderInfo.orderPaymentCancel.cancelNum }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-th class="border-bottom">상태</b-th>
                    <b-td colspan="3" class="border-bottom">{{ orderInfo.orderPaymentCancel.status }}</b-td>
                  </b-tr>
                  </tbody>
                </b-table-simple>
              </template>
            </template>
          </CCol>
        </CRow>
        <CRow>
          <CCol col="12" class="text-center my-4">
            <CButton color="light" @click="onClickCancel()" class="mr-2">
              <CIcon name="cil-x-circle" class="mr-2" />목록
            </CButton>
          </CCol>
        </CRow>
      </CForm>
    </CCardBody>
  </CCard>
</template>

<script>
import { validationMixin } from "vuelidate";
import {numeric, required} from "vuelidate/lib/validators";
import { createNamespacedHelpers } from "vuex";
const orderHelper = createNamespacedHelpers("order");

export default {
  name: "OrderDetail",
  components: {},
  props: {
    idxOrderPayment: {
      type: String,
      default: "0"
    }
  },
  data: function() {
    return {
      orderPaymentCancelInfo: {
        idx: 0,
        idxOrderPayment: this.idxOrderPayment,
        refundBankCd: null,
        refundAcctNo: null,
        refundAcctNm: null,
        cancelMsg: null
      },
      bankOptions: [
        { value: "001", label: "한국은행" },
        { value: "002", label: "산업은행" },
        { value: "003", label: "기업은행" },
        { value: "004", label: "국민은행" },
        { value: "005", label: "외환은행" },
        { value: "007", label: "수협중앙회" },
        { value: "008", label: "수출입은행" },
        { value: "011", label: "농협중앙회" },
        { value: "012", label: "농협회원조합" },
        { value: "020", label: "우리은행" },
        { value: "023", label: "SC은행" },
        { value: "026", label: "서울은행" },
        { value: "027", label: "한국씨티은행" },
        { value: "031", label: "대구은행" },
        { value: "032", label: "부산은행" },
        { value: "034", label: "광주은행" },
        { value: "035", label: "제주은행" },
        { value: "037", label: "전북은행" },
        { value: "039", label: "경남은행" },
        { value: "045", label: "새마을금고연합회" },
        { value: "048", label: "신협중앙회" },
        { value: "050", label: "상호저축은행" },
        { value: "051", label: "기타외국계은행" },
        { value: "052", label: "모건스탠리은행" },
        { value: "054", label: "HSBC은행" },
        { value: "055", label: "도이치은행" },
        { value: "056", label: "알비에스피엘씨은행" },
        { value: "057", label: "제이피모간체이스은행" },
        { value: "058", label: "미즈호코퍼레이트은행" },
        { value: "059", label: "미쓰비시도쿄UFJ은행" },
        { value: "060", label: "BOA" },
        { value: "061", label: "비엔피파리바은행" },
        { value: "062", label: "중국공상은행" },
        { value: "063", label: "중국은행" },
        { value: "064", label: "산림조합" },
        { value: "065", label: "대화은행" },
        { value: "071", label: "정보통신부우체국" },
        { value: "076", label: "신용보증기금" },
        { value: "077", label: "기술신용보증기금" },
        { value: "081", label: "하나은행" },
        { value: "088", label: "신한은행" },
        { value: "089", label: "케이뱅크" },
        { value: "090", label: "카카오뱅크" },
        { value: "093", label: "한국주택금융공사" },
        { value: "094", label: "서울보증보험" },
        { value: "095", label: "경찰청" },
        { value: "099", label: "금융결제원" },
        { value: "209", label: "동양종합금융증권" },
        { value: "218", label: "현대증권" },
        { value: "230", label: "미래에셋증권" },
        { value: "238", label: "대우증권" },
        { value: "240", label: "삼성증권" },
        { value: "243", label: "한국투자증권" },
        { value: "247", label: "NH투자증권" },
        { value: "261", label: "교보증권" },
        { value: "262", label: "하이투자증권" },
        { value: "263", label: "에이치엠씨투자증권" },
        { value: "264", label: "키움증권" },
        { value: "265", label: "이트레이드증권" },
        { value: "266", label: "에스케이증권" },
        { value: "267", label: "대신증권" },
        { value: "268", label: "솔로몬투자증권" },
        { value: "269", label: "한화증권" },
        { value: "270", label: "하나대투증권" },
        { value: "278", label: "신한금융투자" },
        { value: "279", label: "동부증권" },
        { value: "280", label: "유진투자증권" },
        { value: "287", label: "메리츠증권" },
        { value: "289", label: "엔에이치투자증권" },
        { value: "290", label: "부국증권" },
        { value: "291", label: "신영증권" },
        { value: "292", label: "엘아이지투자증권" }
      ]
    };
  },
  computed: {
    ...orderHelper.mapState(["orderInfo"]),
    isValid() {
      return !this.$v.orderPaymentCancelInfo.$invalid;
    },
    isDirty() {
      return this.$v.orderPaymentCancelInfo.$anyDirty;
    }
  },
  mixins: [validationMixin],
  validations: {
    orderPaymentCancelInfo: {
      refundBankCd: { required },
      refundAcctNo: { required, numeric },
      refundAcctNm: { required },
      cancelMsg: { required }
    }
  },
  mounted() {
    this.requestOrderItem();
  },
  methods: {
    ...orderHelper.mapActions(["getOrder", "saveCancel", "requestCancel"]),
    requestOrderItem() {
      if (this.idxOrderPayment && this.idxOrderPayment !== "0") {
        this.getOrder(this.idxOrderPayment).then(data => {
          this.$log.log(data); //TODO
          if (this.orderInfo.orderPaymentCancel !== undefined && this.orderInfo.orderPaymentCancel != null) {
            const orderPaymentCancel = this.orderInfo.orderPaymentCancel;
            this.orderPaymentCancelInfo = {
              idx: orderPaymentCancel.idx,
              idxOrderPayment: this.idxOrderPayment,
              refundBankCd: orderPaymentCancel.refundBankCd,
              refundAcctNo: orderPaymentCancel.refundAcctNo,
              refundAcctNm: orderPaymentCancel.refundAcctNm,
              cancelMsg: orderPaymentCancel.cancelMsg
            };
          }
        });
      }
    },
    onClickCancelInfoSave() {
      this.$v.$touch();
      if (this.isValid) {
        this.saveCancel(this.orderPaymentCancelInfo).then(() => {
          alert("저장되었습니다.");
        }).catch(error => {
          this.$log.error(error);
          alert("취소/환불 정보 저장 오류가 발생하였습니다. 관리자에게 문의하세요.");
        });
      }
    },
    onClickCancelRequest() {
      this.$v.$touch();
      if (this.isValid) {
        this.requestCancel(this.orderPaymentCancelInfo).then(() => {
          alert("저장되었습니다.");
        }).catch(error => {
          this.$log.error(error);
          alert("취소/환불 요청 오류가 발생하였습니다. 관리자에게 문의하세요.");
        });
      }
    },
    onClickCancel() {
      this.$router.push("/management/order-list");
    },
    checkIfValid(fieldName) {
      const field = this.$v.orderPaymentCancelInfo[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    }
  },
  filters: {
    currency: value => {
      if (!value) return "";
      return parseInt(value)
          .toFixed(0)
          .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    }
  }
};
</script>

<style scoped>
.table-sm th,
.table-sm td {
  padding: 0.3rem;
}
</style>
