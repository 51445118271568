<template>
  <div>
    <b-modal
      id="payment-klip-modal-3"
      ref="payment-klip-modal-3"
      class="payment_complate_modal_wrap"
      centered
      hide-header
      hide-footer
    >
      <CRow>
        <CCol class="col-12 py-2">
          <div class="d-flex justify-content-end">
            <img
              class="cursor-pointer"
              src="/img/threespace/icon/close.png"
              alt="close"
              @click="$refs['payment-klip-modal-3'].hide()"
            />
          </div>
        </CCol>
      </CRow>

      <div class="payment_complete_modal">
        <div class="title">
          <p v-html="$t('payment.completePayment')"></p>
        </div>
        <div class="content">
          <b-table-simple borderless>
            <!-- 작품명 -->
            <tr>
              <th>{{ $t("payment.artwork.title") }}</th>
              <td>
                {{ paymentResultInfo.goodsName }}
              </td>
            </tr>
            <!-- 소유주 -->
            <tr>
              <th>{{ $t("payment.klip3.ownership") }}</th>
              <td>
                <a
                  v-if="paymentResultInfo.klaytnScopeUrl !== ''"
                  :href="paymentResultInfo.klaytnScopeUrl"
                  target="_blank"
                  style="cursor: pointer"
                >
                  {{ $t("payment.klip3.klaytnScope") }}
                </a>
                <p v-else>
                  {{ $t("payment.klip3.klaytnScope") }}
                </p>
              </td>
            </tr>
            <!-- 가격 -->
            <tr>
              <th>{{ $t("payment.artwork.price") }}</th>
              <td>
                {{ paymentResultInfo.amountCoin | currency }}
                {{ paymentResultInfo.currency }}
              </td>
            </tr>
            <!-- 주문일 -->
            <tr>
              <th>{{ $t("payment.klip3.orderDate") }}</th>
              <td>
                {{
                  paymentResultInfo.orderDate | moment("YYYY-MM-DD HH:mm:ss")
                }}
              </td>
            </tr>
          </b-table-simple>
        </div>
        <!-- <div class="btn_box">
          <b-button class="goCollection" @click="goMyPage">
            {{ $t("payment.goto.myPage") }}
          </b-button>
          <b-button class="goArtpool" @click="goArtpool">
            {{ $t("payment.goArtPool") }}
          </b-button>
        </div> -->
        <div class="d-flex justify-content-center">
          <div
            class="mt-3"
            style="width: 170px; max-width: 170px; margin-right: 10px"
          >
            <Button :label="$t('payment.goto.myPage')" :onClick="goMyPage" />
          </div>
          <div class="mt-3" style="width: 170px; max-width: 170px">
            <Button :label="$t('payment.goArtPool')" :onClick="goArtpool" />
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Button from "@/views/threespace/components/common/Button.vue";

export default {
  name: "PaymentKlip3",
  components: {
    Button,
  },
  props: {
    paymentResultInfo: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {};
  },
  computed: {
    ...mapState({
      me: (state) => {
        return state.auth.me || {};
      },
    }),
  },
  mounted() {},
  methods: {
    show() {
      this.$refs["payment-klip-modal-3"].show();
    },
    hide() {
      this.$router.push({ name: "Drops" });
      this.$refs["payment-klip-modal-3"].hide();
    },
    goMyPage() {
      if (this.me.idx > 0) {
        this.$router.push({
          name: "MyPage",
          params: { idxMember: this.me.idx, activeTab: 2 },
        });
      } else {
        this.$router.push({ name: "Drops" });
      }
      this.$refs["payment-klip-modal-3"].hide();
    },
    goDrops() {
      this.$router.push({ name: "Drops" });
      this.$refs["payment-klip-modal-3"].hide();
    },
    goArtpool() {
      this.$router.push({ name: "NftPool" });
      this.$refs["payment-klip-modal-3"].hide();
    },
  },
  filters: {
    currency: (value) => {
      if (!value) return "";
      return parseInt(value)
        .toFixed(0)
        .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    },
  },
};
</script>
