<template>
  <div id="Hashmask">
    <div class="video_wrap">
      <div class="video_box">
        <video :poster="no === '1' ? poster1 : poster2" autoplay loop muted playsinline controls controlsList="nodownload">
          <source :src="no === '1' ? video1 : video2" type="video/mp4">
          Your browser does not support the video tag or format.
        </video>
        <!--
        <video
          :poster="no === '1' ? poster1 : poster2"
          :src="no === '1' ? video1 : video2"
          type="video/webm"
          muted
          loop
          autoplay
          playsinline
        ></video>
        -->
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {},
  props: {
    no: {
      type: String,
      default: "1"
    }
  },
  data() {
    return {
      video1: "/img/threespace/event/hashmask.mp4",
      video2: "/img/threespace/event/hashmask_DAO.mp4",
      poster1: "/img/threespace/event/poster_hashmask.jpg",
      poster2: "/img/threespace/event/poster_haskmask_DAO.jpg"
    };
  },
  computed: {
  },
  mounted() {
  },
  methods: {
  }
};
</script>
