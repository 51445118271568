<template>
  <CCard>
    <CCardBody>
      <CRow>
        <CCol class="mb-3"><h5>사용자 관리</h5></CCol>
      </CRow>
      <CRow>
        <CCol class="pt-2">
          <table class="table">
            <tbody>
              <tr>
                <th class="border-bottom bg-light text-dark">검색</th>
                <td class="border-bottom">
                  <CSelect
                    class="col-2 float-left px-0 mb-0"
                    :options="searchOptions"
                    :value.sync="searchSelected"
                    @change="onChangeSearchOption"
                  />
                  <CInput
                    class="col-6 float-left mb-0"
                    placeholder="검색어를 입력해주세요."
                    v-model="externalParams.searchKeyword"
                    @input="onChangeSearchKeyword"
                    @keypress.enter.prevent="onClickSearch"
                  />
                  <CButton color="dark" class="text-white" @click="onClickSearch">
                    <CIcon name="cil-search" class="mr-2" />검색
                  </CButton>
                  <CButton
                    variant="outline"
                    color="secondary"
                    class="ml-2"
                    @click="onClickReset"
                  >
                    <CIcon name="cil-filter-x" />
                  </CButton>
                </td>
              </tr>
            </tbody>
          </table>
        </CCol>
      </CRow>
      <CRow>
        <CCol class="col-auto mr-auto align-self-center">
          총&nbsp;<strong>{{ memberTableItems.totalElements }}</strong>건&nbsp;({{ currentPage }}&nbsp;/&nbsp;{{ memberTableItems.totalPages }}&nbsp;페이지)
        </CCol>
        <CCol class="col-auto">
          <CSelect
            class="float-left mr-2 mb-2"
            :options="pageOptions"
            :value.sync="perPage"
            @change="onPaging"
          />
          <!--
          <CButton color="info" @click="onClickRegister">
            <CIcon name="cil-library-add" class="mr-2" />신규등록
          </CButton>
          -->
        </CCol>
      </CRow>
      <b-table
        id="memberTable"
        thead-class="text-center"
        :busy="isBusy"
        :items="memberTableItems.content"
        :fields="memberTableFields"
        :items-per-page="perPage"
        show-empty
        empty-text="조회된 내역이 없습니다."
      >
        <!-- NO -->
        <template #cell(index)="data">
          {{ data.index + 1 + (currentPage - 1) * perPage }}
        </template>

        <!-- 이름 -->
        <template #cell(name)="data">
          {{ data.item.name === undefined ? "(정보없음)" : data.item.name }}
        </template>

        <!-- 역할 -->
        <template #cell(roles)="data">
          <template v-for="(role, i) in data.item.roles">
            {{ i > 0 ? ", " + role.name : role.name }}
          </template>
        </template>

        <!-- 활성여부 -->
        <template #cell(identityVerify)="data">
          {{ data.value === true ? "Y" : "N" }}
        </template>

        <!-- Actions -->
        <template v-slot:cell(actions)="data">
          <!--
          <CButton
            variant="outline"
            color="dark"
            size="sm"
            @click="onClickDelete(data.item.idx)"
          >
            <CIcon name="cil-trash" />
          </CButton>
          -->
          <CButton
            variant="outline"
            color="dark"
            size="sm"
            class="mx-2"
            @click="onClickModify(data.item.idx)"
          >
            <CIcon name="cil-pencil" />
          </CButton>
        </template>
      </b-table>
      <b-pagination
        aria-controls="memberTable"
        align="center"
        hide-ellipsis
        limit="10"
        v-model="currentPage"
        :total-rows="memberTableItems.totalElements"
        :per-page="perPage"
        :current-page="currentPage"
        v-on:input="onPaging"
      ></b-pagination>
    </CCardBody>
  </CCard>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const memberHelper = createNamespacedHelpers("member");

export default {
  name: "MemberList",
  components: {},
  data: function() {
    return {
      isBusy: false,
      perPage: 10,
      pageOptions: [
        { value: 10, label: "10 개씩 보기" },
        { value: 20, label: "20 개씩 보기" },
        { value: 30, label: "30 개씩 보기" },
        { value: 50, label: "50 개씩 보기" }
      ],
      currentPage: 1,
      sort: [
        { id: "idx", desc: "desc" },
        { id: "updatedAt", desc: "desc" }
      ],
      sortBy: "",
      sortDesc: false,
      // 검색
      searchSelected: "",
      searchOptions: [
        { value: "", label: "전체" },
        { value: "keywordIdx", label: "회원번호" },
        { value: "keywordName", label: "이름" },
        { value: "keywordNickname", label: "사용자 이름" },
        { value: "keywordRole", label: "역할" },
        { value: "keywordMobile", label: "연락처" },
        { value: "keywordEmail", label: "이메일" },
        { value: "keywordReferral", label: "레퍼럴" }
      ],
      externalParams: this.getInitExternalParams()
    };
  },
  computed: {
    ...memberHelper.mapState(["memberTableItems", "memberTableFields"])
  },
  mounted() {
    this.requestMemberItems();
  },
  methods: {
    ...memberHelper.mapActions(["getMembers"]),
    onPaging() {
      this.requestMemberItems();
    },
    onChangeSearchOption() {
      this.setSearchConditions(this.externalParams.searchKeyword);
    },
    onChangeSearchKeyword(keyword) {
      this.setSearchConditions(keyword);
    },
    setSearchConditions(keyword) {
      this.externalParams = this.getInitExternalParams();
      if (this.searchSelected === "") {
        this.externalParams.searchKeyword = keyword;
      } else if (this.searchSelected === "keywordIdx") {
        this.externalParams.keywordIdx = keyword;
      } else if (this.searchSelected === "keywordName") {
        this.externalParams.keywordName = keyword;
      } else if (this.searchSelected === "keywordNickname") {
        this.externalParams.keywordNickname = keyword;
      } else if (this.searchSelected === "keywordRole") {
        this.externalParams.keywordRole = keyword;
      } else if (this.searchSelected === "keywordMobile") {
        this.externalParams.keywordMobile = keyword;
      } else if (this.searchSelected === "keywordEmail") {
        this.externalParams.keywordEmail = keyword;
      } else if (this.searchSelected === "keywordReferral") {
        this.externalParams.keywordReferral = keyword;
      }
    },
    resetSearchConditions() {
      this.searchSelected = "";
      this.externalParams = this.getInitExternalParams();
    },
    getInitExternalParams() {
      return {
        searchKeyword : "",
        keywordIdx : "",
        keywordName : "",
        keywordNickname : "",
        keywordRole : "",
        keywordMobile : "",
        keywordEmail : "",
        keywordReferral: ""
      }
    },
    onClickSearch() {
      this.requestMemberItems();
    },
    onClickReset() {
      this.resetSearchConditions();
      this.requestMemberItems();
    },
    requestMemberItems() {
      this.$log.info(
        "requestMemberItems() this.externalParams=",
        this.externalParams
      );
      this.isBusy = true;
      this.getMembers(
        this.getPagingParams(
          {
            page: this.currentPage - 1,
            pageSize: this.perPage,
            sorted: this.sort,
            filtered: []
          },
          this.externalParams
        )
      )
        .then(data => {
          this.isBusy = false;
        })
        .catch(error => {
          this.isBusy = false;
          this.$log.error(error);
        });
    },
    onClickRegister() {
      this.$log.info("onClickRegister");
      this.$router.push("/management/member-save/0");
    },
    onClickModify(idx) {
      this.$log.info("click onClickModify() idx:{}", idx);
      this.$router.push("/management/member-save/" + idx);
    },
    onClickDelete(idx) {
      this.$log.info("click onClickDelete() idx:{}", idx);
    },
    onClickMemberName(idx) {
      this.$log.info("click onClickMemberName() idx:{}", idx);
      this.$router.push("/management/member-detail/" + idx);
    },
    getPagingParams({ page, pageSize, sorted, filtered }, externalParams) {
      const params = {
        page,
        size: pageSize,
        ...externalParams
      };

      // sorting parameter
      let sorts = [];
      for (let i = 0, length = sorted.length; i < length; i++) {
        const sort = sorted[i];
        sorts.push(`${sort.id},${sort.desc ? "desc" : "asc"}`);
      }
      params["sort"] = sorts;

      // filtering parameter
      for (let i = 0, length = filtered.length; i < length; i++) {
        const filter = filtered[i];
        params[filter.id] = filter.value;
      }

      return params;
    }
  }
};
</script>
