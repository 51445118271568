<template>
  <CHeader with-subheader>
    <div
      id="V2HeaderWrap"
      class="admin_header"
      :class="{ dark_mode: $store.state.darkHeader }"
    >
      <div class="pc_header">
        <div class="bottom_header">
          <div class="wallet_connect float-right ml-5">
            <HeaderDropdownWallet />
          </div>

          <ul class="right_menu">
            <HeaderDropdownArtpool />
            <li>
              <router-link to="/drops">DROPS</router-link>
            </li>
            <li>
              <router-link to="/artist">ARTISTS</router-link>
            </li>
            <HeaderDropdownAbout />
          </ul>
        </div>
      </div>
    </div>
  </CHeader>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import HeaderDropdownArtists from "@/containers/HeaderDropdownArtists";
import HeaderDropdownArtpool from "@/containers/HeaderDropdownArtpool";
import HeaderDropdownAbout from "@/containers/HeaderDropdownAbout";
import HeaderDropdownWallet from "@/containers/HeaderDropdownWallet";

export default {
  name: "HeaderAdmin",
  components: {
    HeaderDropdownArtists,
    HeaderDropdownArtpool,
    HeaderDropdownAbout,
    HeaderDropdownWallet
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters("auth", ["isAuthenticated"]),
    ...mapState({
      me: state => {
        return state.auth.me || {};
      }
    }),
  }
};
</script>
