<template>
  <div>
    <div class="mypage-form settings">
      <section class="left">
        <!-- 컬렉션 비공개 설정 -->
        <p class="title">{{ $t("mypage.collectionCon.title") }}</p>
        <div class="form-checkbox">
          <b-form-group>
            <section class="checkbox-wrapper">
              <b-form-checkbox v-model="closeCollection" switch>
                <p>{{ $t("mypage.collectionCon.close") }}</p>
                <p>{{ $t("mypage.collectionCon.closeCon") }}</p>
              </b-form-checkbox>
              <b-form-checkbox v-model="openCollection" switch>
                <p>{{ $t("mypage.collectionCon.open") }}</p>
                <p>{{ $t("mypage.collectionCon.openCon") }}</p>
              </b-form-checkbox>
            </section>
          </b-form-group>
          <div class="save-button">
            <Button
              :onClick="showSelectCollectionModal"
              :disabled="
                memberInfo.collection === undefined || !memberInfo.collection
              "
              :label="$t('mypage.collectionCon.setting')"
            />
          </div>
        </div>
      </section>
      <section class="right" v-if="!isArtist">
        <!-- <template v-if="false">
          <p class="title">이메일 알림</p>
          <div class="input_check_box">
            <b-form-group>
              <b-form-checkbox v-model="memberInfo.noticeArtwork">
                <p>새 작품 알림</p>
                <p>
                  새로운 NFT작품이나 컬렉션에 대한 알림을 수신할 수 있습니다.
                </p>
              </b-form-checkbox>
              <b-form-checkbox v-model="memberInfo.noticeExhibit">
                <p>오프라인 전시</p>
                <p>새로운 오프라인 전시에 관한 알림을 수신 할 수 있습니다.</p>
              </b-form-checkbox>
            </b-form-group>
          </div>
          <p class="text-danger" v-if="rightErrorMassage !== ''">
            {{ rightErrorMassage }}
          </p>
          <div class="form_submit_btn form_settings_submit_btn">
            <CButton type="submit" @click="onClickSaveSattings">
              {{ $t("mypage.save") }}
            </CButton>
          </div>
        </template> -->
        <div class="member-leave">
          <p class="title">{{ $t("mypage.leave.accountWithdrawal") }}</p>
          <section>
            <p
              class="leave_content"
              v-html="$t('mypage.leave.accountWithdrawalWarning')"
            ></p>

            <div class="save-button">
              <Button
                :onClick="onClickToLeaveConfirm"
                :label="$t('mypage.withdrawal')"
              />
            </div>
          </section>
        </div>
      </section>
    </div>

    <!-- ETC ================================ -->
    <!-- 탈퇴하기 팝업 -->
    <b-modal
      ref="delete-confirm-modal"
      centered
      hide-header
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      body-class="text-center text-black"
    >
      <div class="d-flex justify-content-end">
        <img
          class="cursor-pointer"
          src="/img/threespace/icon/close.png"
          alt="close"
          @click="$refs['delete-confirm-modal'].hide()"
        />
      </div>

      <div class="delete_modal_content">
        <p
          class="big_content"
          v-html="replaceNewLine($t('mypage.memberLeave.confirmMsg'))"
        ></p>
        <div class="small_content">
          <div>
            <div>
              <span>•</span>
              <span> {{ $t("mypage.memberLeave.info1") }}</span>
            </div>
            <div>
              <span>•</span>
              <span> {{ $t("mypage.memberLeave.info2") }}</span>
            </div>
          </div>
          <div>
            <CInputCheckbox
              :label="$t('mypage.memberLeave.agree')"
              :checked.sync="acceptDeleteMember"
              custom
              @change="onCheckAccept"
            />
          </div>
        </div>
        <p class="text-danger" style="font-size: 12px">
          {{ acceptErrorMessage }}
        </p>
        <div class="d-flex justify-content-center mb-4">
          <div class="mt-3" style="width: 80%">
            <Button
              :label="$t('button.completeAccountClose')"
              :onClick="onClickToLeave"
            />
          </div>
        </div>
        <!-- <div class="mt-3" style="width: 100%">
          <Button
            :label="$t('button.completeAccountClose')"
            :onClick="onClickToLeave"
          /> -->
        <!-- </div> -->
      </div>
    </b-modal>

    <!-- 탈퇴하기 완료 팝업 -->
    <b-modal
      ref="delete-message-modal"
      hide-header
      hide-footer
      centered
      no-close-on-esc
      no-close-on-backdrop
      body-class="text-center text-black"
    >
      <div class="d-flex justify-content-end">
        <img
          class="cursor-pointer"
          src="/img/threespace/icon/close.png"
          alt="close"
          @click="$refs['delete-message-modal'].hide()"
        />
      </div>
      <div class="delete_modal_content">
        <p style="font-size: 16px; font-weight: 500">
          {{ completeMessage }}
        </p>
        <!-- <b-button @click="hideDeleteMessageModal">
          {{ $t("button.confirm") }}
        </b-button> -->
        <div class="d-flex justify-content-center mb-4">
          <div class="mt-3" style="width: 80%">
            <Button
              :label="$t('button.completeAccountClose')"
              :onClick="hideDeleteMessageModal"
            />
          </div>
        </div>
      </div>
    </b-modal>

    <MyPageCollectionVisibleModal ref="myPageCollectionVisibleModal" />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import MyPageCollectionVisibleModal from "./MyPageCollectionVisibleModal.vue";
import Button from "@/views/threespace/components/common/Button.vue";

export default {
  components: {
    MyPageCollectionVisibleModal,
    Button,
  },
  name: "MyPageUpdateSettings",
  props: {
    isArtist: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      completeMessage: "",
      acceptDeleteMember: false,
      acceptErrorMessage: null,
      rightErrorMassage: "",
      isBusy: false,
    };
  },
  computed: {
    ...mapState("profile", ["memberInfo"]),
    ...mapState("auth", ["me"]),
    closeCollection: {
      get: function () {
        return !this.memberInfo.collection;
      },
      set: function (newVal) {
        this.isBusy = true;
        this.memberInfo.collection = !newVal;
        if (newVal) {
          this.updateProfileSetting(this.memberInfo)
            .then(() => {
              this.isBusy = false;
            })
            .catch(() => (this.isBusy = false));
        }
      },
    },
    openCollection: {
      get: function () {
        return this.memberInfo.collection;
      },
      set: function (newVal) {
        this.memberInfo.collection = newVal;
        if (newVal) {
          this.updateProfileSetting(this.memberInfo)
            .then(() => {
              this.isBusy = false;
            })
            .catch(() => (this.isBusy = false));
        }
      },
    },
  },
  mounted() {},
  methods: {
    ...mapActions("profile", [
      "updateProfileSetting",
      "deleteMember",
      "getAuthRequestData",
    ]),
    ...mapActions("collection", ["getCollectionsAll"]),
    ...mapActions("auth", ["logout"]),
    showSelectCollectionModal() {
      if (this.me.idx != undefined) {
        this.getCollectionsAll({
          keywordIdxMember: this.me.idx,
          keywordStaked: false,
        }).then(() => {
          this.$refs["myPageCollectionVisibleModal"].show();
        });
      }
    },
    onClickSaveSattings() {
      this.updateProfileSetting(this.memberInfo)
        .then(() => {
          alert("성공적으로 저장되었습니다.");
        })
        .catch(() => {
          alert("요청에 실패하였습니다. 관리자에게 문의하세요.");
        });
      // this.rightErrorMassage = "";
      // if (this.memberInfo.email !== undefined && this.memberInfo.email !== "") {
      //   this.updateProfileSetting(this.memberInfo).then(() => {
      //     alert("성공적으로 저장되었습니다.")
      //   }).catch(() => {
      //     alert("요청에 실패하였습니다. 관리자에게 문의하세요.");
      //   });
      // } else {
      //   this.rightErrorMassage = "프로필설정에서 알림을 받을 이메일을 설정해주세요."
      // }
    },
    onClickToLeaveConfirm() {
      if (this.me.idx && this.me.idx !== "0") {
        this.$refs["delete-confirm-modal"].show();
      }
    },
    onClickToLeave() {
      this.completeMessage = "";
      if (!this.acceptDeleteMember) {
        this.acceptErrorMessage = this.$t("validation.required");
      } else {
        if (this.me.idx && this.me.idx !== "0") {
          this.deleteMember({ idxMember: this.me.idx })
            .then(() => {
              this.logout().then(() => {
                this.completeMessage = this.$t("mypage.memberLeave.complete");
                this.$refs["delete-message-modal"].show();
                this.$refs["delete-confirm-modal"].hide();
              });
            })
            .catch((error) => {
              this.$log.error(error);
              this.completeMessage = this.$t("mypage.memberLeave.error");
              this.$refs["delete-message-modal"].show();
              this.$refs["delete-confirm-modal"].hide();
            });
        }
      }
    },
    hideDeleteMessageModal() {
      this.$refs["delete-message-modal"].hide();
      this.$router.push({ name: "Drops" }).catch(() => {});
    },
    onCheckAccept() {
      if (!this.acceptDeleteMember) {
        this.acceptErrorMessage = this.$t("validation.required");
      } else {
        this.acceptErrorMessage = "";
      }
    },
    replaceNewLine(str) {
      if (str !== undefined && str !== null && str !== "") {
        return str.replace(/(?:\r\n|\r|\n)/g, "<br />");
      } else {
        return "";
      }
    },
  },
};
</script>
