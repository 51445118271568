<template>
  <CCard>
    <CCardBody>
      <CRow>
        <CCol class="mb-3"><h5>작가 관리</h5></CCol>
      </CRow>
      <CRow>
        <CCol class="pt-2">
          <table class="table">
            <tbody>
              <tr>
                <th class="border-bottom bg-light text-dark">검색</th>
                <td class="border-bottom">
                  <CSelect
                    class="col-2 float-left px-0 mb-0"
                    :options="searchOptions"
                    :value.sync="searchSelected"
                    @change="onChangeSearchOption"
                  />
                  <CInput
                    class="col-6 float-left mb-0"
                    placeholder="검색어를 입력해주세요."
                    v-model="externalParams.searchKeyword"
                    @input="onChangeSearchKeyword"
                    @keypress.enter.prevent="onClickSearch"
                  />
                  <CButton color="dark" @click="onClickSearch" class="text-white">
                    <CIcon name="cil-search" class="mr-2" />검색
                  </CButton>
                  <CButton
                    variant="outline"
                    color="secondary"
                    class="ml-2"
                    @click="onClickReset"
                  >
                    <CIcon name="cil-filter-x" />
                  </CButton>
                </td>
              </tr>
            <tr>
              <td colspan="2" class="border-bottom py-2">
                <b-form-checkbox
                  v-model="externalParams.keywordDisplay"
                  value="true"
                  class="mr-3 custom-control-inline"
                >
                  ARTISTS 노출
                </b-form-checkbox>
                <b-form-checkbox
                  v-model="externalParams.keywordTrending"
                  value="true"
                  class="mr-3 custom-control-inline"
                >
                  Trending Artist
                </b-form-checkbox>
              </td>
            </tr>
            </tbody>
          </table>
        </CCol>
      </CRow>
      <CRow>
        <CCol class="col-auto mr-auto align-self-center">
          총&nbsp;<strong>{{ artistTableItems.totalElements }}</strong>건&nbsp;({{ currentPage }}&nbsp;/&nbsp;{{ artistTableItems.totalPages }}&nbsp;페이지)
        </CCol>
        <CCol class="col-auto">
          <CSelect
            class="float-left mr-2 mb-2"
            :options="pageOptions"
            :value.sync="perPage"
            @change="onPaging"
          />
          <CButton color="dark" @click="onClickRegister" class="text-white">
            <CIcon name="cil-library-add" class="mr-2" />신규등록
          </CButton>
        </CCol>
      </CRow>
      <b-table
        id="artistTable"
        thead-class="text-center"
        :busy="isBusy"
        :items="artistTableItems.content"
        :fields="isAdminRole ? artistTableFields : corpArtistTableFields"
        :items-per-page="perPage"
        show-empty
        empty-text="조회된 내역이 없습니다."
      >
        <!-- NO -->
        <template #cell(index)="data">
          {{ data.index + 1 + (currentPage - 1) * perPage }}
        </template>

        <!-- 그룹여부 -->
        <template #cell(artistGroup)="data">
          {{ data.value === true ? "Y" : "N" }}
        </template>

        <!-- 작가명 -->
        <template #cell(artistName)="data">
          <a class="text-info cursor-pointer" @click="onClickArtistName(data.item.idx)">
            {{ data.item.artistName }}
          </a>
        </template>

        <!-- ARTISTS 노출 -->
        <template #cell(display)="data">
          {{ data.value === true ? "Y" : "N" }}
        </template>

        <!-- Trending Artist -->
        <template #cell(trending)="data">
          {{ data.value === true ? "Y" : "N" }}
        </template>

        <!-- Actions -->
        <template v-slot:cell(actions)="data">
          <CButton
            variant="outline"
            color="dark"
            size="sm"
            class="mx-2"
            @click="onClickModify(data.item.idx)"
          >
            <CIcon name="cil-pencil" />
          </CButton>
        </template>
      </b-table>
      <b-pagination
        aria-controls="artistTable"
        align="center"
        hide-ellipsis
        limit="10"
        v-model="currentPage"
        :total-rows="artistTableItems.totalElements"
        :per-page="perPage"
        :current-page="currentPage"
        v-on:input="onPaging"
      ></b-pagination>
    </CCardBody>
  </CCard>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const authHelper = createNamespacedHelpers("auth");
const memberHelper = createNamespacedHelpers("member");

export default {
  name: "ArtistList",
  components: {},
  data: function() {
    return {
      isBusy: false,
      perPage: 10,
      pageOptions: [
        { value: 10, label: "10 개씩 보기" },
        { value: 20, label: "20 개씩 보기" },
        { value: 30, label: "30 개씩 보기" },
        { value: 50, label: "50 개씩 보기" }
      ],
      currentPage: 1,
      sort: [
        { id: "idx", desc: "desc" },
        { id: "updatedAt", desc: "desc" }
      ],
      sortBy: "",
      sortDesc: false,
      // 검색
      searchSelected: "",
      searchOptions: [
        { value: "", label: "전체" },
        { value: "keywordArtistName", label: "작가명" },
        { value: "keywordArtistNameEn", label: "작가명(영문)" },
        { value: "keywordNickname", label: "사용자이름(닉네임)" },
        { value: "keywordMobile", label: "연락처" },
        { value: "keywordEmail", label: "이메일" },
        { value: "keywordKlaytnAddr", label: "카이아 지갑주소" },
        { value: "keywordEthereumAddr", label: "이더리움 지갑주소" }
      ],
      externalParams: this.getInitExternalParams()
    };
  },
  computed: {
    ...authHelper.mapState(["me"]),
    ...memberHelper.mapState(["artistTableItems", "artistTableFields", "corpArtistTableFields"]),
    isAdminRole() {
      if (
        this.me.roles !== undefined &&
        this.me.roles.length > 0 &&
        (this.me.roles.includes("ROLE_SUPERVISOR") || this.me.roles.includes("ROLE_OPERATOR"))
      ) {
        return true;
      } else {
        return false;
      }
    }
  },
  mounted() {
    this.requestArtistItems();
  },
  methods: {
    ...memberHelper.mapActions(["getArtists"]),
    onPaging() {
      this.requestArtistItems();
    },
    onChangeSearchOption() {
      this.setSearchConditions(this.externalParams.searchKeyword);
    },
    onChangeSearchKeyword(keyword) {
      this.setSearchConditions(keyword);
    },
    setSearchConditions(keyword) {
      this.externalParams = this.getInitExternalParams();
      if (this.searchSelected === "") {
        this.externalParams.searchKeyword = keyword;
      } else if (this.searchSelected === "keywordArtistName") {
        this.externalParams.keywordArtistName = keyword;
      } else if (this.searchSelected === "keywordNickname") {
        this.externalParams.keywordNickname = keyword;
      } else if (this.searchSelected === "keywordMobile") {
        this.externalParams.keywordMobile = keyword;
      } else if (this.searchSelected === "keywordEmail") {
        this.externalParams.keywordEmail = keyword;
      } else if (this.searchSelected === "keywordKlaytnAddr") {
        this.externalParams.keywordKlaytnAddr = keyword;
      } else if (this.searchSelected === "keywordEthereumAddr") {
        this.externalParams.keywordEthereumAddr = keyword;
      }
    },
    resetSearchConditions() {
      this.searchSelected = "";
      this.externalParams = this.getInitExternalParams();
    },
    onClickSearch() {
      this.requestArtistItems();
    },
    onClickReset() {
      this.resetSearchConditions();
      this.requestArtistItems();
    },
    requestArtistItems() {
      this.isBusy = true;
      this.getArtists(
        this.getPagingParams(
          {
            page: this.currentPage - 1,
            pageSize: this.perPage,
            sorted: this.sort,
            filtered: []
          },
          this.externalParams
        )
      )
        .then(data => {
          this.isBusy = false;
        })
        .catch(error => {
          this.isBusy = false;
          this.$log.error(error);
        });
    },
    onClickRegister() {
      if (this.isAdminRole) {
        this.$router.push("/management/artist-save/0");
      } else {
        this.$router.push("/corp-management/artist-save/0");
      }
    },
    onClickModify(idx) {
      if (this.isAdminRole) {
        this.$router.push("/management/artist-save/" + idx);
      } else {
        this.$router.push("/corp-management/artist-save/" + idx);
      }
    },
    onClickArtistName(idx) {
      if (this.isAdminRole) {
        this.$router.push("/management/artist-detail/" + idx);
      } else {
        this.$router.push("/corp-management/artist-detail/" + idx);
      }
    },
    getPagingParams({ page, pageSize, sorted, filtered }, externalParams) {
      const params = {
        page,
        size: pageSize,
        ...externalParams
      };

      // sorting parameter
      let sorts = [];
      for (let i = 0, length = sorted.length; i < length; i++) {
        const sort = sorted[i];
        sorts.push(`${sort.id},${sort.desc ? "desc" : "asc"}`);
      }
      params["sort"] = sorts;

      // filtering parameter
      for (let i = 0, length = filtered.length; i < length; i++) {
        const filter = filtered[i];
        params[filter.id] = filter.value;
      }

      return params;
    },
    getInitExternalParams() {
      return {
        searchKeyword: "",
        keywordArtistName: "",
        keywordNickname: "",
        keywordMobile: "",
        keywordEmail: "",
        keywordKlaytnAddr: "",
        keywordEthereumAddr: "",
        keywordDisplay: null,
        keywordTrending: null
      };
    }
  }
};
</script>
