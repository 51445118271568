import ApiService from "@/services/api.service";
import logger from "@/logger";

// action types
export const GET_COLLECTIONS = "getCollections";
export const GET_COLLECTION = "getCollection";
export const UPDATE_COLLECTION = "updateCollection";

// mutation types
export const SET_COLLECTIONS = "setCollections";
export const SET_COLLECTION = "setCollection";

const state = {
  collectionTableItems: {
    content: [],
    first: true,
    last: false,
    number: 0,
    numberOfElements: 0,
    size: 0,
    sort: {
      orders: []
    },
    total: 0,
    totalElements: 0,
    totalPages: 1
  },
  collectionTableFields: [
    { key: "index", label: "번호", class: "text-center", thStyle: "width:5%" },
    { key: "idx", label: "컬렉션 번호", class: "text-center", thStyle: "width:76px" },
    { key: "nft.name", label: "NFT", class: "text-center", thStyle: "width:150px" },
    { key: "title", label: "컬렉션 명" },
    { key: "owner", label: "소유주", class: "text-center" },
    { key: "nft.network", label: "네트워크", class: "text-center" },
    { key: "actions", label: "Actions", class: "text-center", thStyle: "width:100px" }
  ],
  collectionInfo: {}
};

const getters = {};

const actions = {
  [GET_COLLECTIONS](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("/management/v1/collections", payload)
        .then(({ data }) => {
          context.commit(SET_COLLECTIONS, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [GET_COLLECTION](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("/management/v1/collections/" + payload)
        .then(({ data }) => {
          context.commit(SET_COLLECTION, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [UPDATE_COLLECTION](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.put("/management/v1/collections/" + payload.idx, payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
};

const mutations = {
  [SET_COLLECTIONS](state, collectionTableItems) {
    state.collectionTableItems = collectionTableItems;
  },
  [SET_COLLECTION](state, collectionInfo) {
    state.collectionInfo = collectionInfo;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
