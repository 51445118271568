<template>
  <div
    id="NnfPoolProductDetail"
    ref="nnfPoolProductDetail"
    v-if="showProductDetail"
    @click="hide"
  >
    <template v-if="isProductFileTypeImage(artworkFileInfo.productFileType)">
      <b-img
        id="artworkImage"
        ref="artworkImage"
        :src="replaceDisplayDetailUrl(artworkFileInfo)"
        @contextmenu.prevent.stop
      ></b-img>
    </template>
    <template v-else>
      <video
        id="artworkVideo"
        ref="artworkVideo"
        :poster="replaceDisplayPosterUrl(artworkFileInfo)"
        :src="replaceDisplayDetailUrl(artworkFileInfo)"
        type="video/webm"
        loop
        autoplay
        :muted="videoOptions.muted"
        @contextmenu.prevent.stop
        playsinline
      ></video>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: "NftPoolProductDetail",
  data: function () {
    return {
      showProductDetail: false,
      videoOptions: {
        muted: true, //TODO
        fullscreen: false
      },
    }
  },
  computed: {
    ...mapState("gallery", ["artworkFileInfo"]),
    isValid() {
      return !this.$v.form.$invalid;
    },
  },
  methods: {
    show() {
      this.showProductDetail = true;
    },
    hide() {
      this.showProductDetail = false;
    },
    isProductFileTypeImage(_productFileType) {
      if (
        _productFileType !== undefined &&
        _productFileType.startsWith("image")
      ) {
        return true;
      } else {
        return false;
      }
    },
    replaceDisplayPosterUrl(item) {
      if (
        item.displayPoster !== undefined &&
        item.displayPoster !== null &&
        item.displayPoster !== "" &&
        item.displayPoster === "THREESPACE"
      ) {
        return item.displayPosterUrlThreespace; // 3space S3 URL
      } else {
        if (item.displayPosterUrl !== undefined && item.displayPosterUrl.startsWith("https://ipfs.io/")) {
          return item.displayPosterUrl.replace("https://ipfs.io/", "https://ipfs.3space.art/");
        }
        return item.displayPosterUrl;
      }
    },
    replaceDisplayDetailUrl(item) {
      if (
        item.displayDetailFile !== undefined &&
        item.displayDetailFile !== null &&
        item.displayDetailFile !== "" &&
        item.displayDetailFile === "THREESPACE"
      ) {
        return item.displayDetailUrlThreespace; // 3space S3 URL
      } else {
        if (
          item.displayDetailUrl !== undefined &&
          item.displayDetailUrl.startsWith("https://ipfs.io/")
        ) {
          return item.displayDetailUrl.replace(
            "https://ipfs.io/",
            "https://ipfs.3space.art/"
          );
        }
        return item.displayDetailUrl;
      }
    },
  }
}
</script>
