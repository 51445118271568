<template>
  <div id="MypageCollected" class="collected">
    <section class="filter">
      <div>
        <section class="filter__wrapper">
          <button
            class="filter__type"
            @click="visibleBlockchain = !visibleBlockchain"
          >
            <span>{{ $t("product.filterBlockchain") }}</span>
            <img src="/img/threespace/icon/chevron.svg" />
          </button>

          <b-collapse
            class="filter__item filter-item"
            v-model="visibleBlockchain"
          >
            <b-form-checkbox
              v-model="externalParams.isChainKlaytn"
              @change="onChangeFilter()"
            >
              {{ $t("mypage.klaytn") }}
            </b-form-checkbox>
            <b-form-checkbox
              v-model="externalParams.isChainEthereum"
              @change="onChangeFilter()"
            >
              {{ $t("mypage.ethereum") }}
            </b-form-checkbox>
          </b-collapse>
        </section>

        <section class="filter__wrapper">
          <button
            class="filter__type"
            @click="visibleFileType = !visibleFileType"
          >
            <span>{{ $t("product.filterArttype") }}</span>
            <img src="/img/threespace/icon/chevron.svg" />
          </button>
          <b-collapse
            class="filter__item filter-item"
            v-model="visibleFileType"
          >
            <b-form-checkbox
              v-model="externalParams.isFileTypeMp4"
              @change="onChangeFilter()"
              >MP4</b-form-checkbox
            >
            <b-form-checkbox
              v-model="externalParams.isFileTypeGif"
              @change="onChangeFilter()"
              >GIF</b-form-checkbox
            >
            <b-form-checkbox
              v-model="externalParams.isFileTypeImage"
              @change="onChangeFilter()"
              >Image</b-form-checkbox
            >
          </b-collapse>
        </section>
        <button
          class="filter__button"
          @click="visibleMobileFilter = !visibleMobileFilter"
        >
          <img src="/img/threespace/icon/filter.svg" />
        </button>
        <b-collapse
          class="filter__item filter-item"
          v-model="visibleMobileFilter"
        >
          <b-form-checkbox
            v-model="externalParams.isChainKlaytn"
            @change="onChangeFilter()"
          >
            {{ $t("mypage.klaytn") }}
          </b-form-checkbox>
          <b-form-checkbox
            v-model="externalParams.isChainEthereum"
            @change="onChangeFilter()"
          >
            {{ $t("mypage.ethereum") }}
          </b-form-checkbox>
          <hr style="margin: 10px 0" />
          <b-form-checkbox
            v-model="externalParams.isFileTypeMp4"
            @change="onChangeFilter()"
            >MP4</b-form-checkbox
          >
          <b-form-checkbox
            v-model="externalParams.isFileTypeGif"
            @change="onChangeFilter()"
            >GIF</b-form-checkbox
          >
          <b-form-checkbox
            v-model="externalParams.isFileTypeImage"
            @change="onChangeFilter()"
            >Image</b-form-checkbox
          >
        </b-collapse>
      </div>
      <section
        v-if="
          externalParams.isChainKlaytn ||
          externalParams.isChainEthereum ||
          externalParams.isFileTypeMp4 ||
          externalParams.isFileTypeGif ||
          externalParams.isFileTypeImage
        "
        class="selected-keyword"
      >
        <div v-if="externalParams.isChainKlaytn">
          <span
            >{{ $t("product.filterBlockchain") }}:
            {{ $t("mypage.klaytn") }}</span
          >
          <button @click="externalParams.isChainKlaytn = false">
            <img src="/img/threespace/icon/close.svg" />
          </button>
        </div>

        <div v-if="externalParams.isChainEthereum">
          <span
            >{{ $t("product.filterBlockchain") }}:
            {{ $t("mypage.ethereum") }}</span
          >
          <button @click="externalParams.isChainEthereum = false">
            <img src="/img/threespace/icon/close.svg" />
          </button>
        </div>
        <div v-if="externalParams.isFileTypeMp4">
          <span>{{ $t("product.filterArttype") }}: MP4</span>
          <button @click="externalParams.isFileTypeMp4 = false">
            <img src="/img/threespace/icon/close.svg" />
          </button>
        </div>
        <div v-if="externalParams.isFileTypeImage">
          <span>{{ $t("product.filterDisplay") }}: Image</span>
          <button @click="externalParams.isFileTypeImage = false">
            <img src="/img/threespace/icon/close.svg" />
          </button>
        </div>
        <div v-if="externalParams.isFileTypeGif">
          <span>{{ $t("product.filterDisplay") }}: GIF</span>
          <button @click="externalParams.isFileTypeGif = false">
            <img src="/img/threespace/icon/close.svg" />
          </button>
        </div>
        <button class="reset-all" @click="() => resetFilter()">
          Reset All
        </button>
      </section>
    </section>
    <template
      class="row"
      v-if="
        collectionList === undefined ||
        collectionList === null ||
        collectionList.content.length === 0 ||
        !memberSimpleInfo.collection
      "
    >
      <div class="col-12 mb-5 py-5 text-center" key="0">
        <h5 class="pb-5 my-5 text-gray">
          {{ $t("mypage.noHistoryCollection") }}
        </h5>
      </div>
    </template>
    <div class="collected__wrapper" v-else>
      <div
        class="item"
        v-for="(item, i) in collectionList.content"
        :key="i"
        :ref="'cartItem' + item.idx"
      >
        <article
          :id="'galleryItem' + item.idx"
          @click="showProductDetail(item)"
        >
          <v-lazy :options="{ threshold: 0.5 }" transition="fade-transition">
            <div
              class="img-box"
              @contextmenu.prevent.stop
              :class="{ isLoading: isBusy }"
            >
              <!-- <CardHoverBox
                :favoritedCnt="item.favoritedCnt"
                :pageViewCnt="item.product.pageViewCnt"
                :isExternalCollection="
                  item.idxProduct === undefined ||
                  item.idxProduct === null ||
                  item.idxProduct === ''
                "
              /> -->
              <template>
                <template v-if="!item.video">
                  <b-img :src="item.imageUrl"></b-img>
                </template>
                <template v-else>
                  <video
                    :poster="replaceDisplayPosterUrl(item)"
                    :src="replaceDisplayCardUrl(item)"
                    type="video/webm"
                    muted
                    loop
                    autoplay
                    playsinline
                  ></video>
                </template>
              </template>
              <b-skeleton-img
                no-aspect
                width="100%"
                height="100%"
              ></b-skeleton-img>
            </div>
          </v-lazy>
          <section class="card-info">
            <section class="card-info__title" v-if="!isBusy">
              <p
                class="artist_name"
                v-if="
                  item.idxProduct !== undefined &&
                  item.idxProduct !== null &&
                  item.idxProduct !== ''
                "
              >
                <template
                  v-if="activeLanguage !== undefined && activeLanguage === 'ko'"
                >
                  {{ item.artist || item.artistEn }}
                </template>
                <template v-else>
                  {{ item.artistEn || item.artist }}
                </template>
              </p>
              <div class="icon-wrap">
                <FavoritedButton
                  :favoritedItem="item"
                  @onClickFavoritedButton="onClickFavoritedButton"
                />
              </div>
            </section>
            <section class="card-info__title" v-else>
              <p class="artist_name">
                <b-skeleton
                  class="float-left mt-2 ml-2"
                  width="100px"
                ></b-skeleton>
              </p>
            </section>
            <h3 class="card-info__name" v-if="!isBusy">
              <template
                v-if="activeLanguage !== undefined && activeLanguage === 'ko'"
              >
                {{ item.title || item.titleEn }}
              </template>
              <template v-else>
                {{ item.titleEn || item.title }}
              </template>
            </h3>
            <h3 class="card-info__name" v-else>
              <b-skeleton
                class="float-left mt-2 ml-2"
                width="200px"
              ></b-skeleton>
            </h3>
            <section class="card-info__price" v-if="!isBusy">
              <p>
                {{ "Owned by @" + item.owner }}
              </p>
            </section>
            <section class="card-info__price" v-else>
              <span>
                <b-skeleton width="50%" height="25px"></b-skeleton>
              </span>
            </section>
          </section>
        </article>
      </div>
    </div>
    <!-- pagination -->
    <div class="row">
      <div class="col-12 text-right">
        <b-pagination
          v-on:change="onPaging"
          :total-rows="collectionList.totalElements"
          v-model="currentPage"
          :per-page="perPage"
          hide-ellipsis
          limit="10"
          aria-controls="role-function-list"
          class="float-right gallery_pagination"
        >
          <template #first-text>
            <img
              class="page_icon rotate_page_icon"
              src="/img/threespace/icon/pagination_arrow2_icon.png"
            />
          </template>
          <template #prev-text>
            <img
              class="page_icon rotate_page_icon"
              src="/img/threespace/icon/pagination_arrow_icon.png"
            />
          </template>
          <template #next-text>
            <img
              class="page_icon"
              src="/img/threespace/icon/pagination_arrow_icon.png"
            />
          </template>
          <template #last-text>
            <img
              class="page_icon"
              src="/img/threespace/icon/pagination_arrow2_icon.png"
            />
          </template>
          <template #page="{ page }">
            <span :class="{ active_num: page === currentPage }">
              {{ page }}
            </span>
          </template>
        </b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import vClickOutside from "v-click-outside";
import FavoritedButton from "../common/FavoritedButton.vue";
import CardHoverBox from "../common/CardHoverBox.vue";

export default {
  components: {
    FavoritedButton,
    CardHoverBox,
  },
  name: "CollectionsCollection",
  props: {
    idxMember: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      //TODO 이하 현재 미사용
      visibleBlockchain: false,
      visibleFileType: false,
      visibleMobileFilter: false,
      isBusy: false,
      isLast: false,
      perPage: 12,
      currentPage: 1,
      sort: [{ id: "createdAt", desc: "desc" }],
      externalParams: {
        keywordIdxMember: 0,
        isChainEthereum: false,
        isChainKlaytn: false,
        isFileTypeGif: false,
        isFileTypeImage: false,
        isFileTypeMp4: false,
      },
      categoryOptions: [
        { text: "Cate01", value: "CATEGORY1" },
        { text: "Cate02", value: "CATEGORY2" },
        { text: "Cate03", value: "CATEGORY3" },
        { text: "Cate04", value: "CATEGORY4" },
        { text: "Cate05", value: "CATEGORY5" },
        { text: "Cate06", value: "CATEGORY6" },
        { text: "Cate07", value: "CATEGORY7" },
        { text: "Cate08", value: "CATEGORY8" },
      ],
      showFilter: false,
      showCategory: false,
      showCardMenuIdx: 0,
      counter: 0,
      exportCollection: {},
    };
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  computed: {
    ...mapState("collection", ["collectionList"]),
    ...mapState("profile", ["memberSimpleInfo"]),
    ...mapState({
      me: (state) => {
        return state.auth.me || {};
      },
    }),
    ...mapGetters("auth", ["isAuthenticated"]),
    activeLanguage() {
      return this.getActiveLanguage();
    },
    isMobile() {
      return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    },
  },
  mounted() {
    this.getTickers();
    this.$nextTick(() => {
      this.resetCollections();
      this.requestCollectionItems();
    });
  },
  methods: {
    ...mapActions("code", ["getChildMultiCodes"]),
    ...mapActions("collection", [
      "resetCollections",
      "getCollections",
      "downloadCollections",
    ]),
    ...mapActions("profile", ["updateProfileImageByCollection"]),
    ...mapActions("coin", ["getTickers"]),
    ...mapActions("favorited", [
      "addFavorited",
      "deleteFavorited",
      "toggleFavorited",
    ]),
    ...mapActions("auth", ["verifyTokenCallback"]),
    getActiveLanguage() {
      return localStorage.getItem("language") || "en";
    },
    onClickCategoryOutside(event) {
      if (event.srcElement.id !== "categoryBtn") {
        this.showCategory = false;
      }
    },

    onClickCardMenuOutside(e) {
      if (e.srcElement.classList[0] !== "noneClickBtn") {
        this.showCardMenuIdx = 0;
      }
    },
    onClickCopyLink() {
      // stop click
    },
    getCopyLink(item) {
      if (
        item.idxProduct !== undefined &&
        item.idxProduct !== null &&
        item.idxProduct !== ""
      ) {
        return process.env.VUE_APP_FRONTEND_API_URL + "/art/" + item.idxProduct;
      } else {
        let chain = "";
        if (item.nft.rfc.indexOf("ERC") !== -1) {
          chain = "ethereum";
        } else if (item.nft.rfc.indexOf("KIP") !== -1) {
          chain = "klaytn";
        }
        return `https://opensea.io/assets/${chain}/${item.nft.contractAddr}/${item.tokenId}`;
      }
    },
    successCopyLink() {
      this.$bvToast.show("copy-complete");
    },
    onClickProfileSetting(item) {
      let param = {
        idxMember: this.idxMember,
        idxCollection: item.idx,
      };
      this.updateProfileImageByCollection(param)
        .then(() => {
          // request me
          this.$emit("successProfileSetting");
          this.$store.commit("setCommonAlertModalInfo", {
            msgHtml: "프로필 이미지가 설정되었습니다.",
            show: true,
            fontSize: "sm",
          });
        })
        .catch(() => {
          this.$store.commit("setCommonAlertModalInfo", {
            msgHtml: "프로필 이미지 설정에 실패했습니다.",
            show: true,
            fontSize: "sm",
          });
        });
    },
    requestCollectionItems() {
      if (this.idxMember !== undefined) {
        this.externalParams.keywordIdxMember = this.idxMember;
        this.isBusy = true;
        this.getCollections(
          this.getPagingParams(
            {
              page: this.currentPage - 1,
              pageSize: this.perPage,
              sorted: this.sort,
              filtered: [],
            },
            this.externalParams
          )
        )
          .then(() => {
            this.isBusy = false;
          })
          .catch(() => {
            this.isBusy = false;
          });
      }
    },
    getPagingParams({ page, pageSize, sorted, filtered }, externalParams) {
      const params = {
        page,
        size: pageSize,
        ...externalParams,
      };
      // sorting parameter
      let sorts = [];
      for (let i = 0, length = sorted.length; i < length; i++) {
        const sort = sorted[i];
        sorts.push(`${sort.id},${sort.desc ? "desc" : "asc"}`);
      }
      params["sort"] = sorts;

      // filtering parameter
      for (let i = 0, length = filtered.length; i < length; i++) {
        const filter = filtered[i];
        params[filter.id] = filter.value;
      }

      return params;
    },
    onClickExportNft(collection) {
      this.exportCollection = collection;
      this.$refs["myPageExportCollectionModal"].show();
    },
    onPaging(page) {
      this.currentPage = page;
      this.requestCollectionItems();
    },
    onChangeFilter() {
      this.currentPage = 1;
      this.requestCollectionItems();
    },
    resetFilter() {
      this.externalParams.isChainEthereum = false;
      this.externalParams.isChainKlaytn = false;
      this.externalParams.isFileTypeGif = false;
      this.externalParams.isFileTypeImage = false;
      this.externalParams.isFileTypeMp4 = false;
      this.currentPage = 1;
      this.requestCollectionItems();
    },
    showProductDetail(item) {
      if (
        item.idxProduct !== undefined &&
        item.idxProduct !== null &&
        item.idxProduct !== ""
      ) {
        this.$router.push("/art/" + item.idxProduct);
      } else {
        if (item.nft.name === "KlipDrops") {
          let url = `https://klipdrops.com/market/${item.nft.contractAddr}/${item.tokenId}`;
          window.open(url, "_blank");
        } else {
          let chain = "";
          if (item.nft.rfc.indexOf("ERC") !== -1) {
            chain = "ethereum";
          } else if (item.nft.rfc.indexOf("KIP") !== -1) {
            chain = "klaytn";
          }

          let url = `https://opensea.io/assets/${chain}/${item.nft.contractAddr}/${item.tokenId}`;
          window.open(url, "_blank");
        }
      }
    },
    downloadCollection(idx) {
      this.downloadCollections(idx).then();
    },
    replaceDisplayPosterUrl(item) {
      if (
        item.idxProduct !== undefined &&
        item.idxProduct !== null &&
        item.idxProduct !== ""
      ) {
        if (
          item.product.displayPoster !== undefined &&
          item.product.displayPoster !== null &&
          item.product.displayPoster !== "" &&
          item.product.displayPoster === "THREESPACE"
        ) {
          return item.product.displayPosterUrlThreespace; // 3space S3 URL
        } else {
          if (
            item.product.displayPosterUrl !== undefined &&
            item.product.displayPosterUrl.startsWith("https://ipfs.io/")
          ) {
            return item.product.displayPosterUrl.replace(
              "https://ipfs.io/",
              "https://ipfs.3space.art/"
            );
          }
          return item.product.displayPosterUrl;
        }
      } else {
        return item.imageUrl;
      }
    },
    replaceDisplayCardUrl(item) {
      if (
        item.idxProduct !== undefined &&
        item.idxProduct !== null &&
        item.idxProduct !== ""
      ) {
        if (
          item.product.displayCardFile !== undefined &&
          item.product.displayCardFile !== null &&
          item.product.displayCardFile !== "" &&
          item.product.displayCardFile === "THREESPACE"
        ) {
          return item.product.displayCardUrlThreespace; // 3space S3 URL
        } else {
          if (
            item.product.displayCardUrl !== undefined &&
            item.product.displayCardUrl.startsWith("https://ipfs.io/")
          ) {
            return item.product.displayCardUrl.replace(
              "https://ipfs.io/",
              "https://ipfs.3space.art/"
            );
          }
          return item.product.displayCardUrl;
        }
      } else {
        return item.imageUrl;
      }
    },
    onClickFavoritedButton(favoritedItem) {
      if (this.isAuthenticated) {
        this.verifyTokenCallback().then(() => {
          if (
            this.me === undefined ||
            this.me === null ||
            this.me.idx === undefined ||
            this.me.idx === null ||
            this.me.idx === ""
          ) {
            // 로그인
            this.hasKlaytnAddr = false;
            this.$store.commit("setCommonAlertModalInfo", {
              msgHtml: this.$t("validation.requiredLogin"),
              show: true,
              fontSize: "sm",
            });
          } else {
            // 로그인 되어있음
            if (favoritedItem.favorited) {
              // true이면 좋아요 헤재
              this.toggleFavorited(favoritedItem);
              this.deleteFavorited({ idxCollection: favoritedItem.idx });
            } else {
              // false이면 좋아요
              this.toggleFavorited(favoritedItem);
              this.addFavorited({ idxCollection: favoritedItem.idx });
            }
          }
        });
      } else {
        this.$store.commit("setCommonAlertModalInfo", {
          msgHtml: this.$t("validation.requiredLogin"),
          show: true,
          fontSize: "sm",
        });
      }
    },
  },
  filters: {
    currency: (value) => {
      if (!value) return "";
      return parseInt(value)
        .toFixed(0)
        .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    },
  },
};
</script>
