<template>
  <CCard>
    <CCardBody>
      <CRow>
        <CCol class="mb-3"><h5>작가 상세조회</h5></CCol>
      </CRow>
      <CForm>
        <CRow>
          <CCol col="8">
            <b-table-simple>
              <colgroup>
                <col style="width:30%;" />
                <col style="width:70%;" />
              </colgroup>
              <b-tbody>
                <b-tr v-if="isAdminRole">
                  <b-th>작가(그룹)</b-th>
                  <b-td>
                    <b-form-radio-group
                      id="artistGroup"
                      name="artistGroup"
                      :options="artistGroupOptions"
                      v-model="artistInfo.artistGroup"
                    />
                  </b-td>
                </b-tr>
                <b-tr v-if="isAdminRole">
                  <b-th class="col-md-2">작가(그룹) 번호</b-th>
                  <b-td class="col-md-4">{{ artistInfo.idx }}</b-td>
                </b-tr>
                <b-tr v-if="isAdminRole && artistInfo.artistGroup === 'false'">
                  <b-th class="col-md-2">멤버 회원번호</b-th>
                  <b-td class="col-md-4">{{ artistInfo.idxMember }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th class="col-md-3">작가명<template v-if="artistInfo.artistGroup === 'true'">(그룹명)</template></b-th>
                  <b-td class="col-md-9">{{ artistInfo.artistName }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th class="col-md-3">작가명<template v-if="artistInfo.artistGroup === 'true'">(그룹명)</template>(영문)</b-th>
                  <b-td class="col-md-9">{{ artistInfo.artistNameEn }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th>
                    작가 사용자이름(닉네임)
                  </b-th>
                  <b-td>{{ artistInfo.nickname }}</b-td>
                </b-tr>
                <b-tr v-if="artistInfo.artistGroup === 'false'">
                  <b-th>이름</b-th>
                  <b-td>{{ artistInfo.name }}</b-td>
                </b-tr>
                <b-tr v-if="artistInfo.artistGroup === 'false'">
                  <b-th>연락처</b-th>
                  <b-td>{{ artistInfo.mobile }}</b-td>
                </b-tr>
                <b-tr v-if="artistInfo.artistGroup === 'false'">
                  <b-th>이메일</b-th>
                  <b-td>{{ artistInfo.email }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th>작가 소개</b-th>
                  <b-td>
                    <p v-html="replaceNewLine(artistInfo.introduce)" class="mb-0"></p>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-th>작가 소개(영문)</b-th>
                  <b-td>
                    <p v-html="replaceNewLine(artistInfo.introduceEn)" class="mb-0"></p>
                  </b-td>
                </b-tr>
                <b-tr v-if="artistInfo.artistGroup === 'false'">
                  <b-th>SNS</b-th>
                  <b-td>
                    <div>
                      <CButton
                        name="instagram"
                        color="instagram"
                        size="sm"
                        class="mr-2"
                        style="cursor:default;"
                        disabled
                      >
                        <CIcon name="cib-instagram" size="sm" />
                      </CButton>
                      {{ artistInfo.instagram !== undefined && artistInfo.instagram !== '' ? 'https://intagram.com/' + artistInfo.instagram + '/' : '' }}
                    </div>
                    <div class="mt-1">
                      <CButton
                        name="twitter"
                        color="twitter"
                        size="sm"
                        class="mr-2"
                        style="cursor:default;"
                        disabled
                      >
                        <CIcon name="cib-twitter" size="sm" />
                      </CButton>
                      {{ artistInfo.twitter !== undefined && artistInfo.twitter !== '' ? 'https://twitter.com/' + artistInfo.twitter : '' }}
                    </div>
                  </b-td>
                </b-tr>
                <b-tr class="bg-gray-100" v-if="artistInfo.artistGroup === 'false'">
                  <b-th class="text-dark">소속 법인</b-th>
                  <b-td class="text-dark">{{ artistInfo.companyName }}</b-td>
                </b-tr>
                <b-tr v-if="artistInfo.artistGroup === 'false'">
                  <b-th>은행 계좌</b-th>
                  <b-td>
                    {{ replaceKoBankCode(artistInfo.bankCode).text }}
                    {{ artistInfo.bankAccount }}
                  </b-td>
                </b-tr>
                <b-tr v-if="artistInfo.artistGroup === 'false'">
                  <b-th>
                    카이아 지갑주소
                  </b-th>
                  <b-td>{{ artistInfo.klaytnAddr }}</b-td>
                </b-tr>
                <b-tr v-if="artistInfo.artistGroup === 'false'">
                  <b-th>
                    이더리움 지갑주소
                  </b-th>
                  <b-td>{{ artistInfo.ethereumAddr }}</b-td>
                </b-tr>
                <b-tr v-if="artistInfo.artistGroup === 'false'">
                  <b-th>
                    폴리곤 지갑주소
                  </b-th>
                  <b-td>{{ artistInfo.polygonAddr }}</b-td>
                </b-tr>
                <b-tr v-if="artistInfo.artistGroup === 'false'">
                  <b-th>BTC 지갑주소</b-th>
                  <b-td>{{ artistInfo.btcAddr }}</b-td>
                </b-tr>
                <b-tr v-if="artistInfo.artistGroup === 'false'">
                  <b-th class="border-bottom">판매수수료 비율(총 100%)</b-th>
                  <b-td class="border-bottom">
                    작가(법인): {{ artistInfo.salesFeeRatioArtist || '(정보없음)' }} / 세번째공간: {{ artistInfo.salesFeeRatioThreespace || '(정보없음)' }}
                  </b-td>
                </b-tr>
                <b-tr v-if="artistInfo.artistGroup === 'true'">
                  <b-th class="border-bottom">작가(그룹) 멤버</b-th>
                  <b-td class="border-bottom">
                    <b-table-simple class="mt-2">
                      <b-thead>
                        <b-tr>
                          <b-th class="text-center bg-gray-100 text-dark py-2">
                            회원번호
                          </b-th>
                          <b-th class="text-center bg-gray-100 text-dark py-2">
                            작가명
                          </b-th>
                          <b-th class="text-center bg-gray-100 text-dark py-2">
                            작가 사용자이름(닉네임)
                          </b-th>
                        </b-tr>
                      </b-thead>
                      <b-tbody>
                        <template v-for="(item, index) in artistInfo.childArtists">
                          <b-tr :key="'item' + index">
                            <b-td class="text-center">{{ item.idxMember }}</b-td>
                            <b-td>{{ item.artistName }}</b-td>
                            <b-td>{{ item.nickname }}</b-td>
                          </b-tr>
                        </template>
                      </b-tbody>
                    </b-table-simple>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </CCol>
          <CCol col="4">
            <div>
              <strong>프로필 이미지</strong>
              <div class="mt-1">
                <span class="text-success">{{ artistInfo.profileImage || '(정보없음)' }}</span>
                <div v-if="artistInfo.profileImage !== undefined">
                  <v-img
                    lazy-src="/img/avatars/profile_poster.gif"
                    :src="artistInfo.profileImageUrl"
                    width="100px"
                    height="100px"
                    class="mt-1"
                  />
                </div>
              </div>
            </div>
            <div class="mt-3">
              <strong>클립 그룹아이콘</strong>
              <div class="mt-1">
                <span class="text-success">{{ artistInfo.groupIcon || '(정보없음)' }}</span>
                <div v-if="artistInfo.groupIcon !== undefined">
                  <CImg
                    height="100px"
                    :src="artistInfo.groupIcon"
                    class="mt-1"
                  />
                </div>
              </div>
            </div>
            <div class="mt-3" v-if="artistInfo.artistGroup === 'false'">
              <strong>신분증 이미지</strong>
              <div class="mt-1 text-success">{{ artistInfo.idCard || '(정보없음)' }}</div>
            </div>
          </CCol>
        </CRow>
        <CRow>
          <CCol col="12" class="text-center my-4">
            <CButton color="light" @click="onClickConfirm()" class="mr-2">
              <CIcon name="cil-x-circle" class="mr-2" />목록
            </CButton>
            <CButton color="info" @click="onClickModify()" class="text-white" :disabled="isBusy">
              <CIcon name="cil-save" class="mr-2" />수정
            </CButton>
          </CCol>
        </CRow>
      </CForm>
    </CCardBody>
  </CCard>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const authHelper = createNamespacedHelpers("auth");
const memberHelper = createNamespacedHelpers("member");

export default {
  name: "ArtistDetail",
  components: {},
  props: {
    idxArtist: {
      type: String,
      default: "0"
    }
  },
  data: function() {
    return {
      isBusy: true,
      artistGroupOptions: [
        { value: "false", text: "개인", disabled: true },
        { value: "true", text: "그룹", disabled: true }
      ]
    };
  },
  computed: {
    ...authHelper.mapState(["me"]),
    ...memberHelper.mapState(["artistInfo"]),
    isAdminRole() {
      if (
        this.me.roles !== undefined &&
        this.me.roles.length > 0 &&
        (this.me.roles.includes("ROLE_SUPERVISOR") || this.me.roles.includes("ROLE_OPERATOR"))
      ) {
        return true;
      } else {
        return false;
      }
    }
  },
  mounted() {
    this.requestArtistItem();
  },
  methods: {
    ...memberHelper.mapActions(["getArtist"]),
    requestArtistItem() {
      if (this.idxArtist && this.idxArtist !== "0") {
        this.getArtist(this.idxArtist).then(() => {
          this.$log.info(this.artistInfo); //TODO
          if (this.artistInfo.artistGroup === undefined || this.artistInfo.artistGroup === null || this.artistInfo.artistGroup === "") {
            this.artistInfo.artistGroup = "false";
          } else {
            this.artistInfo.artistGroup = this.artistInfo.artistGroup === true ? "true" : "false";
          }
          this.isBusy = false;
        })
        .catch(error => {
          alert("작가 정보 조회 오류가 발생하였습니다. 관리자에게 문의하세요.");
          this.$log.error(error);
        });
      }
    },
    onClickConfirm() {
      if (this.isAdminRole) {
        this.$router.push("/management/artist-list");
      } else {
        this.$router.push("/corp-management/artist-list");
      }
    },
    onClickModify() {
      if (this.isAdminRole) {
        this.$router.push("/management/artist-save/" + this.idxArtist);
      } else {
        this.$router.push("/corp-management/artist-save/" + this.idxArtist);
      }
    },
    replaceNewLine(str) {
      if (str !== undefined && str !== null && str !== "") {
        return str.replace(/(?:\r\n|\r|\n)/g, "<br />");
      } else {
        return "";
      }
    },
    replaceKoBankCode(data) {
      let obj = {};
      switch (data) {
        case "003":
          obj = { text: "기업은행" };
          break;
        case "004":
          obj = { text: "국민은행" };
          break;
        case "011":
          obj = { text: "NH농협은행" };
          break;
        case "020":
          obj = { text: "우리은행" };
          break;
        case "027":
          obj = { text: "씨티은행" };
          break;
        case "081":
          obj = { text: "하나은행" };
          break;
        case "088":
          obj = { text: "신한은행" };
          break;
        case "090":
          obj = { text: "카카오뱅크" };
          break;
        case "092":
          obj = { text: "토스뱅크" };
          break;
        default:
          obj = { text: "" };
      }
      return obj;
    }
  }
};
</script>
