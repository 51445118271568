<template>
  <router-view></router-view>
</template>

<script>
import VueI18n from "./lang/index";

export default {
  name: "App",
  metaInfo: {
    title: VueI18n.t("meta.default.title"),
    titleTemplate: null,
    htmlAttrs: {
      lang: VueI18n.t("meta.lang")
    },
    meta: [
      { charset: "utf-8" },
      { vmid: "title", name: "title", content: VueI18n.t("meta.default.title") },
      { vmid: "description", name: "description", content: VueI18n.t("meta.default.description") },
      { vmid: "og:title", property: "og:title", content: VueI18n.t("meta.default.title") },
      { vmid: "og:description", property: "og:description", content: VueI18n.t("meta.default.description") },
      { vmid: "og:url", property: "og:url", content: "https://3space.art" }
    ]
  }
}
</script>

<style lang="scss">
  // Import Bootstrap an BootstrapVue CSS files (order is important)
  @import "~bootstrap-vue/dist/bootstrap-vue.css";

  // Import Main styles for this application
  @import "assets/scss/style";
</style>
