<template>
  <div class="d-inline-block">
    <b-button squared @click="show()">
      전시공간 관리
    </b-button>
    <b-modal
      id="artpoolPlaceSettingModal"
      ref="artpoolPlaceSettingModal"
      hide-header
      hide-footer
      centered
      body-class="text-center text-black"
      @hide="modalEvent('close')"
      @show="modalEvent('open')"
    >
      <CRow>
        <CCol class="col-12">
          <CLink @click="$refs.artpoolPlaceSettingModal.hide()" class="float-right">
            <img src="/img/threespace/icon/x-icon.png" />
          </CLink>
        </CCol>
      </CRow>
      <div class="">
        <div class="modal_title">
          <h4 class="mb-3">전시 공간 관리</h4>
        </div>

        <div class="artpool_place_form">
          <b-table-simple bordered small>
            <tr>
              <b-th variant="secondary">
                장소 명 <span class="text-danger">*</span>
              </b-th>
              <b-td>
                <b-form-input v-model="registerParams.placeName" size="sm" />
              </b-td>
              <b-th variant="secondary">장소 영문명</b-th>
              <b-td>
                <b-form-input v-model="registerParams.placeNameEn" size="sm" />
              </b-td>
            </tr>
            <tr>
              <b-th variant="secondary">
                장소 설명 
              </b-th>
              <b-td colspan="3">
                <b-form-textarea
                  rows="2"
                  max-rows="4"
                  v-model="registerParams.description" 
                  size="sm"
                ></b-form-textarea>
              </b-td>
            </tr>
            <tr>
              <b-th variant="secondary">장소 영문설명</b-th>
              <b-td colspan="3">
                <b-form-textarea
                  rows="2"
                  max-rows="4"
                  v-model="registerParams.descriptionEn" 
                  size="sm"
                ></b-form-textarea>
              </b-td>
            </tr>
            <tr>
              <b-td colspan="4" class="text-right">
                <template v-if="updateMode">
                  <b-button
                    @click="resetParams"
                    class="mr-1"
                    variant="secondary"
                  >
                    취소
                  </b-button>
                  <b-button
                    @click="onClickPlaceUpdateSave"
                    class="text-white"
                    variant="primary"
                  >
                    수정
                  </b-button>
                </template>
                <template v-else>
                  <b-button
                    @click="onClickPlaceSave"
                    class="text-white"
                    variant="primary"
                  >
                    저장
                  </b-button>
                </template>
              </b-td>
            </tr>
          </b-table-simple>

          <h6 class="text-left">전시 공간 목록</h6>
          <b-table-simple small>
            <template v-for="(item, i) in placeList">
              <b-tr :key="i">
                <b-td>{{ item.idx }}</b-td>
                <b-td>{{ item.placeName }}</b-td>
                <b-td>{{ item.placeNameEn }}</b-td>
                <b-td>
                  <b-button
                    class="px-1 py-0 text-white mr-1"
                    variant="info"
                    @click="onClickPlaceUpdate(item)"
                  >
                    <small>수정</small>
                  </b-button>
                  <b-button
                    class="px-1 py-0 text-white"
                    variant="danger"
                    @click="onClickPlaceDelete(item)"
                  >
                    <small>삭제</small>
                  </b-button>
                </b-td>
              </b-tr>
            </template>
          </b-table-simple>
        </div>
      </div>
      <b-button @click="hide">
        닫기
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  props: {},
  data: function() {
    return {
      registerParams: {
        idx: 0,
        placeName: "",
        placeNameEn: "",
        description: "",
        descriptionEn: ""
      },
      updateMode: false
    };
  },
  computed: {
    ...mapState("artpoolPlaceManager", [
      "placeList",
      "placeInfo",
    ])
  },
  methods: {
    ...mapActions("artpoolPlaceManager", [
      "getplaces",
      "getPlace",
      "addPlace",
      "updatePlace",
      "deletePlace",
    ]),
    onClickPlaceSave() {
      this.addPlace(this.registerParams).then(() => {
        alert("아트풀 전시 공간 저장 성공")
        this.resetParams();
        this.getplaces().then();
      })
    },
    onClickPlaceUpdate(item) {
      this.updateMode = true;
      this.registerParams.idx = item.idx;
      this.registerParams.placeName = item.placeName;
      this.registerParams.placeNameEn = item.placeNameEn;
      this.registerParams.description = item.description;
      this.registerParams.descriptionEn = item.descriptionEn;
    },
    onClickPlaceUpdateSave() {
      this.updatePlace(this.registerParams).then(() => {
        alert("아트풀 전시 공간 수정 성공")
        this.resetParams();
        this.getplaces().then();
      })
    },
    resetParams() {
      this.updateMode = false;
      this.registerParams = {
          idx: 0,
          placeName: "",
          placeNameEn: "",
          description: "",
          descriptionEn: ""
        }
    },
    onClickPlaceDelete(item) {
      if (confirm("정말 삭제하시겠습니까? \n아트풀 컬렉션에서 사용중이라면 삭제가 거부 될 수 있습니다.")) {
        this.deletePlace(item.idx).then(() => {
          alert("아트풀 전시 공간 삭제 성공")
          this.getplaces().then();
        })
      }
    },
    show() {
      this.$refs["artpoolPlaceSettingModal"].show();
    },
    hide() {
      this.$refs["artpoolPlaceSettingModal"].hide();
    },
    modalEvent(event) {
      if (event === "open") {
        this.getplaces().then();
      } else if (event === "close") {
        this.resetParams();
      }

    }
  }
};
</script>

