import ApiService from "@/services/api.service";
import logger from "@/logger";

// action types
export const GET_PRODUCTS = "getProducts";
export const GET_PRODUCT = "getProduct";
export const ADD_PRODUCT = "addProduct";
export const UPDATE_PRODUCT = "updateProduct";
export const UPDATE_PRODUCT_RESOURCE = "updateProductResource";
export const DELETE_PRODUCT = "deleteProduct";
// Ethereum NFT
export const UPDATE_PRODUCT_ETHEREUM_NFT = "updateProductEthereumNFT";
export const MINT_ETHEREUM_NFT = "mintEthereumNFT";
export const TRANSFER_ETHEREUM_NFT = "transferEthereumNFT";
// Polygon NFT
export const UPDATE_PRODUCT_POLYGON_NFT = "updateProductPolygonNFT";
export const MINT_POLYGON_NFT = "mintPolygonNFT";
export const TRANSFER_POLYGON_NFT = "transferPolygonNFT";
// Klaytn NFT
export const UPDATE_PRODUCT_KLAYTN_NFT = "updateProductKlaytnNFT";
export const MINT_KLAYTN_NFT = "mintKlaytnNFT";
export const TRANSFER_KLAYTN_NFT = "transferKlaytnNFT";
// KLIP NFT
export const UPDATE_PRODUCT_KLIP_CARD = "updateProductKlipCard";
export const MINT_KLIP_NFT = "mintKlipNFT";
export const TRANSFER_KLIP_NFT = "transferKlipNFT";
export const BURN_KLIP_NFT = "burnKlipNFT";
export const MINT_KLIP_NFT_REQUEST = "requestMintKlipNFT";
// ORDINALS NFT
export const UPDATE_PRODUCT_ORDINALS_NFT = "updateProductOrdinalsNFT"
// corporation
export const GET_CORP_ARTWORKS = "getCorpArtworks";

// corporation
export const COLLECTION_UPDATE_UPLOAD = "collectionUpdateUpload";

// mutation types
export const SET_PRODUCTS = "setProducts";
export const SET_PRODUCT = "setProduct";
// corporation
export const SET_CORP_ARTWORKS = "setCorpArtworks";

// page 뒤로가기 시 페이지 그대로
export const SET_PRODUCT_PREVIOUS_PARAMS = "setProductPreviousParams";

const state = {
  errors: null,
  productTableItems: {
    content: [],
    first: true,
    last: false,
    number: 0,
    numberOfElements: 0,
    size: 0,
    sort: {
      orders: []
    },
    total: 0,
    totalElements: 0,
    totalPages: 1
  },
  productTableFields: [
    { key: "index", label: "번호", class: "text-center", thStyle: "width:5%" },
    { key: "updatedAt", label: "최근 수정일시", class: "text-center", thStyle: "width:142px" },
    { key: "idx", label: "작품번호", class: "text-center", thStyle: "width:76px" },
    { key: "network", label: "네트워크", class: "text-center", thStyle: "width:118px" },
    { key: "tokenId", label: "NFT Token ID", class: "text-center", thStyle: "width:86px" },
    { key: "productName", label: "작품명" },
    { key: "productNameEn", label: "작품명(영문)" },
    { key: "artistNameEn", label: "작가명(영문)" },
    { key: "productPrice", label: "판매가", tdClass: "text-right", thStyle: "width:120px" },
    { key: "display", label: "갤러리", class: "text-center", thStyle: "width:70px" },
    { key: "status", label: "상태", class: "text-center", thStyle: "width:80px" },
    { key: "actions", label: "Actions", class: "text-center", thStyle: "width:110px" }
  ],
  productInfo: null,
  // corporation
  artworkTableItems: {
    content: [],
    first: true,
    last: false,
    number: 0,
    numberOfElements: 0,
    size: 0,
    sort: {
      orders: []
    },
    total: 0,
    totalElements: 0,
    totalPages: 1
  },
  artworkTableFields: [
    { key: "index", label: "번호", class: "text-center", thStyle: "width:52px" },
    { key: "updatedAt", label: "최근 수정일시", class: "text-center", thStyle: "width:148px" },
    { key: "idxCompany", label: "법인번호", class: "text-center", thStyle: "width:82px" },
    { key: "artistName", label: "작가명", thStyle: "width:100px" },
    { key: "productName", label: "작품명" },
    { key: "productPrice", label: "판매가", tdClass: "text-right", thStyle: "width:100px" },
    { key: "status", label: "상태", class: "text-center", thStyle: "width:80px" },
    { key: "orderDate", label: "주문일시", class: "text-center", thStyle: "width:148px" },
    { key: "payType", label: "결제수단", class: "text-center", thStyle: "width:80px" },
    { key: "settleCoinStatus", label: "코인정산", class: "text-center", thStyle: "width:80px" },
    { key: "referral", label: "레퍼럴", class: "text-center", thStyle: "width:70px" },
    { key: "artworkUrl", label: "작품 URL(Referral)" },
    { key: "actions", label: "Actions", class: "text-center", thStyle: "width:76px" }
  ],
  previousParams: {}
};

const getters = {};

const actions = {
  [GET_PRODUCTS](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("/management/v1/products", payload)
        .then(({ data }) => {
          context.commit(SET_PRODUCTS, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [GET_PRODUCT](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("/management/v1/products/" + payload)
        .then(({ data }) => {
          context.commit(SET_PRODUCT, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [ADD_PRODUCT](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("/management/v1/products", payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [UPDATE_PRODUCT](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.put("/management/v1/products/" + payload.idx, payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [UPDATE_PRODUCT_RESOURCE](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post_upload_file("/management/v1/products/resource", payload)
        .then(({ data }) => {
          logger.log("[UPDATE_PRODUCT_RESOURCE] ", data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [DELETE_PRODUCT](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.delete("/management/v1/products", payload)
        .then(({ data }) => {
          logger.log("[DELETE_PRODUCT] ", data);
          resolve(data);
        })
        .catch(response => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [UPDATE_PRODUCT_ETHEREUM_NFT](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.put("/management/v1/products/ethereum/" + payload.idx, payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [MINT_ETHEREUM_NFT](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("/management/v1/products/ethereum-nft-mint", payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [TRANSFER_ETHEREUM_NFT](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("/management/v1/products/ethereum-nft-transfer", payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [UPDATE_PRODUCT_POLYGON_NFT](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.put("/management/v1/products/polygon/" + payload.idx, payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [MINT_POLYGON_NFT](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("/management/v1/products/polygon-nft-mint", payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [TRANSFER_POLYGON_NFT](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("/management/v1/products/polygon-nft-transfer", payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [UPDATE_PRODUCT_KLAYTN_NFT](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.put("/management/v1/products/klaytn/" + payload.idx, payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [MINT_KLAYTN_NFT](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("/management/v1/products/klaytn-nft-mint", payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [TRANSFER_KLAYTN_NFT](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("/management/v1/products/klaytn-nft-transfer", payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [UPDATE_PRODUCT_KLIP_CARD](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.put("/management/v1/products/klip-card/" + payload.idx, payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [MINT_KLIP_NFT](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("/management/v1/products/klip-nft-mint", payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [MINT_KLIP_NFT_REQUEST](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("/management/v1/products/klip-nft-mint-request", payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [TRANSFER_KLIP_NFT](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("/management/v1/products/klip-nft-transfer", payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [BURN_KLIP_NFT](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("/management/v1/products/klip-nft-burn", payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [UPDATE_PRODUCT_ORDINALS_NFT](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.put("/management/v1/products/ordinals/" + payload.idx, payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [GET_CORP_ARTWORKS](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("/corp-management/v1/artworks", payload)
        .then(({ data }) => {
          context.commit(SET_CORP_ARTWORKS, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [COLLECTION_UPDATE_UPLOAD](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("/management/v1/products/collection/update/upload", payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [SET_PRODUCT_PREVIOUS_PARAMS](context, payload) {
    context.commit(SET_PRODUCT_PREVIOUS_PARAMS, payload);
  },
};

const mutations = {
  [SET_PRODUCTS](state, productList) {
    state.productTableItems = productList;
  },
  [SET_PRODUCT](state, productInfo) {
    state.productInfo = productInfo;
  },
  [SET_CORP_ARTWORKS](state, corpArtworkList) {
    state.artworkTableItems = corpArtworkList;
  },
  [SET_PRODUCT_PREVIOUS_PARAMS](state, previousParams) {
    state.previousParams = previousParams;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
