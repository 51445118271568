import Vue from "vue";
import qs from "qs";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/services/jwt.service";
import logger from "@/logger";
import store from "@/store";
import router from "@/router";
/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = process.env.VUE_APP_BACKEND_API_URL;

    Vue.axios.interceptors.response.use(
      res => {
        //TODO logger.log("Response Interceptor: ", res);
        return res;
      },
      error => {
        logger.error(
          error,
          error.response.status,
          error.response.data.exception
        );

        let current = router.currentRoute.name;
        if (error instanceof Error) {
          if (error.response) {
            if (
              401 === error.response.status ||
              403 === error.response.status
            ) {
              // 인증 실패 또는 권한 없음
              store.dispatch("auth/logout").then(() => {
                if (current !== "Login") {
                  router.push({ name: "Login" });
                }
              });
            } else if (500 === error.response.status) {
              // 서버오류
              if (
                error.response.data.exception ===
                "io.jsonwebtoken.ExpiredJwtException"
              ) {
                store.dispatch("auth/logout").then(() => {
                  // if (current !== "Drops") {
                  //   router.push({ name: "Drops" });
                  // }
                });
              } else {
                return Promise.reject(error);
              }
            } else {
              return Promise.reject(error);
            }
          } else {
            if (current !== "Drops") {
              router.push({ name: "Drops" });
            }
            return Promise.reject(error);
          }
        } else {
          if (401 === error.response.status) {
            store.dispatch("auth/logout").then(() => {
              if (current !== "Login") {
                router.push({ name: "Login" });
              }
            });
          } else if (500 === error.response.status) {
            if (
              error.response.data.exception ===
              "io.jsonwebtoken.ExpiredJwtException"
            ) {
              store.dispatch("auth/logout").then(() => {
                // if (current !== "Drops") {
                //   router.push({ name: "Drops" });
                // }
              });
            } else {
              return Promise.reject(error);
            }
          } else if (400 === error.response.status) {
            return Promise.reject(error.response);
          } else {
            return Promise.reject(error);
          }
        }
      }
    );
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    Vue.axios.defaults.headers.common["Authorization"] = JwtService.getToken()
      ? `bearer ${JwtService.getToken()}`
      : "";
  },

  query(resource, params) {
    return Vue.axios.get(resource, params);
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  get(resource, params) {
    return Vue.axios.get(`${resource}`, {
      params,
      paramsSerializer: params =>
        qs.stringify(params, { arrayFormat: "repeat" })
    });
  },

  get_download_file(resource, params) {
    return Vue.axios.get(`${resource}`, {
        params,
        paramsSerializer: params => qs.stringify(params, { arrayFormat: "repeat" }),
        responseType: 'blob'
    });
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    return Vue.axios.post(`${resource}`, params);
  },

  /**
   * Set the POST HTTP request file upload
   * @param resource
   * @param params
   * @returns {*}
   */
  post_upload_file(resource, params) {
    return Vue.axios.post(`${resource}`, params, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  },

  /**
   * Set the POST HTTP request file download
   * @param resource
   * @param params
   * @returns {*}
   */
  post_download_file(resource, params) {
    return axios({
      method: "POST",
      url: `${resource}`,
      data: params,
      responseType: "blob"
    });
  },
  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },

  /**
   * Send the PATCH HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  patch(resource, params) {
    return Vue.axios.patch(`${resource}`, params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  delete(resource, params) {
    return Vue.axios.delete(resource, { data: params });
  }
};

export default ApiService;
