<template>
  <router-view></router-view>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {
    this.type = this.$route.query.type;
    this.existsSnsType = this.$route.query.existsSnsType;
    this.snsType = this.$route.query.snsType;
    this.idxMember = this.$route.query.idxMember;
    this.email = this.$route.query.email;
    this.mobile = this.$route.query.mobile;
    this.message = this.$route.query.message;
    this.token = this.$route.query.token;
    // 레퍼럴 업데이트 상태
    this.updatedReferral = this.$route.query.updatedReferral !== undefined ? this.$route.query.updatedReferral : false;

    if (this.type === undefined) this.$router.push("/error/500");

    const tokenSet = {
      type: this.type,
      existsSnsType: this.existsSnsType,
      snsType: this.snsType,
      idxMember: this.idxMember,
      email: this.email,
      mobile: this.mobile,
      message: this.message,
      token: this.token,
      updatedReferral: this.updatedReferral
    };

    // 회원가입 & 이벤트 참여하기
    if (this.type !== undefined && this.type === "event-join") {
      const eventUserInfo = {
        snsType: this.snsType,
        idxMember: this.idxMember,
        account: this.email,
        mobile: this.mobile,
        message: this.message,
        isAuthSns: true
      };
      window.opener.app.$root.$store.commit("setEventUserInfo", eventUserInfo);
      if (
        this.message !== undefined &&
        this.message === "EVENT_RESULT_EXISTS"
      ) {
        window.opener.app.$root.$store.commit("setParticipatedEvent", true);
      }
      window.close();
    } else {
      this.$root.$store.dispatch("auth/getToken", tokenSet);
    }
  }
};
</script>
