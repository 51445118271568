<template>
  <div id="PacePool">
    <!-- pace pool menu ========================= -->

    <h1 class="title">$PACE 2.0</h1>

    <article class="pace">
      <section>
        <p class="title">
          {{ $t("pacepool.sliderTitle01") }}
        </p>
        <p class="description">{{ $t("pacepool.sliderContent01") }}</p>
      </section>
      <section>
        <p class="title">
          {{ $t("pacepool.sliderTitle02") }}
        </p>
        <p class="description">{{ $t("pacepool.sliderContent02") }}</p>
      </section>
      <section>
        <p class="title">
          {{ $t("pacepool.sliderTitle03") }}
        </p>
        <p class="description">{{ $t("pacepool.sliderContent03") }}</p>
      </section>
      <section>
        <p class="title">
          {{ $t("pacepool.sliderTitle04") }}
        </p>
        <p class="description">{{ $t("pacepool.sliderContent04") }}</p>
      </section>
      <section>
        <p class="title">
          {{ $t("pacepool.sliderTitle05") }}
        </p>
        <p class="description">{{ $t("pacepool.sliderContent05") }}</p>
      </section>
    </article>
    <article class="staking" ref="staking">
      <div>
        <section class="content">
          <p class="title">$PACE STAKING</p>
          <p class="description">{{ $t("pacepool.stakingInfo") }}</p>

          <Button :onClick="onClickStakingBtn" label="Go to Pool" />
        </section>
        <section class="staking-img">
          <img src="/img/threespace/about/staking-scheme.png" />
        </section>
        <!-- <div class="staking-img mobile">
          <img src="/img/threespace/about/staking-scheme-mobile.png" />
        </div> -->
      </div>
    </article>

    <div class="roadmap-section" ref="roadmap">
      <div class="roadmap-wrap">
        <section>
          <p class="title">ROADMAP</p>
        </section>
        <section>
          <section class="roadmap-item">
            <div class="header">
              <p>{{ $t("pacepool.roadmapTitle01") }}</p>
            </div>
            <div class="content" v-html="$t(`pacepool.roadmapContent01`)" />
          </section>
        </section>
        <section>
          <section class="roadmap-item">
            <div class="header">
              <p>{{ $t("pacepool.roadmapTitle02") }}</p>
            </div>
            <div class="content" v-html="$t(`pacepool.roadmapContent02`)" />
            <div class="header">
              <p>{{ $t("pacepool.roadmapTitle03") }}</p>
            </div>
            <div class="content" v-html="$t(`pacepool.roadmapContent03`)" />
            <div class="header">
              <p>{{ $t("pacepool.roadmapTitle04") }}</p>
            </div>
            <div class="content" v-html="$t(`pacepool.roadmapContent04`)" />
            <div class="header">
              <p>{{ $t("pacepool.roadmapTitle05") }}</p>
            </div>
            <div class="content" v-html="$t(`pacepool.roadmapContent05`)" />
          </section>
        </section>
        <section>
          <section class="roadmap-item">
            <div class="header">
              <p>{{ $t("pacepool.roadmapTitle06") }}</p>
            </div>
            <div class="content" v-html="$t(`pacepool.roadmapContent06`)" />
          </section>
        </section>

        <section>
          <section class="roadmap-item">
            <div class="header">
              <p>{{ $t("pacepool.roadmapTitle07") }}</p>
            </div>
            <div class="content" v-html="$t(`pacepool.roadmapContent07`)" />
            <div class="header">
              <p>{{ $t("pacepool.roadmapTitle08") }}</p>
            </div>
            <div class="content" v-html="$t(`pacepool.roadmapContent08`)" />
          </section>
        </section>
        <!-- <template v-for="i in 5">
          <div class="roadmap-item" :key="i">
            <div class="date">{{ $t(`pacepool.roadmapTitle0${i}`) }}</div>
            <div
              class="description"
              v-html="$t(`pacepool.roadmapContent0${i}`)"
            />
          </div>
        </template> -->
      </div>
    </div>

    <!-- pace pool top btn ========================= -->
    <aside
      id="PaceTopBtn"
      :class="{
        active: isMobile ? scrollPosition > 400 : scrollPosition > 1000,
      }"
      @click="onClickTopBtn"
    >
      TOP
    </aside>
  </div>
</template>

<script>
import Slick from "vue-slick";
import "slick-carousel/slick/slick.css";
import Button from "@/views/threespace/components/common/Button.vue";

export default {
  name: "PacePool",
  metaInfo() {
    return {
      title: this.$t("meta.artPool.title") + " | 3space Art",
      meta: [
        {
          vmid: "title",
          name: "title",
          content: this.$t("meta.artPool.title") + " | 3space Art",
        },
        {
          vmid: "description",
          name: "description",
          content: this.$t("meta.artPool.description"),
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: this.$t("meta.artPool.title") + " | 3space Art",
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: this.$t("meta.artPool.description"),
        },
        {
          vmid: "og:url",
          property: "og:url",
          content: window.location.origin + this.$route.path,
        },
      ],
    };
  },
  components: {
    Slick,
    Button,
  },
  data: function () {
    return {
      slickOptions: {
        dots: true,
        infinite: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        arrows: false,
        speed: 500,
      },
      mobileSlickOptions: {
        dots: true,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        arrows: false,
        speed: 500,
      },
      scrollPosition: 0,
      visible0: true,
      visible1: false,
      visible2: false,
      visible3: false,
      visible4: false,
    };
  },
  computed: {
    isMobile() {
      return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    },
    _slickOptions() {
      if (this.isMobile) {
        return this.mobileSlickOptions;
      } else {
        return this.slickOptions;
      }
    },
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.scrollPosition = window.scrollY;
    },
    scrollMeTo(refName) {
      var element = this.$refs[refName];
      element.scrollIntoView({ behavior: "smooth" });
    },
    onClickTopBtn() {
      this.$refs["pace_menu"].scrollIntoView({ behavior: "smooth" });
    },
    onClickStakingBtn() {
      this.$router.push("/art-pool/pace");
    },
    showItem(i) {
      for (let j = 0; j < 5; j++) {
        const text = `visible${j}`;
        if (i === j) {
          this[text] = !this[text];
        } else {
          this[text] = false;
        }
      }
    },
    getWidth(i) {
      const pi = 3.141592;
      const squares = 32;
      const angle = pi / (squares - i);
      const ratio = 1 / (Math.sin(angle) + Math.cos(angle));

      return Math.pow(ratio, i) * 50 + "vmin";
    },
    getHeight(i) {
      const pi = 3.141592;
      const squares = 32;
      const angle = pi / (squares - i);
      const ratio = 1 / (Math.sin(angle) + Math.cos(angle));

      return Math.pow(ratio, i) * 100 + "vmin";
    },
    getTransform(i) {
      const pi = 3.141592;
      const squares = 32;
      const angle = pi / (squares - i);

      return `translate(-50%, -50%) rotate(70deg)`;
    },
  },
  filters: {},
};
</script>
