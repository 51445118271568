<template>
  <CCard>
    <CCardBody>
      <CRow>
        <CCol class="mb-3"
          ><h5>작품 상세 - <span class="text-danger">개발중</span></h5>
        </CCol>
      </CRow>
      <CRow>
        <CCol col="7">
          <table class="table">
            <tbody>
              <tr>
                <th class="col-md-3">작품번호</th>
                <td class="col-md-9">{{ productInfo.idx }}</td>
              </tr>
              <tr>
                <th>분류</th>
                <td>
                  <b-form-group class="mb-0">
                    <b-form-checkbox-group
                      v-model="productInfo.clusters"
                      :options="commonCodes['PRODUCT_CLUSTER']"
                      disabled
                    ></b-form-checkbox-group>
                  </b-form-group>
                </td>
              </tr>
              <tr>
                <th class="col-md-3">작품명</th>
                <td class="col-md-9">{{ productInfo.productName }}</td>
              </tr>
              <tr>
                <th>작품명(영문)</th>
                <td>{{ productInfo.productNameEn }}</td>
              </tr>
              <tr>
                <th>작가명</th>
                <td>{{ productInfo.artistName }}</td>
              </tr>
              <tr>
                <th>작가 사용자 이름</th>
                <td>@{{ productInfo.artistNickName }}</td>
              </tr>
              <tr>
                <th>판매가</th>
                <td>&#8361; {{ productInfo.productPrice | currency }}</td>
              </tr>
              <tr>
                <th>작품 사이즈</th>
                <td>
                  {{ productInfo.productSizeX }}
                  {{
                    productInfo.productSizeX !== undefined &&
                    productInfo.productSizeY !== undefined
                      ? "X"
                      : ""
                  }}
                  {{ productInfo.productSizeY }}
                </td>
              </tr>
              <tr>
                <th>작품 제작연도</th>
                <td>{{ productInfo.paintedAt }}</td>
              </tr>
              <tr>
                <th>작품 음향</th>
                <td class="form-group">
                  <CInputRadioGroup
                    inline
                    :options="soundOptions"
                    :checked.sync="productInfo.productSound"
                  />
                </td>
              </tr>
              <tr>
                <th>작품 설명</th>
                <td>
                  <p
                    v-html="replaceNewLine(productInfo.productDescription)"
                  ></p>
                </td>
              </tr>
              <tr>
                <th>작품 설명(영문)</th>
                <td>{{ productInfo.productDescriptionEn }}</td>
              </tr>
              <tr>
                <th>작품 기법</th>
                <td>{{ productInfo.productMoreDescription }}</td>
              </tr>
              <!--
              <tr>
                <th>작품 추가 설명(영문)</th>
                <td>{{ productInfo.productMoreDescriptionEn }}</td>
              </tr>
              -->
              <tr>
                <th>기본</th>
                <td class="form-group">
                  <CInputRadioGroup
                    inline
                    :options="displayOptions"
                    :checked.sync="productInfo.display"
                  />
                </td>
              </tr>
              <tr>
                <th>상태</th>
                <td class="form-group">
                  <CInputRadioGroup
                    inline
                    :options="statusOptions"
                    :checked.sync="productInfo.status"
                  />
                </td>
              </tr>
              <tr>
                <th class="border-bottom">공개일</th>
                <td class="border-bottom">
                  <CForm inline>
                    <CInputRadioGroup
                      inline
                      class="float-left"
                      :options="displayDateOptions"
                      :checked.sync="productInfo.displayNow"
                    />
                    <template v-if="productInfo.displayNow === 'false'">
                      {{ productInfo.displayDatetime }}
                    </template>
                  </CForm>
                </td>
              </tr>
              <tr>
                <th>Featured</th>
                <td>
                  <CInputRadioGroup
                    inline
                    :options="featuredOptions"
                    :checked.sync="productInfo.featured"
                  />
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  ※ "기본(공개) + 상태(판매가능/종료) + 공개일(현재일 이전)"인
                  작품이 갤러리에 표시됩니다.
                </td>
              </tr>
            </tbody>
          </table>
        </CCol>
        <CCol col="5">
          <strong>작품 이미지</strong>
          <br />
          {{ productInfo.productFileName }}
          <span v-if="productInfo.productFileType"
            >({{ productInfo.productFileType }})</span
          >
          <span v-else>작품파일이 존재하지 않습니다.</span>
          <div class="mt-1 custom_size_product_image">
            <video
              poster="/img/threespace/media/poster_default.png"
              :src="productInfo.threespaceImageUrl"
              type="video/mp4"
              loop
              autoplay
              @contextmenu.prevent.stop
              width="100%"
              height="200px"
              playsinline
            ></video>
          </div>
          <br />
          <strong>작품파일 IPFS URL</strong>
          <br />
          {{ productInfo.threespaceImageUrl }}
          <br /><br />
          <strong>작품파일 IPFS 메타데이터 URL</strong>
          <br />
          {{ productInfo.threespaceMetadataUrl }}
          <br /><br />
          <strong>카이아 NFT 정보</strong>
          <br />
          <template
            v-if="
              productInfo.receipts !== null && productInfo.receipts.length > 0
            "
          >
            <CToaster
              v-for="(item, key) in productInfo.receipts"
              position="static"
              :key="'items' + key"
              class="px-0"
            >
              <CToast :key="'item' + item.idx" :show="true">
                <table>
                  <tr>
                    <td colspan="3">
                      <b>SPACE(#{{ item.tokenId }})</b> {{ item.createdAt }}
                    </td>
                  </tr>
                  <tr>
                    <th>From</th>
                    <td colspan="2" class="pl-1">{{ item.fromAddr }}</td>
                  </tr>
                  <tr>
                    <th>To</th>
                    <td class="pl-1">{{ item.toAddr }}</td>
                    <td class="pl-2">
                      <a :href="item.klaytnScopeUrl" target="_blank">
                        <CIcon name="cil-external-link" />
                      </a>
                    </td>
                  </tr>
                </table>
              </CToast>
            </CToaster>
            <div class="mt-3">
              <CIcon name="cil-info" class="mr-2" />반드시 카이아 주소를
              확인후 사용자를 선택해주세요.
            </div>
            <CInput
              placeholder="NFT 수신자를 선택해주세요."
              :lazy="false"
              :readonly="true"
              :value.sync="$v.nftInfo.nickname.$model"
              :isValid="checkIfValid('nickname')"
              v-model="nftInfo.nickname"
              invalidFeedback="필수 정보입니다."
              class="mt-1"
            >
              <template #prepend>
                <CButton color="primary" @click="onClickMemberSelect()">
                  <CIcon name="cil-magnifying-glass" />
                </CButton>
              </template>
              <template #append>
                <CButton
                  color="info"
                  @click="onClickTransferNFT()"
                  :disabled="true"
                >
                  <CIcon name="cil-send" class="mr-2" />NFT 전송(삭제 예정)
                </CButton>
              </template>
            </CInput>
          </template>
          <template v-else>
            NFT 정보가 존재하지 않습니다. <br /><br />
            <CIcon name="cil-info" class="mr-2 text-danger" />NFT 발행시 "<span
              class="text-danger"
              >작품파일 IPFS 메타데이터 URL</span
            >"은 필수 정보입니다.
            <br />
            <CButton
              color="info"
              class="mt-1"
              @click="onClickCreateNFT()"
              :disabled="true"
            >
              <CIcon name="cil-loop-circular" class="mr-2" />NFT 발행(삭제 예정)
            </CButton>
          </template>
        </CCol>
      </CRow>
      <CRow>
        <CCol col="12" class="text-center my-4">
          <CButton color="light" @click="onClickCancel()" class="mr-2">
            <CIcon name="cil-arrow-circle-left" class="mr-2" />확인
          </CButton>
          <CButton color="dark" @click="onClickModify()" class="mr-2">
            <CIcon name="cil-pencil" class="mr-2" />수정
          </CButton>
        </CCol>
      </CRow>
    </CCardBody>

    <MemberSelectPopup
      ref="memberSelectPopup"
      @member-selected="selectedMemberInfo"
    />
  </CCard>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { createNamespacedHelpers } from "vuex";
const productHelper = createNamespacedHelpers("product");
const codeHelper = createNamespacedHelpers("code");

import MemberSelectPopup from "@/views/management/member/MemberSelectPopup";

export default {
  name: "ProductDetail",
  components: {
    MemberSelectPopup
  },
  props: {
    idxProduct: {
      type: String,
      default: "0"
    }
  },
  data: function() {
    return {
      productInfo: {
        idx: null,
        productName: null,
        productNameEn: null,
        idxMember: null,
        artistName: null,
        artistNickName: null,
        productPrice: null,
        productSizeX: null,
        productSizeY: null,
        paintedAt: null,
        productSound: "false",
        productDescription: null,
        productDescriptionEn: null,
        display: "false",
        displayNow: "true",
        displayDate: null,
        displayDateHour: "00",
        displayDateMinute: "00",
        displayDatetime: null,
        productFileName: null,
        productFileType: null,
        productMoreDescription: null,
        productMoreDescriptionEn: null,
        threespaceImageUrl: null,
        threespaceMetadataUrl: null,
        status: "PREPARING",
        featured: "false",
        threespaceNftPublish: false,
        clusters: [],
        receipts: []
      },
      soundOptions: [
        { value: "false", label: "미포함", props: { disabled: true } },
        { value: "true", label: "포함", props: { disabled: true } }
      ],
      displayOptions: [
        { value: "false", label: "비공개", props: { disabled: true } },
        { value: "true", label: "공개", props: { disabled: true } }
      ],
      displayDateOptions: [
        { value: "true", label: "현재", props: { disabled: true } },
        { value: "false", label: "예약", props: { disabled: true } }
      ],
      statusOptions: [
        { value: "PREPARING", label: "준비중", props: { disabled: true } },
        { value: "FORSALE", label: "판매중", props: { disabled: true } },
        { value: "SOLDOUT", label: "판매완료", props: { disabled: true } }
      ],
      featuredOptions: [
        { value: "false", label: "미대상" },
        { value: "true", label: "대상" }
      ],
      nftInfo: {
        idx: this.idxProduct,
        idxMember: null,
        nickname: null
      }
    };
  },
  computed: {
    ...codeHelper.mapState(["commonCodes"]),
    isValid() {
      return !this.$v.nftInfo.$invalid;
    },
    isDirty() {
      return this.$v.nftInfo.$anyDirty;
    }
  },
  mixins: [validationMixin],
  validations: {
    nftInfo: {
      nickname: { required }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.requestChildCodes(["PRODUCT_CLUSTER"]);
    });
  },
  methods: {
    ...productHelper.mapActions(["getProduct"]),
    ...codeHelper.mapActions(["getChildMultiCodes"]),
    requestChildCodes(codes) {
      this.getChildMultiCodes(codes).then(() => {
        this.requestProductItem();
      });
    },
    requestProductItem() {
      this.$log.info("idxProduct=", this.idxProduct);
      if (this.idxProduct && this.idxProduct !== "0") {
        this.getProduct(this.idxProduct).then(data => {
          this.$log.info(data);
          if (
            Object.keys(data).length !== 0 &&
            JSON.stringify(data) !== JSON.stringify({})
          ) {
            // 작품분류
            let clusters = [];
            if (
              data.clusters != undefined &&
              data.clusters != null &&
              data.clusters.length > 0
            ) {
              data.clusters.forEach(item => {
                clusters.push(item.codeValue);
              });
            }
            this.productInfo = {
              idx: data.idx,
              productName: data.productName,
              productNameEn: data.productNameEn,
              idxMember: data.idxMember,
              artistName: data.artistName,
              artistNickName: data.artistNickName,
              productPrice: data.productPrice,
              productSizeX: data.productSizeX,
              productSizeY: data.productSizeY,
              paintedAt: data.paintedAt,
              productSound: data.productSound === false ? "false" : "true",
              productDescription: data.productDescription,
              productDescriptionEn: data.productDescriptionEn,
              display: data.display === false ? "false" : "true",
              displayNow: data.displayNow === false ? "false" : "true",
              displayDatetime: data.displayDatetime,
              productFileName: data.productFileName,
              productFileType: data.productFileType,
              productMoreDescription: data.productMoreDescription,
              productMoreDescriptionEn: data.productMoreDescriptionEn,
              threespaceImageUrl: data.threespaceImageUrl,
              threespaceMetadataUrl: data.threespaceMetadataUrl,
              status: data.status,
              featured: data.featured === true ? "true" : "false",
              threespaceNftPublish: data.threespaceNftPublish,
              clusters: clusters,
              receipts: data.receipts
            };
            this.soundOptions.forEach(el => {
              if (el.value === this.productInfo.productSound) {
                el.props.disabled = false;
              }
            });
            this.displayOptions.forEach(el => {
              if (el.value === this.productInfo.display) {
                el.props.disabled = false;
              }
            });
            this.displayDateOptions.forEach(el => {
              if (el.value === this.productInfo.displayNow) {
                el.props.disabled = false;
              }
            });
            this.statusOptions.forEach(el => {
              if (el.value === this.productInfo.status) {
                el.props.disabled = false;
              }
            });
          }
        });
      }
    },
    onClickCreateNFT() {
      this.$log.info("click onClickCreateNFT()");
      if (
        this.productInfo.threespaceMetadataUrl !== undefined &&
        this.productInfo.threespaceMetadataUrl !== null &&
        this.productInfo.threespaceMetadataUrl !== ""
      ) {
        if (confirm("NFT를 발행하시겠습니까?")) {
          // this.publishProductNFT(this.nftInfo)
          //   .then(data => {
          //     if (
          //       Object.keys(data).length !== 0 &&
          //       JSON.stringify(data) !== JSON.stringify({})
          //     ) {
          //       alert("NFT가 정상 발행되었습니다.");
          //       this.$router.go(this.$router.currentRoute);
          //     } else {
          //       alert("NFT 발행 오류가 발생하였습니다. 관리자에게 문의하세요.");
          //       this.$router.go(this.$router.currentRoute);
          //     }
          //   })
          //   .catch(error => {
          //     alert("NFT 발행 오류가 발생하였습니다. 관리자에게 문의하세요.");
          //     this.$router.go(this.$router.currentRoute);
          //     this.$log.error(error);
          //   });
        }
      } else {
        alert("[작품파일 IPFS 메타데이터URL]은 필수 정보입니다.");
      }
    },
    onClickMemberSelect() {
      this.$refs["memberSelectPopup"].show();
    },
    selectedMemberInfo(item) {
      this.$log.info("selectedMemberInfo() item: {}", item);
      if (item.klaytnAddr) {
        this.nftInfo.idxMember = item.idx;
        this.nftInfo.nickname = item.name + "(" + item.nickname + ")";
      } else {
        alert("카이아 주소를 확인해주세요.");
      }
    },
    onClickTransferNFT() {
      this.$log.info("click onClickTransferNFT()");
      this.$v.$touch();
      if (this.isValid) {
        // this.transferProductNFT(this.nftInfo)
        //   .then(data => {
        //     if (
        //       Object.keys(data).length !== 0 &&
        //       JSON.stringify(data) !== JSON.stringify({})
        //     ) {
        //       alert("NFT가 정상 전송되었습니다.");
        //       this.$router.go(this.$router.currentRoute);
        //     } else {
        //       alert("NFT 전송 오류가 발생하였습니다. 관리자에게 문의하세요.");
        //       this.$router.go(this.$router.currentRoute);
        //     }
        //   })
        //   .catch(error => {
        //     alert("NFT 전송 오류가 발생하였습니다. 관리자에게 문의하세요.");
        //     this.$router.go(this.$router.currentRoute);
        //     this.$log.error(error);
        //   });
      }
    },
    onClickModify() {
      this.$router.push("/management/product-save/" + this.idxProduct);
    },
    onClickCancel() {
      this.$router.push({name: "ProductList", params: { linkType: "comeback"}});
    },
    checkIfValid(fieldName) {
      const field = this.$v.nftInfo[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    replaceNewLine(str) {
      if (str !== undefined && str !== null && str !== "") {
        return str.replace(/(?:\r\n|\r|\n)/g, "<br />");
      } else {
        return "";
      }
    }
  },
  filters: {
    currency: value => {
      if (!value) return "";
      return value.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    }
  }
};
</script>

<style scoped>
.custom_size_product_image {
  width: 100%;
  height: 210px;
  background-color: #f7f7f7;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
}
</style>
