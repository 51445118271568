<template>
  <b-modal
    id="nftUnstakeModal"
    ref="nftUnstakeModal"
    hide-header
    hide-footer
    centered
    body-class="text-center text-black"
    @hide="modalEvent(false)"
    @show="modalEvent(true)"
  >
    <CRow>
      <CCol class="col-12">
        <div class="d-flex justify-content-end">
          <img
            class="cursor-pointer"
            src="/img/threespace/icon/close.png"
            alt="close"
            @click="$refs['nftUnstakeModal'].hide()"
          />
        </div>
      </CCol>
    </CRow>
    <div class="stake_select_modal">
      <div class="title">
        {{ $t("stake.nftUnstake") }}
      </div>
      <div class="form_box" v-if="stakedCollections.length > 0">
        <div class="checkbox">
          <b-form-checkbox v-model="allSelected" @change="onCheckAllSelect">
            {{ $t("stake.stakeModal.allSelected") }}
            ({{ selectedNfts.length }}/{{ stakedCollections.length }})
          </b-form-checkbox>
        </div>
        <div class="refresh" @click="onClickRefresh">
          <img src="/img/threespace/icon/new_refresh.svg" />
        </div>
      </div>
      <div class="select_list">
        <template v-for="(item, i) in stakedCollections">
          <div
            class="select_row"
            :key="'stake-item' + i"
            :class="{ active: isSelected(item.idx) }"
            @click="onClickCard(item)"
          >
            <div class="img_box">
              <template
                v-if="
                  item.imageUrl !== undefined &&
                  item.imageUrl !== null &&
                  item.imageUrl !== ''
                "
              >
                <b-img
                  :src="getCloudFrontImageUrl(item.imageUrl)"
                  class="w-100"
                ></b-img>
              </template>
            </div>
            <div class="text_box">
              <h3>
                <template
                  v-if="activeLanguage !== undefined && activeLanguage === 'ko'"
                >
                  {{ item.title || item.titleEn }}
                </template>
                <template v-else>
                  {{ item.titleEn || item.title }}
                </template>
              </h3>
              <p class="artist">
                <template
                  v-if="activeLanguage !== undefined && activeLanguage === 'ko'"
                >
                  Artist @{{ item.artist || item.artistEn }}
                </template>
                <template v-else>
                  Artist @{{ item.artistEn || item.artist }}
                </template>
              </p>
              <p class="token_id">#{{ item.tokenId }} ({{ item.nft.rfc }})</p>
              <!-- <p class="video_icon" v-if="item.video === true">
                <v-icon>mdi-video-outline</v-icon>
              </p> -->
              <!-- <div class="select_overlay">
                <p>
                  {{ $t("stake.stakeModal.selected") }}
                </p>
              </div> -->
            </div>
          </div>
        </template>
        <template v-if="stakedCollections.length === 0">
          <div class="mt-3 d-flex justify-content-center" v-if="isBusy">
            <div class="sk-wave">
              <div class="sk-wave-rect"></div>
              <div class="sk-wave-rect"></div>
              <div class="sk-wave-rect"></div>
              <div class="sk-wave-rect"></div>
              <div class="sk-wave-rect"></div>
            </div>
          </div>
          <p class="mt-3 mb-5 font-sm text-black">{{ message }}</p>
        </template>
      </div>
      <div class="info_box" v-if="stakedCollections.length > 0 && !isBusy">
        <p class="main_info">{{ $t("stake.stakeModal.info1") }}</p>
        <div class="sub_info">
          <p class="title">
            {{ $t("stake.stakeModal.example") }}
          </p>
          <p>- {{ $t("stake.stakeModal.info2-1") }}</p>
          <p>- {{ $t("stake.stakeModal.info2-2") }}</p>
          <p>- {{ $t("stake.stakeModal.info2-3") }}</p>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center mb-4">
      <div class="mt-3" style="width: 86%">
        <Button
          :label="$t('button.next')"
          :onClick="onClickNftUnstakeBtn"
          :disabled="selectedNfts.length === 0"
        />
      </div>
    </div>
    <!-- <div>
      <b-button
        @click="onClickNftUnstakeBtn"
        class="nft_stake_modal_btn"
        :disabled="selectedNfts.length === 0"
      >
        {{ $t("button.next") }}
      </b-button>
    </div> -->
  </b-modal>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import Button from "@/views/threespace/components/common/Button.vue";

const artpoolHelper = createNamespacedHelpers("artpool");

export default {
  name: "NftPoolUnstakeSelectModal",
  props: {
    nftTypeInfo: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    Button,
  },
  data: function () {
    return {
      isBusy: false,
      message: "",
      externalParams: {
        keywordIdxArtpool: null,
        keywordRfc: null,
      },
      stakedCollections: [],
      allSelected: false,
      selectedNfts: [],
      cfOptions: {
        w: 165,
        h: 150,
        f: "webp",
        q: 90,
      },
    };
  },
  computed: {
    activeLanguage() {
      return this.getActiveLanguage();
    },
  },
  methods: {
    ...artpoolHelper.mapActions(["getMyStakedCollections"]),
    requestStakedCollections() {
      // 검색조건
      this.externalParams.keywordIdxArtpool = this.nftTypeInfo.idxArtpool;
      this.externalParams.keywordRfc = this.nftTypeInfo.rfc;

      this.getMyStakedCollections(this.externalParams)
        .then((data) => {
          this.stakedCollections = data;
          if (
            this.stakedCollections !== undefined &&
            this.stakedCollections.length > 0
          ) {
            this.message = "";
          } else {
            this.message = this.$t("stake.stakeModal.noData");
          }
          this.isBusy = false;
        })
        .catch((error) => {
          this.$log.error(error);
          this.isBusy = false;
          alert(this.$t("stake.stakeModal.error"));
        });
    },
    onCheckAllSelect() {
      if (this.allSelected) {
        this.selectedNfts = [];
        this.stakedCollections.forEach((item) => {
          this.selectedNfts.push(item);
        });
      } else {
        this.selectedNfts = [];
      }
    },
    onClickRefresh() {
      this.selectedNfts = [];
      this.allSelected = false;
    },
    onClickCard(_item) {
      const index = this.selectedNfts.findIndex(
        (item) => item.idx === _item.idx
      );
      if (index > -1) {
        this.selectedNfts.splice(index, 1);
      } else {
        //TODO 동일한 종류의 NFT만 선택 가능하도록 해야함!!
        this.selectedNfts.push(_item);
      }
      this.allSelected = false;
    },
    isSelected(_idx) {
      const index = this.selectedNfts.findIndex((item) => item.idx === _idx);
      if (index > -1) {
        return true;
      } else {
        return false;
      }
    },
    onClickNftUnstakeBtn() {
      this.$emit("onClickNftUnstakeBtn", this.selectedNfts);
    },
    show() {
      this.requestStakedCollections();
      this.$refs["nftUnstakeModal"].show();
    },
    hide() {
      this.$refs["nftUnstakeModal"].hide();
    },
    getCloudFrontImageUrl(imageUrl) {
      return `${imageUrl}?w=${this.cfOptions.w}&h=${this.cfOptions.h}&f=${this.cfOptions.f}&q=${this.cfOptions.q}`;
    },
    getActiveLanguage() {
      return localStorage.getItem("language") || "en";
    },
    modalEvent(isVisible) {
      if (isVisible) {
        this.isBusy = true;
        this.message = this.$t("stake.stakeModal.search");
      } else {
        this.isBusy = false;
        this.message = "";
      }
      this.externalParams = {};
      this.stakedCollections = [];
      this.selectedNfts = [];
      this.allSelected = false;
    },
  },
};
</script>
