<template>
  <section class="submit-button" :class="{ white: isWhite }">
    <span class="mask"> {{ label }}</span>
    <button
      @click="onClick"
      :class="buttonClass"
      :disabled="disabled"
      :style="buttonStyle"
    >
      <span>{{ label }}</span>
    </button>
  </section>
</template>

<script>
export default {
  name: "Button",
  props: {
    onClick: {
      type: Function,
      required: true,
    },
    buttonClass: {
      type: Object,
      required: false,
    },
    label: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isWhite: {
      type: Boolean,
      default: false,
    },
    buttonStyle: {
      type: Object,
      required: false,
    },
  },
};
</script>
