import ApiService from "@/services/api.service";
import logger from "@/logger";
import FileDownload from "js-file-download";

// action types
export const GET_COLLECTIONS = "getCollections";
export const GET_COLLECTIONS_ALL = "getCollectionsAll";
export const UPDATE_COLLECTION_VISIBLE = "updateCollectionVisible";
export const UPDATE_COLLECTION_OWNER = "updateCollectionOwner";
export const DOWNLOAD_COLLECTIONS = "downloadCollections"; // s3 download
export const RESET_COLLECTIONS = "resetCollections";
// 신한 꼬마피카소 전용 갤러리
export const GET_GALLERY_COLLECTIONS = "getGalleryCollections";
export const GET_GALLERY_COLLECTION = "getGalleryCollection";
export const GET_GALLERY_COLLECTIONS_RANKING = "getGalleryCollectionsRanking";
export const GET_GALLERY_COLLECTIONS_RANKING_COUNT = "getGalleryCollectionsRankingCount";
export const GET_GALLERY_COLLECTIONS_RANDOM = "getGalleryCollectionsRandom";
export const RESET_GALLERY_COLLECTIONS = "resetGalleryCollections";

// mutation types
export const SET_COLLECTIONS = "setCollections";
export const SET_COLLECTIONS_ALL = "setCollectionsAll";
// 신한 꼬마피카소 전용 갤러리
export const SET_GALLERY_COLLECTIONS = "setGalleryCollections";
export const SET_GALLERY_COLLECTION = "setGalleryCollection";
export const SET_GALLERY_COLLECTIONS_RANKING = "setGalleryCollectionsRanking";
export const SET_GALLERY_COLLECTIONS_RANDOM = "setGalleryCollectionsRandom";

const state = {
  collectionList: {
    content: [],
    first: true,
    last: false,
    number: 0,
    numberOfElements: 0,
    size: 0,
    sort: {
      orders: []
    },
    total: 0,
    totalElements: 0,
    totalPages: 1
  },
  collectionAllList: [],
  galleryCollectionList: {
    content: [],
    first: true,
    last: false,
    number: 0,
    numberOfElements: 0,
    size: 0,
    sort: {
      orders: []
    },
    total: 0,
    totalElements: 0,
    totalPages: 1
  },
  galleryCollectionInfo: {},
  galleryCollectionRankingList: [],
  galleryCollectionRandomList: [],
};

const getters = {};

const actions = {
  [GET_COLLECTIONS](context, payload) {
    /*TODO
    if (payload.page == 0) {
      context.commit(RESET_COLLECTIONS);
    }*/
    return new Promise((resolve, reject) => {
      ApiService.get("/threespace/v1/collections", payload)
        .then(({ data }) => {
          context.commit(SET_COLLECTIONS, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [GET_COLLECTIONS_ALL](context, payload) {
    /*TODO
    if (payload.page == 0) {
      context.commit(RESET_COLLECTIONS);
    }*/
    return new Promise((resolve, reject) => {
      ApiService.get("/threespace/v1/collections/all", payload)
        .then(({ data }) => {
          context.commit(SET_COLLECTIONS_ALL, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [UPDATE_COLLECTION_VISIBLE](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.put("/threespace/v1/collections/visible/update", payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [UPDATE_COLLECTION_OWNER](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.put("/threespace/v1/collection/" + payload.idxCollection, payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [DOWNLOAD_COLLECTIONS](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get_download_file("/threespace/v1/collection/" + payload)
        .then(( data ) => {
          logger.log("[DOWNLOAD_COLLECTIONS]");
          var contentDisposition = data.headers['content-disposition'];
          var filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].split('"')[1].trim();
          FileDownload(data.data, filename)
        })
        .catch(response => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [RESET_COLLECTIONS](context) {
    context.commit(RESET_COLLECTIONS);
  },
  /**
   * ////////////////////////////////////////////////////
   * 신한(꼬마피카소) 전용 갤러리 Actions
   * ////////////////////////////////////////////////////
   * */
  [GET_GALLERY_COLLECTIONS](context, payload) {
    if (payload.page === 0) {
      context.commit(RESET_GALLERY_COLLECTIONS);
    }
    return new Promise((resolve, reject) => {
      ApiService.get("/threespace/v1/collections/gallery", payload)
        .then(({ data }) => {
          context.commit(SET_GALLERY_COLLECTIONS, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [GET_GALLERY_COLLECTION](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("/threespace/v1/collections/gallery/" + payload)
        .then(({ data }) => {
          context.commit(SET_GALLERY_COLLECTION, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [GET_GALLERY_COLLECTIONS_RANKING](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("/threespace/v1/collections/gallery/ranking", payload)
        .then(({ data }) => {
          context.commit(SET_GALLERY_COLLECTIONS_RANKING, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [GET_GALLERY_COLLECTIONS_RANKING_COUNT](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("/threespace/v1/collections/gallery/ranking/count", payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [GET_GALLERY_COLLECTIONS_RANDOM](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("/threespace/v1/collections/gallery/random", payload)
        .then(({ data }) => {
          context.commit(SET_GALLERY_COLLECTIONS_RANDOM, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },

};

const mutations = {
  [SET_COLLECTIONS](state, collectionList) {
    state.collectionList = collectionList;
  },
  [SET_COLLECTIONS_ALL](state, collectionAllList) {
    state.collectionAllList = collectionAllList;
  },
  [RESET_COLLECTIONS](state) {
    state.collectionList.content = [];
  },
  /**
   * ////////////////////////////////////////////////////
   * 신한(꼬마피카소) 전용 갤러리 Mutations
   * ////////////////////////////////////////////////////
   * */
  [SET_GALLERY_COLLECTIONS](state, galleryCollectionList) {
    state.galleryCollectionList = galleryCollectionList;
  },
  [RESET_GALLERY_COLLECTIONS](state) {
    state.galleryCollectionList.content = [];
  },
  [SET_GALLERY_COLLECTION](state, galleryCollectionInfo) {
    state.galleryCollectionInfo = galleryCollectionInfo;
  },
  
  [SET_GALLERY_COLLECTIONS_RANKING](state, galleryCollectionRankingList) {
    // @NOTE 유치부 > 초등 저학년부 > 초등 고학년부 순서로 만들기 위한 사전 작업
    let orderList = galleryCollectionRankingList.map(el => {
      return {
        ...el,
        order: (el.ranking * 3 - 3) + (el.part === "유치부" ? 1 : el.part === "초등 저학년부" ? 2 : 3)
      }
    })
    state.galleryCollectionRankingList = orderList.sort(function(a, b) { // 오름차순
      return a.order - b.order;
    });
  },
  [SET_GALLERY_COLLECTIONS_RANDOM](state, galleryCollectionRandomList) {
    state.galleryCollectionRandomList = galleryCollectionRandomList;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
