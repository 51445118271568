<template>
  <b-modal
    ref="terms-modal"
    size="lg"
    centered
    scrollable
    header-bg-variant="light"
    header-class="text-black"
    body-class="text-black px-5"
    footer-class="pb-4"
  >
    <template #modal-title>
      {{ title }}
    </template>

    <div v-html="termsInfo.codeDesc"></div>

    <template #modal-footer>
      <div class="w-100 text-center">
        <b-button
          variant="primary"
          size="lg"
          squared
          class="px-5"
          @click="hide()"
        >
          <span class="text-white">{{ $t("button.close") }}</span>
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  data: function() {
    return {
      title: null
    };
  },
  props: {
    termsInfo: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    show(code) {
      if (code !== undefined) {
        if (code === "refund_policy") {
          this.title = "환불 및 취소 정책";
        }
      }
      this.$refs["terms-modal"].show();
    },
    hide() {
      this.$refs["terms-modal"].hide();
    }
  }
};
</script>
