<template>
  <div id="LoginWrap" class="h-auto">
    <div class="terms_box login_btn_box m-auto text-center pb-5">
      <h1>{{ $t("login.serviceAgree") }}</h1>
      <p v-html="replaceServiceMessage()"></p>

      <div class="row justify-content-center">
        <div class="content_box">
          <div class="join-item">
            <div class="join_label">
              {{ $t("login.username") }} <span class="text-danger">*</span>
            </div>
            <div class="w-100">
              <div
                style="
                  background-color: #f7f7f7;
                  padding: 6px;
                  margin-bottom: 10px;
                "
              >
                <CInput
                  :lazy="false"
                  :value.sync="$v.form.nickname.$model"
                  :isValid="checkIfValid('nickname')"
                  :placeholder="$t('login.nickname')"
                  autocomplete="username"
                  :invalidFeedback="$t('validation.requiredUsername')"
                  class="w-100 mb-0"
                />
              </div>
            </div>
          </div>
          <div class="join-item mt-2">
            <div class="join_label">
              {{ $t("login.email") }} <span class="text-danger">*</span>
            </div>
            <div class="w-100">
              <div
                style="
                  background-color: #f7f7f7;
                  padding: 6px;
                  margin-bottom: 10px;
                "
              >
                <CInput
                  v-model="form.email"
                  :readonly="true"
                  class="mb-0"
                  style="border: 0.7px solid #999"
                />
              </div>
            </div>
          </div>
          <div class="join-item mt-2 mb-5">
            <div class="join_label">
              {{ $t("login.mobile") }}
            </div>
            <div class="w-100">
              <div
                style="
                  background-color: #f7f7f7;
                  padding: 6px;
                  margin-bottom: 10px;
                "
              >
                <CInput
                  :lazy="false"
                  :value.sync="$v.form.mobile.$model"
                  :isValid="checkIfValid('mobile')"
                  :placeholder="$t('login.mobile')"
                  autocomplete="mobile"
                  :invalidFeedback="$t('validation.onlyNumeric')"
                  class="mb-0"
                />
              </div>
            </div>
          </div>
          <!--
          <table class="table table-borderless">
            <tr>
              <th class="col-4 align-middle p-0">
                {{ $t('login.username') }} <span class="text-danger">*</span>
              </th>
              <td class="col-8 px-0 py-1">
                <CInput
                  :lazy="false"
                  :value.sync="$v.form.nickname.$model"
                  :isValid="checkIfValid('nickname')"
                  :placeholder="$t('login.nickname')"
                  autocomplete="username"
                  :invalidFeedback="$t('validation.requiredUsername')"
                  class="mb-0"
                />
              </td>
            </tr>
            <tr>
              <th class="col-4 align-middle p-0">
                {{ $t('login.email') }} <span class="text-danger">*</span>
              </th>
              <td class="col-8 px-0 py-1">
                <CInput v-model="form.email" :readonly="true" class="mb-0" />
              </td>
            </tr>
            <tr>
              <th class="col-4 align-middle p-0">
                {{ $t('login.mobile') }}
              </th>
              <td class="col-8 px-0 py-1">
                <CInput
                  :lazy="false"
                  :value.sync="$v.form.mobile.$model"
                  :isValid="checkIfValid('mobile')"
                  :placeholder="$t('login.mobile')"
                  autocomplete="mobile"
                  :invalidFeedback="$t('validation.onlyNumeric')"
                  class="mb-0"
                />
              </td>
            </tr>
          </table>
          -->
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
            "
          >
            <CInputCheckbox
              :label="$t('login.allAgree')"
              :checked.sync="acceptCheckTotal"
              custom
              @change="onCheckTotal"
            />
            <CInputCheckbox
              :label="$t('login.termsService')"
              :checked.sync="$v.form.acceptTermsService.$model"
              :isValid="checkIfValid('acceptTermsService')"
              :invalidFeedback="$t('validation.required')"
              custom
              @change="onCheckItem"
            />
          </div>

          <div
            class="mt-2 p-2"
            style="
              height: 200px;
              overflow-y: scroll;
              border: 0.7px solid #343434;
            "
          >
            <p v-html="termsOfService" class="font-sm"></p>
          </div>

          <CInputCheckbox
            :label="$t('login.privacyPolicy')"
            :checked.sync="$v.form.acceptPrivacyPolicy.$model"
            :isValid="checkIfValid('acceptPrivacyPolicy')"
            :invalidFeedback="$t('validation.required')"
            custom
            @change="onCheckItem"
            class="mt-4"
          />
          <div
            class="mt-2 p-2"
            style="
              height: 200px;
              overflow-y: scroll;
              border: 0.7px solid #343434;
            "
          >
            <p v-html="privacyPolicy" class="font-sm"></p>
          </div>

          <CInputCheckbox
            :label="$t('login.promotion')"
            :checked.sync="form.acceptPromotion"
            custom
            @change="onCheckItem"
            class="mt-2"
          />
        </div>
      </div>

      <b-button block class="space_btn mt-5" @click="onAgreeSubmit()">
        {{ $t("login.signUp") }}
      </b-button>
    </div>

    <!-- 회원가입 완료 -->
    <b-modal
      id="joinSuccessModal"
      ref="joinSuccessModal"
      hide-header
      hide-footer
      centered
      no-close-on-esc
      no-close-on-backdrop
      body-class="text-center py-5"
    >
      <b-img src="/img/threespace/logo/logo_100x100.png"></b-img>
      <p class="font-lg font-weight-bold">
        {{ $t("login.join.successJoin") }}
      </p>
      <p>
        {{ $t("login.join.completeJoin") }}
      </p>
      <b-button
        squared
        variant="black"
        class="text-white mt-3 px-5"
        @click="onClickDrops"
      >
        {{ $t("button.gotoGallery") }}
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import {
  required,
  minLength,
  helpers,
  maxLength,
  numeric,
} from "vuelidate/lib/validators";
import { createNamespacedHelpers } from "vuex";
const codeHelper = createNamespacedHelpers("code");
const authHelper = createNamespacedHelpers("auth");

export default {
  data: function () {
    return {
      termsOfService: null,
      privacyPolicy: null,
      form: {
        idx: this.idxMember,
        nickname: null,
        email: this.email,
        mobile: this.mobile,
        acceptTermsService: false,
        acceptPrivacyPolicy: false,
        acceptPromotion: false,
      },
      acceptCheckTotal: false,
    };
  },
  props: {
    idxMember: {
      type: String,
      default: "0",
    },
    snsType: {
      type: String,
      default: "",
    },
    email: {
      type: String,
      default: "",
    },
    mobile: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...codeHelper.mapState(["codeList"]),
    isValid() {
      return !this.$v.form.$invalid;
    },
    isDirty() {
      return this.$v.form.$anyDirty;
    },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      nickname: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(15),
        strongPass: helpers.regex("strongPass", /^[a-zA-Z0-9_-]*$/),
      },
      mobile: {
        minLength: minLength(10),
        maxLength: maxLength(12),
        numeric,
      },
      acceptTermsService: { required, mustAccept: (val) => val },
      acceptPrivacyPolicy: { required, mustAccept: (val) => val },
    },
  },
  mounted() {
    this.requestCodeList();
  },
  methods: {
    ...codeHelper.mapActions(["getCodeList"]),
    ...authHelper.mapActions(["logout", "joinAccept"]),
    requestCodeList() {
      const param = {
        codes: ["terms_of_service", "privacy_policy"],
      };
      this.getCodeList(param).then((data) => {
        data.forEach((item) => {
          if (item.code === "terms_of_service") {
            this.termsOfService = item.codeDesc;
          } else if (item.code === "privacy_policy") {
            this.privacyPolicy = item.codeDesc;
          }
        });
      });
    },
    onCheckTotal() {
      this.form.acceptTermsService = this.acceptCheckTotal;
      this.form.acceptPrivacyPolicy = this.acceptCheckTotal;
      this.form.acceptPromotion = this.acceptCheckTotal;
    },
    onCheckItem() {
      // 전체 동의
      if (
        this.form.acceptTermsService &&
        this.form.acceptPrivacyPolicy &&
        this.form.acceptPromotion
      ) {
        this.acceptCheckTotal = true;
      } else {
        this.acceptCheckTotal = false;
      }
    },
    onAgreeSubmit() {
      this.$v.$touch();
      if (this.isValid) {
        this.logout(); // 로그아웃

        this.joinAccept(this.form)
          .then(() => {
            this.errorMessage = null;
            this.$refs["joinSuccessModal"].show();

            // 회원가입 완료후 레퍼럴 정보 삭제
            let referral = localStorage.getItem("referral3spaceJoin");
            if (
              referral !== undefined &&
              referral !== null &&
              referral !== ""
            ) {
              localStorage.removeItem("referral3spaceJoin");
            }
          })
          .catch(() => {
            this.$log.error(this.errors);
            this.errorMessage =
              "[" + this.errors.error + "] " + this.$t("login.join.error");
          });
      }
    },
    onClickDrops() {
      this.$router.push({ name: "Drops" });
    },
    checkIfValid(fieldName) {
      const field = this.$v.form[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    replaceServiceMessage() {
      this.$log.log("replaceServiceMessage");
      let message = this.$t("login.serviceMessage");
      this.$log.log(message);
      if (
        message !== undefined &&
        message !== null &&
        message.indexOf("{SNS_TYPE}") !== -1
      ) {
        return (message = message.replace(
          "{SNS_TYPE}",
          "<b>" + this.snsType + "</b>"
        ));
      }
      return message;
    },
  },
};
</script>
