<template>
  <div id="NftPool" class="nft-pool-pamphlet nft_pool_pamphlet">
    <section class="header">
      <h1>NFT ART POOL</h1>
      <p class="description" v-html="$t('nftpool.nftPoolMain')" />
    </section>

    <article class="title-image">
      <section class="image-wrapper">
        <div class="request-button mobile">
          <button @click="requestArtpoolSimple(artpoolIdx - 1)" />
          <button @click="requestArtpoolSimple(artpoolIdx + 1)" />
        </div>
        <img
          class="img_box"
          @click="onClickArtPoolListItem(artpoolSimpleInfo)"
          v-if="artpoolSimpleInfo.bannerImagePcEnUrl && activeLanguage === 'en'"
          :src="artpoolSimpleInfo.bannerImagePcEnUrl"
        />
        <img
          class="img_box"
          @click="onClickArtPoolListItem(artpoolSimpleInfo)"
          v-else-if="
            artpoolSimpleInfo.bannerImagePcUrl && activeLanguage === 'ko'
          "
          :src="artpoolSimpleInfo.bannerImagePcUrl"
        />
        <img
          class="img_box"
          @click="onClickArtPoolListItem(artpoolSimpleInfo)"
          v-else
          :src="`/img/threespace/artpool/artpool_round${artpoolSimpleInfo.roundNo}_banner.png`"
        />
      </section>
      <section class="description">
        <div class="request-button pc">
          <button @click="requestArtpoolSimple(artpoolIdx - 1)" />
          <button @click="requestArtpoolSimple(artpoolIdx + 1)" />
        </div>
        <p>
          In Exhibit {{ artpoolSimpleInfo.poolStartDate | moment("M.DD") }}
          &nbsp;-&nbsp;
          {{ artpoolSimpleInfo.poolEndDate | moment("M.DD") }}
        </p>
      </section>
    </article>
    <!-- Nft Pool Text Slide 영역 ==================================== -->
    <article class="marquee-container">
      <div class="marquee-item">
        <template v-for="item in 5">
          <p :key="'p' + item">
            <span :key="'f' + item">In Exhibit</span>
            <span :key="'t' + item">
              {{ artpoolSimpleInfo.poolStartDate | moment("M.DD") }}
              ~
              {{ artpoolSimpleInfo.poolEndDate | moment("M.DD") }}
            </span>
          </p>
        </template>
        <template v-for="item in 5">
          <p :key="'p2' + item">
            <span :key="'f2' + item">In Exhibit</span>
            <span :key="'t2' + item">
              {{ artpoolSimpleInfo.poolStartDate | moment("M.DD") }}
              ~
              {{ artpoolSimpleInfo.poolEndDate | moment("M.DD") }}
            </span>
          </p>
        </template>
      </div>
    </article>

    <article class="accordion-container">
      <!-- What is an Art Pool 영역 ==================================== -->
      <section :class="['accordion', { active: isWhatIsArtPoolOpen }]">
        <section class="header" @click="toggleWhatIsArtPool">
          <h2>
            <template
              v-if="activeLanguage !== undefined && activeLanguage === 'ko'"
            >
              아트풀이란?
            </template>
            <template v-else>WHAT IS AN ART POOL?</template>
          </h2>
          <div />
        </section>
        <div
          class="description"
          v-if="isWhatIsArtPoolOpen"
          v-html="$t('nftpool.whatIsArtpoolContent')"
        />
      </section>
      <!-- How to join 영역 ==================================== -->
      <section :class="['accordion', { active: isHowToJoinOpen }]">
        <section class="header" @click="toggleHowToJoin">
          <h2>
            <template
              v-if="activeLanguage !== undefined && activeLanguage === 'ko'"
            >
              아트풀 참여 방법
            </template>
            <template v-else>HOW DO I PARTICIPATE?</template>
          </h2>
          <div />
        </section>
        <div class="description section2" v-if="isHowToJoinOpen">
          <div class="item" v-for="item in 5" :key="item">
            <p class="title">{{ "0" + item }}</p>
            <p
              class="description"
              v-html="$t(`nftpool.howToJoin.step${item}Detail`)"
            />
          </div>
        </div>
      </section>

      <!-- Artist Benefits 영역 ==================================== -->
      <section :class="['accordion', { active: isArtistBenefitsOpen }]">
        <section class="header" @click="toggleArtistBenefits">
          <h2>
            <template
              v-if="activeLanguage !== undefined && activeLanguage === 'ko'"
            >
              아티스트 베네핏
            </template>
            <template v-else>ARTIST BENEFITS</template>
          </h2>
          <div />
        </section>
        <div class="description section3" v-if="isArtistBenefitsOpen">
          <div class="item">
            <p class="title">BENEFIT 1</p>
            <p class="description">
              {{ $t("nftpool.artistBenefit.benefit1") }}
            </p>
          </div>
          <div class="item">
            <p class="title">BENEFIT 2</p>
            <p class="description">
              {{ $t("nftpool.artistBenefit.benefit2") }}
            </p>
          </div>
        </div>
      </section>
    </article>

    <article class="contact-us">
      <p class="title">CONTACT US</p>
      <div class="btn-wrap">
        <Button label="APPLY AS ARTIST >>" :onClick="onClickArtistApplyPage" />
        <Button label="EXHIBIT WITH US >>" :onClick="onClickContactUsPage" />
      </div>
    </article>

    <article class="previous-exhibitions">
      <p class="title">PREVIOUS EXHIBITIONS</p>
      <section class="exhibition-list">
        <div class="slide-container">
          <div class="slide-wrapper">
            <template v-for="(item, i) in artpoolList">
              <div
                :key="'artpool-list' + i"
                @click="onClickArtPoolListItem(item)"
                class="slide"
                :class="{ active: artpoolSimpleInfo.idx === item.idx }"
              >
                <p>#{{ item.idx }}</p>

                <div class="img_box">
                  <img :src="item.thumbnailImageUrl" />
                </div>
              </div>
            </template>
          </div>
        </div>
      </section>
    </article>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import BXBubbles from "../components/backgroundEffect/Bubbles.vue";
import Button from "../components/common/Button.vue";

export default {
  name: "NftPool",
  components: { BXBubbles, Button },
  metaInfo() {
    return {
      title: this.$t("meta.artPool.title") + " | 3space Art",
      meta: [
        {
          vmid: "title",
          name: "title",
          content: this.$t("meta.artPool.title") + " | 3space Art",
        },
        {
          vmid: "description",
          name: "description",
          content: this.$t("meta.artPool.description"),
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: this.$t("meta.artPool.title") + " | 3space Art",
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: this.$t("meta.artPool.description"),
        },
        {
          vmid: "og:url",
          property: "og:url",
          content: window.location.origin + this.$route.path,
        },
      ],
    };
  },
  data: function () {
    return {
      isBusy: false,
      perPage: 12,
      currentPage: 1,
      sort: [{ id: "roundNo" }],
      showOpen1: false,
      showOpen2: false,
      showOpen3: false,
      externalParams: {
        keywordPoolType: "NFT",
      },
      // masks: {
      //   title: "M",
      //   weekdays: "WWW",
      // },
      attrs: [
        {
          key: "today",
          dot: true,
          dates: new Date(),
        },
      ],
      windowWidth: 1920,
      isWhatIsArtPoolOpen: false,
      isHowToJoinOpen: false,
      isArtistBenefitsOpen: false,
      artpoolIdx: 0,
    };
  },
  computed: {
    ...mapState("gallery", ["artworkFileInfo"]),
    ...mapState("artpool", [
      "artpoolItems",
      "artpoolVersionOptions",
      "calendarArtpoolList",
      "artpoolSimpleInfo",
    ]),
    activeLanguage() {
      return this.getActiveLanguage();
    },
    isMobile() {
      return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    },
    artpoolList() {
      if (
        this.artpoolItems.content !== undefined &&
        this.artpoolItems.content.length > 0
      ) {
        return this.artpoolItems.content;
      } else {
        return [];
      }
    },
  },
  created() {
    // 달력 표시 정보
    let month = this.$moment().format("MM");
    let year = this.$moment().format("YYYY");
    this.getArtpoolsIncludedMonth({
      month: month,
      year: year,
    }).then();
  },
  mounted() {
    this.windowWidth = window.innerWidth;
    window.addEventListener("resize", this.handleResize);
    const param = {
      codes: ["nft_pool_main_product"],
    };
    this.getCodeList(param).then((data) => {
      // 작품
      let idxProduct = data[0].codeValue;
      this.getArtworkFile(idxProduct).then();
      // 아트풀 리스트
      this.requestArtpools();
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    ...mapActions("gallery", ["getArtworkFile"]),
    ...mapActions("code", ["getCodeList"]),
    ...mapActions("artpool", [
      "getArtpoolSimple",
      "getArtpools",
      "getArtpoolsIncludedMonth",
    ]),
    getActiveLanguage() {
      return localStorage.getItem("language") || "en";
    },
    requestArtpoolSimple(idxArtpool) {
      if (idxArtpool === 5) {
        if (this.artpoolIdx > idxArtpool) {
          this.artpoolIdx = 4;
        } else if (this.artpoolIdx < idxArtpool) {
          this.artpoolIdx = 6;
        }
      }
      if (idxArtpool !== 5) {
        this.artpoolIdx = idxArtpool;
      }
      if (idxArtpool > this.artpoolItems.content.length + 1) {
        this.artpoolIdx = 1;
      }
      if (idxArtpool === 0) {
        this.artpoolIdx = this.artpoolItems.content.length + 1;
      }
      this.getArtpoolSimple(this.artpoolIdx).then();
    },
    handleResize(event) {
      this.windowWidth = window.innerWidth;
    },
    requestArtpools() {
      this.$store.commit("setShowLoading", { show: true, msg: "" });
      this.isBusy = true;
      this.getArtpools(
        this.getPagingParams(
          {
            page: this.currentPage - 1,
            pageSize: this.perPage,
            sorted: this.sort,
            filtered: [],
          },
          this.externalParams
        )
      )
        .then(() => {
          this.isBusy = false;
          let idxArtpool = 0;
          this.artpoolIdx = this.artpoolItems.content.length + 1;
          this.artpoolItems.content.forEach((el) => {
            if (el.initActive) {
              idxArtpool = el.idx;
            }
            let calendarData = {
              highlight: {
                fillMode: "solid",
                style: {
                  backgroundColor: el.primaryColor,
                },
                contentStyle: {
                  color: "#000",
                  fontWeight: "normal",
                },
              },
              dates: {
                start: new Date(el.poolStartDate),
                end: new Date(el.poolEndDate),
              },
            };
            this.attrs.push(calendarData);
          });
          if (idxArtpool !== 0) {
            this.requestArtpoolSimple(idxArtpool);
          }
          this.$store.commit("setShowLoading", { show: false, msg: "" });
        })
        .catch((error) => {
          this.$log.error(error);
          this.isBusy = false;
          this.$store.commit("setShowLoading", { show: false, msg: "" });
        });
    },
    getPagingParams({ page, pageSize, sorted, filtered }, externalParams) {
      const params = {
        page,
        size: pageSize,
        ...externalParams,
      };

      // sorting parameter
      let sorts = [];
      for (let i = 0, length = sorted.length; i < length; i++) {
        const sort = sorted[i];
        sorts.push(`${sort.id},${sort.desc ? "desc" : "asc"}`);
      }
      params["sort"] = sorts;

      // filtering parameter
      for (let i = 0, length = filtered.length; i < length; i++) {
        const filter = filtered[i];
        params[filter.id] = filter.value;
      }
      return params;
    },
    // onUpdateCalendarPage(e) {
    //   let month = "0" + e.month;
    //   month = month.slice(-2);
    //   this.getArtpoolsIncludedMonth({
    //     month: month.toString(),
    //     year: e.year.toString(),
    //   }).then();
    // },
    onClickArtPoolListItem(artpool) {
      // let version = this.artpoolVersionOptions.find(el => el.roundNo === artpool.roundNo).version;
      // if (version === 1 ) {
      //   this.$router.push({ name: "NftPoolStake", params: { idxArtpool: artpool.idx }})
      // } else if (version === 2) {
      //   this.$router.push({ name: "NftPoolDetail", params: { idxArtpool: artpool.idx }})
      // }
      if (artpool.roundNo < 3) {
        this.$router.push({
          name: "NftPoolStake",
          params: { idxArtpool: artpool.idx },
        });
      } else if (artpool.roundNo >= 3) {
        this.$router.push({
          name: "NftPoolDetail",
          params: { idxArtpool: artpool.idx },
        });
      }
    },
    isProductFileTypeImage(_productFileType) {
      if (
        _productFileType !== undefined &&
        _productFileType.startsWith("image")
      ) {
        return true;
      } else {
        return false;
      }
    },
    replaceDisplayPosterUrl(item) {
      if (
        item.displayPoster !== undefined &&
        item.displayPoster !== null &&
        item.displayPoster !== "" &&
        item.displayPoster === "THREESPACE"
      ) {
        return item.displayPosterUrlThreespace; // 3space S3 URL
      } else {
        if (
          item.displayPosterUrl !== undefined &&
          item.displayPosterUrl.startsWith("https://ipfs.io/")
        ) {
          return item.displayPosterUrl.replace(
            "https://ipfs.io/",
            "https://ipfs.3space.art/"
          );
        }
        return item.displayPosterUrl;
      }
    },
    replaceDisplayDetailUrl(item) {
      if (
        item.displayDetailFile !== undefined &&
        item.displayDetailFile !== null &&
        item.displayDetailFile !== "" &&
        item.displayDetailFile === "THREESPACE"
      ) {
        return item.displayDetailUrlThreespace; // 3space S3 URL
      } else {
        if (
          item.displayDetailUrl !== undefined &&
          item.displayDetailUrl.startsWith("https://ipfs.io/")
        ) {
          return item.displayDetailUrl.replace(
            "https://ipfs.io/",
            "https://ipfs.3space.art/"
          );
        }
        return item.displayDetailUrl;
      }
    },
    onClickArtistApplyPage() {
      // Artist 버튼 클릭 시
      let routeUrl = this.$router.resolve({ path: "/apply" });
      window.open(routeUrl.href, "_blank");
    },
    onClickContactUsPage() {
      // Corporation 버튼 클릭 시
      let routeUrl = this.$router.resolve({ path: "/contact" });
      window.open(routeUrl.href, "_blank");
    },
    toggleWhatIsArtPool() {
      this.isWhatIsArtPoolOpen = !this.isWhatIsArtPoolOpen;
    },
    toggleHowToJoin() {
      this.isHowToJoinOpen = !this.isHowToJoinOpen;
    },
    toggleArtistBenefits() {
      this.isArtistBenefitsOpen = !this.isArtistBenefitsOpen;
    },
  },
};
</script>
