<template>
  <div id="payment-toss-transfer-1">
    <PaymentTossTransfer2
      :payment-result-info="paymentResultInfo"
      ref="paymentTossTransfer2"
    />
  </div>
</template>
<script>
import { loadTossPayments } from "@tosspayments/payment-sdk";
import { createNamespacedHelpers } from "vuex";
import PaymentTossTransfer2 from "./PaymentTossTransfer2";
const paymentHelper = createNamespacedHelpers("payment");
const clientKey = process.env.VUE_APP_TOSS_CLIENT_KEY;

export default {
  name: "PaymentTossTransfer",
  components: {
    PaymentTossTransfer2,
  },
  data: function () {
    return {
      paymentResultInfo: {},
    };
  },
  mounted() {},
  computed: {},
  methods: {
    ...paymentHelper.mapActions([
      "getTossPaymentTransfer",
      "confirmTossPaymentTransfer",
      "transferNFT",
    ]),
    request(params) {
      this.getTossPaymentTransfer(params.idxProduct).then((data) => {
        if (
          Object.keys(data).length !== 0 &&
          JSON.stringify(data) !== JSON.stringify({})
        ) {
          loadTossPayments(clientKey)
            .then((tossPayments) => {
              tossPayments
                .requestPayment("계좌이체", {
                  amount: data.amt,
                  taxFreeAmount: data.amt,
                  orderId: data.moid,
                  orderName: data.goodsName,
                  successUrl:
                    process.env.VUE_APP_TOSS_PAYMENT_REDIRECT_URL +
                    this.$route.path +
                    "?tossTransferStatus=success",
                  failUrl:
                    process.env.VUE_APP_TOSS_PAYMENT_REDIRECT_URL +
                    this.$route.path +
                    "?tossTransferStatus=fail",
                  cashReceipt: {
                    type: "소득공제",
                  },
                  useEscrow: false,
                  customerName: data.buyerName,
                  customerEmail: data.buyerEmail,
                })
                .catch((error) => {
                  console.error(error);
                  let errorMessage = this.$t("payment.transfer.error.fail");
                  if (
                    error.message !== undefined &&
                    error.message !== null &&
                    error.message !== "" &&
                    error.message === "결제가 취소되었습니다."
                  ) {
                    errorMessage = this.$t("payment.transfer.error.cancel");
                  }
                  this.$store.commit("setCommonAlertModalInfo", {
                    msgHtml: errorMessage,
                    show: true,
                    fontSize: "sm",
                  });
                });
            })
            .catch((error) => {
              console.error(error);
              this.$store.commit("setCommonAlertModalInfo", {
                msgHtml: this.$t("payment.transfer.error.fail"),
                show: true,
                fontSize: "sm",
              });
            });
        }
      });
    },
    confirm(query) {
      const status = query.tossTransferStatus;
      const paymentKey = query.paymentKey || null;
      const orderId = query.orderId || null;
      const amount = query.amount || null;
      const code = query.code || null;
      const message = query.message || null;

      const payload = {
        status,
        paymentKey,
        orderId,
        amount,
        code,
        message,
      };

      if (status === "success") {
        this.confirmTossPaymentTransfer(payload)
          .then((data) => {
            this.paymentResultInfo = data;
            // 승인 완료
            if (
              data &&
              data.stateCd === "0" &&
              data.status === "USER_PAY_SUCCESS" &&
              data.nftTransferStatus === "TX_STATUS_S"
            ) {
              this.$refs["paymentTossTransfer2"].show();
              this.$store.commit("setShowLoading", {
                show: true,
                msg: this.$t("payment.transfer.nftTransfer"),
              });

              // 판매 법인 정보 삭제
              localStorage.removeItem(
                "referralCorpSeller_" + this.paymentResultInfo.idxProduct
              );

              // NFT 전송 >> TODO 폴링 방식으로 변경 필요!!
              this.transferNFT({ idxOrderPayment: data.idx })
                .then(() => {
                  this.$store.commit("setShowLoading", {
                    show: false,
                    msg: "",
                  });
                  this.$emit("complete-toss-transfer", true);
                })
                .catch((error) => {
                  this.$log.error(error);
                  let errorMessage =
                    "NFT 전송 오류가 발생하였습니다. 관리자에게 문의하세요.";
                  if (error.data.reason === "BAD_REQUEST") {
                    if (error.data.resource === "NFT_TRANSFER") {
                      errorMessage =
                        "요청에 실패하였습니다. 이용중인 서비스에서 확인 후 다시 시도해주세요.";
                    } else if (
                      error.data.resource === "NFT_TRANSFER_PROGRESS"
                    ) {
                      errorMessage =
                        "NFT 전송 시간이 지연되고 있습니다. 잠시후 다시 확인해주세요.";
                    } else {
                      errorMessage =
                        "NFT 전송 오류가 발생하였습니다. 관리자에게 문의하세요.";
                    }
                  }
                  this.$store.commit("setShowLoading", {
                    show: false,
                    msg: "",
                  });
                  this.$store.commit("setCommonAlertModalInfo", {
                    msgHtml: errorMessage,
                    show: true,
                    fontSize: "sm",
                  });
                  this.$emit("complete-toss-transfer", false);
                });
            } else {
              this.$store.commit("setCommonAlertModalInfo", {
                msgHtml:
                  "결제 승인 요청에 실패하였습니다. 잠시 후 다시 시도해주세요.",
                show: true,
                fontSize: "sm",
              });
              this.$emit("complete-toss-transfer", false);
            }
          })
          .catch(function (error) {
            console.error(error);
            let errorMessage =
              "결제 승인 요청 오류가 발생하였습니다. 잠시 후 다시 시도해주세요.";
            if (error.data.error === "NOT_FOUND") {
              if (error.data.resource === "PRODUCT_FORSALE") {
                errorMessage = "구매 가능한 작품이 존재하지 않습니다.";
              } else if (error.data.resource === "TOSS_TRANSFER_CONFIRM") {
                errorMessage =
                  "결제요청 상태 정보 조회 오류가 발생하였습니다. 잠시후 다시 시도해 주세요.";
              } else if (error.data.resource === "ORDER_PAYMENT") {
                errorMessage =
                  "결제요청 정보 조회 오류가 발생하였습니다. 잠시후 다시 시도해 주세요.";
              } else {
                errorMessage =
                  "주문결제 오류가 발생하였습니다. 관리자에게 문의하세요.";
              }
            }
            this.$store.commit("setCommonAlertModalInfo", {
              msgHtml: errorMessage,
              show: true,
              fontSize: "sm",
            });
            this.$emit("complete-toss-transfer", false);
          });
      } else if (status === "fail") {
        let errorMessage = this.$t("payment.transfer.error.fail");
        if (
          code !== undefined &&
          code !== null &&
          code !== "" &&
          code === "PAY_PROCESS_CANCELED"
        ) {
          errorMessage = this.$t("payment.transfer.error.cancel");
        }
        this.$store.commit("setCommonAlertModalInfo", {
          msgHtml: errorMessage,
          show: true,
          fontSize: "sm",
        });
        this.$emit("complete-toss-transfer", false);
      }
    },
  },
};
</script>
