var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{ref:"polygon-collection-select-modal",attrs:{"id":"polygon-collection-select-modal","hide-header":"","hide-footer":"","centered":"","scrollable":""},on:{"hide":_vm.closeModal}},[_c('CRow',[_c('CCol',{staticClass:"col-12 py-2"},[_c('div',{staticClass:"d-flex justify-content-end"},[_c('img',{staticClass:"cursor-pointer",attrs:{"src":"/img/threespace/icon/close.png","alt":"close"},on:{"click":function($event){return _vm.$refs['polygon-collection-select-modal'].hide()}}})])])],1),_c('p',{staticStyle:{"color":"#000","font-size":"20px","font-weight":"bold","margin-bottom":"6px","text-align":"center"}},[_vm._v(" "+_vm._s(_vm.$t("mypage.polygonNftImport"))+" ")]),_c('div',{staticClass:"collection_select_wrap"},[(_vm.isBusyPolygon)?[_c('div',{staticClass:"contract_select_list"},[_c('div',{staticClass:"sk-wave mx-auto mt-5"},[_c('div',{staticClass:"sk-wave-rect"}),_c('div',{staticClass:"sk-wave-rect"}),_c('div',{staticClass:"sk-wave-rect"}),_c('div',{staticClass:"sk-wave-rect"}),_c('div',{staticClass:"sk-wave-rect"})])])]:[(
          _vm.tempPolygonNftCollectionList !== undefined &&
          _vm.tempPolygonNftCollectionList.length > 0
        )?[_c('div',{staticClass:"collection_select_list"},[_c('div',{staticClass:"total_cnt"},[_c('div',{staticClass:"float-left"},[_vm._v(" "+_vm._s(_vm.$t("mypage.selected"))+" ("+_vm._s(_vm.selectedNftCollectionList.length)+"/"+_vm._s(_vm.tempPolygonNftCollectionList.length)+") ")]),_c('div',{staticClass:"float-right"},[_c('img',{attrs:{"src":"/img/threespace/icon/refresh_icon.png"}})])]),_c('b-table-simple',{attrs:{"sticky-header":"330px","borderless":"","no-border-collapse":"","head-variant":"light","fixed":""}},[_c('b-thead',[_c('tr',[_c('th',{attrs:{"colspan":"1"}},[_c('div',{staticClass:"d-flex justify-content-center",staticStyle:{"height":"16px"}},[_c('div',{staticStyle:{"width":"15px","height":"15px","border":"1px solid #999","cursor":"pointer","position":"relative"},on:{"click":_vm.selectedAllList}},[(_vm.allSelected)?_c('v-icon',{staticStyle:{"position":"absolute","top":"0","left":"0","color":"#000","font-size":"13px"}},[_vm._v("mdi-check")]):_vm._e()],1)])]),_c('th',{attrs:{"colspan":"2"}},[_vm._v(" "+_vm._s(_vm.$t("mypage.productName"))+" ")]),_c('th',{attrs:{"colspan":"2"}},[_vm._v(" "+_vm._s(_vm.$t("mypage.tokenId"))+" ")]),_c('th',{attrs:{"colspan":"2"}},[_vm._v(" "+_vm._s(_vm.$t("mypage.contract"))+" ")])])]),_c('b-tbody',[_vm._l((_vm.tempPolygonNftCollectionList),function(item,i){return [_c('tr',{key:i,class:{
                    error_row: item.error !== undefined,
                    already_row: _vm.isAlreadyRegister(item),
                  }},[_c('td',{staticClass:"d-flex justify-content-center align-items-center",attrs:{"colspan":"1"}},[_c('div',{staticClass:"checkbox_wrap"},[_c('div',{staticStyle:{"width":"15px","height":"15px","border":"0.7px solid #999","cursor":"pointer","position":"relative","margin-top":"4px"},on:{"click":function($event){return _vm.onClickContractItem(item)}}},[(item.selected || _vm.isAlreadyRegister(item))?_c('v-icon',{staticStyle:{"position":"absolute","top":"0","left":"0","color":"#000"}},[_vm._v("mdi-check")]):_vm._e()],1)])]),_c('td',{attrs:{"colspan":"2"}},[_c('label',{staticClass:"m-0",attrs:{"for":'collection' + i}},[(item.error !== undefined)?[_vm._v(" ERROR ")]:[_vm._v(" "+_vm._s(item.title)+" ")]],2)]),_c('td',{attrs:{"colspan":"2"}},[_c('label',{staticClass:"m-0",attrs:{"for":'collection' + i}},[_vm._v("#"+_vm._s(item.tokenId))])]),_c('td',{attrs:{"colspan":"2"}},[_c('label',{staticClass:"m-0",attrs:{"for":'collection' + i}},[_vm._v(_vm._s(_vm._f("simpleAddr")(item.contract.address)))])])])]})],2)],1)],1),_c('div',{staticClass:"btn_box"},[_c('p',{staticClass:"text-grey"},[_vm._v(" * 가져오기는 1회 당 최대 10개까지 권고합니다. 10개 초과 일 경우, 일정시간이 소요될 수 있습니다. ")])]),_c('div',{staticClass:"d-flex justify-content-center"},[_c('div',{staticClass:"mt-3",staticStyle:{"width":"100%"}},[_c('Button',{attrs:{"label":_vm.$t('button.next'),"onClick":_vm.onClickNext}})],1)])]:[_c('div',{staticClass:"empty_box"},[_vm._v(" "+_vm._s(_vm.$t("stake.stakeModal.noData"))+" ")])]]],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }