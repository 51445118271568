<template>
  <div
    class="c-app bg-white"
    :class="{ 'c-dark-theme': $store.state.darkMode }"
  >
    <div class="overlay_loading" v-if="$store.state.showLoading">
      <div>
        <div class="sk-wave mx-auto mb-4">
          <div class="sk-wave-rect"></div>
          <div class="sk-wave-rect"></div>
          <div class="sk-wave-rect"></div>
          <div class="sk-wave-rect"></div>
          <div class="sk-wave-rect"></div>
        </div>
        <div>
          <p v-html="$store.state.showLoadingMsg"></p>
        </div>
      </div>
    </div>
    <CommonAlertModal />
    <CWrapper :class="{ show_loading: $store.state.showLoading }">
      <Header v-if="!isRenewRoute" />
      <transition name="fade" mode="out-in">
        <router-view :key="$route.path"></router-view>
      </transition>
      <Footer v-if="!isRenewRoute && !isMain" />
    </CWrapper>
  </div>
</template>

<script>
import Header from "@/containers/Header";
import Footer from "@/containers/Footer";
import CommonAlertModal from "../views/threespace/components/common/CommonAlertModal.vue";

export default {
  name: "Container",
  components: {
    Header,
    Footer,
    CommonAlertModal,
  },
  computed: {
    isRenewRoute() {
      return (
        this.$route.name === "renew" || this.$route.path.includes("/renew")
      );
    },
    isMain() {
      return (
        this.$route.name === "home" ||
        this.$route.path.includes("/home") ||
        this.$route.name === "Prepare"
      );
    },
  },
};
</script>

<style src="spinkit/spinkit.min.css"></style>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
