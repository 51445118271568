var render = function render(){var _vm=this,_c=_vm._self._c;return _c('article',{staticClass:"mypage"},[(
      _vm._memberInfo.profileImageName !== undefined &&
      _vm._memberInfo.profileImageName !== null
    )?[_c('article',{staticClass:"profile-image"},[_c('img',{attrs:{"src":_vm._memberInfo.profileImageUrl,"alt":"profile-image"}})])]:[_c('article',{staticClass:"profile-image__default"})],_c('article',{staticClass:"artist"},[(_vm.isArtist)?_c('section',{staticClass:"artist__name"},[_c('p',{staticClass:"name"},[(_vm.activeLanguage !== undefined && _vm.activeLanguage === 'ko')?[_vm._v(" "+_vm._s(_vm.artistInfo.artistName || _vm.artistInfo.artistNameEn)+" ")]:[_vm._v(" "+_vm._s(_vm.artistInfo.artistNameEn || _vm.artistInfo.artistName)+" ")]],2),_c('p',{staticClass:"nickname"},[_vm._v("@ "+_vm._s(_vm._memberInfo.nickname))])]):_c('section',{staticClass:"artist__name"},[_c('p',{staticClass:"name"},[_vm._v("@ "+_vm._s(_vm._memberInfo.nickname))])]),_c('section',{staticClass:"follower"},[_c('div',{staticClass:"item"},[_c('p',{staticClass:"type"},[_vm._v("FOLLOWERS")]),_c('p',{staticClass:"value",on:{"click":function($event){$event.stopPropagation();return _vm.onClickFollowers(_vm._memberInfo.idx)}}},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm._memberInfo.followers))+" ")])]),_c('div',{staticClass:"item"},[_c('p',{staticClass:"type"},[_vm._v("FOLLOWINGS")]),_c('p',{staticClass:"value",on:{"click":function($event){$event.stopPropagation();return _vm.onClickFollowing(_vm._memberInfo.idx)}}},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm._memberInfo.following))+" ")])])])]),_c('div',{staticClass:"follower-btn pc"},[_c('Button',{attrs:{"label":_vm._memberInfo.isFollow !== undefined && _vm._memberInfo.isFollow
          ? 'Unfollow'
          : 'Follow',"buttonClass":{
        'btn-primary': true,
        'btn-outline-primary': _vm._memberInfo.isFollow,
      },"onClick":_vm.onClickMemberFollow}}),(!_vm.showCollection)?_c('router-link',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
        content: _vm.$t('mypage.editMyInformation'),
        placement: 'right',
      }),expression:"{\n        content: $t('mypage.editMyInformation'),\n        placement: 'right',\n      }"}],staticClass:"update-btn",attrs:{"to":"/mypage/update"}},[_c('img',{attrs:{"src":"/img/threespace/icon/new_setting.svg"}})]):_vm._e()],1),_c('article',{staticClass:"introduce"},[_c('article',{staticClass:"introduce__description"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isArtist),expression:"isArtist"}],ref:"artistIntroduce"},[(_vm.activeLanguage !== undefined && _vm.activeLanguage === 'ko')?[_c('p',{domProps:{"innerHTML":_vm._s(
              _vm.artistInfo.introduce !== undefined &&
              _vm.artistInfo.introduce !== ''
                ? _vm.replaceNewLine(_vm.artistInfo.introduce)
                : _vm.replaceNewLine(_vm.artistInfo.introduceEn)
            )}})]:[_c('p',{domProps:{"innerHTML":_vm._s(
              _vm.artistInfo.introduceEn !== undefined &&
              _vm.artistInfo.introduceEn !== ''
                ? _vm.replaceNewLine(_vm.artistInfo.introduceEn)
                : _vm.replaceNewLine(_vm.artistInfo.introduce)
            )}})]],2),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isArtist),expression:"!isArtist"}]},[(
            _vm._memberInfo.introduce !== undefined &&
            _vm._memberInfo.introduce !== null &&
            _vm._memberInfo.introduce !== ''
          )?_c('div',{domProps:{"innerHTML":_vm._s(_vm.replaceNewLine(_vm._memberInfo.introduce))}}):_c('p',[_vm._v(" "+_vm._s(_vm.showCollection ? _vm.$t("mypage.emptyBioMessage") : _vm.$t("mypage.bioMessage"))+" ")])]),(_vm.isArtist)?_c('div',[(_vm.activeLanguage !== undefined && _vm.activeLanguage === 'ko')?[_c('p',{domProps:{"innerHTML":_vm._s(
              _vm.artistInfo.career !== undefined && _vm.artistInfo.career !== ''
                ? _vm.replaceNewLine(_vm.artistInfo.career)
                : _vm.replaceNewLine(_vm.artistInfo.careerEn)
            )}})]:[_c('p',{domProps:{"innerHTML":_vm._s(
              _vm.artistInfo.careerEn !== undefined && _vm.artistInfo.careerEn !== ''
                ? _vm.replaceNewLine(_vm.artistInfo.careerEn)
                : _vm.replaceNewLine(_vm.artistInfo.career)
            )}})]],2):_vm._e()]),_c('div',{staticClass:"follower-btn--mobile"},[_c('Button',{attrs:{"label":_vm._memberInfo.isFollow !== undefined && _vm._memberInfo.isFollow
            ? 'Unfollow'
            : 'Follow',"buttonClass":{
          'btn-primary': true,
          'btn-outline-primary': _vm._memberInfo.isFollow,
        },"onClick":_vm.onClickMemberFollow}})],1),_c('section',{staticClass:"introduce__href"},[_c('section',{staticClass:"sns_link"},[_c('button',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({ content: 'Website', placement: 'top' }),expression:"{ content: 'Website', placement: 'top' }"}],attrs:{"disabled":_vm._memberInfo.homepage === undefined || _vm._memberInfo.homepage === '',"variant":"link"},on:{"click":function($event){return _vm.onClickSnsBtn(_vm._memberInfo.homepage, 'homepage')}}},[_c('img',{attrs:{"src":"/img/threespace/mypage/website.svg"}})]),_c('button',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({ content: 'Instagram', placement: 'top' }),expression:"{ content: 'Instagram', placement: 'top' }"}],staticClass:"p-0",attrs:{"disabled":_vm._memberInfo.instagram === undefined ||
            _vm._memberInfo.instagram === '',"variant":"link"},on:{"click":function($event){return _vm.onClickSnsBtn(_vm._memberInfo.instagram, 'instagram')}}},[_c('img',{attrs:{"src":"/img/threespace/mypage/instagram.svg"}})]),_c('button',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({ content: 'Twitter', placement: 'top' }),expression:"{ content: 'Twitter', placement: 'top' }"}],staticClass:"p-0",attrs:{"disabled":_vm._memberInfo.twitter === undefined || _vm._memberInfo.twitter === '',"variant":"link"},on:{"click":function($event){return _vm.onClickSnsBtn(_vm._memberInfo.twitter, 'twitter')}}},[_c('img',{attrs:{"src":"/img/threespace/mypage/x.svg"}})]),(!_vm.showCollection)?_c('router-link',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
            content: _vm.$t('mypage.editMyInformation'),
            placement: 'right',
          }),expression:"{\n            content: $t('mypage.editMyInformation'),\n            placement: 'right',\n          }"}],staticClass:"update-btn--mobile",attrs:{"to":"/mypage/update"}},[_c('img',{attrs:{"src":"/img/threespace/icon/new_setting.svg"}})]):_vm._e()],1),_c('section',{staticClass:"location"},[_c('img',{attrs:{"src":"/img/threespace/mypage/location.svg"}}),_c('p',[(!_vm.showCollection)?[_vm._v(" "+_vm._s(_vm._memberInfo.location !== undefined && _vm._memberInfo.location !== null && _vm._memberInfo.location !== "" ? _vm._memberInfo.location : "Where you at?")+" ")]:[_vm._v(" "+_vm._s(_vm._memberInfo.location !== undefined && _vm._memberInfo.location !== null && _vm._memberInfo.location !== "" ? _vm._memberInfo.location : "Empty location")+" ")]],2)])])]),_c('FollowModal',{ref:"followModal"})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }