<template>
  <div>
    <div class="text-center loading_wrap" v-if="loaded">
      <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
    </div>
    <iframe
      :src="
        activeLanguage === 'ko'
          ? 'https://form.typeform.com/to/x4x5hUWf'
          : 'https://form.typeform.com/to/JMMb66PL'
      "
      class="custom-iframe"
      @load="load"
    ></iframe>
  </div>
</template>

<script>
export default {
  name: "Apply",
  metaInfo() {
    return {
      title: this.$t("meta.apply.title") + " | 3space Art",
      meta: [
        { vmid: "title", name: "title", content: this.$t("meta.apply.title") + " | 3space Art" },
        { vmid: "description", name: "description", content: this.$t("meta.apply.description") },
        { vmid: "og:title", property: "og:title", content: this.$t("meta.apply.title") + " | 3space Art" },
        { vmid: "og:description", property: "og:description", content: this.$t("meta.apply.description") },
        { vmid: "og:url", property: "og:url", content: window.location.origin + this.$route.path }
      ]
    }
  },
  data() {
    return {
      loaded: true
    };
  },
  computed: {
    activeLanguage() {
      return this.getActiveLanguage();
    }
  },
  mounted() {
    document.getElementById("V2FooterWrap").style.display = "none";
  },
  beforeDestroy() {
    document.getElementById("V2FooterWrap").style.display = "block";
  },
  methods: {
    load() {
      this.loaded = false;
    },
    getActiveLanguage() {
      return localStorage.getItem("language") || "en";
    }
  }
};
</script>

<style scoped>
.custom-iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  border: none;
  margin-top: 70px;
}
.loading_wrap {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}
</style>
