<template>
  <b-modal
    id="artist-select-modal"
    ref="artist-select-modal"
    centered
    no-close-on-esc
    no-close-on-backdrop
    hide-footer
    body-class="text-center text-black px-4"
    size="lg"
    title="작가 선택"
  >
    <!-- 검색 -->
    <div class="row">
      <div class="col">
        <b-input-group>
          <template #prepend>
            <b-form-select
              v-model="searchSelected"
              :options="searchOptions"
              @change="onChangeSearchOption"
            ></b-form-select>
          </template>
          <b-form-input
            placeholder="검색어를 입력해주세요."
            v-model="externalParams.searchKeyword"
            @input="onChangeSearchKeyword"
            @keypress.enter.prevent="onClickSearch"
          ></b-form-input>
          <template #append>
            <b-button variant="info" @click="onClickSearch">
              <CIcon name="cil-magnifying-glass" /> 검색
            </b-button>
            <b-button variant="secondary" @click="onClickReset"
              ><CIcon name="cil-filter-x"
            /></b-button>
          </template>
        </b-input-group>
      </div>
    </div>

    <!-- 목록 -->
    <div class="row mt-3 border-bottom">
      <div class="col">
        <b-table
          id="artistTable"
          thead-class="text-center"
          :busy="isBusy"
          :items="artistTableItems.content"
          :fields="artistSelectTableFields"
          :items-per-page="perPage"
          show-empty
          empty-text="조회된 내역이 없습니다."
          selectable
          select-mode="single"
          @row-selected="onClickRowSubmit"
        >
          <!-- NO -->
          <template #cell(index)="data">
            {{ data.index + 1 + (currentPage - 1) * perPage }}
          </template>
        </b-table>

        <b-pagination
          aria-controls="artistTable"
          align="center"
          hide-ellipsis
          limit="10"
          v-model="currentPage"
          :total-rows="artistTableItems.totalElements"
          :per-page="perPage"
          :current-page="currentPage"
          v-on:input="onPaging"
        ></b-pagination>
      </div>
    </div>

    <div class="row py-3">
      <div class="col text-center">
        <CButton @click="hide()" color="light" class="mr-2">
          <CIcon name="cil-x-circle" class="mr-2" />취소
        </CButton>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const memberHelper = createNamespacedHelpers("member");

export default {
  data: function() {
    return {
      isBusy: false,
      perPage: 10,
      pageOptions: [
        { value: 10, label: "10 개씩 보기" },
        { value: 20, label: "20 개씩 보기" }
      ],
      currentPage: 1,
      sort: [
        { id: "createdAt", desc: "desc" },
        { id: "idx", desc: "desc" }
      ],
      sortBy: "",
      sortDesc: false,
      // 검색
      searchSelected: "",
      searchOptions: [
        { value: "", text: "전체" },
        { value: "keywordArtistName", text: "작가명" },
        { value: "keywordArtistNameEn", text: "작가명(영문)" },
        { value: "keywordNickname", text: "사용자이름(닉네임)" },
        { value: "keywordEmail", text: "이메일" },
        { value: "keywordMobile", text: "모바일" },
        { value: "keywordKlaytnAddr", text: "클레이튼 지갑주소" },
        { value: "keywordEthereumAddr", text: "이더리움 지갑주소" }
      ],
      externalParams: this.getInitExternalParams()
    };
  },
  computed: {
    ...memberHelper.mapState(["artistTableItems", "artistSelectTableFields"])
  },
  mounted() {
    this.requestArtistItems();
  },
  methods: {
    ...memberHelper.mapActions(["getArtists"]),
    onPaging() {
      this.requestArtistItems();
    },
    onChangeSearchOption() {
      this.setSearchConditions(this.externalParams.searchKeyword);
    },
    onChangeSearchKeyword(keyword) {
      this.setSearchConditions(keyword);
    },
    setSearchConditions(keyword) {
      this.externalParams = this.getInitExternalParams();
      if (this.searchSelected === "") {
        this.externalParams.searchKeyword = keyword;
      } else if (this.searchSelected === "keywordArtistName") {
        this.externalParams.keywordArtistName = keyword;
      } else if (this.searchSelected === "keywordNickname") {
        this.externalParams.keywordNickname = keyword;
      } else if (this.searchSelected === "keywordNicknameEn") {
        this.externalParams.keywordNicknameEn = keyword;
      } else if (this.searchSelected === "keywordEmail") {
        this.externalParams.keywordEmail = keyword;
      } else if (this.searchSelected === "keywordMobile") {
        this.externalParams.keywordMobile = keyword;
      } else if (this.searchSelected === "keywordKlaytnAddr") {
        this.externalParams.keywordKlaytnAddr = keyword;
      } else if (this.searchSelected === "keywordEthereumAddr") {
        this.externalParams.keywordEthereumAddr = keyword;
      }
    },
    resetSearchConditions() {
      this.searchSelected = "";
      this.externalParams = this.getInitExternalParams();
    },
    onClickSearch() {
      this.requestArtistItems();
    },
    onClickReset() {
      this.resetSearchConditions();
      this.requestArtistItems();
    },
    requestArtistItems() {
      //TODO this.$log.info("requestArtistItems() this.externalParams=", this.externalParams);
      this.isBusy = true;
      this.getArtists(
        this.getPagingParams(
          {
            page: this.currentPage - 1,
            pageSize: this.perPage,
            sorted: this.sort,
            filtered: []
          },
          this.externalParams
        )
      )
        .then(() => {
          this.isBusy = false;
        })
        .catch(error => {
          this.isBusy = false;
          this.$log.error(error);
        });
    },
    getPagingParams({ page, pageSize, sorted, filtered }, externalParams) {
      const params = {
        page,
        size: pageSize,
        ...externalParams
      };

      // sorting parameter
      let sorts = [];
      for (let i = 0, length = sorted.length; i < length; i++) {
        const sort = sorted[i];
        sorts.push(`${sort.id},${sort.desc ? "desc" : "asc"}`);
      }
      params["sort"] = sorts;

      // filtering parameter
      for (let i = 0, length = filtered.length; i < length; i++) {
        const filter = filtered[i];
        params[filter.id] = filter.value;
      }

      return params;
    },
    show() {
      this.$refs["artist-select-modal"].show();
    },
    hide() {
      this.$refs["artist-select-modal"].hide();
    },
    onClickRowSubmit(item) {
      if (!!item) {
        this.hide();
        this.$emit("artist-selected", item[0]);
      } else {
        alert("사용자를 선택해주세요.");
      }
    },
    getInitExternalParams() {
      return {
        searchKeyword: "",
        keywordArtistName: "",
        keywordArtistNameEn: "",
        keywordNickname: "",
        keywordEmail: "",
        keywordMobile: "",
        keywordKlaytnAddr: "",
        keywordEthereumAddr: ""
      };
    }
  }
};
</script>
