<template>
  <div>
    <template
      class="row"
      v-if="
        galleryProductItems.content === undefined ||
        galleryProductItems.content === null ||
        galleryProductItems.content.length === 0
      "
    >
      <div class="custom-tabs--undefined" key="0">
        <p>
          {{ $t("mypage.noHistoryNFT") }}
        </p>
      </div>
    </template>
    <template v-else>
      <div class="custom-tabs__wrapper">
        <article
          v-for="(item, i) in galleryProductItems.content"
          :key="i"
          :ref="'cartItem' + item.idx"
          class="item"
        >
          <div
            class="card_list_item cursor-pointer"
            :id="'galleryItem' + item.idx"
            @click="showProductDetail(item.idx)"
          >
            <v-lazy :options="{ threshold: 0.5 }" transition="fade-transition">
              <div
                class="img-box"
                @contextmenu.prevent.stop
                :class="{ isLoading: isBusy }"
              >
                <!-- <CardHoverBox
                  :favoritedCnt="item.favoritedCnt"
                  :pageViewCnt="item.pageViewCnt"
                  :isExternalCollection="false"
                /> -->
                <template>
                  <template
                    v-if="isProductFileTypeImage(item.displayCardFileType)"
                  >
                    <b-img :src="replaceDisplayCardUrl(item)"></b-img>
                  </template>
                  <template v-else>
                    <video
                      :poster="replaceDisplayPosterUrl(item)"
                      :src="replaceDisplayCardUrl(item)"
                      type="video/webm"
                      muted
                      loop
                      autoplay
                      playsinline
                    ></video>
                  </template>
                </template>
                <b-skeleton-img
                  no-aspect
                  width="100%"
                  height="100%"
                ></b-skeleton-img>
              </div>
            </v-lazy>
            <section class="card-info">
              <section class="card-info__title" v-if="!isBusy">
                <p class="artist_name">
                  <template
                    v-if="
                      activeLanguage !== undefined && activeLanguage === 'ko'
                    "
                  >
                    {{ item.artistName || item.artistNameEn }}
                  </template>
                  <template v-else>
                    {{ item.artistNameEn || item.artistName }}
                  </template>
                </p>
                <div class="icon-wrap">
                  <FavoritedButton
                    :favoritedItem="item"
                    @onClickFavoritedButton="onClickFavoritedButton"
                  />
                </div>
              </section>
              <section class="card-info__title" v-else>
                <b-skeleton
                  class="float-left mt-2 ml-2"
                  width="100px"
                ></b-skeleton>
              </section>
              <h3 class="card-info__name" v-if="!isBusy">
                <template
                  v-if="activeLanguage !== undefined && activeLanguage === 'ko'"
                >
                  {{ item.productName || item.productNameEn }}
                </template>
                <template v-else>
                  {{ item.productNameEn || item.productName }}
                </template>
              </h3>

              <h3 class="card-info__name" v-else>
                <b-skeleton
                  class="float-left mt-2 ml-2"
                  width="200px"
                ></b-skeleton>
              </h3>
              <section
                class="card-info__price"
                :class="{ soldout: item.status === 'SOLDOUT' }"
                v-if="!isBusy"
              >
                <p class="price">price</p>
                <p class="amount">
                  <template v-if="item.status === 'SOLDOUT'">
                    {{
                      item.buyerNickname !== undefined &&
                      item.buyerNickname !== ""
                        ? "Owned by @" + item.buyerNickname
                        : $t("product.statusSoldOut")
                    }}
                  </template>
                  <template v-else-if="item.status === 'EXHIBIT'">
                    {{ $t("product.statusExhibit") }}
                  </template>
                  <template
                    v-else-if="
                      activeLanguage !== undefined &&
                      activeLanguage === 'en' &&
                      isCoinPriceNonZero(item.nftType) &&
                      isAcceptableNftType(item.nftType)
                    "
                  >
                    {{ getProductCoinPrice(item.productPrice, item.nftType) }}
                    {{ getCoinCurrencyByNftType(item.nftType) }}
                  </template>
                  <template v-else>
                    {{ item.productPrice | currency }}
                    {{
                      item.productPrice !== undefined && item.productPrice > 0
                        ? "KRW"
                        : $t("product.statusExhibit")
                    }}
                  </template>
                </p>
              </section>

              <section class="card-info__price" v-else>
                <span>
                  <b-skeleton width="50%" height="25px"></b-skeleton>
                </span>
              </section>
            </section>
          </div>
        </article>
      </div>
      <div class="row pt-5">
        <div class="col-12 text-right">
          <b-pagination
            v-on:change="onPaging"
            :total-rows="galleryProductItems.totalElements"
            v-model="currentPage"
            :per-page="perPage"
            hide-ellipsis
            limit="10"
            aria-controls="role-function-list"
            class="float-right gallery_pagination"
          >
            <template #first-text>
              <img
                class="page_icon rotate_page_icon"
                src="/img/threespace/icon/pagination_arrow2_icon.png"
              />
            </template>
            <template #prev-text>
              <img
                class="page_icon rotate_page_icon"
                src="/img/threespace/icon/pagination_arrow_icon.png"
              />
            </template>
            <template #next-text>
              <img
                class="page_icon"
                src="/img/threespace/icon/pagination_arrow_icon.png"
              />
            </template>
            <template #last-text>
              <img
                class="page_icon"
                src="/img/threespace/icon/pagination_arrow2_icon.png"
              />
            </template>
            <template #page="{ page }">
              <span :class="{ active_num: page === currentPage }">
                {{ page }}
              </span>
            </template>
          </b-pagination>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import FavoritedButton from "../components/common/FavoritedButton.vue";
import CardHoverBox from "../components/common/CardHoverBox.vue";
import coinUtils from "../../../mixins/CoinUtils";

export default {
  components: {
    FavoritedButton,
    CardHoverBox,
  },
  name: "NFTs",
  props: {
    idxArtist: {
      type: Number,
      default: 0,
    },
  },
  mixins: [coinUtils],
  data() {
    return {
      //TODO 이하 현재 미사용
      isBusy: false,
      isLast: false,
      perPage: 20,
      currentPage: 1,
      sort: [
        { id: "createdAt", desc: "desc" },
        { id: "idx", desc: "desc" },
      ],
      externalParams: {
        idxArtist: this.idxArtist,
      },
    };
  },
  computed: {
    ...mapState("gallery", ["galleryProductItems"]),
    ...mapState({
      me: (state) => {
        return state.auth.me || {};
      },
    }),
    ...mapGetters("auth", ["isAuthenticated"]),
    activeLanguage() {
      return this.getActiveLanguage();
    },
  },
  mounted() {
    this.getTickers();
    this.$nextTick(() => {
      this.resetGalleryProducts();
      this.requestGalleryProductItems();
    });
  },
  methods: {
    ...mapMutations("gallery", ["resetGalleryProducts"]),
    ...mapActions("gallery", ["getGalleryProductsDrops"]),
    ...mapActions("coin", ["getTickers"]),
    ...mapActions("favorited", [
      "addFavorited",
      "deleteFavorited",
      "toggleFavorited",
    ]),
    ...mapActions("auth", ["verifyTokenCallback"]),
    getActiveLanguage() {
      return localStorage.getItem("language") || "en";
    },
    requestGalleryProductItems() {
      this.isBusy = true;
      this.getGalleryProductsDrops(
        this.getPagingParams(
          {
            page: this.currentPage - 1,
            pageSize: this.perPage,
            sorted: this.sort,
            filtered: [],
          },
          this.externalParams
        )
      )
        .then((data) => {
          this.isLast = data.last;
          setTimeout(() => {
            this.currentElement = this.currentPage * this.perPage;
            this.isBusy = false;
          }, 2000);
        })
        .catch((error) => {
          this.isBusy = false;
          this.$log.error(error);
        });
    },
    getPagingParams({ page, pageSize, sorted, filtered }, externalParams) {
      const params = {
        page,
        size: pageSize,
        ...externalParams,
      };

      // sorting parameter
      let sorts = [];
      for (let i = 0, length = sorted.length; i < length; i++) {
        const sort = sorted[i];
        sorts.push(`${sort.id},${sort.desc ? "desc" : "asc"}`);
      }
      params["sort"] = sorts;

      // filtering parameter
      for (let i = 0, length = filtered.length; i < length; i++) {
        const filter = filtered[i];
        params[filter.id] = filter.value;
      }

      return params;
    },
    showProductDetail(idx) {
      this.$router.push("/art/" + idx);
    },
    isProductFileTypeImage(_productFileType) {
      if (
        _productFileType !== undefined &&
        _productFileType !== null &&
        _productFileType.startsWith("image")
      ) {
        return true;
      } else {
        return false;
      }
    },
    replaceDisplayPosterUrl(item) {
      if (
        item.displayPoster !== undefined &&
        item.displayPoster !== null &&
        item.displayPoster !== "" &&
        item.displayPoster === "THREESPACE"
      ) {
        return item.displayPosterUrlThreespace; // 3space S3 URL
      } else {
        if (
          item.displayPosterUrl !== undefined &&
          item.displayPosterUrl.startsWith("https://ipfs.io/")
        ) {
          return item.displayPosterUrl.replace(
            "https://ipfs.io/",
            "https://ipfs.3space.art/"
          );
        }
        return item.displayPosterUrl;
      }
    },
    replaceDisplayCardUrl(item) {
      if (
        item.displayCardFile !== undefined &&
        item.displayCardFile !== null &&
        item.displayCardFile !== "" &&
        item.displayCardFile === "THREESPACE"
      ) {
        return item.displayCardUrlThreespace; // 3space S3 URL
      } else {
        if (
          item.displayCardUrl !== undefined &&
          item.displayCardUrl.startsWith("https://ipfs.io/")
        ) {
          return item.displayCardUrl.replace(
            "https://ipfs.io/",
            "https://ipfs.3space.art/"
          );
        }
        return item.displayCardUrl;
      }
    },
    onClickFavoritedButton(favoritedItem) {
      if (this.isAuthenticated) {
        this.verifyTokenCallback().then(() => {
          if (
            this.me === undefined ||
            this.me === null ||
            this.me.idx === undefined ||
            this.me.idx === null ||
            this.me.idx === ""
          ) {
            // 로그인
            this.hasKlaytnAddr = false;
            this.$store.commit("setCommonAlertModalInfo", {
              msgHtml: this.$t("validation.requiredLogin"),
              show: true,
              fontSize: "sm",
            });
          } else {
            // 로그인 되어있음
            if (favoritedItem.favorited) {
              // true이면 좋아요 헤재
              this.toggleFavorited(favoritedItem);
              this.deleteFavorited({ idxProduct: favoritedItem.idx });
            } else {
              // false이면 좋아요
              this.toggleFavorited(favoritedItem);
              this.addFavorited({ idxProduct: favoritedItem.idx });
            }
          }
        });
      } else {
        this.$store.commit("setCommonAlertModalInfo", {
          msgHtml: this.$t("validation.requiredLogin"),
          show: true,
          fontSize: "sm",
        });
      }
    },
    onPaging(page) {
      window.scrollTo(0, 0);
      this.currentPage = page;
      this.resetGalleryProducts();
      this.requestGalleryProductItems();
    },
  },
  filters: {
    currency: (value) => {
      if (!value) return "";
      return parseInt(value)
        .toFixed(0)
        .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    },
  },
};
</script>
