<template>
  <b-modal
    id="nftpoolGalleryModal"
    ref="nftpoolGalleryModal"
    hide-header
    hide-footer
    centered
    body-class="text-center text-black"
    @hide="modalEvent"
    @show="modalEvent"
  >
    <CRow>
      <CCol class="col-12">
        <CLink @click="$refs.nftpoolGalleryModal.hide()" class="float-right">
          <img src="/img/threespace/icon/x-icon.png" />
        </CLink>
      </CCol>
    </CRow>
    <div class="artpool_gallery_modal">
      <div class="modal_title">
        EVENT
      </div>
      <img src="/img/threespace/artpool/artpool_gallery_modal_object.jpg" />
      <p class="content" v-html="$t('nftpool.artpoolGallery.modalContent')"></p>
      <b-button class="more_btn" @click="onClickMoreBtn">
        {{ $t("nftpool.artpoolGallery.details") }}
      </b-button>
      <b-button
        variant="link"
        class="not_seen_week_btn"
        @click="onClickNotSeenBtn"
      >
        {{ $t("nftpool.artpoolGallery.doNotShow") }}
      </b-button>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "NftPoolGalleryModal",
  data: function () {
    return {
    }
  },
  computed: {
  },
  methods: {
    show() {
      this.$refs["nftpoolGalleryModal"].show();
    },
    hide() {
      this.$refs["nftpoolGalleryModal"].hide();
    },
    modalEvent() {
    },
    onClickNotSeenBtn() {
      this.$emit("onClickPopupNoneSeenWeekBtn");
    },
    onClickMoreBtn() {
      this.$router.push("/features/14");
      this.hide();
    }
  },
  filters: {
  }
}
</script>
