import ApiService from "@/services/api.service";
import logger from "@/logger";

// action types
export const ADD_EXTERNAL_NFT_COLLECTIONS_KLAYTN = "addExternalNftCollectionsKlaytn";
export const ADD_EXTERNAL_NFT_COLLECTIONS_ETHEREUM = "addExternalNftCollectionsEthereum";
export const ADD_EXTERNAL_NFT_COLLECTIONS_POLYGON = "addExternalNftCollectionsPolygon";

const state = {};

const getters = {};

const actions = {
  [ADD_EXTERNAL_NFT_COLLECTIONS_KLAYTN](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("/threespace/v1/nfts/klaytn", payload)
          .then(({ data }) => {
            resolve(data.data);
          })
          .catch(({ response }) => {
            logger.error(response);
            reject(response);
          });
    });
  },
  [ADD_EXTERNAL_NFT_COLLECTIONS_ETHEREUM](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("/threespace/v1/nfts/ethereum", payload)
          .then(({ data }) => {
            resolve(data.data);
          })
          .catch(({ response }) => {
            logger.error(response);
            reject(response);
          });
    });
  },
  [ADD_EXTERNAL_NFT_COLLECTIONS_POLYGON](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("/threespace/v1/nfts/polygon", payload)
          .then(({ data }) => {
            resolve(data.data);
          })
          .catch(({ response }) => {
            logger.error(response);
            reject(response);
          });
    });
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
