<template>
  <div>
    <b-modal
      id="collection-import-nft-modal"
      ref="collection-import-nft-modal"
      hide-header
      hide-footer
      centered
      scrollable
      no-close-on-esc
      no-close-on-backdrop
    >
      <CRow>
        <CCol class="col-12 py-2">
          <div class="d-flex justify-content-end">
            <img
              class="cursor-pointer"
              src="/img/threespace/icon/close.png"
              alt="close"
              @click="hide"
            />
          </div>
        </CCol>
      </CRow>

      <!-- NFT 선택 -->
      <div
        class="contract_select_wrap"
        v-if="Object.keys(selectedNft).length === 0"
      >
        <p class="title">{{ $t("mypage.nftImport") }}</p>
        <div class="contract_select_list">
          <template v-for="(item, i) in nftTableItems.content">
            <b-button
              :key="i"
              block
              class="text-left px-3"
              @click="onClickNft(item)"
            >
              <b>[{{ item.symbol }}]</b> {{ item.name }}
              <span class="float-right">{{
                item.contractAddr | simpleAddr
              }}</span>
            </b-button>
          </template>
        </div>
        <div class="whitelist_info">
          <p>Klaytn NFT만 가져오기가 가능합니다.</p>
        </div>
      </div>

      <!-- TokenId 입력 -->
      <div class="collection_select_wrap" v-else>
        <p class="title">
          {{ $t("mypage.nftImport") }}<br />
          <span class="contract_name">
            <b>[{{ selectedNft.symbol }}] </b>{{ selectedNft.name }}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {{ selectedNft.contractAddr | simpleAddr }}
          </span>
        </p>

        <div class="collection_select_list">
          <!-- toke id 입력 -->
          <p class="mb-1 text-left font-weight-bold text-primary">
            Token Id 입력
          </p>
          <b-form-input
            type="text"
            v-model="tokenId"
            placeholder="Token Id를 입력해 주세요"
          />
        </div>

        <div class="btn_box">
          <b-button class="prev_btn" @click="onClickPrev" :disabled="isBusy">
            {{ $t("button.prev") }}
          </b-button>
          <b-button class="next_btn" @click="onClickNext" :disabled="isBusy">
            <b-spinner
              small
              class="ml-1"
              v-if="isBusy"
              variant="light"
              label="Spinning"
            ></b-spinner>
            <template v-else>
              {{ $t("button.next") }}
            </template>
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  data: function () {
    return {
      isBusy: false,
      selectedNft: {},
      tokenId: "",
    };
  },
  mounted() {},
  computed: {
    ...mapState("nftManager", ["nftTableItems"]),
  },
  methods: {
    ...mapActions("nftManager", [
      "importKlaytnNft",
      "importEthereumNft",
      "importPolygonNft",
    ]),
    show() {
      this.$refs["collection-import-nft-modal"].show();
    },
    hide() {
      this.$refs["collection-import-nft-modal"].hide();
      this.selectedNft = {};
    },
    onClickNft(nft) {
      this.selectedNft = nft;
    },
    onClickPrev() {
      this.selectedNft = {};
    },
    onClickNext() {
      let check = /^[0-9]+$/;
      if (this.tokenId !== "") {
        if (!check.test(this.tokenId)) {
          alert("숫자만 입력가능합니다.");
        } else {
          this.isBusy = true;
          let params = {
            tokenId: this.$options.filters.decimalToHexdecimal(
              this.tokenId * 1
            ),
            contractAddr: this.selectedNft.contractAddr,
          };
          if (this.selectedNft.network === "KLAYTN") {
            this.importKlaytnNft(params)
              .then(() => {
                // import 성공
                this.$emit("importNftSuccess");
                this.hide();
                this.isBusy = false;
              })
              .catch(() => {
                // import 실패
                alert("불러오기에 실패하였습니다. 관리자에게 문의해주세요");
                this.hide();
                this.isBusy = false;
              });
          } else if (this.selectedNft.network === "ETHEREUM") {
            this.importEthereumNft(params)
              .then(() => {
                // import 성공
                this.$emit("importNftSuccess");
                this.hide();
                this.isBusy = false;
              })
              .catch(() => {
                // import 실패
                alert("불러오기에 실패하였습니다. 관리자에게 문의해주세요");
                this.hide();
                this.isBusy = false;
              });
          } else if (this.selectedNft.network === "POLYGON") {
            this.importPolygonNft(params)
              .then(() => {
                // import 성공
                this.$emit("importNftSuccess");
                this.hide();
                this.isBusy = false;
              })
              .catch(() => {
                // import 실패
                alert("불러오기에 실패하였습니다. 관리자에게 문의해주세요");
                this.hide();
                this.isBusy = false;
              });
          } else {
            alert("불러오기에 실패하였습니다. 관리자에게 문의해주세요");
            this.isBusy = false;
          }
        }
      } else {
        alert("토큰아이디를 입력해주세요.");
      }
    },
  },
  filters: {
    simpleAddr: (value) => {
      if (value !== undefined) {
        return value.slice(0, 5) + "..." + value.slice(-3);
      }
      return "";
    },
    decimalToHexdecimal: (value) => {
      if (value !== undefined) {
        return "0x" + value.toString(16);
      } else {
        return "";
      }
    },
  },
};
</script>
