<template>
  <div id="Meme">
    <div class="meme-section">
      <div class="title_box">
        <h3>MEME</h3>
      </div>
      <div class="top">
        <div class="planet">
          <img class="planet" src="/img/threespace/meme/planet.png" />
          <img class="c1" src="/img/threespace/meme/c1.png" />
          <img class="c2" src="/img/threespace/meme/c2.png" />
          <img class="c3" src="/img/threespace/meme/c3.png" />
          <img class="c4" src="/img/threespace/meme/c4.png" />
          <img class="c5" src="/img/threespace/meme/c5.png" />
        </div>
        <!-- <div class="title">The Third Space</div>
        <div class="subtitle">Build the Web3 World with us</div>
        <div class="img-container">
          <img src="/img/threespace/meme/top-middle-image.png" />
        </div> -->
        <div class="meme-container">
          <div>COMING SOON</div>
          <div>[Become the p3ople of the third space]</div>
        </div>
      </div>
      <!-- <div class="middle">
        <div class="img-container">
          <img src="/img/threespace/meme/middle-image.png" />
        </div>
      </div>
      <div class="bottom">
        <img
          class="absolute"
          src="/img/threespace/meme/bottom-background.png"
        />
        <div class="title">
          {{ $t("meme.title") }}
        </div>
        <div class="subtitle-section">
          <div>
            <div class="subtitle">{{ $t("meme.description1") }}</div>
            <div class="subtitle">{{ $t("meme.description2") }}</div>
            <div class="subtitle">{{ $t("meme.description3") }}</div>
          </div>
          <div>
            <div class="subtitle">{{ $t("meme.description4") }}</div>
            <div class="subtitle">{{ $t("meme.description5") }}</div>
          </div>
        </div>
        <div class="btn-wrap">
          <button>Coming Soon</button>
        </div>
        <div class="mission-wraps">
          <template v-for="item in 6">
            <div class="mission" :key="item">
              <div>{{ $t(`meme.mission${item}`) }}</div>
              <img src="/img/threespace/meme/meme.png" />
            </div>
          </template>
        </div> -->
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {};
  },
  methods: {},
};
</script>
