<template>
  <li :class="{ active: isActive }">
    <div class="header-hover__main">
      <span>{{ $t("menu.artPool") }}</span>
      <img src="/img/threespace/icon/chevron.svg" />
    </div>
    <div class="header-hover__menu">
      <router-link to="/art-pool/nft/gallery">
        {{ $t("menu.artPoolSub.viewGallery") }}
      </router-link>
      <router-link to="/art-pool/nft">
        {{ $t("menu.artPoolSub.exhibit") }}
      </router-link>
    </div>
  </li>
</template>
<script>
export default {
  name: "HeaderDropdownArtpool",
  data() {
    return {};
  },
  computed: {
    isActive() {
      const activeLink = ["/art-pool/nft/gallery", "/art-pool/nft"];
      return activeLink.includes(this.$route.path);
    },
  },
  methods: {},
};
</script>
