<template>
  <b-modal
    id="followModal"
    ref="followModal"
    centered
    hide-header
    hide-footer
    body-class="text-center text-black mt-1 mb-3 mx-2"
    @hide="modalEvent(false)"
    @show="modalEvent(true)"
  >
    <div class="row">
      <div class="col-12">
        <CLink @click="$refs.followModal.hide()" class="float-right">
          <img src="/img/threespace/icon/x-icon.png" />
        </CLink>
      </div>
    </div>

    <b-tabs class="follow_tabs" v-model="selectedTab">
      <!-- Followers -->
      <b-tab>
        <template #title>
          Followers
        </template>
        <Followers :idx-member="idxMember" />
      </b-tab>
      <!-- Following -->
      <b-tab>
        <template #title>
          Following
        </template>
        <Following :idx-member="idxMember" />
      </b-tab>
    </b-tabs>
  </b-modal>
</template>

<script>
import Followers from "./Followers.vue";
import Following from "./Following.vue";

export default {
  name: "FollowModal",
  components: {
    Followers,
    Following
  },
  data() {
    return {
      selectedTab: 0,
      tabs: [
        { index: 0, name: "Followers" },
        { index: 1, name: "Following" }
      ],
      idxMember: null
    };
  },
  computed: {},
  mounted() {},
  methods: {
    show(_activeTab, _idxMember) {
      this.idxMember = _idxMember;
      this.selectedTab = _activeTab;
      this.$refs["followModal"].show();
    },
    hide() {
      this.$refs["followModal"].hide();
    },
    modalEvent(isVisible) {
      if (isVisible) {
        document.getElementsByTagName("html")[0].style.overflow = "hidden";
      } else {
        document.getElementsByTagName("html")[0].style.overflow = "auto";
      }
    }
  }
}
</script>
