<template>
  <div>
    <CCard>
      <CCardBody>
        <CRow>
          <CCol class="mb-3"><h5>아트풀 투표</h5></CCol>
        </CRow>
        <b-tabs
          active-nav-item-class="bg-info text-light font-weight-bold"
          content-class="mt-3"
          v-model="tabIndex"
          disabled
          @input="onClickTab"
        >
          <b-tab title="Ethereum">
            <CForm>
              <CRow>
                <CCol col="8">
                  <b-table-simple>
                    <colgroup>
                      <col style="width:15%;" />
                      <col style="width:85%;" />
                    </colgroup>
                    <b-tbody>
                      <b-tr>
                        <b-th variant="secondary">라운드</b-th>
                        <b-td>
                          {{ artpoolInfo.roundNoErc || "라운드가 시작되지 않았습니다." }}
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-th variant="secondary">
                          투표 시작 <span class="text-danger font-lg">*</span>
                        </b-th>
                        <b-td>
                          <div class="row align-items-center">
                            <div class="col-4">
                              <CInput
                                class="mb-0"
                                disabled
                                v-model="artpoolInfo.voteStartDate"
                              />
                            </div>
                            <div class="col-8">
                              <CIcon name="cil-info" class="text-info mr-1" />
                              <span class="text-info">해당 시간으로 블록에 기록됩니다. (아트풀 수정페이지에서 변경)</span>
                            </div>
                          </div>
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-th variant="secondary">
                          투표 종료 <span class="text-danger font-lg">*</span>
                        </b-th>
                        <b-td>
                          <div class="row align-items-center">
                            <div class="col-4">
                              <CInput
                                class="mb-0"
                                disabled
                                v-model="artpoolInfo.voteEndDate"
                              />
                            </div>
                            <div class="col-8">
                              <CIcon name="cil-info" class="text-info mr-1" />
                              <span class="text-info">해당 시간으로 블록에 기록됩니다. (아트풀 수정페이지에서 변경)</span>
                            </div>
                          </div>
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-th variant="secondary">
                          투표 시작(블록)
                        </b-th>
                        <b-td>
                          <div class="row align-items-center">
                            <div class="col-4">
                              <CInput
                                class="mb-0"
                                disabled
                                v-model="ercVoteInfo.voteStartDate"
                              />
                            </div>
                            <div class="col-8">
                              <CIcon name="cil-info" class="text-info mr-1" />
                              <span class="text-info">블록에 기록된 투표 시간</span>
                            </div>
                          </div>
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-th variant="secondary">
                          투표 종료(블록)
                        </b-th>
                        <b-td>
                          <div class="row align-items-center">
                            <div class="col-4">
                              <CInput
                                class="mb-0"
                                disabled
                                v-model="ercVoteInfo.voteEndDate"
                              />
                            </div>
                            <div class="col-8">
                              <CIcon name="cil-info" class="text-info mr-1" />
                              <span class="text-info">블록에 기록된 투표 시간</span>
                            </div>
                          </div>
                        </b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </CCol>
                <CCol col="4">
                  투표 정산 정보(이더리움)
                  <b-table-simple>
                    <colgroup>
                      <col style="width:30%;" />
                      <col style="width:70%;" />
                    </colgroup>
                    <b-tbody>
                      <b-tr>
                        <b-th variant="secondary">총 투표작품 수</b-th>
                        <b-td>
                          {{ ercVoteWinnerInfo.totalVotedNFT}}
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-th variant="secondary">총 투표 수</b-th>
                        <b-td>
                          {{ ercVoteWinnerInfo.totalVoted}}
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-th variant="secondary">우승작품 투표 수</b-th>
                        <b-td>
                          {{ ercVoteWinnerInfo.winnerVoted}}
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-th variant="secondary">우승작품 NFT</b-th>
                        <b-td>
                          {{ ercVoteWinnerInfo.winnerNFT}}
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-th variant="secondary">우승작품 token ID</b-th>
                        <b-td>
                          {{ ercVoteWinnerInfo.winnerTokenId}}
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-th variant="secondary">승자</b-th>
                        <b-td v-if="ercVoteWinnerInfo.winners">
                          {{ ercVoteWinnerInfo.winners.length }}명
                        </b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                  <b-table-simple>
                    <colgroup>
                      <col style="width:40%;" />
                      <col style="width:60%;" />
                    </colgroup>
                    <b-tbody class="mt-10">
                      <b-tr>
                        <b-th variant="secondary">수수료(%)</b-th>
                        <b-td>
                          <CInput
                            class="mb-0"
                            v-model="finishVoteInfo.ercFeeRate"
                          />
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-th variant="secondary">수수료 받는 주소</b-th>
                        <b-td>
                          <CInput
                            class="mb-0"
                            v-model="finishVoteInfo.ercCollector"
                          />
                        </b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                  <CButton color="light" @click="onClickFinishErcVote" class="mr-2">
                    <CIcon name="cil-money" class="mr-2"/>투표정산
                  </CButton>
                </CCol>
              </CRow>
              <CRow>
                <CCol col="8" class="text-center my-4" v-if="!artpoolInfo.roundNoErc">
                  <span class="text-danger">투표 설정 전에 라운드를 먼저 시작해주세요!</span>
                </CCol>
                <CCol col="8" class="text-center my-4">
                  <CButton color="light" @click="onClickCancel" class="mr-2">
                    <CIcon name="cil-arrow-circle-left" class="mr-2" />목록
                  </CButton>
                  <CButton
                    color="dark"
                    @click="onClickSetEthVote"
                    class="text-white"
                    :disabled="!artpoolInfo.roundNoErc ||
                    (new Date(this.artpoolInfo.voteStartDate).getTime() === new Date(this.ercVoteInfo.voteStartDate).getTime() &&
                     new Date(this.artpoolInfo.voteEndDate).getTime() === new Date(this.ercVoteInfo.voteEndDate).getTime())"
                  >
                    <CIcon name="cil-save" class="mr-2" />투표 설정
                  </CButton>
                </CCol>
              </CRow>
            </CForm>
          </b-tab>
          <b-tab title="Klaytn">
            <CForm>
              <CRow>
                <CCol col="8">
                  <b-table-simple>
                    <colgroup>
                      <col style="width:15%;" />
                      <col style="width:85%;" />
                    </colgroup>
                    <b-tbody>
                      <b-tr>
                        <b-th variant="secondary">라운드</b-th>
                        <b-td>
                          {{ artpoolInfo.roundNoKip || "라운드가 시작되지 않았습니다." }}
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-th variant="secondary">
                          투표 시작 <span class="text-danger font-lg">*</span>
                        </b-th>
                        <b-td>
                          <div class="row align-items-center">
                            <div class="col-4">
                              <CInput
                                class="mb-0"
                                disabled
                                v-model="artpoolInfo.voteStartDate"
                              />
                            </div>
                            <div class="col-8">
                              <CIcon name="cil-info" class="text-info mr-1" />
                              <span class="text-info">해당 시간으로 블록에 기록됩니다. (아트풀 수정페이지에서 변경)</span>
                            </div>
                          </div>
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-th variant="secondary">
                          투표 종료 <span class="text-danger font-lg">*</span>
                        </b-th>
                        <b-td>
                          <div class="row align-items-center">
                            <div class="col-4">
                              <CInput
                                class="mb-0"
                                disabled
                                v-model="artpoolInfo.voteEndDate"
                              />
                            </div>
                            <div class="col-8">
                              <CIcon name="cil-info" class="text-info mr-1" />
                              <span class="text-info">해당 시간으로 블록에 기록됩니다. (아트풀 수정페이지에서 변경)</span>
                            </div>
                          </div>
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-th variant="secondary">
                          투표 시작(블록)
                        </b-th>
                        <b-td>
                          <div class="row align-items-center">
                            <div class="col-4">
                              <CInput
                                class="mb-0"
                                disabled
                                v-model="kipVoteInfo.voteStartDate"
                              />
                            </div>
                            <div class="col-8">
                              <CIcon name="cil-info" class="text-info mr-1" />
                              <span class="text-info">블록에 기록된 투표 시간</span>
                            </div>
                          </div>
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-th variant="secondary">
                          투표 종료(블록)
                        </b-th>
                        <b-td>
                          <div class="row align-items-center">
                            <div class="col-4">
                              <CInput
                                class="mb-0"
                                disabled
                                v-model="kipVoteInfo.voteEndDate"
                              />
                            </div>
                            <div class="col-8">
                              <CIcon name="cil-info" class="text-info mr-1" />
                              <span class="text-info">블록에 기록된 투표 시간</span>
                            </div>
                          </div>
                        </b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </CCol>
                <CCol col="4">
                  투표 정산 정보(클레이튼)
                  <b-table-simple>
                    <colgroup>
                      <col style="width:30%;" />
                      <col style="width:70%;" />
                    </colgroup>
                    <b-tbody>
                      <b-tr>
                        <b-th variant="secondary">총 투표작품 수</b-th>
                        <b-td>
                          {{ kipVoteWinnerInfo.totalVotedNFT}}
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-th variant="secondary">총 투표 수</b-th>
                        <b-td>
                          {{ kipVoteWinnerInfo.totalVoted}}
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-th variant="secondary">우승작품 투표 수</b-th>
                        <b-td>
                          {{ kipVoteWinnerInfo.winnerVoted}}
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-th variant="secondary">우승작품 NFT</b-th>
                        <b-td>
                          {{ kipVoteWinnerInfo.winnerNFT}}
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-th variant="secondary">우승작품 token ID</b-th>
                        <b-td>
                          {{ kipVoteWinnerInfo.winnerTokenId}}
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-th variant="secondary">승자</b-th>
                        <b-td v-if="kipVoteWinnerInfo.winners">
                          {{ kipVoteWinnerInfo.winners.length }}명
                        </b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                  <b-table-simple>
                    <colgroup>
                      <col style="width:40%;" />
                      <col style="width:60%;" />
                    </colgroup>
                    <b-tbody class="mt-10">
                      <b-tr>
                        <b-th variant="secondary">수수료(%)</b-th>
                        <b-td>
                          <CInput
                            class="mb-0"
                            v-model="finishVoteInfo.kipFeeRate"
                          />
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-th variant="secondary">수수료 받는 주소</b-th>
                        <b-td>
                          <CInput
                            class="mb-0"
                            v-model="finishVoteInfo.kipCollector"
                          />
                        </b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                  <CButton color="light" @click="onClickFinishKipVote" class="mr-2">
                    <CIcon name="cil-money" class="mr-2"/>투표정산
                  </CButton>
                </CCol>
              </CRow>
              <CRow>
                <CCol col="8" class="text-center my-4" v-if="!artpoolInfo.roundNoKip">
                  <span class="text-danger">투표 설정 전에 라운드를 먼저 시작해주세요!</span>
                </CCol>
                <CCol col="8" class="text-center my-4">
                  <CButton color="light" @click="onClickCancel" class="mr-2">
                    <CIcon name="cil-arrow-circle-left" class="mr-2" />목록
                  </CButton>
                  <CButton
                    color="dark"
                    @click="onClickSetKipVote"
                    class="text-white"
                    :disabled="!artpoolInfo.roundNoKip ||
                    (new Date(this.artpoolInfo.voteStartDate).getTime() === new Date(this.kipVoteInfo.voteStartDate).getTime() &&
                     new Date(this.artpoolInfo.voteEndDate).getTime() === new Date(this.kipVoteInfo.voteEndDate).getTime())"
                  >
                    <CIcon name="cil-save" class="mr-2" />투표 설정
                  </CButton>
                </CCol>
              </CRow>
            </CForm>
          </b-tab>
        </b-tabs>
      </CCardBody>
    </CCard>
    <b-modal
      id="message-modal"
      ref="message-modal"
      hide-header
      hide-footer
      centered
      no-close-on-esc
      no-close-on-backdrop
      body-class="my-4 text-dark d-flex flex-column"
    >
      <div class="mt-3 text-center">
        {{ message }}<br/>
      </div>
      <div class="mt-3 d-flex justify-content-center" v-if="!submitted">
        <div class="sk-wave">
          <div class="sk-wave-rect"></div>
          <div class="sk-wave-rect"></div>
          <div class="sk-wave-rect"></div>
          <div class="sk-wave-rect"></div>
          <div class="sk-wave-rect"></div>
        </div>
      </div>
      <div class="w-100 text-center mt-5">
        <b-button
          variant="primary"
          squared
          class="text-white px-5 py-2"
          @click="hideMessageModal"
          :disabled="!submitted"
        >
          확인
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import {validationMixin} from "vuelidate";
import {required} from "vuelidate/lib/validators";
const artpoolHelper = createNamespacedHelpers("artpoolManager");

export default {
  name: "ArtpoolVote",
  components: {},
  props: {
    idxArtpool: {
      type: String,
      default: "0"
    }
  },
  mixins: [validationMixin],
  validations() {
    return {
      artpoolInfo: {
        voteStartDate: { required },
        voteEndDate: { required },
      },
    };
  },
  data: function() {
    return {
      artpoolInfo: {},
      ercVoteInfo: this.getInitVoteInfo(),
      kipVoteInfo: this.getInitVoteInfo(),
      tabIndex: 0,
      submitted: false,
      message: "처리중입니다.",
      ercVoteWinnerInfo: this.getInitVoteWinnerInfo(),
      kipVoteWinnerInfo: this.getInitVoteWinnerInfo(),
      finishVoteInfo: {
        ercFeeRate: 10,
        ercCollector: null,
        kipFeeRate: 10,
        kipCollector: null,
      }
    };
  },
  computed: {
    isValid() {
      return !this.$v.artpoolInfo.$invalid;
    },
    isDirty() {
      return this.$v.artpoolInfo.$anyDirty;
    }
  },
  mounted() {
    this.requestArtpoolItem();
    this.requestErcVoteWinner();
    this.requestKipVoteWinner();
  },
  methods: {
    ...artpoolHelper.mapActions(["getArtpool", "getErcRound", "updateErcVote", "getKipRound", "updateKipVote", "getErcVoteWinner", "getKipVoteWinner", "finishErcVote", "finishKipVote"]),
    requestArtpoolItem() {
      if (this.idxArtpool && this.idxArtpool !== 0) {
        this.getArtpool(this.idxArtpool).then(data => {
          this.$log.info(this.artpoolInfo); //TODO
          this.isBusy = false;
          this.artpoolInfo = Object.assign({}, this.artpoolInfo, data);
          this.requestErcRoundInfo();
          this.requestKipRoundInfo();
        })
          .catch(error => {
            alert("아트풀 정보 조회 오류가 발생하였습니다. 관리자에게 문의하세요.");
            this.$log.error(error);
          });
      }
    },
    requestErcRoundInfo() {
      if(this.idxArtpool && this.idxArtpool !== "0" && this.artpoolInfo.roundNoErc > 0){
        this.getErcRound(this.idxArtpool).then(data => {
          this.ercVoteInfo = Object.assign({}, this.ercVoteInfo, data);
        })
          .catch(error => {
            alert("아트풀 라운드 정보 조회 오류가 발생하였습니다. 관리자에게 문의하세요.");
            this.$log.error(error);
          })
      }
    },
    requestErcVoteWinner() {
      if(this.idxArtpool && this.idxArtpool !== "0"){
        this.getErcVoteWinner(this.idxArtpool).then(data => {
          this.ercVoteWinnerInfo = Object.assign({}, this.ercVoteWinnerInfo, data);
        })
          .catch(error => {
            alert("아트풀 투표 정보 조회 오류가 발생하였습니다. 관리자에게 문의하세요.");
            this.$log.error(error);
          })
      }
    },
    requestKipRoundInfo() {
      if(this.idxArtpool && this.idxArtpool !== "0" && this.artpoolInfo.roundNoErc > 0){
        this.getKipRound(this.idxArtpool).then(data => {
          this.kipVoteInfo = Object.assign({}, this.kipVoteInfo, data);
        })
          .catch(error => {
            alert("아트풀 라운드 정보 조회 오류가 발생하였습니다. 관리자에게 문의하세요.");
            this.$log.error(error);
          })
      }
    },
    requestKipVoteWinner() {
      if(this.idxArtpool && this.idxArtpool !== "0"){
        this.getKipVoteWinner(this.idxArtpool).then(data => {
          this.kipVoteWinnerInfo = Object.assign({}, this.kipVoteWinnerInfo, data);
        })
          .catch(error => {
            alert("아트풀 투표 정보 조회 오류가 발생하였습니다. 관리자에게 문의하세요.");
            this.$log.error(error);
          })
      }
    },
    onClickTab() {
    },
    onClickSetEthVote() {
      if (this.isValid) {
        if (confirm("Ethereum 투표 정산을 하시겠습니까?")) {

          const voteStartDate = new Date(this.artpoolInfo.voteStartDate);
          const voteEndDate = new Date(this.artpoolInfo.voteEndDate);

          const startTimestamp = voteStartDate.getTime() / 1000;
          const endTimestamp = voteEndDate.getTime() / 1000;

          const payload = {
            idxArtpool: this.idxArtpool,
            startTimestamp,
            endTimestamp,
            round: this.artpoolInfo.roundNoErc,
          }

          this.submitted = false;
          this.$refs["message-modal"].show();

          this.updateErcVote(payload).then(data => {
            if (data != null) {
              this.message = "Ethereum 투표 설정이 정상적으로 됐습니다.";
              this.submitted = true;
              this.voteInfo = Object.assign({}, this.voteInfo, data);
            } else {
              this.message = "Ethereum 투표 설정 오류가 발생하였습니다. 관리자에게 문의하세요.";
              this.submitted = true;
            }
          })
            .catch(error => {
              this.message = "Ethereum 투표 설정 오류가 발생하였습니다. 관리자에게 문의하세요.\n" + error;
              this.submitted = true;
              this.$log.error(error);
            });

        }
      } else {
        this.$v.$touch();
      }
    },
    onClickSetKipVote() {
      if (this.isValid) {
        if (confirm("Kaia 투표 시간을 설정하시겠습니까?")) {

          const voteStartDate = new Date(this.artpoolInfo.voteStartDate);
          const voteEndDate = new Date(this.artpoolInfo.voteEndDate);

          const startTimestamp = voteStartDate.getTime() / 1000;
          const endTimestamp = voteEndDate.getTime() / 1000;

          const payload = {
            idxArtpool: this.idxArtpool,
            startTimestamp,
            endTimestamp,
            round: this.artpoolInfo.roundNoKip,
          }

          this.submitted = false;
          this.$refs["message-modal"].show();

          this.updateKipVote(payload).then(data => {
            if (data != null) {
              this.message = "Kaia 투표 설정이 정상적으로 됐습니다.";
              this.submitted = true;
              this.voteInfo = Object.assign({}, this.voteInfo, data);
            } else {
              this.message = "Kaia 투표 설정 오류가 발생하였습니다. 관리자에게 문의하세요.";
              this.submitted = true;
            }
          })
            .catch(error => {
              this.message = "Kaia 투표 설정 오류가 발생하였습니다. 관리자에게 문의하세요.\n" + error;
              this.submitted = true;
              this.$log.error(error);
            });

        }
      } else {
        this.$v.$touch();
      }
    },
    hideMessageModal(){
      this.$refs["message-modal"].hide();
    },
    onClickCancel() {
      this.$router.push("/management/artpool-list");
    },
    onClickFinishErcVote() {
      if(this.finishVoteInfo.ercFeeRate >= 0 &&
        this.finishVoteInfo.ercFeeRate <= 100 &&
        this.finishVoteInfo.ercCollector &&
        this.finishVoteInfo.ercCollector.length > 0
      ){
        if (confirm("Ethereum 투표 정산을 하시겠습니까?")) {

          const payload = {
            idxArtpool: this.idxArtpool,
            round: this.artpoolInfo.roundNoErc,
            feeRate: this.finishVoteInfo.ercFeeRate,
            collector: this.finishVoteInfo.ercCollector
          }

          this.submitted = false;
          this.$refs["message-modal"].show();

          this.finishErcVote(payload).then(data => {
            if (data) {
              this.message = "Ethereum 투표 정산이 정상적으로 됐습니다.";
              this.submitted = true;
            } else {
              this.message = "Ethereum 투표 정산 오류가 발생하였습니다. 관리자에게 문의하세요.";
              this.submitted = true;
            }
          })
            .catch(error => {
              this.message = "Ethereum 투표 정산 오류가 발생하였습니다. 관리자에게 문의하세요.\n" + error;
              this.submitted = true;
              this.$log.error(error);
            });
        } else {
          this.$v.$touch();
        }
      }
    },
    onClickFinishKipVote() {
      if(this.finishVoteInfo.kipFeeRate >= 0 &&
        this.finishVoteInfo.kipFeeRate <= 100 &&
        this.finishVoteInfo.kipCollector &&
        this.finishVoteInfo.kipCollector.length > 0
      ){
        if (confirm("Kaia 투표 정산을 하시겠습니까?")) {

          const payload = {
            idxArtpool: this.idxArtpool,
            round: this.artpoolInfo.roundNoKip,
            feeRate: this.finishVoteInfo.kipFeeRate,
            collector: this.finishVoteInfo.kipCollector
          }

          this.submitted = false;
          this.$refs["message-modal"].show();

          this.finishKipVote(payload).then(data => {
            if (data) {
              this.message = "Kaia 투표 정산이 정상적으로 됐습니다.";
              this.submitted = true;
            } else {
              this.message = "Kaia 투표 정산 오류가 발생하였습니다. 관리자에게 문의하세요.";
              this.submitted = true;
            }
          })
            .catch(error => {
              this.message = "Kaia 투표 정산 오류가 발생하였습니다. 관리자에게 문의하세요.\n" + error;
              this.submitted = true;
              this.$log.error(error);
            });
        } else {
          this.$v.$touch();
        }
      }
    },
    getInitVoteInfo() {
      return {
        round: null,
        voteStartDate: null,
        voteEndDate: null,
      };
    },
    getInitVoteWinnerInfo() {
      return {
        toalVotedNFT: null,
        totalVoted: null,
        winnerNFT : null,
        winnerTokenId: null,
        winnerVoted: null,
        winners: [],
      }
    },
  }
};
</script>
