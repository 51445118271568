import { mapState, mapActions } from "vuex";

export default {
  data: function () {
    return {
      acceptableNftType: ["ETHEREUM", "KLAYTN", "KLIP", "POLYGON", "ORDINALS"]
    };
  },
  computed: {
    ...mapState("coin", ["eth", "klay", "matic", "btc"])
  },
  methods: {
    isAcceptableNftType(nftType) {
      return this.acceptableNftType.includes(nftType);
    },
    isCoinPriceNonZero(nftType) {
      let coinPrice;
      if(nftType === "ETHEREUM"){
        coinPrice = this.eth.price;
      } else if(nftType === "KLIP" || nftType === "KLAYTN"){
        coinPrice = this.klay.price;
      } else if(nftType === "POLYGON") {
        coinPrice = this.matic.price;
      } else if(nftType === "ORDINALS") {
        coinPrice = this.btc.price;
      }

      return coinPrice > 0 && coinPrice !== null && coinPrice !== undefined
    },
    getSatoshiToBtc(sat) {
      return parseFloat(sat / 100000000)
    },
    getSatoshiToKRW(sat){
      const price = sat * this.btc.price / 100000000
      return price.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    },
    getProductCoinPrice(productPrice, nftType) {
      if (nftType === "ETHEREUM") {
        const price = productPrice / this.eth.price;
        return parseFloat(Math.floor(price * 1000) / 1000)
          .toFixed(3)
          .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
      } else if (nftType === "KLIP" || nftType === "KLAYTN") {
        return parseInt(productPrice / this.klay.price)
          .toFixed(0)
          .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
      } else if (nftType === "POLYGON" ) {
        return parseInt(productPrice / this.matic.price)
          .toFixed(0)
          .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
      } else if (nftType === "ORDINALS") {
        const price = productPrice / 100000000;
        return parseFloat(Math.floor(price * 100000000) / 100000000)
          .toFixed(8)
      }
    },
    getCoinCurrencyByNftType(nftType) {
      if (nftType === "ETHEREUM") {
        return "ETH";
      } else if (nftType === "KLIP" || nftType === "KLAYTN") {
        return "KAIA";
      } else if (nftType === "POLYGON") {
        return "MATIC";
      } else if (nftType === "ORDINALS") {
        return "BTC";
      }
    },
    getCoinCurrencyByCurrency(currency) {
      if(currency === "KLAY") {
        return "KAIA"
      }
      return currency;
    }
  }
}