<template>
  <div class="follow_modal_content">
    <template v-if="followingItemsContent === undefined || followingItemsContent === null || followingItemsContent.length === 0">
      <div class="row mx-0">
        <div class="col-12 mb-3 py-3 text-center" key="0">
          <p>{{ $t("artist.noResult") }}</p>
        </div>
      </div>
    </template>
    <div class="row mx-0 mb-2"
       v-for="(item, i) in followingItemsContent"
       :key="i"
       :ref="'followingItem' + item.idx"
    >
      <div class="col-2 my-auto">
        <template v-if="item.followingProfileImage === undefined">
          <b-img
            rounded="circle"
            src="/img/avatars/profile-gray-person.jpg"
            class="cursor-pointer" @click="onClickUserNickname(item)"
          />
        </template>
        <template v-else>
          <b-img
            rounded="circle"
            :src="item.followingProfileImageUrl"
            class="cursor-pointer" @click="onClickUserNickname(item)"
          />
        </template>
      </div>
      <div class="col-6 pl-0 my-auto text-left">
        <span class="cursor-pointer" @click="onClickUserNickname(item)">@{{ item.followingNickname }}</span>
      </div>
      <div class="col-4 follow_list_item">
        <button :id="'following-'+i" @click.stop="onClickMemberFollow(item)" :class="{ 'followed': item.isFollow }">
          {{ item.isFollow !== undefined && item.isFollow ? 'Unfollow' : 'Follow' }}
        </button>
      </div>
    </div>
    <scroll-loader
      :loader-method="requestFollowingItems"
      :loader-disable="isLast"
    >
      <svg
        data-v-c2de8a26=""
        viewBox="25 25 50 50"
        class="loader__svg"
        style="width: 50px;"
      >
        <circle
          data-v-c2de8a26=""
          cx="50"
          cy="50"
          r="20"
          class="loader__circle"
          style="stroke: rgb(204, 204, 204);"
        ></circle>
      </svg>
    </scroll-loader>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const profileHelper = createNamespacedHelpers("profile");
const authHelper = createNamespacedHelpers("auth");

export default {
  name: "Following",
  props: {
    idxMember: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      isBusy: false,
      perPage: 10,
      isLast: true,
      currentPage: 0,
      sort: [
        { id: "updatedAt", desc: "desc" },
        { id: "idx", desc: "desc" }
      ],
      externalParams: {
        keywordIdxMemberFollower: null
      }
    };
  },
  computed: {
    ...profileHelper.mapState(["followingItems", "followingItemsContent"]),
    ...authHelper.mapState(["me"])
  },
  mounted() {
    this.$nextTick(() => {
      this.externalParams.keywordIdxMemberFollower = this.idxMember;
      this.requestFollowingItems();
    })
  },
  methods: {
    ...profileHelper.mapActions(["getFollowing", "updateMemberFollow"]),
    ...authHelper.mapActions(["verifyTokenCallback"]),
    requestFollowingItems() {
      this.isBusy = true;
      this.getFollowing(
        this.getPagingParams(
          {
            page: this.currentPage,
            pageSize: this.perPage,
            sorted: this.sort,
            filtered: [],
          },
          this.externalParams
        )
      )
      .then(data => {
        this.isLast = data.last;
        this.currentPage = data.pageable.page + 1;
        this.isBusy = false;
      })
      .catch(error => {
        this.isBusy = false;
        this.$log.error(error);
      });
    },
    onClickMemberFollow(item) {
      this.verifyTokenCallback()
        .then(() => {
          if (
            this.me === undefined ||
            this.me === null ||
            this.me.idx === undefined ||
            this.me.idx === null ||
            this.me.idx === ""
          ) {
            // 로그인
            this.$store.commit("setCommonAlertModalInfo", {
              msgHtml: this.$t("artist.requiredLogin"),
              show: true,
              fontSize: "sm"
            });
          } else {
            // Follow/Unfollow
            const follow = item.isFollow !== undefined && item.isFollow === true ? false : true;
            const param = { idxMember: item.idxMemberFollower, follow: follow, referral: null };  // TODO
            this.updateMemberFollow(param)
              .then(data => {
                if (Object.keys(data).length !== 0 && JSON.stringify(data) !== JSON.stringify({})) {
                  item.isFollow = data.isFollow;
                }
              })
              .catch(error => {
                this.$log.error(error);
                alert(this.$t("artist.failTryAgain"));
              });
          }
        })
        .catch(error => {
          this.$log.error(error);
          // 로그인
          this.$store.commit("setCommonAlertModalInfo", {
            msgHtml: this.$t("artist.requiredLogin"),
            show: true,
            fontSize: "sm"
          });
        });
    },
    onClickUserNickname(item) {
      //this.$log.log(item); //TODO 아티스트인 경우 작가페이지로 이동? 또는 일반사용자와 동일한 화면으로 적용후 수정!
      if (item.idxMemberFollower !== undefined && item.idxMemberFollower !== null && item.idxMemberFollower > 0) {
        let idxEncode = window.btoa(item.idxMemberFollower);
        this.$router.push("/collections/" + idxEncode);
        document.getElementsByTagName("html")[0].style.overflow = "auto";
      }
    },
    getPagingParams({ page, pageSize, sorted, filtered }, externalParams) {
      const params = {
        page,
        size: pageSize,
        ...externalParams
      };

      // sorting parameter
      let sorts = [];
      for (let i = 0, length = sorted.length; i < length; i++) {
        const sort = sorted[i];
        sorts.push(`${sort.id},${sort.desc ? "desc" : "asc"}`);
      }
      params["sort"] = sorts;

      // filtering parameter
      for (let i = 0, length = filtered.length; i < length; i++) {
        const filter = filtered[i];
        params[filter.id] = filter.value;
      }
      return params;
    }
  }
}
</script>
