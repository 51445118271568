import ApiService from "@/services/api.service";
import logger from "@/logger";
import FileDownload from "js-file-download";

// action types
export const GET_PROFILE = "getProfile";
export const GET_PROFILE_SIMPLE = "getProfileSimple";
export const UPDATE_PROFILE = "updateProfile";
export const UPLOAD_PROFILE_IMAGE = "updateProfileImage";
export const UPLOAD_PROFILE_IMAGE_BY_COLLECTION = "updateProfileImageByCollection";
export const UPLOAD_BANNER_IMAGE = "updateBannerImage";
export const UPDATE_PROFILE_PASSWORD = "updateProfilePassword";
export const UPDATE_PROFILE_SETTING = "updateProfileSetting";
export const UPDATE_PROFILE_ARTIST = "updateProfileArtist";
export const DELETE_MEMBER = "deleteMember";
export const GET_COLLECTIONS = "getCollections";
export const DOWNLOAD_COLLECTIONS = "downloadCollections";
export const GET_NFTS = "getNFTs";
export const GET_AUTH_REQUEST_DATA = "getAuthRequestData";
export const GET_AUTH_RESULT = "getAuthResult";

// NFT - 블록체인 처리 방식 변경
export const ADD_NFT_EXPORT_REQUESTED = "addNftExportRequestedTx";

export const GET_FOLLOWERS = "getFollowers";
export const GET_FOLLOWING = "getFollowing";
export const UPDATE_MEMBER_FOLLOW = "updateMemberFollow";

// mutation types
export const SET_PROFILE = "setProfile";
export const SET_PROFILE_SIMPLE = "setProfileSimple";
export const SET_COLLECTIONS = "setCollections";
export const RESET_COLLECTIONS = "resetCollections";
export const SET_NFTS = "setNFTs";
export const SET_AUTH_RESULT = "setAuthResult";

export const SET_FOLLOWERS = "setFollowers";
export const RESET_FOLLOWERS = "resetFollowers";
export const SET_FOLLOWING = "setFollowing";
export const RESET_FOLLOWING = "resetFollowing";

const state = {
  errors: null,
  memberInfo: {},
  memberSimpleInfo: {},
  collectionItems: {
    content: [],
    first: true,
    last: false,
    number: 0,
    numberOfElements: 0,
    size: 0,
    sort: {
      orders: []
    },
    total: 0,
    totalElements: 0,
    totalPages: 1
  },
  collectionItemsContent: [],
  collectionList: [],
  nftList: [],
  // Followers/Following
  followersItems: {
    content: [],
    first: true,
    last: false,
    number: 0,
    numberOfElements: 0,
    size: 0,
    sort: {
      orders: []
    },
    total: 0,
    totalElements: 0,
    totalPages: 1
  },
  followersItemsContent: [],
  followingItems: {
    content: [],
    first: true,
    last: false,
    number: 0,
    numberOfElements: 0,
    size: 0,
    sort: {
      orders: []
    },
    total: 0,
    totalElements: 0,
    totalPages: 1
  },
  followingItemsContent: []
};

const getters = {};

const actions = {
  [GET_PROFILE](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("/profile/v1/profile/" + payload)
        .then(({ data }) => {
          context.commit(SET_PROFILE, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [GET_PROFILE_SIMPLE](context, payload) {
    return new Promise((resolve, reject) => {
      context.commit(SET_PROFILE_SIMPLE, {});
      ApiService.get("/threespace/v1/profile/simple/" + payload)
        .then(({ data }) => {
          context.commit(SET_PROFILE_SIMPLE, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [UPDATE_PROFILE](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.put("/profile/v1/profile/" + payload.idx, payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [UPDATE_PROFILE_PASSWORD](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.put("/profile/v1/password/" + payload.idx, payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response.data);
        });
    });
  },
  [UPDATE_PROFILE_SETTING](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.put("/profile/v1/setting/" + payload.idx, payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response.data);
        });
    });
  },
  [UPDATE_PROFILE_ARTIST](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.put("/profile/v1/artist/" + payload.idxMember, payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response.data);
        });
    });
  },
  [UPLOAD_PROFILE_IMAGE](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post_upload_file("/profile/v1/upload/profile", payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [UPLOAD_PROFILE_IMAGE_BY_COLLECTION](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("/profile/v1/upload/profile/collection", payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [UPLOAD_BANNER_IMAGE](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post_upload_file("/profile/v1/upload/banner", payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [DELETE_MEMBER](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.delete("/profile/v1/members/member", payload)
        .then(({ data }) => {
          //logger.log("[DELETE_MEMBER] ", data);
          resolve(data);
        })
        .catch(response => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [GET_COLLECTIONS](context, payload) {
    /*TODO
    if (payload.page == 0) {
      context.commit(RESET_COLLECTIONS);
    }*/
    return new Promise((resolve, reject) => {
      ApiService.get("/profile/v1/collections", payload)
        .then(({ data }) => {
          context.commit(SET_COLLECTIONS, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [GET_NFTS](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("/profile/v1/nfts/" + payload)
        .then(({ data }) => {
          context.commit(SET_NFTS, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [DOWNLOAD_COLLECTIONS](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get_download_file("/profile/v1/collections/" + payload)
        .then(( data ) => {
          logger.log("[DOWNLOAD_COLLECTIONS] ");
          var contentDisposition = data.headers['content-disposition'];
          var filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].split('"')[1].trim();
          FileDownload(data.data, filename)
        })
        .catch(response => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [GET_AUTH_REQUEST_DATA](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("/threespace/v1/niceapi/verify/request-data", payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [GET_AUTH_RESULT](context, payload) {
    //logger.info("[GET_AUTH_RESULT] ", payload);
    context.commit(SET_AUTH_RESULT, payload);
  },
  [ADD_NFT_EXPORT_REQUESTED](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("/profile/v1/nft/export/requested", payload)
          .then(({ data }) => {
            resolve(data.data);
          })
          .catch(({ response }) => {
            logger.error(response);
            reject(response);
          });
    });
  },
  [GET_FOLLOWERS](context, payload) {
    if (payload.page === 0) {
      context.commit(RESET_FOLLOWERS);
    }
    return new Promise((resolve, reject) => {
      ApiService.get("/threespace/v1/member/followers", payload)
          .then(({ data }) => {
            context.commit(SET_FOLLOWERS, data.data);
            resolve(data.data);
          })
          .catch(({ response }) => {
            logger.error(response);
            reject(response);
          });
    });
  },
  [GET_FOLLOWING](context, payload) {
    if (payload.page === 0) {
      context.commit(RESET_FOLLOWING);
    }
    return new Promise((resolve, reject) => {
      ApiService.get("/threespace/v1/member/following", payload)
          .then(({ data }) => {
            context.commit(SET_FOLLOWING, data.data);
            resolve(data.data);
          })
          .catch(({ response }) => {
            logger.error(response);
            reject(response);
          });
    });
  },
  [UPDATE_MEMBER_FOLLOW](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("/threespace/v1/member/follow", payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  }
};

const mutations = {
  [SET_PROFILE](state, memberInfo) {
    state.memberInfo = memberInfo;
  },
  [SET_PROFILE_SIMPLE](state, memberSimpleInfo) {
    state.memberSimpleInfo = memberSimpleInfo;
  },
  [SET_COLLECTIONS](state, collectionList) {
    state.collectionList = collectionList;
    //state.collectionItems = collectionList;
    //state.collectionItemsContent.push(...collectionList.content);
  },
  [RESET_COLLECTIONS](state) {
    state.collectionItemsContent = [];
  },
  [SET_NFTS](state, nftList) {
    state.nftList = nftList;
  },
  [SET_AUTH_RESULT](state, payload) {
    state.memberInfo.name = payload.name;
    state.memberInfo.mobile = payload.mobile;
    state.memberInfo.identityVerify = payload.identityVerify;
  },
  [SET_FOLLOWERS](state, followers) {
    state.followersItems = followers;
    state.followersItemsContent.push(...followers.content);
  },
  [RESET_FOLLOWERS](state) {
    state.followersItemsContent = [];
  },
  [SET_FOLLOWING](state, following) {
    state.followingItems = following;
    state.followingItemsContent.push(...following.content);
  },
  [RESET_FOLLOWING](state) {
    state.followingItemsContent = [];
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
