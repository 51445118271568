import {EthereumProvider} from "@walletconnect/ethereum-provider";

export default {
  data: function () {
    return {};
  },
  computed: {},
  methods: {
    async getWalletConnectProvider(chainId = 1) {
      return await EthereumProvider.init({
        projectId: process.env.VUE_APP_WALLETCONNECT_PROJECT_ID,
        showQrModal: true,
        qrModalOptions: {themeMode: "light"},
        chains: [chainId],
        methods: ["eth_sendTransaction", "personal_sign"],
        events: ["chainChanged", "accountsChanged"],
        metadata: {
          name: "3space Art",
          description: "3space Art. Collect and exhibit digital art NFTs.",
          url: "https://3space.art",
          icons: ["https://3space.art/favicon-96x96.png"],
        },
      })
    },
    checkValidAddress(address, memberInfo, chainId) {
      let memberAddress= '';
      if(chainId.toString() === process.env.VUE_APP_POLYGON_CHAIN_ID){
        memberAddress = memberInfo.polygonAddr;
      } else if(chainId.toString() === process.env.VUE_APP_ETHEREUM_CHAIN_ID) {
        memberAddress = memberInfo.ethereumAddr;
      }

      return address.toLowerCase() !== memberAddress.toLowerCase()
    },
    checkValidChain(chainId){
      const isNotEthereumChain = chainId === null || chainId === undefined || chainId.toString() !== process.env.VUE_APP_ETHEREUM_CHAIN_ID
      const isNotPolygonChain = chainId === null || chainId === undefined || chainId.toString() !== process.env.VUE_APP_POLYGON_CHAIN_ID

      return isNotEthereumChain && isNotPolygonChain
    }
  }
}