<template>
  <b-modal
    id="polygon-collection-select-modal"
    ref="polygon-collection-select-modal"
    hide-header
    hide-footer
    centered
    scrollable
    @hide="closeModal"
  >
    <CRow>
      <CCol class="col-12 py-2">
        <div class="d-flex justify-content-end">
          <img
            class="cursor-pointer"
            src="/img/threespace/icon/close.png"
            alt="close"
            @click="$refs['polygon-collection-select-modal'].hide()"
          />
        </div>
      </CCol>
    </CRow>

    <p
      style="
        color: #000;
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 6px;
        text-align: center;
      "
    >
      {{ $t("mypage.polygonNftImport") }}
    </p>

    <!-- Collection 선택 =========================== -->
    <div class="collection_select_wrap">
      <!-- <p class="title">
        {{ $t("mypage.polygonNftImport") }}
      </p> -->

      <template v-if="isBusyPolygon">
        <div class="contract_select_list">
          <div class="sk-wave mx-auto mt-5">
            <div class="sk-wave-rect"></div>
            <div class="sk-wave-rect"></div>
            <div class="sk-wave-rect"></div>
            <div class="sk-wave-rect"></div>
            <div class="sk-wave-rect"></div>
          </div>
        </div>
      </template>

      <template v-else>
        <template
          v-if="
            tempPolygonNftCollectionList !== undefined &&
            tempPolygonNftCollectionList.length > 0
          "
        >
          <div class="collection_select_list">
            <div class="total_cnt">
              <div class="float-left">
                {{ $t("mypage.selected") }} ({{
                  selectedNftCollectionList.length
                }}/{{ tempPolygonNftCollectionList.length }})
              </div>
              <div class="float-right">
                <img src="/img/threespace/icon/refresh_icon.png" />
              </div>
            </div>
            <b-table-simple
              sticky-header="330px"
              borderless
              no-border-collapse
              head-variant="light"
              fixed
            >
              <b-thead>
                <tr>
                  <th colspan="1">
                    <div
                      class="d-flex justify-content-center"
                      style="height: 16px"
                    >
                      <div
                        style="
                          width: 15px;
                          height: 15px;
                          border: 1px solid #999;
                          cursor: pointer;
                          position: relative;
                        "
                        @click="selectedAllList"
                      >
                        <v-icon
                          v-if="allSelected"
                          style="
                            position: absolute;
                            top: 0;
                            left: 0;
                            color: #000;
                            font-size: 13px;
                          "
                          >mdi-check</v-icon
                        >
                      </div>
                    </div>
                  </th>
                  <th colspan="2">
                    {{ $t("mypage.productName") }}
                  </th>
                  <th colspan="2">
                    {{ $t("mypage.tokenId") }}
                  </th>
                  <th colspan="2">
                    {{ $t("mypage.contract") }}
                  </th>
                </tr>
              </b-thead>
              <b-tbody>
                <template v-for="(item, i) in tempPolygonNftCollectionList">
                  <tr
                    :key="i"
                    :class="{
                      error_row: item.error !== undefined,
                      already_row: isAlreadyRegister(item),
                    }"
                  >
                    <td
                      colspan="1"
                      class="d-flex justify-content-center align-items-center"
                    >
                      <div class="checkbox_wrap">
                        <div
                          style="
                            width: 15px;
                            height: 15px;
                            border: 0.7px solid #999;
                            cursor: pointer;
                            position: relative;
                            margin-top: 4px;
                          "
                          @click="onClickContractItem(item)"
                        >
                          <v-icon
                            v-if="item.selected || isAlreadyRegister(item)"
                            style="
                              position: absolute;
                              top: 0;
                              left: 0;
                              color: #000;
                            "
                            >mdi-check</v-icon
                          >
                        </div>
                        <!-- <template v-if="isAlreadyRegister(item)">
                        <v-icon>mdi-check-underline</v-icon>
                      </template>
                      <template v-else>
                        <b-form-checkbox
                          :id="'collection' + i"
                          v-model="selectedNftCollectionList"
                          :value="item"
                          :disabled="
                            selectedNftCollectionList.length > 4 &&
                            selectedNftCollectionList.indexOf(item) === -1
                          "
                          v-if="item.error === undefined"
                        ></b-form-checkbox>
                      </template> -->
                      </div>
                    </td>
                    <td colspan="2">
                      <label class="m-0" :for="'collection' + i">
                        <template v-if="item.error !== undefined">
                          ERROR
                        </template>
                        <template v-else>
                          {{ item.title }}
                        </template>
                      </label>
                    </td>
                    <td colspan="2">
                      <label class="m-0" :for="'collection' + i"
                        >#{{ item.tokenId }}</label
                      >
                    </td>
                    <td colspan="2">
                      <label class="m-0" :for="'collection' + i">{{
                        item.contract.address | simpleAddr
                      }}</label>
                    </td>
                  </tr>
                </template>
              </b-tbody>
            </b-table-simple>
          </div>

          <div class="btn_box">
            <p class="text-grey">
              * 가져오기는 1회 당 최대 10개까지 권고합니다. 10개 초과 일 경우,
              일정시간이 소요될 수 있습니다.
            </p>
          </div>
          <div class="d-flex justify-content-center">
            <div class="mt-3" style="width: 100%">
              <Button :label="$t('button.next')" :onClick="onClickNext" />
            </div>
          </div>
        </template>
        <template v-else>
          <div class="empty_box">
            {{ $t("stake.stakeModal.noData") }}
          </div>
        </template>
      </template>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapState } from "vuex";
import BigNumber from "bignumber.js";
import Button from "@/views/threespace/components/common/Button.vue";

export default {
  props: {
    isBusyPolygon: {
      type: Boolean,
      default: false,
    },
    polygonNftCollectionList: {
      type: Array,
      default: () => [],
    },
  },
  data: function () {
    return {
      tempPolygonNftCollectionList: [],
      selectedNftCollectionList: [],
      allSelected: false,
    };
  },
  mounted() {
    this.tempPolygonNftCollectionList = this.polygonNftCollectionList.map(
      (list) => {
        return {
          ...list,
          selected: false,
        };
      }
    );
  },
  computed: {
    ...mapState("auth", ["me"]),
    ...mapState("collection", ["collectionAllList"]),
  },
  components: {
    Button,
  },
  methods: {
    ...mapActions("nft", ["addExternalNftCollectionsPolygon"]),
    onClickNext() {
      // @TODO 승인 받을 수 있으면 승인 받기
      // get NFT
      if (this.selectedNftCollectionList.length > 0) {
        this.$store.commit("setShowLoading", { show: true, msg: "" });

        this.addExternalNftCollectionsPolygon(this.selectedNftCollectionList)
          .then(() => {
            this.$store.commit("setShowLoading", { show: false, msg: "" });
            this.$store.commit("setCommonAlertModalInfo", {
              msgHtml: this.$t("mypage.importModal.complete"),
              show: true,
              fontSize: "sm",
            });
            this.hide();
            this.$emit("addExternalNftSuccess");
          })
          .catch(() => {
            this.$store.commit("setCommonAlertModalInfo", {
              msgHtml: this.$t("mypage.importModal.fail"),
              show: true,
              fontSize: "sm",
            });
            this.hide();
            this.$store.commit("setShowLoading", { show: false, msg: "" });
          });
      } else {
        alert("불러오기 할 tokenId를 선택해주세요.");
      }
    },
    show() {
      this.$refs["polygon-collection-select-modal"].show();
    },
    hide() {
      this.$refs["polygon-collection-select-modal"].hide();
    },
    closeModal() {
      this.$log.info("close modal");
      this.$emit("clearNftCollectionList");
      this.selectedNftCollectionList = [];
    },
    selectedAllList() {
      if (this.allSelected) {
        this.tempPolygonNftCollectionList =
          this.tempPolygonNftCollectionList.map((el) => {
            return {
              ...el,
              selected: false,
            };
          });
        this.selectedNftCollectionList = [];
        this.allSelected = false;
      } else {
        this.tempPolygonNftCollectionList =
          this.tempPolygonNftCollectionList.map((el) => {
            return {
              ...el,
              selected: true,
            };
          });
        this.selectedNftCollectionList = this.tempPolygonNftCollectionList;
        this.allSelected = true;
      }
    },
    onClickContractItem(nftObj) {
      if (
        this.tempPolygonNftCollectionList.find(
          (el) => el.tokenId === nftObj.tokenId
        )?.selected === false
      ) {
        delete nftObj.selected;
        this.selectedNftCollectionList.push(nftObj);
        this.tempPolygonNftCollectionList =
          this.tempPolygonNftCollectionList.map((el) => {
            if (el.tokenId === nftObj.tokenId) {
              return {
                ...el,
                selected: true,
              };
            } else {
              return el;
            }
          });
      } else {
        this.selectedNftCollectionList = this.selectedNftCollectionList.filter(
          (el) => el.tokenId !== nftObj.tokenId
        );
        this.tempPolygonNftCollectionList =
          this.tempPolygonNftCollectionList.map((el) => {
            if (el.tokenId === nftObj.tokenId) {
              return {
                ...el,
                selected: false,
              };
            } else {
              return el;
            }
          });
      }

      if (
        this.tempPolygonNftCollectionList.length ===
        this.selectedNftCollectionList.length
      ) {
        this.allSelected = true;
      } else {
        this.allSelected = false;
      }
    },
    isAlreadyRegister(item) {
      if (item.tokenId !== undefined && item.tokenId !== "") {
        let contractAddr = item.contract.address;
        let rfc = item.tokenType;
        if (rfc.indexOf("-") === -1) {
          rfc = rfc.slice(0, 3) + "-" + rfc.slice(3);
        }
        let index = this.collectionAllList.findIndex(
          (el) =>
            el.tokenId === item.tokenId &&
            el.nft.rfc === rfc &&
            el.nft.contractAddr === contractAddr
        );
        if (index === -1) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
  },
  filters: {
    simpleAddr: (value) => {
      if (value !== undefined) {
        return value.slice(0, 5) + "..." + value.slice(-3);
      }
      return "";
    },
    hexToNumber: (value) => {
      if (value !== undefined) {
        if (value.indexOf("0x") !== -1) {
          // BigNumber
          var x = new BigNumber(value, 16);
          var result = x.toString(10);
          if (result.length > 10) {
            result = result.slice(0, 10) + "...";
          }
          return result;
        } else {
          return value;
        }
      } else {
        return "";
      }
    },
  },
};
</script>
