import { render, staticRenderFns } from "./SimpleImagePopupBySystemMaintenance.vue?vue&type=template&id=8dbc7ad4&scoped=true&"
import script from "./SimpleImagePopupBySystemMaintenance.vue?vue&type=script&lang=js&"
export * from "./SimpleImagePopupBySystemMaintenance.vue?vue&type=script&lang=js&"
import style0 from "./SimpleImagePopupBySystemMaintenance.vue?vue&type=style&index=0&id=8dbc7ad4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8dbc7ad4",
  null
  
)

export default component.exports