import ApiService from "@/services/api.service";
import logger from "@/logger";

// action types
export const GENERATE_IMAGE = "generateImage";

// mutation types

const state = {};

const getters = {};

const actions = {
  [GENERATE_IMAGE](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("/threespace/v1/ai/generate", payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
