<template>
  <b-modal
    id="nftTopRankImageModal10"
    ref="nftTopRankImageModal10"
    modal-class="nft-top-rank-image-model top_rank10"
    hide-header
    hide-footer
    centered
    body-class="text-center text-black"
    @hide="modalEvent"
    @show="modalEvent"
  >
    <div class="nft_pool_result_modal">
      <img src="/img/threespace/artpool/art_pool_result10.png" />
      <div class="go-to-exhibition" @click="onClickOnlineGallery()"></div>
      <div class="event-modal-btn-wrap">
        <div @click="onClickNotSeenBtn()">{{ $t("nftpool.artpoolGallery.doNotShow") }}</div>
        <div @click="hide">{{ $t("button.close") }}</div>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "NftPoolTopRankImageModal10",
  props: {
    artpoolInfo: {
      type: Object,
      default: () => {}
    }
  },
  data: function () {
    return {
    }
  },
  computed: {
    activeLanguage() {
      return this.getActiveLanguage();
    },
  },
  methods: {
    show() {
      this.$refs["nftTopRankImageModal10"].show();
    },
    hide() {
      this.$emit("onClickTopRankCloseBtn", this.artpoolInfo.idx);
      this.$refs["nftTopRankImageModal10"].hide();
    },
    getActiveLanguage() {
      return localStorage.getItem("language") || "en";
    },
    onClickNotSeenBtn() {
      this.$emit("onClickTopRankNoneSeenWeekBtn", this.artpoolInfo.idx);
    },
    onClickOnlineGallery() {
      this.$router.push('/art-pool/nft/detail/11');
    },
    modalEvent() {
    }
  },
  filters: {
    decimal: value => {
      if (!value) return "0.00";
      return parseFloat(Math.floor(value * 100) / 100)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    },
    currency: value => {
      if (!value) return "0";
      return parseInt(value)
        .toFixed(0)
        .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    }
  }
}
</script>
<style scoped>
.go-to-exhibition {
  position: absolute;
  left: 50%;
  bottom: 30px;
  transform: translate(-50%, -50%);
  width: calc(100% - 40px);
  height: 50px;
  cursor: pointer;
}
.event-modal-btn-wrap {
  display:flex;
  justify-content: space-between;
  padding: 10px 20px;
  color: #111111;
  font-weight: 600;
  font-family: "Pretendard";
}
.event-modal-btn-wrap div {
  cursor: pointer;
}
</style>
