import ApiService from "@/services/api.service";
import logger from "@/logger";

// action types
export const GET_CODES = "getCommonCodes";
export const GET_CODE = "getCommonCode";
export const ADD_CODE = "addCommonCode";
export const UPDATE_CODE = "updateCommonCode";
export const GET_TERMS = "getTerms";
export const GET_CODE_LIST = "getCodeList";
export const GET_CHILD_MULTI_CODES = "getChildMultiCodes";
export const RESET_CODE = "resetCommonCode";

// mutation types
export const SET_CODES = "setCommonCodes";
export const SET_CODE = "setCommonCode";
export const SET_TERMS = "setTerms";
export const SET_CODE_LIST = "setCodeList";
export const SET_CHILD_MULTI_CODES = "setChildMultiCodes";
export const UPDATE_RESET_CODE = "updateCommonCode";

const state = {
  errors: null,
  codeTableItems: {
    content: [],
    first: true,
    last: false,
    number: 0,
    numberOfElements: 0,
    size: 0,
    sort: {
      orders: []
    },
    total: 0,
    totalElements: 0,
    totalPages: 1
  },
  codeTableFields: [
    { key: "index", label: "번호", class: "text-center", thStyle: "width:5%" },
    {
      key: "idx",
      label: "코드번호",
      class: "text-center",
      thStyle: "width:76px"
    },
    {
      key: "updatedAt",
      label: "등록일시",
      class: "text-center",
      thStyle: "width:142px"
    },
    { key: "parentCode", label: "상위 코드" },
    { key: "parentCodeName", label: "상위 코드명" },
    { key: "code", label: "코드" },
    { key: "codeName", label: "코드명" },
    { key: "codeNameEn", label: "코드명(영문)" },
    {
      key: "orderNum",
      label: "정렬순서",
      class: "text-right",
      thStyle: "width:80px"
    },
    { key: "display", label: "사용여부", class: "text-center", thStyle: "width:76px" },
    {
      key: "actions",
      label: "Actions",
      class: "text-center",
      thStyle: "width:70px"
    }
  ],
  codeInfo: {},
  termsInfo: {
    codeDesc: null
  },
  codeList: [],
  commonCodes: {}
};

const getters = {};

const actions = {
  [GET_CODES](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("/management/v1/codes", payload)
        .then(({ data }) => {
          context.commit(SET_CODES, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [GET_CODE](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("/management/v1/codes/" + payload)
        .then(({ data }) => {
          context.commit(SET_CODE, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [ADD_CODE](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("/management/v1/codes", payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [UPDATE_CODE](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.put("/management/v1/codes/" + payload.idx, payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [GET_TERMS](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("/threespace/v1/terms/" + payload.code)
        .then(({ data }) => {
          context.commit(SET_TERMS, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [GET_CODE_LIST](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("/management/v1/code-list", payload)
        .then(({ data }) => {
          context.commit(SET_CODE_LIST, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [GET_CHILD_MULTI_CODES](context, payload) {
    let parentCodes = [];
    payload.forEach(el => {
      if (!state.commonCodes.hasOwnProperty(el)) {
        parentCodes.push(el);
      }
    });

    //logger.log("[GET_CHILD_MULTI_CODES] parentCodes=", parentCodes);
    // state.commoncodes에 해당 codekey가 있다면 reqeust하지 않음
    if (parentCodes != null && parentCodes.length > 0) {
      return new Promise((resolve, reject) => {
        ApiService.post("/management/v1/codes-multi-childs", {
          parentCodes: parentCodes
        })
          .then(({ data }) => {
            data.data.options.forEach(function(option) {
              context.commit(SET_CHILD_MULTI_CODES, option);
            });
            resolve(data);
          })
          .catch(({ response }) => {
            logger.error(response);
            reject(response);
          });
      });
    }
  },
  [RESET_CODE](context) {
    context.commit(UPDATE_RESET_CODE);
  }
};

const mutations = {
  [SET_CODES](state, codeList) {
    state.codeTableItems = codeList;
  },
  [SET_CODE](state, codeInfo) {
    state.codeInfo = codeInfo;
  },
  [SET_TERMS](state, termsInfo) {
    state.termsInfo = termsInfo;
  },
  [SET_CODE_LIST](state, codeList) {
    state.codeList = codeList;
  },
  [SET_CHILD_MULTI_CODES](state, commonCode) {
    if (!(commonCode.code in state.commonCodes)) {
      const lang = localStorage.getItem("language") || "en";
      state.commonCodes[commonCode.code] = commonCode.childCodes.map(o => {
        let obj = {};
        if (lang === "ko") {
          obj.text = o.codeName;
        } else {
          if (o.codeNameEn !== undefined && o.codeNameEn !== null && o.codeNameEn !== "") {
            obj.text = o.codeNameEn;
          } else {
            obj.text = o.codeName;
          }
        }
        obj.value = o.codeValue;
        return obj;
      });
    }
  },
  [UPDATE_RESET_CODE](state) {
    state.codeInfo = {};
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
