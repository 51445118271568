<template>
  <b-modal
    id="nftVoteModal"
    ref="nftVoteModal"
    hide-header
    hide-footer
    centered
    body-class="text-center text-black"
    @hide="modalEvent"
    @show="modalEvent"
  >
    <CRow>
      <CCol class="col-12">
        <CLink @click="$refs.nftVoteModal.hide()" class="float-right">
          <img src="/img/threespace/icon/x-icon.png" />
        </CLink>
      </CCol>
    </CRow>
    <div class="nft_vote_modal">
      <div class="modal_title">
        {{ $t("stake.btn.nftVote") }}
      </div>
      <div class="vote_nft_type">
        <div class="pace" :class="{ active: voteTypeInfo.tokenSymbol === 'PACE'}">
          PACE
        </div>
        <div class="kpace" :class="{ active: voteTypeInfo.tokenSymbol === 'oPACE'}">
          oPACE
        </div>
      </div>
      <div class="content">
        <b-table-simple borderless>
          <!-- 투표 수량  -->
          <tr class="tr1">
            <td class="title">{{ $t("stake.voteModal.voteAmount") }}</td>
            <td class="amount">
              <CInput
                :lazy="false"
                :value.sync="$v.form.voteAmount.$model"
                :isValid="checkIfValid('voteAmount')"
                :placeholder="$t('stake.vote.placeholderAmount')"
                :invalidFeedback="$t('stake.vote.requiredAmount')"
              />
            </td>
            <td class="currency">
              {{ voteTypeInfo.tokenSymbol }}
              <br />
              <b-button class="max_btn" @click="onClickMaxBtn">MAX</b-button>
            </td>
          </tr>
          <!-- 지갑 보유 수량  -->
          <tr class="tr2">
            <td class="title">{{ $t("stake.voteModal.walletAmount") }}</td>
            <td class="amount">
              {{ tokenBalance | decimal }}
            </td>
            <td class="currency">{{ voteTypeInfo.tokenSymbol }}</td>
          </tr>
          <!-- 나의 투표 현황  -->
          <tr class="tr3">
            <td class="title">{{ $t("stake.voteModal.myAmount") }}</td>
            <td class="amount">{{ voteTypeInfo.userAmountVoted | currency }} <span>/ {{ voteTypeInfo.maxVote | currency }}</span></td>
            <td class="currency">{{ voteTypeInfo.tokenSymbol }}</td>
          </tr>
        </b-table-simple>
        <div class="info_box">
          <p>
            <template v-if="voteTypeInfo.tokenSymbol === 'oPACE'">
              {{ $t("stake.voteModal.klaytnNetwork") }}
            </template>
            <template v-else>
              {{ $t("stake.voteModal.ethereumNetwork") }}
            </template>
          </p>
        </div>
      </div>
    </div>
    <b-button @click="onClickNftVoteBtn" class="nft_stake_modal_btn">
      {{ $t("button.confirm") }}
    </b-button>
  </b-modal>
</template>

<script>
import { validationMixin } from "vuelidate";
import {required, numeric } from "vuelidate/lib/validators";
import { mapState } from "vuex";

export default {
  name: "NftPoolVoteModal",
  props: {
    voteTypeInfo: {
      type: Object,
      default: () => {}
    }
  },
  data: function () {
    return {
      form: {
        voteAmount: null
      },
      tokenBalance: 0
    }
  },
  computed: {
    ...mapState(["wallet"]),
    isValid() {
      return !this.$v.form.$invalid;
    },
    isDirty() {
      return this.$v.form.$anyDirty;
    },
    activeLanguage() {
      return this.getActiveLanguage();
    }
  },
  mixins: [validationMixin],
  validations: {
    form: {
      voteAmount: { required, numeric }
    }
  },
  methods: {
    getBalance() {
      if (this.voteTypeInfo.tokenSymbol === "oPACE") {
        this.tokenBalance = parseFloat(Math.floor(this.wallet.balance.kpace.quantity * 100) / 100).toFixed(2);
      } else if (this.voteTypeInfo.tokenSymbol === "PACE") {
        this.tokenBalance = parseFloat(Math.floor(this.wallet.balance.pace.quantity * 100) / 100).toFixed(2);
      } else {
        this.$log.info(this.$t("stake.notSupported"));
      }
    },
    onClickMaxBtn () {
      const tokenAmount = parseInt(this.tokenBalance);
      if (tokenAmount > this.voteTypeInfo.userRemainingVote) {
        this.form.voteAmount = this.voteTypeInfo.userRemainingVote;
      } else {
        this.form.voteAmount = parseInt(this.tokenBalance);
      }
    },
    onClickNftVoteBtn() {
      if (this.isValid) {
        if (this.voteTypeInfo.userRemainingVote > 0
            && this.form.voteAmount > 0
            && this.form.voteAmount <= this.voteTypeInfo.userRemainingVote
            && this.form.voteAmount <= parseInt(this.tokenBalance)) {
          this.$emit("onClickNftVoteBtn", this.form.voteAmount);
        } else {
          let alertMessage = this.$t("stake.vote.minToken");
          if (this.voteTypeInfo.userRemainingVote === 0
              || this.form.voteAmount > this.voteTypeInfo.userRemainingVote
              || this.form.voteAmount > parseInt(this.tokenBalance)) {
            alertMessage = this.$t("stake.vote.maxToken");
          }
          alert(alertMessage);
          return;
        }
      } else {
        this.$v.$touch();
      }
    },
    show() {
      this.getBalance();
      this.$refs["nftVoteModal"].show();
    },
    hide() {
      this.$refs["nftVoteModal"].hide();
    },
    getActiveLanguage() {
      return localStorage.getItem("language") || "en";
    },
    checkIfValid(fieldName) {
      const field = this.$v.form[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    modalEvent() {
      this.form.voteAmount = null;
      this.$v.$reset();
    }
  },
  filters: {
    decimal: value => {
      if (!value) return "0.00";
      return parseFloat(Math.floor(value * 100) / 100)
          .toFixed(2)
          .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    },
    currency: value => {
      if (!value) return "0";
      return parseInt(value)
          .toFixed(0)
          .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    }
  }
}
</script>
