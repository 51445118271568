<template>
  <b-modal
    id="nft-select-modal"
    ref="nft-select-modal"
    centered
    no-close-on-esc
    no-close-on-backdrop
    hide-footer
    body-class="text-center text-black px-4"
    size="lg"
    title="NFT 선택"
  >
    <!-- 검색 -->
    <div class="row">
      <div class="col">
        <b-input-group>
          <template #prepend>
            <b-form-select
              v-model="searchSelected"
              :options="searchOptions"
              @change="onChangeSearchOption"
            ></b-form-select>
          </template>
          <b-form-input
            placeholder="검색어를 입력해주세요."
            v-model="externalParams.searchKeyword"
            @input="onChangeSearchKeyword"
            @keypress.enter.prevent="onClickSearch"
          ></b-form-input>
          <template #append>
            <b-button size="sm" variant="info" @click="onClickSearch">
              <CIcon name="cil-magnifying-glass" class="mr-1" /><span class="font-md">검색</span>
            </b-button>
            <b-button size="sm" variant="secondary" @click="onClickReset">
              <CIcon name="cil-filter-x"/>
            </b-button>
          </template>
        </b-input-group>
      </div>
    </div>

    <!-- 목록 -->
    <div class="row mt-3 border-bottom">
      <div class="col">
        <b-table
          id="nftTable"
          thead-class="text-center"
          :busy="isBusy"
          :items="nftTableItems.content"
          :fields="nftSelectTableFields"
          :items-per-page="perPage"
          show-empty
          empty-text="조회된 내역이 없습니다."
          selectable
          select-mode="single"
          @row-selected="onClickRowSubmit"
        >
          <!-- NO -->
          <template #cell(index)="data">
            {{ data.index + 1 + (currentPage - 1) * perPage }}
          </template>
          <!-- 이름 -->
          <template #cell(name)="data">
            {{ data.item.name || "-" }}
          </template>

          <!-- 역할 -->
          <template #cell(roles)="data">
            <template v-for="(role, i) in data.item.roles">
              {{ i > 0 ? ", " + role.name : role.name }}
            </template>
          </template>
        </b-table>

        <b-pagination
          aria-controls="nftTable"
          align="center"
          hide-ellipsis
          limit="10"
          v-model="currentPage"
          :total-rows="nftTableItems.totalElements"
          :per-page="perPage"
          :current-page="currentPage"
          v-on:input="onPaging"
        ></b-pagination>
      </div>
    </div>

    <div class="row py-3">
      <div class="col text-center">
        <CButton @click="hide()" color="light" class="mr-2">
          <CIcon name="cil-x-circle" class="mr-2" />취소
        </CButton>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const nftHelper = createNamespacedHelpers("nftManager");

export default {
  name: "NftSelectPopup",
  props: {
    nftType: {
      type: String,
      default: ""
    }
  },
  data: function() {
    return {
      isBusy: false,
      perPage: 10,
      pageOptions: [
        { value: 10, label: "10 개씩 보기" },
        { value: 20, label: "20 개씩 보기" }
      ],
      currentPage: 1,
      sort: [
        { id: "updatedAt", desc: "desc" },
        { id: "idx", desc: "desc" }
      ],
      sortBy: "",
      sortDesc: false,
      // 검색
      searchSelected: "",
      searchOptions: [
        { value: "", text: "전체" },
        { value: "keywordName", text: "이름" },
        { value: "keywordSymbol", text: "심볼" },
        { value: "keywordContractAddr", text: "컨트랙트 주소" },
        { value: "keywordRfc", text: "RFC" }
      ],
      externalParams: this.getInitExternalParams()
    };
  },
  computed: {
    ...nftHelper.mapState(["nftTableItems", "nftSelectTableFields"])
  },
  mounted() {
    //this.requestNftItems();
  },
  methods: {
    ...nftHelper.mapActions(["getNfts"]),
    onPaging() {
      this.requestNftItems();
    },
    onChangeSearchOption() {
      this.setSearchConditions(this.externalParams.searchKeyword);
    },
    onChangeSearchKeyword(keyword) {
      this.setSearchConditions(keyword);
    },
    setSearchConditions(keyword) {
      this.externalParams = this.getInitExternalParams();
      if (this.searchSelected === "") {
        this.externalParams.searchKeyword = keyword;
      } else if (this.searchSelected === "keywordName") {
        this.externalParams.keywordName = keyword;
      } else if (this.searchSelected === "keywordSymbol") {
        this.externalParams.keywordSymbol = keyword;
      } else if (this.searchSelected === "keywordContractAddr") {
        this.externalParams.keywordContractAddr = keyword;
      } else if (this.searchSelected === "keywordRfc") {
        this.externalParams.keywordRfc = keyword;
      }
    },
    resetSearchConditions() {
      this.searchSelected = "";
      this.externalParams = this.getInitExternalParams();
    },
    onClickSearch() {
      this.requestNftItems();
    },
    onClickReset() {
      this.resetSearchConditions();
      this.requestNftItems();
    },
    requestNftItems() {
      this.$log.info(
        "requestNftItems() this.externalParams=",
        this.externalParams
      );
      this.isBusy = true;
      this.getNfts(
        this.getPagingParams(
          {
            page: this.currentPage - 1,
            pageSize: this.perPage,
            sorted: this.sort,
            filtered: []
          },
          this.externalParams
        )
      )
        .then(() => {
          this.isBusy = false;
        })
        .catch(error => {
          this.isBusy = false;
          this.$log.error(error);
        });
    },
    getPagingParams({ page, pageSize, sorted, filtered }, externalParams) {
      const params = {
        page,
        size: pageSize,
        ...externalParams
      };

      // sorting parameter
      let sorts = [];
      for (let i = 0, length = sorted.length; i < length; i++) {
        const sort = sorted[i];
        sorts.push(`${sort.id},${sort.desc ? "desc" : "asc"}`);
      }
      params["sort"] = sorts;

      // filtering parameter
      for (let i = 0, length = filtered.length; i < length; i++) {
        const filter = filtered[i];
        params[filter.id] = filter.value;
      }

      return params;
    },
    show(_nftType) {
      if (_nftType !== undefined) {
        this.externalParams.keywordRfc = _nftType;
      }
      this.requestNftItems();
      this.$refs["nft-select-modal"].show();
    },
    hide() {
      this.$refs["nft-select-modal"].hide();
    },
    onClickRowSubmit(item) {
      if (!!item) {
        this.hide();
        this.$emit("nft-selected", item[0]);
      } else {
        alert("사용자를 선택해주세요.");
      }
    },
    getInitExternalParams() {
      return {
        searchKeyword: "",
        keywordName: "",
        keywordSymbol: "",
        keywordContractAddr: "",
        keywordRfc: ""
      };
    }
  }
};
</script>
