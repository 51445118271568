import ApiService from "@/services/api.service";
import logger from "@/logger";

// action types
// 사용자 공통
export const GET_MEMBERS = "getMembers";
export const GET_MEMBER = "getMember";
export const ADD_MEMBER = "addMember";
export const UPDATE_MEMBER = "updateMember";
export const UPDATE_MEMBER_ROLE = "updateMemberRole";
export const DELETE_MEMBER = "deleteMember";

// 아티스트
export const GET_ARTISTS = "getArtists";
export const GET_ARTIST = "getArtist";
export const ADD_ARTIST = "addArtist";
export const UPDATE_ARTIST = "updateArtist";
export const UPLOAD_ARTIST_PROFILE_IMAGE = "uploadArtistProfileImage";
export const DELETE_ARTIST = "deleteArtist";
export const DELETE_ARTIST_COMPANY = "deleteArtistCompany";

// mutation types
// 사용자 공통
export const SET_MEMBERS = "setMembers";
export const SET_MEMBER = "setMember";

// 아티스트
export const SET_ARTIST_LIST = "setArtistList";
export const SET_ARTISTS = "setArtists";
export const SET_ARTIST = "setArtist";

const state = {
  errors: null,
  memberTableItems: {
    content: [],
    first: true,
    last: false,
    number: 0,
    numberOfElements: 0,
    size: 0,
    sort: {
      orders: []
    },
    total: 0,
    totalElements: 0,
    totalPages: 1
  },
  memberTableFields: [
    { key: "index", label: "번호", class: "text-center", thStyle: "width:5%" },
    { key: "createdAt", label: "가입일", class: "text-center", thStyle: "width:100px" },
    { key: "idx", label: "회원번호", class: "text-center", thStyle: "width:100px" },
    { key: "nickname", label: "사용자 이름", class: "text-center" },
    { key: "roles", label: "역할" },
    { key: "mobile", label: "연락처", class: "text-center" },
    { key: "email", label: "이메일" },
    { key: "snsType", label: "가입채널" },
    { key: "identityVerify", label: "본인인증", class: "text-center" },
    { key: "actions", label: "Actions", class: "text-center", thStyle: "width:120px" }
  ],
  memberSelectTableFields: [
    { key: "idx", label: "회원번호", thStyle: "width:76px" },
    { key: "name", label: "이름" },
    { key: "nickname", label: "사용자 이름" },
    { key: "klaytnAddr", label: "카이아 지갑주소" },
    { key: "ethereumAddr", label: "이더리움 지갑주소" }
  ],
  memberInfo: {},
  // 작가(그룹) 정보
  artistSelectTableItems: {},
  artistSelectTableFields: [
    { key: "index", label: "번호", class: "text-center", thStyle: "width:10%" },
    { key: "idx", label: "작가 번호" },
    { key: "companyName", label: "소속 법인" },
    { key: "artistName", label: "작가명" },
    { key: "artistNameEn", label: "작가명(영문)" },
    { key: "nickname", label: "사용자이름(닉네임)" }
  ],
  artistTableItems: {
    content: [],
    first: true,
    last: false,
    number: 0,
    numberOfElements: 0,
    size: 0,
    sort: {
      orders: []
    },
    total: 0,
    totalElements: 0,
    totalPages: 1
  },
  artistTableFields: [
    { key: "index", label: "번호", class: "text-center", thStyle: "width:5%" },
    { key: "idx", label: "작가번호", class: "text-center", thStyle: "width:76px" },
    { key: "idxMember", label: "회원번호", class: "text-center", thStyle: "width:76px" },
    { key: "artistGroup", label: "그룹여부", class: "text-center", thStyle: "width:76px" },
    { key: "companyName", label: "소속 법인" },
    { key: "name", label: "이름" },
    { key: "artistName", label: "작가명" },
    { key: "nickname", label: "사용자이름(닉네임)" },
    { key: "display", label: "ARTISTS 노출", class: "text-center" },
    { key: "trending", label: "Trending Artist", class: "text-center" },
    { key: "actions", label: "Actions", class: "text-center", thStyle: "width:80px" }
  ],
  corpArtistTableFields: [
    { key: "index", label: "번호", class: "text-center", thStyle: "width:5%" },
    { key: "companyName", label: "소속 법인" },
    { key: "artistName", label: "작가명" },
    { key: "artistNameEn", label: "작가명(영문)" },
    { key: "nickname", label: "사용자이름(닉네임)" },
    { key: "mobile", label: "연락처" },
    { key: "email", label: "이메일" },
    { key: "actions", label: "Actions", class: "text-center", thStyle: "width:80px" }
  ],
  artistInfo: {
    artistGroup: "false",
    childArtists: []
  }
};

const getters = {};

const actions = {
  [GET_MEMBERS](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("/management/v1/members", payload)
        .then(({ data }) => {
          context.commit(SET_MEMBERS, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [GET_MEMBER](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("/management/v1/members/" + payload)
        .then(({ data }) => {
          context.commit(SET_MEMBER, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [ADD_MEMBER](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("/management/v1/members", payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [UPDATE_MEMBER](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.put("/management/v1/members/" + payload.idx, payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [UPDATE_MEMBER_ROLE](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.put("/management/v1/members/role/" + payload.idx, payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [DELETE_MEMBER](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.delete("/management/v1/members", payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(response => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [GET_ARTISTS](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("/corp-management/v1/artists", payload)
        .then(({ data }) => {
          context.commit(SET_ARTISTS, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [GET_ARTIST](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("/corp-management/v1/artists/" + payload)
        .then(({ data }) => {
          context.commit(SET_ARTIST, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [ADD_ARTIST](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("/corp-management/v1/artists", payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [UPDATE_ARTIST](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.put("/corp-management/v1/artists/" + payload.idx, payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [UPLOAD_ARTIST_PROFILE_IMAGE](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post_upload_file("/corp-management/v1/artists/profile-image/upload", payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [DELETE_ARTIST](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.delete("/corp-management/v1/artists", payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(response => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [DELETE_ARTIST_COMPANY](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.delete("/management/v1/artists/company", payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(response => {
          logger.error(response);
          reject(response);
        });
    });
  }
};

const mutations = {
  [SET_MEMBERS](state, memberList) {
    state.memberTableItems = memberList;
  },
  [SET_MEMBER](state, memberInfo) {
    state.memberInfo = memberInfo;
  },
  [SET_ARTIST_LIST](state, artistList) {
    state.artistSelectTableItems = artistList;
  },
  [SET_ARTISTS](state, artistList) {
    state.artistTableItems = artistList;
  },
  [SET_ARTIST](state, artistInfo) {
    state.artistInfo = artistInfo;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
