<script>
export default {
  name: "nav",
  computed: {
    sidebarItemsAdmin() {
      return [
        {
          _name: "CSidebarNav",
          _children: [
            {
              _name: "CSidebarNavTitle",
              _children: ["관리자 페이지"]
            },
            {
              _name: "CSidebarNavItem",
              name: "작품 관리",
              to: "/management/product-list",
              icon: "cil-image-plus"
            },
            {
              _name: "CSidebarNavItem",
              name: "법인 관리",
              to: "/management/company-list",
              icon: "cil-institution"
            },
            {
              _name: "CSidebarNavItem",
              name: "작가 관리",
              to: "/management/artist-list",
              icon: "cil-puzzle"
            },
            {
              _name: "CSidebarNavItem",
              name: "게시글 관리",
              to: "/management/article-list",
              icon: "cil-pencil"
            },
            {
              _name: "CSidebarNavItem",
              name: "주문 현황",
              to: "/management/order-list",
              icon: "cil-star"
            },
            {
              _name: "CSidebarNavTitle",
              _children: ["아트풀 관리"]
            },
            {
              _name: "CSidebarNavItem",
              name: "아트풀",
              to: "/management/artpool-list",
              icon: "cil-contact"
            },
            {
              _name: "CSidebarNavItem",
              name: "NFT",
              to: "/management/nft-list",
              icon: "cil-gift"
            },
            {
              _name: "CSidebarNavItem",
              name: "컬렉션",
              to: "/management/collection-list",
              icon: "cil-inbox"
            },
            {
              _name: "CSidebarNavTitle",
              _children: ["법인 관리자 페이지"]
            },
            {
              _name: "CSidebarNavItem",
              name: "작품 관리",
              to: "/corp-management/artworks",
              icon: "cil-image-plus"
            },
            {
              _name: "CSidebarNavItem",
              name: "작가 관리",
              to: "/corp-management/artist-list",
              icon: "cil-puzzle"
            },
            {
              _name: "CSidebarNavTitle",
              _children: ["시스템 관리"]
            },
            {
              _name: "CSidebarNavItem",
              name: "사용자 관리",
              to: "/management/member-list",
              icon: "cil-people"
            },
            {
              _name: "CSidebarNavItem",
              name: "공통코드",
              to: "/management/code-list",
              icon: "cil-code"
            }
          ]
        }
      ];
    },
    sidebarItemsCorpAdmin() {
      return [
        {
          _name: "CSidebarNav",
          _children: [
            {
              _name: "CSidebarNavTitle",
              _children: ["법인 관리자 페이지"]
            },
            {
              _name: "CSidebarNavItem",
              name: "작품 관리",
              to: "/corp-management/artworks",
              icon: "cil-image-plus"
            },
            {
              _name: "CSidebarNavItem",
              name: "작가 관리",
              to: "/corp-management/artist-list",
              icon: "cil-puzzle"
            }
          ]
        }
      ]
    }
  }
};
</script>
