var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"collections",attrs:{"id":"MyPageDetail"}},[_c('section',{staticClass:"banner"},[_c('div',{staticClass:"banner__image",style:(_vm.memberSimpleInfo.bannerImageUrl
          ? { backgroundImage: `url(${_vm.memberSimpleInfo.bannerImageUrl})` }
          : {
              border: '10px solid white',
              outline: '1px solid #343434',
              padding: '10px',
              background:
                'repeating-linear-gradient(-45deg, #d9d9d9, #d9d9d9 1px, #fff 1px, #fff 10px)',
              backgroundClip: 'padding-box',
            })}),_c('MyPageDetailInfo',{attrs:{"isArtist":_vm.isArtist,"artistInfo":_vm.artistInfo,"showCollection":true}})],1),_c('section',{staticClass:"artpool-collections",class:{ is_mobile: _vm.isMobile, is_artist: _vm.isArtist }},[_c('b-tabs',{staticClass:"custom-tabs",model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},[(_vm.isArtist)?_c('b-tab',{attrs:{"lazy":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Created")]},proxy:true}],null,false,897987229)},[_c('NFTs',{ref:"nfts",attrs:{"idxArtist":_vm.memberSimpleInfo.idxArtist}})],1):_vm._e(),_c('b-tab',{attrs:{"lazy":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("mypage.collected"))+" ")]},proxy:true}])},[_c('CollectionsCollection',{attrs:{"idxMember":_vm.idxMember}})],1),_c('b-tab',{attrs:{"lazy":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("mypage.favorited"))+" ")]},proxy:true}])},[_c('FavoritedCollections',{attrs:{"idxMember":Number(_vm.idxMember)}})],1)],1)],1),_c('MyPageContractSelectModal',{ref:"myPageContractSelectModal"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }