import Vue from "vue";

let logger;
export const logLvl = {
  NONE: 99,
  DEBUG: 5,
  ERR: 4,
  WARN: 3,
  INFO: 2,
  LOG: 1
};

// let LOG_LEVEL =
//   process.env.NODE_ENV === "local" || process.env.NODE_ENV === "dev"
//     ? logLvl.LOG
//     : logLvl.WARN;

let LOG_LEVEL = logLvl.LOG;

const test = () => {
  // log.group('logger test', LOG_LEVEL)
  logger.groupCollapsed("logger test", LOG_LEVEL);
  logger.time("test 'TIME') END::");

  logger.trace("...'TRACE'");
  logger.log("'LOG'");
  logger.info("'INFO'", window, isUseConsole(logLvl.INFO));
  logger.warn("'WARN'", isUseConsole(logLvl.WARN));
  logger.error("'ERROR'", isUseConsole(logLvl.ERR));

  logger.timeEnd("test 'TIME' END time: ");
  logger.groupEnd("logger test");
};

const isUseConsole = thisLvl => {
  return thisLvl >= LOG_LEVEL && typeof console !== "undefined";
};

export const initLog = () => {
  Vue.prototype.$log = logger;
  //test();
};

export default logger = {
  /* eslint-disable no-console */
  clear: isUseConsole(logLvl.LOG) ? console.clear : function() {},

  error: isUseConsole(logLvl.ERR) ? console.error : function() {},
  warn: isUseConsole(logLvl.LOG) ? console.warn : function() {},
  info: isUseConsole(logLvl.INFO) ? console.info : function() {},
  log: isUseConsole(logLvl.LOG) ? console.log : function() {},
  trace: isUseConsole(logLvl.LOG) ? console.trace : function() {},
  // assert,dirxml, dir, table, debug 제외.

  count: isUseConsole(logLvl.LOG) ? console.count : function() {},
  countReset: isUseConsole(logLvl.LOG) ? console.countReset : function() {},
  group: isUseConsole(logLvl.LOG) ? console.group : function() {},
  groupCollapsed: isUseConsole(logLvl.LOG)
    ? console.groupCollapsed
    : function() {},
  groupEnd: isUseConsole(logLvl.LOG) ? console.groupEnd : function() {},
  time: isUseConsole(logLvl.LOG) ? console.time : function() {},
  timeLog: isUseConsole(logLvl.LOG) ? console.timeLog : function() {},
  timeEnd: isUseConsole(logLvl.LOG) ? console.timeEnd : function() {}
  /* eslint-enable no-console */
};
