<template>
  <b-modal
    id="artpoolUserGuideModal"
    modal-class="artpool-user-guide-modal"
    ref="artpoolUserGuideModal"
    hide-header
    hide-footer
    centered
    body-class="text-center text-black my-4"
    @hide="modalEvent(false)"
    @show="modalEvent(true)"
  >
    <div class="user_guide_modal">
      <template v-if="activeLanguage === 'ko'">
        <img src="/img/threespace/artpool/art_pool_user_guide1_ko.jpg" />
      </template>
      <template v-else>
        <img src="/img/threespace/artpool/art_pool_user_guide1_en.jpg" />
      </template>
      <div class="btn_box">
        <a @click="onClickIsArtPool">Artpool?</a>
        <a @click="onClickOnlineGallery">Exhibition</a>
      </div>
      <div class="close_btn" @click="hide()">close btn</div>
      <div class="not_seen_week_btn" @click="onClickNotSeenBtn()">일주일동안 보지않기</div>
    </div>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: "ArtPoolUserGuideModal",
  props: {
    activeLanguage: {
      type: String,
      default: ""
    },
    artpoolInfo: {
      type: Object,
      default: () => {}
    }
  },
  data: function () {
    return {
    }
  },
  methods: {
    show() {
      this.$refs["artpoolUserGuideModal"].show();
    },
    hide() {
      this.$refs["artpoolUserGuideModal"].hide();
    },
    onClickIsArtPool() {
      this.$router.push("/features-detail/feature14");
    },
    onClickNotSeenBtn() {
      this.$emit("onClickPopupNoneSeenWeekBtn", this.artpoolInfo.idx);
    },
    onClickOnlineGallery() {
      window.open("https://spatial.io/rooms/6236afd9b4b8ab0001afb57f?share=8044373989335370460", "_blank");
    },
    modalEvent(isVisible) {
      if (isVisible) {
        // open event hook
      } else {
        // close event hook ***>> modal data init here
        this.unstakingAmount = "";
      }
    }
  },
  filters: {
    decimal: value => {
      if (!value) return 0;
      return (Math.floor((value * 100) / 10 ** 18) / 100)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    },
  }
}
</script>
