import ApiService from "../services/api.service";

export const GET_TICKERS = "getTickers";
export const GET_ETHEREUM_TICKER = "getEthereumTicker";
export const GET_KLAYTN_TICKER = "getKlaytnTicker";

export const SET_TICKERS = "setTickers";


const state = {
  btc: {
    currency: "BTC",
    price: null,
    datetime: null
  },
  eth: {
    currency: "ETH",
    price: null,
    datetime: null
  },
  klay: {
    currency: "KLAY",
    price: null,
    datetime: null
  },
  matic: {
    currency: "MATIC",
    price: null,
    datetime: null
  }
}

const getters = {};

const actions = {
  [GET_TICKERS](context) {
    return new Promise((resolve, reject) => {
      ApiService.get("/threespace/v1/coin/tickers")
        .then(({ data }) => {
          context.commit(SET_TICKERS, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
};

const mutations = {
  [SET_TICKERS](state, payload) {
    state.btc = {...payload.btc};
    state.eth = {...payload.eth};
    state.klay = {...payload.klay};
    state.matic = {...payload.matic};
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};

