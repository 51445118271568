<template>
  <div :class="{ 'c-dark-theme': $store.state.darkMode }" class="c-app">
    <CContainer class="d-flex align-items-center min-vh-100">
      <CRow class="w-100 justify-content-center">
        <CCol md="6">
          <div class="w-100">
            <div class="clearfix">
              <h1 class="float-left display-3 mr-4">404</h1>
              <h4 class="pt-3">
                존재하지 않는 주소를 입력하셨거나, 요청하신 페이지의 주소가
                변경, 삭제되어 찾을 수 없습니다.
              </h4>
              <p class="text-muted">
                The page you are looking for was not found.
              </p>
            </div>
          </div>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
export default {
  name: "Page404"
};
</script>
