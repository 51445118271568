import "core-js/stable";
import Vue from "vue";
import BootstrapVue from "bootstrap-vue";
import CoreuiVuePro from "../node_modules/@coreui/vue-pro/src/index.js";
import App from "./App";
import router from "./router";
import store from "./store";
import i18n from "./lang";
import Meta from "vue-meta";

import { iconsSet as icons } from "./assets/icons/icons.js";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(faExclamationCircle);
Vue.component("font-awesome-icon", FontAwesomeIcon);

import ApiService from "./services/api.service";
import logger, { initLog } from "./logger";

import VModal from "vue-js-modal";
import Vuetify from "vuetify";
import "@mdi/font/css/materialdesignicons.css";
import "./assets/icons/fontawesome";
import VueCookies from "vue-cookies";
import vueMoment from "vue-moment";
import moment from "moment-timezone";
import ScrollLoader from "vue-scroll-loader"
import VueClipboard from 'vue-clipboard2'
import VCalendar from 'v-calendar';

moment.locale(localStorage.getItem("language") || "en");

ApiService.init();
initLog();

Vue.use(CoreuiVuePro);
Vue.use(BootstrapVue);
Vue.use(VModal, { dynamic: true, dialog: true });
Vue.use(Vuetify);
Vue.use(VueCookies);
Vue.$cookies.config("30d");
Vue.use(vueMoment, { moment });
Vue.use(ScrollLoader);
Vue.use(Meta, {
  keyName: "metaInfo",
  attribute: "data-meta",
  ssrAttribute: "data-meta-server-rendered",
  tagIDKeyName: "vmid",
  refreshOnceOnNavigation: true
});
Vue.use(VueClipboard)
Vue.use(VCalendar, {
  componentPrefix: 'vc',
});

router.beforeEach((to, from, next) => {
  // Ensure we checked auth before each page load.
  if (to.name === "Login" && store.getters["auth/isAuthenticated"]) {
    router.push({ name: "Drops", params: { linkType: "headerClick" } });
  } else {
    let darkModeArtpool = false;
    if (to.name === "NftPoolVote") {
      darkModeArtpool = to.params.idxArtpool === "1" || to.params.idxArtpool === "2" || to.params.idxArtpool === 1 || to.params.idxArtpool === 2
    }

    if (to.matched[1].name === "MyPage" || darkModeArtpool || to.name === "Collections") {
      // white text header (dark mode)
      // store.commit("setDarkHeader", true);

      // all white text header
      if (to.matched[1].name === "MyPage" || to.name === "Collections") {
        store.commit("set", ["allWhiteTextHeader", true]);
      } else {
        store.commit("set", ["allWhiteTextHeader", false]);
      }
    } else {
      // default
      store.commit("setDarkHeader", false);
      store.commit("set", ["allWhiteTextHeader", false]);
    }
    Promise.all([store.dispatch("auth/verifyToken")]).then(next);
  }

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

new Vue({
  router,
  store,
  i18n,
  icons,
  vuetify: new Vuetify(),
  render: h => h(App)
}).$mount("#app");
