import ApiService from "@/services/api.service";
import logger from "@/logger";

// action types
export const GET_ARTICLES = "getArticles";
export const GET_ARTICLE = "getArticle";
export const ADD_ARTICLE = "addArticle";
export const UPDATE_ARTICLE = "updateArticle";
export const DELETE_ARTICLE = "deleteArticle";
export const UPDATE_IMAGE_UPLOAD = "updateImageUpload";
export const UPDATE_CONTENT_IMAGE_UPLOAD = "updateContentImageUpload"; //TODO 미사용

// mutation types
export const SET_ARTICLES = "setArticles";
export const SET_ARTICLE = "setArticle";

const state = {
  errors: null,
  articleTableItems: {
    content: [],
    first: true,
    last: false,
    number: 0,
    numberOfElements: 0,
    size: 0,
    sort: {
      orders: []
    },
    total: 0,
    totalElements: 0,
    totalPages: 1
  },
  articleTableFields: [
    { key: "index", label: "번호", class: "text-center", thStyle: "width:5%" },
    { key: "createdAt", label: "등록일", class: "text-center", thStyle: "width:90px" },
    { key: "updatedAt", label: "최근 수정일시", class: "text-center", thStyle: "width:150px" },
    { key: "idx", label: "게시글 번호", class: "text-center", thStyle: "width:76px" },
    { key: "type", label: "분류", class: "text-center", thStyle: "width:150px" },
    { key: "title", label: "제목" },
    { key: "actions", label: "Actions", class: "text-center", thStyle: "width:100px" }
  ],
  articleInfo: {
    top: false,
    title: "",
    titleEn: "",
    curator: "",
    location: "",
    content: "",
    contentEn: ""
  }
};

const getters = {};

const actions = {
  [GET_ARTICLES](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("/management/v1/articles", payload)
        .then(({data}) => {
          context.commit(SET_ARTICLES, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    })
  },
  [GET_ARTICLE](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("/management/v1/article/" + payload)
        .then(({data}) => {
          console.log(data.data.content)
          context.commit(SET_ARTICLE, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    })
  },
  [ADD_ARTICLE](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("/management/v1/articles", payload)
        .then(({data}) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    })
  },
  [UPDATE_ARTICLE](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.put("/management/v1/article/" + payload.idx, payload)
        .then(({data}) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    })
  },
  [DELETE_ARTICLE](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.delete("/management/v1/article/" + payload)
        .then(({data}) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    })
  },
  [UPDATE_IMAGE_UPLOAD](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post_upload_file("/management/v1/articles/image/upload", payload)
        .then(({data}) => {
          logger.log("[UPDATE_IMAGE_UPLOAD] ", data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    })
  },
  [UPDATE_CONTENT_IMAGE_UPLOAD](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post_upload_file("/management/v1/articles/content/image/upload", payload)
        .then(({data}) => {
          logger.log("[UPDATE_CONTENT_IMAGE_UPLOAD] ", data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    })
  }
}

const mutations = {
  [SET_ARTICLES](state, articleList) {
    state.articleTableItems = articleList;
  },
  [SET_ARTICLE](state, articleInfo) {
    state.articleInfo = articleInfo;
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
