<template>
  <CCard>
    <CCardBody>
      <CRow>
        <CCol class="mb-3"><h5>컬렉션</h5></CCol>
      </CRow>
      <CRow>
        <CCol col="8">
          <!-- NFT 정보 ====================== -->
          <b-table-simple class="border-bottom mb-5">
            <b-tr>
              <b-th
                colspan="2" 
                class="text-dark font-weight-bold"
                variant="secondary"
              >NFT 정보</b-th>
            </b-tr>
            <b-tr>
              <b-td class="font-weight-bold">NFT 번호</b-td>
              <b-td>{{ collectionInfo.nft.idx }}</b-td>
            </b-tr>
            <b-tr>
              <b-td class="font-weight-bold">NFT 이름 (심볼)</b-td>
              <b-td>{{ collectionInfo.nft.name }} ({{ collectionInfo.nft.symbol }})</b-td>
            </b-tr>
            <b-tr>
              <b-td class="font-weight-bold">NFT 주소</b-td>
              <b-td>{{ collectionInfo.nft.contractAddr }}</b-td>
            </b-tr>
            <b-tr>
              <b-td class="font-weight-bold">NFT 네트워크 / RFC</b-td>
              <b-td>{{ collectionInfo.nft.network }} / {{ collectionInfo.nft.rfc }}</b-td>
            </b-tr>
          </b-table-simple>

          <!-- 컬렉션 정보 ====================== -->
          <b-table-simple class="border-bottom mb-5">
            <b-tr>
              <b-th
                colspan="4" 
                class="text-dark font-weight-bold"
                variant="secondary"
              >컬렉션 정보</b-th>
            </b-tr>
            <b-tr>
              <b-td class="font-weight-bold">토큰 아이디</b-td>
              <b-td colspan="3">
                {{ collectionInfo.tokenId }}
              </b-td>
            </b-tr>
            <b-tr>
              <b-td class="font-weight-bold">토큰 URI</b-td>
              <b-td colspan="3">
                {{ collectionInfo.tokenUri }}
              </b-td>
            </b-tr>
            <b-tr>
              <b-td class="font-weight-bold">컬렉션 명 (영문명)</b-td>
              <b-td colspan="3">
                {{ collectionInfo.title }} ({{ collectionInfo.titleEn }})
              </b-td>
            </b-tr>
            <b-tr>
              <b-td class="font-weight-bold">아티스트 명 (영문명)</b-td>
              <b-td colspan="3">
                <b-form inline>
                  <b-form-input
                    v-model="updateParams.artist"
                    placeholder="아티스트 명"
                    class="mr-2"
                  />
                  <b-form-input
                    v-model="updateParams.artistEn"
                    placeholder="아티스트 명 (영문)"
                  />
                </b-form>
              </b-td>
            </b-tr>
            <b-tr>
              <b-td class="font-weight-bold">s3에 업로드 파일명</b-td>
              <b-td colspan="3">
                {{ collectionInfo.fileName }}
                <v-icon v-if="collectionInfo.video">mdi-video-outline</v-icon>
              </b-td>
            </b-tr>
            <b-tr>
              <b-td class="font-weight-bold">스테이크 여부</b-td>
              <b-td>
                <b-form-radio-group v-model="collectionInfo.staked" disabled>
                  <b-form-radio :value="true">YES</b-form-radio>
                  <b-form-radio :value="false">NO</b-form-radio>
                </b-form-radio-group>
              </b-td>
              <b-td class="font-weight-bold">사용자 노출 여부</b-td>
              <b-td>
                <b-form-radio-group v-model="collectionInfo.visible" disabled>
                  <b-form-radio :value="true">YES</b-form-radio>
                  <b-form-radio :value="false">NO</b-form-radio>
                </b-form-radio-group>
              </b-td>
            </b-tr>
            <b-tr>
              <b-td class="font-weight-bold">관리자가 외부에서 끌어왔는지 여부</b-td>
              <b-td colspan="3">
                <template v-if="collectionInfo.status === 'GET_ADMIN'">
                  관리자가 '외부 NFT 가져오기' 로 가져옴
                </template>
                <template v-else>
                  -
                </template>
              </b-td>
            </b-tr>
          </b-table-simple>

          <!-- 소유주 정보 ====================== -->
          <b-table-simple class="border-bottom mb-5">
            <b-tr>
              <b-th
                colspan="4" 
                class="text-dark font-weight-bold"
                variant="secondary"
              >소유주 정보</b-th>
            </b-tr>
            <b-tr>
              <b-td class="font-weight-bold">소유주 명</b-td>
              <b-td>
                {{ collectionInfo.owner }}
                &nbsp;
                <a
                  @click="onClickMember(collectionInfo.idxMember)"
                  id="show-owner-info"
                  class="text-primary cursor-pointer"
                  v-if="
                    collectionInfo.idxMember != undefined && 
                    collectionInfo.idxMember !== 0
                  "
                  title="소유주 정보 보기"
                ><v-icon small>mdi-open-in-new</v-icon></a>
                <b-tooltip target="show-owner-info">소유주 정보 보기</b-tooltip>
              </b-td>
            </b-tr>
            <b-tr>
              <b-td class="font-weight-bold">소유주 지갑 주소</b-td>
              <b-td>
                {{ collectionInfo.walletAddr }}
              </b-td>
            </b-tr>
          </b-table-simple>

          <!-- 기타 정보 ====================== -->
          <b-table-simple class="border-bottom mb-5">
            <b-tr>
              <b-th
                colspan="4" 
                class="text-dark font-weight-bold"
                variant="secondary"
              >기타 정보</b-th>
            </b-tr>
            <b-tr>
              <b-td class="font-weight-bold">(작품 번호) 작품명</b-td>
              <b-td colspan="3">
                <template v-if="collectionInfo.idxProduct != undefined && collectionInfo.product != undefined">
                  <b>({{ collectionInfo.idxProduct }})</b> &nbsp;{{ collectionInfo.product.productName || collectionInfo.product.productNameEn }}
                  &nbsp;
                  <a
                    @click="onClickProduct(collectionInfo.idxProduct)"
                    id="show-product-info"
                    class="text-primary cursor-pointer"
                  ><v-icon small>mdi-open-in-new</v-icon></a>
                  <b-tooltip target="show-product-info">작품 정보 보기</b-tooltip>
                </template>
                <template v-else>
                  외부 작품입니다.
                </template>
              </b-td>
            </b-tr>
            <b-tr>
              <b-td class="font-weight-bold">등록일</b-td>
              <b-td>
                {{ collectionInfo.createdAt | moment("YYYY.MM.DD HH:mm") }}
              </b-td>
              <b-td class="font-weight-bold">수정일</b-td>
              <b-td>
                {{ collectionInfo.updatedAt | moment("YYYY.MM.DD HH:mm") }}
              </b-td>
            </b-tr>
            <b-tr>
              <b-td class="font-weight-bold">사용여부</b-td>
              <b-td>
                <b-form-radio-group v-model="collectionInfo.enabled" disabled>
                  <b-form-radio :value="true">사용</b-form-radio>
                  <b-form-radio :value="false">미사용</b-form-radio>
                </b-form-radio-group>
              </b-td>
              <b-td class="font-weight-bold">좋아요 개수</b-td>
              <b-td>
                {{ collectionInfo.favoritedCnt }}
              </b-td>
            </b-tr>
          </b-table-simple>
        </CCol>
        <CCol col="4">
          <div class="media_wrap">
            <template v-if="!collectionInfo.video">
              <b-img :src="collectionInfo.imageUrl"></b-img>
            </template>
            <template v-else>
              <video
                :poster="replaceDisplayPosterUrl(collectionInfo)"
                :src="replaceDisplayCardUrl(collectionInfo)"
                type="video/webm"
                muted
                loop
                autoplay
                playsinline
              ></video>
            </template>
          </div>
        </CCol>
      </CRow>
      <CRow>
        <CCol col="12" class="text-center my-4">
          <CButton color="light" @click="onClickConfirm()" class="mr-2">
            <CIcon name="cil-x-circle" class="mr-2" />목록
          </CButton>
          <CButton color="info" @click="onClickModify()" class="text-white">
            <CIcon name="cil-save" class="mr-2" />수정
          </CButton>
        </CCol>
      </CRow>
      <CForm>
      </CForm>
    </CCardBody>
  </CCard>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "CollectionDetail",
  components: {},
  props: {
    idxCollection: {
      type: String,
      default: "0"
    }
  },
  data: function() {
    return {
      updateParams: {
        idx: 0,
        artist: "",
        artistEn: ""
      }
    };
  },
  computed: {
    ...mapState("collectionManager", ["collectionInfo"])
  },
  mounted() {
    this.requestCollectionItem();
  },
  methods: {
    ...mapActions("collectionManager", ["getCollection", "updateCollection"]),
    requestCollectionItem() {
      this.$log.info("idxCollection=", this.idxCollection);
      if (this.idxCollection && this.idxCollection !== "0") {
        this.getCollection(this.idxCollection).then(data => {
          this.$log.info(data);
          if (data.artist != undefined) {
            this.updateParams.artist = data.artist
          }
          if (data.artistEn != undefined) {
            this.updateParams.artistEn = data.artistEn
          }
        });
      }
    },
    onClickMember(idxMember) {
      let routeData = this.$router.resolve({name: 'MemberSave', params: {idxMember: idxMember}});
      window.open(routeData.href, '_blank');
    },
    onClickProduct(idxProduct) {
      let routeData = this.$router.resolve({name: 'ProductSave', params: {idxProduct: idxProduct}});
      window.open(routeData.href, '_blank');
    },
    onClickConfirm() {
      this.$router.push("/management/collection-list");
    },
    onClickModify() {
      this.updateParams.idx = this.idxCollection;
      this.updateCollection(this.updateParams).then(() => {
        alert("컬렉션 정보가 수정되었습니다.")
        this.requestCollectionItem();
      }).catch(() => {
        alert("컬렉션 정보 수정 실패")
      })
    },
    replaceDisplayPosterUrl(item) {
      if (item.idxProduct !== undefined && item.idxProduct !== null && item.idxProduct !== "") {
        if (
          item.product.displayPoster !== undefined &&
          item.product.displayPoster !== null &&
          item.product.displayPoster !== "" &&
          item.product.displayPoster === "THREESPACE"
        ) {
          return item.product.displayPosterUrlThreespace; // 3space S3 URL
        } else {
          if (item.product.displayPosterUrl !== undefined && item.product.displayPosterUrl.startsWith("https://ipfs.io/")) {
            return item.product.displayPosterUrl.replace("https://ipfs.io/", "https://ipfs.3space.art/");
          }
          return item.product.displayPosterUrl;
        }
      } else {
        return item.imageUrl
      }
    },
    replaceDisplayCardUrl(item) {
      if (item.idxProduct !== undefined && item.idxProduct !== null && item.idxProduct !== "") {
        if (
          item.product.displayCardFile !== undefined &&
          item.product.displayCardFile !== null &&
          item.product.displayCardFile !== "" &&
          item.product.displayCardFile === "THREESPACE"
        ) {
          return item.product.displayCardUrlThreespace; // 3space S3 URL
        } else {
          if (item.product.displayCardUrl !== undefined && item.product.displayCardUrl.startsWith("https://ipfs.io/")) {
            return item.product.displayCardUrl.replace("https://ipfs.io/", "https://ipfs.3space.art/");
          }
          return item.product.displayCardUrl;
        }
      } else {
        return item.imageUrl
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.media_wrap {
  width: 100%;
  height: auto;
}
.media_wrap img,
.media_wrap video {
  width: 100%;
  height: auto;
  border: 1px solid #ddd;
}
</style>
