<template>
  <div
    class="card_hover_box"
    v-if="favoritedCnt !== undefined && pageViewCnt !== undefined"
  >
    <div class="wrap">
      <div v-if="favoritedCnt !== undefined">
        <img src="/img/threespace/icon/heart_white_icon.png" />
        {{ favoritedCnt ? favoritedCnt : 0 }}
      </div>
      <div v-if="pageViewCnt !== undefined && !isExternalCollection">
        <img src="/img/threespace/icon/view_white_icon.png" />
        {{ pageViewCnt ? pageViewCnt : 0 }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardHoverBox",
  props: {
    favoritedCnt: {
      type: Number,
      default: 0
    },
    pageViewCnt: {
      type: Number,
      default: 0
    },
    isExternalCollection: {
      type: Boolean,
      default: false,
    }
  },
  data: function () {return {}},
  computed: {},
  methods: {},
}
</script>
