<template>
  <b-modal
    id="voteApproveModal"
    ref="voteApproveModal"
    hide-header
    hide-footer
    centered
    body-class="text-center text-black my-4"
  >
    <b-img class="mb-3" src="/img/threespace/logo/logo_100x100.png" width="55"></b-img>
    <h5 class="mt-3">{{ $t("stake.vote.requiredApprove") }}</h5>
    <div class="w-100 text-center mt-5">
      <b-button
        squared
        variant="secondary"
        class="text-primary px-4 py-2 mr-3"
        @click="hide"
      >
        {{ $t("button.close") }}
      </b-button>
      <b-button
        squared
        variant="primary"
        class="text-white px-4 py-2 mr-3"
        @click="onClickVoteApproveBtn"
      >
        {{ $t("button.approve") }}
      </b-button>
    </div>
  </b-modal>
</template>

<script>

export default {
  name: "NftVoteApproveModal",
  data: function () {
    return {}
  },
  methods: {
    show() {
      this.$refs["voteApproveModal"].show();
    },
    hide() {
      this.$refs["voteApproveModal"].hide();
    },
    onClickVoteApproveBtn() {
      this.$emit("onClickVoteApproveBtn");
    }
  },
}
</script>
