<template>
  <div>
    <Event01 />
  </div>
</template>

<script>
import Event01 from "./event/Event01";

export default {
  name: "Event",
  components: {
    Event01
  }
};
</script>
