<template>
  <router-view></router-view>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {
    this.message = this.$route.query.message;
    this.mobile = this.$route.query.mobile;
    this.name = this.$route.query.name;
    this.identityVerify = false;

    if (this.message === undefined) this.$router.push("/error/500");

    if (this.message === "AUTH_SUCCESS") {
      this.identityVerify = true;
    }

    const authResult = {
      name: this.name,
      mobile: this.mobile,
      identityVerify: this.identityVerify
    };
    window.opener.app.$root.$store.dispatch("profile/getAuthResult", authResult);
    window.close();
  }
};
</script>
