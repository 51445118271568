<template>
  <div id="PicassoDetail">
    <!-- Detail image box -->
    <div class="image_box">
      <img
        :src="galleryCollectionInfo.imageUrl"
        @click="showImgPopup = !showImgPopup"
        @error="replaceByDefault"
      />
    </div>

    <!-- Detail content wrap -->
    <div class="content_wrap">
      <div class="left">
        <h2>
          <template v-if="activeLanguage !== undefined && activeLanguage === 'ko'">
            {{ galleryCollectionInfo.title || galleryCollectionInfo.titleEn }}
          </template>
          <template v-else>
            {{ galleryCollectionInfo.titleEn || galleryCollectionInfo.title }}
          </template>
        </h2>
        <p class="info">
          <template v-if="activeLanguage !== undefined && activeLanguage === 'ko'">
            {{ galleryCollectionInfo.artist || galleryCollectionInfo.artistEn }}
          </template>
          <template v-else>
            {{ galleryCollectionInfo.artistEn || galleryCollectionInfo.artist }}
          </template>
        </p>
        <p class="info">{{ galleryCollectionInfo.company }}</p>
        <p class="info ranking" v-if="galleryCollectionInfo.ranking != null">
          <template v-if="galleryCollectionInfo.ranking === 1">
            <span>{{ galleryCollectionInfo.part.replace(" ","") }} 대상</span>여성가족부장관상
          </template>
          <template v-else-if="galleryCollectionInfo.ranking === 2">
            <span>{{ galleryCollectionInfo.part.replace(" ","") }} 최우수상</span>한국미술협회장상
          </template>
          <template v-else-if="galleryCollectionInfo.ranking === 3">
            <span>{{ galleryCollectionInfo.part.replace(" ","") }} 우수상</span>신한카드 대표이사상
          </template>
          <template v-else-if="galleryCollectionInfo.ranking === 4">
            <span>{{ galleryCollectionInfo.part.replace(" ","") }} 특선</span>
          </template>
        </p>
        <p class="info">
          <template v-if="galleryCollectionInfo.part !== undefined">
            <template v-if="galleryCollectionInfo.part === '초등 고학년부'">
              <span>Dimension 538 X 416</span><br>
            </template>
            <template v-else>
              <span>Dimension 372 X 279</span><br>
            </template>
          </template>
          <span>Year Created 2022</span><br>
          <span>Edition 1/1</span>
        </p>
        <div class="description" v-if="galleryCollectionInfo.ranking != undefined && galleryCollectionInfo.ranking != null">
          {{ galleryCollectionInfo.description }}
        </div>
      </div>
      <div class="right">
        <div class="product_proof">
          <p class="title">
            {{ $t("product.authentication") }}
          </p>
          <p class="info">{{ $t("product.id") }} {{ galleryCollectionInfo.tokenId }}</p>
          <p class="info">
            {{ $t("product.contract") }}<br />
            {{ galleryCollectionInfo.nft != undefined ? galleryCollectionInfo.nft.contractAddr : "" }}
          </p>
          <p class="network_scan" @click="onClickKlaytnNetwork()">
            <CImg src="/img/threespace/company/kaia-black.png" class="klaytn_logo"/>
            {{ $t("product.klaytnScope") }}
          </p>
        </div>
      </div>
    </div>

    <!-- Detail random list -->
    <div class="random_list">
      <ul>
        <template v-for="(item,i) in galleryCollectionRandomList">
          <li :key="i" @click="onClickCollectionItem(item)">
            <img
              :src="item.imageUrl"
              @error="replaceByDefault"
            />
          </li>
        </template>
      </ul>
    </div>

    <!-- Detail image popup -->
    <div class="img_popup" :class="{show: showImgPopup}">
      <div class="img_wrap">
        <img
          :src="galleryCollectionInfo.imageUrl"
          @error="replaceByDefault"
        />
      </div>
      <div class="zoomout_btn">
        <img
          src="/img/threespace/icon/zoomout_icon.png"
          @click="showImgPopup = !showImgPopup"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
  name: "PicassoDetail",
  props: {
    idxCollection: {
      type: String,
      default: "0"
    }
  },
  data() {
    return {
      showImgPopup: false
    };
  },
  mounted() {
    this.requestCollectionItem();
  },
  computed: {
    ...mapState("collection", ["galleryCollectionInfo", "galleryCollectionRandomList"]),
    activeLanguage() {
      return this.getActiveLanguage();
    },
  },
  methods: {
    ...mapActions("collection", ["getGalleryCollection", "getGalleryCollectionsRandom"]),
    getActiveLanguage() {
      return localStorage.getItem("language") || "en";
    },
    requestCollectionItem() {
      this.getGalleryCollection(this.idxCollection)
      .then((data) => {
        const params = {
          idxNft: data.nft.idx,
          part: data.part,
          count: 5
        }
        this.getGalleryCollectionsRandom(params).then();

      })
      .catch(error => {
        console.log("error : ", error)
      })
    },
    onClickCollectionItem(item) {
      this.$router.push("/little-picasso/detail/" + item.idx);
    },
    onClickKlaytnNetwork() {
      const url = process.env.VUE_APP_KLAYTNSCOPE_URL + "/nft/" + this.galleryCollectionInfo.nft.contractAddr + "/" + this.galleryCollectionInfo.tokenId + "?tabId=nftTransfer";
      window.open(url, "_blank");
      // if (process.env.NODE_ENV === "production") {
      //   const url = 'https://scope.klaytn.com/account/' + this.galleryCollectionInfo.nft.contractAddr
      //   window.open(url, "_blank");
      // } else {
      //   const url = 'https://baobab.scope.klaytn.com/account/' + this.galleryCollectionInfo.nft.contractAddr
      //   window.open(url, "_blank");
      // }
    },
    replaceByDefault(e) {
      e.target.src = '/img/threespace/picasso/empty_img.png'
    }
  }
};
</script>
