<template>
  <div id="Artist">
    <div class="artist_wrap">
      <div class="artist_left">
        <!-- content left area -->
        <div class="title_box">
          <div class="artist_img_box p-1">
            <template v-if="artistInfo.profileImage === undefined">
              <img src="/img/avatars/profile-3space-logo.png" />
            </template>
            <template v-else>
              <img :src="artistInfo.profileImageUrl" />
            </template>
          </div>
        </div>
        <div class="artist_info_box">
          <h2>
            <template v-if="activeLanguage !== undefined && activeLanguage === 'ko'">
              {{ artistInfo.artistName || artistInfo.artistNameEn }}
            </template>
            <template v-else>
              {{ artistInfo.artistNameEn || artistInfo.artistName }}
            </template>
          </h2>
          <p class="mt-1">@{{ artistInfo.nickname }}</p>
          <div class="button_wrap">
            <b-button
              :disabled="artistInfo.instagram === undefined || artistInfo.instagram === null || artistInfo.instagram === ''"
              v-c-tooltip="{ content: 'Instagram', placement: 'top' }"
              variant="link"
              class="p-0 mr-3"
              @click="onClickSNS(artistInfo.instagram, 'instagram')"
            >
              <img src="/img/threespace/mypage/insta_icon.png" />
            </b-button>
            <b-button
              :disabled="artistInfo.twitter === undefined || artistInfo.twitter === null || artistInfo.twitter === ''"
              v-c-tooltip="{ content: 'Twitter', placement: 'top' }"
              variant="link"
              class="p-0 mr-3"
              @click="onClickSNS(artistInfo.twitter, 'twitter')"
            >
              <img src="/img/threespace/mypage/twitter_icon.png" />
            </b-button>
            <b-button
              :disabled="artistInfo.homepage === undefined || artistInfo.homepage === null || artistInfo.homepage === ''"
              v-c-tooltip="{ content: 'Website', placement: 'top' }"
              variant="link"
              class="p-0"
              @click="onClickSNS(artistInfo.homepage, 'homepage')"
            >
              <img src="/img/threespace/mypage/homepage_icon.png" />
            </b-button>
          </div>
          <!-- location -->
          <div class="location mt-3" v-if="artistInfo.location !== undefined && artistInfo.location !== null && artistInfo.location !== ''">
            <img src="/img/threespace/mypage/location_icon.png" class="mr-2" />
            {{ artistInfo.location }}
          </div>
        </div>
        <div class="introduce_box">
          <template v-if="activeLanguage !== undefined && activeLanguage === 'ko'">
            <p v-html="artistInfo.introduce !== undefined && artistInfo.introduce !== '' ? replaceNewLine(artistInfo.introduce) : replaceNewLine(artistInfo.introduceEn)" />
          </template>
          <template v-else>
            <p v-html="artistInfo.introduceEn !== undefined && artistInfo.introduceEn !== '' ? replaceNewLine(artistInfo.introduceEn) : replaceNewLine(artistInfo.introduce)" />
          </template>
        </div>
      </div>
      <div class="artist_right">
        <div class="row">
          <div
            class="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12 px-4"
            v-for="(item, i) in galleryProductItemsContent"
            :key="i"
          >
            <div
              class="card_list_item cursor-pointer"
              @click="onClickArtwork(item.idx)"
            >
              <v-lazy :options="{ threshold: 0.5 }" transition="fade-transition">
                <div
                  class="card_media_box"
                  @contextmenu.prevent.stop
                  :class="{ 'skeleton_display_none': i < currentElement || !isBusy }"
                >
                  <CardHoverBox
                    :favoritedCnt="item.favoritedCnt"
                    :pageViewCnt="item.pageViewCnt"
                    :isExternalCollection="false"
                  />
                  <template v-if="isProductFileTypeImage(item.productFileType)">
                    <b-img :src="replaceDisplayCardUrl(item)"></b-img>
                  </template>
                  <template v-else>
                    <video
                      :poster="replaceDisplayPosterUrl(item)"
                      :src="replaceDisplayCardUrl(item)"
                      type="video/webm"
                      muted
                      loop
                      autoplay
                      playsinline
                    ></video>
                  </template>
                  <b-skeleton-img
                    no-aspect
                    width="100%"
                    height="100%"
                  ></b-skeleton-img>
                </div>
              </v-lazy>
              <div class="card_title_box" v-if="i < currentElement || !isBusy">
                <h3>
                  <template v-if="activeLanguage !== undefined && activeLanguage === 'ko'">
                    {{ item.productName || item.productNameEn }}
                  </template>
                  <template v-else>
                    {{ item.productNameEn || item.productName }}
                  </template>
                </h3>
                <p class="artist_name">
                  <template v-if="item.profileImage === undefined">
                    <CImg
                      class="c-avatar-img mr-2"
                      height="25"
                      src="/img/avatars/profile-gray-person.jpg"
                    />
                  </template>
                  <template v-else>
                    <CImg
                      class="c-avatar-img mr-2"
                      height="25"
                      :src="item.profileImageUrl"
                    />
                  </template>
                  <template v-if="activeLanguage !== undefined && activeLanguage === 'ko'">
                    {{ item.artistName || item.artistNameEn }}
                  </template>
                  <template v-else>
                    {{ item.artistNameEn || item.artistName }}
                  </template>
                </p>
              </div>
              <div class="card_title_box" v-else>
                <p class="artist_name">
                  <b-skeleton
                    type="avatar"
                    height="30px"
                    width="30px"
                    class="float-left"
                  ></b-skeleton>
                  <b-skeleton class="float-left mt-2 ml-2" width="100px"></b-skeleton>
                </p>
              </div>
              <div class="card_price_box" v-if="i < currentElement || !isBusy">
                <div class="text_wrap" :class="{soldout: item.status === 'SOLDOUT'}">
                  <template v-if="item.status === 'SOLDOUT'">
                    {{ 
                      item.buyerNickname !== undefined && item.buyerNickname !== '' ? 
                      "Owned by @" + item.buyerNickname :
                      $t("product.statusSoldOut")
                    }}
                  </template>
                  <template v-else-if="item.status === 'EXHIBIT'">
                    {{ $t("product.statusExhibit") }}
                  </template>
                  <template
                    v-else-if="
                      activeLanguage !== undefined &&
                      activeLanguage === 'en' &&
                      isCoinPriceNonZero(item.nftType) &&
                      isAcceptableNftType(item.nftType)
                    "
                  >
                    {{ getProductCoinPrice(item.productPrice, item.nftType) }} {{getCoinCurrencyByNftType(item.nftType)}}
                  </template>
                  <template v-else>
                    {{ item.productPrice | currency }} {{ item.productPrice !== undefined && item.productPrice > 0 ? "KRW" : $t("product.statusExhibit") }}
                  </template>
                </div>
                <div class="icon_wrap">
                  <FavoritedButton
                    :favoritedItem="item"
                    @onClickFavoritedButton="onClickFavoritedButton"
                  />
                  <CImg
                    src="/img/threespace/company/ethereum-eth-logo.svg"
                    v-if="item.nftType === 'ETHEREUM'"
                    class="ethereum_logo"
                  />
                  <CImg
                    src="/img/threespace/company/polygon-matic-logo.png"
                    v-if="item.nftType === 'POLYGON'"
                    class="polygon_logo"
                  />
                  <CImg
                    src="/img/threespace/company/kaia-black.png"
                    v-else-if="item.nftType === 'KLAYTN' || item.nftType === 'KLIP'"
                    class="klaytn_logo"
                  />
                </div>
              </div>
              <div class="card_price_box" v-else>
                <span>
                  <b-skeleton width="50%" height="25px"></b-skeleton>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-right">
            <div class="p-2">
              <b-button variant="outline-accent" pill class="button_pill_md" @click="onClickViewMore" v-if="!isLast">
                {{ $t("button.viewMore") }}
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {createNamespacedHelpers, mapActions, mapGetters, mapState} from "vuex";
import FavoritedButton from './components/common/FavoritedButton.vue';
import CardHoverBox from './components/common/CardHoverBox.vue';
import coinUtils from "../../mixins/CoinUtils";
const galleryHelper = createNamespacedHelpers("gallery");
const coinHelper = createNamespacedHelpers("coin");

export default {
  components: { 
    FavoritedButton,
    CardHoverBox
   },
  name: "Artist",
  props: {
    idxArtist: {
      type: String,
      default: "0"
    }
  },
  mixins: [coinUtils],
  // metaInfo() {
  //   return {
  //     title: (this.activeLanguage === "ko" ? this.artistInfo.artistName : this.artistInfo.artistNameEn) + " | 3space Art",
  //     meta: [
  //       { vmid: "title", name: "title", content: (this.activeLanguage === "ko" ? this.artistInfo.artistName : this.artistInfo.artistNameEn) + " | 3space Art" },
  //       { vmid: "description", name: "description", content: this.activeLanguage === "ko" ? this.artistInfo.introduce : this.artistInfo.introduceEn },
  //       { vmid: "og:title", property: "og:title", content: (this.activeLanguage === "ko" ? this.artistInfo.artistName : this.artistInfo.artistNameEn) + " | 3space Art" },
  //       { vmid: "og:description", property: "og:description", content: this.activeLanguage === "ko" ? this.artistInfo.introduce : this.artistInfo.introduceEn },
  //       { vmid: "og:url", property: "og:url", content: window.location.origin + this.$route.path }
  //     ]
  //   }
  // },
  data() {
    return {
      isBusy: false,
      isLast: false,
      perPage: 6,
      currentPage: 0,
      sort: [
        { id: "displayDatetime", desc: "desc" },
        { id: "idx", desc: "desc" }
      ],
      currentElement: 0,
      artistInfo: {
        idx: null,
        artistName: null,
        artistNameEn: null,
        nickname: null,
        instagram: null,
        twitter: null,
        introduce: null,
        introduceEn: null,
        profileImage: null,
        profileImageUrl: null
      },
      externalParams: {
        idxArtist: this.idxArtist
      }
    };
  },
  computed: {
    ...galleryHelper.mapState(["galleryProductItems", "galleryProductItemsContent"]),
    ...mapState({
      me: state => {
        return state.auth.me || {};
      }
    }),
    ...mapGetters("auth", ["isAuthenticated"]),
    activeLanguage() {
      return this.getActiveLanguage();
    }
  },
  mounted() {
    this.requestArtistInfo();
    this.resetGalleryProducts();
    this.requestGalleryProductItems();
  },
  methods: {
    ...galleryHelper.mapMutations(["resetGalleryProducts"]),
    ...galleryHelper.mapActions(["getGalleryArtist", "getGalleryProducts"]),
    ...coinHelper.mapActions(["getTickers"]),
    ...mapActions("favorited", ["addFavorited", "deleteFavorited", "toggleFavorited"]),
    ...mapActions("auth", ["verifyTokenCallback"]),
    getActiveLanguage() {
      return localStorage.getItem("language") || "en";
    },
    requestArtistInfo() {
      if (this.idxArtist && this.idxArtist !== "0") {
        this.getGalleryArtist(this.idxArtist).then(data => {
          if (
            Object.keys(data).length !== 0 &&
            JSON.stringify(data) !== JSON.stringify({})
          ) {
            this.artistInfo = data;
          }
        });
      }
    },
    requestGalleryProductItems() {
      this.isBusy = true;
      this.getGalleryProducts(
        this.getPagingParams(
          {
            page: this.currentPage,
            pageSize: this.perPage,
            sorted: this.sort,
            filtered: []
          },
          this.externalParams
        )
      )
        .then(data => {
          this.isLast = data.last;
          this.currentPage = data.pageable.page + 1;
          setTimeout(() => {
            this.currentElement = this.currentPage * this.perPage;
            this.isBusy = false;
          }, 2000);
        })
        .catch(error => {
          this.isBusy = false;
          this.$log.error(error);
        });
    },
    getPagingParams({ page, pageSize, sorted, filtered }, externalParams) {
      const params = {
        page,
        size: pageSize,
        ...externalParams
      };

      // sorting parameter
      let sorts = [];
      for (let i = 0, length = sorted.length; i < length; i++) {
        const sort = sorted[i];
        sorts.push(`${sort.id},${sort.desc ? "desc" : "asc"}`);
      }
      params["sort"] = sorts;

      // filtering parameter
      for (let i = 0, length = filtered.length; i < length; i++) {
        const filter = filtered[i];
        params[filter.id] = filter.value;
      }

      return params;
    },
    onClickViewMore() {
      if (this.isLast !== true) {
        this.requestGalleryProductItems();
      }
    },
    onClickArtwork(idx) {
      this.$router.push("/art/" + idx);
    },
    isProductFileTypeImage(_productFileType) {
      if (
        _productFileType !== undefined &&
        _productFileType.startsWith("image")
      ) {
        return true;
      } else {
        return false;
      }
    },
    replaceDisplayPosterUrl(item) {
      if (
          item.displayPoster !== undefined &&
          item.displayPoster !== null &&
          item.displayPoster !== "" &&
          item.displayPoster === "THREESPACE"
      ) {
        return item.displayPosterUrlThreespace; // 3space S3 URL
      } else {
        if (item.displayPosterUrl !== undefined && item.displayPosterUrl.startsWith("https://ipfs.io/")) {
          return item.displayPosterUrl.replace("https://ipfs.io/", "https://ipfs.3space.art/");
        }
        return item.displayPosterUrl;
      }
    },
    replaceDisplayCardUrl(item) {
      if (
        item.displayCardFile !== undefined &&
        item.displayCardFile !== null &&
        item.displayCardFile !== "" &&
        item.displayCardFile === "THREESPACE"
      ) {
        return item.displayCardUrlThreespace; // 3space S3 URL
      } else {
        if (
          item.displayCardUrl !== undefined &&
          item.displayCardUrl.startsWith("https://ipfs.io/")
        ) {
          return item.displayCardUrl.replace("https://ipfs.io/", "https://ipfs.3space.art/");
        }
        return item.displayCardUrl;
      }
    },
    replaceNewLine(str) {
      if (str !== undefined && str !== null && str !== "") {
        return str.replace(/(?:\r\n|\r|\n)/g, "<br />");
      } else {
        return "";
      }
    },
    goSns(type) {
      let url = "";
      if (type === "instagram") {
        url = "https://www.instagram.com/" + this.artistInfo.instagram + "/";
      } else if (type === "twitter") {
        url = "https://twitter.com/" + this.artistInfo.twitter;
      }
      if (url !== "") {
        window.open(url, "_blank");
      }
    },
    onClickSNS(snsLink, snsType) {
      if (snsType === "instagram") {
        window.open("https://www.instagram.com/" + snsLink + "/", '_blank');
      } else if (snsType === "twitter") {
        window.open("https://twitter.com/" + snsLink, '_blank');
      } else {
        window.open(snsLink, '_blank');
      }
    },
    onClickFavoritedButton(favoritedItem) {
      if (this.isAuthenticated) {
          this.verifyTokenCallback()
            .then(() => {
              if (
                this.me === undefined ||
                this.me === null ||
                this.me.idx === undefined ||
                this.me.idx === null ||
                this.me.idx === ""
              ) {
                // 로그인
                this.$store.commit("setCommonAlertModalInfo", {
                  msgHtml: this.$t("validation.requiredLogin"),
                  show: true,
                  fontSize: "sm"
                })
              } else {
                // 로그인 되어있음 
                if (favoritedItem.favorited) {
                  // true이면 좋아요 헤재
                  this.toggleFavorited(favoritedItem);
                  this.deleteFavorited({idxProduct: favoritedItem.idx});
                } else {
                  // false이면 좋아요
                  this.toggleFavorited(favoritedItem);
                  this.addFavorited({idxProduct: favoritedItem.idx});
                }
              }
            })
        } else {
          this.$store.commit("setCommonAlertModalInfo", {
            msgHtml: this.$t("validation.requiredLogin"),
            show: true,
            fontSize: "sm"
          })
        }
    },
  },
  filters: {
    currency: value => {
      if (!value) return "";
      return value.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    }
  }
};
</script>
