<template>
  <div class="c-app" :class="{ 'c-dark-theme': $store.state.darkMode }">
    <Sidebar v-if="isAdminRole || isCorpAdminRole" />
    <CWrapper>
      <HeaderAdmin />
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid>
            <transition name="fade" mode="out-in">
              <router-view :key="$route.path"></router-view>
            </transition>
          </CContainer>
        </main>
      </div>
      <Footer />
    </CWrapper>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Sidebar from "@/containers/Sidebar";
import HeaderAdmin from "@/containers/HeaderAdmin";
import Footer from "@/containers/Footer";

export default {
  name: "ContainerAdmin",
  components: {
    Sidebar,
    HeaderAdmin,
    Footer
  },
  computed: {
    ...mapState({
      me: state => {
        return state.auth.me || {};
      }
    }),
    isAdminRole() {
      if (
        this.me.roles !== undefined &&
        this.me.roles.length > 0 &&
        (this.me.roles.includes("ROLE_SUPERVISOR") || this.me.roles.includes("ROLE_OPERATOR"))
      ) {
        return true;
      } else {
        return false;
      }
    },
    isCorpAdminRole() {
      if (
        this.me.roles !== undefined &&
        this.me.roles.length > 0 &&
        this.me.roles.includes("ROLE_CORP_ADMIN")
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
