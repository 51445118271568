<template>
  <div>
    <CCard>
      <CCardBody>
        <CRow>
          <CCol class="mb-3"><h5>작품 등록/수정</h5></CCol>
        </CRow>
        <CForm>
          <CRow>
            <!-- Left -->
            <CCol class="col-7">
              <b-table-simple>
                <colgroup>
                  <col style="width:15%;" />
                  <col style="width:85%;" />
                </colgroup>
                <b-tbody>
                  <b-tr>
                    <b-th variant="secondary">작품번호</b-th>
                    <b-td>{{ productInfo.idx || "(정보없음)" }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-th variant="secondary">분류</b-th>
                    <b-td>
                      <p class="mb-0 font-weight-bold">블록체인</p>
                      <b-form-group class="mb-0 mt-1">
                        <b-form-checkbox-group
                          v-model="productInfo.clusters"
                          :options="commonCodes['ART_FILTER_BLOCKCHAIN']"
                        ></b-form-checkbox-group>
                      </b-form-group>
                      <p class="mb-0 mt-2 font-weight-bold">작품 형식</p>
                      <b-form-group class="mb-0 mt-1">
                        <b-form-checkbox-group
                          v-model="productInfo.clusters"
                          :options="commonCodes['ART_FILTER_TYPE']"
                        ></b-form-checkbox-group>
                      </b-form-group>
                      <p class="mb-0 mt-2 font-weight-bold">클러스터</p>
                      <b-form-group class="mb-0 mt-1">
                        <b-form-checkbox-group
                          v-model="productInfo.clusters"
                          :options="commonCodes['PRODUCT_CLUSTER']"
                        ></b-form-checkbox-group>
                      </b-form-group>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-th variant="secondary">작품명</b-th>
                    <b-td>
                      <div class="row align-items-center">
                        <div class="col-lg-1 col-md-1 pr-0">
                          영문&nbsp;<span class="text-danger font-lg">*</span>
                        </div>
                        <div class="col-11">
                          <CInput
                            class="mb-0"
                            placeholder="작품명(영문)을 입력해주세요."
                            :lazy="false"
                            :value.sync="$v.productInfo.productNameEn.$model"
                            :isValid="checkIfValid('productNameEn')"
                            invalidFeedback="필수 정보입니다."
                          />
                        </div>
                      </div>
                      <div class="row align-items-center mt-1">
                        <div class="col-lg-1 col-md-1 pr-0">
                          한글
                        </div>
                        <div class="col-11">
                          <CInput
                            class="mb-0"
                            placeholder="작품명을 입력해주세요."
                            v-model="productInfo.productName"
                          />
                        </div>
                      </div>
                    </b-td>
                  </b-tr>
                  <!--
                  <b-tr>
                    <b-th>작품명(영문)&nbsp;<span class="text-danger font-lg">*</span></b-th>
                    <b-td>
                      <CInput
                        class="mb-0"
                        placeholder="작품명(영문)을 입력해주세요."
                        :lazy="false"
                        :value.sync="$v.productInfo.productNameEn.$model"
                        :isValid="checkIfValid('productNameEn')"
                        invalidFeedback="필수 정보입니다."
                      />
                    </b-td>
                  </b-tr>
                  -->
                  <b-tr>
                    <b-th variant="secondary">작가명</b-th>
                    <b-td>
                      <div class="row align-items-center">
                        <div class="col-lg-1 col-md-1 pr-0">
                          영문&nbsp;<span class="text-danger font-lg">*</span>
                        </div>
                        <div class="col-11">
                          <CInput
                            class="mb-0"
                            placeholder="검색"
                            :readonly="true"
                            :lazy="false"
                            :value.sync="$v.productInfo.artistNameEn.$model"
                            :isValid="checkIfValid('artistNameEn')"
                            invalidFeedback="필수 정보입니다."
                          >
                            <template #append>
                              <CButton color="dark" class="text-white" @click="onClickArtistSelect">
                                <CIcon name="cil-magnifying-glass" />
                              </CButton>
                            </template>
                          </CInput>
                        </div>
                      </div>
                      <div class="row align-items-center mt-1">
                        <div class="col-lg-1 col-md-1 pr-0">
                          한글
                        </div>
                        <div class="col-lg-4 col-md-4 pr-0">
                          <CInput
                            class="mb-0"
                            :readonly="true"
                            v-model="productInfo.artistName"
                          />
                        </div>
                        <div class="col-lg-3 col-md-3 pr-0">
                          작가 사용자이름
                        </div>
                        <div class="col-lg-4 col-md-4">
                          <CInput
                            prepend="@"
                            class="mb-0"
                            placeholder="사용자 이름"
                            :readonly="true"
                            v-model="productInfo.artistNickName"
                          />
                        </div>
                      </div>
                    </b-td>
                  </b-tr>
                  <!--
                  <b-tr>
                    <b-th>작가명(영문)&nbsp;<span class="text-danger font-lg">*</span></b-th>
                    <b-td>
                      <CInput
                        class="mb-0"
                        placeholder="검색"
                        :readonly="true"
                        :lazy="false"
                        :value.sync="$v.productInfo.artistNameEn.$model"
                        :isValid="checkIfValid('artistNameEn')"
                        invalidFeedback="필수 정보입니다."
                      >
                        <template #append>
                          <CButton color="dark" class="text-white" @click="onClickArtistSelect">
                            <CIcon name="cil-magnifying-glass" />
                          </CButton>
                        </template>
                      </CInput>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-th>작가<br />사용자 이름</b-th>
                    <b-td>
                      <CInput
                        prepend="@"
                        class="mb-0"
                        placeholder="사용자 이름"
                        :lazy="false"
                        :readonly="true"
                        v-model="productInfo.artistNickName"
                      />
                    </b-td>
                  </b-tr>
                  -->
                  <b-tr>
                    <b-th variant="secondary">판매가&nbsp;<span class="text-info font-lg">*</span></b-th>
                    <b-td>
                      <CInput
                        class="mb-0"
                        placeholder="₩ 가격을 입력하세요."
                        v-model="productInfo.productPrice"
                      />
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-th variant="secondary">오디널스 판매가(사토시)<span class="text-info font-lg">*</span></b-th>
                    <b-td>
                      <CInput
                        class="mb-0"
                        placeholder="BTC 사토시 수량을 입력하세요. (1BTC = 100,000,000 sat)"
                        v-model="productInfo.productCoinPrice"
                      />
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-th variant="secondary">결제수단 <span class="text-info font-lg">*</span></b-th>
                    <b-td>
                      <b-form-group class="mb-0 mt-1">
                        <b-form-checkbox-group
                          v-model="productInfo.paytypes"
                          :options="paymentTypeOptions"
                        ></b-form-checkbox-group>
                      </b-form-group>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-th variant="secondary">작품 스펙&nbsp;<span class="text-info font-lg">*</span></b-th>
                    <b-td>
                      <div class="row align-items-center">
                        <div class="col-lg-2 col-md-2 pr-0">
                          작품 사이즈
                        </div>
                        <div class="col-lg-3 col-md-3 pr-0">
                          <CInput
                            class="mb-0"
                            placeholder="가로"
                            v-model="productInfo.productSizeX"
                          />
                        </div>
                        <div class="col-lg-3 col-md-3 pr-0">
                          <CInput
                            class="mb-0"
                            placeholder="세로"
                            v-model="productInfo.productSizeY"
                          />
                        </div>
                      </div>
                      <div class="row align-items-center mt-1">
                        <div class="col-lg-2 col-md-2 pr-0">
                          작품 제작연도
                        </div>
                        <div class="col-lg-3 col-md-3 pr-0">
                          <CInput
                            class="mb-0"
                            placeholder="작품 제작연도"
                            v-model="productInfo.paintedAt"
                          />
                        </div>
                      </div>
                      <div class="row align-items-center mt-1">
                        <div class="col-lg-2 col-md-2 pr-0">
                          작품 에디션
                        </div>
                        <div class="col-lg-3 col-md-3 pr-0">
                          <CInput
                            class="mb-0"
                            placeholder="작품 에디션"
                            v-model="productInfo.productEdition"
                          />
                        </div>
                      </div>
                    </b-td>
                  </b-tr>
                  <!--
                  <b-tr>
                    <b-th>작품 사이즈&nbsp;<span class="text-info font-lg">*</span></b-th>
                    <b-td>
                      <CForm inline>
                        <CInput
                          class="mb-0 float-left custom-width-120 mr-2"
                          placeholder="가로"
                          v-model="productInfo.productSizeX"
                        />
                        <CInput
                          class="mb-0 float-left custom-width-120"
                          placeholder="세로"
                          v-model="productInfo.productSizeY"
                        />
                      </CForm>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-th>작품 제작연도&nbsp;<span class="text-info font-lg">*</span></b-th>
                    <b-td>
                      <CInput
                        class="mb-0"
                        placeholder="작품 제작연도를 입력하세요."
                        v-model="productInfo.paintedAt"
                      />
                    </b-td>
                  </b-tr>
                  -->
                  <b-tr>
                    <b-th variant="secondary">작품 음향</b-th>
                    <b-td>
                      <b-form-radio-group
                        id="productSound"
                        name="productSound"
                        :options="soundOptions"
                        v-model="productInfo.productSound"
                      />
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-th variant="secondary">작품 설명</b-th>
                    <b-td>
                      <div class="row align-items-center">
                        <div class="col-lg-1 col-md-1 pr-0">
                          영문&nbsp;<span class="text-danger font-lg">*</span>
                        </div>
                        <div class="col-11">
                          <CTextarea
                            class="mb-0"
                            placeholder="작품 설명(영문)을 입력하세요."
                            rows="4"
                            :lazy="false"
                            :value.sync="$v.productInfo.productDescriptionEn.$model"
                            :isValid="checkIfValid('productDescriptionEn')"
                            invalidFeedback="필수 정보입니다."
                          />
                        </div>
                      </div>
                      <div class="row align-items-center mt-1">
                        <div class="col-lg-1 col-md-1 pr-0">
                          한글
                        </div>
                        <div class="col-11">
                          <CTextarea
                            class="mb-0"
                            placeholder="작품 설명을 입력하세요."
                            rows="4"
                            v-model="productInfo.productDescription"
                          />
                        </div>
                      </div>
                    </b-td>
                  </b-tr>
                  <!--
                  <b-tr>
                    <b-th>작품 설명</b-th>
                    <b-td>
                      <CTextarea
                        class="mb-0"
                        placeholder="작품 설명을 입력하세요."
                        rows="4"
                        v-model="productInfo.productDescription"
                      />
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-th>작품 설명(영문)&nbsp;<span class="text-danger font-lg">*</span></b-th>
                    <b-td>
                      <CTextarea
                        class="mb-0"
                        placeholder="작품 설명(영문)을 입력하세요."
                        rows="4"
                        :lazy="false"
                        :value.sync="$v.productInfo.productDescriptionEn.$model"
                        :isValid="checkIfValid('productDescriptionEn')"
                        invalidFeedback="필수 정보입니다."
                      />
                    </b-td>
                  </b-tr>
                  -->
                  <b-tr>
                    <b-th variant="secondary">작품 기법</b-th>
                    <b-td>
                      <div class="row align-items-center">
                        <div class="col-lg-1 col-md-1 pr-0">
                          영문
                        </div>
                        <div class="col-11">
                          <CTextarea
                            class="mb-0"
                            placeholder="작품 기법(영문)을 입력하세요."
                            rows="2"
                            v-model="productInfo.productMoreDescriptionEn"
                          />
                        </div>
                      </div>
                      <div class="row align-items-center mt-1">
                        <div class="col-lg-1 col-md-1 pr-0">
                          한글
                        </div>
                        <div class="col-11">
                          <CTextarea
                            class="mb-0"
                            placeholder="작품 기법을 입력하세요."
                            rows="2"
                            v-model="productInfo.productMoreDescription"
                          />
                        </div>
                      </div>
                    </b-td>
                  </b-tr>
                  <!--
                  <b-tr>
                    <b-th>작품 기법</b-th>
                    <b-td>
                      <CTextarea
                        class="mb-0"
                        placeholder="작품 기법을 입력하세요."
                        rows="2"
                        v-model="productInfo.productMoreDescription"
                      />
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-th>작품 기법(영문)</b-th>
                    <b-td>
                      <CTextarea
                        class="mb-0"
                        placeholder="작품 기법(영문)을 입력하세요."
                        rows="2"
                        v-model="productInfo.productMoreDescriptionEn"
                      />
                    </b-td>
                  </b-tr>
                  -->
                  <b-tr>
                    <b-th variant="secondary">갤러리</b-th>
                    <b-td>
                      <b-form-radio-group
                        id="display"
                        name="display"
                        :options="displayOptions"
                        v-model="productInfo.display"
                      />
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-th variant="secondary">상태</b-th>
                    <b-td>
                      <b-form-radio-group
                        id="status"
                        name="status"
                        :options="statusOptions"
                        v-model="productInfo.status"
                      />
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-th variant="secondary">공개일</b-th>
                    <b-td>
                      <CForm inline>
                        <b-form-radio-group
                          id="displayNow"
                          name="displayNow"
                          :options="displayDateOptions"
                          v-model="productInfo.displayNow"
                        />
                        <CInput
                          type="date"
                          class="float-left mr-2"
                          v-if="productInfo.displayNow === 'false'"
                          v-model="productInfo.displayDate"
                        />
                        <CFormGroup
                          wrapperClasses="input-group mr-2 custom-width-80"
                        >
                          <template #input>
                            <masked-input
                              type="text"
                              name="displayDateHour"
                              class="form-control"
                              v-if="productInfo.displayNow === 'false'"
                              v-model="productInfo.displayDateHour"
                              :mask="[/\d/, /\d/]"
                              :guide="true"
                              placeholderChar="#"
                            />
                          </template>
                        </CFormGroup>
                        <CFormGroup wrapperClasses="input-group custom-width-80">
                          <template #input>
                            <masked-input
                              type="text"
                              name="displayDateMinute"
                              class="form-control"
                              v-if="productInfo.displayNow === 'false'"
                              v-model="productInfo.displayDateMinute"
                              :mask="[/\d/, /\d/]"
                              :guide="true"
                              placeholderChar="#"
                            />
                          </template>
                        </CFormGroup>
                      </CForm>
                      <div
                        class="bg-gray-100 text-dark mt-2 px-3 py-2"
                        v-if="productInfo.displayNow === 'true'"
                      >
                        <CIcon name="cil-info" class="text-info mr-2" />
                        <span class="text-info">"예"를 선택한 경우, <u>저장시 매번</u> 공개일을 현재날짜로 업데이트 합니다.</span>
                        <b-form-radio-group
                          id="displayAlwaysUptoDate"
                          name="displayAlwaysUptoDate"
                          :options="yesOrNoOptions"
                          v-model="productInfo.displayAlwaysUptoDate"
                          class="mt-2"
                        />
                      </div>
                      <div class="mt-2">
                        공개 시작일: {{ productInfo.displayDatetime }}
                      </div>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-th variant="secondary">Featured</b-th>
                    <b-td>
                      <b-form-radio-group
                        id="featured"
                        name="featured"
                        :options="featuredOptions"
                        v-model="productInfo.featured"
                      />
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-th variant="secondary">필터시에만 전시</b-th>
                    <b-td>
                      <b-form-radio-group
                        id="filterDisplayOnly"
                        name="filterDisplayOnly"
                        :options="yesOrNoOptions"
                        v-model="productInfo.filterDisplayOnly"
                      />
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td colspan="2" class="text-info">
                      <CIcon name="cil-info" class="mr-2" /> 갤러리: "비공개"인 경우에는 작품이 갤러리에 표시되지 않습니다.<br/>
                      <!--<CIcon name="cil-info" class="mr-2" /> 상태: "판매준비"인 경우에는 작품이 갤러리에 표시되지 않습니다.-->
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </CCol>
            <!-- Right -->
            <CCol class="col-5">
              <CRow>
                <CCol class="pl-0 text-danger">
                  <CIcon name="cil-info" /> 작품 파일 종류에 따라 작품 리소스 관리 항목이 상이합니다.
                </CCol>
              </CRow>
              <CRow class="border-danger mt-1 mr-1 py-2">
                <CCol>
                  <b-form-radio-group
                    id="productFileType"
                    name="productFileType"
                    :options="productFileOptions"
                    v-model="productInfo.productFileType"
                  />
                  <div class="mt-2 bg-gray-100 px-2">
                    <b>&#183;</b><small class="ml-2">작품이 <b class="text-info">영상</b>인 경우 : <b>1. 작품 이미지(or 포스터), 2. 작품 영상 or 3. 작품 영상(대용량)</b></small><br />
                    <b>&#183;</b><small class="ml-2">작품이 <b class="text-info">이미지</b>인 경우 : <b>1. 작품 이미지(or 포스터)</b></small>
                  </div>
                </CCol>
              </CRow>
               <CRow class="mt-3">
                <CCol class="pl-0">
                  <strong>1. 작품 이미지(or 포스터)</strong>
                </CCol>
              </CRow>
              <CRow class="bg-gray-100 mt-2 mr-1 py-2">
                <CCol>
                  <CRow>
                    <CCol>
                      <CIcon name="cil-info" />
                      <small> Klip 카드 이미지 사이즈 - 가로/세로 1024px 이상, 크기: 5MB 이하, 종류: jpg, jpeg, 해상도: 72dpi 이상</small><br/>
                      <CIcon name="cil-info" />
                      <small> 포스터 - 동영상을 재생하기 전까지 출력되는 포스터 프레임에 사용되는 이미지 파일(영상의 첫장면), 크기: 50MB 이하</small>
                    </CCol>
                  </CRow>
                  <CRow class="mt-1">
                    <CCol>
                      <b-form-file
                        placeholder="작품 이미지 파일을 선택하세요."
                        accept="image/jpg, image/jpeg, image/png, image/gif"
                        ref="image-file"
                        v-model="file.image"
                        @input="onClickImageFile"
                      >
                      </b-form-file>
                    </CCol>
                  </CRow>
                  <CRow class="mt-1">
                    <CCol>
                      <CIcon name="cil-save" class="text-success" />
                      <span class="text-success">
                        {{ productResourceInfo.image.fileInfo || "(정보없음)" }}
                      </span>
                    </CCol>
                  </CRow>
                  <CRow class="mt-2">
                    <CCol><b>저장소</b></CCol>
                  </CRow>
                  <CRow class="mt-1">
                    <CCol class="col-4 pr-0">
                      <b-form-checkbox
                        v-model="productResourceInfo.image.threespace"
                        value="true"
                        unchecked-value="false"
                      >
                        3space AWS
                      </b-form-checkbox>
                    </CCol>
                    <CCol class="col-8 bg-gray-100">
                      <span class="text-success">
                        {{ productResourceInfo.image.threespaceUrl || "(정보없음)" }}
                      </span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol class="col-4 pr-0">
                      <b-form-checkbox
                        v-model="productResourceInfo.image.klip"
                        value="true"
                        unchecked-value="false"
                      >
                        Klip Partners AWS
                      </b-form-checkbox>
                    </CCol>
                    <CCol class="col-8 bg-gray-100">
                      <span class="text-success">
                        {{ productResourceInfo.image.klipUrl || "(정보없음)" }}
                      </span>
                    </CCol>
                  </CRow>
                  <CRow class="mt-2">
                    <CCol>IPFS(작품)</CCol>
                  </CRow>
                  <CRow class="mt-1">
                    <CCol>
                      <CTextarea
                        placeholder="IPFS URL을 입력하세요."
                        rows="2"
                        v-model="productResourceInfo.image.ipfsUrl"
                        class="mb-0"
                      />
                    </CCol>
                  </CRow>
                </CCol>
              </CRow>
              <CRow class="mt-3">
                <CCol class="pl-0">
                  <strong>2. 작품 영상</strong>
                </CCol>
              </CRow>
              <CRow class="bg-gray-100 mt-2 mr-1 py-2">
                <CCol>
                  <CRow>
                    <CCol>
                      <CIcon name="cil-info" />
                      <small> Klip 카드 영상 사이즈 - 3840x2160 이하, 크기: 50MB 이하, 종류: mp4</small>
                    </CCol>
                  </CRow>
                  <CRow class="mt-1">
                    <CCol>
                      <b-form-file
                        placeholder="작품 영상 파일을 선택하세요."
                        accept=".glb, .webm, .mp4, .m4v, .ogv, .ogg, .mp3, .wav, .oga"
                        ref="preview-file"
                        v-model="file.preview"
                        @input="onClickPreviewFile"
                      ></b-form-file>
                    </CCol>
                  </CRow>
                  <CRow class="mt-1">
                    <CCol>
                      <CIcon name="cil-save" class="text-success" />
                      <span class="text-success">
                        {{ productResourceInfo.preview.fileInfo || "(정보없음)" }}
                      </span>
                    </CCol>
                  </CRow>
                  <CRow class="mt-2">
                    <CCol><b>저장소</b></CCol>
                  </CRow>
                  <CRow class="mt-1">
                    <CCol class="col-4 pr-0">
                      <b-form-checkbox
                        v-model="productResourceInfo.preview.threespace"
                        value="true"
                        unchecked-value="false"
                      >
                        3space AWS
                      </b-form-checkbox>
                    </CCol>
                    <CCol class="col-8 bg-gray-100">
                      <span class="text-success">
                        {{ productResourceInfo.preview.threespaceUrl || "(정보없음)" }}
                      </span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol class="col-4 pr-0">
                      <b-form-checkbox
                        v-model="productResourceInfo.preview.klip"
                        value="true"
                        unchecked-value="false"
                      >
                        Klip Partners AWS
                      </b-form-checkbox>
                    </CCol>
                    <CCol class="col-8 bg-gray-100">
                      <span class="text-success">
                        {{ productResourceInfo.preview.klipUrl || "(정보없음)" }}
                      </span>
                    </CCol>
                  </CRow>
                  <CRow class="mt-2">
                    <CCol>IPFS(작품)</CCol>
                  </CRow>
                  <CRow class="mt-1">
                    <CCol>
                      <CTextarea
                        placeholder="IPFS URL을 입력하세요."
                        rows="2"
                        v-model="productResourceInfo.preview.ipfsUrl"
                        class="mb-0"
                      />
                    </CCol>
                  </CRow>
                </CCol>
              </CRow>
              <CRow class="mt-3">
                <CCol class="pl-0">
                  <strong>3. 작품 영상(대용량)</strong><span class="font-xs"> - 작품 파일 사이즈가 50MB 이상인 경우 추가 저장</span>
                </CCol>
              </CRow>
              <CRow class="bg-gray-100 mt-2 mr-1 py-2">
                <CCol>
                  <CRow>
                    <CCol>
                      <b-form-file
                        placeholder="작품 영상 파일을 선택하세요."
                        accept=".glb, .webm, .mp4, .m4v, .ogv, .ogg, .mp3, .wav, .oga"
                        ref="original-file"
                        v-model="file.original"
                        @input="onClickOriginalFile"
                      ></b-form-file>
                    </CCol>
                  </CRow>
                  <CRow class="mt-1">
                    <CCol>
                      <CIcon name="cil-save" class="text-success" />
                      <span class="text-success">
                        {{ productResourceInfo.original.fileInfo || "(정보없음)" }}
                      </span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol>
                      <CRow class="mt-2">
                        <CCol><b>저장소</b></CCol>
                      </CRow>
                      <CRow class="mt-1">
                        <CCol class="col-4">
                          <b-form-checkbox
                            v-model="productResourceInfo.original.threespace"
                            value="true"
                            unchecked-value="false"
                          >
                            3space AWS
                          </b-form-checkbox>
                        </CCol>
                        <CCol class="col-8 bg-gray-100">
                          <span class="text-success">{{ productResourceInfo.original.threespaceUrl || "(정보없음)" }}</span>
                        </CCol>
                      </CRow>
                      <CRow class="mt-2">
                        <CCol>IPFS(작품)</CCol>
                      </CRow>
                      <CRow class="mt-1">
                        <CCol>
                          <CTextarea
                            placeholder="IPFS URL을 입력하세요."
                            rows="2"
                            v-model="productResourceInfo.original.ipfsUrl"
                            class="mb-0"
                          />
                        </CCol>
                      </CRow>
                    </CCol>
                  </CRow>
                </CCol>
              </CRow>
              <CRow class="mt-3">
                <CCol class="pl-0">
                  <strong class="text-info">작품 전시</strong><code>(필수) - 갤러리 및 작품 상세 화면에 표시되는 기준정보!!</code>
                </CCol>
              </CRow>
              <CRow class="border-info mt-2 mr-1 py-2">
                <CCol>
                  <CRow v-if="productInfo.productFileType === 'video'">
                    <CCol class="col-3"><strong>작품 포스터</strong></CCol>
                    <CCol class="col-9">
                      <b-form-radio-group
                        id="displayPoster"
                        name="displayPoster"
                        :options="productPreviewOptions"
                        v-model="productInfo.displayPoster"
                        @change="onClickProductDisplay('PRODUCT-POSTER')"
                      />
                      <CRow class="bg-gray-100 mt-2 mr-1 py-2">
                        <CCol class="text-success">{{ productInfo.displayPosterUrl || "(정보없음)" }}</CCol>
                      </CRow>
                    </CCol>
                  </CRow>
                  <CRow :class="productInfo.productFileType === 'video' ? 'mt-3' : 'mt-1'">
                    <CCol class="col-3"><strong>작품 카드</strong></CCol>
                    <CCol class="col-9">
                      <b-form-radio-group
                        id="displayCard"
                        name="displayCard"
                        :options="productViewOptions"
                        v-model="productInfo.displayCard"
                        @change="onClickProductDisplay('PRODUCT-CARD')"
                      />
                      <CRow class="bg-gray-100 mt-2 mr-1 py-2">
                        <CCol>
                          <b-form-radio-group
                            id="displayCardFile"
                            name="displayCardFile"
                            :options="productInfo.displayCard === 'ORIGINAL' ? productOriginalOptions : productPreviewOptions"
                            v-model="productInfo.displayCardFile"
                            @change="onClickProductDisplay('PRODUCT-CARD')"
                          />
                        </CCol>
                      </CRow>
                      <CRow class="bg-gray-100 mr-1 py-2">
                        <CCol class="text-success">{{ productInfo.displayCardUrl || "(정보없음)" }}</CCol>
                      </CRow>
                    </CCol>
                  </CRow>
                  <CRow class="mt-3">
                    <CCol class="col-3"><strong>작품 상세</strong></CCol>
                    <CCol class="col-9">
                      <b-form-radio-group
                        id="displayDetail"
                        name="displayDetail"
                        :options="productViewOptions"
                        v-model="productInfo.displayDetail"
                        @change="onClickProductDisplay('PRODUCT-DETAIL')"
                      />
                      <CRow class="bg-gray-100 mt-2 mr-1 py-2">
                        <CCol>
                          <b-form-radio-group
                            id="displayDetailFile"
                            name="displayDetailFile"
                            :options="productInfo.displayDetail === 'ORIGINAL' ? productOriginalOptions : productPreviewOptions"
                            v-model="productInfo.displayDetailFile"
                            @change="onClickProductDisplay('PRODUCT-DETAIL')"
                          />
                        </CCol>
                      </CRow>
                      <CRow class="bg-gray-100 mr-1 py-2">
                        <CCol class="text-success">{{ productInfo.displayDetailUrl || "(정보없음)" }}</CCol>
                      </CRow>
                    </CCol>
                  </CRow>
                </CCol>
              </CRow>
            </CCol>
          </CRow>
          <!-- Bottom -->
          <CRow>
            <CCol col="12" class="text-center my-4">
              <CButton color="light" @click="onClickCancel()" class="mr-2">
                <CIcon name="cil-x-circle" class="mr-2" />목록
              </CButton>
              <CButton color="secondary" @click="onClickDelete()" class="mr-2">
                <CIcon name="cil-trash" class="mr-2" />삭제
              </CButton>
              <CButton color="dark" class="text-white" @click="onClickSave()">
                <CIcon name="cil-save" class="mr-2" :disabled="!submitted" />저장
              </CButton>
            </CCol>
          </CRow>
        </CForm>

        <ArtistSelectPopup ref="artistSelectPopup" @artist-selected="selectedArtistInfo" />
      </CCardBody>
    </CCard>

    <!-- 메시지 팝업 -->
    <b-modal
      id="message-modal"
      ref="message-modal"
      hide-header
      hide-footer
      centered
      no-close-on-esc
      no-close-on-backdrop
      body-class="my-4 text-dark d-flex flex-column"
    >
      <template v-if="!submitted">
        <div class="mt-3 text-center">
          처리중입니다.
        </div>
        <div class="mt-3 d-flex justify-content-center">
          <div class="sk-wave">
            <div class="sk-wave-rect"></div>
            <div class="sk-wave-rect"></div>
            <div class="sk-wave-rect"></div>
            <div class="sk-wave-rect"></div>
            <div class="sk-wave-rect"></div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="mt-3 text-center">
          {{ message }}
        </div>
        <div class="w-100 text-center mt-5">
          <b-button
            variant="primary"
            squared
            class="text-white px-5 py-2"
            @click="hideMessageModal"
          >
            확인
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import MaskedInput from "vue-text-mask";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { createNamespacedHelpers } from "vuex";
const productHelper = createNamespacedHelpers("product");
const codeHelper = createNamespacedHelpers("code");

import ArtistSelectPopup from "@/views/management/artist/ArtistSelectPopup";

export default {
  name: "ProductSave",
  components: {
    MaskedInput,
    ArtistSelectPopup
  },
  props: {
    idxProduct: {
      type: String,
      default: "0"
    }
  },
  data: function() {
    return {
      submitted: false,
      message: "처리중입니다.",
      productInfo: {
        productNameEn: "",
        artistNameEn: "",
        productDescriptionEn: "",
        productCoinPrice:"",
        // 여기까지 validation 체크 대상항목
        productCoinCurrency:"",
        productFileType: "video",
        productSound: "false",
        display: "false",
        displayNow: "true",
        displayDateHour: "00",
        displayDateMinute: "00",
        status: "PREPARING",
        featured: "false",
        filterDisplayOnly: "false",
        displayAlwaysUptoDate: "false",
        // 작품 전시
        displayPoster: "THREESPACE",
        displayCard: "PREVIEW",
        displayCardFile: "IPFS",
        displayDetail: "PREVIEW",
        displayDetailFile: "IPFS",
        // 분류
        clusters: [],
        artistName: "",
        artistNickName: "",
        paytypes: []
      },
      productResourceInfo: {
        image: {
          fileInfo: "(정보없음)",
          threespace: false,
          klip: false,
          threespaceUrl: "",
          klipUrl: "",
          ipfsUrl: ""
        },
        preview: {
          fileInfo: "(정보없음)",
          threespace: false,
          klip: false,
          threespaceUrl: "",
          klipUrl: "",
          ipfsUrl: ""
        },
        original: {
          fileInfo: "(정보없음)",
          threespace: false,
          threespaceUrl: "",
          ipfsUrl: ""
        }
      },
      file: {
        image: null,
        preview: null,
        original: null
      },
      soundOptions: [
        { value: "false", text: "미포함" },
        { value: "true", text: "포함" }
      ],
      displayOptions: [
        { value: "false", text: "비공개" },
        { value: "true", text: "공개" }
      ],
      displayDateOptions: [
        { value: "true", text: "현재" },
        { value: "false", text: "예약" }
      ],
      statusOptions: [
        { value: "PREPARING", text: "판매예정" },
        { value: "EXHIBIT", text: "전시중" },
        { value: "FORSALE", text: "판매가능" },
        { value: "SOLDOUT", text: "판매종료" },
        { value: "PRIVATESOLD", text: "판매종료(가격비공개)" },
        { value: "RESERVE", text: "예약중(결제대기)", disabled: true }
      ],
      featuredOptions: [
        { value: "false", text: "미대상" },
        { value: "true", text: "대상" }
      ],
      yesOrNoOptions: [
        { value: "false", text: "아니오" },
        { value: "true", text: "예" }
      ],
      productFileOptions: [
        { value: "video", text: "영상" },
        { value: "image", text: "이미지" }
      ],
      productPreviewOptions: [
        { value: "THREESPACE", text: "3space AWS" },
        { value: "KLIP", text: "Klip Partners AWS" },
        { value: "IPFS", text: "IPFS" }
      ],
      productOriginalOptions: [
        { value: "THREESPACE", text: "3space AWS" },
        { value: "IPFS", text: "IPFS" }
      ],
      productViewOptions: [
        { value: "IMAGE", text: "작품 이미지" },
        { value: "PREVIEW", text: "작품 영상" },
        { value: "ORIGINAL", text: "작품 영상(대용량)" }
      ],
      productDisplayMap: null,
      paymentTypeOptions: [
        { value: "CARD", text: "간편결제" },
        { value: "VBANK", text: "가상계좌(KRW)" },
        { value: "TRANSFER", text: "계좌이체(KRW)" },
        { value: "ETH", text: "이더(ETH)" },
        { value: "MATIC", text: "매틱(MATIC)" },
        { value: "KLAY", text: "클레이(KLAY)" },
        { value: "SSX", text: "썸씽토큰(SSX)" },
        { value: "BTC", text: "비트코인(BTC)" },
      ]
    };
  },
  computed: {
    ...codeHelper.mapState(["commonCodes"]),
    isValid() {
      return !this.$v.productInfo.$invalid;
    },
    isDirty() {
      return this.$v.productInfo.$anyDirty;
    }
  },
  mixins: [validationMixin],
  validations: {
    productInfo: {
      productNameEn: { required },
      artistNameEn: { required },
      productDescriptionEn: { required }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.requestChildCodes(["PRODUCT_CLUSTER", "ART_FILTER_BLOCKCHAIN", "ART_FILTER_TYPE"]);
    });
    this.productDisplayMap = new Map();
  },
  methods: {
    ...productHelper.mapActions([
      "addProduct",
      "updateProduct",
      "updateProductResource",
      "getProduct",
      "deleteProduct"
    ]),
    ...codeHelper.mapActions(["getChildMultiCodes"]),
    requestChildCodes(codes) {
      this.getChildMultiCodes(codes).then(() => {
        this.requestProductItem();
      });
    },
    requestProductItem() {
      if (this.idxProduct && this.idxProduct !== "0") {
        this.getProduct(this.idxProduct).then(data => {
          if (
            Object.keys(data).length !== 0 &&
            JSON.stringify(data) !== JSON.stringify({})
          ) {
            this.$log.log(data); //TODO
            // 작품 기본정보
            this.productInfo = data;
            this.productInfo.productSound =
              data.productSound === false ? "false" : "true";
            this.productInfo.display =
              data.display === false ? "false" : "true";
            this.productInfo.displayNow =
              data.displayNow === false ? "false" : "true";
            this.productInfo.featured =
              data.featured === true ? "true" : "false";
            this.productInfo.filterDisplayOnly =
              data.filterDisplayOnly === true ? "true" : "false";
            this.productInfo.displayAlwaysUptoDate =
              data.displayAlwaysUptoDate === true ? "true" : "false";

            // 작품분류
            let clusters = [];
            if (data.clusters != undefined && data.clusters != null && data.clusters.length > 0) {
              data.clusters.forEach(item => {
                clusters.push(item.codeValue);
              });
            }
            this.productInfo.clusters = clusters;

            // 결제수단
            let paytypes = [];
            if (data.paytypes != undefined && data.paytypes != null && data.paytypes.length > 0) {
              data.paytypes.forEach(item => {
                paytypes.push(item.currency);
              });
            }
            this.productInfo.paytypes = paytypes;

            // 공개일(예약)
            let displayDates = ["", "", ""];
            if (
              data.displayDatetime !== undefined &&
              data.displayDatetime !== null &&
              data.displayDatetime.length === 16
            ) {
              const datetime = data.displayDatetime.split(" ");
              const time = datetime[1].split(":");
              displayDates[0] = datetime[0];
              displayDates[1] = time[0];
              displayDates[2] = time[1];
            }
            this.productInfo.displayDate = displayDates[0];
            this.productInfo.displayDateHour = displayDates[1];
            this.productInfo.displayDateMinute = displayDates[2];

            // 작품 파일 구분
            if (
              data.productFileType !== undefined &&
              data.productFileType !== null &&
              data.productFileType !== ""
            ) {
              this.productInfo.productFileType = data.productFileType;
            } else {
              this.productInfo.productFileType = "video";
            }

            // 작품 리소스, 작품 전시 정보 표시용
            data.resources.forEach(item => {
              let fileInfo =
                item.fileName !== undefined && item.fileName !== null && item.fileName !== "" ? item.fileName + " | " + item.fileType + " | " + item.fileSize + "(byte)" : "";

              if (item.resourceType === "PRODUCT_IMAGE") {
                this.productResourceInfo.image = item;
                this.productResourceInfo.image.fileInfo = fileInfo;

                this.productDisplayMap.set("IMAGE-THREESPACE", item.threespaceUrl);
                this.productDisplayMap.set("IMAGE-KLIP", item.klipUrl);
                this.productDisplayMap.set("IMAGE-IPFS", item.ipfsUrl);
              } else if (item.resourceType === "PRODUCT_PREVIEW") {
                this.productResourceInfo.preview = item;
                this.productResourceInfo.preview.fileInfo = fileInfo;

                this.productDisplayMap.set("PREVIEW-THREESPACE", item.threespaceUrl);
                this.productDisplayMap.set("PREVIEW-KLIP", item.klipUrl);
                this.productDisplayMap.set("PREVIEW-IPFS", item.ipfsUrl);
              } else if (item.resourceType === "PRODUCT_ORIGINAL") {
                this.productResourceInfo.original = item;
                this.productResourceInfo.original.fileInfo = fileInfo;

                this.productDisplayMap.set("ORIGINAL-THREESPACE", item.threespaceUrl);
                this.productDisplayMap.set("ORIGINAL-IPFS", item.ipfsUrl);
              }
            });
            this.$log.log(this.productInfo); //TODO
            // 작품 전시
            this.onClickProductDisplay("PRODUCT-POSTER");
            this.onClickProductDisplay("PRODUCT-CARD");
            this.onClickProductDisplay("PRODUCT-DETAIL");
          }
        });
      }
    },
    onClickArtistSelect() {
      this.$refs["artistSelectPopup"].show();
    },
    selectedArtistInfo(item) {
      this.$log.log(item); //TODO
      this.productInfo.idxArtist = item.idx;
      this.productInfo.artistName = item.artistName;
      this.productInfo.artistNameEn = item.artistNameEn;
      this.productInfo.artistNickName = item.nickname;
      this.productInfo.idxCompany = item.idxCompany;
    },
    onClickCancel() {
      this.$router.push({name: "ProductList", params: { linkType: "comeback"}});
    },
    onClickDelete() {
      if (confirm("정말 삭제하시겠습니까?")) {
        this.deleteProduct({ idxProduct: this.idxProduct }).then(() => {
          alert("삭제되었습니다.");
          this.$router.push({name: "ProductList", params: { linkType: "comeback"}});
        });
      }
    },
    onClickSave() {
      this.$log.log("onClickSave() ", this.productInfo); //TODO
      this.$v.$touch();
      if (this.isValid) {
        // 작품 전시
        if (this.productInfo.display === 'true') {
          let isOk = false;
          if (this.productInfo.productFileType === 'video') {
            if (!this.productInfo.displayPoster ||
              !this.productInfo.displayCard ||
              !this.productInfo.displayCardFile ||
              !this.productInfo.displayDetail ||
              !this.productInfo.displayDetailFile) {
              isOk = false;
            } else {
              isOk = true;
            }
          } else if (this.productInfo.productFileType === 'image') {
            if (!this.productInfo.displayCard ||
              !this.productInfo.displayCardFile ||
              !this.productInfo.displayDetail ||
              !this.productInfo.displayDetailFile) {
              isOk = false;
            } else {
              isOk = true;
            }
          }
          if (!isOk) {
            alert('[갤러리]에 "공개"인 경우, [작품 전시]는 필수 정보입니다.');
            return false;
          }
        }

        // 공개일(예약)
        if (this.productInfo.displayNow === "false") {
          let displayDatetime = "";
          if (this.productInfo.displayDate !== null) {
            displayDatetime = this.productInfo.displayDate;
          }
          if (this.productInfo.displayDateHour !== null) {
            displayDatetime = displayDatetime.concat(
              " ",
              this.productInfo.displayDateHour
            );
          }
          if (this.productInfo.displayDateMinute !== null) {
            displayDatetime = displayDatetime.concat(
              ":",
              this.productInfo.displayDateMinute
            );
          }
          this.productInfo.displayDatetime = displayDatetime;
        }

        if(this.productInfo.productCoinPrice > 0){
          this.productInfo.productCoinCurrency = "sat"
        }

        // 작품 정보 저장
        this.submitted = false;
        this.$refs["message-modal"].show();
        if (this.idxProduct && this.idxProduct !== "0") {
          // 작품 수정
          this.updateProduct(this.productInfo).then(() => {
            // 작품파일 수정
            this.updateProductResourceFile(this.idxProduct);
          });
        } else {
          // 작품 등록
          this.addProduct(this.productInfo).then(data => {
            // 작품파일 등록
            this.updateProductResourceFile(data.idx);
          });
        }
      }
    },
    updateProductResourceFile(idxProduct) {
      // 작품 파일 업로드
      let formData = new FormData();
      formData.append("idxProduct", idxProduct);
      if (this.file.image !== null) {
        formData.append("imageFile", this.file.image);
      }
      if (this.file.preview !== null) {
        formData.append("previewFile", this.file.preview);
      }
      if (this.file.original !== null) {
        formData.append("originalFile", this.file.original);
      }
      formData.append(
        "imageInfo",
        JSON.stringify(this.productResourceInfo.image)
      );
      formData.append(
        "previewInfo",
        JSON.stringify(this.productResourceInfo.preview)
      );
      formData.append(
        "originalInfo",
        JSON.stringify(this.productResourceInfo.original)
      );

      this.updateProductResource(formData)
        .then(() => {
          this.message = "저장되었습니다."
          this.submitted = true;
        })
        .catch(error => {
          this.message = "작품파일 업로드 오류가 발생하였습니다. 관리자에게 문의하세요.";
          this.submitted = true;
          this.$log.error(error);
        });
    },
    onClickImageFile() {
      const file = this.$refs["image-file"].files[0];
      this.file.image = null;

      if (file) {
        // 50MB 이하, jpg, jpeg
        // if (!file.type.startsWith("image/") || file.size > 1024 * 1024 * 50) {
        // 100MB 잠시
        if (!file.type.startsWith("image/") || file.size > 1024 * 1024 * 100) {
          alert("[작품 이미지] 파일크기 또는 종류를 확인하세요.");
          this.$nextTick(() => {
            this.$refs["image-file"].reset();
          });
        } else {
          this.file.image = file;
        }
      }
    },
    onClickPreviewFile() {
      const file = this.$refs["preview-file"].files[0];
      this.file.preview = null;

      if (file) {
        // 50MB 이하
        if (!file.type.startsWith("video/") || file.size > 1024 * 1024 * 50) {
          alert("[작품 영상] 파일크기 또는 종류를 확인하세요.");
          this.$nextTick(() => {
            this.$refs["preview-file"].reset();
          });
        } else {
          this.file.preview = file;
        }
      }
    },
    onClickOriginalFile() {
      const file = this.$refs["original-file"].files[0];
      this.file.original = null;

      if (file) {
        if (!file.type.startsWith("video/")) {
          alert("[작품 파일(대용량)] 파일종류를 확인하세요.");
          this.$nextTick(() => {
            this.$refs["original-file"].reset();
          });
        } else {
          this.file.original = file;
        }
      }
    },
    onClickProductDisplay(type) {
      if (type === "PRODUCT-POSTER") {
        const key = "IMAGE-" + this.productInfo.displayPoster;
        this.productInfo.displayPosterUrl = this.productDisplayMap.get(key);
        this.$log.log(key, this.productInfo.displayPosterUrl);
      } else if (type === "PRODUCT-CARD") {
        const key =
          this.productInfo.displayCard + "-" + this.productInfo.displayCardFile;
        this.productInfo.displayCardUrl = this.productDisplayMap.get(key);
        this.$log.log(key, this.productInfo.displayCardUrl);
      } else if (type === "PRODUCT-DETAIL") {
        const key =
          this.productInfo.displayDetail +
          "-" +
          this.productInfo.displayDetailFile;
        this.productInfo.displayDetailUrl = this.productDisplayMap.get(key);
        this.$log.log(key, this.productInfo.displayDetailUrl);
      }
    },
    checkIfValid(fieldName) {
      const field = this.$v.productInfo[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    hideMessageModal(){
      this.$refs["message-modal"].hide();
      this.$router.push("/management/product-list");
    }
  }
};
</script>
