<template>
  <div id="Artists">
    <article class="trending-artists">
      <section class="top">
        <p class="title">{{ $t("artist.trendingArtists") }}</p>
        <div class="button-wrap">
          <Button :onClick="onClickApply" :label="$t('artist.button.apply')" />
        </div>
      </section>

      <section
        class="trending_artists_slide artists-wrap"
        v-if="
          trendingArtistItems !== undefined &&
          trendingArtistItems !== null &&
          trendingArtistItems.length > 0
        "
      >
        <slick :options="_slickOptions">
          <template v-for="(item, i) in trendingArtistItems">
            <div
              class="trending-artist-card"
              @click.stop="onClickArtist(item.idxMember)"
              :key="i"
            >
              <section
                class="trending-artist-card__image"
                @contextmenu.prevent.stop
              >
                <div class="image-box">
                  <b-img :src="item.artworkImageUrl"></b-img>
                </div>
              </section>
              <section class="trending-artist-card__description">
                <section class="artist-name">
                  <p class="name">
                    <template
                      v-if="
                        activeLanguage !== undefined && activeLanguage === 'ko'
                      "
                    >
                      {{ item.artistName || item.artistNameEn }}
                    </template>
                    <template v-else>
                      {{ item.artistNameEn || item.artistName }}
                    </template>
                  </p>

                  <div class="skills">
                    <p v-for="(skill, i) in item.skills" :key="'skils' + i">
                      #{{ replaceSkilltoText(skill) }}
                    </p>
                  </div>
                </section>
                <section class="stats">
                  <div class="item">
                    <p class="type">DROPS</p>
                    <p class="value">{{ item.artworks }}</p>
                  </div>
                  <div class="item">
                    <p class="type">TOTAL VIEWS</p>
                    <p class="value">{{ item.viewers | currency }}</p>
                  </div>
                  <div class="item">
                    <p class="type">TOTAL VOLUME</p>
                    <p class="value">
                      {{ item.volume | currency }}{{ item.volumeUnit }}
                    </p>
                  </div>
                </section>
              </section>
            </div>
          </template>
        </slick>
      </section>
    </article>

    <article class="artists">
      <div class="artists__wrapper">
        <div class="search">
          <section class="search__input">
            <img src="/img/threespace/icon/search_icon.svg" />
            <input
              type="text"
              placeholder="Search..."
              autocomplete="off"
              v-model="externalParams.searchKeyword"
              @keypress.enter.prevent="onClickSearch"
            />
            <div
              style="
                position: absolute;
                right: 1%;
                top: 50%;
                transform: translateY(-50%);
                width: 16px;
                height: 16px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
              "
              v-if="externalParams.searchKeyword !== ''"
              @click="onClickResetFilterKeyword"
            >
              <img src="/img/threespace/icon/drop-x.png" />
            </div>
            <!-- <div
              class="reset"
              @click="onClickResetFilterKeyword"
              v-if="externalParams.searchKeyword !== ''"
            >
              <img src="/img/threespace/icon/search_reset_icon.png" />
            </div> -->
          </section>
          <div class="search__button">
            <Button :onClick="onClickSearch" label="Search" />
          </div>
          <b-button class="sort-btn" @click.stop="showSortByFilter">
            {{
              Object.keys(sortSelected).length > 0
                ? sortSelected.text
                : $t("product.sortBy")
            }}
            <img
              src="/img/threespace/icon/chevron.svg"
              :style="{
                transform: visibleSortBy ? 'rotate(180deg)' : 'rotate(0deg)',
              }"
            />
          </b-button>
          <div
            class="sort-list"
            :class="{ open: visibleSortBy }"
            v-click-outside:showSortByFilter="false"
          >
            <template v-for="(item, i) in sortOptions">
              <div
                :key="i"
                class="sort-item"
                :class="{ active: sortSelected.text === item.text }"
                @click="onClickSortByItem(item)"
              >
                {{ item.text }}
              </div>
            </template>
          </div>
        </div>
        <transition-group name="artists" tag="div" class="artists-section">
          <article
            class="artist-card"
            v-for="(item, i) in artistItemsContent"
            :key="'artistItem' + i"
          >
            <section
              class="image-box"
              @click.stop="onClickArtist(item.idxMember)"
              @contextmenu.prevent.stop
            >
              <b-img :src="item.artworkImageUrl"></b-img>
            </section>
            <section class="description">
              <section
                class="profile"
                @click.stop="onClickArtist(item.idxMember)"
              >
                <p class="name">
                  <template
                    v-if="
                      activeLanguage !== undefined && activeLanguage === 'ko'
                    "
                  >
                    {{ item.artistName || item.artistNameEn }}
                  </template>
                  <template v-else>
                    {{ item.artistNameEn || item.artistName }}
                  </template>
                </p>

                <div class="skill">
                  <p v-for="(skill, i) in item.skills" :key="'skills' + i">
                    #{{ replaceSkilltoText(skill) }}
                  </p>
                </div>
              </section>
              <section class="stats">
                <div class="item">
                  <p
                    class="type"
                    @click.stop="onClickFollowers(item.idxMember)"
                  >
                    Followers
                  </p>
                  <p class="value">{{ item.followers | currency }}</p>
                </div>
                <div class="item">
                  <p
                    class="type"
                    @click.stop="onClickFollowing(item.idxMember)"
                  >
                    Following
                  </p>
                  <p class="value">{{ item.following | currency }}</p>
                </div>
              </section>
              <div class="btn-container">
                <Button
                  :onClick="() => onClickArtistFollow(item)"
                  :label="
                    item.isFollow !== undefined && item.isFollow
                      ? 'UNFOLLOW'
                      : 'FOLLOW'
                  "
                />
                <!-- <button
                  :id="'follow-' + i"
                  class="follow-btn"
                  @click.stop="onClickArtistFollow(item)"
                  :class="{ followed: item.isFollow }"
                >
                  {{
                    item.isFollow !== undefined && item.isFollow
                      ? "UNFOLLOW"
                      : "FOLLOW"
                  }} -->
                </button>
              </div>
            </section>
          </article>
        </transition-group>
        <scroll-loader
          :loader-method="requestArtistItems"
          :loader-disable="isLast"
        >
          <svg
            data-v-c2de8a26=""
            viewBox="25 25 50 50"
            class="loader__svg"
            style="width: 50px"
          >
            <circle
              data-v-c2de8a26=""
              cx="50"
              cy="50"
              r="20"
              class="loader__circle"
              style="stroke: rgb(204, 204, 204)"
            ></circle>
          </svg>
        </scroll-loader>
      </div>
    </article>

    <FollowModal ref="followModal" />
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const artistHelper = createNamespacedHelpers("artist");
const codeHelper = createNamespacedHelpers("code");
const authHelper = createNamespacedHelpers("auth");

import Slick from "vue-slick";
import "slick-carousel/slick/slick.css";

import FollowModal from "@/views/threespace/components/follow/FollowModal";
import Button from "@/views/threespace/components/common/Button.vue";

export default {
  name: "Artists",
  components: {
    Slick,
    FollowModal,
    Button,
  },
  // metaInfo() {
  //   return {
  //     title: this.$t("meta.artist.title") + " | 3space Art",
  //     meta: [
  //       { vmid: "title", name: "title", content: this.$t("meta.artist.title") + " | 3space Art" },
  //       { vmid: "description", name: "description", content: this.$t("meta.artist.description") },
  //       { vmid: "og:title", property: "og:title", content: this.$t("meta.artist.title") + " | 3space Art" },
  //       { vmid: "og:description", property: "og:description", content: this.$t("meta.artist.description") },
  //       { vmid: "og:url", property: "og:url", content: window.location.origin + this.$route.path }
  //     ]
  //   }
  // },
  data() {
    return {
      slickOptions: {
        dots: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,
        speed: 500,
        responsive: [
          {
            breakpoint: 1279,
            settings: {
              dots: false,
              infinite: true,
              // centerMode: true,
              slidesToShow: 2,
              slidesToScroll: 1,
              autoplay: false,
              speed: 500,
            },
          },
          {
            breakpoint: 960,
            settings: {
              dots: false,
              infinite: true,
              // centerMode: true,
              slidesToShow: 1,
              slidesToScroll: 1,
              autoplay: false,
              speed: 500,
            },
          },
        ],
      },
      isBusy: false,
      perPage: 8,
      isLast: true,
      currentPage: 0,
      sort: this.getInitSort(),
      sortSelected: {},
      sortOptions: [
        {
          value: { id: "artistStat.nftMintDate", desc: "desc" },
          text: this.$t("artist.sortByNewest"),
        },
      ],
      externalParams: {
        searchKeyword: "",
      },
      visibleSortBy: false,
    };
  },
  computed: {
    ...artistHelper.mapState([
      "trendingArtistItems",
      "artistItems",
      "artistItemsContent",
    ]),
    ...codeHelper.mapState(["commonCodes"]),
    ...authHelper.mapState(["me"]),
    activeLanguage() {
      return this.getActiveLanguage();
    },
    isMobile() {
      return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    },
    _slickOptions() {
      return this.slickOptions;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.requestTrendingArtistItems();
      this.requestChildCodes(["ARTIST_SKILL"]);
    });
  },
  directives: {
    "click-outside": {
      bind: function (el, binding, vnode) {
        // create event
        el.eventClickOutside = function (event) {
          if (!(el == event.target || el.contains(event.target))) {
            vnode.context[binding.arg](binding.value);
          }
        };
        // event binding
        document.body.addEventListener("click", el.eventClickOutside);
      },
      unbind: function (el) {
        document.body.removeEventListener("click", el.eventClickOutside);
      },
    },
  },
  methods: {
    ...artistHelper.mapActions([
      "getTrendingArtists",
      "getArtists",
      "updateArtistFollow",
    ]),
    ...codeHelper.mapActions(["getChildMultiCodes"]),
    ...authHelper.mapActions(["verifyTokenCallback"]),
    requestChildCodes(codes) {
      this.getChildMultiCodes(codes).then(() => {
        this.requestArtistItems();
      });
    },
    requestTrendingArtistItems() {
      this.isBusy = true;
      this.getTrendingArtists()
        .then(() => {
          this.isBusy = false;
        })
        .catch((error) => {
          this.isBusy = false;
          this.$log.error(error);
        });
    },
    requestArtistItems() {
      this.isBusy = true;
      this.getArtists(
        this.getPagingParams(
          {
            page: this.currentPage,
            pageSize: this.perPage,
            sorted: this.sort,
            filtered: [],
          },
          this.externalParams
        )
      )
        .then((data) => {
          this.isLast = data.last;
          this.currentPage = data.pageable.page + 1;
          this.isBusy = false;
        })
        .catch((error) => {
          this.isBusy = false;
          this.$log.error(error);
        });
    },
    getPagingParams({ page, pageSize, sorted, filtered }, externalParams) {
      const params = {
        page,
        size: pageSize,
        ...externalParams,
      };

      // sorting parameter
      let sorts = [];
      for (let i = 0, length = sorted.length; i < length; i++) {
        const sort = sorted[i];
        sorts.push(`${sort.id},${sort.desc ? "desc" : "asc"}`);
      }
      params["sort"] = sorts;

      // filtering parameter
      for (let i = 0, length = filtered.length; i < length; i++) {
        const filter = filtered[i];
        params[filter.id] = filter.value;
      }
      return params;
    },
    onClickSearch() {
      this.isLast = true;
      this.currentPage = 0;
      this.requestArtistItems();
    },
    onClickResetFilter() {
      this.externalParams.searchKeyword = "";
      this.sort = this.getInitSort();
      this.sortSelected = {};
      this.onClickSearch();
    },
    onClickResetFilterKeyword() {
      this.externalParams.searchKeyword = "";
      this.onClickSearch();
    },
    onClickSortByItem(item) {
      this.sort[0] = item.value;
      this.visibleSortBy = false;
      this.isLast = true;
      this.currentPage = 0;
      this.sortSelected = item;
      this.requestArtistItems();
    },
    showSortByFilter(value) {
      if (typeof value === "boolean") this.visibleSortBy = value;
      else {
        this.visibleSortBy = !this.visibleSortBy;
      }
    },
    onClickArtist(idxMember) {
      // this.$router.push("/artist/" + idxArtist);
      let idxEncode = window.btoa(idxMember);
      this.$router.push("/collections/" + idxEncode);
    },
    onClickApply() {
      let routeUrl = this.$router.resolve({ path: "/apply" });
      window.open(routeUrl.href, "_blank");
    },
    onClickArtistFollow(item) {

      this.verifyTokenCallback()
        .then(() => {
          if (
            this.me === undefined ||
            this.me === null ||
            this.me.idx === undefined ||
            this.me.idx === null ||
            this.me.idx === ""
          ) {
            // 로그인
            this.$store.commit("setCommonAlertModalInfo", {
              msgHtml: this.$t("artist.requiredLogin"),
              show: true,
              fontSize: "sm",
            });
          } else {
            // Follow/Unfollow
            const follow =
              item.isFollow !== undefined && item.isFollow === true
                ? false
                : true;
            const param = { idx: item.idx, follow: follow, referral: null }; // TODO
            this.updateArtistFollow(param)
              .then((data) => {
                if (
                  Object.keys(data).length !== 0 &&
                  JSON.stringify(data) !== JSON.stringify({})
                ) {
                  item.isFollow = data.isFollow;
                  item.followers = data.followers;
                  item.following = data.following;
                }
              })
              .catch((error) => {
                this.$log.error(error);
                alert(this.$t("artist.failTryAgain"));
              });
          }
        })
        .catch((error) => {
          this.$log.error(error);
          // 로그인
          this.$store.commit("setCommonAlertModalInfo", {
            msgHtml: this.$t("artist.requiredLogin"),
            show: true,
            fontSize: "sm",
          });
        });
    },
    onClickFollowers(_idxMember) {
      this.$refs["followModal"].show(0, _idxMember);
    },
    onClickFollowing(_idxMember) {
      this.$refs["followModal"].show(1, _idxMember);
    },
    replaceArtistSkillKo(skills) {
      if (skills !== undefined && skills !== null && skills.length > 0) {
        let skillTexts = new Array();
        skills.forEach((item) => {
          try {
            skillTexts.push(
              this.commonCodes["ARTIST_SKILL"].find(
                (o) => o.value === item.codeValue
              ).text
            );
          } catch (error) {
            this.$log.error(error);
          }
        });
        return skillTexts.join(", ");
      }
      return "";
    },
    replaceSkilltoText(skill) {
      return this.commonCodes["ARTIST_SKILL"].find(
        (o) => o.value === skill.codeValue
      ).text;
    },
    getInitSort() {
      return [
        { id: "updatedAt", desc: "desc" },
        { id: "idx", desc: "desc" },
      ];
    },
    getActiveLanguage() {
      return localStorage.getItem("language") || "en";
    },
  },
  filters: {
    currency: (value) => {
      if (!value) return 0;
      return value.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    },
  },
};
</script>
