import ApiService from "@/services/api.service";
import logger from "@/logger";

// action types
export const GET_FEATURED_PRODUCT = "getFeaturedProduct";
export const GET_FEATURED_PRODUCT_LIST = "getFeaturedProducts";
export const GET_GALLERY_PRODUCTS = "getGalleryProducts";
export const GET_GALLERY_PRODUCTS_DROPS = "getGalleryProductsDrops";
export const GET_GALLERY_PRODUCTS_HOME = "getGalleryProductsHome";
export const GET_GALLERY_PRODUCTS_RANDOM = "getGalleryProductRandom"
export const GET_GALLERY_PRODUCT = "getGalleryProduct";
export const GET_GALLERY_ARTIST = "getGalleryArtist";
export const GET_GALLERY_ARTIST_RECENT_ARTS = "getGalleryArtistRecentArts";
export const GET_GALLERY_PRODUCT_PAYMENT = "getPaymentProduct";
export const GET_GALLERY_PRODUCT_DETAIL = "getDetailProduct";
export const GET_ARTWORK_TOTAL_COUNT = "getArtworkTotalCount";
export const GET_ARTWORK_FILE = "getArtworkFile";

export const SET_GALLERY_PREVIOUS_PARAMS = "setGalleryPreviousParams";

// mutation types
export const SET_FEATURED_PRODUCT = "setFeaturedProduct";
export const SET_FEATURED_PRODUCT_LIST = "setFeaturedProducts";
export const SET_GALLERY_PRODUCTS = "setGalleryProducts";
export const SET_GALLERY_PRODUCTS_HOME = "setGalleryProductsHome";
export const SET_GALLERY_PRODUCTS_RANDOM = "setGalleryProductsRandom";
export const SET_GALLERY_PRODUCT = "setGalleryProduct";
export const SET_GALLERY_ARTIST = "setGalleryArtist";
export const SET_GALLERY_ARTIST_RECENT_ARTS = "setGalleryArtistRecentArts";
export const RESET_GALLERY_PRODUCTS = "resetGalleryProducts";
export const SET_GALLERY_PRODUCT_PAYMENT = "setPaymentProduct";
export const SET_GALLERY_PRODUCT_DETAIL = "setDetailProduct";
export const SET_ARTWORK_TOTAL_COUNT = "setArtworkTotalCount";
export const SET_ARTWORK_FILE = "setArtworkFile";

const state = {
  errors: null,
  artistInfo: {},
  featuredProductItems: [],
  galleryProductItems: {
    content: [],
    first: true,
    last: false,
    number: 0,
    numberOfElements: 0,
    size: 0,
    sort: {
      orders: []
    },
    total: 0,
    totalElements: 0,
    totalPages: 1
  },
  galleryProductHomeItems: [],
  galleryProductHomeItemsRandom: [],
  galleryProductItemsContent: [],
  galleryArtistRecentArts: [],
  galleryProductInfo: {},
  paymentProductInfo: {},
  featuredProductItem: {},
  previousParams: {},
  artworkTotalCount: 0,
  artworkFileInfo: {}
};

const getters = {};

const actions = {
  [GET_FEATURED_PRODUCT](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("/threespace/v1/gallery/products/featured/main", payload)
        .then(({ data }) => {
          context.commit(SET_FEATURED_PRODUCT, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [GET_FEATURED_PRODUCT_LIST](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("/threespace/v1/gallery/products/featured", payload)
        .then(({ data }) => {
          context.commit(SET_FEATURED_PRODUCT_LIST, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [GET_GALLERY_PRODUCTS](context, payload) {
    if (payload.page === 0) {
      context.commit(RESET_GALLERY_PRODUCTS);
    }
    return new Promise((resolve, reject) => {
      ApiService.get("/threespace/v1/gallery/products", payload)
        .then(({ data }) => {
          context.commit(SET_GALLERY_PRODUCTS, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [GET_GALLERY_PRODUCTS_DROPS](context, payload) {
    if (payload.page === 0) {
      context.commit(RESET_GALLERY_PRODUCTS);
    }
    return new Promise((resolve, reject) => {
      ApiService.get("/threespace/v1/gallery/products/drops", payload)
        .then(({ data }) => {
          context.commit(SET_GALLERY_PRODUCTS, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [GET_GALLERY_PRODUCTS_HOME](context) {
    return new Promise((resolve, reject) => {
      ApiService.get("/threespace/v1/gallery/products/home")
        .then(({ data }) => {
          context.commit(SET_GALLERY_PRODUCTS_HOME, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [GET_GALLERY_PRODUCTS_RANDOM](context) {
    return new Promise((resolve, reject) => {
      ApiService.get("/threespace/v1/gallery/products/home/random")
        .then(({ data }) => {
          context.commit(SET_GALLERY_PRODUCTS_RANDOM, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [GET_GALLERY_PRODUCT](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("/threespace/v1/gallery/products/" + payload)
        .then(({ data }) => {
          context.commit(SET_GALLERY_PRODUCT, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [GET_GALLERY_ARTIST](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("/threespace/v1/gallery/artists/" + payload)
        .then(({ data }) => {
          resolve(data.data);
          context.commit(SET_GALLERY_ARTIST, data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [GET_GALLERY_ARTIST_RECENT_ARTS](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("/threespace/v1/gallery/artists/recent/arts/" + payload)
        .then(({ data }) => {
          resolve(data.data);
          context.commit(SET_GALLERY_ARTIST_RECENT_ARTS, data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [GET_GALLERY_PRODUCT_PAYMENT](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("/threespace/v1/gallery/products/payment/" + payload)
        .then(({ data }) => {
          context.commit(SET_GALLERY_PRODUCT_PAYMENT, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [GET_GALLERY_PRODUCT_DETAIL](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("/threespace/v1/gallery/products/detail/" + payload)
        .then(({ data }) => {
          context.commit(SET_GALLERY_PRODUCT_DETAIL, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [GET_ARTWORK_TOTAL_COUNT](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("/threespace/v1/gallery/products/total-count", payload)
        .then(({ data }) => {
          context.commit(SET_ARTWORK_TOTAL_COUNT, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [GET_ARTWORK_FILE](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("/threespace/v1/gallery/artwork/" + payload)
        .then(({ data }) => {
          context.commit(SET_ARTWORK_FILE, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [SET_GALLERY_PREVIOUS_PARAMS](context, payload) {
    context.commit(SET_GALLERY_PREVIOUS_PARAMS, payload);
  },
};

const mutations = {
  [SET_FEATURED_PRODUCT](state, product) {
    state.featuredProductItem = product;
  },
  [SET_FEATURED_PRODUCT_LIST](state, productList) {
    state.featuredProductItems = productList;
  },
  [SET_GALLERY_PRODUCTS](state, productList) {
    state.galleryProductItems = productList;
    state.galleryProductItemsContent.push(...productList.content);
  },
  [SET_GALLERY_PRODUCTS_HOME](state, galleryProductHomeItems) {
    state.galleryProductHomeItems = galleryProductHomeItems;
  },
  [SET_GALLERY_PRODUCTS_RANDOM](state, galleryProductHomeItemsRandom) {
    state.galleryProductHomeItemsRandom = galleryProductHomeItemsRandom;
  },
  [SET_GALLERY_PRODUCT](state, productInfo) {
    state.galleryProductInfo = productInfo;
  },
  [SET_GALLERY_ARTIST](state, artistInfo) {
    state.artistInfo = artistInfo;
  },
  [SET_GALLERY_ARTIST_RECENT_ARTS](state, galleryArtistRecentArts) {
    state.galleryArtistRecentArts = galleryArtistRecentArts;
  },
  [RESET_GALLERY_PRODUCTS](state) {
    state.galleryProductItemsContent = [];
    state.galleryProductItems.content = [];
  },
  [SET_GALLERY_PRODUCT_PAYMENT](state, productInfo) {
    state.paymentProductInfo = productInfo;
  },
  [SET_GALLERY_PRODUCT_DETAIL](state, productInfo) {
    state.galleryProductInfo = productInfo;
  },
  [SET_ARTWORK_TOTAL_COUNT](state, totalCount) {
    state.artworkTotalCount = totalCount+6368;
  },
  [SET_ARTWORK_FILE](state, artworkInfo) {
    state.artworkFileInfo = artworkInfo;
  },
  [SET_GALLERY_PREVIOUS_PARAMS](state, previousParams) {
    state.previousParams = previousParams;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
