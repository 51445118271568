import CaverExtKAS from "caver-js-ext-kas";
import { Network, Alchemy } from 'alchemy-sdk';


export default {
  data() {
    return {
      pageKey: "",
      isBusyKlaytn: false,
      isBusyEthereum: false,
      isBusyPolygon: false,
      klaytnNftCollectionList: [],
      ethereumNftCollectionList: [],
      polygonNftCollectionList: [],
      klaytnWhiteList: [
        "0xa9a95c5fef43830d5d67156a2582a2e793acb465", // klip drops
        process.env.VUE_APP_KLIP_NFT_ADDRESS,
        process.env.VUE_APP_SHINHAN_NFT_ADDRESS
      ],
      klaytnNftCollectionItem: {},

      // alchemy 사용법 변경 ===============
      alchemyEthSettings: {
        apiKey: process.env.VUE_APP_ALCHEMY_ETHEREUM_API_KEY,
        network: process.env.NODE_ENV === "production" ? Network.ETH_MAINNET : Network.ETH_GOERLI, // Replace with your network.
      },
      alchemyPolySettings: {
        apiKey: process.env.VUE_APP_ALCHEMY_POLYGON_API_KEY,
        network: process.env.NODE_ENV === "production" ? Network.MATIC_MAINNET : Network.MATIC_MUMBAI, // Replace with your network.
      }
    };
  },
  methods: {
    // 이더리음 NFT 불러오기 ===============================
    async getEthereumNfts(address) {
      this.isBusyEthereum = true
      const alchemy = new Alchemy(this.alchemyEthSettings);
      for await (const nft of alchemy.nft.getNftsForOwnerIterator(address)) {
        this.ethereumNftCollectionList.push(nft)
      }
      this.isBusyEthereum = false
    },
    // 폴리곤 NFT 불러오기 ===============================
    async getPolygonNfts(address) {
      this.isBusyPolygon = true
      const alchemy = new Alchemy(this.alchemyPolySettings);
      for await (const nft of alchemy.nft.getNftsForOwnerIterator(address)) {
        this.polygonNftCollectionList.push(nft)
      }
      this.isBusyPolygon = false
    },
    // 클레이튼 NFT 가져오기  ===============================
    getKlaytnNfts(address) {
      this.klaytnNftCollectionList = [];
      this.isBusyKlaytn = true;
        
      // caver ext kas Setting
      const caver = new CaverExtKAS()
      caver.initTokenHistoryAPI(
        process.env.VUE_APP_KLAYTN_CHAIN_ID, 
        process.env.VUE_APP_KAS_ACCESS_KEY_ID, 
        process.env.VUE_APP_KAS_SECRET_ACCESS_KEY)
  
      const transferQuery = {
        kind: [caver.kas.tokenHistory.queryOptions.kind.NFT],
        size: 1000
      }
  
      // All Transfer Hitory
      caver.kas.tokenHistory.getTransferHistoryByAccount(address, transferQuery).then((data) => {
        let contracts = data.items.map(el => el.contract.address);
        const set = new Set(contracts);
        contracts = [...set];
        this.$log.info("[Unique Contract Array] :", contracts)
        if (contracts.length > 0) {
          this.loopKlaytnNfts(address, contracts);
        } else {
          this.isBusyKlaytn = false;
        }
      })
    },
    async loopKlaytnNfts(address, contracts) {
      // caver ext kas Setting
      const caver = new CaverExtKAS()
      caver.initTokenHistoryAPI(
        process.env.VUE_APP_KLAYTN_CHAIN_ID, 
        process.env.VUE_APP_KAS_ACCESS_KEY_ID, 
        process.env.VUE_APP_KAS_SECRET_ACCESS_KEY)

      const nftListQuery = {
        size: 1000,
        cursor: ''
      }
      for (const [i, contract] of contracts.entries()) {
        if (this.klaytnWhiteList.findIndex(el => el === contract) !== -1) {
          await caver.kas.tokenHistory.getNFTListByOwner(contract, address, nftListQuery)
            .then(nftListData => {
              if (nftListData.items.length > 0) {
                caver.kas.tokenHistory.getNFTContract(contract)
                  .then(contractData => {
                    let nftObj = {};
                    nftObj.nft = contractData;
                    nftObj.collections = nftListData.items;
                    this.klaytnNftCollectionList.push(nftObj);
                    if (i === contracts.length - 1) {
                      this.isBusyKlaytn = false;
                    }
                  })
                  .catch(() => {
                    // 저장 못함
                    this.$log.info("getNFTContract 실패")
                    if (i === contracts.length - 1) {
                      this.isBusyKlaytn = false;
                    }
                  });
              }
            })
            .catch(() => {
              // 저장 못함
              this.$log.info("getNftListByOwner 실패")
              if (i === contracts.length - 1) {
                this.isBusyKlaytn = false;
              }
            });
        }
        if (i === contracts.length - 1) {
          this.isBusyKlaytn = false;
        }
      }
    },
  }
};