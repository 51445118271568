<template>
  <div>
    <section class="mypage-form profile">
      <section class="left">
        <!-- 이름 -->
        <section class="items">
          <p class="title">{{ $t("mypage.name") }}</p>
          <div class="input-box">
            <CInput
              class="mb-0"
              v-model="memberInfo.name"
              :disabled="memberInfo.identityVerify === true || isCorporation"
            />
          </div>
        </section>

        <!-- 사용자이름 (닉네임) -->
        <section class="items">
          <p class="title">
            {{ $t("mypage.nickname") }}&nbsp;<span class="text-danger">*</span>
          </p>
          <div class="input-box">
            <CInput
              class="mb-0"
              :lazy="false"
              :value.sync="$v.memberInfo.nickname.$model"
              :isValid="checkIfValid('nickname')"
              :invalidFeedback="$t('mypage.validMsgUsername')"
              :disabled="isCorporation"
            />
          </div>
        </section>
        <!-- 이메일계정 -->
        <section class="items">
          <p class="title">{{ $t("mypage.email") }}</p>
          <div class="input-box">
            <CInput
              class="mb-0"
              :value.sync="$v.memberInfo.email.$model"
              :isValid="checkIfValid('email')"
              :invalidFeedback="$t('mypage.validMsgEmail')"
              :disabled="memberInfo.snsType !== 'WALLET' || isCorporation"
            >
              <!-- <template #append>
                <div class="sns_type" :class="memberInfo.snsType">
                  <template v-if="memberInfo.snsType == 'NAVER'">
                    <CImg
                      src="/img/threespace/company/naver_logo.png"
                      class="naver_logo"
                    />
                  </template>
                  <template v-if="memberInfo.snsType == 'KAKAO'">
                    <CImg
                      src="/img/threespace/company/kakao_logo.png"
                      class="kakao_logo"
                    />
                  </template>
                  <template v-if="memberInfo.snsType == 'GOOGLE'">
                    <CImg
                      src="/img/threespace/company/google_logo.png"
                      class="google_logo"
                    />
                  </template>
                </div>
              </template> -->
            </CInput>
          </div>
        </section>
        <!-- 연락처 -->
        <section class="items">
          <p class="title">{{ $t("mypage.mobile") }}</p>
          <div class="input-box">
            <CInput
              class="mb-0"
              :lazy="false"
              :value.sync="$v.memberInfo.mobile.$model"
              :isValid="checkIfValid('mobile')"
              :invalidFeedback="$t('mypage.validMsgMobile')"
              :disabled="memberInfo.identityVerify === true || isCorporation"
            >
            </CInput>
            <div class="input-btn">
              <Button
                :onClick="showIdentityVerify"
                :label="$t('mypage.identityVerify')"
                :disabled="isCorporation"
              />
            </div>
            <form name="niceAuthForm" id="niceAuthForm">
              <input type="hidden" id="m" name="m" value="service" />
              <input
                type="hidden"
                id="token_version_id"
                name="token_version_id"
                value=""
              />
              <input type="hidden" id="enc_data" name="enc_data" />
              <input
                type="hidden"
                id="integrity_value"
                name="integrity_value"
              />
            </form>
          </div>
        </section>
        <!-- 비트코인 지갑주소 -->
        <section class="items">
          <template
            v-if="
              memberInfo.snsType !== undefined &&
              (memberInfo.snsType !== 'WALLET' ||
                (memberInfo.snsType === 'WALLET' &&
                  memberInfo.btcAddr !== undefined &&
                  memberInfo.btcAddr !== ''))
            "
          >
            <p class="title">{{ $t("mypage.btcWallet") }}</p>
            <div class="input-box">
              <CInput
                class="mb-0"
                v-model="memberInfo.btcAddr"
                :readonly="true"
              />

              <div
                class="input-btn"
                v-if="
                  memberInfo.snsType !== undefined &&
                  memberInfo.snsType !== 'WALLET'
                "
              >
                <Button
                  :onClick="() => showWalletPopupModal('Btc')"
                  :label="$t('mypage.connectWallet')"
                  :disabled="isCorporation"
                />
              </div>
            </div>
          </template>
        </section>
        <!-- 이더리움 지갑주소 -->
        <section class="items">
          <template
            v-if="
              memberInfo.snsType !== undefined &&
              (memberInfo.snsType !== 'WALLET' ||
                (memberInfo.snsType === 'WALLET' &&
                  memberInfo.ethereumAddr !== undefined &&
                  memberInfo.ethereumAddr !== ''))
            "
          >
            <p class="title">{{ $t("mypage.ethereumWallet") }}</p>
            <div class="input-box">
              <CInput
                class="mb-0"
                v-model="memberInfo.ethereumAddr"
                :readonly="true"
              />
              <div
                class="input-btn"
                v-if="
                  memberInfo.snsType !== undefined &&
                  memberInfo.snsType !== 'WALLET'
                "
              >
                <Button
                  :onClick="() => showWalletPopupModal('Ethereum')"
                  :label="$t('mypage.connectWallet')"
                  :disabled="isCorporation"
                />
              </div>
            </div>
          </template>
        </section>
        <!-- 폴리곤 지갑주소 -->
        <section class="items">
          <template
            v-if="
              memberInfo.snsType !== undefined &&
              (memberInfo.snsType !== 'WALLET' ||
                (memberInfo.snsType === 'WALLET' &&
                  memberInfo.polygonAddr !== undefined &&
                  memberInfo.polygonAddr !== ''))
            "
          >
            <p class="title">{{ $t("mypage.polygonWallet") }}</p>
            <div class="input-box">
              <CInput
                class="mb-0"
                v-model="memberInfo.polygonAddr"
                :readonly="true"
              />
              <div
                class="input-btn"
                v-if="
                  memberInfo.snsType !== undefined &&
                  memberInfo.snsType !== 'WALLET'
                "
              >
                <Button
                  :onClick="() => showWalletPopupModal('Polygon')"
                  :label="$t('mypage.connectWallet')"
                  :disabled="isCorporation"
                />
              </div>
            </div>
          </template>
        </section>
        <section class="items">
          <!-- 클레이튼 지갑주소 -->
          <template
            v-if="
              memberInfo.snsType !== undefined &&
              (memberInfo.snsType !== 'WALLET' ||
                (memberInfo.snsType === 'WALLET' &&
                  memberInfo.klaytnAddr !== undefined &&
                  memberInfo.klaytnAddr !== ''))
            "
          >
            <p class="title">{{ $t("mypage.klaytnWallet") }}</p>
            <div class="input-box">
              <CInput
                class="mb-0"
                v-model="memberInfo.klaytnAddr"
                :readonly="true"
              />
              <div
                class="input-btn"
                v-if="
                  memberInfo.snsType !== undefined &&
                  memberInfo.snsType !== 'WALLET'
                "
              >
                <Button
                  :onClick="() => showWalletPopupModal('Klaytn')"
                  :label="$t('mypage.connectWallet')"
                  :disabled="isCorporation"
                />
              </div>
            </div>
          </template>
        </section>
      </section>
      <section class="right">
        <!-- 자기소개 -->
        <section class="items">
          <p class="title">{{ $t("mypage.bio") }}</p>
          <div class="input-box textarea-box">
            <CTextarea
              rows="4"
              :lazy="false"
              v-model="memberInfo.introduce"
              :disabled="isCorporation"
            />
          </div>
        </section>
        <!-- 위치 -->
        <section class="items">
          <p class="title">{{ $t("mypage.location") }}</p>
          <div class="input-box">
            <CInput
              class="mb-0"
              v-model="memberInfo.location"
              :disabled="isCorporation"
            />
          </div>
        </section>
        <!-- 인스타그램 -->
        <section class="items">
          <p class="title">{{ $t("mypage.instagram") }}</p>
          <div class="input-box">
            <CInput
              v-model="_instagram"
              placeholder="instagram.com/"
              :disabled="isCorporation"
            />
          </div>
        </section>
        <!-- 트위터 -->
        <section class="items">
          <p class="title">{{ $t("mypage.twitter") }}</p>
          <div class="input-box">
            <CInput
              v-model="_twitter"
              placeholder="twitter.com/"
              :disabled="isCorporation"
            />
          </div>
        </section>
        <!-- 웹사이트 -->
        <section class="items" style="margin-bottom: 20px">
          <p class="title">{{ $t("mypage.website") }}</p>
          <div class="input-box">
            <CInput v-model="_website" :disabled="isCorporation" />
          </div>
        </section>
        <div class="save-button">
          <p class="text-danger text-center">{{ errorMessage }}</p>
          <!-- <CButton type="submit" @click="onClickSave" :disabled="isCorporation">
            {{ $t("mypage.save") }}
          </CButton> -->
          <Button
            :onClick="onClickSave"
            :label="$t('mypage.save')"
            :disabled="isCorporation"
          />
        </div>
      </section>
    </section>

    <!-- ETC ================================ -->
    <!-- 지갑 연결 팝업 -->
    <WalletPopup ref="walletPopupModal" @wallet-info="getWalletInfo" />

    <!-- 완료 팝업 -->
    <b-modal
      ref="save-message-modal2"
      hide-header
      hide-footer
      centered
      no-close-on-esc
      no-close-on-backdrop
      body-class="text-center text-black py-5"
    >
      <b-img
        src="/img/threespace/logo/logo_3space_color.png"
        width="64"
      ></b-img>
      <p class="my-5 font-lg">{{ completeMessage }}</p>
      <b-button
        variant="primary"
        size="lg"
        squared
        class="mt-3 px-5"
        @click="hideSaveMessageModal"
      >
        <span class="text-white">{{ $t("button.confirm") }}</span>
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import {
  required,
  minLength,
  helpers,
  maxLength,
  email,
} from "vuelidate/lib/validators";
import { createNamespacedHelpers } from "vuex";
import WalletPopup from "@/views/threespace/WalletPopup";
import Button from "@/views/threespace/components/common/Button.vue";
const profileHelper = createNamespacedHelpers("profile");
const authHelper = createNamespacedHelpers("auth");

export default {
  name: "MyPageUpdateProfile",
  components: {
    WalletPopup,
    Button,
  },
  data() {
    return {
      instagram: "",
      twitter: "",
      homepage: "",
      selectedTab: 0,
      tabs: [
        { index: 0, name: this.$t("mypage.profile") },
        { index: 1, name: this.$t("mypage.collection") },
        { index: 2, name: this.$t("mypage.payment") },
      ],
      // (Tab 1) 프로필
      errorMessage: null,
      profileImage: [],
      clipboardCopied: false,
      listOrderSelected: "LATEST",
      listOrderOptions: [
        { text: "최신순", value: "LATEST" },
        { text: "낮은가격순", value: "LOW_PRICE" },
        { text: "높은가격순", value: "HIGH_PRICE" },
      ],
      completeMessage: null,
      acceptDeleteMember: false,
      acceptErrorMessage: null,
      acceptCheckTotal: false,
      selectedWallet: null,
      walletAddress: {
        klaytn: null,
        ethereum: null,
        polygon: null,
        btc: null,
      },
    };
  },
  computed: {
    ...profileHelper.mapState(["memberInfo"]),
    ...authHelper.mapState(["me", "isCorporation"]),
    isValid() {
      return !this.$v.memberInfo.$invalid;
    },
    isDirty() {
      return this.$v.memberInfo.$anyDirty;
    },
    _instagram: {
      get: function () {
        if (
          this.memberInfo.instagram != undefined &&
          this.memberInfo.instagram !== ""
        ) {
          return "instagram.com/" + this.memberInfo.instagram;
        } else {
          return "instagram.com/";
        }
      },
      set: function (newVal) {
        let strArr = newVal.split("/");
        this.instagram = strArr[strArr.length - 1];
        this.memberInfo.instagram = strArr[strArr.length - 1];
      },
    },
    _twitter: {
      get: function () {
        if (
          this.memberInfo.twitter != undefined &&
          this.memberInfo.twitter !== ""
        ) {
          return "twitter.com/" + this.memberInfo.twitter;
        } else {
          return "twitter.com/";
        }
      },
      set: function (newVal) {
        let strArr = newVal.split("/");
        this.twitter = strArr[strArr.length - 1];
        this.memberInfo.twitter = strArr[strArr.length - 1];
      },
    },
    _website: {
      get: function () {
        if (
          this.memberInfo.homepage != undefined &&
          this.memberInfo.homepage !== ""
        ) {
          return this.memberInfo.homepage;
        } else {
          return "";
        }
      },
      set: function (newVal) {
        this.homepage = newVal;
        this.memberInfo.homepage = newVal;
      },
    },
  },
  mixins: [validationMixin],
  validations: {
    memberInfo: {
      nickname: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(15),
        strongPass: helpers.regex("strongPass", /^[a-zA-Z0-9._-]*$/),
      },
      mobile: {
        minLength: minLength(10),
        maxLength: maxLength(12),
        numbers: helpers.regex("numbers", /^[0-9]*$/),
      },
      email: { email },
      //acceptTermsService: { required, mustAccept: val => val },
      //acceptPrivacyPolicy: { required, mustAccept: val => val }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.requestMemberItem();
    });
  },
  methods: {
    ...profileHelper.mapActions([
      "getProfile",
      "updateProfile",
      "deleteMember",
      "getAuthRequestData",
    ]),
    ...authHelper.mapActions(["logout"]),
    // (Tab 1) 프로필 >>>>>
    requestMemberItem() {
      if (this.me.idx && this.me.idx !== "0") {
        this.getProfile(this.me.idx)
          .then(() => {
            //this.$log.log(this.memberInfo);//TODO
            /*
            if (this.memberInfo.mobile === undefined || this.memberInfo.mobile === "") {
              this.memberInfo.mobile = null;
            }
            if (this.memberInfo.email === undefined || this.memberInfo.email === "") {
              this.memberInfo.email = null;
            }*/
            // 주소 변경 > 중복 체크후 복원용
            this.walletAddress.klaytn = this.memberInfo.klaytnAddr || null;
            this.walletAddress.ethereum = this.memberInfo.ethereumAddr || null;
            this.walletAddress.polygon = this.memberInfo.polygonAddr || null;
            this.walletAddress.btc = this.memberInfo.btcAddr || null;
          })
          .catch((response) => {
            this.$log.error(response);
            this.$router.push("/error/500");
          });
      }
    },
    onClickSave() {
      this.errorMessage = "";

      if (this.isValid) {
        // 사용자 정보 저장
        if (this.me.idx) {
          this.updateProfile(this.memberInfo)
            .then(() => {
              this.completeMessage = this.$t("mypage.updatedProfile");
              // this.$refs["save-message-modal2"].show();
              // alert(this.$t("mypage.updatedProfile"));
              this.$router.push({
                name: "MyPageDetail",
                params: { updateComplete: true },
              });
              this.$v.$reset();
            })
            .catch((response) => {
              this.$log.error(response);
              if (response.data.error === "BAD_REQUESTED") {
                if (response.data.resource === "EXISTS_NICKNAME") {
                  this.errorMessage = this.$t("validation.exist.username");
                } else if (response.data.resource === "EXISTS_EMAIL") {
                  this.errorMessage = this.$t("validation.exist.email");
                } else if (response.data.resource === "EXISTS_KLAYTN_ADDR") {
                  this.errorMessage = this.$t(
                    "validation.exist.klaytnWalletAddr"
                  );
                  this.memberInfo.klaytnAddr = this.walletAddress.klaytn;
                } else if (response.data.resource === "EXISTS_ETHEREUM_ADDR") {
                  this.errorMessage = this.$t(
                    "validation.exist.ethereumWalletAddr"
                  );
                  this.memberInfo.ethereumAddr = this.walletAddress.ethereum;
                } else if (response.data.resource === "EXISTS_POLYGON_ADDR") {
                  this.errorMessage = this.$t(
                    "validation.exist.polygonWalletAddr"
                  );
                  this.memberInfo.polygonAddr = this.walletAddress.polygon;
                } else if (response.data.resource === "EXISTS_BTC_ADDR") {
                  this.errorMessage = this.$t("validation.exist.btcWalletAddr");
                  this.memberInfo.btcAddr = this.walletAddress.btc;
                } else if (response.data.resource === "EXISTS_WALLET_ADDR") {
                  this.memberInfo.walletAddr = null;
                  this.memberInfo.walletNetwork = null;
                  this.errorMessage = this.$t("validation.exist.walletAddr");
                }
              } else {
                this.errorMessage = this.$t("mypage.errorProfile");
              }
            });
        }
      } else {
        this.$v.$touch();
      }
    },
    showWalletPopupModal(networkType) {
      this.selectedWallet = networkType;
      this.$refs["walletPopupModal"].showChangeWallet(networkType);
    },
    getWalletInfo(data) {
      if (
        this.memberInfo.snsType !== undefined &&
        this.memberInfo.snsType !== "WALLET"
      ) {
        if (this.selectedWallet !== undefined) {
          if (this.selectedWallet === "Ethereum") {
            if (
              process.env.NODE_ENV === "production" &&
              data.chainId.toString() !== "1"
            ) {
              let msg = this.$t("validation.wallet.ethereumMainnet");
              alert(msg);
              return;
            }
            this.memberInfo.ethereumAddr = data.addr;
            this.memberInfo.ethereumProvider = data.walletProvider;
            this.$forceUpdate();
          } else if (this.selectedWallet === "Polygon") {
            this.memberInfo.polygonAddr = data.addr;
            this.memberInfo.polygonProvider = data.walletProvider;
            this.$forceUpdate();
          } else if (this.selectedWallet === "Klaytn") {
            this.memberInfo.klaytnAddr = data.addr;
            this.memberInfo.klaytnProvider = data.walletProvider;
            this.$forceUpdate();
          } else if (this.selectedWallet === "Btc") {
            this.memberInfo.btcAddr = data.addr;
            this.memberInfo.btcAddrType = data.addrType;
            this.memberInfo.btcProvider = data.walletProvider;
            this.$forceUpdate();
          }
        }
      }
      //this.memberInfo.walletAddr = data.addr;
      //this.memberInfo.walletNetwork = data.network;
    },
    checkIfValid(fieldName) {
      const field = this.$v.memberInfo[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    hideSaveMessageModal() {
      this.$refs["save-message-modal2"].hide();
    },
    onCheckAccept() {
      if (!this.acceptDeleteMember) {
        this.acceptErrorMessage = this.$t("validation.required");
      } else {
        this.acceptErrorMessage = "";
      }
    },
    onClickToLeave() {
      this.completeMessage = "";
      if (!this.acceptDeleteMember) {
        this.acceptErrorMessage = this.$t("validation.required");
      } else {
        if (this.me.idx && this.me.idx !== "0") {
          this.deleteMember({ idxMember: this.me.idx })
            .then(() => {
              this.logout().then(() => {
                this.completeMessage = this.$t("mypage.memberLeave.complete");
                this.$refs["delete-message-modal"].show();
                this.$refs["delete-confirm-modal"].hide();
              });
            })
            .catch((error) => {
              this.$log.error(error);
              this.completeMessage = this.$t("mypage.memberLeave.error");
              this.$refs["delete-message-modal"].show();
              this.$refs["delete-confirm-modal"].hide();
            });
        }
      }
    },
    showIdentityVerify() {
      this.getAuthRequestData()
        .then((data) => {
          let niceAuthForm = document.niceAuthForm;
          let url =
            "https://nice.checkplus.co.kr/CheckPlusSafeModel/service.cb";

          window.app = this;
          window.open(
            "",
            "niceAuthForm",
            "toolbar=no, width=480, height=600, directories=no, status=no, scrollorbars=no, resizable=no"
          );
          niceAuthForm.action = url;
          niceAuthForm.method = "post";
          niceAuthForm.target = "niceAuthForm";
          niceAuthForm.token_version_id.value = data.tokenVersionId;
          niceAuthForm.enc_data.value = data.encData;
          niceAuthForm.integrity_value.value = data.integrityValue;
          niceAuthForm.submit();
        })
        .catch((error) => {
          this.$log.error(error);
        });
    },
  },
  filters: {
    currency: (value) => {
      if (!value) return "";
      return parseInt(value)
        .toFixed(0)
        .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    },
  },
};
</script>
