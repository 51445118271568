<template>
  <CCard>
    <CCardBody>
      <CRow>
        <CCol class="mb-3"><h5>작품 관리</h5></CCol>
      </CRow>
      <CRow>
        <CCol>
          <table class="table">
            <tbody>
              <tr>
                <th class="bg-light text-dark">검색</th>
                <td>
                  <CSelect
                    class="col-2 float-left px-0 mb-0"
                    :options="searchOptions"
                    :value.sync="searchSelected"
                    @change="onChangeSearchOption"
                  />
                  <CInput
                    class="col-6 float-left mb-0"
                    placeholder="검색어를 입력해주세요."
                    v-model="externalParams.searchKeyword"
                    @input="onChangeSearchKeyword"
                    @keypress.enter.prevent="onClickSearch"
                  />
                  <CButton color="dark" class="text-white" @click="onClickSearch">
                    <CIcon name="cil-search" class="mr-2" />검색
                  </CButton>
                  <CButton
                    variant="outline"
                    color="secondary"
                    class="ml-2"
                    @click="onClickReset"
                  >
                    <CIcon name="cil-filter-x" />
                  </CButton>
                </td>
              </tr>
              <tr>
                <!-- <td colspan="2" class="border-bottom">
                  <span class="float-left mt-2 mr-3">
                    <strong class="mr-2">&#183; 등록일시</strong>
                  </span>
                  <CInputRadioGroup
                    inline
                    class="float-left mt-2"
                    :options="searchDateOptions"
                    :checked.sync="searchDateSelected"
                    @change="onChangeSearchDate"
                  />
                  <CInput
                    type="date"
                    class="float-left mr-2 mb-0"
                    v-model="externalParams.keywordUpdatedAtBgn"
                  />
                  <CInput
                    type="date"
                    class="float-left mb-0"
                    v-model="externalParams.keywordUpdatedAtEnd"
                  />
                </td> -->
                <td colspan="2" class="border-bottom py-2">
                  <b-form inline class="mb-0">
                    <span class="float-left mr-3">
                      <strong class="mr-2">갤러리 노출</strong>
                    </span>
                    <b-form-checkbox
                      v-model="displayAllSelected"
                      @change="displayToggleAll"
                      class="mr-3 custom-control-inline"
                    >
                      전체
                    </b-form-checkbox>
                    <b-form-checkbox-group
                      v-model="externalParams.keywordDisplay"
                      :options="displayOptions"
                      class="mr-2"
                    >
                    </b-form-checkbox-group>
                    <span class="float-left mr-3">
                      <strong class="ml-5 mr-2">상태</strong>
                    </span>
                    <b-form-checkbox
                      v-model="statusAllSelected"
                      @change="statusToggleAll"
                      class="mr-3 custom-control-inline"
                    >
                      전체
                    </b-form-checkbox>
                    <b-form-checkbox-group
                      v-model="externalParams.keywordStatus"
                      :options="statusOptions"
                    >
                    </b-form-checkbox-group>
                  </b-form>
                </td>
              </tr>
            </tbody>
          </table>
        </CCol>
      </CRow>
      <CRow>
        <CCol class="col-auto mr-auto align-self-center">
          총&nbsp;
          <strong>{{ productTableItems.totalElements }}</strong>건&nbsp;({{ currentPage }}&nbsp;/&nbsp;{{ productTableItems.totalPages }}&nbsp;페이지)
        </CCol>
        <CCol class="col-auto">
          <CSelect
            class="float-left mr-2 mb-2"
            :options="pageOptions"
            :value.sync="perPage"
            @change="onPaging"
          />
          <CButton color="info" class="mr-2 text-white" @click="onClickIPFSRegister">
            <CIcon name="cil-image-plus" class="mr-2" />IPFS 작품등록
          </CButton>
          <CButton color="dark" class="text-white" @click="onClickRegister">
            <CIcon name="cil-library-add" class="mr-2" />신규등록
          </CButton>
        </CCol>
      </CRow>
      <b-table
        id="productTable"
        thead-class="text-center"
        :busy="isBusy"
        :items="productTableItems.content"
        :fields="productTableFields"
        :items-per-page="perPage"
        show-empty
        empty-text="조회된 내역이 없습니다."
      >
        <!-- NO -->
        <template #cell(index)="data">
          {{ data.index + 1 + (currentPage - 1) * perPage }}
        </template>

        <!-- Ethereum NFT Token ID -->
        <template #cell(network)="data">
          {{ data.item.nftType }}
        </template>
        <!-- KLIP NFT Token ID -->
        <template #cell(tokenId)="data">
          {{ data.item.nftTokenId > 0 ? `#${data.item.nftTokenId}` : '' }}
        </template>

        <!-- 작품명
        <template #cell(productName)="data">
          <a
            class="text-info"
            style="cursor: pointer;"
            @click="onClickProductName(data.item.idx)"
          >
            {{ data.item.productName }}
          </a>
        </template> -->

        <!-- 판매가 -->
        <template #cell(productPrice)="data">
          <template v-if="data.item.productCoinCurrency">
            {{ data.item.productCoinPrice * 1 | currency}} sats
          </template>
          <template v-else>
            {{ data.value | currency }} &#8361;
          </template>
        </template>

        <!-- 갤러리 -->
        <template #cell(display)="data">
          <span :class="replaceKoDisplay(data.item.display).class">
            {{ replaceKoDisplay(data.item.display).text }}
          </span>
        </template>

        <!-- 상태 -->
        <template #cell(status)="data">
          <span :class="replaceKoStatus(data.item.status).class">
            {{ replaceKoStatus(data.item.status).text }}
          </span>
        </template>

        <!-- Actions -->
        <template v-slot:cell(actions)="data">
          <CButton
            variant="outline"
            color="dark"
            size="sm"
            class="mr-1"
            @click="onClickModify(data.item.idx)"
          >
            <CIcon name="cil-pencil" />
          </CButton>
          <CButton
            variant="outline"
            color="dark"
            @click="onClickNFT(data.item.idx)"
          >
            NFT
          </CButton>
        </template>
      </b-table>
      <b-pagination
        aria-controls="productTable"
        align="center"
        hide-ellipsis
        limit="10"
        v-model="currentPage"
        :total-rows="productTableItems.totalElements"
        :per-page="perPage"
        :current-page="currentPage"
        v-on:input="onPaging"
      ></b-pagination>
    </CCardBody>
  </CCard>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const productHelper = createNamespacedHelpers("product");

const today = new Date();

export default {
  name: "ProductList",
  components: {},
  data: function() {
    return {
      isBusy: false,
      perPage: 10,
      pageOptions: [
        { value: 10, label: "10 개씩 보기" },
        { value: 20, label: "20 개씩 보기" },
        { value: 30, label: "30 개씩 보기" },
        { value: 50, label: "50 개씩 보기" }
      ],
      currentPage: 1,
      sort: [
        { id: "updatedAt", desc: "desc" },
        { id: "idx", desc: "desc" }
      ],
      sortBy: "",
      sortDesc: false,
      // 검색
      searchSelected: "",
      searchOptions: [
        { value: "", label: "전체" },
        { value: "keywordProductName", label: "작품명" },
        { value: "keywordArtistName", label: "작가명" },
        { value: "keywordNetwork", label: "네트워크" },
        { value: "keywordTokenId", label: "토큰ID" },
      ],
      searchDateSelected: "",
      searchDateOptions: [
        { value: "", label: "전체" },
        { value: "LAST_1_WEEK", label: "최근 1주일" },
        { value: "LAST_1_MONTH", label: "최근 1개월" }
      ],
      externalParams: {
        searchKeyword: "",
        keywordProductName: "",
        keywordArtistName: "",
        keywordNetwork: "",
        keywordTokenId: "",
        keywordStatus: [],
        keywordDisplay: [],
        keywordUpdatedAtBgn: "",
        keywordUpdatedAtEnd: ""
      },
      displayAllSelected: true,
      displayOptions: [
        { value: true, text: "공개" },
        { value: false, text: "비공개" },
      ],
      statusAllSelected: true,
      statusOptions: [
        { text: "판매예정", value: "PREPARING" },
        { text: "전시중", value: "EXHIBIT" },
        { text: "판매가능", value: "FORSALE" },
        { text: "판매종료", value: "SOLDOUT" },
        { text: "판매종료(가격비공개)", value: "PRIVATESOLD"},
        { text: "예약중(결제중)", value: "RESERVE" },
      ]
    };
  },
  computed: {
    ...productHelper.mapState(["productTableItems", "productTableFields","previousParams",])
  },
  mounted() {
    // previousParams Set
    if (this.$route.params.linkType === "comeback") {
      if (Object.keys(this.previousParams).length > 0) {
        this.externalParams = this.previousParams.externalParams;
        this.currentPage = this.previousParams.currentPage;
        this.searchSelected = this.previousParams.searchSelected;
        if (this.previousParams.externalParams.searchKeyword !== "") {
          if (this.searchSelected === "") {
            this.resetSearchKeyword()
          } else if (this.searchSelected === "keywordProductName") {
            this.resetSearchKeyword()
            this.externalParams.keywordProductName = this.previousParams.externalParams.searchKeyword;
          } else if (this.searchSelected === "keywordArtistName") {
            this.resetSearchKeyword()
            this.externalParams.keywordArtistName = this.previousParams.externalParams.searchKeyword;
          } else if (this.searchSelected === "keywordNetwork") {
            this.resetSearchKeyword()
            this.externalParams.keywordNetwork = this.previousParams.externalParams.searchKeyword;
          } else if (this.searchSelected === "keywordTokenId") {
            this.resetSearchKeyword()
            this.externalParams.keywordTokenId = this.previousParams.externalParams.searchKeyword;
          }
        }
      }
    } else {
      this.setProductPreviousParams({});
      this.externalParams.keywordDisplay = this.displayOptions.map(el => el.value);
      this.externalParams.keywordStatus = this.statusOptions.map(el => el.value);
    }
    this.requestProductItems();
    this.showPageMoveType();
  },
  methods: {
    ...productHelper.mapActions(["getProducts", "setProductPreviousParams"]),
    onPaging() {
      this.requestProductItems();
    },
    // 갤러리노출검색 '전체' 선택 이벤트
    displayToggleAll(checked) {
      this.externalParams.keywordDisplay = checked
        ? this.displayOptions.map(val => val.value)
        : [];
    },
    // 상태검색 '전체' 선택 이벤트
    statusToggleAll(checked) {
      this.externalParams.keywordStatus = checked
        ? this.statusOptions.map(val => val.value)
        : [];
    },
    setPreviousParams() {
      this.setProductPreviousParams({
        externalParams: this.externalParams,
        currentPage: this.currentPage,
        searchSelected: this.searchSelected,
      });
    },
    onChangeSearchOption() {
      this.setSearchConditions(this.externalParams.searchKeyword);
    },
    onChangeSearchKeyword(keyword) {
      this.setSearchConditions(keyword);
    },
    onChangeSearchDate(value) {
      // TODO 동작 안함
      this.$log.info("onChangeSearchDate() ", value);
      if (this.searchDateSelected === "") {
        this.externalParams.keywordUpdatedAtBgn = "";
        this.externalParams.keywordUpdatedAtEnd = "";
      } else if (this.searchDateSelected === "LAST_1_WEEK") {
        this.externalParams.keywordUpdatedAtBgn = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDay()
        ).toLocaleDateString();
      } else if (this.searchDateSelected === "LAST_1_MONTH") {
      }
    },
    setSearchConditions(keyword) {
      if (this.searchSelected === "") {
        this.resetSearchKeyword()
      } else if (this.searchSelected === "keywordProductName") {
        this.resetSearchKeyword()
        this.externalParams.keywordProductName = keyword;
      } else if (this.searchSelected === "keywordArtistName") {
        this.resetSearchKeyword()
        this.externalParams.keywordArtistName = keyword;
      } else if (this.searchSelected === "keywordNetwork") {
        this.resetSearchKeyword()
        this.externalParams.keywordNetwork = keyword;
      } else if (this.searchSelected === "keywordTokenId") {
        this.resetSearchKeyword()
        this.externalParams.keywordTokenId = keyword;
      }
    },
    resetSearchKeyword() {
      this.externalParams.keywordProductName = "";
      this.externalParams.keywordArtistName = "";
      this.externalParams.keywordNetwork = "";
      this.externalParams.keywordTokenId = "";
    },
    resetSearchConditions() {
      this.externalParams.searchKeyword = "";
      this.searchSelected = "";
      this.resetSearchKeyword()
      this.externalParams.keywordDisplay = this.displayOptions.map(el => el.value);
      this.externalParams.keywordStatus = this.statusOptions.map(el => el.value);
    },
    onClickSearch() {
      this.setProductPreviousParams({});
      this.requestProductItems();
    },
    onClickReset() {
      this.resetSearchConditions();
      this.requestProductItems();
    },
    requestProductItems() {
      this.$log.info(
        "requestProductItems() this.externalParams=",
        this.externalParams
      );
      this.setPreviousParams();
      this.isBusy = true;
      this.getProducts(
        this.getPagingParams(
          {
            page: this.currentPage - 1,
            pageSize: this.perPage,
            sorted: this.sort,
            filtered: []
          },
          this.externalParams
        )
      )
        .then(data => {
          this.$log.log(data);
          this.isBusy = false;
        })
        .catch(error => {
          this.isBusy = false;
          this.$log.error(error);
        });
    },
    onClickIPFSRegister() {
      const ipfsWebUrl = process.env.VUE_APP_IPFS_URL;
      window.open(ipfsWebUrl, "_blank");
      this.$log.info(ipfsWebUrl);
    },
    onClickRegister() {
      this.$log.info("onClickRegister");
      this.$router.push("/management/product-save/0");
    },
    onClickModify(idx) {
      this.setPreviousParams();
      this.$log.info("click onClickModify() idx:{}", idx);
      this.$router.push("/management/product-save/" + idx);
    },
    onClickNFT(idx) {
      this.setPreviousParams();
      this.$log.info("click onClickNFT() idx:{}", idx);
      this.$router.push("/management/product-nft/" + idx);
    },
    onClickProductName(idx) {
      this.setPreviousParams();
      this.$log.info("click onClickProductName() idx:{}", idx);
      this.$router.push("/management/product-detail/" + idx);
    },
    getPagingParams({ page, pageSize, sorted, filtered }, externalParams) {
      const params = {
        page,
        size: pageSize,
        ...externalParams
      };

      // sorting parameter
      let sorts = [];
      for (let i = 0, length = sorted.length; i < length; i++) {
        const sort = sorted[i];
        sorts.push(`${sort.id},${sort.desc ? "desc" : "asc"}`);
      }
      params["sort"] = sorts;

      // filtering parameter
      for (let i = 0, length = filtered.length; i < length; i++) {
        const filter = filtered[i];
        params[filter.id] = filter.value;
      }

      return params;
    },
    showPageMoveType() {
      this.$nextTick(() => {
        let moveType = this.$route.params.moveType;
        if (moveType === "addProductSuccess") {
          alert("작품이 추가되었습니다.");
        } else if (moveType === "updateProductSuccess") {
          alert("작품 정보가 업데이트되었습니다.");
        }
      });
    },
    replaceKoDisplay(data) {
      let obj = {};
      switch (data) {
        case true:
          obj = { class: "text-success", text: "공개" };
          break;
        case false:
          obj = { class: "text-gray", text: "비공개" };
          break;
        default:
          obj = { class: "text-black", text: "-" };
      }
      return obj;
    },
    replaceKoStatus(data) {
      let obj = {};
      switch (data) {
        case "PREPARING":
          obj = { class: "text-danger", text: "판매예정" };
          break;
        case "EXHIBIT":
          obj = { class: "text-success", text: "전시중" };
          break;
        case "FORSALE":
          obj = { class: "text-success", text: "판매가능" };
          break;
        case "SOLDOUT":
          obj = { class: "text-dark", text: "판매종료" };
          break;
        case "PRIVATESOLD":
          obj = { class: "text-dark", text: "판매종료(가격비공개)"}
          break;
        case "RESERVE":
          obj = { class: "text-info", text: "예약중(결제중)" };
          break;
        default:
          obj = { class: "text-black", text: "-" };
      }
      return obj;
    }
  },
  filters: {
    currency: value => {
      if (!value) return "";
      return value.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    }
  },
  watch: {
    ["externalParams.keywordDisplay"]: {
      deep: true,
      handler(newVal) {
        if (newVal.length === this.displayOptions.length) {
          this.displayAllSelected = true;
        } else {
          this.displayAllSelected = false;
        }
      }
    },
    ["externalParams.keywordStatus"]: {
      deep: true,
      handler(newVal) {
        if (newVal.length === this.statusOptions.length) {
          this.statusAllSelected = true;
        } else {
          this.statusAllSelected = false;
        }
      }
    }
  }
};
</script>
