<template>
  <b-modal
    id="artpoolUserGuideModal2"
    modal-class="artpool-user-guide-modal guide2"
    ref="artpoolUserGuideModal2"
    hide-header
    hide-footer
    centered
    body-class="text-center text-black my-4"
    @hide="modalEvent(false)"
    @show="modalEvent(true)"
  >
    <div class="user_guide_modal">
      <template v-if="activeLanguage === 'ko'">
        <img src="/img/threespace/artpool/art_pool_user_guide3_ko.jpg" />
      </template>
      <template v-else>
        <img src="/img/threespace/artpool/art_pool_user_guide3_en.jpg" />
      </template>
      <div class="btn_box">
        <a @click="onClickIsArtPool">Artpool?</a>
        <!-- <a @click="onClickOnlineGallery">Exhibition</a> -->
      </div>
      <div class="close_btn" @click="hide()">close btn</div>
      <div class="not_seen_week_btn" @click="onClickNotSeenBtn()">일주일동안 보지않기</div>
    </div>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: "artpoolUserGuideModal2",
  props: {
    activeLanguage: {
      type: String,
      default: ""
    },
    artpoolInfo: {
      type: Object,
      default: () => {}
    }
  },
  data: function () {
    return {
    }
  },
  methods: {
    show() {
      this.$refs["artpoolUserGuideModal2"].show();
    },
    hide() {
      this.$refs["artpoolUserGuideModal2"].hide();
    },
    onClickIsArtPool() {
      this.$router.push("/features/11");
    },
    onClickNotSeenBtn() {
      this.$emit("onClickPopupNoneSeenWeekBtn", 2);
    },
    // onClickOnlineGallery() {
    //   window.open("https://spatial.io/s/THE-ART-POOL-002-CITY-NIGHT-624fc94cf16e690001fc9312?share=6126801489799390087", "_blank");
    // },
    modalEvent(isVisible) {
      if (isVisible) {
        // open event hook
      } else {
        // close event hook ***>> modal data init here
      }
    }
  },
  filters: {
    decimal: value => {
      if (!value) return 0;
      return (Math.floor((value * 100) / 10 ** 18) / 100)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    },
  }
}
</script>
