<template>
  <b-modal
    id="nftTopRankImageModal"
    ref="nftTopRankImageModal"
    modal-class="nft-top-rank-image-model"
    hide-header
    hide-footer
    centered
    body-class="text-center text-black"
    @hide="modalEvent"
    @show="modalEvent"
  >
    <div class="nft_pool_result_modal">
      <template v-if="activeLanguage === 'ko'">
        <img src="/img/threespace/artpool/art_pool_result_ko.jpg" />
      </template>
      <template v-else>
        <img src="/img/threespace/artpool/art_pool_result_en.jpg" />
      </template>
      <div class="close_btn" @click="hide()">close btn</div>
      <div class="online_gallery_btn" @click="onClickOnlineGallery()">Exhibition</div>
      <div class="not_seen_week_btn" @click="onClickNotSeenBtn()">일주일동안 보지않기</div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "NftPoolTopRankImageModal",
  props: {
    artpoolInfo: {
      type: Object,
      default: () => {}
    }
  },
  data: function () {
    return {
    }
  },
  computed: {
    activeLanguage() {
      return this.getActiveLanguage();
    },
  },
  methods: {
    show() {
      this.$refs["nftTopRankImageModal"].show();
    },
    hide() {
      this.$emit("onClickTopRankCloseBtn", this.artpoolInfo.idx);
      this.$refs["nftTopRankImageModal"].hide();
    },
    getActiveLanguage() {
      return localStorage.getItem("language") || "en";
    },
    onClickNotSeenBtn() {
      this.$emit("onClickTopRankNoneSeenWeekBtn", this.artpoolInfo.idx);
    },
    onClickOnlineGallery() {
      window.open(this.artpoolInfo.externalLink, "_blank");
    },
    modalEvent() {
    }
  },
  filters: {
    decimal: value => {
      if (!value) return "0.00";
      return parseFloat(Math.floor(value * 100) / 100)
          .toFixed(2)
          .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    },
    currency: value => {
      if (!value) return "0";
      return parseInt(value)
          .toFixed(0)
          .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    }
  }
}
</script>
