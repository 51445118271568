import ApiService from "@/services/api.service";
import logger from "@/logger";

// action types
export const ADD_ORDER = "addOrder";
export const UPDATE_ORDER = "updateOrder";
export const GET_ORDERS = "getOrders";
export const GET_ORDER = "getOrder";
export const SAVE_CANCEL = "saveCancel";
export const REQUEST_CANCEL = "requestCancel";

// mutation types
export const SET_ORDERS = "setOrders";
export const SET_ORDER = "setOrder";

const state = {
  errors: null,
  orderTableItems: {
    content: [],
    first: true,
    last: false,
    number: 0,
    numberOfElements: 0,
    size: 0,
    sort: {
      orders: []
    },
    total: 0,
    totalElements: 0,
    totalPages: 1
  },
  orderTableFields: [
    { key: "index", label: "번호", class: "text-center", thStyle: "width:60px" },
    { key: "createdAt", label: "주문일자", class: "text-center", thStyle: "width:136px" },
    { key: "idx", label: "주문ID", class: "text-center", thStyle: "width:70px" },
    { key: "payType", label: "결제구분", class: "text-center", thStyle: "width:76px" },
    { key: "moid", label: "주문번호", class: "text-center", thStyle: "width:140px" },
    { key: "idxProduct", label: "작품번호", class: "text-center", thStyle: "width:76px" },
    { key: "productName", label: "작품명" },
    { key: "amt", label: "금액", class: "text-right" },
    { key: "idxArtist", label: "작가번호", class: "text-center" },
    { key: "idxMember", label: "회원번호", class: "text-center" },
    { key: "status", label: "주문상태", class: "text-center" },
    { key: "nftTransferStatus", label: "NFT전송", class: "text-center" },
    { key: "settleCoinStatus", label: "코인정산", class: "text-center" },
    { key: "actions", label: "Actions", class: "text-center" }
  ],
  orderInfo: {
    product: {},
    orderPaymentCancel: {}
  }
};

const getters = {};

const actions = {
  [ADD_ORDER](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("/management/v1/orders", payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [UPDATE_ORDER](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.put("/management/v1/orders/" + payload.idx, payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [GET_ORDERS](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("/management/v1/orders", payload)
        .then(({ data }) => {
          context.commit(SET_ORDERS, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [GET_ORDER](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("/management/v1/orders/" + payload)
        .then(({ data }) => {
          context.commit(SET_ORDER, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [SAVE_CANCEL](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("/payment/v1/payments/vbank/cancel", payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [REQUEST_CANCEL](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("/management/v1/payments/vbank/cancel", payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  }
};

const mutations = {
  [SET_ORDERS](state, orderList) {
    state.orderTableItems = orderList;
  },
  [SET_ORDER](state, orderInfo) {
    state.orderInfo = orderInfo;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
