<template>
  <button
    class="favorited_button"
    :class="{liked: favoritedItem.favorited}"
    @click.stop="onClickFavorited"
  >
  </button>
</template>

<script>
export default {
  name: "FavoritedButton",
  props: {
    favoritedItem: {
      type: Object,
      default: () => {}
    },
  },
  data: function () {
    return {
    }
  },
  computed: {
  },
  methods: {
    onClickFavorited() {
      this.$emit("onClickFavoritedButton", this.favoritedItem)
    }
  },
}
</script>
