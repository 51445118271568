<template>
  <div class="bubbles">
      <div v-for="i in count"
           class="bubble"
           :key="`bubble-${i}`"
           :style="styles[i]"
      />
  </div>
</template>

<script>
export default {
  name: 'BXBubbles',
  props: {
    count: {
      type: Number,
      default : 30,
    },
    colors: {
      // TODO
      type: Array,
      default: () => ["#daff71","#acc9f6"],
    },
    type : {
      type: Array,
      default: () => ["sway-left-to-right", "sway-right-to-left"],
    }
  },
  data: function () {
    return {
      styles : this.getInitStyle(),
    }
  },
  computed: {},
  methods: {
    randomRange(min, max){
      return min + Math.floor(Math.random() * ((max - min) + 1))
    },
    sample(list){
      return list[this.randomRange(1, list.length)-1]
    },
    getInitStyle(){
      const styles = [];
      for(let i = 0 ; i < this.count; i++ ){
        const style = {
          '--bubble-left-offset': this.randomRange(0, 100) + "%",
          '--bubble-radius': this.randomRange(1, 10) + "vw",
          '--bubble-float-duration': this.randomRange(6, 12) + "s",
          '--bubble-sway-duration': this.randomRange(4, 6) + "s",
          '--bubble-float-delay': this.randomRange(0, 4) + "s",
          '--bubble-sway-delay': this.randomRange(0, 4) + "s",
          '--bubble-sway-type': this.sample(this.type),
        }
        styles.push(style);
      }
      return styles;
    }
  }
}
</script>

<style>
  .bubbles {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .bubble {
    position: absolute;
    left: var(--bubble-left-offset);
    bottom: -75%;
    display: block;
    width: var(--bubble-radius);
    height: var(--bubble-radius);
    border-radius: 50%;
    animation: float-up var(--bubble-float-duration) var(--bubble-float-delay) ease-in infinite;
    opacity: 0.7;
  }
  .bubble::before {
     position: absolute;
     content: '';
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     background: radial-gradient(#daff71, #f4ffc8);
     border-radius: inherit;
     animation: var(--bubble-sway-type) var(--bubble-sway-duration) var(--bubble-sway-delay) ease-in-out alternate infinite;
  }
  .bubble:nth-child(2n)::before {
     background: radial-gradient(#acc9f6, #e6efff);
  }

  @keyframes float-up {
    to {
      transform: translateY(-175vh);
    }
  }

  @keyframes sway-left-to-right {
    from {
      transform: translateX(-100%);
    }

    to {
      transform: translateX(100%);
    }
  }

  @keyframes sway-right-to-left {
    from {
      transform: translateX(100%);
    }

    to {
      transform: translateX(-100%);
    }
  }
</style>