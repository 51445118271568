<template>
  <CCard>
    <CCardBody>
      <CRow>
        <CCol class="mb-3"><h5>아트풀 등록/수정</h5></CCol>
      </CRow>
      <CForm>
        <CRow>
          <CCol col="8">
            <b-table-simple>
              <colgroup>
                <col style="width:15%;" />
                <col style="width:85%;" />
              </colgroup>
              <b-tbody>
                <b-tr>
                  <b-th variant="secondary">아트풀 번호</b-th>
                  <b-td>
                    {{ artpoolInfo.idx || "(정보없음)" }}
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-th variant="secondary">
                    회차(라운드)
                  </b-th>
                  <b-td>
                    <div class="row align-items-center">
                      <div class="col-lg-2 col-md-2 pr-0">
                        아트풀 <span class="text-danger font-lg">*</span>
                      </div>
                      <div class="col-4">
                        <CInput
                          class="mb-0"
                          v-model="artpoolInfo.roundNo"
                          placeholder="화면에 표시되는 회차(순서)"
                          :lazy="false"
                          :value.sync="$v.artpoolInfo.roundNo.$model"
                          :isValid="checkIfValid('roundNo')"
                          invalidFeedback="필수 정보입니다."
                        />
                      </div>
                      <div class="col-6">
                        <CIcon name="cil-info" class="text-info mr-1" />
                        <span class="text-info">화면에 표시되는 회차(순서)</span>
                      </div>
                    </div>
                    <div class="row align-items-center mt-1">
                      <div class="col-lg-2 col-md-2 pr-0">
                        클레이튼
                      </div>
                      <div class="col-4">
                        <CInput
                          class="mb-0"
                          v-model="artpoolInfo.roundNoKip"
                          placeholder="카이아 컨트랙트 Round No"
                          :lazy="false"
                          :value.sync="$v.artpoolInfo.roundNoKip.$model"
                          :isValid="checkIfValid('roundNoKip')"
                          invalidFeedback="필수 정보입니다."
                        />
                      </div>
                      <div class="col-6">
                        <CIcon name="cil-info" class="text-info mr-1" />
                        <span class="text-info">카이아 컨트랙트에서 자동설정(디폴트 0)</span>
                      </div>
                    </div>
                    <div class="row align-items-center mt-1">
                      <div class="col-lg-2 col-md-2 pr-0">
                        이더리움
                      </div>
                      <div class="col-4">
                        <CInput
                          class="mb-0"
                          v-model="artpoolInfo.roundNoErc"
                          placeholder="이더리움 컨트랙트 Round No"
                          :lazy="false"
                          :value.sync="$v.artpoolInfo.roundNoErc.$model"
                          :isValid="checkIfValid('roundNoErc')"
                          invalidFeedback="필수 정보입니다."
                        />
                      </div>
                      <div class="col-6">
                        <CIcon name="cil-info" class="text-info mr-1" />
                        <span class="text-info">이더리움 컨트랙트에서 자동설정(디폴트 0)</span>
                      </div>
                    </div>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-th variant="secondary">
                    아트풀 명
                  </b-th>
                  <b-td>
                    <div class="row align-items-center">
                      <div class="col-lg-2 col-md-2 pr-0">
                        한글 <span class="text-danger font-lg">*</span>
                      </div>
                      <div class="col-10">
                        <CInput
                          class="mb-0"
                          placeholder="아트풀 명"
                          :lazy="false"
                          :value.sync="$v.artpoolInfo.title.$model"
                          :isValid="checkIfValid('title')"
                          invalidFeedback="필수 정보입니다."
                        />
                      </div>
                    </div>
                    <div class="row align-items-center mt-1">
                      <div class="col-lg-2 col-md-2 pr-0">
                        영문
                      </div>
                      <div class="col-10">
                        <CInput
                          class="mb-0"
                          placeholder="아트풀 명"
                          v-model="artpoolInfo.titleEn"
                        />
                      </div>
                    </div>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-th variant="secondary">
                    아트풀 명<br/>(부제목)
                  </b-th>
                  <b-td>
                    <div class="row align-items-center">
                      <div class="col-lg-2 col-md-2 pr-0">
                        한글
                      </div>
                      <div class="col-10">
                        <CInput
                          class="mb-0"
                          placeholder="아트풀 명(부제목)"
                          v-model="artpoolInfo.subTitle"
                        />
                      </div>
                    </div>
                    <div class="row align-items-center mt-1">
                      <div class="col-lg-2 col-md-2 pr-0">
                        영문
                      </div>
                      <div class="col-10">
                        <CInput
                          class="mb-0"
                          placeholder="아트풀 명(부제목)"
                          v-model="artpoolInfo.subTitleEn"
                        />
                      </div>
                    </div>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-th variant="secondary">설명(소개)</b-th>
                  <b-td>
                    <div class="row align-items-center mt-1">
                      <div class="col-lg-2 col-md-2 pr-0">
                        한글 <span class="text-danger font-lg">*</span>
                      </div>
                      <div class="col-10">
                        <CTextarea
                          class="mb-0"
                          placeholder="아트풀 설명(전시 소개)"
                          rows="4"
                          :lazy="false"
                          :value.sync="$v.artpoolInfo.description.$model"
                          :isValid="checkIfValid('description')"
                          invalidFeedback="필수 정보입니다."
                        />
                      </div>
                    </div>
                    <div class="row mt-1 align-items-center">
                      <div class="col-lg-2 col-md-2 pr-0">
                        영문
                      </div>
                      <div class="col-10">
                        <CTextarea
                          class="mb-0"
                          placeholder="아트풀 설명(전시 소개)"
                          rows="4"
                          v-model="artpoolInfo.descriptionEn"
                        />
                      </div>
                    </div>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-th variant="secondary">
                    아트풀 기간
                  </b-th>
                  <b-td>
                    <div class="row align-items-center">
                      <div class="col-lg-2 col-md-2 pr-0">
                        시작일시
                      </div>
                      <div class="col-5">
                        <CInput
                          class="mb-0"
                          v-model="artpoolInfo.startDate"
                        />
                      </div>
                      <div class="col-5">
                        예) 2022-01-01T09:00:00
                        <span class="text-danger">개발중</span>
                      </div>
                    </div>
                    <div class="row align-items-center mt-1">
                      <div class="col-lg-2 col-md-2 pr-0">
                        종료일시
                      </div>
                      <div class="col-5">
                        <CInput
                          class="mb-0"
                          v-model="artpoolInfo.endDate"
                        />
                      </div>
                    </div>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-th variant="secondary">
                    투표 기간
                  </b-th>
                  <b-td>
                    <div class="row align-items-center">
                      <div class="col-lg-2 col-md-2 pr-0">
                        투표 여부
                      </div>
                      <div class="col-10">
                        <b-form-radio-group
                          id="voted"
                          name="voted"
                          :options="yesOrNoOptions"
                          v-model="artpoolInfo.voted"
                        />
                      </div>
                    </div>
                    <div class="row align-items-center mt-2">
                      <div class="col-lg-2 col-md-2 pr-0">
                        시작일시
                      </div>
                      <div class="col-5">
                        <CInput
                          class="mb-0"
                          v-model="artpoolInfo.voteStartDate"
                        />
                      </div>
                      <div class="col-5">
                        예) 2022-01-01T09:00:00
                        <span class="text-danger">개발중</span>
                      </div>
                    </div>
                    <div class="row align-items-center mt-1">
                      <div class="col-lg-2 col-md-2 pr-0">
                        종료일시
                      </div>
                      <div class="col-5">
                        <CInput
                          class="mb-0"
                          v-model="artpoolInfo.voteEndDate"
                        />
                      </div>
                    </div>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-th variant="secondary">
                    전시회 장소
                  </b-th>
                  <b-td>
                    <div class="row align-items-center">
                      <div class="col-lg-2 col-md-2 pr-0">
                        한글
                      </div>
                      <div class="col-10">
                        <CInput
                          class="mb-0"
                          placeholder="서울특별시 중구 서소문로"
                          v-model="artpoolInfo.venue"
                        />
                      </div>
                    </div>
                    <div class="row align-items-center mt-1">
                      <div class="col-lg-2 col-md-2 pr-0">
                        영문
                      </div>
                      <div class="col-10">
                        <CInput
                        class="mb-0"
                        placeholder="Seoul, South Korea"
                        v-model="artpoolInfo.venueEn"
                        />
                      </div>
                    </div>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-th variant="secondary">
                    외부 링크
                  </b-th>
                  <b-td>
                    <CInput
                      class="mb-0"
                      placeholder="온라인 전시회 링크"
                      v-model="artpoolInfo.externalLink"
                    />
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-th variant="secondary">
                    화면 노출
                  </b-th>
                  <b-td>
                    <div class="row">
                      <div class="col-4">
                        <b-form-radio-group
                          id="visible"
                          name="visible"
                          :options="yesOrNoOptions"
                          v-model="artpoolInfo.visible"
                        />
                      </div>
                      <div class="col-8">
                        <CIcon name="cil-info" class="text-info mr-1" />
                        <span class="text-info">"예"인 경우 "ART POOL" 화면에 노출됩니다.</span>
                      </div>
                    </div>
                  </b-td>
                </b-tr>
                <b-tr class="border-bottom">
                  <b-th variant="secondary">
                    참여 작가
                  </b-th>
                  <b-td>
                    <CButton color="light" @click="onClickArtistSelect">
                      <CIcon name="cil-plus" class="mr-2" />작가선택
                    </CButton>
                    <table class="table mt-2 mb-0">
                      <thead>
                        <b-tr>
                          <b-th class="text-center bg-gray-100 text-dark py-2">
                            작가번호
                          </b-th>
                          <b-th class="text-center bg-gray-100 text-dark py-2">
                            작가명
                          </b-th>
                          <b-th class="text-center bg-gray-100 text-dark py-2">
                            작가 사용자이름(닉네임)
                          </b-th>
                          <b-th class="bg-gray-100"></b-th>
                        </b-tr>
                      </thead>
                      <tbody>
                      <template v-for="(item, index) in artpoolInfo.artists">
                        <b-tr :key="'item' + index" class="border-bottom">
                          <b-td class="text-center py-0">{{ item.idx }}</b-td>
                          <b-td class="text-center py-0">{{ item.artistName }}</b-td>
                          <b-td class="text-center py-0">{{ item.nickname }}</b-td>
                          <b-td class="text-center py-0">
                            <CButton
                              variant="ghost"
                              color="right"
                              @click="onClickArtistDelete(item.idx)"
                            >
                              <CIcon name="cil-x" />
                            </CButton>
                          </b-td>
                        </b-tr>
                      </template>
                      </tbody>
                    </table>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </CCol>
          <CCol col="4">
            <div>
              <strong>썸네일 이미지</strong>
              <b-form-file
                v-model="thumbnailImage"
                placeholder="썸네일 이미지를 선택하세요."
                accept=".jpg, .jpeg, .png"
                class="mt-2"
                @change="onClickThumbnailImage"
                :disabled="isBusy"
              ></b-form-file>
              <div class="mt-2">
                <span class="text-success">{{ artpoolInfo.thumbnailImage || '(정보없음)' }}</span>
                <div v-if="artpoolInfo.thumbnailImage !== undefined && artpoolInfo.thumbnailImage !== null">
                  <CImg
                    height="100px"
                    :src="artpoolInfo.thumbnailImageUrl"
                    class="mt-1"
                  />
                </div>
              </div>
            </div>
            <hr />
            <div class="mt-3">
              <strong>배너 이미지(PC)</strong>
              <b-form-file
                v-model="bannerImagePc"
                placeholder="배너 이미지(Pc)를 선택하세요."
                accept=".jpg, .jpeg, .png"
                class="mt-2"
                @change="onClickBannerImagePc"
                :disabled="isBusy"
              ></b-form-file>
              <div class="mt-2">
                <span class="text-success">{{ artpoolInfo.bannerImagePc || '(정보없음)' }}</span>
                <div v-if="artpoolInfo.bannerImagePc !== undefined && artpoolInfo.bannerImagePc !== null">
                  <CImg
                    height="100px"
                    :src="artpoolInfo.bannerImagePcUrl"
                    class="mt-1"
                  />
                </div>
              </div>
            </div>
            <div class="mt-3">
              <strong>배너 이미지(PC) 영문</strong>
              <b-form-file
                v-model="bannerImagePcEn"
                placeholder="배너 이미지(Pc) 영문을 선택하세요."
                accept=".jpg, .jpeg, .png"
                class="mt-2"
                @change="onClickBannerImagePcEn"
                :disabled="isBusy"
              ></b-form-file>
              <div class="mt-2">
                <span class="text-success">{{ artpoolInfo.bannerImagePcEn || '(정보없음)' }}</span>
                <div v-if="artpoolInfo.bannerImagePcEn !== undefined && artpoolInfo.bannerImagePcEn !== null">
                  <CImg
                    height="100px"
                    :src="artpoolInfo.bannerImagePcEnUrl"
                    class="mt-1"
                  />
                </div>
              </div>
            </div>
            <hr />
            <div class="mt-3">
              <strong>배너 이미지(Mobile)</strong>
              <b-form-file
                v-model="bannerImageMobile"
                placeholder="배너 이미지(Mobile)를 선택하세요."
                accept=".jpg, .jpeg, .png"
                class="mt-2"
                @change="onClickBannerImageMobile"
                :disabled="isBusy"
              ></b-form-file>
              <div class="mt-2">
                <span class="text-success">{{ artpoolInfo.bannerImageMobile || '(정보없음)' }}</span>
                <div v-if="artpoolInfo.bannerImageMobile !== undefined && artpoolInfo.bannerImageMobile !== null">
                  <CImg
                    height="100px"
                    :src="artpoolInfo.bannerImageMobileUrl"
                    class="mt-1"
                  />
                </div>
              </div>
            </div>
            <div class="mt-3">
              <strong>배너 이미지(Mobile) 영문</strong>
              <b-form-file
                v-model="bannerImageMobileEn"
                placeholder="배너 이미지(Mobile) 영문을 선택하세요."
                accept=".jpg, .jpeg, .png"
                class="mt-2"
                @change="onClickBannerImageMobileEn"
                :disabled="isBusy"
              ></b-form-file>
              <div class="mt-2">
                <span class="text-success">{{ artpoolInfo.bannerImageMobileEn || '(정보없음)' }}</span>
                <div v-if="artpoolInfo.bannerImageMobileEn !== undefined && artpoolInfo.bannerImageMobileEn !== null">
                  <CImg
                    height="100px"
                    :src="artpoolInfo.bannerImageMobileEnUrl"
                    class="mt-1"
                  />
                </div>
              </div>
            </div>
            <hr />
            <div class="mt-3">
              <strong>배경 이미지</strong>
              <b-form-file
                v-model="backgroundImage"
                placeholder="배경 이미지를 선택하세요."
                accept=".jpg, .jpeg, .png, .gif"
                class="mt-2"
                @change="onClickBackgroundImage"
                :disabled="isBusy"
              ></b-form-file>
              <div class="mt-2">
                <span class="text-success">{{ artpoolInfo.backgroundImage || '(정보없음)' }}</span>
                <div v-if="artpoolInfo.backgroundImage !== undefined && artpoolInfo.backgroundImage !== null">
                  <CImg
                    height="100px"
                    :src="artpoolInfo.backgroundImageUrl"
                    class="mt-1"
                  />
                </div>
              </div>
            </div>
            <div class="mt-3">
              <strong>시그니처 컬러</strong>
              <div class="row mt-2">
                <div class="col-3">메인 컬러</div>
                <div class="col-9">
                  <CInput
                    class="mb-0"
                    placeholder="#0000"
                    v-model="artpoolInfo.primaryColor"
                  />
                </div>
              </div>
              <div class="row mt-1">
                <div class="col-3">서브 컬러</div>
                <div class="col-9">
                  <CInput
                    class="mb-0"
                    placeholder="#FFFF"
                    v-model="artpoolInfo.secondaryColor"
                  />
                </div>
              </div>
              <div class="mt-2 text-gray">
                &#183; 버튼의 컬러 등에 사용됩니다.
              </div>
            </div>
          </CCol>
        </CRow>
        <CRow>
          <CCol col="12" class="text-center my-4">
            <CButton color="light" @click="onClickCancel" class="mr-2">
              <CIcon name="cil-x-circle" class="mr-2" />취소
            </CButton>
            <CButton color="secondary" @click="onClickDelete" class="mr-2" :disabled="isBusy" v-if="idxArtpool !== '0'">
              <CIcon name="cil-trash" class="mr-2" />삭제
            </CButton>
            <CButton color="dark" @click="onClickSave" class="text-white" :disabled="isBusy">
              <CIcon name="cil-save" class="mr-2" />저장
            </CButton>
          </CCol>
        </CRow>
      </CForm>

      <ArtistSelectPopup
        ref="artistSelectPopup"
        @artist-selected="selectedArtistInfo"
      />
    </CCardBody>
  </CCard>
</template>

<script>
import { validationMixin } from "vuelidate";
import { numeric, required } from "vuelidate/lib/validators";
import { createNamespacedHelpers } from "vuex";
const artpoolHelper = createNamespacedHelpers("artpoolManager");

import ArtistSelectPopup from "@/views/management/artist/ArtistSelectPopup";

export default {
  name: "ArtpoolSave",
  components: {
    ArtistSelectPopup
  },
  props: {
    idxArtpool: {
      type: String,
      default: "0"
    }
  },
  data: function() {
    return {
      isBusy: true,
      artpoolInfo: this.getInitArtpoolInfo(),
      yesOrNoOptions: [
        { value: "false", text: "아니오" },
        { value: "true", text: "예" }
      ],
      thumbnailImage: null,
      bannerImagePc: null,
      bannerImagePcEn: null,
      bannerImageMobile: null,
      bannerImageMobileEn: null,
      backgroundImage: null
    };
  },
  computed: {
    isValid() {
      return !this.$v.artpoolInfo.$invalid;
    },
    isDirty() {
      return this.$v.artpoolInfo.$anyDirty;
    }
  },
  mixins: [validationMixin],
  validations() {
    return {
      artpoolInfo: {
        roundNo: { required, numeric },
        roundNoKip: { required, numeric },
        roundNoErc: { required, numeric },
        title: { required },
        description: { required }
      }
    };
  },
  mounted() {
    this.requestArtpoolItem();
  },
  methods: {
    ...artpoolHelper.mapActions(["getArtpool", "addArtpool", "updateArtpool", "updateArtpoolImage", "deleteArtpool"]),
    requestArtpoolItem() {
      if (this.idxArtpool && this.idxArtpool !== "0") {
        this.getArtpool(this.idxArtpool)
          .then(data => {
            this.$log.info("requestArtpoolItem()", this.artpoolInfo); //TODO
            this.artpoolInfo = Object.assign({}, this.artpoolInfo, data);
            this.isBusy = false;
          })
          .catch(error => {
            alert("아트풀 정보 조회 오류가 발생하였습니다. 관리자에게 문의하세요.");
            this.$log.error(error);
          });
      } else {
        this.isBusy = false;
      }
    },
    onClickArtistSelect() {
      this.$refs["artistSelectPopup"].show();
    },
    selectedArtistInfo(artist) {
      let isExist = false;
      for (let i = 0; i < this.artpoolInfo.artists.length; i++) {
        const item = this.artpoolInfo.artists[i];
        if (item.idx === artist.idx) {
          isExist = true;
          break;
        }
      }
      if (!isExist) {
        this.artpoolInfo.artists.push({
          idx: artist.idx,
          artistName: artist.artistName,
          nickname: artist.nickname
        });
      }
    },
    onClickArtistDelete(idxArtist) {
      const _idxArtist = this.artpoolInfo.artists.findIndex(
          item => item.idx === idxArtist
      );
      if (_idxArtist > -1) this.artpoolInfo.artists.splice(_idxArtist, 1);
    },
    onClickCancel() {
      this.$router.push("/management/artpool-list");
    },
    onClickDelete() {
      if (confirm("정말 삭제하시겠습니까?")) {
        this.deleteArtpool({ idxArtpool: this.idxArtpool })
          .then(() => {
            alert("삭제 되었습니다.");
            this.$router.push("/management/artpool-list");
          })
          .catch(error => {
            this.$log.error(error);
            alert("아트풀 삭제 오류가 발생하였습니다. 관리자에게 문의하세요.");
          });
      }
    },
    onClickSave() {
      this.$log.log("onClickSave()", this.artpoolInfo); //TODO

      if (this.isValid) {
        // 아트풀 정보 저장
        if (this.idxArtpool && this.idxArtpool !== "0") {
          this.updateArtpool(this.artpoolInfo)
            .then(() => {
              // 이미지 파일 수정
              this.updateArtpoolImageFile(this.idxArtpool);
            });
        } else {
          this.addArtpool(this.artpoolInfo)
            .then(data => {
              // 이미지 파일 등록
              this.updateArtpoolImageFile(data.idx);
            })
            .catch(error => {
              this.$log.log(error);
              alert("아트풀 정보 등록 오류가 발생하였습니다. 관리자에게 문의해 주세요.");
            });
        }
      } else {
        this.$v.$touch();
      }
    },
    updateArtpoolImageFile(idxArtpool) {
      // 이미지 파일 업로드
      let formData = new FormData();
      formData.append("idxArtpool", idxArtpool);
      if (this.thumbnailImage !== null) {
        formData.append("thumbnailImage", this.thumbnailImage);
      }
      if (this.bannerImagePc !== null) {
        formData.append("bannerImagePc", this.bannerImagePc);
      }
      if (this.bannerImagePcEn !== null) {
        formData.append("bannerImagePcEn", this.bannerImagePcEn);
      }
      if (this.bannerImageMobile !== null) {
        formData.append("bannerImageMobile", this.bannerImageMobile);
      }
      if (this.bannerImageMobileEn !== null) {
        formData.append("bannerImageMobileEn", this.bannerImageMobileEn);
      }
      if (this.backgroundImage !== null) {
        formData.append("backgroundImage", this.backgroundImage);
      }

      this.updateArtpoolImage(formData)
        .then(() => {
          this.submitted = true;
          alert("저장되었습니다.");
          this.onClickCancel();
        })
        .catch(error => {
          this.$log.error(error);
          this.submitted = true;
          alert("이미지 업로드 오류가 발생하였습니다. 관리자에게 문의하세요.");
        });
    },
    onClickThumbnailImage(event) {
      this.thumbnailImage = event.target.files[0];
    },
    onClickBannerImagePc(event) {
      this.bannerImagePc = event.target.files[0];
    },
    onClickBannerImagePcEn(event) {
      this.bannerImagePcEn = event.target.files[0];
    },
    onClickBannerImageMobile(event) {
      this.bannerImageMobile = event.target.files[0];
    },
    onClickBannerImageMobileEn(event) {
      this.bannerImageMobileEn = event.target.files[0];
    },
    onClickBackgroundImage(event) {
      this.backgroundImage = event.target.files[0];
    },
    checkIfValid(fieldName) {
      const field = this.$v.artpoolInfo[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    getInitArtpoolInfo(){
      return {
        idx: null,
        poolType: "NFT",
        roundNo: null,
        roundNoKip: 0,
        roundNoErc: 0,
        title: "",
        titleEn: "",
        subTitle: "",
        subTitleEn: "",
        description: "",
        descriptionEn: "",
        startDate: "",
        endDate: "",
        voted: false,
        voteStartDate: "",
        voteEndDate: "",
        thumbnailImage: null,
        bannerImagePc: null,
        bannerImagePcEn: null,
        bannerImageMobile: null,
        bannerImageMobileEn: null,
        backgroundImage: null,
        externalLink: null,
        venue: "",
        venueEn: "",
        primaryColor: "",
        secondaryColor: "",
        visible: false,
        artists: []
      }
    }
  }
};
</script>
