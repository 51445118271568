<template>
  <div id="ArtPool" class="py-0">
    <!-- s3에서 bg 이미지 가져옴 -->
    <div class="bg_overlay" :style="{backgroundImage: `url(${artpoolInfo.backgroundImageUrl})`}"></div>
    <div class="pool_detail">
      <div class="pool_detail_wrap">
        <!-- pool_header ==================== -->
        <div class="pool_header">
          <div class="title">
            <p>
              <template v-if="activeLanguage !== undefined && activeLanguage === 'ko'">
                {{ artpoolInfo.title || artpoolInfo.titleEn }}
              </template>
              <template v-else>
                {{ artpoolInfo.titleEn || artpoolInfo.title }}
              </template>
              <br />
              <template v-if="activeLanguage !== undefined && activeLanguage === 'ko'">
                총 <span>{{ artpoolInfo.stakedTotalCount || 0 }} NFTs</span> 예치
              </template>
              <template v-else>
                Total <span>{{ artpoolInfo.stakedTotalCount || 0 }} NFTs</span> staked
              </template>
            </p>
            <div class="pool_icon_info">
              <b-table-simple borderless small>
                <tr>
                  <th>
                    <img
                      src="/img/threespace/icon/reward_pace_icon.png"
                      class="reward"
                      v-c-tooltip="{content: $t('stake.iconInfo.info1'), placement: isMobile ? 'right' :'top'}"
                    />
                  </th>
                  <td>
                    100<span class="unit">$PACE</span>
                  </td>
                  <th>
                    <img
                      src="/img/threespace/icon/erc_medal_icon.png"
                      class="erc"
                      v-c-tooltip="{content: $t('stake.iconInfo.info2'), placement: isMobile ? 'right' :'top'}"
                    />
                  </th>
                  <td v-if="artpoolInfo.ercNftPool !== undefined">
                    {{ Number(artpoolInfo.ercNftPool.winningVote) | currency }}
                    <span class="unit"><span class="transparent">0</span>PACE / N</span>
                  </td>
                  <td v-else>
                    0
                    <span class="unit"><span class="transparent">0</span>PACE / N</span>
                  </td>
                </tr>
                <tr>
                  <th>
                    <img
                      src="/img/threespace/icon/vote_icon.png"
                      v-c-tooltip="{content: $t('stake.iconInfo.info3'), placement: isMobile ? 'right' :'top'}"
                    />
                  </th>
                  <td>
                    {{ Number(artpoolInfo.votedTotalAmount) | currency }}<span class="unit">$PACE</span>
                  </td>
                  <th>
                    <img
                      src="/img/threespace/icon/kip_medal_icon.png"
                      class="kip"
                      v-c-tooltip="{content: $t('stake.iconInfo.info4'), placement: isMobile ? 'right' :'top'}"
                    />
                  </th>
                  <td v-if="artpoolInfo.kipNftPool !== undefined">
                    {{ Number(artpoolInfo.kipNftPool.winningVote) | currency }}
                    <span class="unit">oPACE / N</span>
                  </td>
                  <td v-else>
                    0
                    <span class="unit">oPACE / N</span>
                  </td>
                </tr>
              </b-table-simple>
            </div>
            <b-button class="online_gallery" @click="onClickGoOnlineGallery(artpoolInfo.externalLink)">
              {{ $t("stake.btn.onlineGallery") }}
            </b-button>
          </div>
          <template v-if="topRankList.topKips != undefined &&
                          topRankList.topErcs != undefined &&
                          topRankList.topKips.length > 0 &&
                          topRankList.topErcs.length > 0"
          >
            <div class="pool_top_rank_list">
              <div class="top_rank_title">
                Trending
              </div>
              <div class="top_rank_slide">
                <slick ref="slickA" :options="slickOptions" v-if="!isTopRankBusy">
                  <template v-for="(item, idx) in topRankLength">
                    <div class="top_rank_row" :key="'top_rank_row' + idx">
                      <div class="top_rank_item" v-if="topKips[idx] != undefined">
                        <div class="img_box">
                          <template v-if="topKips[idx].imageUrl !== undefined && topKips[idx].imageUrl !== null && topKips[idx].imageUrl !== ''">
                            <b-img :src="getCloudFrontImageUrl(topKips[idx].imageUrl)"></b-img>
                          </template>
                        </div>
                        <div class="text_box">
                          <div class="main_text">
                            <h2>
                              <template v-if="activeLanguage !== undefined && activeLanguage === 'ko'">
                                {{ topKips[idx].title || topKips[idx].titleEn }}
                              </template>
                              <template v-else>
                                {{ topKips[idx].titleEn || topKips[idx].title }}
                              </template>
                            </h2>
                            <p>
                              #{{ topKips[idx].tokenId }} ({{ topKips[idx].nftType }})<br/>
                              <span>
                                <template v-if="activeLanguage !== undefined && activeLanguage === 'ko'">
                                  Artist @{{ topKips[idx].artist || topKips[idx].artistEn }}
                                </template>
                                <template v-else>
                                  Artist @{{ topKips[idx].artistEn || topKips[idx].artist }}
                                </template>
                              </span>
                            </p>
                          </div>
                          <div class="vote_text">
                            <p class="pace">
                              <img src="/img/threespace/artpool/pace_amount_icon.png" v-if="topKips[idx].nftType === 'ERC-721'" />
                              <img src="/img/threespace/artpool/kpace_amount_icon.png" v-else />
                              {{ Number(topKips[idx].voteAmount) | currency }}
                            </p>
                            <p class="kpace">
                              <img src="/img/threespace/icon/vote_icon.png" />
                              {{ Number(topKips[idx].userVoteAmount) | currency }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="top_rank_item" v-else></div>
                      <div class="top_rank_item" v-if="topErcs[idx] != undefined">
                        <div class="img_box">
                          <template v-if="topErcs[idx].imageUrl !== undefined && topErcs[idx].imageUrl !== null && topErcs[idx].imageUrl !== ''">
                            <b-img :src="getCloudFrontImageUrl(topErcs[idx].imageUrl)"></b-img>
                          </template>
                        </div>
                        <div class="text_box">
                          <div class="main_text">
                            <h2>
                              <template v-if="activeLanguage !== undefined && activeLanguage === 'ko'">
                                {{ topErcs[idx].title || topErcs[idx].titleEn }}
                              </template>
                              <template v-else>
                                {{ topErcs[idx].titleEn || topErcs[idx].title }}
                              </template>
                            </h2>
                            <p>
                              #{{ topErcs[idx].tokenId }} ({{ topErcs[idx].nftType }})<br/>
                              <span>
                                <template v-if="activeLanguage !== undefined && activeLanguage === 'ko'">
                                  Artist @{{ topErcs[idx].artist || topErcs[idx].artistEn }}
                                </template>
                                <template v-else>
                                  Artist @{{ topErcs[idx].artistEn || topErcs[idx].artist }}
                                </template>
                              </span>
                            </p>
                          </div>
                          <div class="vote_text">
                            <p class="pace">
                              <img src="/img/threespace/artpool/pace_amount_icon.png" v-if="topErcs[idx].nftType === 'ERC-721'" />
                              <img src="/img/threespace/artpool/kpace_amount_icon.png" v-else />
                              {{ Number(topErcs[idx].voteAmount) | currency }}
                            </p>
                            <p class="kpace">
                              <img src="/img/threespace/icon/vote_icon.png" />
                              {{ Number(topErcs[idx].userVoteAmount) | currency }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="top_rank_item" v-else></div>
                    </div>
                  </template>
                </slick>
              </div>
            </div>
          </template>
          <div class="pool_info" v-else>
            <div>
              <p><span v-html="$t('stake.details.01')"></span></p>
            </div>
            <div>
              <p>{{ $t("stake.details.02.title") }} <br /><span v-html="$t('stake.details.02.description')"></span></p>
            </div>
            <div>
              <p>{{ $t("stake.details.03.title") }} <br /><span v-html="$t('stake.details.03.description')"></span></p>
            </div>
          </div>
        </div>

        <!-- pool remain date -->
        <aside class="remain_date_side">
          <img src="/img/threespace/icon/time_icon.png" />
          <div v-html="remainVoteDate"></div>
          <div v-html="remainPoolDate"></div>
        </aside>

        <!-- pool_tabs ==================== -->
        <div class="pool_tabs">
          <div class="pool_tab" :class="{ 'active': tab.total }" @click="onClickSearch(null)">{{ $t("stake.nft.tab.all") }}</div>
          <div class="pool_tab" :class="{ 'active': tab.erc721 }" @click="onClickSearch('ERC-721')">ERC-721</div>
          <div class="pool_tab" :class="{ 'active': tab.kip17 }" @click="onClickSearch('KIP-17')">KIP-17</div>
        </div>

        <!-- pool_content ==================== -->
        <div class="pool_content">
          <div class="check_box">
            <b-form-checkbox v-model="externalParams.keywordStaked" @change="onClickDetailSearch">
              {{ $t("stake.nftDetail.viewOnlyStaked") }}
            </b-form-checkbox>
            <b-form-checkbox class="ckeckbox2" v-model="externalParams.keywordVoted" @change="onClickDetailSearch">
              {{ $t("stake.nftDetail.viewOnlyVoted") }}
            </b-form-checkbox>
            <div class="vote_summary_box">
                <img src="/img/threespace/artpool/pace_amount_icon.png" class="pace" />
                <img src="/img/threespace/artpool/kpace_amount_icon.png" class="kpace" />
                {{ $t("stake.productVoteCount") }}
                <img src="/img/threespace/icon/vote_icon.png" class="vote" />
                <span>{{ $t("stake.myVoteCount") }}</span>
            </div>
          </div>
          <div class="pool_list">
            <!-- loading -->
            <template v-if="isBusy">
              <div class="sk-wave mx-auto mt-5 pool_list_loading">
                <div class="sk-wave-rect"></div>
                <div class="sk-wave-rect"></div>
                <div class="sk-wave-rect"></div>
                <div class="sk-wave-rect"></div>
                <div class="sk-wave-rect"></div>
              </div>
            </template>
            <!-- loading end -->
            <template v-else>
              <!-- list not empty -->
              <template v-if="artpoolCollectionItemsContent != undefined && artpoolCollectionItemsContent.length > 0">
                <template v-for="(item, i) in artpoolCollectionItemsContent">
                  <div class="pool_row" :key="'pool-item' + i">
                    <div class="img_box" @click="onClickProductImage(item.idxProduct)">
                      <!--
                      <div class="top_rank_tag" :class="{ pace_tag: item.nftType === 'ERC-721' }" v-if="item.topVoted">
                        <img src="/img/threespace/icon/crown_icon.png" />
                      </div>
                      -->
                      <template v-if="item.imageUrl !== undefined && item.imageUrl !== null && item.imageUrl !== ''">
                        <b-img :src="getCloudFrontImageUrl(item.imageUrl)"></b-img>
                      </template>
                    </div>
                    <div class="text_box">
                      <h3>
                        <template v-if="activeLanguage !== undefined && activeLanguage === 'ko'">
                          {{ item.title || item.titleEn }}
                        </template>
                        <template v-else>
                          {{ item.titleEn || item.title }}
                        </template>
                      </h3>
                      <p class="video_icon">
                        <v-icon v-if="item.video === true">mdi-video-outline</v-icon>
                      </p>
                      <p class="artist">
                        <template v-if="activeLanguage !== undefined && activeLanguage === 'ko'">
                          Artist @{{ item.artist || item.artistEn }}
                        </template>
                        <template v-else>
                          Artist @{{ item.artistEn || item.artist }}
                        </template>
                      </p>
                      <p class="token_id">
                        #{{ item.tokenId }} ({{ item.nftType }})
                      </p>
                      <!--TODO
                      <p class="owned">
                        Owned by @{{ item.owner }}
                      </p>-->
                      <div class="vote_info">
                        <p class="pace">
                          <img src="/img/threespace/artpool/pace_amount_icon.png" v-if="item.nftType === 'ERC-721'" />
                          <img src="/img/threespace/artpool/kpace_amount_icon.png" v-else />
                          {{ Number(item.voteAmount) | currency }}
                        </p>
                        <p class="kpace">
                          <img src="/img/threespace/icon/vote_icon.png" />
                          {{ Number(item.userVoteAmount) | currency }}
                        </p>
                      </div>
                      <div class="btn_box">
                        <CButton @click="onClickVote(item)" :class="{ pace_vote: item.nftType === 'ERC-721'}">
                          {{ $t("stake.btn.vote") }}
                        </CButton>
                      </div>
                    </div>
                  </div>
                </template>
              </template>
              <!-- list empty -->
              <template v-else>
                <p class="pool_list_empty">
                  <template v-if="activeLanguage !== undefined && activeLanguage === 'ko'">
                    {{ artpoolInfo.title || artpoolInfo.titleEn }}에 조회된 {{ currentTab !== "total" ? currentTab : ''}} 작품이 없습니다.
                  </template>
                  <template v-else>
                    There are no {{ currentTab !== "total" ? currentTab : ''}} works yet staked in {{ artpoolInfo.titleEn || artpoolInfo.title }}
                  </template>
                </p>
              </template>
            </template>
          </div>
          <div class="row">
            <div class="col-12 text-right px-5">
              <b-pagination
                v-on:change="onPaging"
                :total-rows="totalRows"
                v-model="currentPage"
                :per-page="perPage"
                hide-ellipsis
                limit="10"
                aria-controls="role-function-list"
                class="float-right gallery_pagination"
              >
                <template #first-text>
                  <img
                    class="page_icon rotate_page_icon"
                    src="/img/threespace/icon/pagination_arrow2_icon.png"
                  />
                </template>
                <template #prev-text>
                  <img
                    class="page_icon rotate_page_icon"
                    src="/img/threespace/icon/pagination_arrow_icon.png"
                  />
                </template>
                <template #next-text>
                  <img
                    class="page_icon"
                    src="/img/threespace/icon/pagination_arrow_icon.png"
                  />
                </template>
                <template #last-text>
                  <img
                    class="page_icon"
                    src="/img/threespace/icon/pagination_arrow2_icon.png"
                  />
                </template>
                <template #page="{ page }">
                  <span :class="{active_num: page === currentPage}">
                    {{ page }}
                  </span>
                </template>
              </b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>

    <NftVoteApproveModal
        ref="voteApproveModal"
        @onClickVoteApproveBtn="setVoteApproval"
    />
    <NftPoolVoteModal
        ref="nftPoolVoteModal"
        :vote-type-info="voteTypeInfo"
        @onClickNftVoteBtn="executeVote"
    />

    <NftPoolProductDetail ref="nftPoolProductDetail" />

    <b-modal
      id="klipModal"
      ref="klipModal"
      hide-header
      hide-footer
      centered
      body-class="text-center text-black my-4"
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      @hide="closeModal"
    >
      <CRow>
        <CCol class="col-12">
          <CLink @click="$refs.klipModal.hide()">
            <CIcon name="cil-x" class="float-right" size="lg" />
          </CLink>
        </CCol>
      </CRow>

      <!-- 카카오톡 클립(PC)으로 연결 -->
      <template v-if="form.isKakaoKlipPc">
        <div class="w-100 d-flex justify-content-center align-items-center">
          <img
              src="/img/threespace/company/kakao_klip_logo_color.png"
              width="50"
              class="float-left"
          />
          <h5 class="ml-2 mb-0">{{ $t("wallet.modal.klipQRTitle") }}</h5>
        </div>
        <div class="w-100 d-flex justify-content-center">
          <img :src="klip.qrcode" width="160" height="160" class="border-1" />
        </div>
        <div class="w-100 text-center">
          <p>
            <span class="text-gray mr-2">{{ $t("wallet.modal.remainTime") }}</span>
            <span class="text-danger">{{ time }}</span>
          </p>
        </div>
        <div class="w-100 text-center mt-3">
          <p>
            {{ $t("wallet.modal.klipQRdescription") }}
          </p>
          <p class="text-gray">
            {{ $t("wallet.modal.klipQRdescription2") }}
          </p>
        </div>
        <div class="row d-flex justify-content-center align-items-center mt-3">
          <div class="col-2">
            <img src="/img/threespace/payment/kakaotalk-logo.svg" />
          </div>
          <div class="col-1">
            <img src="/img/threespace/payment/pointer-right.svg" />
          </div>
          <div class="col-2">
            <img src="/img/threespace/payment/kakaotalk-search.svg" />
          </div>
          <div class="col-1">
            <img src="/img/threespace/payment/pointer-right.svg" />
          </div>
          <div class="col-2">
            <img src="/img/threespace/payment/kakaotalk-scan.svg" />
          </div>
        </div>
        <div
            class="row d-flex justify-content-center align-items-center text-center mt-2 mb-5"
        >
          <div class="col-4">{{ $t("wallet.klip.exeKakao") }}</div>
          <div class="col-3">{{ $t("wallet.klip.search") }}</div>
          <div class="col-4">{{ $t("wallet.klip.scanCode") }}</div>
        </div>
      </template>
      <!-- 카카오톡 클립(모바일)으로 연결 -->
      <template v-if="form.isKakaoKlipMobile">
        <div class="w-100 text-center my-5">
          <p>
            <span class="text-gray mr-2">{{ $t("wallet.modal.remainTime") }}</span>
            <span class="text-danger">{{ time }}</span>
          </p>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {mapState, createNamespacedHelpers, mapGetters, mapActions} from "vuex";
const artpoolHelper = createNamespacedHelpers("artpool");
const authHelper = createNamespacedHelpers("auth");
import Caver from "caver-js";
import Web3 from "web3";

import NftVoteApproveModal from "@/views/threespace/components/artpool/NftVoteApproveModal.vue";
import NftPoolVoteModal from "@/views/threespace/components/artpool/NftPoolVoteModal.vue";
import NftPoolProductDetail from "@/views/threespace/components/artpool/NftPoolProductDetail.vue";
import {getResult, prepare, request} from "klip-sdk";
import QRCode from "qrcode";

import Slick from 'vue-slick';
import 'slick-carousel/slick/slick.css';
import WalletConnectUtil from "@/mixins/WalletConnectUtil";

export default {
  name: "NftPoolVoteV1",
  components: {
    NftVoteApproveModal,
    NftPoolVoteModal,
    NftPoolProductDetail,
    Slick
  },
  props: {
    idxArtpool: {
      type: Number,
      default: 0
    }
  },
  metaInfo() {
    return {
      title: this.$t("meta.artPool.title") + " | 3space Art",
      meta: [
        { vmid: "title", name: "title", content: this.$t("meta.artPool.title") + " | 3space Art" },
        { vmid: "description", name: "description", content: this.$t("meta.artPool.description") },
        { vmid: "og:title", property: "og:title", content: this.$t("meta.artPool.title") + " | 3space Art" },
        { vmid: "og:description", property: "og:description", content: this.$t("meta.artPool.description") },
        { vmid: "og:url", property: "og:url", content: window.location.origin + this.$route.path }
      ],
    }
  },
  mixins: [WalletConnectUtil],
  data: function () {
    return {
      slickOptions: {
        arrows: false,
        dots: false,
        infinite: true,
        autoplay: true,
        speed: 500,
        autoplaySpeed: 5000,
        fade: true,
        cssEase: 'linear'
      },
      isBusy: false,
      isTopRankBusy: false,
      perPage: 20,
      totalRows: 0,
      currentPage: 1,
      sort: [
        { id: "updatedAt", desc: "desc" },
        { id: "idx", desc: "desc" }
      ],
      externalParams: {
        keywordIdxArtpool: this.idxArtpool,
        keywordRfc: null,
        keywordStaked: false,
        keywordVoted: false
      },
      nftStakingInfo: {},
      voteTypeInfo: {
        tokenSymbol: "",
        maxVote: "",
        userAmountVoted: "",
        userRemainingVote: ""
      },
      tab: this.getInitTabStatus(),
      date: this.$moment(60 * 3 * 1000),
      form: this.getInitPage(),
      klip: this.getInitKakaoKlip(),
      poolContractAddr: null,
      hasKlaytnAddr: false,
      hasEthereumAddr: false,
      isOpenApprove: false,
      isOpenVote: false,
      selectedItem: null,
      votingAmount: 0,
      cfOptions: {
        w: 330,
        h: 390,
        f: "webp",
        q: 90
      },
      remainPoolDate: "",
      poolTimer: null,
      remainVoteDate: "",
      voteTimer: null,
      topRankSlide: 0,
    }
  },
  computed: {
    ...artpoolHelper.mapState([
      "artpoolInfo",
      "artpoolCollectionItems",
      "artpoolCollectionItemsContent",
      "topRankList"
    ]),
    ...mapState({
      me: (state) => {
        return state.auth.me || {};
      }
    }),
    ...mapGetters("auth", ["isAuthenticated"]),
    ...mapState(["wallet"]),
    activeLanguage() {
      return this.getActiveLanguage();
    },
    isMobile() {
      return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    },
    getKlaytnWalletProvider() {
      const walletInfo = JSON.parse(localStorage.getItem("walletInfo"));
      const walletProvider = this.me.klaytnProvider || walletInfo.walletProvider ;

      return walletProvider;
    },
    getEthereumWalletProvider() {
      const walletInfo = JSON.parse(localStorage.getItem("walletInfo"));
      const walletProvider = this.me.ethereumProvider || walletInfo.walletProvider ;

      return walletProvider;
    },
    currentTab() {
      let currentTab = Object.keys(this.tab).find(el => this.tab[el] === true)
      return currentTab;
    },
    time: function() {
      return this.date.format("mm : ss");
    },
    topRankLength() {
      if (
        this.topRankList.topKips != undefined &&
        this.topRankList.topErcs != undefined &&
        this.topRankList.topKips.length > 0 &&
        this.topRankList.topErcs.length > 0
      ) {
         if (this.topRankList.topErcs.length >= this.topRankList.topKips.length) {
            return this.topRankList.topErcs.length;
         } else {
            return this.topRankList.topKips.length;
         }
      }
      return 0
    },
    topErcs() {
      if (
        this.topRankList.topErcs != undefined &&
        this.topRankList.topErcs.length > 0
      ) {
        return this.topRankList.topErcs;
      }
      return [];
    },
    topKips() {
      if (
        this.topRankList.topKips != undefined &&
        this.topRankList.topKips.length > 0
      ) {
        return this.topRankList.topKips;
      }
      return [];
    }
  },
  mounted() {
    this.tab.total = true;
    this.$nextTick(() => {
      if (this.idxArtpool !== 0) {
        this.getArtpool({ idxArtpool: this.idxArtpool }).then(() => {
          this.getRemainTimeVote();
          this.getRemainTimePool();
        });
        this.resetArtpoolCollections();
        this.requestArtpoolCollections();
        this.requestArtpoolTopRankList();
      }
    });
  },
  methods: {
    ...artpoolHelper.mapActions([
        "getArtpool"
      , "getMyNftStakingInfo"
      , "getArtpoolCollections"
      , "getKlaytnVoteApprovalStatus"
      , "getEthereumVoteApprovalStatus"
      , "completeVote"
      , "getArtpoolCollectionsTopRankList"
    ]),
    ...artpoolHelper.mapMutations(["resetArtpoolCollections"]),
    ...authHelper.mapActions(["verifyTokenCallback"]),
    ...mapActions("wallet", ["getKlaytnTokenBalance", "getPaceBalance"]),
    ...mapActions("gallery", ["getArtworkFile"]),
    getActiveLanguage() {
      return localStorage.getItem("language") || "en";
    },
    getCloudFrontImageUrl(imageUrl) {
      return `${imageUrl}?w=${this.cfOptions.w}&h=${this.cfOptions.h}&f=${this.cfOptions.f}&q=${this.cfOptions.q}`;
    },
    getRemainTimeVote() {
      var end = new Date();

      var _second = 1000;
      var _minute = _second * 60;
      var _hour = _minute * 60;
      var _day = _hour * 24;

      if (this.$moment().isBefore(this.artpoolInfo.voteStartDate)) {
        // 투표시작 전
        end = new Date(this.artpoolInfo.voteStartDate);
      } else if (this.$moment().isBetween(this.artpoolInfo.voteStartDate, this.artpoolInfo.voteEndDate)) {
        // 투표 중
        end = new Date(this.artpoolInfo.voteEndDate);
      } else {
        // 투표 끝
        this.remainVoteDate = this.$t("stake.voteIsOver");
        return;
      }
      this.voteTimer = setInterval(() => {
        var now = new Date();
        var distance = end - now;
        if (distance < 0) {

            clearInterval(this.voteTimer);
            this.remainVoteDate = 'EXPIRED!';

            return;
        }
        var days = Math.floor(distance / _day);
        var hours = Math.floor((distance % _day) / _hour);
        if ((hours.toString()).length === 1) {
          hours = "0" + hours;
        }
        var minutes = Math.floor((distance % _hour) / _minute);
        if ((minutes.toString()).length === 1) {
          minutes = "0" + minutes;
        }
        var seconds = Math.floor((distance % _minute) / _second);
        if ((seconds.toString()).length === 1) {
          seconds = "0" + seconds;
        }

        if (this.$moment().isBefore(this.artpoolInfo.voteStartDate)) {
          // 투표시작 전
          this.remainVoteDate = this.$t("stake.voteRemainStartDate", {days: days, times: `${hours}:${minutes}:${seconds}`})
        } else if (this.$moment().isBetween(this.artpoolInfo.voteStartDate, this.artpoolInfo.voteEndDate)) {
          // 투표 중
          this.remainVoteDate = this.$t("stake.voteRemainEndDate", {days: days, times: `${hours}:${minutes}:${seconds}`})
        }
      }, 1000);
    },
    getRemainTimePool() {
      var end = new Date();

      var _second = 1000;
      var _minute = _second * 60;
      var _hour = _minute * 60;
      var _day = _hour * 24;

      if (this.$moment().isBefore(this.artpoolInfo.poolStartDate)) {
        // 투표시작 전
        end = new Date(this.artpoolInfo.poolStartDate);
      } else if (this.$moment().isBetween(this.artpoolInfo.poolStartDate, this.artpoolInfo.poolEndDate)) {
        // 투표 중
        end = new Date(this.artpoolInfo.poolEndDate);
      } else {
        // 투표 끝
        this.remainPoolDate = this.$t("stake.poolIsOver");
        return;
      }
      this.poolTimer = setInterval(() => {
        var now = new Date();
        var distance = end - now;
        if (distance < 0) {

            clearInterval(this.poolTimer);
            this.remainPoolDate = 'EXPIRED!';

            return;
        }
        var days = Math.floor(distance / _day);
        var hours = Math.floor((distance % _day) / _hour);
        if ((hours.toString()).length === 1) {
          hours = "0" + hours;
        }
        var minutes = Math.floor((distance % _hour) / _minute);
        if ((minutes.toString()).length === 1) {
          minutes = "0" + minutes;
        }
        var seconds = Math.floor((distance % _minute) / _second);
        if ((seconds.toString()).length === 1) {
          seconds = "0" + seconds;
        }

        if (this.$moment().isBefore(this.artpoolInfo.poolStartDate)) {
          // 투표시작 전
          this.remainPoolDate = this.$t("stake.poolRemainStartDate", {days: days, times: `${hours}:${minutes}:${seconds}`})
        } else if (this.$moment().isBetween(this.artpoolInfo.poolStartDate, this.artpoolInfo.poolEndDate)) {
          // 투표 중
          this.remainPoolDate = this.$t("stake.poolRemainEndDate", {days: days, times: `${hours}:${minutes}:${seconds}`})
        }
      }, 1000);
    },
    onClickProductImage(idxProduct) {
      this.getArtworkFile(idxProduct).then(data => {
        if (
          Object.keys(data).length !== 0 && JSON.stringify(data) !== JSON.stringify({})
        ) {
          this.$refs["nftPoolProductDetail"].show();
        }
      });
    },
    requestArtpoolCollections() {
      this.isBusy = true;
      this.getArtpoolCollections(
        this.getPagingParams(
          {
            page: this.currentPage - 1,
            pageSize: this.perPage,
            sorted: this.sort,
            filtered: [],
          },
          this.externalParams
        )
      )
      .then(() => {
        this.totalRows = this.artpoolCollectionItems.totalElements;
        this.isBusy = false;
      })
      .catch(error => {
        this.isBusy = false;
        this.$log.error(error);
      });
    },
    requestArtpoolTopRankList() {
      this.isTopRankBusy = true;
      this.getArtpoolCollectionsTopRankList(this.idxArtpool)
      .then(() => {
        this.isTopRankBusy = false;
      })
      .catch(error => {
        this.isTopRankBusy = false;
      });
    },
    onClickVote(item) {
      this.selectedItem = item;
      let nowInSeconds = Date.now() / 1000;
      if (this.selectedItem.nftType === "KIP-17") {
        if (nowInSeconds >= parseInt(this.artpoolInfo.kipNftPool.voteStartTimestamp) && nowInSeconds <= parseInt(this.artpoolInfo.kipNftPool.voteEndTimestamp)) {
          this.checkKlaytnAddr()
            .then((hasKlatynAddr) => {
              if (hasKlatynAddr) {
                this.poolContractAddr = this.artpoolInfo.kipNftPool.contractAddress;
                this.getKlaytnVoteApprovalStatus()
                  .then((isApproved) => {
                    if (isApproved) {
                      this.onOpenVoteModal(this.selectedItem);
                    } else {
                      this.isOpenApprove = true;
                      this.$refs["voteApproveModal"].show();
                    }
                  });
              }
            });
        } else {
          alert(this.$t("stake.vote.notInProgress"));
        }
      } else if (this.selectedItem.nftType === "ERC-721") {
        if (nowInSeconds >= parseInt(this.artpoolInfo.ercNftPool.voteStartTimestamp) && nowInSeconds <= parseInt(this.artpoolInfo.ercNftPool.voteEndTimestamp)) {
          this.checkEthereumAddr()
            .then((hasEthereumAddr) => {
              if (hasEthereumAddr) {
                this.poolContractAddr = this.artpoolInfo.ercNftPool.contractAddress;
                this.getEthereumVoteApprovalStatus().then((isApproved) => {
                  if (isApproved) {
                    this.onOpenVoteModal(this.selectedItem);
                  } else {
                    this.isOpenApprove = true;
                    this.$refs["voteApproveModal"].show();
                  }
                });
              }
            });
        } else {
          alert(this.$t("stake.vote.notInProgress"));
        }
      } else {
        alert(this.$t("stake.notSupported"));
      }
    },
    onOpenVoteModal(item) {
      // 사용자 투표 정보 조회
      this.getMyNftStakingInfo({ idxArtpool: this.idxArtpool })
        .then(data => {
          this.nftStakingInfo = data;

          if (item.nftType === 'KIP-17') {
            this.voteTypeInfo.tokenSymbol = "oPACE";
            this.voteTypeInfo.maxVote = this.artpoolInfo.kipNftPool.maxVote;
            this.voteTypeInfo.userAmountVoted = parseInt(this.nftStakingInfo.kipUserData.userAmountVoted);
            this.voteTypeInfo.userRemainingVote = parseInt(this.artpoolInfo.kipNftPool.maxVote) - parseInt(this.nftStakingInfo.kipUserData.userAmountVoted);
          } else if (item.nftType === 'ERC-721') {
            this.voteTypeInfo.tokenSymbol = "PACE";
            this.voteTypeInfo.maxVote = parseInt(this.artpoolInfo.ercNftPool.maxVote);
            this.voteTypeInfo.userAmountVoted = parseInt(this.nftStakingInfo.ercUserData.userAmountVoted);
            this.voteTypeInfo.userRemainingVote = parseInt(this.artpoolInfo.ercNftPool.maxVote) - parseInt(this.nftStakingInfo.ercUserData.userAmountVoted);
          } else {
            this.$log.info(this.$t("stake.notSupported"));
            return;
          }

          this.isOpenVote = true;
          this.$refs["nftPoolVoteModal"].show();
        });
    },
    setVoteApproval() {
      let walletProvider = "";
      if (this.selectedItem.nftType === 'KIP-17') {
        walletProvider = this.getKlaytnWalletProvider;
      } else if (this.selectedItem.nftType === 'ERC-721') {
        walletProvider = this.getEthereumWalletProvider;
      }

      const approvalABI = this.getApprovalABI();

      if (walletProvider === "Kaikas") {
        this.kaikasApproval(approvalABI);
      } else if (walletProvider === "Klip") {
        this.klipApproval(approvalABI);
      } else if (walletProvider === "MetaMask") {
        this.metamaskApproval(approvalABI);
      } else if(walletProvider.includes("wc-")) {
        this.walletConnectApproval(approvalABI);
      } else {
        alert(this.$t("wallet.validation.checkProvider"));
      }
    },
    kaikasApproval(approvalABI) {
      if (this.checkKaikas()) {
        const caver = new Caver(window.klaytn);
        const amount = "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"; // 최대 수량 64bit
        const data = caver.klay.abi.encodeFunctionCall(
          approvalABI,
          [this.poolContractAddr, amount]
        )

        caver.klay.sendTransaction({
          type: 'SMART_CONTRACT_EXECUTION',
          from: this.me.klaytnAddr,
          to: process.env.VUE_APP_KPACE_ADDRESS,
          gas: 300000,
          data
        })
        .on('error', this.closeApprove)
        .on('receipt', this.onCompleteKlaytnApproval);
      }
    },
    onCompleteKlaytnApproval(receipt){
      if (receipt.status) {
        this.onOpenVoteModal(this.selectedItem);
      } else {
        alert(this.$t("stake.failApprove"));
      }
      this.closeApprove();
    },
    closeApprove(){
      this.isOpenApprove = false;
      this.$refs["voteApproveModal"].hide();
    },
    klipApproval(approvalABI) {
      const bappName = "3space Art";
      const from = this.me.klaytnAddr;
      const to = process.env.VUE_APP_KPACE_ADDRESS;
      const value = "0";
      const _amount = 16 ** 64;
      const amount = _amount.toLocaleString('fullwide', {useGrouping:false});
      const abi = JSON.stringify(approvalABI);
      const params = `["${this.poolContractAddr}", "${amount}"]`;

      prepare
        .executeContract({ bappName, from, to, value, abi, params })
        .then(data => {
          this.klip.requestKey = data.request_key;
          this.sendNext(data, "approve");
        })
        .catch(error => {
          this.$log.error(error);
          this.closeApprove();
          alert(this.$t("stake.failRequest"));
        });
    },
    metamaskApproval(approvalABI) {
      if (this.checkMetamask()) {
        const web3 = new Web3(window.ethereum);
        const amount = "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"; //최대 수량 64bit
        const data = web3.eth.abi.encodeFunctionCall(
            approvalABI,
            [this.poolContractAddr, amount]
        )

        web3.eth.sendTransaction({
          from: this.me.ethereumAddr,
          to: process.env.VUE_APP_PACE_ADDRESS,
          data
        })
        .on("transactionHash", (hash) => {
          this.$log.info("sendTransaction hash:", hash); //TODO
          this.$store.commit("setShowLoading", {
            show: true,
            msg: this.$t("loading.nftArtpool.wallet.prepared", {type: this.$t("loading.nftArtpool.type.vote")})
          });
        })
        .on("receipt", (result) => {
          this.$log.info("sendTransaction result:", result);  //TODO
          if (result.status) {
            this.onCompleteEthereumApproval(result);
            this.$store.commit("setShowLoading", {show: false, msg: ""});
          } else {
            this.$store.commit("setShowLoading", {show: false, msg: ""});
            this.closeApprove(result);
          }
        })
        .on("error", (error) => {
          this.$log.info("sendTransaction error:", error); //TODO
          this.$store.commit("setShowLoading", {show: false, msg: ""});
          this.closeApprove(error);
        });
      }
    },
    async walletConnectApproval(approvalABI) {
      const provider = await this.getWalletConnectProvider();

      if(provider){
        const web3 = new Web3(provider);
        const amount = "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"; //최대 수량 64bit
        const data = web3.eth.abi.encodeFunctionCall(
          approvalABI,
          [this.poolContractAddr, amount]
        );

        const tx = {
          from: this.me.ethereumAddr,
          to: process.env.VUE_APP_PACE_ADDRESS,
          data
        }

        const infuraWeb3 = new Web3(new Web3.providers.HttpProvider(process.env.VUE_APP_INFURA_URL))
        let maxPolling = 10;
        let pollingInterval = 15000;

        provider.request({
          method: 'eth_sendTransaction',
          params: [tx]
        })
          .then((hash)  => {
            this.$log.info("sendTransaction hash:", hash); //TODO
            this.$store.commit("setShowLoading", {
              show: true,
              msg: this.$t("loading.nftArtpool.wallet.prepared", {type: this.$t("loading.nftArtpool.type.vote")})
            });
            const timer = setInterval(() => infuraWeb3.eth.getTransactionReceipt(hash).then((result) => {
              if(maxPolling > 0) {
                if(result) {
                  if(result.status){
                    this.onCompleteEthereumApproval(result);
                    this.$store.commit("setShowLoading", {show: false, msg: ""});
                    clearInterval(timer);
                  }else {
                    this.$store.commit("setShowLoading", {show: false, msg: ""});
                    this.closeApprove(result);
                    clearInterval(timer);
                  }
                }
              } else {
                clearInterval(timer);
              }
              maxPolling--;
            }), pollingInterval);
          })
          .catch((error) => {
            this.$log.error("sendTransaction error:", error); //TODO
            this.$store.commit("setShowLoading", {show: false, msg: ""});
            this.closeApprove(error);
          })
      } else {
        alert(this.$t("wallet.validation.checkProvider"));
      }
    },
    onCompleteEthereumApproval(receipt){
      if (receipt.status) {
        this.onOpenVoteModal(this.selectedItem);
      } else {
        alert(this.$t("stake.failApprove"));
      }
      this.closeApprove();
    },
    executeVote(_votingAmount) {
      let walletProvider = "";
      let roundNumber = 0;
      if (this.selectedItem.nftType === "KIP-17") {
        walletProvider = this.getKlaytnWalletProvider;
        roundNumber = this.artpoolInfo.roundNoKip;
      } else if (this.selectedItem.nftType === "ERC-721") {
        walletProvider = this.getEthereumWalletProvider;
        roundNumber = this.artpoolInfo.roundNoErc;
      }

      const voteABI = this.getVoteABI();
      const params = {
        round: roundNumber,
        nftAddress: this.selectedItem.contractAddr,
        tokenId: this.selectedItem.tokenId,
        amount: Caver.utils.toPeb(_votingAmount, 'KLAY')
      };

      this.votingAmount = _votingAmount;
      if (walletProvider === "Kaikas") {
        this.kaikasVote(voteABI, params);
      } else if (walletProvider === "MetaMask") {
        this.metamaskVote(voteABI, params);
      } else if (walletProvider === "Klip") {
        this.klipVote(voteABI, params);
      } else if(walletProvider.includes("wc-")) {
        this.walletConnectVote(voteABI, params);
      } else {
        alert(this.$t("wallet.validation.checkProvider"));
      }
    },
    kaikasVote(voteABI, params) {
      if (this.checkKaikas()) {
        const caver = new Caver(window.klaytn);
        const data = caver.klay.abi.encodeFunctionCall(
            voteABI,
            [params.round, params.nftAddress, params.tokenId, params.amount]
        );

        caver.klay.sendTransaction({
          type: "SMART_CONTRACT_EXECUTION",
          from: this.me.klaytnAddr,
          to: this.poolContractAddr,
          gas: 300000,
          data
        })
        .on("error", this.onErrorNftVote)
        .on("receipt", this.onCompleteNftVote);
      }
    },
    metamaskVote(voteABI, params) {
      if (this.checkMetamask()) {
        const web3 = new Web3(window.ethereum);
        const data = web3.eth.abi.encodeFunctionCall(
            voteABI,
            [params.round, params.nftAddress, params.tokenId, params.amount]
        );

        web3.eth.sendTransaction({
          from: this.me.ethereumAddr,
          to: this.poolContractAddr,
          data
        })
        .on("transactionHash", (hash) => {
          this.$log.info("sendTransaction hash:", hash); //TODO
          this.$store.commit("setShowLoading", {
            show: true,
            msg: this.$t("loading.nftArtpool.wallet.progressing", { type: this.$t("loading.nftArtpool.type.vote") })
          });
        })
        .on("receipt", (result) => {
          this.$log.info("sendTransaction result:", result);  //TODO
          if (result.status) {
            this.onCompleteNftVote(result);
            this.$store.commit("setShowLoading", {show: false, msg: ""});
          } else {
            this.$store.commit("setShowLoading", {show: false, msg: ""});
            this.onErrorNftVote(result);
          }
        })
        .on("error", (error) => {
          this.$log.info("sendTransaction error:", error); //TODO
          this.$store.commit("setShowLoading", {show: false, msg: ""});
          this.onErrorNftVote(error);
        });
      }
    },
    klipVote(voteABI, payload) {
      const bappName = "3space Art";
      const from = this.me.klaytnAddr;
      const to = this.poolContractAddr;
      const value = "0";
      const abi = JSON.stringify(voteABI);
      const params = `["${payload.round}", "${payload.nftAddress}", "${payload.tokenId}", "${payload.amount}"]`;

      prepare
        .executeContract({ bappName, from, to, value, abi, params })
        .then(data => {
          this.klip.requestKey = data.request_key;
          this.sendNext(data, "vote");
        })
        .catch(error => {
          this.$log.error(error);
          this.onErrorNftVote();
          alert(this.$t("stake.failRequest"));
        });
    },
    async walletConnectVote(voteABI, params){
      const provider = await this.getWalletConnectProvider();

      if(provider){
        const web3 = new Web3(provider);
        const data = web3.eth.abi.encodeFunctionCall(
          voteABI,
          [params.round, params.nftAddress, params.tokenId, params.amount]
        );

        const tx = {
          from: this.me.ethereumAddr,
          to: this.poolContractAddr,
          data
        }

        const infuraWeb3 = new Web3(new Web3.providers.HttpProvider(process.env.VUE_APP_INFURA_URL))
        let maxPolling = 10;
        let pollingInterval = 15000;

        provider.request({
          method: 'eth_sendTransaction',
          params: [tx]
        })
          .then((hash)  => {
            this.$log.info("sendTransaction hash:", hash); //TODO
            this.$store.commit("setShowLoading", {
              show: true,
              msg: this.$t("loading.nftArtpool.wallet.prepared", {type: this.$t("loading.nftArtpool.type.vote")})
            });
            const timer = setInterval(() => infuraWeb3.eth.getTransactionReceipt(hash).then((result) => {
              if(maxPolling > 0) {
                if(result) {
                  if(result.status){
                    this.onCompleteNftVote(result);
                    this.$store.commit("setShowLoading", {show: false, msg: ""});
                    clearInterval(timer);
                  }else {
                    this.$store.commit("setShowLoading", {show: false, msg: ""});
                    this.onErrorNftVote(result);
                    clearInterval(timer);
                  }
                }
              } else {
                if(confirm(this.$t("stake.waitBlockchain"))){
                  maxPolling += 5;
                }else {
                  this.$store.commit("setShowLoading", {show: false, msg: ""});
                  clearInterval(timer);
                }
              }
              maxPolling--;
            }), pollingInterval);
          })
          .catch((error) => {
            this.$log.error("sendTransaction error:", error);
            this.$store.commit("setShowLoading", {show: false, msg: ""});
            this.onErrorNftVote(error);
          })
      } else {
        alert(this.$t("wallet.validation.checkProvider"));
      }
    },
    onErrorNftVote(error) {
      this.$log.info("onErrorNftVote", error);
      alert(this.$t("stake.vote.fail"));
      this.closeNftVote();
    },
    onCompleteNftVote(receipt) {
      if (receipt.status) {
        this.closeNftVote();

        // 투표 결과 저장
        const param = {
          idxArtpool: this.artpoolInfo.idx,
          idxCollection: this.selectedItem.idx,
          walletAddr: receipt.from,
          amount: this.votingAmount,
          transactionHash: receipt.transactionHash
        };
        this.completeVote(param)
          .then(data => {
            this.requestArtpoolCollections();
            this.requestArtpoolTopRankList();
            this.$store.commit("setCommonAlertModalInfo", {
              msgHtml: this.$t("stake.vote.success"),
              show: true,
              fontSize: "sm"
            })
          })
          .catch(error => {
            this.$log.error(error);
          });
      } else {
        this.$store.commit("setCommonAlertModalInfo", {
          msgHtml: this.$t("stake.vote.fail"),
          show: true,
          fontSize: "sm"
        })
        this.closeNftVote();
      }
    },
    closeNftVote(){
      this.isOpenVote = false;
      this.$refs["nftPoolVoteModal"].hide();
    },
    // Check Wallet Address >>>
    checkKlaytnAddr() {
      return new Promise((resolve, reject) => {
        if (this.isAuthenticated) {
          this.verifyTokenCallback()
            .then(() => {
              if (
                  this.me === undefined ||
                  this.me === null ||
                  this.me.idx === undefined ||
                  this.me.idx === null ||
                  this.me.idx === ""
              ) {
                // 로그인
                this.hasKlaytnAddr = false;
                this.$store.commit("setCommonAlertModalInfo", {
                  msgHtml: this.$t("stake.requiredLogin"),
                  show: true,
                  fontSize: "sm"
                })
              } else {
                if (this.me.klaytnAddr === undefined || this.me.klaytnAddr === null || this.me.klaytnAddr === "") {
                  this.hasKlaytnAddr = false;
                  alert(this.$t("wallet.validation.requiredKlaytn"));
                } else {
                  this.getKlaytnTokenBalance(process.env.VUE_APP_KPACE_ADDRESS, this.me.klaytnAddr);
                  this.hasKlaytnAddr = true;
                }
              }
              resolve(this.hasKlaytnAddr);
            })
        } else {
          alert(this.$t("stake.requiredLogin"));
          reject(this.hasKlaytnAddr);
        }
      })
    },
    checkEthereumAddr() {
      return new Promise((resolve, reject) => {
        if (this.isAuthenticated) {
          this.verifyTokenCallback()
            .then(() => {
              if (
                  this.me === undefined ||
                  this.me === null ||
                  this.me.idx === undefined ||
                  this.me.idx === null ||
                  this.me.idx === ""
              ) {
                // 로그인
                this.hasEthereumAddr = false;
                alert(this.$t("stake.requiredLogin"));
              } else {
                if (this.me.ethereumAddr === undefined || this.me.ethereumAddr === null || this.me.ethereumAddr === "") {
                  this.hasEthereumAddr = false;
                  alert(this.$t("wallet.validation.requiredEthereum"));
                } else {
                  this.getPaceBalance();
                  this.hasEthereumAddr = true;
                }
              }
              resolve(this.hasEthereumAddr);
            })
        } else {
          alert(this.$t("stake.requiredLogin"));
          reject(this.hasEthereumAddr);
        }
      })
    },
    // Check Wallet Provider >>>
    checkKaikas() {
      if (window.klaytn === undefined || !window.klaytn) {
        alert(this.$t("wallet.validation.notFoundKaikas"));
        return false;
      }

      const kaikas = window.klaytn;

      if (
          kaikas.selectedAddress !== undefined &&
          kaikas.selectedAddress !== null &&
          kaikas.selectedAddress !== ""
      ) {
        if (kaikas.selectedAddress.toLowerCase() !== this.me.klaytnAddr.toLowerCase()) {
          alert(this.$t("wallet.validation.checkWalletAddr"));
          return false;
        }
      } else {
        alert(this.$t("wallet.validation.checkWalletAddr"));
        return false;
      }

      if (
          kaikas.networkVersion === undefined ||
          kaikas.networkVersion === null ||
          kaikas.networkVersion.toString() !== process.env.VUE_APP_KLAYTN_CHAIN_ID
      ) {
        alert(this.$t("wallet.validation.checkNetwork"));
        return false;
      }
      return true;
    },
    checkMetamask() {
      if (window.ethereum === undefined || !window.ethereum) {
        alert(this.$t("wallet.validation.checkMetamask"));
        return false;
      }

      const metamask = window.ethereum;

      if (
          metamask.selectedAddress !== undefined &&
          metamask.selectedAddress !== null &&
          metamask.selectedAddress !== ""
      ) {
        if (metamask.selectedAddress.toLowerCase() !== this.me.ethereumAddr.toLowerCase()) {
          alert(this.$t("wallet.validation.checkWalletAddr"));
          return false;
        }
      } else {
        alert(this.$t("wallet.validation.checkWalletAddr"));
        return false;
      }

      if (
          metamask.networkVersion === undefined ||
          metamask.networkVersion === null ||
          metamask.networkVersion.toString() !== process.env.VUE_APP_ETHEREUM_CHAIN_ID
      ) {
        alert(this.$t("wallet.validation.checkNetwork"));
        return false;
      }
      return true;
    },
    onClickSearch(_rfc) {
      this.tab = this.getInitTabStatus();
      if (_rfc !== undefined && _rfc !== null && _rfc !== "") {
        if (_rfc === 'ERC-721') {
          this.tab.erc721 = true;
        } else if (_rfc === 'KIP-17') {
          this.tab.kip17 = true;
        }
      } else {
        this.tab.total = true;
      }
      this.currentPage = 1;
      this.externalParams.keywordRfc = _rfc;
      this.requestArtpoolCollections();
    },
    onClickStakedView() {
      this.requestArtpoolCollections();
    },
    onClickDetailSearch() {
      this.requestArtpoolCollections();
    },
    onPaging(page) {
      window.scrollTo(0, 0);
      this.currentPage = page;
      this.requestArtpoolCollections();
    },
    getPagingParams({ page, pageSize, sorted, filtered }, externalParams) {
      const params = {
        page,
        size: pageSize,
        ...externalParams
      };

      // sorting parameter
      let sorts = [];
      for (let i = 0, length = sorted.length; i < length; i++) {
        const sort = sorted[i];
        sorts.push(`${sort.id},${sort.desc ? "desc" : "asc"}`);
      }
      params["sort"] = sorts;

      // filtering parameter
      for (let i = 0, length = filtered.length; i < length; i++) {
        const filter = filtered[i];
        params[filter.id] = filter.value;
      }

      return params;
    },
    onClickGoOnlineGallery(externalLink) {
      if (externalLink !== undefined && externalLink !== null && externalLink !== "") {
        window.open(externalLink, "_blank");
      } else {
        this.$log.info("External link information does not exist.");
      }
    },
    sendNext(data, functionType) {
      if (data.err) {
        this.closeOpenedModal();
        if (data.code === 6412) {
          alert(this.$t("stake.claim.failAmount"));
        } else {
          alert(this.$t("stake.failRequest") + "\n[" + data.code + "] " + data.err);
        }
      } else {
        if (data.request_key) {
          // 타이머
          this.isTimeout = false;
          this.date = this.$moment(60 * 3 * 1000); // 3분
          clearInterval(this.timer);
          clearInterval(this.totalTimer);

          if (this.isMobile){
            // 카카오톡 클립 > 정보제공 요청
            request(data.request_key, true);
            this.form = this.getInitPage();
            this.form.isKakaoKlipMobile = true;
          } else {
            // QR 코드 생성
            this.createQRCode("https://klipwallet.com/?target=/a2a?request_key=" + data.request_key);
            if (this.klip.qrcode != null) {
              this.$refs["klipModal"].show();
            }
          }
          // 결과 조회 전체 타이머(QR 남은 시간)
          this.setTotalTimer();
          // 결과 조회
          this.setResultTimer(data.request_key, functionType);
        } else {
          this.closeOpenedModal();
          if(this.isMobile){
            alert(this.$t("stake.failTryAgain"));
          } else {
            alert(this.$t("stake.failKakaoQR"));
          }
        }
      }
    },
    setResultTimer(reqeustKey, functionType) {
      if (!this.isTimeout) {
        this.timer = setInterval(() => {
          getResult(this.klip.requestKey)
            .then(data => {
              if (data.status === "completed") {
                if (data.result.status === "success") {
                  clearInterval(this.timer);
                  this.isTimeout = true;
                  // 열린 모달 닫기
                  this.$refs["klipModal"].hide();

                  if(functionType === "approve"){
                    this.onOpenVoteModal(this.selectedItem);
                  }else if(functionType === "vote") {
                    // 투표 결과 저장
                    const param = {
                      idxArtpool: this.artpoolInfo.idx,
                      idxCollection: this.selectedItem.idx,
                      walletAddr: this.me.klaytnAddr,
                      amount: this.votingAmount,
                      transactionHash: data.result.tx_hash
                    };
                    this.completeVote(param)
                      .then(data => {
                        this.requestArtpoolCollections();
                        alert(this.$t("stake.vote.success"));
                      })
                      .catch(error => {
                        this.$log.error(error);
                      });
                  }
                  this.closeOpenedModal();
                } else if (data.result.status === "pending") {
                  // pending : success될때까지 실행
                  this.isTimeout = false;
                } else {
                  clearInterval(this.timer);
                  this.isTimeout = true;
                  this.$refs["klipModal"].hide();

                  // Fail
                  if(functionType === "approve"){
                    alert(this.$t("stake.failApprove"));
                  }else if(functionType === "vote") {
                    alert(this.$t("stake.vote.fail"));
                  }
                  this.closeOpenedModal();
                }
              } else if (data.status === "canceled") {
                this.closeModal();
                this.closeOpenedModal();
              }
            })
            .catch(error => {
              this.$log.error(error);
              clearInterval(this.timer);
            });
        }, 3000);
      }
    },
    closeOpenedModal() {
      if(this.isOpenApprove){
        this.closeApprove();
      }

      if(this.isOpenVote) {
        this.closeNftVote();
      }
    },
    closeModal() {
      clearInterval(this.timer);
      clearInterval(this.totalTimer);
    },
    getCachedSession() {
      const local = localStorage ? localStorage.getItem("walletconnect") : null;

      let session = null;
      if (local) {
        session = JSON.parse(local);
      }
      return session;
    },
    getInitTabStatus() {
      return {
        total: false,
        erc721: false,
        kip17: false
      };
    },
    getInitPage() {
      return {
        title: "CONNECT",
        isDefault: false,
        isKakaoKlipPc: false,
        isKakaoKlipMobile: false,
        errorMessage: null,
        isLogin: false,
        isChange: false,
        isEthereum: false,
        isKlaytn: false,
      };
    },
    getInitKakaoKlip() {
      return {
        qrcode: null,
        requestKey: null
      };
    },
    createQRCode(_klipURL) {
      QRCode.toDataURL(_klipURL, async (err, url) => {
        this.form = this.getInitPage();
        if (err) {
          this.klip.qrcode = null;
        } else {
          this.form.isKakaoKlipPc = true;
          this.form.title = this.$t("wallet.modal.klipQRTitle");
          this.klip.qrcode = url;
        }
      });
    },
    setTotalTimer() {
      if (!this.isTimeout) {
        this.totalTimer = setInterval(() => {
          this.date = this.date.clone().subtract(1, "seconds");
          if (this.time === "00 : 00") {
            clearInterval(this.timer);
            clearInterval(this.totalTimer);
            this.hide();
          }
        }, 1000);
      }
    },
    getApprovalABI() {
      return {
        "inputs": [
          {
            "internalType": "address",
            "name": "spender",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "amount",
            "type": "uint256"
          }
        ],
        "name": "approve",
        "outputs": [
          {
            "internalType": "bool",
            "name": "",
            "type": "bool"
          }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
      }
    },
    getVoteABI() {
      return {
        "inputs": [
          {
            "internalType": "uint8",
            "name": "round",
            "type": "uint8"
          },
          {
            "internalType": "address",
            "name": "nftAddress",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "tokenId",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "amount",
            "type": "uint256"
          }
        ],
        "name": "vote",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      }
    }
  },
  filters: {
    currency: value => {
      if (!value) return 0;
      return value.toFixed().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    }
  }
}
</script>
