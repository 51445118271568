import Vue from "vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
     faCaretDown
    ,faCaretUp
    ,faExternalLinkAlt
    ,faPhotoVideo
    ,faUserCircle
    ,faVolumeMute
    ,faVolumeDown
    ,faWallet
} from "@fortawesome/free-solid-svg-icons";

library.add(
     faCaretDown
    ,faCaretUp
    ,faExternalLinkAlt
    ,faPhotoVideo
    ,faUserCircle
    ,faVolumeMute
    ,faVolumeDown
    ,faWallet
);

Vue.component("font-awesome-icon", FontAwesomeIcon);
