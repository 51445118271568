<template>
  <div>
    <div id="FeaturesWrap">
      <div class="features_detail_title">
        <h1>Contact Us</h1>
      </div>
      <div id="ContactUsWrap">
        <table>
          <tr>
            <th><span></span>{{ $t("about.name") }}</th>
            <td>
              <CInput
                label=""
                placeholder=""
                :lazy="false"
                :value.sync="$v.form.name.$model"
                autocomplete="name"
              />
            </td>
          </tr>
          <tr>
            <th><span>*</span>{{ $t("about.email") }}</th>
            <td>
              <CInput
                type="email"
                :lazy="false"
                :value.sync="$v.form.email.$model"
                :isValid="checkIfValid('email')"
                placeholder=""
                autocomplete="email"
                invalidFeedback="이메일 형식에 맞게 입력해주세요."
              />
            </td>
          </tr>
          <tr>
            <th><span>*</span>{{ $t("about.subject") }}</th>
            <td>
              <CInput
                :lazy="false"
                :value.sync="$v.form.title.$model"
                :isValid="checkIfValid('title')"
                placeholder=""
                autocomplete="title"
                invalidFeedback="제목을 입력해주세요."
              />
            </td>
          </tr>
          <tr>
            <th><span>*</span>{{ $t("about.content") }}</th>
            <td>
              <CTextarea
                :lazy="false"
                :value.sync="$v.form.contents.$model"
                :isValid="checkIfValid('contents')"
                placeholder=""
                autocomplete="contents"
                invalidFeedback="내용을 입력해주세요."
                rows="9"
              />
            </td>
          </tr>
        </table>
        <CButton block variant="outline" color="dark" @click="submit" :disabled="submitted">
          {{ $t("about.submit") }}
        </CButton>
      </div>
    </div>

    <!-- 이메일 전송중 -->
    <b-modal
      ref="email-send-modal"
      hide-header
      hide-footer
      centered
      no-close-on-esc
      no-close-on-backdrop
      body-class="text-center text-black py-5"
    >
      <b-img src="/img/threespace/logo/logo_100x100.png" width="70"></b-img>
      <p class="mt-3">
        문의해 주셔서 감사합니다.<br />이메일 전송중입니다. 잠시만 기다려주세요.
      </p>
    </b-modal>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import { createNamespacedHelpers } from "vuex";
const utilHelper = createNamespacedHelpers("util");

export default {
  name: "Contact",
  metaInfo() {
    return {
      title: this.$t("meta.contact.title") + " | 3space Art",
      meta: [
        { vmid: "title", name: "title", content: this.$t("meta.contact.title") + " | 3space Art" },
        { vmid: "description", name: "description", content: this.$t("meta.contact.description") },
        { vmid: "og:title", property: "og:title", content: this.$t("meta.contact.title") + " | 3space Art" },
        { vmid: "og:description", property: "og:description", content: this.$t("meta.contact.description") },
        { vmid: "og:url", property: "og:url", content: window.location.origin + this.$route.path }
      ]
    }
  },
  data() {
    return {
      form: this.getEmptyForm(),
      submitted: false
    };
  },
  computed: {
    formString() {
      return JSON.stringify(this.form, null, 4);
    },
    isValid() {
      return !this.$v.form.$invalid;
    },
    isDirty() {
      return this.$v.form.$anyDirty;
    },
    activeLanguage() {
      return this.getActiveLanguage();
    }
  },
  mixins: [validationMixin],
  validations: {
    form: {
      title: { required },
      email: { required, email },
      contents: { required },
      name
    }
  },
  methods: {
    ...utilHelper.mapActions(["sendEmail"]),
    checkIfValid(fieldName) {
      const field = this.$v.form[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    submit() {
      if (this.isValid) {
        this.$refs["email-send-modal"].show();
        this.submitted = true;
        this.sendEmail(this.form).then(() => {
          this.reset();
          this.$refs["email-send-modal"].hide();
        })
        .catch(error => {
          this.$log.error(error);
          alert("오류가 발생하였습니다. 관리자에게 문의하세요.");
          this.$refs["email-send-modal"].hide();
        });
      } else {
        this.validate();
      }
    },
    validate() {
      this.$v.$touch();
    },
    reset() {
      this.form = this.getEmptyForm();
      this.submitted = false;
      this.$v.$reset();
    },
    getEmptyForm() {
      return {
        title: "",
        email: "",
        contents: "",
        name: ""
      };
    },
    getActiveLanguage() {
      return localStorage.getItem("language") || "en";
    }
  }
};
</script>
