var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{ref:"klaytn-collection-select-modal",attrs:{"id":"klaytn-collection-select-modal","hide-header":"","hide-footer":"","centered":"","scrollable":""},on:{"hide":_vm.closeModal}},[_c('CRow',[_c('CCol',{staticClass:"col-12 py-2"},[_c('div',{staticClass:"d-flex justify-content-end"},[_c('img',{staticClass:"cursor-pointer",attrs:{"src":"/img/threespace/icon/close.png","alt":"close"},on:{"click":function($event){return _vm.$refs['klaytn-collection-select-modal'].hide()}}})])])],1),_c('p',{staticStyle:{"color":"#000","font-size":"20px","font-weight":"bold","margin-bottom":"6px","text-align":"center"}},[_vm._v(" "+_vm._s(_vm.$t("mypage.klaytnNftImport"))+" ")]),(!_vm.showCollection)?_c('div',{staticClass:"contract_select_wrap klaytn_contract_select_wrap"},[(_vm.isBusyKlaytn)?[_c('div',{staticClass:"contract_select_list"},[_c('div',{staticClass:"sk-wave mx-auto mt-5"},[_c('div',{staticClass:"sk-wave-rect"}),_c('div',{staticClass:"sk-wave-rect"}),_c('div',{staticClass:"sk-wave-rect"}),_c('div',{staticClass:"sk-wave-rect"}),_c('div',{staticClass:"sk-wave-rect"})])])]:[(
          _vm.klaytnNftCollectionList !== undefined &&
          _vm.klaytnNftCollectionList.length > 0
        )?_vm._l((_vm.klaytnNftCollectionList),function(item,i){return _c('div',{key:i},[_c('div',{staticClass:"d-flex justify-content-center"},[_c('div',{staticClass:"mt-3",staticStyle:{"width":"100%"},on:{"onClick":function($event){return _vm.onClickContractItem(item)}}},[_c('Button',{attrs:{"label":`${item.nft.name} (${_vm.$options.filters.simpleAddr(
                  item.nft.address
                )})`,"onClick":() => _vm.onClickContractItem(item)}})],1)])])}):[_c('div',{staticClass:"contract_select_list pt-5"},[_vm._v(" "+_vm._s(_vm.$t("mypage.noResultContract"))+" ")])]]],2):_c('div',{staticClass:"collection_select_wrap",staticStyle:{"padding":"0px 30px"}},[_c('p',{staticClass:"title",staticStyle:{"margin-bottom":"20px"}},[_c('span',{staticClass:"contract_name"},[_vm._v(" "+_vm._s(_vm.selectedNftCollectionList[0].nft.name)+" ("+_vm._s(_vm._f("simpleAddr")(_vm.selectedNftCollectionList[0].nft.address))+") ")])]),_c('div',{staticClass:"collection_select_list"},[_c('div',{staticClass:"total_cnt"},[_c('div',{staticClass:"float-left"},[_vm._v(" "+_vm._s(_vm.$t("mypage.selected"))+" ("+_vm._s(_vm.selectedCollections.length)+"/"+_vm._s(_vm.selectedNftCollectionList[0].collections.length)+") ")])]),_c('b-table-simple',{attrs:{"sticky-header":"330px","borderless":"","no-border-collapse":"","head-variant":"light"}},[_c('b-thead',[_c('tr',[_c('th'),_c('th',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.$t("mypage.tokenId"))+" ")]),_c('th'),_c('th',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.$t("mypage.tokenId"))+" ")])])]),_c('b-tbody',_vm._l((_vm.chunk(
              _vm.selectedNftCollectionList[0].collections,
              2
            )),function(chunkArry,i){return _c('tr',{key:i},[_vm._l((chunkArry),function(collection,j){return [_c('td',{key:'collection' + j,staticClass:"text-right pr-0"},[(_vm.isAlreadyRegister(collection))?[_c('v-icon',[_vm._v("mdi-check-underline")])]:[_c('b-form-checkbox',{attrs:{"id":'collection' + i + j,"value":collection,"disabled":_vm.selectedCollections.length > 4 &&
                      _vm.selectedCollections.indexOf(collection) === -1},model:{value:(_vm.selectedCollections),callback:function ($$v) {_vm.selectedCollections=$$v},expression:"selectedCollections"}})]],2),_c('td',{key:'tokenId' + j,staticClass:"text-left"},[_c('label',{staticClass:"m-0",attrs:{"for":'collection' + i + j}},[_vm._v("#"+_vm._s(_vm._f("hexToNumber")(collection.tokenId)))])])]})],2)}),0)],1)],1),_c('div',{staticClass:"btn_box",staticStyle:{"margin-top":"20px"}},[_c('p',{staticClass:"text-grey"},[_vm._v(" * 가져오기는 1회 당 최대 10개까지 권고합니다. 10개 초과 일 경우, 일정시간이 소요될 수 있습니다. ")])]),_c('div',{staticClass:"d-flex justify-content-center"},[_c('div',{staticClass:"d-flex justify-content-center",staticStyle:{"margin-right":"10px"}},[_c('div',{staticClass:"mt-3",staticStyle:{"width":"170px","max-width":"170px"}},[_c('Button',{attrs:{"label":_vm.$t('button.prev'),"onClick":_vm.onClickPrev}})],1)]),_c('div',{staticClass:"d-flex justify-content-center"},[_c('div',{staticClass:"mt-3",staticStyle:{"width":"170px","max-width":"170px"}},[_c('Button',{attrs:{"label":_vm.$t('button.next'),"onClick":_vm.onClickNext}})],1)])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }