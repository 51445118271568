<template>
  <b-modal
    id="collection-visible-modal"
    ref="collection-visible-modal"
    hide-header
    hide-footer
    centered
    body-class="text-center text-black"
    @hide="modalEvent(false)"
    @show="modalEvent(true)"
  >
    <CRow>
      <CCol class="col-12">
        <div class="d-flex justify-content-end">
          <img
            class="cursor-pointer"
            src="/img/threespace/icon/close.png"
            alt="close"
            @click="$refs['collection-visible-modal'].hide()"
          />
        </div>
      </CCol>
    </CRow>
    <div class="stake_select_modal">
      <div class="title" v-if="collectionAllList.length > 0">COLLECTION</div>
      <div class="form_box" v-if="collectionAllList.length > 0">
        <div class="checkbox">
          <b-form-checkbox v-model="allSelected" @change="onCheckAllSelect">
            {{ $t("stake.stakeModal.allSelected") }}
            ({{ collectionAllList.filter((el) => !el.visible).length }}/{{
              collectionAllList.length
            }})
          </b-form-checkbox>
        </div>
        <div class="refresh" @click="onClickRefresh">
          <img src="/img/threespace/icon/new_refresh.svg" style="width: 18px" />
        </div>
      </div>
      <div class="select_list">
        <template v-if="collectionAllList.length === 0">
          <div class="mt-3 d-flex justify-content-center">
            <div class="sk-wave" v-if="false">
              <div class="sk-wave-rect"></div>
              <div class="sk-wave-rect"></div>
              <div class="sk-wave-rect"></div>
              <div class="sk-wave-rect"></div>
              <div class="sk-wave-rect"></div>
            </div>
          </div>
          <p class="text-black" style="font-size: 20px; font-weight: bold">
            {{ $t("mypage.noNft") }}
          </p>
        </template>
        <template v-else>
          <template v-for="(item, i) in collectionAllList">
            <div
              class="select_row"
              :key="'stake-item' + i"
              :class="{ active: !item.visible }"
              @click="onClickCard(item)"
            >
              <div class="img_box">
                <template
                  v-if="
                    item.imageUrl !== undefined &&
                    item.imageUrl !== null &&
                    item.imageUrl !== ''
                  "
                >
                  <template
                    v-if="
                      item.idxProduct !== undefined &&
                      item.idxProduct !== null &&
                      item.idxProduct !== ''
                    "
                  >
                    <b-img :src="item.imageUrl" class="w-100"></b-img>
                  </template>
                  <template v-else>
                    <video
                      :src="item.imageUrl"
                      type="video/webm"
                      muted
                      loop
                      autoplay
                      playsinline
                      v-if="item.video"
                    ></video>
                    <b-img :src="item.imageUrl" class="w-100" v-else></b-img>
                  </template>
                </template>
              </div>
              <div class="text_box">
                <h3>
                  <template
                    v-if="
                      activeLanguage !== undefined && activeLanguage === 'ko'
                    "
                  >
                    {{ item.title || item.titleEn }}
                  </template>
                  <template v-else>
                    {{ item.titleEn || item.title }}
                  </template>
                </h3>
                <p class="artist" v-if="item.idxProduct !== undefined">
                  <template
                    v-if="
                      activeLanguage !== undefined && activeLanguage === 'ko'
                    "
                  >
                    Artist @{{ item.artist || item.artistEn }}
                  </template>
                  <template v-else>
                    Artist @{{ item.artistEn || item.artist }}
                  </template>
                </p>
                <p class="token_id">#{{ item.tokenId }} ({{ item.nft.rfc }})</p>
                <!-- <p class="video_icon" v-if="item.video === true">
                  <v-icon>mdi-video-outline</v-icon>
                </p> -->
                <!-- <div class="select_overlay">
                  <p>
                    {{ $t("stake.stakeModal.selected") }}
                  </p>
                </div> -->
              </div>
            </div>
          </template>
        </template>
      </div>
    </div>
    <!-- <div>
      <b-button
        class="nft_stake_modal_btn"
        @click="onClickCollectionVisible"
        :disabled="
          collectionAllList === undefined || collectionAllList.length === 0
        "
      >
        {{ $t("button.confirm") }}
      </b-button>
    </div> -->
    <div class="d-flex justify-content-center mb-4">
      <div class="mt-3" style="width: 86%">
        <Button
          :label="$t('button.confirm')"
          :onClick="onClickCollectionVisible"
        />
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Button from "@/views/threespace/components/common/Button.vue";

export default {
  components: {
    Button,
  },
  name: "MyPageCollectionVisibleModal",
  data: function () {
    return {
      isBusy: false,
      message: "",
      allSelected: false,
    };
  },
  computed: {
    ...mapState("collection", ["collectionAllList"]),
    activeLanguage() {
      return this.getActiveLanguage();
    },
  },
  methods: {
    ...mapActions("collection", ["updateCollectionVisible"]),
    onCheckAllSelect() {
      if (this.allSelected) {
        this.collectionAllList.forEach((item) => {
          item.visible = false;
        });
      } else {
        this.collectionAllList.forEach((item) => {
          item.visible = true;
        });
      }
    },
    onClickRefresh() {
      this.collectionAllList.forEach((item) => {
        item.visible = true;
      });
      this.allSelected = false;
    },
    onClickCard(item) {
      item.visible = !item.visible;
    },
    onClickCollectionVisible() {
      this.updateCollectionVisible({
        updateCollections: this.collectionAllList,
      }).then(() => {
        this.hide();
        this.$store.commit("setCommonAlertModalInfo", {
          msgHtml: "설정이 완료되었습니다.",
          show: true,
          fontSize: "sm",
        });
      });
    },
    show() {
      this.$refs["collection-visible-modal"].show();
    },
    hide() {
      this.$refs["collection-visible-modal"].hide();
    },
    getActiveLanguage() {
      return localStorage.getItem("language") || "en";
    },
    modalEvent(isVisible) {
      if (isVisible) {
        this.isBusy = true;
        this.message = this.$t("stake.stakeModal.search");
      } else {
        this.isBusy = false;
        this.message = "";
        this.allSelected = false;
      }
    },
  },
};
</script>
