<template>
  <CCard>
    <CCardBody>
      <CRow>
        <CCol class="mb-3"><h5>아트풀 상세</h5></CCol>
      </CRow>
      <CForm>
        <CRow>
          <CCol col="8">
            <b-table-simple>
              <colgroup>
                <col style="width:15%;" />
                <col style="width:85%;" />
              </colgroup>
              <b-tbody>
                <b-tr>
                  <b-th variant="secondary">아트풀 번호</b-th>
                  <b-td>
                    {{ artpoolInfo.idx || "(정보없음)" }}
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-th variant="secondary">
                    회차(라운드)
                  </b-th>
                  <b-td>
                    <div class="row align-items-center">
                      <div class="col-lg-2 col-md-2 pr-0">
                        아트풀 <span class="text-danger font-lg">*</span>
                      </div>
                      <div class="col-4">
                        {{ artpoolInfo.roundNo }}
                      </div>
                      <div class="col-6">
                        <CIcon name="cil-info" class="text-info mr-1" />
                        <span class="text-info">화면에 표시되는 회차(순서)</span>
                      </div>
                    </div>
                    <div class="row align-items-center mt-1">
                      <div class="col-lg-2 col-md-2 pr-0">
                        클레이튼
                      </div>
                      <div class="col-4">
                        {{ artpoolInfo.roundNoKip }}
                      </div>
                      <div class="col-6">
                        <CIcon name="cil-info" class="text-info mr-1" />
                        <span class="text-info">아트풀 "클레이튼" 컨트랙트에 설정되는 회차</span>
                      </div>
                    </div>
                    <div class="row align-items-center mt-1">
                      <div class="col-lg-2 col-md-2 pr-0">
                        이더리움
                      </div>
                      <div class="col-4">
                        {{ artpoolInfo.roundNoErc }}
                      </div>
                      <div class="col-6">
                        <CIcon name="cil-info" class="text-info mr-1" />
                        <span class="text-info">아트풀 "이더리움" 컨트랙트에 설정되는 회차</span>
                      </div>
                    </div>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-th variant="secondary">
                    아트풀 명
                  </b-th>
                  <b-td>
                    <div class="row align-items-center">
                      <div class="col-lg-2 col-md-2 pr-0">
                        한글 <span class="text-danger font-lg">*</span>
                      </div>
                      <div class="col-10">
                        {{ artpoolInfo.title }}
                      </div>
                    </div>
                    <div class="row align-items-center mt-1">
                      <div class="col-lg-2 col-md-2 pr-0">
                        영문
                      </div>
                      <div class="col-10">
                        {{ artpoolInfo.titleEn }}
                      </div>
                    </div>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-th variant="secondary">
                    아트풀 명<br/>(부제목)
                  </b-th>
                  <b-td>
                    <div class="row align-items-center">
                      <div class="col-lg-2 col-md-2 pr-0">
                        한글
                      </div>
                      <div class="col-10">
                        {{ artpoolInfo.subTitle }}
                      </div>
                    </div>
                    <div class="row align-items-center mt-1">
                      <div class="col-lg-2 col-md-2 pr-0">
                        영문
                      </div>
                      <div class="col-10">
                        {{ artpoolInfo.subTitleEn }}
                      </div>
                    </div>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-th variant="secondary">설명(소개)</b-th>
                  <b-td>
                    <div class="row align-items-center mt-1">
                      <div class="col-lg-2 col-md-2 pr-0">
                        한글 <span class="text-danger font-lg">*</span>
                      </div>
                      <div class="col-10">
                        <p v-html="replaceNewLine(artpoolInfo.description)"></p>
                      </div>
                    </div>
                    <div class="row mt-1 align-items-center">
                      <div class="col-lg-2 col-md-2 pr-0">
                        영문
                      </div>
                      <div class="col-10">
                        <p v-html="replaceNewLine(artpoolInfo.descriptionEn)" class="border-top pt-3 mb-0"></p>
                      </div>
                    </div>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-th variant="secondary">
                    아트풀 기간
                  </b-th>
                  <b-td>
                    <div class="row align-items-center">
                      <div class="col-lg-2 col-md-2 pr-0">
                        시작일시
                      </div>
                      <div class="col-5">
                        {{ artpoolInfo.startDate }}
                      </div>
                    </div>
                    <div class="row align-items-center mt-1">
                      <div class="col-lg-2 col-md-2 pr-0">
                        종료일시
                      </div>
                      <div class="col-5">
                        {{ artpoolInfo.endDate }}
                      </div>
                    </div>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-th variant="secondary">
                    투표 기간
                  </b-th>
                  <b-td>
                    <div class="row align-items-center">
                      <div class="col-lg-2 col-md-2 pr-0">
                        투표 여부
                      </div>
                      <div class="col-10">
                        {{ artpoolInfo.voted === true ? "예" : "아니오" }}
                      </div>
                    </div>
                    <div class="row align-items-center mt-2">
                      <div class="col-lg-2 col-md-2 pr-0">
                        시작일시
                      </div>
                      <div class="col-5">
                        {{ artpoolInfo.voteStartDate }}
                      </div>
                    </div>
                    <div class="row align-items-center mt-1">
                      <div class="col-lg-2 col-md-2 pr-0">
                        종료일시
                      </div>
                      <div class="col-5">
                        {{ artpoolInfo.voteEndDate }}
                      </div>
                    </div>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-th variant="secondary">
                    전시회 장소
                  </b-th>
                  <b-td>
                    <div class="row align-items-center">
                      <div class="col-lg-2 col-md-2 pr-0">
                        한글
                      </div>
                      <div class="col-10">
                        {{ artpoolInfo.venue }}
                      </div>
                    </div>
                    <div class="row align-items-center mt-1">
                      <div class="col-lg-2 col-md-2 pr-0">
                        영문
                      </div>
                      <div class="col-10">
                        {{ artpoolInfo.venueEn }}
                      </div>
                    </div>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-th variant="secondary">
                    외부 링크
                  </b-th>
                  <b-td>
                    {{ artpoolInfo.externalLink }}
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-th variant="secondary">
                    화면 노출
                  </b-th>
                  <b-td>
                    <div class="row">
                      <div class="col-4">
                        {{ artpoolInfo.visible === true ? "예" : "아니오" }}
                      </div>
                      <div class="col-8">
                        <CIcon name="cil-info" class="text-info mr-1" />
                        <span class="text-info">"예"인 경우 "ART POOL" 화면에 노출됩니다.</span>
                      </div>
                    </div>
                  </b-td>
                </b-tr>
                <b-tr class="border-bottom">
                  <b-th variant="secondary">
                    참여 작가
                  </b-th>
                  <b-td>
                    <table class="table mt-2 mb-0">
                      <thead>
                      <b-tr>
                        <b-th class="text-center bg-gray-100 text-dark py-2">
                          작가번호
                        </b-th>
                        <b-th class="text-center bg-gray-100 text-dark py-2">
                          작가명
                        </b-th>
                        <b-th class="text-center bg-gray-100 text-dark py-2">
                          작가 사용자이름(닉네임)
                        </b-th>
                      </b-tr>
                      </thead>
                      <tbody>
                      <template v-for="(item, index) in artpoolInfo.artists">
                        <b-tr :key="'item' + index" class="border-bottom">
                          <b-td class="text-center">{{ item.idx }}</b-td>
                          <b-td class="text-center">{{ item.artistName }}</b-td>
                          <b-td class="text-center">{{ item.nickname }}</b-td>
                        </b-tr>
                      </template>
                      </tbody>
                    </table>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </CCol>
          <CCol col="4">
            <div>
              <strong>썸네일 이미지</strong>
              <div class="mt-2">
                <span class="text-success">{{ artpoolInfo.thumbnailImage || '(정보없음)' }}</span>
                <div v-if="artpoolInfo.thumbnailImage !== undefined && artpoolInfo.thumbnailImage !== null">
                  <CImg
                    height="100px"
                    :src="artpoolInfo.thumbnailImageUrl"
                    class="mt-1"
                  />
                </div>
              </div>
            </div>
            <hr />
            <div class="mt-3">
              <strong>배너 이미지(PC)</strong>
              <div class="mt-2">
                <span class="text-success">{{ artpoolInfo.bannerImagePc || '(정보없음)' }}</span>
                <div v-if="artpoolInfo.bannerImagePc !== undefined && artpoolInfo.bannerImagePc !== null">
                  <CImg
                    height="100px"
                    :src="artpoolInfo.bannerImagePcUrl"
                    class="mt-1"
                  />
                </div>
              </div>
            </div>
            <div class="mt-3">
              <strong>배너 이미지(PC) 영문 </strong>
              <div class="mt-2">
                <span class="text-success">{{ artpoolInfo.bannerImagePcEn || '(정보없음)' }}</span>
                <div v-if="artpoolInfo.bannerImagePcEn !== undefined && artpoolInfo.bannerImagePcEn !== null">
                  <CImg
                    height="100px"
                    :src="artpoolInfo.bannerImagePcEnUrl"
                    class="mt-1"
                  />
                </div>
              </div>
            </div>
            <hr />
            <div class="mt-3">
              <strong>배너 이미지(Mobile)</strong>
              <div class="mt-2">
                <span class="text-success">{{ artpoolInfo.bannerImageMobile || '(정보없음)' }}</span>
                <div v-if="artpoolInfo.bannerImageMobile !== undefined && artpoolInfo.bannerImageMobile !== null">
                  <CImg
                    height="100px"
                    :src="artpoolInfo.bannerImageMobileUrl"
                    class="mt-1"
                  />
                </div>
              </div>
            </div>
            <div class="mt-3">
              <strong>배너 이미지(Mobile) 영문 </strong>
              <div class="mt-2">
                <span class="text-success">{{ artpoolInfo.bannerImageMobileEn || '(정보없음)' }}</span>
                <div v-if="artpoolInfo.bannerImageMobileEn !== undefined && artpoolInfo.bannerImageMobileEn !== null">
                  <CImg
                    height="100px"
                    :src="artpoolInfo.bannerImageMobileEnUrl"
                    class="mt-1"
                  />
                </div>
              </div>
            </div>
            <hr />
            <div class="mt-3">
              <strong>배경 이미지</strong>
              <div class="mt-2">
                <span class="text-success">{{ artpoolInfo.backgroundImage || '(정보없음)' }}</span>
                <div v-if="artpoolInfo.backgroundImage !== undefined && artpoolInfo.backgroundImage !== null">
                  <CImg
                    height="100px"
                    :src="artpoolInfo.backgroundImageUrl"
                    class="mt-1"
                  />
                </div>
              </div>
            </div>
            <div class="mt-3">
              <strong>시그니처 컬러</strong>
              <div class="row mt-2">
                <div class="col-3">메인 컬러</div>
                <div class="col-9">
                  {{ artpoolInfo.primaryColor }}
                </div>
              </div>
              <div class="row mt-1">
                <div class="col-3">서브 컬러</div>
                <div class="col-9">
                  {{ artpoolInfo.secondaryColor }}
                </div>
              </div>
              <div class="mt-2 text-gray">
                &#183; 버튼의 컬러 등에 사용됩니다.
              </div>
            </div>
          </CCol>
        </CRow>

        <CRow>
          <CCol col="12" class="text-center my-4">
            <CButton color="light" @click="onClickConfirm()" class="mr-2">
              <CIcon name="cil-x-circle" class="mr-2" />목록
            </CButton>
            <CButton color="info" @click="onClickModify()" class="text-white" :disabled="isBusy">
              <CIcon name="cil-save" class="mr-2" />수정
            </CButton>
          </CCol>
        </CRow>
      </CForm>
    </CCardBody>
  </CCard>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const artpoolHelper = createNamespacedHelpers("artpoolManager");

export default {
  name: "ArtpoolDetail",
  components: {},
  props: {
    idxArtpool: {
      type: String,
      default: "0"
    }
  },
  data: function() {
    return {
      isBusy: true,
    };
  },
  computed: {
    ...artpoolHelper.mapState(["artpoolInfo"]),
  },
  mounted() {
    this.requestArtpoolItem();
  },
  methods: {
    ...artpoolHelper.mapActions(["getArtpool"]),
    requestArtpoolItem() {
      if (this.idxArtpool && this.idxArtpool !== "0") {
        this.getArtpool(this.idxArtpool)
          .then(() => {
            this.$log.info(this.artpoolInfo); //TODO
            this.isBusy = false;
          })
          .catch(error => {
            alert("아트풀 정보 조회 오류가 발생하였습니다. 관리자에게 문의하세요.");
            this.$log.error(error);
          });
      }
    },
    onClickConfirm() {
      this.$router.push("/management/artpool-list");
    },
    onClickModify() {
      this.$router.push("/management/artpool-save/" + this.idxArtpool);
    },
    replaceNewLine(str) {
      if (str !== undefined && str !== null && str !== "") {
        return str.replace(/(?:\r\n|\r|\n)/g, "<br />");
      } else {
        return "";
      }
    },
  }
};
</script>
