var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"favorites",attrs:{"id":"MypageCollected"}},[(
      _vm.favoritedCollectionList === undefined ||
      _vm.favoritedCollectionList === null ||
      _vm.favoritedCollectionList.content.length === 0
    )?[_c('div',{key:"0",staticClass:"col-12 mb-5 py-5 text-center"},[_c('h5',{staticClass:"pb-5 my-5 text-gray"},[_vm._v(" "+_vm._s(_vm.$t("mypage.noHistoryFavorited"))+" ")])])]:_c('div',{staticClass:"favorites__wrapper"},_vm._l((_vm.favoritedCollectionList.content),function(item,i){return _c('div',{key:i,ref:'cartItem' + item.idx,refInFor:true,staticClass:"item"},[_c('div',{attrs:{"id":'galleryItem' + item.idx},on:{"click":function($event){return _vm.showProductDetail(item)}}},[_c('v-lazy',{attrs:{"options":{ threshold: 0.5 },"transition":"fade-transition"}},[_c('div',{staticClass:"img-box",class:{ skeleton_display_none: !_vm.isBusy },on:{"contextmenu":function($event){$event.preventDefault();$event.stopPropagation();}}},[[(!item.video)?[_c('b-img',{attrs:{"src":item.imageUrl}})]:[_c('video',{attrs:{"poster":_vm.replaceDisplayPosterUrl(item),"src":_vm.replaceDisplayCardUrl(item),"type":"video/webm","muted":"","loop":"","autoplay":"","playsinline":""},domProps:{"muted":true}})]],_c('b-skeleton-img',{attrs:{"no-aspect":"","width":"100%","height":"100%"}})],2)]),_c('section',{staticClass:"card-info"},[(!_vm.isBusy)?_c('section',{staticClass:"card-info__title"},[(
                item.idxProduct !== undefined &&
                item.idxProduct !== null &&
                item.idxProduct !== ''
              )?_c('p',{staticClass:"artist_name"},[(_vm.activeLanguage !== undefined && _vm.activeLanguage === 'ko')?[_vm._v(" "+_vm._s(item.artist || item.artistEn)+" ")]:[_vm._v(" "+_vm._s(item.artistEn || item.artist)+" ")]],2):_vm._e(),_c('div',{staticClass:"icon-wrap"},[_c('FavoritedButton',{attrs:{"favoritedItem":item},on:{"onClickFavoritedButton":_vm.onClickFavoritedButton}})],1)]):_c('div',{staticClass:"card-info__title"},[_c('p',{staticClass:"artist_name"},[_c('b-skeleton',{staticClass:"float-left",attrs:{"type":"avatar","height":"30px","width":"30px"}}),_c('b-skeleton',{staticClass:"float-left mt-2 ml-2",attrs:{"width":"100px"}})],1)]),(!_vm.isBusy)?_c('h3',{staticClass:"card-info__name"},[(_vm.activeLanguage !== undefined && _vm.activeLanguage === 'ko')?[_vm._v(" "+_vm._s(item.title || item.titleEn)+" ")]:[_vm._v(" "+_vm._s(item.titleEn || item.title)+" ")]],2):_c('h3',{staticClass:"card-info__name"},[_c('b-skeleton',{staticClass:"float-left mt-2 ml-2",attrs:{"width":"200px"}})],1),(!_vm.isBusy)?_c('section',{staticClass:"card-info__price",class:{ soldout: item.product.status === 'SOLDOUT' }},[_c('p',{staticClass:"price"},[_vm._v("price")]),_c('p',{staticClass:"amount"},[(item.product.status === 'SOLDOUT')?[_vm._v(" "+_vm._s(item.product.buyerNickname !== undefined && item.product.buyerNickname !== "" ? "Owned by @" + item.product.buyerNickname : _vm.$t("product.statusSoldOut"))+" ")]:(item.product.status === 'EXHIBIT')?[_vm._v(" "+_vm._s(_vm.$t("product.statusExhibit"))+" ")]:(
                  _vm.activeLanguage !== undefined &&
                  _vm.activeLanguage === 'en' &&
                  _vm.isCoinPriceNonZero(item.product.nftType) &&
                  _vm.isAcceptableNftType(item.product.nftType)
                )?[_vm._v(" "+_vm._s(_vm.getProductCoinPrice( item.product.productPrice, item.product.nftType ))+" "+_vm._s(_vm.getCoinCurrencyByNftType(item.product.nftType))+" ")]:[_vm._v(" "+_vm._s(_vm._f("currency")(item.product.productPrice))+" "+_vm._s(item.product.productPrice !== undefined && item.product.productPrice > 0 ? "KRW" : _vm.$t("product.statusExhibit"))+" ")]],2)]):_c('section',{staticClass:"card-info__price"},[_c('span',[_c('b-skeleton',{attrs:{"width":"50%","height":"25px"}})],1)])])],1)])}),0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 text-right"},[_c('b-pagination',{staticClass:"float-right gallery_pagination",attrs:{"total-rows":_vm.favoritedCollectionList.totalElements,"per-page":_vm.perPage,"hide-ellipsis":"","limit":"10","aria-controls":"role-function-list"},on:{"change":_vm.onPaging},scopedSlots:_vm._u([{key:"first-text",fn:function(){return [_c('img',{staticClass:"page_icon rotate_page_icon",attrs:{"src":"/img/threespace/icon/pagination_arrow2_icon.png"}})]},proxy:true},{key:"prev-text",fn:function(){return [_c('img',{staticClass:"page_icon rotate_page_icon",attrs:{"src":"/img/threespace/icon/pagination_arrow_icon.png"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('img',{staticClass:"page_icon",attrs:{"src":"/img/threespace/icon/pagination_arrow_icon.png"}})]},proxy:true},{key:"last-text",fn:function(){return [_c('img',{staticClass:"page_icon",attrs:{"src":"/img/threespace/icon/pagination_arrow2_icon.png"}})]},proxy:true},{key:"page",fn:function({ page }){return [_c('span',{class:{ active_num: page === _vm.currentPage }},[_vm._v(" "+_vm._s(page)+" ")])]}}]),model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }