export default {
    data: function() {
        return {
            passwordRule: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/
        };
    },
    methods: {
        /* ==========================
        Mypage > 비밀번호변경
        ========================== */
        passwordChangeValidation: function() {
          this.warningText = {
            password: "",
            newPassword: "",
            newPasswordCheck: "",
          };
          // 빈값 체크
          if (this.externalParams.password === "") {
            this.warningText.password = this.$t("mypage.changedPassword.passwordNull");
            return false;
          }
          if (this.externalParams.newPassword === "") {
            this.warningText.newPassword = this.$t("mypage.changedPassword.newPasswordNull");
            return false;
          }
          if (this.externalParams.newPasswordCheck === "") {
            this.warningText.newPasswordCheck = this.$t("mypage.changedPassword.newPasswordCheckNull");
            return false;
          }
  
          // 비밀번호 정규식
          if (!this.passwordRule.test(this.externalParams.newPassword)) {
            this.warningText.newPassword = this.$t("mypage.changedPassword.regexInfo")
            return false;
          }
  
          // 동일한지 체크
          if (this.externalParams.newPassword !== this.externalParams.newPasswordCheck) {
            this.warningText.newPasswordCheck = this.$t("mypage.changedPassword.wrongCheck")
            return false;
          }

          this.warningText = {
            password: "",
            newPassword: "",
            newPasswordCheck: "",
          };
  
          return true;
        },
    }
};
