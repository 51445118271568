import ApiService from "@/services/api.service";
import logger from "@/logger";

// action types
export const GET_ARTPOOLS = "getArtpools";
export const GET_ARTPOOL = "getArtpool";
export const GET_ERC_ROUND = "getErcRound";
export const GET_KIP_ROUND = "getKipRound";
export const ADD_ARTPOOL = "addArtpool";
export const UPDATE_ARTPOOL = "updateArtpool";
export const UPDATE_ARTPOOL_IMAGE = "updateArtpoolImage";
export const GET_ARTPOOL_GALLERY_COLLECTION = "getArtpoolGalleryCollection";
export const GET_ARTPOOL_GALLERY_COLLECTION_TOTAL = "getArtpoolGalleryCollectionTotal";
export const UPDATE_ARTPOOL_GALLERY_COLLECTION= "updateArtpoolGalleryCollection";
export const DELETE_ARTPOOL = "deleteArtpool";
export const ADD_ARTPOOL_WHITELIST = "addArtpoolWhitelist";
export const INIT_ERC_ROUND = "initErcRound";
export const INIT_KIP_ROUND = "initKipRound";
export const GET_ERC_VOTE_WINNER = "getErcVoteWinner";
export const GET_KIP_VOTE_WINNER = "getKipVoteWinner";
export const UPDATE_ERC_VOTE = "updateErcVote";
export const UPDATE_KIP_VOTE = "updateKipVote";
export const FINISH_ERC_VOTE = "finishErcVote";
export const FINISH_KIP_VOTE = "finishKipVote"

// mutation types
export const RESET_ARTPOOL = "resetArtpool";
export const SET_ARTPOOLS = "setArtpools";
export const SET_ARTPOOL = "setArtpool";
export const SET_ROUND = "setRound";
export const SET_VOTE_WINNER = "setVoteWinner";
export const SET_ARTPOOL_GALLERY_COLLECTION = "setArtpoolGalleryCollection";
export const SET_ARTPOOL_GALLERY_COLLECTION_TOTAL = "setArtpoolGalleryCollectionTotal";

const state = {
  errors: null,
  artpoolTableItems: {
    content: [],
    first: true,
    last: false,
    number: 0,
    numberOfElements: 0,
    size: 0,
    sort: {
      orders: []
    },
    total: 0,
    totalElements: 0,
    totalPages: 1
  },
  artpoolTableFields: [
    { key: "index", label: "번호", class: "text-center", thStyle: "width:5%" },
    { key: "idx", label: "IDX", class: "text-center", thStyle: "width:50px" },
    { key: "roundNo", label: "회차", class: "text-center", thStyle: "width:50px" },
    { key: "title", label: "아트풀 명" },
    { key: "startDate", label: "시작일", class: "text-center", thStyle: "width:124px" },
    { key: "endDate", label: "종료일", class: "text-center", thStyle: "width:124px" },
    { key: "voteStartDate", label: "투표 시작일", class: "text-center", thStyle: "width:126px" },
    { key: "voteEndDate", label: "투표 종료일", class: "text-center", thStyle: "width:126px" },
    { key: "actions", label: "Actions", class: "text-center", thStyle: "width:310px" }
  ],
  artpoolInfo: {},
  roundInfo: {
    idx: null,
    fullLockup: null,
    lockupTimestamp: null,
    rewardPerSecond: null,
    startTime: null,
    endTime: null,
    voteMax : null,
    voteStartTime: null,
    voteEndTime: null
  },
  voteWinnerInfo: {
    totalVotedNFT: null,
    totalVoted: null,
    winnerVoted: null,
    winnerNFT: null,
    winnerTokenId: null,
    winners : [],
  },
  artpoolGalleryCollections: [],
  artpoolGalleryCollectionsTotal: []
};

const getters = {};

const actions = {
  [GET_ARTPOOLS](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("/management/v1/artpools", payload)
        .then(({data}) => {
          context.commit(SET_ARTPOOLS, data.data);
          resolve(data.data);
        })
        .catch(({response}) => {
          logger.error(response);
          reject(response);
        });
    })
  },
  [GET_ARTPOOL](context, payload) {
    context.commit(RESET_ARTPOOL);
    return new Promise((resolve, reject) => {
      ApiService.get("/management/v1/artpools/" + payload)
        .then(({data}) => {
          context.commit(SET_ARTPOOL, data.data);
          resolve(data.data);
        })
        .catch(({response}) => {
          logger.error(response);
          reject(response);
        });
    })
  },
  [GET_ERC_ROUND](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get(`/management/v1/artpools/${payload}/erc/round/`)
        .then(({data}) => {
          context.commit(SET_ROUND, data.data);
          resolve(data.data);
        })
        .catch(({response}) => {
          logger.error(response);
          reject(response);
        });
    })
  },
  [INIT_ERC_ROUND](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`/management/v1/artpools/${payload.idxArtpool}/erc/round/`, payload)
        .then(({data}) => {
          context.commit(SET_ROUND, data.data);
          resolve(data.data);
        })
        .catch(({response}) => {
          logger.error(response);
          reject(response);
        });
    })
  },
  [GET_KIP_ROUND](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get(`/management/v1/artpools/${payload}/kip/round/`)
        .then(({data}) => {
          context.commit(SET_ROUND, data.data);
          resolve(data.data);
        })
        .catch(({response}) => {
          logger.error(response);
          reject(response);
        });
    })
  },
  [INIT_KIP_ROUND](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`/management/v1/artpools/${payload.idxArtpool}/kip/round/`, payload)
        .then(({data}) => {
          context.commit(SET_ROUND, data.data);
          resolve(data.data);
        })
        .catch(({response}) => {
          logger.error(response);
          reject(response);
        });
    })
  },
  [GET_ERC_VOTE_WINNER](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get(`/management/v1/artpools/${payload}/erc/vote/`)
        .then(({data}) => {
          context.commit(SET_VOTE_WINNER, data.data);
          resolve(data.data);
        })
        .catch(({response}) => {
          logger.error(response);
          reject(response);
        });
    })
  },
  [GET_KIP_VOTE_WINNER](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get(`/management/v1/artpools/${payload}/kip/vote/`)
        .then(({data}) => {
          context.commit(SET_VOTE_WINNER, data.data);
          resolve(data.data);
        })
        .catch(({response}) => {
          logger.error(response);
          reject(response);
        });
    })
  },
  [UPDATE_ERC_VOTE](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`/management/v1/artpools/${payload.idxArtpool}/erc/vote/`, payload)
        .then(({data}) => {
          context.commit(SET_ROUND, data.data);
          resolve(data.data);
        })
        .catch(({response}) => {
          logger.error(response);
          reject(response);
        });
    })
  },
  [UPDATE_KIP_VOTE](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`/management/v1/artpools/${payload.idxArtpool}/kip/vote/`, payload)
        .then(({data}) => {
          context.commit(SET_ROUND, data.data);
          resolve(data.data);
        })
        .catch(({response}) => {
          logger.error(response);
          reject(response);
        });
    })
  },
  [ADD_ARTPOOL](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("/management/v1/artpools", payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [UPDATE_ARTPOOL](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.put("/management/v1/artpools/" + payload.idx, payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [UPDATE_ARTPOOL_IMAGE](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post_upload_file("/management/v1/artpools/image", payload)
          .then(({ data }) => {
            resolve(data.data);
          })
          .catch(({ response }) => {
            logger.error(response);
            reject(response);
          });
    });
  },
  [DELETE_ARTPOOL](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.delete("/management/v1/artpools", payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(response => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [ADD_ARTPOOL_WHITELIST](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("/management/v1/artpools/whitelist/" + payload.idxArtpool, payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [FINISH_ERC_VOTE](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`/management/v1/artpools/${payload.idxArtpool}/erc/vote/finish`, payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        })
    })
  },
  [FINISH_KIP_VOTE](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`/management/v1/artpools/${payload.idxArtpool}/kip/vote/finish`, payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        })
    })
  },
  [GET_ARTPOOL_GALLERY_COLLECTION](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("/management/v1/artpools/collection/" + payload)
        .then(({ data }) => {
          context.commit(SET_ARTPOOL_GALLERY_COLLECTION, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [GET_ARTPOOL_GALLERY_COLLECTION_TOTAL](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("/management/v1/artpools/collection/total/" + payload)
        .then(({ data }) => {
          context.commit(SET_ARTPOOL_GALLERY_COLLECTION_TOTAL, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [UPDATE_ARTPOOL_GALLERY_COLLECTION](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.put("/management/v1/artpools/collection/" + payload.idx, payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
}

const mutations = {
  [SET_ARTPOOLS](state, artpoolList) {
    state.artpoolTableItems = artpoolList;
  },
  [SET_ARTPOOL](state, artpoolInfo) {
    state.artpoolInfo = artpoolInfo;
  },
  [SET_ROUND](state, roundInfo) {
    state.roundInfo = roundInfo;
  },
  [SET_VOTE_WINNER](state, voteWinnerInfo) {
    state.voteWinnerInfo = voteWinnerInfo;
  },
  [RESET_ARTPOOL](state) {
    state.artpoolInfo = {}
  },
  [SET_ARTPOOL_GALLERY_COLLECTION](state, artpoolGalleryCollections) {
    state.artpoolGalleryCollections = artpoolGalleryCollections;
  },
  [SET_ARTPOOL_GALLERY_COLLECTION_TOTAL](state, artpoolGalleryCollectionsTotal) {
    state.artpoolGalleryCollectionsTotal = artpoolGalleryCollectionsTotal;
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
