<template>
  <div id="vue-neopin"></div>
</template>


<script>
import NeopinConnect from "nptconnect-client";
import QRCodeModal from "nptconnect-qrcode-modal";
import VueI18n from "../lang";

export default {
  data() {
    return {
      connected: false,
      connector : new NeopinConnect({
        bridge: "https://wc-bridge.neopin.io",
        clientMeta: {
          description: VueI18n.t("meta.home.description"),
          url: `${process.env.VUE_APP_FRONTEND_API_URL}`,
          icons: [
            `${process.env.VUE_APP_FRONTEND_API_URL}/apple-icon-57x57.png`,
            `${process.env.VUE_APP_FRONTEND_API_URL}/apple-icon-60x60.png`,
            `${process.env.VUE_APP_FRONTEND_API_URL}/apple-icon-72x72.png`,
            `${process.env.VUE_APP_FRONTEND_API_URL}/apple-icon-76x76.png`,
            `${process.env.VUE_APP_FRONTEND_API_URL}/apple-icon-114x114.png`,
            `${process.env.VUE_APP_FRONTEND_API_URL}/apple-icon-120x120.png`,
            `${process.env.VUE_APP_FRONTEND_API_URL}/apple-icon-144x144.png`,
            `${process.env.VUE_APP_FRONTEND_API_URL}/apple-icon-152x152.png`,
            `${process.env.VUE_APP_FRONTEND_API_URL}/apple-icon-180x180.png`,
            `${process.env.VUE_APP_FRONTEND_API_URL}/android-icon-192x192.png`,
            `${process.env.VUE_APP_FRONTEND_API_URL}/favicon-32x32.png`,
            `${process.env.VUE_APP_FRONTEND_API_URL}/favicon-96x96.png`,
            `${process.env.VUE_APP_FRONTEND_API_URL}/favicon-16x16.png`
          ],
          name: "3space Art",
          appId: 'NWC1001G5SEI0YYUP69QM64P0Q2T6ZII',
        },
        qrcodeModal: QRCodeModal,
        qrcodeModalOptions: {
          registryUrl:`${process.env.VUE_APP_BACKEND_API_URL}/threespace/v1/neopin/whitelist`,
        }
      }),
      chainId: null,
      address: null,
    };
  },
  methods: {
    async onClick(network) {

      console.log("connected?", this.connector.connected);
      if (!this.connector.connected) {
        await this.createSession(network);
      } else {
        this.checkAccounts(network);
      }
    },
    checkAccounts(network) {
      const connector = this.connector

      const {chainId, accounts } = connector;
      const address = accounts[0];
      const walletProvider = "Neopin";

      let walletInfo = {
        chainId: chainId,
        addr: address,
        network,
        walletProvider,
      }

      if(network === "Ethereum") {
        walletInfo.ethereumAddr = address;
      } else {
        walletInfo.klaytnAddr = address;
      }

      this.$emit("wallet-info", walletInfo);
    },
    async createSession(network) {
      console.log("Create Session")

      const connector = this.connector

      await connector.createSession();

      connector.on("chainChanged", (chainId) => {
        console.log(chainId);
      });


      connector.on("connect", async () => {

        const {chainId} = connector;

        // if ((chainId.toString() !== process.env.VUE_APP_ETHEREUM_CHAIN_ID ||  chainId.toString() !== process.env.VUE_APP_KLAYTN_CHAIN_ID ) && process.env.NODE_ENV === "production") {
        //   alert("네트워크가 올바르지 않습니다.");
        //   this.killSession()
        //   return;
        // }

        this.checkAccounts(network)
      });

      connector.on("disconnect", (code, reason) => {
        console.log(code, reason);
      });

      connector.on("session_update", (error, payload) => {
        if (error) {
          throw error;
        }

        const { accounts, chainId } = payload.params[0];
        this.account = accounts[0];
        this.chainId = chainId;
      })
    },
    async killSession() {
      if (this.provider.connector) {
        await this.provider.connector.killSession();
      }
      this.resetState();
    },
    resetState() {
      this.$emit("wallet-info", {
        chainId: null,
        address: null,
        network: null,
        asset: null,
        provider: null,
      });
    },
  }
};
</script>

<style scoped>
#vue-walletConnect {
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
}
</style>