<template>
  <CCard>
    <CCardBody>
      <CRow>
        <CCol class="mb-3"><h5>아트풀 화이트리스트</h5></CCol>
      </CRow>
      <CRow>
        <CCol>
          <b-table-simple>
            <colgroup>
              <col style="width:12%;" />
              <col style="width:38%;" />
              <col style="width:12%;" />
              <col style="width:38%;" />
            </colgroup>
            <b-tbody>
              <b-tr>
                <b-th variant="secondary">아트풀 번호</b-th>
                <b-td>
                  {{ artpoolInfo.idx || "(정보없음)" }}
                </b-td>
                <b-th variant="secondary">
                  회차(라운드)
                </b-th>
                <b-td>
                  <div class="row align-items-center">
                    <div class="col-lg-3 col-md-3 pr-0">
                      아트풀 <span class="text-danger font-lg">*</span>
                    </div>
                    <div class="col-9">
                      {{ artpoolInfo.roundNo }}
                    </div>
                  </div>
                  <div class="row align-items-center mt-1">
                    <div class="col-lg-3 col-md-3 pr-0">
                      클레이튼
                    </div>
                    <div class="col-9">
                      {{ artpoolInfo.roundNoKip }}
                    </div>
                  </div>
                  <div class="row align-items-center mt-1">
                    <div class="col-lg-3 col-md-3 pr-0">
                      이더리움
                    </div>
                    <div class="col-9">
                      {{ artpoolInfo.roundNoErc }}
                    </div>
                  </div>
                </b-td>
              </b-tr>
              <b-tr class="border-bottom">
                <b-th variant="secondary">
                  아트풀 명
                </b-th>
                <b-td>
                  {{ artpoolInfo.title || artpoolInfo.titleEn }}
                </b-td>
                <b-th variant="secondary">
                  아트풀 기간
                </b-th>
                <b-td>
                  {{ artpoolInfo.startDate }} ~ {{ artpoolInfo.endDate }}
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </CCol>
      </CRow>

      <CRow class="mt-5">
        <CCol col="6" class="d-flex justify-content-between">
          <strong>KIP-17</strong>
          <CButton size="sm" class="bg-accent text-white" @click="onClickNftSelect('KIP-17')">
            <CIcon name="cil-magnifying-glass" />
          </CButton>
        </CCol>
        <CCol col="6" class="d-flex justify-content-between">
          <strong>ERC-721</strong>
          <CButton size="sm" color="primary" class="text-white" @click="onClickNftSelect('ERC-721')">
            <CIcon name="cil-magnifying-glass" />
          </CButton>
        </CCol>
      </CRow>
      <CRow class="mt-1">
        <CCol col="6">
          <b-table-simple>
            <colgroup>
              <col />
              <col />
              <col style="width:100px;" />
              <col style="width:50px;" />
            </colgroup>
            <b-thead>
              <b-th colspan="2" class="text-center" variant="secondary">NFT 컨트랙트</b-th>
              <b-th class="text-center" variant="secondary">Token ID</b-th>
              <b-th class="text-center" variant="secondary">삭제</b-th>
            </b-thead>
            <b-tbody>
              <template v-for="(item, index) in whitelistKlaytn">
                <b-tr :key="'klaytn' + index">
                  <b-td class="pr-0">{{ item.name }}</b-td>
                  <b-td class="pr-0">{{ item.contractAddr }}</b-td>
                  <b-td class="pr-0 text-center">
                    <CInput
                      class="mb-0"
                      placeholder="개발중"
                      v-model="item.tokenId"
                    />
                  </b-td>
                  <b-td class="px-0 text-center">
                    <CButton
                      variant="ghost"
                      color="right"
                      @click="onClickDelete('KIP-17', item.idx)"
                    >
                      <CIcon name="cil-x" />
                    </CButton>
                  </b-td>
                </b-tr>
              </template>
            </b-tbody>
          </b-table-simple>
        </CCol>
        <CCol col="6">
          <b-table-simple>
            <colgroup>
              <col />
              <col />
              <col style="width:100px;" />
              <col style="width:50px;" />
            </colgroup>
            <b-thead>
              <b-th colspan="2" class="text-center" variant="secondary">NFT 컨트랙트</b-th>
              <b-th class="text-center" variant="secondary">Token ID</b-th>
              <b-th class="text-center" variant="secondary">삭제</b-th>
            </b-thead>
            <b-tbody>
              <template v-for="(item, index) in whitelistEthereum">
                <b-tr :key="'ethereum' + index">
                  <b-td class="pr-0">{{ item.name }}</b-td>
                  <b-td class="pr-0">{{ item.contractAddr }}</b-td>
                  <b-td class="pr-0 text-center">
                    <CInput
                      class="mb-0"
                      placeholder="개발중"
                      v-model="item.tokenId"
                    />
                  </b-td>
                  <b-td class="px-0 text-center">
                    <CButton
                      variant="ghost"
                      color="right"
                      @click="onClickDelete('ERC-721', item.idx)"
                    >
                      <CIcon name="cil-x" />
                    </CButton>
                  </b-td>
                </b-tr>
              </template>
            </b-tbody>
          </b-table-simple>
        </CCol>
      </CRow>

      <CRow>
        <CCol col="12" class="text-center my-4">
          <CButton color="light" @click="onClickCancel()" class="mr-2">
            <CIcon name="cil-x-circle" class="mr-2" />취소
          </CButton>
          <CButton color="dark" @click="onClickSave()" class="text-white" :disabled="isBusy">
            <CIcon name="cil-save" class="mr-2" />저장
          </CButton>
        </CCol>
      </CRow>

      <NftSelectPopup
        ref="nft-select-popup"
        @nft-selected="selectedNftInfo"
      />
    </CCardBody>
  </CCard>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const artpoolHelper = createNamespacedHelpers("artpoolManager");

import NftSelectPopup from "@/views/management/nft/NftSelectPopup";

export default {
  name: "ArtpoolWhitelist",
  components: {
    NftSelectPopup
  },
  props: {
    idxArtpool: {
      type: String,
      default: "0"
    }
  },
  data: function() {
    return {
      nftType: null,
      whitelistKlaytn: [],
      whitelistEthereum: [],
      isBusy: false
    };
  },
  computed: {
    ...artpoolHelper.mapState(["artpoolInfo"])
  },
  mounted() {
    this.requestArtpoolItem();
  },
  methods: {
    ...artpoolHelper.mapActions(["getArtpool", "addArtpoolWhitelist"]),
    requestArtpoolItem() {
      if (this.idxArtpool && this.idxArtpool !== "0") {
        this.isBusy = true;
        this.getArtpool(this.idxArtpool)
          .then(() => {
            this.$log.info(this.artpoolInfo); //TODO
            this.whitelistKlaytn = this.artpoolInfo.whitelistKlaytn;
            if (this.whitelistKlaytn === undefined || this.whitelistKlaytn === null) {
              this.whitelistKlaytn = new Array();
            }
            this.whitelistEthereum = this.artpoolInfo.whitelistEthereum;
            if (this.whitelistEthereum === undefined || this.whitelistEthereum === null) {
              this.whitelistEthereum = new Array();
            }
            this.isBusy = false;
          })
          .catch(error => {
            alert("아트풀 정보 조회 오류가 발생하였습니다. 관리자에게 문의하세요.");
            this.$log.error(error);
          });
      }
    },
    onClickCancel() {
      this.$router.push("/management/artpool-list");
    },
    onClickSave() {
      this.$log.log("this.whitelistKlaytn", this.whitelistKlaytn); //TODO
      this.$log.log("this.whitelistEthereum", this.whitelistEthereum); //TODO
      // 화이트리스트 정보 저장
      if (this.idxArtpool && this.idxArtpool !== "0") {
        this.addArtpoolWhitelist({ idxArtpool: this.idxArtpool, whitelistKlaytn: this.whitelistKlaytn, whitelistEthereum: this.whitelistEthereum })
          .then(() => {
            alert("저장되었습니다.");
            this.$router.push("/management/artpool-list");
          })
          .catch(error => {
            this.$log.log(error);
            alert("화이트리스트 등록 오류가 발생하였습니다. 관리자에게 문의해 주세요.");
          });
      }
    },
    onClickNftSelect(_rfc) {
      this.nftType = _rfc;
      this.$refs["nft-select-popup"].show(_rfc);
    },
    selectedNftInfo(item) {
      if (this.nftType !== undefined && this.nftType !== null) {
        const nftInfo = Object.assign(item, { idxNft: item.idx });
        if (this.nftType === "KIP-17") {
          this.whitelistKlaytn.push(nftInfo);
        } else if (this.nftType === "ERC-721") {
          this.whitelistEthereum.push(nftInfo);
        }
      }
    },
    onClickDelete(_rfc, idx) {
      if (_rfc !== undefined) {
        if (_rfc === 'KIP-17') {
          const _idx = this.whitelistKlaytn.findIndex(
              item => item.idx === idx
          );
          if (_idx > -1) this.whitelistKlaytn.splice(_idx, 1);
        } else if (_rfc === 'ERC-721') {
          const _idx = this.whitelistEthereum.findIndex(
              item => item.idx === idx
          );
          if (_idx > -1) this.whitelistEthereum.splice(_idx, 1);
        }
      }
    }
  }
};
</script>
