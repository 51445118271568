import ApiService from "@/services/api.service";
import logger from "@/logger";

// action types
export const SEND_EMAIL = "sendEmail";
export const SEND_SLACK = "sendSlack";

// mutation types

const state = {
  slackInfo: {
    type: "", // PAYMENT, ORDER, ARTWORK
    header: "",
    productName: "",
    artistName: "",
    job: "",
  },
};

const getters = {};

const actions = {
  [SEND_EMAIL](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("/threespace/v1/util/email", payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [SEND_SLACK](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("/threespace/v1/slack", payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  }
};

const mutations = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
