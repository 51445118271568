var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"CommonBoardsWrap"}},[_c('div',{staticClass:"article-title"},[_vm._v(" "+_vm._s(_vm.activeLanguage === "ko" ? _vm.articleInfo.title : _vm.articleInfo.titleEn)+" ")]),_c('div',{staticClass:"article"},[(_vm.activeLanguage === 'ko')?[_c('div',{staticClass:"ck ck-content content-box-content",domProps:{"innerHTML":_vm._s(
          _vm.articleInfo.content !== undefined &&
          _vm.articleInfo.content !== null &&
          _vm.articleInfo.content !== ''
            ? _vm.articleInfo.content
            : _vm.articleInfo.contentEn
        )}})]:[_c('div',{staticClass:"ck ck-content content-box-content",domProps:{"innerHTML":_vm._s(
          _vm.articleInfo.contentEn !== undefined &&
          _vm.articleInfo.contentEn !== null &&
          _vm.articleInfo.contentEn !== ''
            ? _vm.articleInfo.contentEn
            : _vm.articleInfo.content
        )}})]],2),_c('router-link',{staticClass:"back-btn",attrs:{"to":"/features"}},[_vm._v(" GO TO LIST ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }