<template>
  <CCard>
    <CCardBody>
      <CRow>
        <CCol class="mb-3"><h5>법인 작품 관리</h5></CCol>
      </CRow>
      <CRow>
        <CCol>
          <b-table-simple>
            <colgroup>
              <col style="width:10%;" />
              <col style="width:90%;" />
            </colgroup>
            <b-tbody>
              <b-tr>
                <b-th variant="secondary">검색</b-th>
                <b-td>
                  <b-row>
                    <b-col sm="2" class="pr-0">
                      <CSelect
                        class="mb-0"
                        :options="searchOptions"
                        :value.sync="searchSelected"
                        @change="onChangeSearchOption"
                      />
                    </b-col>
                    <b-col sm="6" class="pr-0">
                      <CInput
                        class="mb-0"
                        placeholder="검색어를 입력해주세요."
                        v-model="externalParams.searchKeyword"
                        @input="onChangeSearchKeyword"
                        @keypress.enter.prevent="onClickSearch"
                      />
                    </b-col>
                    <b-col sm="4">
                      <CButton color="dark" class="text-white" @click="onClickSearch">
                        <CIcon name="cil-search" class="mr-2" />검색
                      </CButton>
                      <CButton
                        variant="outline"
                        color="secondary"
                        class="ml-2"
                        @click="onClickReset"
                      >
                        <CIcon name="cil-filter-x" />
                      </CButton>
                    </b-col>
                  </b-row>
                </b-td>
              </b-tr>
              <b-tr class="border-bottom">
                <b-th>&#183; 작품상태</b-th>
                <b-td>
                  <b-form-group class="mb-0">
                    <b-form inline>
                      <b-form-checkbox
                        v-model="selectedAllStatus"
                        @change="toggleAllStatus"
                        class="mr-3"
                      >
                        전체
                      </b-form-checkbox>
                      <b-form-checkbox-group
                        v-model="externalParams.keywordStatus"
                        :options="searchStatusOptions"
                      ></b-form-checkbox-group>
                    </b-form>
                  </b-form-group>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </CCol>
      </CRow>
      <CRow>
        <CCol class="col-auto mr-auto align-self-center">
          총&nbsp;<strong>{{ artworkTableItems.totalElements }}</strong>건&nbsp;({{ currentPage }}&nbsp;/&nbsp;{{ artworkTableItems.totalPages }}&nbsp;페이지)
        </CCol>
        <CCol class="col-auto">
          <CSelect
            class="float-left mr-2 mb-2"
            :options="pageOptions"
            :value.sync="perPage"
            @change="onPaging"
          />
        </CCol>
      </CRow>
      <b-table
        id="productTable"
        thead-class="text-center"
        :busy="isBusy"
        :items="artworkTableItems.content"
        :fields="artworkTableFields"
        :items-per-page="perPage"
        show-empty
        empty-text="조회된 내역이 없습니다."
      >
        <!-- NO -->
        <template #cell(index)="data">
          {{ data.index + 1 + (currentPage - 1) * perPage }}
        </template>

        <!-- 판매가 -->
        <template #cell(productPrice)="data">
          <template v-if="data.item.status === 'SOLDOUT' && data.item.orderPayment !== undefined">
            <template v-if="data.item.orderPayment.payType === 'ETH' || data.item.orderPayment.payType === 'KLAY'">
              {{ data.item.orderPayment.amountCoin | currencyCoin }}{{ data.item.orderPayment.currency }}<span class="text-gray font-xs">({{ data.value | currency }}&#8361;)</span>
            </template>
            <template v-else>
              {{ data.value | currency }}&#8361;<span class="text-gray font-xs">({{ data.item.soldCoinPrice | currencyCoin }}{{ data.item.soldCoinCurrency }})</span>
            </template>
          </template>
          <template v-else>
            {{ data.value | currency }}&#8361;
          </template>
        </template>

        <!-- 상태 -->
        <template #cell(status)="data">
          <span :class="replaceKoStatus(data.item.status).class">
            {{ replaceKoStatus(data.item.status).text }}
          </span>
        </template>

        <!-- 주문일시 -->
        <template #cell(orderDate)="data">
          <template v-if="data.item.status === 'SOLDOUT' && data.item.orderPayment !== undefined">
            {{ data.item.orderPayment.orderDate | moment("YYYY-MM-DD HH:mm") }}
          </template>
        </template>

        <!-- 결제수단 -->
        <template #cell(payType)="data">
          <template v-if="data.item.orderPayment !== undefined">
            {{ data.item.orderPayment.payType }}
          </template>
        </template>

        <!-- 코인정산 -->
        <template #cell(settleCoinStatus)="data">
          <template v-if="data.item.orderPayment !== undefined && (data.item.orderPayment.payType === 'ETH' || data.item.orderPayment.payType === 'KLAY')">
            <span :class="replaceKoSettleStatus(data.item.orderPayment.status, data.item.orderPayment.settleCoinStatus).class">
              {{ replaceKoSettleStatus(data.item.orderPayment.status, data.item.orderPayment.settleCoinStatus).text }}
            </span>
          </template>
        </template>

        <!-- 레퍼럴 -->
        <template #cell(referral)="data">
          <template v-if="data.item.status === 'SOLDOUT'">
            <template v-if="data.item.orderPayment !== undefined
              && data.item.orderPayment.referral !== undefined && data.item.orderPayment.referral !== null && data.item.orderPayment.referral !== ''">
              <span class="text-info">Y</span>
            </template>
          </template>
        </template>

        <!-- 작품 URL -->
        <template #cell(artworkUrl)="data">
          {{ data.value.substring(0, 35) }}...
        </template>

        <!-- Actions -->
        <template v-slot:cell(actions)="data">
          <b-button
            variant="outline-primary"
            v-c-tooltip="'URL Copy'"
            @click="onClickUrlCopy(data.item.idx)"
          >
            <CIcon name="cil-copy" />
          </b-button>
          <CInput
            :id="'hiddenArtworkUrl_' + data.item.idx"
            type="hidden"
            class="mb-0"
            v-model="data.item.artworkUrl"
          />
        </template>
      </b-table>
      <b-pagination
        aria-controls="productTable"
        align="center"
        hide-ellipsis
        limit="10"
        v-model="currentPage"
        :total-rows="artworkTableItems.totalElements"
        :per-page="perPage"
        :current-page="currentPage"
        v-on:input="onPaging"
      ></b-pagination>
    </CCardBody>
  </CCard>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const productHelper = createNamespacedHelpers("product");

export default {
  name: "CorpArtworkList",
  components: {},
  data: function() {
    return {
      isBusy: false,
      perPage: 10,
      pageOptions: [
        { value: 10, label: "10 개씩 보기" },
        { value: 20, label: "20 개씩 보기" },
        { value: 30, label: "30 개씩 보기" },
        { value: 50, label: "50 개씩 보기" }
      ],
      currentPage: 1,
      sort: [
        { id: "orderPayment.orderDate", desc: "desc" },
        { id: "idx", desc: "desc" }
      ],
      sortBy: "",
      sortDesc: false,
      // 검색
      searchSelected: "",
      searchOptions: [
        { value: "", label: "전체" },
        { value: "keywordProductName", label: "작품명" },
        { value: "keywordProductNameEn", label: "작품명(영문)" },
        { value: "keywordArtistName", label: "작가명" },
        { value: "keywordArtistNameEn", label: "작가명(영문)" }
      ],
      searchStatusOptions: [
        // { value: "PREPARING", text: "판매예정" },
        // { value: "EXHIBIT", text: "전시중" },
        { value: "FORSALE", text: "판매중" },
        { value: "SOLDOUT", text: "판매완료" },
        { value: "RESERVE", text: "예약중(결제대기)" }
      ],
      selectedAllStatus: true,
      externalParams: this.getInitExternalParams()
    };
  },
  computed: {
    ...productHelper.mapState(["artworkTableItems", "artworkTableFields"])
  },
  mounted() {
    this.requestCorpArtworkItems();
  },
  methods: {
    ...productHelper.mapActions(["getCorpArtworks"]),
    onPaging() {
      this.requestCorpArtworkItems();
    },
    onChangeSearchOption() {
      this.setSearchConditions(this.externalParams.searchKeyword);
    },
    onChangeSearchKeyword(keyword) {
      this.setSearchConditions(keyword);
    },
    setSearchConditions(keyword) {
      this.externalParams = this.getInitExternalParams();
      if (this.searchSelected === "") {
        this.externalParams.searchKeyword = keyword;
      } else if (this.searchSelected === "keywordProductName") {
        this.externalParams.keywordProductName = keyword;
      } else if (this.searchSelected === "keywordProductNameEn") {
        this.externalParams.keywordProductNameEn = keyword;
      } else if (this.searchSelected === "keywordArtistName") {
        this.externalParams.keywordArtistName = keyword;
      } else if (this.searchSelected === "keywordArtistNameEn") {
        this.externalParams.keywordArtistNameEn = keyword;
      }
    },
    resetSearchConditions() {
      this.searchSelected = "";
      this.externalParams = this.getInitExternalParams();
    },
    getInitExternalParams() {
      return {
        searchKeyword: "",
        keywordProductName: "",
        keywordProductNameEn: "",
        keywordArtistName: "",
        keywordArtistNameEn: "",
        keywordStatus: []
      };
    },
    onClickSearch() {
      this.requestCorpArtworkItems();
    },
    onClickReset() {
      this.resetSearchConditions();
      this.requestCorpArtworkItems();
    },
    requestCorpArtworkItems() {
      //this.$log.info("requestCorpArtworkItems() this.externalParams=", this.externalParams);
      this.isBusy = true;
      this.getCorpArtworks(
        this.getPagingParams(
          {
            page: this.currentPage - 1,
            pageSize: this.perPage,
            sorted: this.sort,
            filtered: []
          },
          this.externalParams
        )
      )
        .then(data => {
          //this.$log.log(data);//TODO
          this.isBusy = false;
        })
        .catch(error => {
          this.isBusy = false;
          this.$log.error(error);
        });
    },
    toggleAllStatus(checked) {
      this.externalParams.keywordStatus = checked ? this.searchStatusOptions.map(val => val.value) : [];
    },
    onClickUrlCopy(idx) {
      this.$log.info("onClickUrlCopy() idx: ", idx); //TODO

      let hiddenArtworkUrl = document.querySelector("#hiddenArtworkUrl_"+idx);
      hiddenArtworkUrl.setAttribute("type", "text");
      hiddenArtworkUrl.select();
      try {
        document.execCommand("copy");
      } catch (e) {}
      hiddenArtworkUrl.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },
    getPagingParams({ page, pageSize, sorted, filtered }, externalParams) {
      const params = {
        page,
        size: pageSize,
        ...externalParams
      };

      // sorting parameter
      let sorts = [];
      for (let i = 0, length = sorted.length; i < length; i++) {
        const sort = sorted[i];
        sorts.push(`${sort.id},${sort.desc ? "desc" : "asc"}`);
      }
      params["sort"] = sorts;

      // filtering parameter
      for (let i = 0, length = filtered.length; i < length; i++) {
        const filter = filtered[i];
        params[filter.id] = filter.value;
      }

      return params;
    },
    replaceKoDisplay(data) {
      let obj = {};
      switch (data) {
        case true:
          obj = { class: "text-success", text: "공개" };
          break;
        case false:
          obj = { class: "text-gray", text: "비공개" };
          break;
        default:
          obj = { class: "text-black", text: "-" };
      }
      return obj;
    },
    replaceKoStatus(data) {
      let obj = {};
      switch (data) {
        case "PREPARING":
          obj = { class: "text-danger", text: "판매예정" };
          break;
        case "EXHIBIT":
          obj = { class: "text-success", text: "전시중" };
          break;
        case "FORSALE":
          obj = { class: "text-success", text: "판매중" };
          break;
        case "SOLDOUT":
          obj = { class: "text-dark", text: "판매완료" };
          break;
        case "PRIVATESOLD":
          obj = { class: "text-dark", text: "판매완료(가격비공개"};
          break;
        case "RESERVE":
          obj = { class: "text-info", text: "예약중(결제중)" };
          break;
        default:
          obj = { class: "text-black", text: "-" };
      }
      return obj;
    },
    replaceKoSettleStatus(status, settleStatus) {
      let obj = {};
      if (settleStatus === 'SETTLE_S') {
        obj = { class: "text-info", text: "정산대기" };
      } else if (settleStatus === 'SETTLE_P') {
        obj = { class: "text-info", text: "정산중" };
      } else if (settleStatus === 'SETTLE_P_S') {
        obj = { class: "text-black", text: "정산완료" };
      } else if (settleStatus === 'SETTLE_P_S_M') {
        obj = { class: "text-black", text: "정산완료(수동)" };
      } else if (settleStatus === 'SETTLE_P_F') {
        obj = { class: "text-danger", text: "정산실패" };
      } else {
        if (status === 'USER_PAY_SUCCESS') {
          obj = { class: "text-danger", text: "확인필요" };
        } else {
          obj = { class: "text-black", text: settleStatus };
        }
      }
      return obj;
    }
  },
  filters: {
    currency: value => {
      if (!value) return "";
      return parseInt(value)
        .toFixed(0)
        .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    },
    currencyCoin: value => {
      if (!value) return "";
      return value.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    }
  },
  watch: {
    externalParams: {
      deep: true,
      handler(newVal) {
        // 작품상태
        if (newVal.keywordStatus.length === 0) {
          this.selectedAllStatus = false;
        } else if (newVal.keywordStatus.length === this.searchStatusOptions.length) {
          this.selectedAllStatus = true;
        } else {
          this.selectedAllStatus = false;
        }
      }
    }
  }
};
</script>
