<template>
  <div>
    <b-modal
      ref="event-join-modal"
      id="eventJoinModal"
      centered
      scrollable
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      header-class="pb-0"
      body-class="text-center text-black pt-0 pb-5 px-5"
      footer-class="pb-4"
      header-bg-variant="white"
      header-border-variant="white"
      footer-border-variant="white"
    >
      <h4>세번째 공간<br />사전 등록하고 클레이 받기</h4>

      <template v-if="isParticipatedEvent">
        <p class="font-lg font-weight-bold mt-5">
          이미 이벤트 참여가 완료되었습니다.
        </p>
        <p>
          클레이 지급을 위해서 지갑 등록과정을 완료해주세요!
        </p>
        <p>
          <a href="http://pf.kakao.com/_tRkTs" target="_blank">
            <u>지갑 등록 알아보기</u>
          </a>
        </p>
        <b-button
          variant="primary"
          size="lg"
          squared
          class="mt-3 px-5"
          @click="hide()"
        >
          <span class="text-white">확인</span>
        </b-button>
      </template>
      <template v-else>
        <div class="c-avatar c-avatar-xl mr-5 mt-4">
          <b-img
            alt="카카오 계정으로 간편가입"
            class="c-avatar-img cursor-pointer"
            src="/img/threespace/company/kakao_logo_100x100.png"
            @click="onEventJoinKakao()"
          />
        </div>
        <div class="c-avatar c-avatar-xl mr-5">
          <b-img
            alt="네이버 계정으로 간편가입"
            class="c-avatar-img cursor-pointer"
            src="/img/threespace/company/naver_logo_180x180.png"
            @click="onEventJoinNaver()"
          />
        </div>
        <div class="c-avatar c-avatar-xl">
          <b-img
            alt="구글 계정으로 간편가입"
            class="c-avatar-img cursor-pointer"
            src="/img/threespace/company/google_logo_100x100.jpg"
            @click="onEventJoinGoogle()"
          />
        </div>

        <div class="text-left">
          <CInput
            :lazy="false"
            :value.sync="$v.form.account.$model"
            :isValid="checkIfValid('account')"
            placeholder="이메일 계정(필수)"
            autocomplete="email"
            invalidFeedback="필수 정보입니다. 이메일 형식으로 입력해주세요."
            class="mt-4 mb-2"
            :readonly="true"
          />
          <!--
          :readonly="eventUserInfo.isAuthSns"
          <CInput
            type="password"
            :lazy="false"
            :value.sync="$v.form.password.$model"
            :isValid="checkIfValid('password')"
            placeholder="패스워드(필수)"
            autocomplete="new-password"
            invalidFeedback="필수 정보입니다. (영문 대 소문+숫자+특수문자 8자리 이상)"
            class="mb-2"
            v-if="
              eventUserInfo.isAuthSns === undefined || !eventUserInfo.isAuthSns
            "
          />
          -->
          <CInput
            :lazy="false"
            :value.sync="$v.form.mobile.$model"
            :isValid="checkIfValid('mobile')"
            placeholder="휴대폰 번호 '-' 없이 입력"
            autocomplete="mobile"
            invalidFeedback="필수 정보입니다. (휴대폰 번호 '-' 없이 입력)"
            class="mb-2"
          />
        </div>

        <div class="border-dark p-2 mt-3">
          <table class="table text-left table-borderless mb-0">
            <tr>
              <td colspan="2">
                <CInputCheckbox
                  label="전체 동의"
                  :checked.sync="acceptCheckTotal"
                  custom
                  @change="onCheckTotal"
                />
              </td>
            </tr>
            <tr>
              <td>
                <CInputCheckbox
                  label="[필수] 서비스 이용약관 동의"
                  :checked.sync="$v.form.acceptTermsService.$model"
                  :isValid="checkIfValid('acceptTermsService')"
                  invalidFeedback="필수 정보입니다."
                  custom
                  @change="onCheckItem"
                />
              </td>
              <td class="text-right">
                <a
                  class="cursor-pointer"
                  @click="showTerms('terms_of_service')"
                >
                  자세히보기
                  <font-awesome-icon icon="exclamation-circle" />
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <CInputCheckbox
                  label="[필수] 개인정보 수집·이용 동의"
                  :checked.sync="$v.form.acceptPrivacyPolicy.$model"
                  :isValid="checkIfValid('acceptPrivacyPolicy')"
                  invalidFeedback="필수 정보입니다."
                  custom
                  @change="onCheckItem"
                />
              </td>
              <td class="text-right">
                <a class="cursor-pointer" @click="showTerms('privacy_policy')">
                  자세히보기
                  <font-awesome-icon icon="exclamation-circle" />
                </a>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <CInputCheckbox
                  label="[필수] 프로모션 수신동의(이메일/SMS)"
                  :checked.sync="$v.form.acceptPromotion.$model"
                  :isValid="checkIfValid('acceptPromotion')"
                  invalidFeedback="필수 정보입니다."
                  custom
                  @change="onCheckItem"
                />
              </td>
            </tr>
          </table>
        </div>

        <div class="w-100 text-center mt-3">
          <p class="text-danger">{{ errorMessage }}</p>
          <b-button
            variant="primary"
            size="lg"
            squared
            class="px-5"
            @click="onEventJoinSubmit()"
            :disabled="errorMessage !== '' ? true : false"
          >
            <span class="text-white">클레이 받기</span>
          </b-button>
        </div>
      </template>
    </b-modal>

    <!-- 사전 등록하기(회원가입&이벤트 참여) 완료 -->
    <b-modal
      ref="event-join-success-modal"
      hide-header
      hide-footer
      centered
      no-close-on-esc
      no-close-on-backdrop
      body-class="text-center text-black py-5"
    >
      <b-img src="/img/threespace/logo/logo_100x100.png"></b-img>
      <p class="font-lg font-weight-bold">
        이벤트 참여가 완료되었습니다 !
      </p>
      <p>
        클레이 지급을 위해서 지갑 등록과정을 완료해주세요!
      </p>
      <p>
        <a href="http://pf.kakao.com/_tRkTs" target="_blank">
          <u>지갑 등록 알아보기</u>
        </a>
      </p>
      <b-button
        variant="primary"
        size="lg"
        squared
        class="mt-3 px-5"
        @click="hideEventJoinSuccessModal()"
      >
        <span class="text-white">확인</span>
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import {
  required,
  minLength,
  maxLength,
  email,
  helpers,
  numeric
} from "vuelidate/lib/validators";
import { v4 as uuidv4 } from "uuid";
import { createNamespacedHelpers, mapState } from "vuex";
const authHelper = createNamespacedHelpers("auth");
const eventHelper = createNamespacedHelpers("event");

export default {
  name: "EventJoinPopup",
  data: function() {
    return {
      isBusy: false,
      form: this.getInitForm(),
      acceptCheckTotal: false,
      errorMessage: ""
    };
  },
  props: {
    eventInfo: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapState({
      errors: state => {
        return state.auth.errors || {};
      }
    }),
    isValid() {
      return !this.$v.form.$invalid;
    },
    isDirty() {
      return this.$v.form.$anyDirty;
    },
    eventUserInfo() {
      return this.$store.state.eventUserInfo;
    },
    isParticipatedEvent() {
      return this.$store.state.isParticipatedEvent;
    }
  },
  mixins: [validationMixin],
  validations() {
    if (this.eventUserInfo.isAuthSns) {
      return {
        form: {
          account: { required, email },
          mobile: {
            required,
            minLength: minLength(10),
            maxLength: maxLength(12),
            numeric
          },
          acceptTermsService: { required, mustAccept: val => val },
          acceptPrivacyPolicy: { required, mustAccept: val => val },
          acceptPromotion: { required, mustAccept: val => val }
        }
      };
    } else {
      return {
        form: {
          account: { required, email },
          password: {
            required,
            minLength: minLength(8),
            strongPass: helpers.regex(
              "strongPass",
              /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/
            )
          },
          mobile: {
            required,
            minLength: minLength(10),
            maxLength: maxLength(12),
            numeric
          },
          acceptTermsService: { required, mustAccept: val => val },
          acceptPrivacyPolicy: { required, mustAccept: val => val },
          acceptPromotion: { required, mustAccept: val => val }
        }
      };
    }
  },
  methods: {
    ...authHelper.mapActions(["logout", "eventJoin"]),
    ...eventHelper.mapActions(["addEventResult"]),
    onCheckTotal() {
      this.form.acceptTermsService = this.acceptCheckTotal;
      this.form.acceptPrivacyPolicy = this.acceptCheckTotal;
      this.form.acceptPromotion = this.acceptCheckTotal;
    },
    onCheckItem() {
      // 전체 동의
      if (
        this.form.acceptTermsService &&
        this.form.acceptPrivacyPolicy &&
        this.form.acceptPromotion
      ) {
        this.acceptCheckTotal = true;
      } else {
        this.acceptCheckTotal = false;
      }
    },
    showTerms(_code) {
      let routeUrl = this.$router.resolve({
        path: "/terms/" + _code
      });
      window.open(routeUrl.href, "_blank");
    },
    onEventJoinSubmit() {
      this.errorMessage = "";

      if (
        this.eventUserInfo === undefined ||
        this.eventUserInfo.isAuthSns !== true
      ) {
        this.errorMessage = "이메일(SNS) 인증 후 가입해주세요.";
      }
      this.$v.$touch();
      if (this.isValid) {
        //this.logout(); // 로그아웃

        let isAuthSns = this.eventUserInfo.isAuthSns;
        if (isAuthSns) {
          const eventResult = {
            idxEvent: this.eventInfo.idx,
            idxMember: this.form.idxMember,
            mobile: this.form.mobile,
            participated: true,
            acceptTermsService: true,
            acceptPrivacyPolicy: true,
            acceptPromotion: true
          };
          this.addEventResult(eventResult)
            .then(() => {
              this.$refs["event-join-modal"].hide();
              this.$refs["event-join-success-modal"].show();
            })
            .catch(error => {
              this.$log.error(error);
              this.errorMessage =
                "이벤트 참여 오류가 발생하였습니다. 나중에 다시 시도해 주세요.";
            });
        } else {
          this.errorMessage = "이메일(SNS) 인증 후 가입해주세요.";
          /*
          this.eventJoin(this.form)
            .then(() => {
              this.$refs["event-join-modal"].hide();
              this.$refs["event-join-success-modal"].show();
            })
            .catch(() => {
              this.$log.error(this.errors);
              if (
                this.errors.error === "BAD_REQUESTED" &&
                (this.errors.resource === "EXISTS_ACCOUNT" ||
                  this.errors.resource === "EXISTS_MOBILE")
              ) {
                this.errorMessage =
                  "이미 가입된 아이디(이메일) 또는 휴대폰 번호입니다. 로그인 후 다시 시도해 주세요.";
              } else {
                this.errorMessage =
                  "[" +
                  this.errors.error +
                  "] 회원가입 오류가 발생하였습니다. 나중에 다시 시도해 주세요.";
              }
            });
            */
        }
      }
    },
    onEventJoinKakao() {
      this.$v.$reset();
      this.errorMessage = "";
      const redirect_uri =
        process.env.VUE_APP_BACKEND_API_URL +
        "/threespace/v1/event-join/kakao/callback";
      const url = `https://kauth.kakao.com/oauth/authorize?client_id=80e3176f5940dbb2f27f3b44d5fd124d&redirect_uri=${redirect_uri}&response_type=code`;

      window.app = this;
      window.open(
        url,
        "popup",
        "top=10, left=10, width=500, height=600, status=no, menubar=no, toolbar=no, resizable=no"
      );
    },
    onEventJoinNaver() {
      this.$v.$reset();
      this.errorMessage = "";
      const redirect_uri =
        process.env.VUE_APP_BACKEND_API_URL +
        "/threespace/v1/event-join/naver/callback";
      const state = uuidv4();
      const url = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=9kK4tH7jbFwIgYMBleCG&redirect_uri=${redirect_uri}&state=${state}`;

      window.app = this;
      window.open(
        url,
        "popup",
        "top=10, left=10, width=520, height=600, status=no, menubar=no, toolbar=no, resizable=no"
      );
    },
    onEventJoinGoogle() {
      this.$v.$reset();
      this.errorMessage = "";
      const redirect_uri =
        process.env.VUE_APP_BACKEND_API_URL +
        "/threespace/v1/event-join/google/callback";
      const url =
        `https://accounts.google.com/o/oauth2/v2/auth?client_id=713701466995-jef2uanlhjor7gbe5e15c20fodnar88u.apps.googleusercontent.com&redirect_uri=${redirect_uri}&response_type=code` +
        `&scope=openid%20email%20profile%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuser.phonenumbers.read&access_type=offline`;

      window.app = this;
      window.open(
        url,
        "popup",
        "top=10, left=10, width=500, height=600, status=no, menubar=no, toolbar=no, resizable=no"
      );
    },
    checkIfValid(fieldName) {
      const field = this.$v.form[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    getInitForm() {
      return {
        idxMember: null,
        account: null,
        password: null,
        mobile: null,
        acceptTermsService: false,
        acceptPrivacyPolicy: false,
        acceptPromotion: false
      };
    },
    show() {
      this.$v.$reset();
      this.form = this.getInitForm();
      this.acceptCheckTotal = false;
      this.errorMessage = "";
      this.$store.commit("setEventUserInfo", {}); //TODO
      this.$refs["event-join-modal"].show();
    },
    hide() {
      this.$refs["event-join-modal"].hide();
    },
    hideEventJoinSuccessModal() {
      this.$store.commit("set", ["isParticipatedEvent", true]);
      this.$refs["event-join-success-modal"].hide();
    }
  },
  watch: {
    eventUserInfo: {
      handler: function(newValue, oldValue) {
        this.form.idxMember = newValue.idxMember;
        this.form.account = newValue.account;
        this.form.mobile = newValue.mobile;
        if (newValue.message) {
          if (newValue.message === "NOT_FOUNT_ACCOUNT") {
            this.errorMessage =
              "사용자 인증에 실패하였습니다. 나중에 다시 시도해 주세요.";
          } else if (
            newValue.message === "JOIN_EMAIL_EXISTS" ||
            newValue.message === "JOIN_MOBILE_EXISTS"
          ) {
            this.errorMessage =
              "이미 가입된 아이디(이메일) 또는 휴대폰 번호입니다. 로그인 후 다시 시도해 주세요.";
          } else if (newValue.message === "EVENT_RESULT_EXISTS") {
            this.errorMessage = "이미 이벤트에 참여하였습니다.";
          } else {
            this.errorMessage = "";
          }
        }
      },
      deep: true
    }
  }
};
</script>
