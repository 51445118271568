import { render, staticRenderFns } from "./NftPoolApproveModal.vue?vue&type=template&id=e64b6168&"
import script from "./NftPoolApproveModal.vue?vue&type=script&lang=js&"
export * from "./NftPoolApproveModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports