import ApiService from "@/services/api.service";
import logger from "@/logger";
import {ADD_CODE} from "./management.code";

// action types
export const GET_STAKING_POOLS = "getStakingPools";
export const GET_STAKING_POOL = "getStakingPool";
export const GET_APPROVAL_STATUS = "getApprovalStatus";
export const GET_CURRENT_POOL_USER_DATA = "getUserData";

// NFT
export const GET_ARTPOOLS = "getArtpools";
export const GET_ARTPOOLS_INCLUDED_MONTH = "getArtpoolsIncludedMonth";
export const GET_ARTPOOL = "getArtpool";
export const GET_ARTPOOL_SIMPLE = "getArtpoolSimple";
export const GET_MY_NFT_STAKING_INFO = "getMyNftStakingInfo";
export const GET_ARTPOOL_PRODUCTS = "getArtpoolProducts";
export const GET_AVAILABLE_COLLECTIONS = "getMyAvailableCollections";
export const GET_STAKED_COLLECTIONS = "getMyStakedCollections";
export const GET_ARTPOOL_COLLECTIONS = "getArtpoolCollections";
export const GET_ARTPOOL_COLLECTIONS_TOP_RANK = "getArtpoolCollectionsTopRank";
export const GET_ARTPOOL_COLLECTIONS_TOP_RANK_LIST = "getArtpoolCollectionsTopRankList";
export const SET_ARTPOOL_PREVIOUS_PARAMS = "setArtpoolPreviousParams";
export const GET_ARTPOOL_GALLERY = "getArtpoolGallery";
export const GET_ARTPOOL_GALLERY_PLACES = "getArtpoolGalleryPlaces";

export const GET_KLAYTN_NFT_APPROVAL_STATUS = "getKlaytnNftApprovalStatus";
export const GET_ETHEREUM_NFT_APPROVAL_STATUS = "getEthereumNftApprovalStatus";

export const PREPARE_STAKE_COLLECTIONS = "prepareStakeCollections";
export const COMPLETE_STAKE_COLLECTIONS = "completeStakeCollections";
export const COMPLETE_UNSTAKE_COLLECTIONS = "completeUnstakeCollections";

// NFT - 블록체인 처리 방식 변경
export const ADD_NFT_STAKE_APPROVE_REQUESTED = "addNftStakeApproveRequestedTx";
export const ADD_NFT_STAKE_REQUESTED = "addNftStakeRequestedTx";
export const ADD_NFT_UNSTAKE_REQUESTED = "addNftUnstakeRequestedTx";

// Vote
export const GET_KLAYTN_VOTE_APPROVAL_STATUS = "getKlaytnVoteApprovalStatus";
export const GET_ETHEREUM_VOTE_APPROVAL_STATUS = "getEthereumVoteApprovalStatus";
export const COMPLETE_NFT_VOTE = "completeVote";

// mutation types
export const SET_STAKING_POOLS = "setStakingPools";
export const SET_ALLOWANCE_STATUS = "setAllowanceStatus";
export const SET_CURRENT_POOL_USER_DATA = "setCurrentPoolUserData";

export const SET_ARTPOOLS = "setArtpools";
export const SET_ARTPOOLS_INCLUDED_MONTH = "setArtpoolsIncludedMonth";
export const SET_ARTPOOL = "setArtpool";
export const SET_ARTPOOL_SIMPLE = "setArtpoolSimple";
export const RESET_ARTPOOLS = "resetArtpools";
export const SET_MY_NFT_STAKING_INFO = "setMyNftStakingInfo";
export const SET_ARTPOOL_PRODUCTS = "setArtpoolProducts";
export const RESET_ARTPOOL_PRODUCTS = "resetArtpoolProducts";
export const SET_AVAILABLE_COLLECTIONS = "setMyAvailableCollections";
export const SET_STAKED_COLLECTIONS = "setMyUnstakeTargetCollections";
export const SET_ARTPOOL_COLLECTIONS = "setArtpoolCollections";
export const SET_ARTPOOL_COLLECTIONS_TOP_RANK = "setArtpoolCollectionsTopRank";
export const SET_ARTPOOL_COLLECTIONS_TOP_RANK_LIST = "setArtpoolCollectionsTopRankList";
export const RESET_ARTPOOL_COLLECTIONS = "resetArtpoolCollections";
export const SET_STAKED_ERC_COLLECTIONS = "setStakedErcCollections";
export const SET_STAKED_KIP_COLLECTIONS = "setStakedKipCollections";
export const SET_ARTPOOL_GALLERY = "setArtpoolGallery";
export const RESET_ARTPOOL_GALLERY = "resetArtpoolGallery";
export const SET_ARTPOOL_GALLERY_PLACES = "setArtpoolGalleryPlaces";

export const SET_KLAYTN_NFT_APPROVAL_STATUS = "setKlaytnNftApprovalStatus";
export const SET_ETHEREUM_NFT_APPROVAL_STATUS = "setEthereumNftApprovalStatus";

// Vote
export const SET_KLAYTN_VOTE_APPROVAL_STATUS = "setKlaytnVoteApprovalStatus";
export const SET_ETHEREUM_VOTE_APPROVAL_STATUS = "setEthereumVoteApprovalStatus";

export const CLAIM_REWARD_BY_ARTPOOL = "claimRewardByArtpool";

const state = {
  stakingPoolInfo: {
    current:null,
    prev:null,
    next:null
  },
  artpoolVersionOptions: [
    {roundNo: 1, version: 1},
    {roundNo: 2, version: 1},
    {roundNo: 3, version: 2},
    {roundNo: 4, version: 2},
    {roundNo: 5, version: 2},
    {roundNo: 6, version: 2},
  ],
  currentStakingPool: {
    userData:{},
  },
  closedStakingPools: [],
  currentPoolUserData: {},
  isApproved: false,
  artpoolItems: {
    content: [],
    first: true,
    last: false,
    number: 0,
    numberOfElements: 0,
    size: 0,
    sort: {
      orders: []
    },
    total: 0,
    totalElements: 0,
    totalPages: 1
  },
  artpoolItemsContent: [],
  artpoolInfo: {},
  artpoolSimpleInfo: {},
  nftStakingInfo: {},
  artpoolProductItems: {
    content: [],
    first: true,
    last: false,
    number: 0,
    numberOfElements: 0,
    size: 0,
    sort: {
      orders: []
    },
    total: 0,
    totalElements: 0,
    totalPages: 1
  },
  artpoolProductItemsContent: [],
  availableCollections: [],
  stakedCollections: [],
  previousParams: {},
  artpoolCollectionItems: {
    content: [],
    first: true,
    last: false,
    number: 0,
    numberOfElements: 0,
    size: 0,
    sort: {
      orders: []
    },
    total: 0,
    totalElements: 0,
    totalPages: 1
  },
  artpoolCollectionItemsContent: [],
  stakedErcCollections: [],
  stakedKipCollections: [],
  isKlaytnNftApproved: false,
  isEthereumNftApproved: false,
  isKlaytnVoteApproved: false,
  isEthereumVoteApproved: false,
  topRankCollections: {},
  topRankList: {},
  calendarArtpoolList: [],
  artpoolGallery: {
    content: [],
    first: true,
    last: false,
    number: 0,
    numberOfElements: 0,
    size: 0,
    sort: {
      orders: []
    },
    total: 0,
    totalElements: 0,
    totalPages: 1
  },
  artpoolGalleryContent: [],
  artpoolGalleryPlaces: []
};

const getters = {};

const actions = {
  [GET_STAKING_POOLS](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("/threespace/v1/art-pool")
        .then(({ data }) => {
          context.commit(SET_STAKING_POOLS, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [GET_STAKING_POOL](context, roundNumber) {
    return new Promise((resolve, reject) => {
      ApiService.get("/threespace/v1/art-pool/" + roundNumber)
        .then(({ data }) => {
          context.commit(SET_STAKING_POOLS, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [GET_APPROVAL_STATUS](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("/threespace/v1/art-pool/check-allowance")
        .then(({ data }) => {
          context.commit(SET_ALLOWANCE_STATUS, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [GET_CURRENT_POOL_USER_DATA](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("/threespace/v1/art-pool/current/user")
        .then(({ data }) => {
          context.commit(SET_CURRENT_POOL_USER_DATA, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [GET_ARTPOOLS](context, payload) {
    if (payload.page === 0) {
      context.commit(RESET_ARTPOOLS);
    }
    return new Promise((resolve, reject) => {
      ApiService.get("/threespace/v1/artpools", payload)
          .then(({ data }) => {
            context.commit(SET_ARTPOOLS, data.data);
            // 최신 1건
            // if (payload.page === 0 && data.data.empty !== true) {
            //   context.commit(SET_ARTPOOL, data.data.content[0]);
            // }
            resolve(data.data);
          })
          .catch(({ response }) => {
            logger.error(response);
            reject(response);
          });
    });
  },
  [GET_ARTPOOLS_INCLUDED_MONTH](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("/threespace/v1/artpools/included/month/" + payload.year + "/" + payload.month)
          .then(({ data }) => {
            context.commit(SET_ARTPOOLS_INCLUDED_MONTH, data.data);
            resolve(data.data);
          })
          .catch(({ response }) => {
            logger.error(response);
            reject(response);
          });
    });
  },
  [GET_ARTPOOL](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("/threespace/v1/artpools/" + payload.idxArtpool)
          .then(({ data }) => {
            context.commit(SET_ARTPOOL, data.data);
            resolve(data.data);
          })
          .catch(({ response }) => {
            logger.error(response);
            reject(response);
          });
    });
  },
  [GET_ARTPOOL_SIMPLE](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("/threespace/v1/artpools/simple/" + payload)
          .then(({ data }) => {
            context.commit(SET_ARTPOOL_SIMPLE, data.data);
            resolve(data.data);
          })
          .catch(({ response }) => {
            logger.error(response);
            reject(response);
          });
    });
  },
  [GET_MY_NFT_STAKING_INFO](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("/artpool/v1/nft-staking/user/" + payload.idxArtpool)
          .then(({ data }) => {
            context.commit(SET_MY_NFT_STAKING_INFO, data.data);
            resolve(data.data);
          })
          .catch(({ response }) => {
            logger.error(response);
            reject(response);
          });
    });
  },
  [GET_ARTPOOL_PRODUCTS](context, payload) {
    if (payload.page === 0) {
      context.commit(RESET_ARTPOOL_PRODUCTS);
    }
    return new Promise((resolve, reject) => {
      ApiService.get("/threespace/v1/artpool/products", payload)
          .then(({ data }) => {
            context.commit(SET_ARTPOOL_PRODUCTS, data.data);
            resolve(data.data);
          })
          .catch(({ response }) => {
            logger.error(response);
            reject(response);
          });
    });
  },
  [GET_AVAILABLE_COLLECTIONS](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("/artpool/v1/collections/available", payload)
          .then(({ data }) => {
            context.commit(SET_AVAILABLE_COLLECTIONS, data.data);
            resolve(data.data);
          })
          .catch(({ response }) => {
            logger.error(response);
            reject(response);
          });
    });
  },
  [GET_STAKED_COLLECTIONS](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("/artpool/v1/collections/staked", payload)
          .then(({ data }) => {
            context.commit(SET_STAKED_COLLECTIONS, data.data);
            if (payload.keywordRfc !== undefined && payload.keywordRfc === "ERC-721") {
              context.commit(SET_STAKED_ERC_COLLECTIONS, data.data);
            } else if (payload.keywordRfc !== undefined && payload.keywordRfc === "KIP-17") {
              context.commit(SET_STAKED_KIP_COLLECTIONS, data.data);
            }

            resolve(data.data);
          })
          .catch(({ response }) => {
            logger.error(response);
            reject(response);
          });
    });
  },
  [SET_ARTPOOL_PREVIOUS_PARAMS](context, payload) {
    context.commit(SET_ARTPOOL_PREVIOUS_PARAMS, payload);
  },
  [GET_ARTPOOL_COLLECTIONS](context, payload) {
    if (payload.page === 0) {
      context.commit(RESET_ARTPOOL_COLLECTIONS);
    }
    return new Promise((resolve, reject) => {
      ApiService.get("/threespace/v1/artpool/collections", payload)
          .then(({ data }) => {
            context.commit(SET_ARTPOOL_COLLECTIONS, data.data);
            resolve(data.data);
          })
          .catch(({ response }) => {
            logger.error(response);
            reject(response);
          });
    });
  },
  [GET_ARTPOOL_COLLECTIONS_TOP_RANK](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("/threespace/v1/artpool/collections/toprank/" + payload)
          .then(({ data }) => {
            context.commit(SET_ARTPOOL_COLLECTIONS_TOP_RANK, data.data);
            resolve(data.data);
          })
          .catch(({ response }) => {
            logger.error(response);
            reject(response);
          });
    });
  },
  [GET_ARTPOOL_COLLECTIONS_TOP_RANK_LIST](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("/threespace/v1/artpool/collections/toprank/list/" + payload)
          .then(({ data }) => {
            context.commit(SET_ARTPOOL_COLLECTIONS_TOP_RANK_LIST, data.data);
            resolve(data.data);
          })
          .catch(({ response }) => {
            logger.error(response);
            reject(response);
          });
    });
  },
  [GET_KLAYTN_NFT_APPROVAL_STATUS](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("/threespace/v1/art-pool/nft/approval/" + payload.nftAddress)
          .then(({ data }) => {
            context.commit(SET_KLAYTN_NFT_APPROVAL_STATUS, data.data);
            resolve(data.data);
          })
          .catch(({ response }) => {
            logger.error(response);
            reject(response);
          });
    });
  },
  [GET_ETHEREUM_NFT_APPROVAL_STATUS](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("/threespace/v1/art-pool/nft/approval/eth/" + payload.nftAddress)
          .then(({ data }) => {
            context.commit(SET_ETHEREUM_NFT_APPROVAL_STATUS, data.data);
            resolve(data.data);
          })
          .catch(({ response }) => {
            logger.error(response);
            reject(response);
          });
    });
  },
  [PREPARE_STAKE_COLLECTIONS](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("/artpool/v1/collections/stake/prepare", payload)
          .then(({ data }) => {
            resolve(data.data);
          })
          .catch(({ response }) => {
            logger.error(response);
            reject(response);
          });
    });
  },
  [COMPLETE_STAKE_COLLECTIONS](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("/artpool/v1/collections/stake", payload)
          .then(({ data }) => {
            resolve(data.data);
          })
          .catch(({ response }) => {
            logger.error(response);
            reject(response);
          });
    });
  },
  [COMPLETE_UNSTAKE_COLLECTIONS](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("/artpool/v1/collections/unstake", payload)
          .then(({ data }) => {
            resolve(data.data);
          })
          .catch(({ response }) => {
            logger.error(response);
            reject(response);
          });
    });
  },
  [GET_KLAYTN_VOTE_APPROVAL_STATUS](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("/threespace/v1/art-pool/nft/vote/approval", payload)
        .then(({ data }) => {
          context.commit(SET_KLAYTN_VOTE_APPROVAL_STATUS, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [GET_ETHEREUM_VOTE_APPROVAL_STATUS](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("/threespace/v1/art-pool/nft/vote/approval/eth", payload)
        .then(({ data }) => {
          context.commit(SET_ETHEREUM_VOTE_APPROVAL_STATUS, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [COMPLETE_NFT_VOTE](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("/artpool/v1/art-pool/nft/vote/complete", payload)
          .then(({ data }) => {
            resolve(data.data);
          })
          .catch(({ response }) => {
            logger.error(response);
            reject(response);
          });
    });
  },
  [ADD_NFT_STAKE_APPROVE_REQUESTED](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("/artpool/v1/art-pool/nft/stake/approve/requested", payload)
          .then(({ data }) => {
            resolve(data.data);
          })
          .catch(({ response }) => {
            logger.error(response);
            reject(response);
          });
    });
  },
  [ADD_NFT_STAKE_REQUESTED](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("/artpool/v1/art-pool/nft/stake/requested", payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [ADD_NFT_UNSTAKE_REQUESTED](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("/artpool/v1/art-pool/nft/unstake/requested", payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [GET_ARTPOOL_GALLERY](context, payload) {
    if (payload.page === 0) {
      context.commit(RESET_ARTPOOL_GALLERY);
    }
    return new Promise((resolve, reject) => {
      ApiService.get("/threespace/v1/artpools/collection", payload)
        .then(({ data }) => {
          context.commit(SET_ARTPOOL_GALLERY, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [GET_ARTPOOL_GALLERY_PLACES](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("/threespace/v1/artpools/collection/places/" + payload)
        .then(({ data }) => {
          context.commit(SET_ARTPOOL_GALLERY_PLACES, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [CLAIM_REWARD_BY_ARTPOOL](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("/transaction/v1/reward/claim/artpool", payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
};

const mutations = {
  [SET_STAKING_POOLS](state, stakingInfo) {
    state.currentStakingPool = stakingInfo.current;
    state.stakingPoolInfo.current = stakingInfo.currentRound;
    state.stakingPoolInfo.prev = stakingInfo.prevRound;
    state.stakingPoolInfo.next = stakingInfo.nextRound;
  },
  [SET_ALLOWANCE_STATUS](state, payload) {
    state.isApproved = payload;
  },
  [SET_CURRENT_POOL_USER_DATA](state, payload) {
    state.currentPoolUserData = payload;
  },
  [SET_ARTPOOLS](state, nftPoolList) {
    state.artpoolItems = nftPoolList;
    state.artpoolItemsContent.push(...nftPoolList.content);
  },
  [SET_ARTPOOLS_INCLUDED_MONTH](state, calendarArtpoolList) {
    state.calendarArtpoolList = calendarArtpoolList;
  },
  [SET_ARTPOOL](state, nftPool) {
    state.artpoolInfo = nftPool;
  },
  [SET_ARTPOOL_SIMPLE](state, nftPool) {
    state.artpoolSimpleInfo = nftPool;
  },
  [RESET_ARTPOOLS](state) {
    state.artpoolItemsContent = [];
  },
  [SET_MY_NFT_STAKING_INFO](state, stakingInfo) {
    state.nftStakingInfo = stakingInfo;
  },
  [SET_ARTPOOL_PRODUCTS](state, productList) {
    state.artpoolProductItems = productList;
    state.artpoolProductItemsContent.push(...productList.content);
  },
  [RESET_ARTPOOL_PRODUCTS](state) {
    state.artpoolProductItemsContent = [];
  },
  [SET_AVAILABLE_COLLECTIONS](state, collectionList) {
    state.availableCollections = collectionList;
  },
  [SET_STAKED_COLLECTIONS](state, collectionList) {
    state.stakedCollections = collectionList;
  },
  [SET_ARTPOOL_PREVIOUS_PARAMS](state, previousParams) {
    state.previousParams = previousParams;
  },
  [SET_ARTPOOL_COLLECTIONS](state, collectionList) {
    state.artpoolCollectionItems = collectionList;
    // state.artpoolCollectionItemsContent.push(...collectionList.content);
    state.artpoolCollectionItemsContent = collectionList.content;
  },
  [SET_ARTPOOL_COLLECTIONS_TOP_RANK](state, topRankCollections) {
    state.topRankCollections = topRankCollections;
  },
  [SET_ARTPOOL_COLLECTIONS_TOP_RANK_LIST](state, topRankList) {
    state.topRankList = topRankList;
  },
  [RESET_ARTPOOL_COLLECTIONS](state) {
    state.artpoolCollectionItemsContent = [];
  },
  [SET_STAKED_ERC_COLLECTIONS](state, collections) {
    state.stakedErcCollections = collections;
  },
  [SET_STAKED_KIP_COLLECTIONS](state, collections) {
    state.stakedKipCollections = collections;
  },
  [SET_KLAYTN_NFT_APPROVAL_STATUS](state, payload) {
    state.isKlaytnNftApproved = payload;
  },
  [SET_ETHEREUM_NFT_APPROVAL_STATUS](state, payload) {
    state.isEthereumNftApproved = payload;
  },
  [SET_KLAYTN_VOTE_APPROVAL_STATUS](state, payload) {
    state.isKlaytnVoteApproved = payload;
  },
  [SET_ETHEREUM_VOTE_APPROVAL_STATUS](state, payload) {
    state.isEthereumVoteApproved = payload;
  },
  [SET_ARTPOOL_GALLERY](state, artpoolGallery) {
    state.artpoolGallery = artpoolGallery;
    state.artpoolGalleryContent.push(...artpoolGallery.content);
  },
  [RESET_ARTPOOL_GALLERY](state) {
    state.artpoolGalleryContent = []
  },
  [SET_ARTPOOL_GALLERY_PLACES](state, artpoolGalleryPlaces) {
    state.artpoolGalleryPlaces = artpoolGalleryPlaces;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
