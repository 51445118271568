<template>
  <div ref="canvasContainer" class="canvas-container"></div>
</template>

<script>
// Create a global state to track initialization
if (!window._artworkState) {
  window._artworkState = {
    hasInitialized: false,
    isFirstLoad: true  // New flag to track first attempt
  };
}

const images = {
  /* 붓 이미지파일 수정하는 코드 */
  brush: require('./source/brush.png'),

  /* 가구 이미지파일 수정하는 코드 */
  furniture: require('./source/furniture-new.png'),

  /* 아래부분 지우면 애니메이션 및 식물 삭제가능 */
  light: require('./source/light-on.png'),
  tv: require('./source/tv-dark.png'),
  plant:[
    require('./source/plant-1.png'),
    require('./source/plant-2.png'),
    require('./source/plant-3.png'),
    require('./source/plant-4.png'),
    require('./source/plant-5.png'),
    require('./source/plant-6.png'),
    require('./source/plant-7.png'),
    require('./source/plant-8.png'),
    require('./source/plant-9.png'),
    require('./source/plant-10.png')
  ],
  flower:[
    require('./source/flower-1.png'),
    require('./source/flower-2.png'),
  ]
};

export default {
  name: 'IntroArtwork',
  props: {
    headerHeight: {
      type: Number,
      default: 100 // 기본값 100 설정
    },
    weight: {
      type: Number,
      default: 1 // 기본값 1 설정. 비례하여 조정
    },
    speed: {
      type: Number,
      default: 1 // 기본값 1 설정. 비례하여 조정
    },
    brushSize: {
      type: Number,
      default: 1 // 기본값 1 설정. 비례하여 조정
    },
    sceneScale: {
      type: Number,
      default: 1 // 기본값 1 설정. 비례하여 조정
    },
  },
  data() {
    return {
      p5Instance: null
    };
  },
  methods: {
    async initCanvas() {
      if (this.p5Instance) return;

      try {
        const module = await import('./component_bundle.js');
        this.p5Instance = module.default(
          this.$refs.canvasContainer,
          images,
          {
            headerHeight: this.headerHeight,
            moverWeight: this.weight,
            moverSpeed: this.speed,
            brushSize: this.brushSize,
            sceneScale: this.sceneScale,
          }
        );
        
        // Mark as initialized if successful
        window._artworkState.hasInitialized = true;
        
      } catch (error) {
        console.error('Canvas initialization error:', error);
        
        // Only reload if this is our first try and we haven't initialized before
        if (window._artworkState.isFirstLoad && !window._artworkState.hasInitialized) {
          window._artworkState.isFirstLoad = false;  // Prevent future reloads
          window.location.href = '/home';
          return;
        }
      }
    }
  },
  mounted() {
    this.initCanvas();
  },
  beforeDestroy() {
    if (this.p5Instance) {
      this.p5Instance.remove();
      this.p5Instance = null;
    }
  }
};
</script>

<style scoped>
.canvas-container {
  width: 100%;
  height: 100%;
  position: relative;
}
</style>