<template>
  <b-modal
    id="stakeModal"
    ref="stakeModal"
    hide-header
    hide-footer
    centered
    body-class="text-center text-black mb-4"
    @hide="modalEvent(false)"
    @show="modalEvent(true)"
  >
    <CRow>
      <CCol class="col-12">
        <div class="d-flex justify-content-end">
          <img
            class="cursor-pointer"
            src="/img/threespace/icon/close.png"
            alt="close"
            @click="$refs['stakeModal'].hide()"
          />
        </div>
      </CCol>
    </CRow>
    <div
      style="
        color: #000;
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 6px;
      "
    >
      {{ $t("stake.stake") }}
    </div>
    <div class="art-pool-modal-content">
      <div class="art-pool-modal-content-row main_row">
        <div class="first">
          <img src="/img/threespace/logo/space_black.png" />
        </div>
        <div class="amount">
          <b-input
            type="text"
            v-model="stakingAmount"
            :placeholder="$t('stake.amountPlaceholder')"
          />
          <div class="currency">
            <div>oPACE</div>
            <b-button @click="onClickMaxBtn" class="max_btn">MAX</b-button>
          </div>
        </div>
      </div>
      <div
        class="art-pool-modal-content-row"
        style="
          display: flex;
          background-color: #f7f7f7;
          margin-top: 10px;
          padding: 10px;
          align-items: center;
        "
      >
        <div class="wallet_amount">
          {{ $t("stake.walletAmount") }}
        </div>
        <div class="amount_input" style="display: flex; margin-left: auto">
          <b-input
            style="background-color: #f7f7f7; border: none"
            readonly
            type="text"
            class="text-primary"
            :value="wallet.balance.kpace.quantity | currency"
          />
          <div class="amount_currency">oPACE</div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <div class="mt-4" style="width: 100%; max-width: 90%">
        <Button :label="$t('button.next')" :onClick="onClickStakeBtn" />
      </div>
    </div>
    <!-- <div class="w-100 text-center">
      <b-button @click="onClickStakeBtn" class="stake_modal_btn">
        {{ $t("button.next") }}
      </b-button>
    </div> -->
  </b-modal>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Button from "@/views/threespace/components/common/Button.vue";

export default {
  name: "ArtPoolStakeModal",
  components: {
    Button,
  },
  data: function () {
    return {
      stakingAmount: "",
    };
  },
  computed: {
    ...mapState(["artpool", "wallet"]),
  },
  mounted() {},
  methods: {
    show() {
      this.$refs["stakeModal"].show();
    },
    hide() {
      this.$refs["stakeModal"].hide();
    },
    onClickMaxBtn() {
      this.stakingAmount = parseFloat(
        Math.floor(this.wallet.balance.kpace.quantity * 100) / 100
      ).toFixed(2);
    },
    onClickStakeBtn() {
      let stakingAmount = parseFloat(
        Math.floor(this.stakingAmount * 100) / 100
      ).toFixed(2);
      let walletAmount = parseFloat(
        Math.floor(this.wallet.balance.kpace.quantity * 100) / 100
      ).toFixed(2);

      if (this.stakingAmount === "" || stakingAmount < 0.01) {
        alert("스테이킹할 수량을 입력해주세요");
        return;
      }
      if (
        Math.round(parseFloat(stakingAmount) * 100) / 100 >
        Math.round(parseFloat(walletAmount) * 100) / 100
      ) {
        alert("스테이킹할 수 있는 수량을 초과하였습니다.");
        return;
      }
      this.$emit("onClickStakeBtn", stakingAmount);
    },
    modalEvent(isVisible) {
      if (isVisible) {
        // open event hook
      } else {
        // close event hook ***>> modal data init here
        this.stakingAmount = "";
      }
    },
  },
  filters: {
    currency: (value) => {
      if (!value) return "";
      return parseFloat(Math.floor(value * 100) / 100)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    },
  },
};
</script>
