<template>
  <b-modal
    id="simple-image-popup"
    ref="simple-image-popup"
    class="home_event_modal"
    centered
    scrollable
    hide-header
    hide-footer
    body-class="p-0"
  >
    <div class="sip_klay_content">
      <div class="close_btn" @click="hide"></div>
      <div class="none_seen_week_btn" @click="onClickPopupNoneSeenWeekBtn"></div>
      <div class="img_box">
        <img
          src="/img/threespace/event/system_maintenance_220126_ko.jpg"
          v-if="$i18n.locale === 'ko'"
        />
        <img src="/img/threespace/event/system_maintenance_220126_en.jpg" v-else />
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "SimpleImagePopup",
  data() {
    return {
      arrowColor: "#000",
      openWinnerList: false
    };
  },
  methods: {
    show() {
      this.$refs["simple-image-popup"].show();
    },
    hide() {
      this.$refs["simple-image-popup"].hide();
    },
    onClickPopupNoneSeenWeekBtn() {
      this.$emit("onClickPopupNoneSeenWeekBtn");
    }
  }
};
</script>

<style scoped>
.sip_klay_content {
  position: relative;
  overflow: hidden;
}
.sip_klay_content .close_btn {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 5%;
  height: 4%;
  cursor: pointer;
  z-index: 999;
}
.sip_klay_content .none_seen_week_btn {
  position: absolute;
  bottom: 3%;
  right: 7%;
  width: 20%;
  height: 5%;
  cursor: pointer;
  z-index: 999;
}
.sip_klay_content .img_box {
  width: 100%;
  height: auto;
}
.sip_klay_content .img_box img{
  width: 100%;
  height: auto;
}
</style>
