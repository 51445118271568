<template>
  <div id="MypageCollected" class="favorites">
    <template
      class="row"
      v-if="
        favoritedCollectionList === undefined ||
        favoritedCollectionList === null ||
        favoritedCollectionList.content.length === 0
      "
    >
      <div class="col-12 mb-5 py-5 text-center" key="0">
        <h5 class="pb-5 my-5 text-gray">
          {{ $t("mypage.noHistoryFavorited") }}
        </h5>
      </div>
    </template>
    <div class="favorites__wrapper" v-else>
      <div
        class="item"
        v-for="(item, i) in favoritedCollectionList.content"
        :key="i"
        :ref="'cartItem' + item.idx"
      >
        <div :id="'galleryItem' + item.idx" @click="showProductDetail(item)">
          <v-lazy :options="{ threshold: 0.5 }" transition="fade-transition">
            <div
              class="img-box"
              @contextmenu.prevent.stop
              :class="{ skeleton_display_none: !isBusy }"
            >
              <!-- <CardHoverBox
                :favoritedCnt="item.favoritedCnt"
                :pageViewCnt="item.product.pageViewCnt"
                :isExternalCollection="
                  item.idxProduct === undefined ||
                  item.idxProduct === null ||
                  item.idxProduct === ''
                "
              /> -->
              <template>
                <template v-if="!item.video">
                  <b-img :src="item.imageUrl"></b-img>
                </template>
                <template v-else>
                  <video
                    :poster="replaceDisplayPosterUrl(item)"
                    :src="replaceDisplayCardUrl(item)"
                    type="video/webm"
                    muted
                    loop
                    autoplay
                    playsinline
                  ></video>
                </template>
              </template>
              <b-skeleton-img
                no-aspect
                width="100%"
                height="100%"
              ></b-skeleton-img>
            </div>
          </v-lazy>
          <section class="card-info">
            <section class="card-info__title" v-if="!isBusy">
              <p
                class="artist_name"
                v-if="
                  item.idxProduct !== undefined &&
                  item.idxProduct !== null &&
                  item.idxProduct !== ''
                "
              >
                <template
                  v-if="activeLanguage !== undefined && activeLanguage === 'ko'"
                >
                  {{ item.artist || item.artistEn }}
                </template>
                <template v-else>
                  {{ item.artistEn || item.artist }}
                </template>
              </p>
              <div class="icon-wrap">
                <FavoritedButton
                  :favoritedItem="item"
                  @onClickFavoritedButton="onClickFavoritedButton"
                />
              </div>
            </section>
            <div class="card-info__title" v-else>
              <p class="artist_name">
                <b-skeleton
                  type="avatar"
                  height="30px"
                  width="30px"
                  class="float-left"
                ></b-skeleton>
                <b-skeleton
                  class="float-left mt-2 ml-2"
                  width="100px"
                ></b-skeleton>
              </p>
            </div>
            <h3 class="card-info__name" v-if="!isBusy">
              <template
                v-if="activeLanguage !== undefined && activeLanguage === 'ko'"
              >
                {{ item.title || item.titleEn }}
              </template>
              <template v-else>
                {{ item.titleEn || item.title }}
              </template>
            </h3>
            <h3 class="card-info__name" v-else>
              <b-skeleton
                class="float-left mt-2 ml-2"
                width="200px"
              ></b-skeleton>
            </h3>
            <section
              class="card-info__price"
              :class="{ soldout: item.product.status === 'SOLDOUT' }"
              v-if="!isBusy"
            >
              <p class="price">price</p>
              <p class="amount">
                <template v-if="item.product.status === 'SOLDOUT'">
                  {{
                    item.product.buyerNickname !== undefined &&
                    item.product.buyerNickname !== ""
                      ? "Owned by @" + item.product.buyerNickname
                      : $t("product.statusSoldOut")
                  }}
                </template>
                <template v-else-if="item.product.status === 'EXHIBIT'">
                  {{ $t("product.statusExhibit") }}
                </template>
                <template
                  v-else-if="
                    activeLanguage !== undefined &&
                    activeLanguage === 'en' &&
                    isCoinPriceNonZero(item.product.nftType) &&
                    isAcceptableNftType(item.product.nftType)
                  "
                >
                  {{
                    getProductCoinPrice(
                      item.product.productPrice,
                      item.product.nftType
                    )
                  }}
                  {{ getCoinCurrencyByNftType(item.product.nftType) }}
                </template>
                <template v-else>
                  {{ item.product.productPrice | currency }}
                  {{
                    item.product.productPrice !== undefined &&
                    item.product.productPrice > 0
                      ? "KRW"
                      : $t("product.statusExhibit")
                  }}
                </template>
              </p>
            </section>
            <section class="card-info__price" v-else>
              <span>
                <b-skeleton width="50%" height="25px"></b-skeleton>
              </span>
            </section>
          </section>
        </div>
      </div>
    </div>
    <!-- pagination -->
    <div class="row">
      <div class="col-12 text-right">
        <b-pagination
          v-on:change="onPaging"
          :total-rows="favoritedCollectionList.totalElements"
          v-model="currentPage"
          :per-page="perPage"
          hide-ellipsis
          limit="10"
          aria-controls="role-function-list"
          class="float-right gallery_pagination"
        >
          <template #first-text>
            <img
              class="page_icon rotate_page_icon"
              src="/img/threespace/icon/pagination_arrow2_icon.png"
            />
          </template>
          <template #prev-text>
            <img
              class="page_icon rotate_page_icon"
              src="/img/threespace/icon/pagination_arrow_icon.png"
            />
          </template>
          <template #next-text>
            <img
              class="page_icon"
              src="/img/threespace/icon/pagination_arrow_icon.png"
            />
          </template>
          <template #last-text>
            <img
              class="page_icon"
              src="/img/threespace/icon/pagination_arrow2_icon.png"
            />
          </template>
          <template #page="{ page }">
            <span :class="{ active_num: page === currentPage }">
              {{ page }}
            </span>
          </template>
        </b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import vClickOutside from "v-click-outside";
import FavoritedButton from "./FavoritedButton.vue";
import CardHoverBox from "./CardHoverBox.vue";
import coinUtils from "../../../../mixins/CoinUtils";

export default {
  components: {
    FavoritedButton,
    CardHoverBox,
  },
  name: "FavoritedCollections",
  props: {
    idxMember: {
      type: Number,
      default: 0,
    },
  },
  mixins: [coinUtils],
  data() {
    return {
      //TODO 이하 현재 미사용
      isBusy: false,
      isLast: false,
      perPage: 12,
      currentPage: 1,
      sort: [{ id: "createdAt", desc: "desc" }],
      showCategory: false,
      showCardMenuIdx: 0,
      counter: 0,
      exportCollection: {},
    };
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  computed: {
    ...mapState("favorited", ["favoritedCollectionList"]),
    ...mapState({
      me: (state) => {
        return state.auth.me || {};
      },
    }),
    ...mapGetters("auth", ["isAuthenticated"]),
    activeLanguage() {
      return this.getActiveLanguage();
    },
    isMobile() {
      return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    },
  },
  mounted() {
    this.getTickers();
    this.$nextTick(() => {
      this.requestCollectionItems();
    });
  },
  methods: {
    ...mapActions("code", ["getChildMultiCodes"]),
    ...mapActions("coin", ["getTickers"]),
    ...mapActions("favorited", [
      "getFavoritedsByMember",
      "addFavorited",
      "deleteFavorited",
      "toggleFavorited",
    ]),
    ...mapActions("auth", ["verifyTokenCallback"]),
    getActiveLanguage() {
      return localStorage.getItem("language") || "en";
    },
    requestCollectionItems() {
      if (this.idxMember !== undefined) {
        this.isBusy = true;
        this.getFavoritedsByMember(
          this.getPagingParams({
            page: this.currentPage - 1,
            pageSize: this.perPage,
            sorted: this.sort,
            filtered: [],
          })
        )
          .then(() => {
            this.isBusy = false;
          })
          .catch(() => {
            this.isBusy = false;
          });
      }
    },
    getPagingParams({ page, pageSize, sorted, filtered }) {
      const params = {
        page,
        size: pageSize,
        idxMember: this.idxMember,
      };
      // sorting parameter
      let sorts = [];
      for (let i = 0, length = sorted.length; i < length; i++) {
        const sort = sorted[i];
        sorts.push(`${sort.id},${sort.desc ? "desc" : "asc"}`);
      }
      params["sort"] = sorts;

      // filtering parameter
      for (let i = 0, length = filtered.length; i < length; i++) {
        const filter = filtered[i];
        params[filter.id] = filter.value;
      }

      return params;
    },
    onClickExportNft(collection) {
      this.exportCollection = collection;
      this.$refs["myPageExportCollectionModal"].show();
    },
    onPaging(page) {
      this.currentPage = page;
      this.requestCollectionItems();
    },
    showProductDetail(item) {
      if (
        item.idxProduct !== undefined &&
        item.idxProduct !== null &&
        item.idxProduct !== ""
      ) {
        this.$router.push("/art/" + item.idxProduct);
      } else {
        if (item.nft.name === "KlipDrops") {
          let url = `https://klipdrops.com/market/${item.nft.contractAddr}/${item.tokenId}`;
          window.open(url, "_blank");
        } else {
          let chain = "";
          if (item.nft.rfc.indexOf("ERC") !== -1) {
            chain = "ethereum";
          } else if (item.nft.rfc.indexOf("KIP") !== -1) {
            chain = "klaytn";
          }

          let url = `https://opensea.io/assets/${chain}/${item.nft.contractAddr}/${item.tokenId}`;
          window.open(url, "_blank");
        }
      }
    },
    replaceDisplayPosterUrl(item) {
      if (
        item.idxProduct !== undefined &&
        item.idxProduct !== null &&
        item.idxProduct !== ""
      ) {
        if (
          item.product.displayPoster !== undefined &&
          item.product.displayPoster !== null &&
          item.product.displayPoster !== "" &&
          item.product.displayPoster === "THREESPACE"
        ) {
          return item.product.displayPosterUrlThreespace; // 3space S3 URL
        } else {
          if (
            item.product.displayPosterUrl !== undefined &&
            item.product.displayPosterUrl.startsWith("https://ipfs.io/")
          ) {
            return item.product.displayPosterUrl.replace(
              "https://ipfs.io/",
              "https://ipfs.3space.art/"
            );
          }
          return item.product.displayPosterUrl;
        }
      } else {
        return item.imageUrl;
      }
    },
    replaceDisplayCardUrl(item) {
      if (
        item.idxProduct !== undefined &&
        item.idxProduct !== null &&
        item.idxProduct !== ""
      ) {
        if (
          item.product.displayCardFile !== undefined &&
          item.product.displayCardFile !== null &&
          item.product.displayCardFile !== "" &&
          item.product.displayCardFile === "THREESPACE"
        ) {
          return item.product.displayCardUrlThreespace; // 3space S3 URL
        } else {
          if (
            item.product.displayCardUrl !== undefined &&
            item.product.displayCardUrl.startsWith("https://ipfs.io/")
          ) {
            return item.product.displayCardUrl.replace(
              "https://ipfs.io/",
              "https://ipfs.3space.art/"
            );
          }
          return item.product.displayCardUrl;
        }
      } else {
        return item.imageUrl;
      }
    },
    onClickFavoritedButton(favoritedItem) {
      if (this.isAuthenticated) {
        this.verifyTokenCallback().then(() => {
          if (
            this.me === undefined ||
            this.me === null ||
            this.me.idx === undefined ||
            this.me.idx === null ||
            this.me.idx === ""
          ) {
            // 로그인
            this.hasKlaytnAddr = false;
            this.$store.commit("setCommonAlertModalInfo", {
              msgHtml: this.$t("validation.requiredLogin"),
              show: true,
              fontSize: "sm",
            });
          } else {
            // 로그인 되어있음
            if (favoritedItem.favorited) {
              // true이면 좋아요 헤재
              this.toggleFavorited(favoritedItem);
              this.deleteFavorited({ idxCollection: favoritedItem.idx }).then(
                () => {
                  this.requestCollectionItems();
                }
              );
            } else {
              // false이면 좋아요
              this.toggleFavorited(favoritedItem);
              this.addFavorited({ idxCollection: favoritedItem.idx }).then(
                () => {
                  this.requestCollectionItems();
                }
              );
            }
          }
        });
      } else {
        this.$store.commit("setCommonAlertModalInfo", {
          msgHtml: this.$t("validation.requiredLogin"),
          show: true,
          fontSize: "sm",
        });
      }
    },
  },
  filters: {
    currency: (value) => {
      if (!value) return "";
      return parseInt(value)
        .toFixed(0)
        .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    },
  },
};
</script>
