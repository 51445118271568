<template>
  <div>
    <div class="mypage-form password">
      <div class="password-form">
        <!-- 현재 비밀번호 -->
        <div>
          <p class="title">
            {{ $t("mypage.changedPassword.password") }}
          </p>
          <div class="input_box">
            <b-input
              type="password"
              v-model="externalParams.password"
              @keypress.enter.prevent="onClickPasswordChangeSave"
            />
          </div>
          <p class="warning">
            <template v-if="warningText.password !== ''">
              {{ warningText.password }}
            </template>
          </p>
        </div>

        <!-- 신규 비밀번호 -->
        <div>
          <p class="title">
            {{ $t("mypage.changedPassword.newPassword") }}
          </p>
          <div class="input_box">
            <b-input
              type="password"
              v-model="externalParams.newPassword"
              @keypress.enter.prevent="onClickPasswordChangeSave"
            />
          </div>
          <p class="warning">
            <template v-if="warningText.newPassword !== ''">
              {{ warningText.newPassword }}
            </template>
          </p>
        </div>

        <!-- 신규 비밀번호 -->
        <div>
          <p class="title">
            {{ $t("mypage.changedPassword.newPasswordCheck") }}
          </p>
          <div class="input_box">
            <b-input
              type="password"
              v-model="externalParams.newPasswordCheck"
              @keypress.enter.prevent="onClickPasswordChangeSave"
            />
          </div>
          <p class="warning">
            <template v-if="warningText.newPasswordCheck !== ''">
              {{ warningText.newPasswordCheck }}
            </template>
          </p>
        </div>
      </div>

      <div class="button-wrapper">
        <Button
          :onClick="onClickPasswordChangeSave"
          :label="$t('mypage.save')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FormValidations from "@/mixins/FormValidations";
import { mapActions, mapState } from "vuex";
import Button from "@/views/threespace/components/common/Button.vue";

export default {
  name: "MyPageUpdatePassword",
  components: {
    Button,
  },
  mixins: [FormValidations],
  data() {
    return {
      externalParams: {
        password: "",
        newPassword: "",
        newPasswordCheck: "",
      },
      warningText: {
        password: "",
        newPassword: "",
        newPasswordCheck: "",
      },
    };
  },
  computed: {
    ...mapState("profile", ["memberInfo"]),
    ...mapState("auth", ["me"]),
  },
  methods: {
    ...mapActions("profile", ["updateProfilePassword"]),
    ...mapActions("auth", ["logout"]),
    onClickPasswordChangeSave() {
      if (this.passwordChangeValidation()) {
        this.externalParams.idx = this.me.idx;
        this.updateProfilePassword(this.externalParams)
          .then(() => {
            // 성공
            alert(this.$t("mypage.changedPassword.success"));
            this.logout()
              .then(() => {
                this.$router.push({ name: "Drops" });
              })
              .catch(() => {});
          })
          .catch((error) => {
            // 실패
            if (error.error === "MISMATCHED") {
              this.warningText.password = this.$t(
                "mypage.changedPassword.wrongCheck"
              );
            } else {
              alert("요청중 문제가 있습니다. 관리자에게 문의해주세요.");
            }
          });
      }
    },
  },
};
</script>
