<template>
  <footer id="V2FooterWrap" class="footer">
    <section class="footer__navigation">
      <a href="https://medium.com/@3spaceart" target="_blank">
        <span>MEDIUM</span>
        <img src="/img/threespace/icon/external-link.svg" />
      </a>
      <a href="https://twitter.com/3spaceart" target="_blank">
        <span>X(TWITTER)</span>
        <img src="/img/threespace/icon/external-link.svg" />
      </a>
      <a href="https://t.me/threespaceart_chat" target="_blank">
        <span>TELEGRAM</span>
        <img src="/img/threespace/icon/external-link.svg" />
      </a>
      <a href="https://www.instagram.com/3space.art/" target="_blank">
        <span>INSTAGRAM</span>
        <img src="/img/threespace/icon/external-link.svg" />
      </a>
      <a href="https://github.com/3spaceart" target="_blank">
        <span>GITHUB</span>
        <img src="/img/threespace/icon/external-link.svg" />
      </a>
    </section>
    <section class="footer__href pc">
      <a href="/terms/terms_of_service" target="_blank">{{
        $t("menu.terms")
      }}</a>
      <a href="/terms/privacy_policy" target="_blank">{{
        $t("menu.privacyPolicy")
      }}</a>
      <a href="/contact">{{ $t("menu.inquiries") }}</a>
    </section>

    <section class="footer__wrapper">
      <div>
        <img src="/img/threespace/logo/title.svg" />
        <span>(C) 2025 3space Art</span>
      </div>
      <div>
        <div class="description">
          <p></p>
          <p style="font-weight: 700">{{ $t("menu.space") }}</p>
        </div>
        <div class="description">
          <p>CEO.</p>
          <p>{{ $t("menu.ceo") }}</p>
          <p style="color: #999">TEL.</p>
          <p>{{ $t("menu.tel") }}</p>
        </div>
        <div v-if="activeLanguage !== 'en'" class="description">
          <p>BUSINESS NUMBER.</p>
          <p>{{ $t("menu.businessNumber") }}</p>
        </div>
        <div v-if="activeLanguage !== 'en'" class="description">
          <p>Mail order sales approval number.</p>
          <p>{{ $t("menu.mailOrderSalesApprovalNumber") }}</p>
        </div>
        <div class="description">
          <p>ADDRESS.</p>
          <p>{{ $t("menu.address") }}</p>
        </div>
      </div>
    </section>

    <section class="footer__href--mobile">
      <a href="/terms/terms_of_service" target="_blank">{{
        $t("menu.terms")
      }}</a>
      <a href="/terms/privacy_policy" target="_blank">{{
        $t("menu.privacyPolicy")
      }}</a>
      <a href="/contact">{{ $t("menu.inquiries") }}</a>
    </section>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      visibleSortBy: false,
    };
  },
  components: {},
  computed: {
    activeLanguage() {
      return this.getActiveLanguage();
    },
  },
  methods: {
    getActiveLanguage() {
      return localStorage.getItem("language") || "en";
    },
    setActiveLanguage(_lang) {
      localStorage.setItem("language", _lang);

      this.$emit("language-changed", _lang);
      window.location.reload();
    },
    isActiveLanguage(_lang) {
      return this.activeLanguage === _lang;
    },
    showSortByFilter(value) {
      if (typeof value === "boolean") this.visibleSortBy = value;
      else {
        this.visibleSortBy = !this.visibleSortBy;
      }
    },
  },
};
</script>
