<template>
  <li :class="{ active: isActive }">
    <div class="header-hover__main">
      <span>W3</span>
      <img src="/img/threespace/icon/chevron.svg" />
    </div>
    <div class="header-hover__menu">
      <router-link to="/ordinals">
        {{ $t("menu.ordinals") }}
      </router-link>
      <router-link to="/ai">A.I.</router-link>
      <router-link to="/meme">MEME</router-link>
    </div>
  </li>
</template>

<script>
export default {
  name: "HeaderDropdownW3",
  data() {
    return {};
  },
  computed: {
    isActive() {
      const activeLink = ["/ordinals", "/ai", "/meme"];
      return activeLink.includes(this.$route.path);
    },
  },
  methods: {},
};
</script>
