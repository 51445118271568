import ApiService from "@/services/api.service";
import logger from "@/logger";

// action types
export const GET_NFTS = "getNfts";
export const GET_NFT = "getNft";
export const ADD_NFT = "addNft";
export const UPDATE_NFT = "updateNft";
export const RESET_NFT = "resetNft";
export const IMPORT_KLAYTN_NFT = "importKlaytnNft";
export const IMPORT_ETHEREUM_NFT = "importEthereumNft";
export const IMPORT_POLYGON_NFT = "importPolygonNft";
export const UPLOAD_NFT_ICON_IMAGE = "uploadNftIconImage"

// mutation types
export const SET_NFTS = "setNfts";
export const SET_NFT = "setNft";
export const UPDATE_RESET_NFT = "updateNft";

const state = {
  errors: null,
  nftTableItems: {
    content: [],
    first: true,
    last: false,
    number: 0,
    numberOfElements: 0,
    size: 0,
    sort: {
      orders: []
    },
    total: 0,
    totalElements: 0,
    totalPages: 1
  },
  nftTableFields: [
    { key: "index", label: "번호", class: "text-center", thStyle: "width:5%" },
    { key: "idx", label: "IDX", class: "text-center", thStyle: "width:76px" },
    { key: "name", label: "이름" },
    { key: "symbol", label: "심볼" },
    { key: "contractAddr", label: "컨트랙트 주소" },
    { key: "network", label: "네트워크", class: "text-center" },
    { key: "actions", label: "Actions", class: "text-center", thStyle: "width:70px" }
  ],
  nftSelectTableFields: [
    { key: "index", label: "번호", class: "text-center", thStyle: "width:50px" },
    { key: "name", label: "이름" },
    { key: "symbol", label: "심볼" },
    { key: "contractAddr", label: "컨트랙트 주소" },
    { key: "rfc", label: "RFC", class: "text-center" },
  ],
  nftInfo: {
    name: null,
    symbol: null,
    contractAddr: null,
    rfc: null
  }
};

const getters = {};

const actions = {
  [GET_NFTS](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("/management/v1/nfts", payload)
        .then(({ data }) => {
          context.commit(SET_NFTS, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [GET_NFT](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("/management/v1/nfts/" + payload)
        .then(({ data }) => {
          context.commit(SET_NFT, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [ADD_NFT](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("/management/v1/nfts", payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [UPDATE_NFT](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.put("/management/v1/nfts/" + payload.idx, payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [RESET_NFT](context) {
    context.commit(UPDATE_RESET_NFT);
  },
  [IMPORT_KLAYTN_NFT](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("/management/v1/import/klaytn/nft", payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [IMPORT_ETHEREUM_NFT](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("/management/v1/import/ethereum/nft", payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [IMPORT_POLYGON_NFT](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("/management/v1/import/polygon/nft", payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [UPLOAD_NFT_ICON_IMAGE](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post_upload_file("/management/v1/nfts/upload", payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
};

const mutations = {
  [SET_NFTS](state, codeList) {
    state.nftTableItems = codeList;
  },
  [SET_NFT](state, nftInfo) {
    state.nftInfo = nftInfo;
  },
  [UPDATE_RESET_NFT](state) {
    state.nftInfo = {
      name: null,
      symbol: null,
      contractAddr: null,
      rfc: null
    };
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
