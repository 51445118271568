import ApiService from "@/services/api.service";
import logger from "@/logger";

// action types
export const GET_EVENT = "getEvent";
export const GET_EVENT_RESULT = "getEventResult";
export const ADD_EVENT_RESULT = "addEventResult";
export const ADD_EVENT_SHARE_LINK = "addEventShareLink";

// mutation types
export const SET_EVENT = "setEvent";
export const SET_EVENT_RESULT = "setEventResult";

const state = {
  errors: null,
  eventInfo: {},
  eventResultInfo: {}
};

const getters = {};

const actions = {
  [GET_EVENT](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("/threespace/v1/event/" + payload.idxEvent)
        .then(({ data }) => {
          context.commit(SET_EVENT, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [GET_EVENT_RESULT](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get(
        "/threespace/v1/event/results/" +
          payload.idxEvent +
          "/" +
          payload.idxMember
      )
        .then(({ data }) => {
          context.commit(SET_EVENT_RESULT, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [ADD_EVENT_RESULT](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("/threespace/v1/event/results", payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [ADD_EVENT_SHARE_LINK](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("/threespace/v1/event/share-link", payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  }
};

const mutations = {
  [SET_EVENT](state, eventInfo) {
    state.eventInfo = eventInfo;
  },
  [SET_EVENT_RESULT](state, eventResultInfo) {
    state.eventResultInfo = eventResultInfo;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
