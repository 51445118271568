<template>
  <b-modal
    id="approveModal"
    ref="approveModal"
    hide-header
    hide-footer
    centered
    body-class="text-center text-black my-4"
  >
    <b-img class="mb-3" src="/img/threespace/logo/logo_100x100.png" width="55"></b-img>
    <h5 class="mt-3">{{ $t("stake.staking.requiredApprove") }}</h5>
    <div class="w-100 text-center mt-5">
      <b-button
        squared
        variant="secondary"
        class="text-primary px-4 py-2 mr-3"
        @click="hide"
      >
        {{ $t("button.close") }}
      </b-button>
      <b-button
        squared
        variant="primary"
        class="text-white px-4 py-2 mr-3"
        @click="onClickApproveBtn"
      >
        {{ $t("button.approve") }}
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "ArtPoolApproveModal",
  data: function () {
    return {}
  },
  methods: {
    show() {
      this.$refs["approveModal"].show();
    },
    hide() {
      this.$refs["approveModal"].hide();
    },
    onClickApproveBtn() {
      this.$emit("onClickApproveBtn");
    },
    modalEvent(isVisible) {
      if (isVisible) {
        // open event hook
      } else {
        // close event hook ***>> modal data init here
        this.stakingAmount = "";
      }
    }
  },
}
</script>
