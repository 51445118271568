import ApiService from "@/services/api.service";
import logger from "@/logger";
import {DELETE_ARTIST} from "./management.member";

// action types
export const GET_COMPANYS = "getCompanys";
export const GET_COMPANY = "getCompany";
export const ADD_COMPANY = "addCompany";
export const UPDATE_COMPANY = "updateCompany";
export const DELETE_COMPANY = "deleteCompany";
export const GET_MY_COMPANY = "getMyCompany";

// mutation types
export const RESET_COMPANYS = "resetCompanys";
export const SET_COMPANYS = "setCompanys";
export const SET_COMPANY = "setCompany";
export const SET_MY_COMPANY = "setMyCompany";

const state = {
  errors: null,
  companyTableItems: {
    content: [],
    first: true,
    last: false,
    number: 0,
    numberOfElements: 0,
    size: 0,
    sort: {
      orders: []
    },
    total: 0,
    totalElements: 0,
    totalPages: 1
  },
  companyTableItemsContent: [],
  companyTableFields: [
    { key: "index", label: "번호", class: "text-center", thStyle: "width:5%" },
    { key: "updatedAt", label: "최근 수정일시", class: "text-center", thStyle: "width:147px" },
    { key: "idx", label: "법인번호", class: "text-center", thStyle: "width:100px" },
    { key: "companyName", label: "법인명" },
    { key: "companyNameEn", label: "법인명(영문)" },
    { key: "corporationNumber", label: "사업자 등록번호" },
    { key: "phoneNumber", label: "연락처", class: "text-center" },
    { key: "actions", label: "Actions", class: "text-center", thStyle: "width:100px" }
  ],
  companyInfo: {},
  // 법인 선택 팝업
  companySelectTableFields: [
    { key: "index", label: "번호", class: "text-center", thStyle: "width:60px" },
    { key: "idx", label: "법인번호", class: "text-center", thStyle: "width:100px" },
    { key: "companyName", label: "법인명" },
    { key: "corporationNumber", label: "사업자등록번호" },
    { key: "phoneNumber", label: "연락처", class: "text-center" }
  ],
  // 소속법인 정보
  myCompanyInfo: {}
};

const getters = {};

const actions = {
  [GET_COMPANYS](context, payload) {
    if (payload.page === 0) {
      context.commit(RESET_COMPANYS);
    }
    return new Promise((resolve, reject) => {
      ApiService.get("/management/v1/companys", payload)
        .then(({ data }) => {
          context.commit(SET_COMPANYS, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [GET_COMPANY](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("/management/v1/companys/" + payload)
        .then(({ data }) => {
          context.commit(SET_COMPANY, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [ADD_COMPANY](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("/management/v1/companys", payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [UPDATE_COMPANY](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.put("/management/v1/companys/" + payload.idx, payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [DELETE_COMPANY](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.delete("/management/v1/companys", payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(response => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [GET_MY_COMPANY](context) {
    return new Promise((resolve, reject) => {
      ApiService.get("/corp-management/v1/companys")
        .then(({ data }) => {
          context.commit(SET_MY_COMPANY, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  }
};

const mutations = {
  [RESET_COMPANYS](state) {
    state.companyTableItemsContent = [];
  },
  [SET_COMPANYS](state, companyList) {
    state.companyTableItems = companyList;
    state.companyTableItemsContent.push(...companyList.content);
  },
  [SET_COMPANY](state, companyInfo) {
    state.companyInfo = companyInfo;
  },
  [SET_MY_COMPANY](state, companyInfo) {
    state.myCompanyInfo = companyInfo;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
