<template>
  <div>
    <b-modal
      id="payment-klip-modal-1"
      ref="payment-klip-modal-1"
      centered
      hide-header
      hide-footer
      body-class="text-black"
    >
      <CRow>
        <CCol class="col-12">
          <div class="d-flex justify-content-end">
            <img
              class="cursor-pointer"
              src="/img/threespace/icon/close.png"
              alt="close"
              @click="$refs['payment-klip-modal-1'].hide()"
            />
          </div>
        </CCol>
      </CRow>

      <div class="w-100 d-flex justify-content-center align-items-center">
        <p
          style="
            color: #000;
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 6px;
          "
        >
          {{ $t("payment.klip.connectTitle") }}
        </p>
      </div>
      <div
        class="text-center mt-3 px-5"
        style="background-color: #f7f7f7; padding: 10px 20px"
      >
        <p style="font-size: 12px; color: #000">
          {{ $t("payment.klip.connectDesc") }}
        </p>
      </div>
      <div class="d-flex justify-content-center">
        <div
          class="mt-3"
          style="width: 170px; max-width: 170px; margin-right: 10px"
        >
          <button
            style="
              width: 100%;
              border: 1px solid #000;
              padding: 10px;
              height: 31px;
            "
            class="text-black"
            @click="$refs['payment-klip-modal-1'].hide()"
          >
            {{ $t("button.cancel") }}
          </button>
        </div>
        <div class="mt-3" style="width: 170px; max-width: 170px">
          <Button
            :label="$t('button.yesPayment')"
            :onClick="showPaymentKlip2"
            :buttonStyle="{ height: '31px' }"
            :style="{ height: '31px' }"
          />
        </div>
      </div>
      <!-- <div class="my-4 d-flex justify-content-center">
        <b-button squared class="px-4" @click="hide">
          <span>{{ $t("button.cancel") }}</span>
        </b-button>
        <b-button
          variant="primary"
          squared
          class="ml-3 px-4"
          @click="showPaymentKlip2"
          :disabled="timeout"
        >
          <span class="text-white">{{ $t("button.yesPayment") }}</span>
        </b-button>
      </div> -->
    </b-modal>

    <PaymentKlip2 :klay-payment-info="klayPaymentInfo" ref="paymentKlip2" />
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const paymentHelper = createNamespacedHelpers("payment");

import PaymentKlip2 from "@/views/threespace/payment/PaymentKlip2";
import Button from "@/views/threespace/components/common/Button.vue";

export default {
  name: "PaymentKlip1",
  components: {
    PaymentKlip2,
    Button,
  },
  props: {
    orderPaymentInfo: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      klayPaymentInfo: this.getInitData(),
      time: 60,
      timeout: false,
      paymentType: "",
    };
  },
  mounted() {},
  computed: {
    isMobile() {
      return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    },
  },
  methods: {
    ...paymentHelper.mapActions(["updatePaymentKlay"]),
    showPaymentKlip2() {
      this.klayPaymentInfo.idxProduct = this.orderPaymentInfo.idxProduct;
      this.klayPaymentInfo.idxMember = this.orderPaymentInfo.idxMember;
      this.klayPaymentInfo.idxOrderPayment =
        this.orderPaymentInfo.idxOrderPayment;
      //this.$log.log("[showPaymentKlip2] >> ", this.klayPaymentInfo);

      // 작품 상태(예약중) 업데이트
      this.updatePaymentKlay(this.klayPaymentInfo)
        .then((data) => {
          //this.$log.log("[updatePaymentKlay] >> ", data);

          this.klayPaymentInfo.fromAddr = data.fromAddr;
          this.klayPaymentInfo.toAddr = data.toAddr;
          this.klayPaymentInfo.amountCoin = data.amountCoin;

          clearInterval(this.totalTimer);
          this.$refs["paymentKlip2"].show(this.paymentType);
          this.$refs["payment-klip-modal-1"].hide();
        })
        .catch((error) => {
          this.$log.error(error);
          alert(
            "요청에 실패하였습니다. 이용중인 서비스에서 확인 후 다시 시도해주세요."
          );
          clearInterval(this.totalTimer);
          this.$refs["payment-klip-modal-1"].hide();
        });
    },
    show(paymentType) {
      this.time = 60;
      this.timeout = false;
      this.setTotalTimer();
      if (paymentType != undefined) {
        this.paymentType = paymentType;
      }
      this.klayPaymentInfo = this.getInitData();
      this.$refs["payment-klip-modal-1"].show();
    },
    hide() {
      if (confirm(this.$t("payment.payCancelMsg"))) {
        clearInterval(this.totalTimer);
        this.$refs["payment-klip-modal-1"].hide();
      }
    },
    closeModal() {
      clearInterval(this.totalTimer);
    },
    getInitData() {
      return {
        idxProduct: null,
        idxMember: null,
        idxOrderPayment: null,
        status: "RESERVE",
        fromAddr: null,
        toAddr: null,
        amountCoin: null,
        coinCurrencyType: this.paymentType,
      };
    },
    setTotalTimer() {
      this.totalTimer = setInterval(() => {
        this.time--;
        if (this.time === 0) {
          clearInterval(this.totalTimer);
          this.timeout = true;
        }
      }, 1000);
    },
  },
};
</script>
