import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

import auth from "./authentication";
import code from "./management.code";
import company from "./management.company";
import member from "./management.member";
import order from "./management.order";
import product from "./management.product";
import article from "./management.article";
import artpoolManager from "./management.artpool";
import nftManager from "./management.nft";
import collectionManager from "./management.collection";
import artpoolPlaceManager from "./management.artpool.place"

import event from "./threespace.event";
import gallery from "./threespace.gallery";
import payment from "./threespace.payment";
import profile from "./threespace.profile";
import util from "./threespace.util";
import wallet from "./wallet.js";
import features from "./threespace.article";
import coin from "./coin.js"
import transaction from "./transaction.js"
import artpool from "./threespace.artpool";
import artist from "./threespace.artist";
import nft from "./threespace.nft";
import collection from "./threespace.collection";
import favorited from "./threespace.favorited";
import ai from "./threespace.ai";

Vue.use(Vuex);

const state = {
  sidebarShow: "responsive",
  sidebarMinimize: false,
  asideShow: false,
  darkMode: false,
  darkHeader: false,
  wideHeader: false,
  allWhiteTextHeader: false,
  whiteHeader: false,
  eventUserInfo: {
    snsType: "",
    idxMember: "",
    account: "",
    mobile: "",
    message: "",
    isAuthSns: false
  },
  isParticipatedEvent: false,
  isDark: false,
  showLoading: false,
  showLoadingMsg: "",
  commonAlertModalInfo: {
    msgHtml: "",
    show: false,
    fontSize: "sm" // sm, md, lg
  }
};

const mutations = {
  toggleSidebarDesktop(state) {
    const sidebarOpened = [true, "responsive"].includes(state.sidebarShow);
    state.sidebarShow = sidebarOpened ? false : "responsive";
  },
  toggleSidebarMobile(state) {
    const sidebarClosed = [false, "responsive"].includes(state.sidebarShow);
    state.sidebarShow = sidebarClosed ? true : "responsive";
  },
  set(state, [variable, value]) {
    state[variable] = value;
  },
  toggle(state, variable) {
    state[variable] = !state[variable];
  },
  setDarkHeader(state, isDark) {
    state.darkHeader = isDark;
  },
  setWideHeader(state, isWide) {
    state.wideHeader = isWide;
  },
  setEventUserInfo(state, eventUserInfo) {
    state.eventUserInfo = eventUserInfo;
  },
  setParticipatedEvent(state, isParticipatedEvent) {
    state.isParticipatedEvent = isParticipatedEvent;
  },
  setShowLoading (state, status) {
    state.showLoading = status.show;
    state.showLoadingMsg = status.msg;
  },
  setCommonAlertModalInfo (state, commonAlertModalInfo) {
    state.commonAlertModalInfo = commonAlertModalInfo;
  },
};

export default new Vuex.Store({
  state,
  mutations,
  plugins: [
    createPersistedState({
      paths: ["auth"]
    })
  ],
  modules: {
    auth,
    code,
    company,
    member,
    order,
    product,
    article,
    event,
    gallery,
    payment,
    profile,
    util,
    wallet,
    features,
    coin,
    transaction,
    artpool,
    artist,
    nft,
    collection,
    artpoolManager,
    artpoolPlaceManager,
    nftManager,
    favorited,
    collectionManager,
    ai
  }
});
