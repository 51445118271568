import ApiService from "@/services/api.service";
import JwtService from "@/services/jwt.service";
import logger from "@/logger";
import router from "@/router";
import VueI18n from "@/lang/index";

// action types
export const JOIN = "join";
export const GET_TOKEN = "getToken";
export const JOIN_ACCEPT = "joinAccept"; // SNS 가입하기, 인증 후 서비스 이용약관 동의 후 최종 가입하기
export const LOGIN = "login";
export const VERIFY_TOKEN = "verifyToken";
export const VERIFY_TOKEN_CALLBACK = "verifyTokenCallback";
export const LOGOUT = "logout";
export const GET_ACCOUNTS = "getAccount";
export const SEND_EMAIL_RESET_PASSWORD = "sendEmailResetPassword";
export const UPDATE_PASSWORD = "updatePassword";
export const EVENT_JOIN = "eventJoin";
export const FIND_WALLET_ADDR = "findWalletAddr";

// mutation types
export const SET_TOKEN = "setToken";
export const SET_ERROR = "setError";
export const PURGE_TOKEN = "purgeToken";
export const SET_ACCOUNTS = "setAccount";

const state = {
  errors: null,
  user: {},
  me: {},
  privileges: [],
  isAuthenticated: !!JwtService.getToken(),
  isCorporation: false,
  isInitPwd: false,
  accounts: []
};

const getters = {
  isAuthenticated(state) {
    return state.isAuthenticated;
  }
};

const actions = {
  [JOIN](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("/threespace/v1/join", payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(error => {
          logger.error("[JOIN] error: ", error, error.response);
          if (error.response) {
            context.commit(SET_ERROR, error.response.data);
            reject(error.response.data);
          } else {
            context.commit(SET_ERROR, error);
            reject(error);
          }
        });
    });
  },
  [GET_TOKEN](context, tokenSet) {
    const type = tokenSet.type;
    const message = tokenSet.message;
    if (type === "join") {
      if (message === "REQUIRED_ACCEPT_TERMS") {
        router.push({
          name: "JoinTerms",
          params: {
            snsType: tokenSet.snsType,
            idxMember: tokenSet.idxMember,
            email: tokenSet.email,
            mobile: tokenSet.mobile
          }
        });
      } else if (message === "JOIN_ACCOUNT_EXISTS" || message === "JOIN_EMAIL_EXISTS") {
        let msg = VueI18n.t("login.join.existAccount");
        msg = msg.replace("{snsType}", tokenSet.existsSnsType);
        msg = msg.replace("{email}", tokenSet.email);
        alert(msg);
        router.push("/login");
      } else {
        let msg = VueI18n.t("login.join.error");
        alert(msg);
        router.push("/home");
      }
    } else if (type === "login") {
      if (message === "LOGIN_SUCCESS") {
        // 레퍼럴이 업데이트 된 경우 로컬스토리지 정보 삭제
        if (tokenSet.updatedReferral !== undefined && tokenSet.updatedReferral !== null && tokenSet.updatedReferral) {
          let referral = localStorage.getItem("referral3spaceJoin");
          if (referral !== undefined && referral !== null && referral !== "") {
            localStorage.removeItem("referral3spaceJoin");
          }
        }
        JwtService.saveToken(tokenSet.token);
        ApiService.setHeader();
        ApiService.get("/profile/v1/token/verify").then(({ data }) => {
          context.commit(SET_TOKEN, data.data);
          router.push("/home");
        });
      } else if (message === "ACCOUNT_NOT_FOUND") {
        context.commit(PURGE_TOKEN);
        const confirmMsg = VueI18n.t("login.join.notFound");
        if (confirm(confirmMsg)) {
          router.push("/join");
          //router.push("/event"); //TODO 이벤트 기간동안 이벤트 화면으로 이동
        } else {
          router.back();
        }
      } else {
        context.commit(PURGE_TOKEN);
        const msg = VueI18n.t("login.error.verify");
        alert(msg);
        router.push("/home");
      }
    }
  },
  [JOIN_ACCEPT](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.put("/threespace/v1/join/" + payload.idx, payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [LOGIN](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post("/threespace/v1/token/tokens", credentials)
        .then(({ data }) => {
          context.commit(SET_TOKEN, data.data);
          resolve(data.data);
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response.data);
            reject(error.response.data);
          } else if (error.request) {
            context.commit(SET_ERROR, {
              error: "ERR_CONNECTION_REFUSED"
            });
            reject({
              error: "ERR_CONNECTION_REFUSED"
            });
          } else {
            context.commit(SET_ERROR, error);
            reject(error);
          }
        });
    });
  },
  [VERIFY_TOKEN](context) {
    if (JwtService.getToken()) {
      //logger.log("[VERIFY_TOKEN] ", context, JwtService.getToken());
      ApiService.setHeader();
      ApiService.get("/profile/v1/token/verify")
        .then(({ data }) => {
          //logger.log("[VERIFY_TOKEN] ", data.data);
          context.commit(SET_TOKEN, data.data);
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response.data);
          } else if (error.request) {
            context.commit(SET_ERROR, {
              error: "ERR_CONNECTION_REFUSED"
            });
            context.commit(PURGE_TOKEN);
          } else {
            context.commit(SET_ERROR, error);
          }
        });
    } else {
      context.commit(PURGE_TOKEN);
    }
  },
  [VERIFY_TOKEN_CALLBACK](context) {
    //logger.log("[VERIFY_TOKEN_CALLBACK] ", JwtService.getToken());
    return new Promise((resolve, reject) => {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get("/profile/v1/token/verify")
          .then(({ data }) => {
            //logger.log("[VERIFY_TOKEN_CALLBACK] ", data.data);
            context.commit(SET_TOKEN, data.data);
            resolve(data.data);
          })
          .catch(error => {
            if (error.response) {
              context.commit(SET_ERROR, error.response.data);
            } else if (error.request) {
              context.commit(SET_ERROR, {
                error: "ERR_CONNECTION_REFUSED"
              });
              context.commit(PURGE_TOKEN);
            } else {
              context.commit(SET_ERROR, error);
            }
            logger.error(error);
            reject(error);
          });
      } else {
        context.commit(PURGE_TOKEN);
        reject("REQUIRED_LOGIN");
      }
    });
  },
  [LOGOUT](context) {
    // SNS에서 발급받은 토큰 정보 삭제(SNS 로그아웃)
    /*
    if (JwtService.getToken() && JwtService.getSnsAccessToken()) {
      const param = {
        token: JwtService.getToken(),
        snstoken: JwtService.getSnsAccessToken()
      };
      ApiService.get("/threespace/v1/logout", param)
        .then(({ data }) => {
          logger.log("[LOGOUT] logout: ", data.data);
          context.commit(PURGE_TOKEN);
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response.data);
          } else if (error.request) {
            context.commit(SET_ERROR, {
              error: "ERR_CONNECTION_REFUSED"
            });
            context.commit(PURGE_TOKEN);
          } else {
            context.commit(SET_ERROR, error);
          }
        });
    } else {
      context.commit(PURGE_TOKEN);
    }*/
    context.commit(PURGE_TOKEN);
    ApiService.setHeader();
    logger.log("[LOGOUT] ", JwtService.getToken());
  },
  [GET_ACCOUNTS](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("/threespace/v1/account", payload)
        .then(({ data }) => {
          context.commit(SET_ACCOUNTS, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [SEND_EMAIL_RESET_PASSWORD](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("/threespace/v1/account", payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [UPDATE_PASSWORD](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.put("/threespace/v1/reset-password", payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [EVENT_JOIN](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("/threespace/v1/event-join", payload)
        .then(({ data }) => {
          logger.log("[EVENT_JOIN] data: ", data);
          resolve(data.data);
        })
        .catch(error => {
          logger.error("[EVENT_JOIN] error: ", error, error.response);
          if (error.response) {
            context.commit(SET_ERROR, error.response.data);
            reject(error.response.data);
          } else {
            context.commit(SET_ERROR, error);
            reject(error);
          }
        });
    });
  },
  [FIND_WALLET_ADDR](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("/threespace/v1/find/wallet-addr", payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_TOKEN](state, user) {
    state.isAuthenticated = true;
    state.errors = {};
    state.user = user;
    state.me = user.basic;
    state.privileges = user.privileges;
    state.isCorporation = user.isCorporation;
    state.isInitPwd = user.isInitPwd;
    JwtService.saveToken(state.user.jwtAccess);
  },
  [PURGE_TOKEN](state) {
    state.isAuthenticated = false;
    state.errors = {};
    state.user = {};
    state.me = {};
    state.privileges = [];
    JwtService.destroyToken();
  },
  [SET_ACCOUNTS](state, accounts) {
    state.accounts = accounts;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
