<template>
  <div>
    <b-modal
      id="order-payment-modal-2"
      ref="order-payment-modal-2"
      size="lg"
      centered
      hide-header
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      body-class="pb-4 text-dark"
    >
      <CRow>
        <CCol class="col-7 py-2">
          <CImg src="/img/threespace/company/nicepay_logo.png" width="100" />
        </CCol>
        <CCol class="col-5 py-2">
          <h5 class="float-left font-weight-bold">3space Art</h5>
          <CLink @click="hide()">
            <CIcon name="cil-x" class="float-right" size="lg" />
          </CLink>
        </CCol>
      </CRow>

      <CRow class="mt-4">
        <CCol class="font-lg font-weight-bold mb-0">
          {{ $t("payment.vbank2.confirmPayment") }}
        </CCol>
      </CRow>
      <CRow>
        <CCol class="col-7 border-right">
          <hr />
          <table class="table table-clear text-dark w-100">
            <colgroup>
              <col style="width: 32%" />
              <col style="width: 68%" />
            </colgroup>
            <tr>
              <td>
                {{ $t("payment.vbank2.bank") }}
              </td>
              <td class="font-lg font-weight-bold">
                {{ $t("payment.vbank2.bankIBK") }}
              </td>
            </tr>
            <tr>
              <td>
                {{ $t("payment.vbank2.account") }}
              </td>
              <td class="font-lg font-weight-bold text-secondary">
                {{ $t("payment.vbank2.accountMsg") }}
              </td>
            </tr>
            <tr>
              <td>
                {{ $t("payment.vbank2.deadline") }}
              </td>
              <td class="font-lg font-weight-bold">
                {{ paymentInfo.vbankExpDatetime }}
              </td>
            </tr>
            <tr>
              <td>{{ $t("payment.vbank2.receiver") }}</td>
              <td>
                {{ paymentInfo.vbankAccountName }}
                <!--
                <CInput
                  :lazy="false"
                  :value.sync="$v.paymentInfo.vbankAccountName.$model"
                  :isValid="checkIfValid('vbankAccountName')"
                  placeholder="예금주명(필수)"
                  invalidFeedback="필수 정보입니다."
                  class="mb-0"
                />
                -->
              </td>
            </tr>
            <tr>
              <td>
                {{ $t("payment.vbank2.cashReceipt") }}
                <span class="text-danger font-lg">*</span>
              </td>
              <td>
                <b-form-radio-group
                  id="receiptType"
                  name="receiptType"
                  :options="receiptTypeOptions"
                  v-model="paymentInfo.receiptType"
                  class="text-dark"
                  @change="onChangeReceiptType"
                />
                <div class="mt-2" v-if="showReceiptMethod">
                  <div class="col-5 float-left pl-0">
                    <b-form-select
                      :options="receiptMethodOptions"
                      v-model="receiptMethod"
                      class="mb-0"
                    />
                  </div>
                  <div class="col-7 float-left px-0">
                    <CInput
                      :lazy="false"
                      :value.sync="$v.paymentInfo.receiptTypeNo.$model"
                      :isValid="checkIfValid('receiptTypeNo')"
                      :placeholder="$t('validation.onlyNumeric')"
                      :invalidFeedback="$t('validation.required')"
                      class="mb-0"
                    />
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>E-mail <span class="text-danger font-lg">*</span></td>
              <td>
                <CInput
                  :lazy="false"
                  :value.sync="$v.paymentInfo.buyerEmail.$model"
                  :isValid="checkIfValid('buyerEmail')"
                  :placeholder="$t('validation.enterEmail')"
                  :invalidFeedback="$t('validation.requiredEmail')"
                  class="mb-0"
                />
              </td>
            </tr>
          </table>

          <hr />
        </CCol>
        <CCol class="col-5">
          <div>{{ $t("payment.artwork.title") }}</div>
          <p class="font-lg font-weight-bold">
            <template
              v-if="activeLanguage !== undefined && activeLanguage === 'ko'"
            >
              {{
                orderPaymentInfo.productName || orderPaymentInfo.productNameEn
              }}
            </template>
            <template v-else>
              {{
                orderPaymentInfo.productNameEn || orderPaymentInfo.productName
              }}
            </template>
          </p>
          <div>{{ $t("payment.artwork.price") }}</div>
          <p class="font-lg font-weight-bold">
            {{ orderPaymentInfo.productPrice | currency }} KRW
          </p>
        </CCol>
      </CRow>

      <CRow>
        <CCol class="col-7 text-center border-right d-flex flex-column">
          <div>
            <b-form-checkbox id="accept" name="accept" v-model="accept">
              {{ $t("payment.vbank2.confirmMsg") }}
            </b-form-checkbox>
          </div>
          <div class="d-flex justify-content-center">
            <div class="" style="width: 100%">
              <Button
                :label="$t('button.completePayment')"
                :onClick="onClickPayment"
                :disabled="!accept"
              />
            </div>
          </div>
          <!-- <b-button
            variant="primary"
            :disabled="!accept"
            size="lg"
            squared
            class="align-self-end btn-block mt-auto"
            @click="onClickPayment"
          > -->
          <!-- <span class="text-white">{{ $t("button.completePayment") }}</span>
          </b-button> -->
        </CCol>
        <CCol class="col-5 text-center">
          <div class="mx-3 border-dark p-2">
            <p>{{ $t("payment.artwork.total") }}</p>
            <p class="font-xl font-weight-bold">
              {{ orderPaymentInfo.productPrice | currency }} KRW
            </p>
          </div>
        </CCol>
      </CRow>
    </b-modal>

    <PaymentVBank3
      :payment-result-info="paymentResultInfo"
      ref="paymentVBank3"
    />
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import {
  required,
  minLength,
  maxLength,
  email,
  numeric,
} from "vuelidate/lib/validators";
import { mapState } from "vuex";
import { createNamespacedHelpers } from "vuex";
import Button from "@/views/threespace/components/common/Button.vue";

const authHelper = createNamespacedHelpers("auth");
const paymentHelper = createNamespacedHelpers("payment");

import PaymentVBank3 from "./PaymentVBank3";

export default {
  name: "PaymentVBank2",
  components: {
    PaymentVBank3,
    Button,
  },
  props: {
    orderPaymentInfo: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {},
  data: function () {
    return {
      paymentInfo: this.getInitPaymentInfo(),
      receiptTypeOptions: [
        { value: "1", text: this.$t("payment.vbank2.incomeDeduction") },
        { value: "2", text: this.$t("payment.vbank2.proopOfExpense") },
      ],
      receiptMethodOptions: [
        { value: "0", text: this.$t("payment.vbank2.select") },
        { value: "1", text: this.$t("payment.vbank2.mobile") },
        { value: "2", text: this.$t("payment.vbank2.corpNumber") },
      ],
      receiptMethod: "1",
      accept: false,
      showReceiptMethod: true,
      paymentResultInfo: {},
    };
  },
  computed: {
    ...mapState({
      me: (state) => {
        return state.auth.me || {};
      },
    }),
    isValid() {
      return !this.$v.paymentInfo.$invalid;
    },
    isDirty() {
      return this.$v.paymentInfo.$anyDirty;
    },
    activeLanguage() {
      return this.getActiveLanguage();
    },
  },
  mixins: [validationMixin],
  validations() {
    if (this.paymentInfo.receiptType === "0") {
      return {
        paymentInfo: {
          buyerEmail: { required, email },
        },
      };
    } else {
      return {
        paymentInfo: {
          receiptTypeNo: {
            required,
            minLength: minLength(10),
            maxLength: maxLength(11),
            numeric,
          },
          buyerEmail: { required, email },
        },
      };
    }
  },
  mounted() {},
  methods: {
    ...authHelper.mapActions(["verifyTokenCallback"]),
    ...paymentHelper.mapActions(["getPaymentVbank", "addPaymentVbank"]),
    getActiveLanguage() {
      return localStorage.getItem("language") || "en";
    },
    requestPaymentInfo() {
      if (
        this.orderPaymentInfo.idxProduct !== undefined &&
        this.orderPaymentInfo.idxProduct !== null &&
        this.orderPaymentInfo.idxProduct !== ""
      ) {
        this.getPaymentVbank(this.orderPaymentInfo.idxProduct).then((data) => {
          if (
            Object.keys(data).length !== 0 &&
            JSON.stringify(data) !== JSON.stringify({})
          ) {
            this.paymentInfo = data;
            this.paymentInfo.receiptType = "1"; // 소득공제용
          }
        });
      }
    },
    onChangeReceiptType() {
      this.receiptMethod = this.paymentInfo.receiptType;
      if (this.paymentInfo.receiptType === "0") {
        this.showReceiptMethod = false;
      } else {
        this.showReceiptMethod = true;
      }
    },
    onClickPayment() {
      //this.$log.log("[onClickPayment] >> ", this.paymentInfo);

      this.$v.$touch();
      if (this.isValid) {
        this.verifyTokenCallback()
          .then(() => {
            if (
              this.me === undefined ||
              this.me === null ||
              this.me.idx === undefined ||
              this.me.idx === ""
            ) {
              // 로그인
              alert("로그인 후 구매가능합니다. 로그인 후 다시 시도해주세요.");
            } else {
              // 결제하기
              this.addPaymentVbank(this.paymentInfo)
                .then((data) => {
                  //this.$log.log("[addPaymentVbank] >> ", data);
                  if (
                    data !== undefined &&
                    data !== null &&
                    data.resultCode !== undefined &&
                    data.resultCode !== null
                  ) {
                    if (data.resultCode === "4120") {
                      // 결제완료 화면 이동
                      this.paymentResultInfo = data;
                      this.$refs["paymentVBank3"].show();
                      this.$refs["order-payment-modal-2"].hide();

                      // 판매 법인 정보 삭제
                      localStorage.removeItem(
                        "referralCorpSeller_" +
                          this.paymentResultInfo.idxProduct
                      );
                    } else {
                      alert(
                        "요청에 실패하였습니다. 이용중인 서비스에서 확인 후 다시 시도해주세요."
                      );
                      //TODO 화면 확인을 위해...추후삭제
                      //this.paymentResultInfo = data;
                      //this.$refs["paymentVBank3"].show();
                      //this.$refs["order-payment-modal-2"].hide();
                    }
                  } else {
                    alert(
                      "요청에 실패하였습니다. 이용중인 서비스에서 확인 후 다시 시도해주세요."
                    );
                  }
                })
                .catch((error) => {
                  this.$log.error(error);
                  if (error.data.error === "NOT_FOUND") {
                    if (error.data.resource === "PRODUCT_FORSALE") {
                      alert("구매 가능한 작품이 존재하지 않습니다.");
                    } else if (error.data.resource === "ENTITY") {
                      alert("사용자(회원) 정보가 존재하지 않습니다.");
                    } else if (error.data.resource === "VBANK_EXPIRE") {
                      alert(
                        "가상계좌 입금시간이 만료되었습니다. 다시 시도해 주세요."
                      );
                    } else {
                      alert(
                        "주문결제 오류가 발생하였습니다. 관리자에게 문의하세요."
                      );
                    }
                  } else {
                    alert(
                      "주문결제 오류가 발생하였습니다. 관리자에게 문의하세요."
                    );
                  }
                });
            }
          })
          .catch((error) => {
            this.$log.error(error);
          });
      }
    },
    show() {
      this.getInitForm();
      this.requestPaymentInfo();
      this.$refs["order-payment-modal-2"].show();
    },
    hide() {
      if (confirm(this.$t("payment.payCancelMsg"))) {
        this.$refs["order-payment-modal-2"].hide();
      }
    },
    checkIfValid(fieldName) {
      const field = this.$v.paymentInfo[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    getInitForm() {
      this.paymentInfo = this.getInitPaymentInfo();
      this.accept = false;
      this.receiptMethod = "1";
      this.showReceiptMethod = true;
      this.paymentResultInfo = {};
    },
    getInitPaymentInfo() {
      return {
        idx: null,
        idxProduct: null,
        idxMember: null,
        vbankAccountName: null,
        vbankExpDatetime: null,
        buyerName: null,
        receiptType: "1",
        receiptTypeNo: null,
        buyerEmail: null,
      };
    },
  },
  filters: {
    currency: (value) => {
      if (!value) return "";
      return value.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    },
  },
};
</script>
