<template>
  <div>
    <b-modal
      id="order-payment-modal-1"
      ref="order-payment-modal-1"
      size="lg"
      centered
      hide-header
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      body-class="pb-4 text-black"
    >
      <CRow>
        <CCol class="col-7 py-2">
          <CImg src="/img/threespace/company/nicepay_logo.png" width="100" />
        </CCol>
        <CCol class="col-5 py-2">
          <h5 class="float-left font-weight-bold">3space Art</h5>
          <CLink @click="hide()">
            <CIcon name="cil-x" class="float-right" size="lg" />
          </CLink>
        </CCol>
      </CRow>

      <CRow class="mt-5">
        <CCol class="col-7 border-right">
          <table class="table table-clear text-dark">
            <colgroup>
              <col style="width: 70%" />
              <col style="width: 30%" />
            </colgroup>
            <tr>
              <td colspan="2">
                <b-form-checkbox
                  id="totalTerms"
                  name="totalTerms"
                  v-model="form.totalTerms"
                  @change="onChangeTotalTerms"
                >
                  <b class="font-lg">{{ $t("payment.vbank1.acceptAll") }}</b>
                </b-form-checkbox>
              </td>
            </tr>
            <tr>
              <td>
                <b-form-checkbox
                  id="paymentTerms"
                  name="paymentTerms"
                  v-model="form.paymentTerms"
                  @change="onChangeItems"
                >
                  {{ $t("payment.vbank1.acceptTerms") }}
                </b-form-checkbox>
              </td>
              <td style="text-align: right">
                <a
                  class="cursor-pointer"
                  @click="showTermsModal('nicepay_terms_payment')"
                >
                  <u>{{ $t("payment.vbank1.details") }} </u>
                  <font-awesome-icon icon="exclamation-circle" />
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <b-form-checkbox
                  id="privacyPolicyTerms"
                  name="privacyPolicyTerms"
                  v-model="form.privacyPolicyTerms"
                  @change="onChangeItems"
                >
                  {{ $t("payment.vbank1.acceptPrivacy") }}
                </b-form-checkbox>
              </td>
              <td style="text-align: right">
                <a
                  class="cursor-pointer"
                  @click="showTermsModal('nicepay_terms_privacy')"
                >
                  <u>{{ $t("payment.vbank1.details") }} </u>
                  <font-awesome-icon icon="exclamation-circle" />
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <b-form-checkbox
                  id="privacyThirdPartyTerms"
                  name="privacyThirdPartyTerms"
                  v-model="form.privacyThirdPartyTerms"
                  @change="onChangeItems"
                >
                  {{ $t("payment.vbank1.acceptThirdparty") }}
                </b-form-checkbox>
              </td>
              <td style="text-align: right">
                <a
                  class="cursor-pointer"
                  @click="showTermsModal('nicepay_terms_privacy_third_party')"
                >
                  <u>{{ $t("payment.vbank1.details") }} </u>
                  <font-awesome-icon icon="exclamation-circle" />
                </a>
              </td>
            </tr>
          </table>
        </CCol>
        <CCol class="col-5">
          <div>{{ $t("payment.artwork.title") }}</div>
          <p class="font-lg font-weight-bold">
            <template
              v-if="activeLanguage !== undefined && activeLanguage === 'ko'"
            >
              {{
                orderPaymentInfo.productName || orderPaymentInfo.productNameEn
              }}
            </template>
            <template v-else>
              {{
                orderPaymentInfo.productNameEn || orderPaymentInfo.productName
              }}
            </template>
          </p>
          <div>{{ $t("payment.artwork.price") }}</div>
          <p class="font-lg font-weight-bold">
            {{ orderPaymentInfo.productPrice | currency }} KRW
          </p>
        </CCol>
      </CRow>

      <CRow>
        <CCol class="col-7 border-right"><br /><br /><br /></CCol>
        <CCol class="col-5"></CCol>
      </CRow>
      <CRow>
        <CCol
          class="col-7 text-center border-right d-flex flex-column justify-content-end"
        >
          <div class="d-flex justify-content-center">
            <div class="" style="width: 100%">
              <Button
                :label="$t('button.next')"
                :onClick="next"
                :disabled="!form.totalTerms"
              />
            </div>
          </div>
          <!-- <b-button
            variant="primary"
            :disabled="!form.totalTerms"
            size="lg"
            squared
            class="align-self-end btn-block mt-auto"
            @click="next()"
          >
            <span class="text-white">{{ $t("button.next") }}</span>
          </b-button> -->
        </CCol>
        <CCol class="col-5 text-center">
          <div class="mx-3 border-dark p-2">
            <p>{{ $t("payment.artwork.total") }}</p>
            <p class="font-xl font-weight-bold">
              {{ orderPaymentInfo.productPrice | currency }} KRW
            </p>
          </div>
        </CCol>
      </CRow>
    </b-modal>

    <TermsPopup :terms-info="termsInfo" ref="termsModal" />
    <PaymentVBank2 :order-payment-info="orderPaymentInfo" ref="paymentVBank2" />
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const codeHelper = createNamespacedHelpers("code");

import TermsPopup from "@/views/threespace/TermsPopup";
import PaymentVBank2 from "@/views/threespace/payment/PaymentVBank2";
import Button from "@/views/threespace/components/common/Button.vue";

export default {
  name: "PaymentVBank1",
  components: {
    TermsPopup,
    PaymentVBank2,
    Button,
  },
  props: {
    orderPaymentInfo: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      form: this.getInitForm(),
    };
  },
  computed: {
    ...codeHelper.mapState(["termsInfo"]),
    activeLanguage() {
      return this.getActiveLanguage();
    },
  },
  mounted() {},
  methods: {
    ...codeHelper.mapActions(["getTerms"]),
    getActiveLanguage() {
      return localStorage.getItem("language") || "en";
    },
    onChangeTotalTerms() {
      this.form.paymentTerms = this.form.totalTerms;
      this.form.privacyPolicyTerms = this.form.totalTerms;
      this.form.privacyThirdPartyTerms = this.form.totalTerms;
    },
    onChangeItems() {
      // 전체 동의
      if (
        this.form.paymentTerms &&
        this.form.privacyPolicyTerms &&
        this.form.privacyThirdPartyTerms
      ) {
        this.form.totalTerms = true;
      } else {
        this.form.totalTerms = false;
      }
    },
    showTermsModal(_code) {
      const param = { code: _code };
      this.getTerms(param).then(() => {
        this.$refs["termsModal"].show(_code);
      });
    },
    next() {
      this.$refs["paymentVBank2"].show();
      this.$refs["order-payment-modal-1"].hide();
    },
    show() {
      this.form = this.getInitForm();
      this.$refs["order-payment-modal-1"].show();
    },
    hide() {
      if (confirm(this.$t("payment.payCancelMsg"))) {
        this.$refs["order-payment-modal-1"].hide();
      }
    },
    getInitForm() {
      return {
        totalTerms: false,
        paymentTerms: false,
        privacyPolicyTerms: false,
        privacyThirdPartyTerms: false,
      };
    },
  },
  filters: {
    currency: (value) => {
      if (!value) return "";
      return value.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    },
  },
};
</script>
