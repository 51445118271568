<template>
  <div id="vue-walletConnect"></div>
</template>

<script>
import { EthereumProvider } from "@walletconnect/ethereum-provider";
import {mapMutations} from "vuex";
import WalletConnectUtil from "@/mixins/WalletConnectUtil";

export default {
  data() {
    return {
      connected: false,
      provider: null,
      chainId: null,
      address: null,
      asset: null,
      // api: axios.create({
      //   baseURL: "https://ethereum-api.xyz",
      //   timeout: 30000, // 30 secs
      //   headers: {
      //     Accept: "application/json",
      //     "Content-Type": "application/json",
      //   },
      // }),
    };
  },
  mixins: [WalletConnectUtil],
  methods: {
    ...mapMutations("wallet", ["setWalletProvider"]),
    resetState() {
      this.$emit("onComplete", {
        chainId: null,
        address: null,
        network: null,
        asset: null,
        provider: null,
      });
    },
    checkAccounts(network) {
      const {chainId, accounts} = this.provider;
      const { peer } = this.provider.session;
      const address = accounts[0];
      const walletProvider = "wc-"+peer.metadata.name;

      const walletInfo = {
        chainId: chainId,
        addr: address,
        network: network,
        walletProvider: walletProvider
      }

      if (network.toUpperCase() === "POLYGON") {
        walletInfo.polygonAddr = address;
      }else {
        walletInfo.ethereumAddr = address;
      }

      this.$emit("wallet-info", {...walletInfo});
    },
    async killSession() {
      if (this.provider.connector) {
        await this.provider.connector.killSession();
      }
      this.resetState();
    },
    async onClick(network) {
      if (!this.provider) {
        await this.createSession(network);
      } else {
        this.checkAccounts(network);
      }
    },
    async createSession(network) {
      let chainId = process.env.VUE_APP_ETHEREUM_CHAIN_ID;
      if (network?.toUpperCase() === "POLYGON") {
        chainId = process.env.VUE_APP_POLYGON_CHAIN_ID;
      }
      if (!this.provider) {
        this.provider = await this.getWalletConnectProvider(chainId)

        this.provider.on("chainChanged", (chainId) => {
          console.log(chainId);
        });
        this.provider.on("connect", async () => {

          localStorage.setItem("pairingTopic",this.provider.session.pairingTopic);

          const {chainId} = this.provider;

          if(network.toUpperCase() === "POLYGON") {
            if(chainId.toString() !== process.env.VUE_APP_POLYGON_CHAIN_ID) {
              if(process.env.NODE_ENV === "production") {
                alert("폴리곤 네트워크를 선택해주세요")
                this.killSession()
                return;
              }else {
                alert("폴리곤 뭄바이 네트워크를 선택해주세요")
                this.killSession()
                return;
              }
            }
          } else if(network.toUpperCase() === "ETHEREUM") {
            if(chainId.toString() !== process.env.VUE_APP_ETHEREUM_CHAIN_ID) {
              if(process.env.NODE_ENV === "production") {
                alert("이더리움 네트워크를 선택해주세요")
                this.killSession()
                return;
              }else {
                alert("이더리움 Goerli 네트워크를 선택해주세요")
                this.killSession()
                return;
              }
            }
          }
          console.log("connect");
          this.checkAccounts(network)
        });

        this.provider.on("disconnect", (code, reason) => {
          console.log(code, reason);
        });
      }
      this.provider.connect();
    },
    async reconnect() {
      if (this.provider.connector.connected) {
        await this.killSession();
      }
      this.createSession();
    },
    // async apiGetGasPrices() {
    //   // Only for ethereum mainnet
    //   const response = await this.api.get(`/gas-prices`);
    //   const {result} = response.data;
    //   return result;
    // },
    // async apiGetAccountNonce(address, chainId) {
    //   const response = await this.api.get(`/account-nonce?address=${address}&chainId=${chainId}`);
    //   const {result} = response.data;
    //   return result;
    // },
    // async sendTransaction({to, quantity}) {
    //
    //   const {accounts, chainId} = this.provider.connector;
    //   const address = accounts[0];
    //
    //   if (!this.provider.connector) {
    //     return;
    //   }
    //
    //   // nonce
    //   const _nonce = await this.apiGetAccountNonce(address, chainId);
    //   const nonce = "0x" + _nonce.toString(16);
    //
    //   // gasPrice
    //   const gasPrices = await this.apiGetGasPrices();
    //   const _gasPrice = gasPrices.slow.price;
    //   const _hexPrice = _gasPrice * (10 ** 9)
    //   const gasPrice = "0x" + _hexPrice.toString(16);
    //
    //   // gasLimit
    //   const _gasLimit = 21000;
    //   const gasLimit = "0x" + _gasLimit.toString(16);
    //
    //   // value
    //   const _value = quantity * (10 ** 18)
    //   const value = "0x" + _value.toString(16)
    //
    //   // data
    //   const data = "0x";
    //
    //   const tx = {
    //     from: address,
    //     to,
    //     nonce,
    //     gasPrice,
    //     gasLimit,
    //     value,
    //     data,
    //   };
    //
    //   console.log(tx)
    //
    //   const sendResult = await this.provider.connector.sendTransaction(tx);
    //
    //   console.log("txHash : ", sendResult)
    //
    // },
  }
};
</script>

<style scoped>
  #vue-walletConnect {
    position: fixed;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
  }
</style>