import ApiService from "@/services/api.service";
import logger from "@/logger";

// action types
export const GET_ARTICLES = "getArticles";
export const GET_ARTICLE = "getArticle";

// mutation types
export const SET_ARTICLES = "setArticles";
export const SET_ARTICLE = "setArticle";
export const RESET_ARTICLE = "resetArticle";

const state = {
  errors: null,
  articleItemsContent: [],
  articleItems: {
    content: [],
    first: true,
    last: false,
    number: 0,
    numberOfElements: 0,
    size: 0,
    sort: {
      orders: [],
    },
    total: 0,
    totalElements: 0,
    totalPages: 1,
  },
  articleInfo: {
    top: false,
    title: "",
    titleEn: "",
    curator: "",
    location: "",
    content: "",
    contentEn: "",
  },
};

const getters = {};

const actions = {
  [GET_ARTICLES](context, payload) {
    if (payload.page == 0) {
      context.commit(RESET_ARTICLE);
    }
    return new Promise((resolve, reject) => {
      ApiService.get("/threespace/v1/articles", payload)
        .then(({ data }) => {
          context.commit(SET_ARTICLES, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [GET_ARTICLE](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("/threespace/v1/article/" + payload)
        .then(({ data }) => {
          context.commit(SET_ARTICLE, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
};

const mutations = {
  [SET_ARTICLES](state, articleList) {
    state.articleItems = articleList;
    state.articleItemsContent.push(...articleList.content);
  },
  [SET_ARTICLE](state, articleInfo) {
    state.articleInfo = articleInfo;
  },
  [RESET_ARTICLE](state) {
    state.articleItemsContent = [];
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
