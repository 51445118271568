<template>
  <div id="Terms">
    <div class="Terms">
      <div class="title-wrap">
        <div class="title">
          TERMS OF USE & PRIVACY POLICY
        </div>
        <div class="menu">
          <router-link to="/terms/terms_of_service" active-class="active">{{ $t("terms.name") }}</router-link>
          <router-link to="/terms/privacy_policy" active-class="active">{{ $t("policy.name") }}</router-link>
          <router-link to="/terms/refund_policy" active-class="active">{{ $t("refund.name") }}</router-link>
        </div>
      </div>
      <hr/>

      <template v-if="$route.path === '/terms/terms_of_service'">
        <div class="subtitle">
          {{ $t("terms.title") }}
        </div>


        <div class="chapter">{{ $t("terms.chapter1") }}</div>


        <div class="description-wrap">
          <div class="description-title">{{ $t("terms.article1") }}</div>
          <div class="description">{{ $t("terms.article1Title") }}</div>
        </div>

        <div class="description-wrap">
          <div class="description-title">{{ $t("terms.article2") }}</div>
          <template v-for="i in 8">
            <div :key="'article2' + i" class="description">{{ $t(`terms.article2Desc${i}`)}}</div>
          </template>
        </div>

        <div class="description-wrap">
          <div class="description-title">{{ $t("terms.article3") }}</div>
          <template v-for="i in 6">
            <div :key="'article3' + i" class="description">{{ $t(`terms.article3Desc${i}`)}}</div>
          </template>
        </div>


        <div class="chapter">{{ $t("terms.chapter2") }}</div>


        <div class="description-wrap">
          <div class="description-title">{{ $t("terms.article4") }}</div>
          <template v-for="i in 4">
            <div :key="'article4' + i" class="description">{{ $t(`terms.article4Desc${i}`)}}</div>
          </template>
        </div>

        <div class="description-wrap">
          <div class="description-title">{{ $t("terms.article5") }}</div>
          <template v-for="i in 3">
            <div :key="'article5' + i" class="description">{{ $t(`terms.article5Desc${i}`)}}</div>
          </template>
        </div>

        <div class="description-wrap">
          <div class="description-title">{{ $t("terms.article6") }}</div>
          <template v-for="i in 7">
            <div :key="'article6' + i" class="description">{{ $t(`terms.article6Desc${i}`)}}</div>
          </template>
        </div>

        <div class="description-wrap">
          <div class="description-title">{{ $t("terms.article7") }}</div>
          <template v-for="i in 2">
            <div :key="'article7' + i" class="description">{{ $t(`terms.article7Desc${i}`)}}</div>
          </template>
        </div>

        <div class="description-wrap">
          <div class="description-title">{{ $t("terms.article8") }}</div>
          <template v-for="i in 2">
            <div :key="'article8' + i" class="description">{{ $t(`terms.article8Desc${i}`)}}</div>
          </template>
        </div>

        <div class="description-wrap">
          <div class="description-title">{{ $t("terms.article9") }}</div>
          <template v-for="i in 2">
            <div :key="'article9' + i" class="description">{{ $t(`terms.article9Desc${i}`)}}</div>
          </template>
        </div>

        <div class="description-wrap">
          <div class="description-title">{{ $t("terms.article10") }}</div>
          <template v-for="i in 6">
            <div :key="'article10' + i" class="description">{{ $t(`terms.article10Desc${i}`)}}</div>
          </template>
        </div>

        <div class="description-wrap">
          <div class="description-title">{{ $t("terms.article11") }}</div>
          <template v-for="i in 2">
            <div :key="'article11' + i" class="description">{{ $t(`terms.article11Desc${i}`)}}</div>
          </template>
        </div>

        <div class="description-wrap">
          <div class="description-title">{{ $t("terms.article12") }}</div>
          <template v-for="i in 3">
            <div :key="'article12' + i" class="description">{{ $t(`terms.article12Desc${i}`)}}</div>
          </template>
        </div>

        <div class="description-wrap">
          <div class="description-title">{{ $t("terms.article13") }}</div>
          <template v-for="i in 15">
            <div :key="'article13' + i" class="description">{{ $t(`terms.article13Desc${i}`)}}</div>
          </template>
        </div>

        <div class="description-wrap">
          <div class="description-title">{{ $t("terms.article14") }}</div>
          <template v-for="i in 12">
            <div :key="'article14' + i" class="description">{{ $t(`terms.article14Desc${i}`)}}</div>
          </template>
        </div>

        <div class="description-wrap">
          <div class="description-title">{{ $t("terms.article15") }}</div>
          <template v-for="i in 6">
            <div :key="'article15' + i" class="description">{{ $t(`terms.article15Desc${i}`)}}</div>
          </template>
        </div>


        <div class="chapter">{{ $t("terms.chapter3") }}</div>


        <div class="description-wrap">
          <div class="description-title">{{ $t("terms.article16") }}</div>
          <div class="description">{{ $t("terms.article16Title") }}</div>
        </div>

        <div class="description-wrap">
          <div class="description-title">{{ $t("terms.article17") }}</div>
          <template v-for="i in 6">
            <div :key="'article17' + i" class="description">{{ $t(`terms.article17Desc${i}`)}}</div>
          </template>
        </div>

        <div class="description-wrap">
          <div class="description-title">{{ $t("terms.article18") }}</div>
          <template v-for="i in 2">
            <div :key="'article15' + i" class="description">{{ $t(`terms.article18Desc${i}`)}}</div>
          </template>
        </div>


        <div class="chapter last">{{ $t("terms.addendumTitle") }}</div>


        <div class="description">{{ $t("terms.addendum1") }}</div>
        <div class="description">{{ $t("terms.addendum2") }}</div>
      </template>

      <template v-else-if="$route.path === '/terms/privacy_policy'">
        <div class="subtitle">
          {{ $t("policy.title") }}
        </div>

        <div class="description first">
          {{ $t("policy.description") }}
        </div>


        <div class="chapter">{{ $t("policy.chapter1") }}</div>

        <div class="description margin">{{ $t("policy.chapter1mDesc") }}</div>
        <template v-for="i in 8">
          <div :key="'chapter1' + i" class="description">{{ $t(`policy.chapter1Desc${i}`)}}</div>
        </template>



        <div class="chapter">{{ $t("policy.chapter2") }}</div>

        <div class="description margin">{{ $t("policy.chapter2mDesc") }}</div>
        <template v-for="i in 7">
          <div :key="'chapter2' + i" class="description">{{ $t(`policy.chapter2Desc${i}`)}}</div>
        </template>

        <div class="chapter">
          {{ $t("policy.chapter3") }}
        </div>


        <div class="description margin">
          {{ $t("policy.chapter3Desc1") }}
          <div class="description">
            {{ $t("policy.chapter3Desc2") }}
          </div>
        </div>

        <div class="description margin">
          {{ $t("policy.chapter3Desc3") }}
          <div class="description">
            {{ $t("policy.chapter3Desc4") }}
          </div>
        </div>

        <div class="description margin">
          {{ $t("policy.chapter3Desc5") }}
          <div class="description">
            {{ $t("policy.chapter3Desc6") }}
          </div>
        </div>


        <div class="description margin">
          {{ $t("policy.chapter3Desc7") }}
          <div class="description">
            {{ $t("policy.chapter3Desc8") }}
          </div>
        </div>

        <div class="description margin">
          {{ $t("policy.chapter3Desc9") }}
          <div class="description">
            {{ $t("policy.chapter3Desc10") }}
          </div>
          <div class="description">
            {{ $t("policy.chapter3Desc11") }}
          </div>
          <div class="description">
            {{ $t("policy.chapter3Desc12") }}
          </div>
        </div>


        <div class="description margin">
          {{ $t("policy.chapter3Desc13") }}
          <div class="description">
            {{ $t("policy.chapter3Desc14") }}
          </div>
          <div class="description">
            {{ $t("policy.chapter3Desc15") }}
          </div>
        </div>


        <div class="chapter">
          {{ $t("policy.chapter4") }}
        </div>


        <div class="description bold">
          {{ $t("policy.chapter4Desc1") }}
        </div>
        <div class="description">
          {{ $t("policy.chapter4Desc2") }}
        </div>


        <div class="chapter">
          {{ $t("policy.chapter5") }}
        </div>


        <div class="description margin">
          {{ $t("policy.chapter5Desc1") }}
          <div class="description">
            {{ $t("policy.chapter5Desc2") }}
          </div>
          <div class="description">
            {{ $t("policy.chapter5Desc3") }}
          </div>
        </div>
        <div class="description">
          {{ $t("policy.chapter5Desc4") }}
        </div>


        <div class="chapter">
          {{ $t("policy.chapter6") }}
        </div>
        <template v-for="i in 3">
          <div :key="'chapter6' + i" class="description">{{ $t(`policy.chapter6Desc${i}`)}}</div>
        </template>

        <div class="chapter">
          {{ $t("policy.chapter7") }}
        </div>
        <template v-for="i in 4">
          <div :key="'chapter7' + i" class="description">{{ $t(`policy.chapter7Desc${i}`)}}</div>
        </template>


        <div class="chapter">
          {{ $t("policy.chapter8") }}
        </div>
        <div class="description margin">
          {{ $t("policy.chapter8Desc1") }}
          <div class="description">
            {{ $t("policy.chapter8Desc2") }}
          </div>
        </div>
        <div class="description bold">
          {{ $t("policy.chapter8Desc3") }}
        </div>
        <div class="description">
          {{ $t("policy.chapter8Desc4") }}
        </div>
        <div class="description">
          {{ $t("policy.chapter8Desc5") }}
        </div>


        <div class="chapter">
          {{ $t("policy.chapter9") }}
        </div>
        <div class="description">
          {{ $t("policy.chapter9Desc1") }}
        </div>


        <div class="chapter">
          {{ $t("policy.chapter10") }}
        </div>
        <div class="description margin">
          {{ $t("policy.chapter10Desc1") }}
        </div>
        <div class="description">
          {{ $t("policy.chapter10Desc2") }}
        </div>
        <div class="description">
          {{ $t("policy.chapter10Desc3") }}
        </div>
        <div class="description">
          {{ $t("policy.chapter10Desc4") }}
        </div>


        <div class="chapter">
          {{ $t("policy.chapter11") }}
        </div>
        <div class="description margin">
          {{ $t("policy.chapter11Desc1") }}
        </div>
        <div class="description">
          {{ $t("policy.chapter11Desc2") }}
        </div>
        <div class="description">
          {{ $t("policy.chapter11Desc3") }}
        </div>
        <div class="description">
          {{ $t("policy.chapter11Desc4") }}
        </div>
        <div class="description">
          {{ $t("policy.chapter11Desc5") }}
        </div>

      </template>

      <template v-else-if="$route.path === '/terms/refund_policy'">
        <div class="subtitle">
          {{ $t("refund.title") }}
        </div>

        <div class="chapter"></div>

        <div class="description-wrap">
          <template v-for="i in 1">
            <div :key="'article1' + i" class="description">{{ $t(`refund.article1Desc${i}`)}}</div>
          </template>
        </div>

        <div class="description-wrap">
          <template v-for="i in 3">
            <div :key="'article2' + i" class="description">{{ $t(`refund.article2Desc${i}`)}}</div>
          </template>
        </div>

        <div class="description-wrap">
          <template v-for="i in 2">
            <div :key="'article3' + i" class="description">{{ $t(`refund.article3Desc${i}`)}}</div>
          </template>
        </div>


        <div class="description-wrap">
          <template v-for="i in 2">
            <div :key="'article4' + i" class="description">{{ $t(`refund.article4Desc${i}`)}}</div>
          </template>
        </div>


        <template v-for="i in 6">
          <div :key="'chapter2' + i" class="description">{{ $t(`refund.chapter2Desc${i}`)}}</div>
        </template>
      </template>
      <template v-else>

      </template>
    </div>
  </div>
</template>

<script>

export default {
  data: function() {
    return {}
  },
  props: {},
  mounted() {},
  methods: {}
};
</script>
