<template>
  <article class="mypage">
    <!-- profile_image -->
    <template
      v-if="
        _memberInfo.profileImageName !== undefined &&
        _memberInfo.profileImageName !== null
      "
    >
      <article class="profile-image">
        <img :src="_memberInfo.profileImageUrl" alt="profile-image" />
      </article>
    </template>
    <template v-else>
      <article class="profile-image__default"></article>
    </template>
    <article class="artist">
      <section class="artist__name" v-if="isArtist">
        <p class="name">
          <template
            v-if="activeLanguage !== undefined && activeLanguage === 'ko'"
          >
            {{ artistInfo.artistName || artistInfo.artistNameEn }}
          </template>
          <template v-else>
            {{ artistInfo.artistNameEn || artistInfo.artistName }}
          </template>
        </p>

        <p class="nickname">@ {{ _memberInfo.nickname }}</p>
      </section>

      <section class="artist__name" v-else>
        <p class="name">@ {{ _memberInfo.nickname }}</p>
      </section>
      <section class="follower">
        <div class="item">
          <p class="type">FOLLOWERS</p>
          <p class="value" @click.stop="onClickFollowers(_memberInfo.idx)">
            {{ _memberInfo.followers | currency }}
          </p>
        </div>
        <div class="item">
          <p class="type">FOLLOWINGS</p>
          <p class="value" @click.stop="onClickFollowing(_memberInfo.idx)">
            {{ _memberInfo.following | currency }}
          </p>
        </div>
      </section>
    </article>
    <div class="follower-btn pc">
      <Button
        :label="
          _memberInfo.isFollow !== undefined && _memberInfo.isFollow
            ? 'Unfollow'
            : 'Follow'
        "
        :buttonClass="{
          'btn-primary': true,
          'btn-outline-primary': _memberInfo.isFollow,
        }"
        :onClick="onClickMemberFollow"
      />
      <router-link
        class="update-btn"
        to="/mypage/update"
        v-c-tooltip="{
          content: $t('mypage.editMyInformation'),
          placement: 'right',
        }"
        v-if="!showCollection"
      >
        <img src="/img/threespace/icon/new_setting.svg" />
      </router-link>
    </div>
    <article class="introduce">
      <article class="introduce__description">
        <div v-show="isArtist" ref="artistIntroduce">
          <template
            v-if="activeLanguage !== undefined && activeLanguage === 'ko'"
          >
            <p
              v-html="
                artistInfo.introduce !== undefined &&
                artistInfo.introduce !== ''
                  ? replaceNewLine(artistInfo.introduce)
                  : replaceNewLine(artistInfo.introduceEn)
              "
            ></p>
          </template>
          <template v-else>
            <p
              v-html="
                artistInfo.introduceEn !== undefined &&
                artistInfo.introduceEn !== ''
                  ? replaceNewLine(artistInfo.introduceEn)
                  : replaceNewLine(artistInfo.introduce)
              "
            ></p>
          </template>
        </div>
        <div v-show="!isArtist">
          <div
            v-if="
              _memberInfo.introduce !== undefined &&
              _memberInfo.introduce !== null &&
              _memberInfo.introduce !== ''
            "
            v-html="replaceNewLine(_memberInfo.introduce)"
          ></div>
          <p v-else>
            {{
              showCollection
                ? $t("mypage.emptyBioMessage")
                : $t("mypage.bioMessage")
            }}
          </p>
        </div>
        <div v-if="isArtist">
          <template
            v-if="activeLanguage !== undefined && activeLanguage === 'ko'"
          >
            <p
              v-html="
                artistInfo.career !== undefined && artistInfo.career !== ''
                  ? replaceNewLine(artistInfo.career)
                  : replaceNewLine(artistInfo.careerEn)
              "
            ></p>
          </template>
          <template v-else>
            <p
              v-html="
                artistInfo.careerEn !== undefined && artistInfo.careerEn !== ''
                  ? replaceNewLine(artistInfo.careerEn)
                  : replaceNewLine(artistInfo.career)
              "
            ></p>
          </template>
        </div>
      </article>

      <div class="follower-btn--mobile">
        <Button
          :label="
            _memberInfo.isFollow !== undefined && _memberInfo.isFollow
              ? 'Unfollow'
              : 'Follow'
          "
          :buttonClass="{
            'btn-primary': true,
            'btn-outline-primary': _memberInfo.isFollow,
          }"
          :onClick="onClickMemberFollow"
        />
      </div>
      <section class="introduce__href">
        <section class="sns_link">
          <button
            :disabled="
              _memberInfo.homepage === undefined || _memberInfo.homepage === ''
            "
            v-c-tooltip="{ content: 'Website', placement: 'top' }"
            variant="link"
            @click="onClickSnsBtn(_memberInfo.homepage, 'homepage')"
          >
            <img src="/img/threespace/mypage/website.svg" />
          </button>
          <button
            :disabled="
              _memberInfo.instagram === undefined ||
              _memberInfo.instagram === ''
            "
            v-c-tooltip="{ content: 'Instagram', placement: 'top' }"
            variant="link"
            class="p-0"
            @click="onClickSnsBtn(_memberInfo.instagram, 'instagram')"
          >
            <img src="/img/threespace/mypage/instagram.svg" />
          </button>
          <button
            :disabled="
              _memberInfo.twitter === undefined || _memberInfo.twitter === ''
            "
            v-c-tooltip="{ content: 'Twitter', placement: 'top' }"
            variant="link"
            class="p-0"
            @click="onClickSnsBtn(_memberInfo.twitter, 'twitter')"
          >
            <img src="/img/threespace/mypage/x.svg" />
          </button>

          <router-link
            class="update-btn--mobile"
            to="/mypage/update"
            v-c-tooltip="{
              content: $t('mypage.editMyInformation'),
              placement: 'right',
            }"
            v-if="!showCollection"
          >
            <img src="/img/threespace/icon/new_setting.svg" />
          </router-link>
        </section>

        <section class="location">
          <img src="/img/threespace/mypage/location.svg" />
          <p>
            <template v-if="!showCollection">
              {{
                _memberInfo.location !== undefined &&
                _memberInfo.location !== null &&
                _memberInfo.location !== ""
                  ? _memberInfo.location
                  : "Where you at?"
              }}
            </template>
            <template v-else>
              {{
                _memberInfo.location !== undefined &&
                _memberInfo.location !== null &&
                _memberInfo.location !== ""
                  ? _memberInfo.location
                  : "Empty location"
              }}
            </template>
          </p>
        </section>
      </section>
    </article>

    <FollowModal ref="followModal" />
  </article>
</template>

<script>
import { mapState, mapActions } from "vuex";

import FollowModal from "@/views/threespace/components/follow/FollowModal";
import Button from "@/views/threespace/components/common/Button";

export default {
  name: "MyPageDetailInfo",
  components: {
    FollowModal,
    Button,
  },
  props: {
    isArtist: {
      type: Boolean,
      default: false,
    },
    artistInfo: {
      type: Object,
      default: () => {},
    },
    showCollection: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      copiedEthAddr: false,
      copiedKlayAddr: false,
      tabs: [
        {
          index: 0,
          name: this.$t("mypage.collection"),
        },
        {
          index: 1,
          name: this.$t("mypage.payment"),
        },
      ],
      introduceMore: false,
      isOverText: false,
    };
  },
  updated() {
    this.isOverText = this.$refs.artistIntroduce.clientHeight > 42;
  },
  computed: {
    ...mapState("profile", ["memberInfo", "memberSimpleInfo"]),
    ...mapState("auth", ["me"]),
    ...mapState(["wallet"]),
    _memberInfo() {
      if (this.showCollection !== undefined && this.showCollection) {
        return this.memberSimpleInfo;
      } else {
        return this.memberInfo;
      }
    },
    activeLanguage() {
      return this.getActiveLanguage();
    },
    isMobile() {
      return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    },
  },
  methods: {
    ...mapActions("profile", ["updateMemberFollow"]),
    onCopyEthAddr() {
      this.copiedEthAddr = true;
      setTimeout(() => {
        this.copiedEthAddr = false;
      }, 3000);
    },
    onCopyKlayAddr() {
      this.copiedKlayAddr = true;
      setTimeout(() => {
        this.copiedKlayAddr = false;
      }, 2000);
    },
    getActiveLanguage() {
      return localStorage.getItem("language") || "en";
    },
    onClickSnsBtn(snsLink, snsType) {
      if (snsType === "instagram") {
        window.open("https://www.instagram.com/" + snsLink, "_blank");
      } else if (snsType === "twitter") {
        window.open("https://twitter.com/" + snsLink, "_blank");
      } else {
        window.open(snsLink, "_blank");
      }
    },
    onClickMemberFollow() {
      // Follow/Unfollow
      const follow =
        this._memberInfo.isFollow !== undefined &&
        this._memberInfo.isFollow === true
          ? false
          : true;
      const param = { idxMember: this._memberInfo.idx, follow: follow };
      this.updateMemberFollow(param)
        .then((data) => {
          if (
            Object.keys(data).length !== 0 &&
            JSON.stringify(data) !== JSON.stringify({})
          ) {
            this._memberInfo.isFollow = data.isFollow;
            this._memberInfo.followers = data.followers;
            this._memberInfo.following = data.following;
          }
        })
        .catch((error) => {
          this.$log.error(error);
          alert(this.$t("artist.failTryAgain"));
        });
    },
    onClickFollowers(_idxMember) {
      this.$refs["followModal"].show(0, _idxMember);
    },
    onClickFollowing(_idxMember) {
      this.$refs["followModal"].show(1, _idxMember);
    },
    replaceNewLine(str) {
      if (str !== undefined && str !== null && str !== "") {
        return str.replace(/(?:\r\n|\r|\n)/g, "<br />");
      } else {
        return "";
      }
    },
  },
  filters: {
    currency: (value) => {
      if (!value) return 0;
      return value.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    },
  },
};
</script>
