<template>
  <b-modal
    v-model="commonAlertModalInfo.show"
    id="commonAlertModal"
    ref="commonAlertModal"
    hide-header
    hide-footer
    centered
  >
    <div class="common_alert_modal">
      <div class="d-flex justify-content-end">
        <img
          class="cursor-pointer"
          src="/img/threespace/icon/close.png"
          alt="close"
          @click="$refs.commonAlertModal.hide()"
        />
      </div>
      <p
        class="msg_box"
        style="font-size: 20px; font-weight: bold"
        v-html="commonAlertModalInfo.msgHtml"
        :class="'font_' + commonAlertModalInfo.fontSize"
      ></p>
      <div class="d-flex justify-content-center mb-4">
        <div class="mt-3" style="width: 300px; max-width: 300px">
          <Button :label="$t('button.confirm')" :onClick="onClickOkBtn" />
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Button from "@/views/threespace/components/common/Button.vue";
export default {
  name: "CommonAlertModal",
  components: {
    Button,
  },
  mounted() {},
  data: function () {
    return {};
  },
  computed: {
    commonAlertModalInfo() {
      return this.$store.state.commonAlertModalInfo;
    },
  },
  methods: {
    onClickOkBtn(e) {
      e.preventDefault();
      this.$store.commit("setCommonAlertModalInfo", {
        msgHtml: "",
        show: false,
      });
    },
  },
};
</script>
