<template>
  <CCard>
    <CCardBody>
      <CRow>
        <CCol class="mb-3"><h5>주문 현황</h5></CCol>
      </CRow>
      <CRow>
        <CCol>
          <b-table-simple>
            <colgroup>
              <col style="width:12%;" />
              <col style="width:38%;" />
              <col style="width:12%;" />
              <col style="width:38%;" />
            </colgroup>
            <b-tbody>
              <b-tr>
                <b-th class="bg-light text-dark">검색</b-th>
                <b-td colspan="3">
                  <CSelect
                    class="col-2 float-left px-0 mb-0"
                    :options="searchOptions"
                    :value.sync="searchSelected"
                    @change="onChangeSearchOption"
                  />
                  <CInput
                    class="col-6 float-left mb-0"
                    placeholder="검색어를 입력해주세요."
                    v-model="externalParams.searchKeyword"
                    @input="onChangeSearchKeyword"
                    @keypress.enter.prevent="onClickSearch"
                  />
                  <CButton color="dark" class="text-white" @click="onClickSearch">
                    <CIcon name="cil-search" class="mr-2" />검색
                  </CButton>
                  <CButton
                    variant="outline"
                    color="secondary"
                    class="ml-2"
                    @click="onClickReset"
                  >
                    <CIcon name="cil-filter-x" />
                  </CButton>
                </b-td>
              </b-tr>
              <!--
              <b-tr>
                <b-th>&#183; 결제구분</b-th>
                <b-td colspan="3">
                  <b-form-group class="mb-0">
                    <b-form inline>
                      <b-form-checkbox
                        v-model="selectedAllPaytype"
                        @change="toggleAllPaytype"
                        class="mr-3"
                      >
                        전체
                      </b-form-checkbox>
                      <b-form-checkbox-group
                        v-model="externalParams.keywordPayType"
                        :options="searchPaytypeOptions"
                      ></b-form-checkbox-group>
                    </b-form>
                  </b-form-group>
                </b-td>
              </b-tr>
              -->
              <b-tr class="border-bottom">
                <b-th>&#183; 주문상태</b-th>
                <b-td colspan="3">
                  <b-form-group class="mb-0">
                    <b-form inline>
                      <b-form-checkbox
                        v-model="selectedAllStatus"
                        @change="toggleAllStatus"
                        class="mr-3"
                      >
                        전체
                      </b-form-checkbox>
                      <b-form-checkbox-group
                        v-model="externalParams.keywordStatus"
                        :options="searchStatusOptions"
                      ></b-form-checkbox-group>
                    </b-form>
                  </b-form-group>
                </b-td>
              </b-tr>
              <!--
              <b-tr class="border-bottom">
                <b-th>&#183; NFT 전송</b-th>
                <b-td>
                  <b-form-group class="mb-0">
                    <b-form inline>
                      <b-form-checkbox
                        v-model="selectedAllNftTransferStatus"
                        @change="toggleAllNftTransferStatus"
                        class="mr-3"
                      >
                        전체
                      </b-form-checkbox>
                      <b-form-checkbox-group
                        v-model="externalParams.keywordNftTransferStatus"
                        :options="searchNftTransferStatusOptions"
                      ></b-form-checkbox-group>
                    </b-form>
                  </b-form-group>
                </b-td>
                <b-th>&#183; 코인정산</b-th>
                <b-td>
                  <b-form-group class="mb-0">
                    <b-form inline>
                      <b-form-checkbox
                        v-model="selectedAllSettleCoinStatus"
                        @change="toggleAllSettleCoinStatus"
                        class="mr-3"
                      >
                        전체
                      </b-form-checkbox>
                      <b-form-checkbox-group
                        v-model="externalParams.keywordSettleCoinStatus"
                        :options="searchSettleCoinStatusOptions"
                      ></b-form-checkbox-group>
                    </b-form>
                  </b-form-group>
                </b-td>
              </b-tr>
              <b-tr>
                <td colspan="2" class="border-bottom">
                  <span class="float-left mt-2 mr-3">
                    <strong class="mr-2">&#183; 등록일시</strong>
                  </span>
                  <CInputRadioGroup
                    inline
                    class="float-left mt-2"
                    :options="searchDateOptions"
                    :checked.sync="searchDateSelected"
                    @change="onChangeSearchDate"
                  />
                  <CInput
                    type="date"
                    class="float-left mr-2 mb-0"
                    v-model="externalParams.keywordUpdatedAtBgn"
                  />
                  <CInput
                    type="date"
                    class="float-left mb-0"
                    v-model="externalParams.keywordUpdatedAtEnd"
                  />
                </td>
              </tr>
              -->
            </b-tbody>
          </b-table-simple>
        </CCol>
      </CRow>
      <CRow>
        <CCol class="col-auto mr-auto align-self-center">
          총&nbsp;<strong>{{ orderTableItems.totalElements }}</strong>건&nbsp;({{ currentPage }}&nbsp;/&nbsp;{{ orderTableItems.totalPages }}&nbsp;페이지)
        </CCol>
        <CCol class="col-auto">
          <CSelect
            class="float-left mr-2 mb-2"
            :options="pageOptions"
            :value.sync="perPage"
            @change="onPaging"
          />
        </CCol>
      </CRow>
      <b-table
        id="orderTable"
        thead-class="text-center"
        :busy="isBusy"
        :items="orderTableItems.content"
        :fields="orderTableFields"
        :items-per-page="perPage"
        show-empty
        empty-text="조회된 내역이 없습니다."
      >
        <!-- NO -->
        <template #cell(index)="data">
          {{ data.index + 1 + (currentPage - 1) * perPage }}
        </template>

        <!-- 주문번호 -->
        <template #cell(moid)="data">
          <a
            class="text-info"
            style="cursor: pointer;"
            @click="onClickOrderName(data.item.idx)"
          >
            {{ data.item.moid }}
          </a>
        </template>

        <!-- 작품명 -->
        <template #cell(productName)="data">
          {{ data.item.product.productName }}
        </template>

        <!-- 금액 -->
        <template #cell(amt)="data">
          <template v-if="data.item.payType === 'ETH' || data.item.payType === 'KLAY'">
            {{ data.item.amountCoin | currencyCoin }}{{ data.item.currency }}<span class="text-gray font-xs">({{ data.value | currency }}&#8361;)</span>
          </template>
          <template v-else>
            {{ data.value | currency }}&#8361;<span class="text-gray font-xs" v-if="data.item.status === 'USER_PAY_SUCCESS'">({{ data.item.product.soldCoinPrice | currencyCoin }}{{ data.item.product.soldCoinCurrency }})</span>
          </template>
        </template>

        <!-- 작가번호 -->
        <template #cell(idxArtist)="data">
          {{ data.item.product.idxArtist }}
        </template>

        <!-- 상태 -->
        <template #cell(status)="data">
          <span :class="replaceKoOrderStatus(data.item.payType, data.item.status).class">
            {{ replaceKoOrderStatus(data.item.payType, data.item.status).text }}
          </span>
        </template>
        <template #cell(nftTransferStatus)="data">
          <span :class="replaceKoDeliveryStatus(data.item.nftTransferStatus).class">
            {{ replaceKoDeliveryStatus(data.item.nftTransferStatus).text }}
          </span>
        </template>
        <template #cell(settleCoinStatus)="data">
          <template v-if="data.item.payType === 'ETH' || data.item.payType === 'KLAY' || data.item.payType === 'SSX'">
            <span :class="replaceKoSettleStatus(data.item.status, data.item.settleCoinStatus).class">
              {{ replaceKoSettleStatus(data.item.status, data.item.settleCoinStatus).text }}
            </span>
          </template>
        </template>

        <!-- Actions -->
        <template v-slot:cell(actions)="data">
          <CButton
            variant="outline"
            color="dark"
            size="sm"
            class="mr-1"
            @click="onClickModify(data.item.idx)"
          >
            <CIcon name="cil-pencil" />
          </CButton>
        </template>
      </b-table>
      <b-pagination
        aria-controls="orderTable"
        align="center"
        hide-ellipsis
        limit="10"
        v-model="currentPage"
        :total-rows="orderTableItems.totalElements"
        :per-page="perPage"
        :current-page="currentPage"
        v-on:input="onPaging"
      ></b-pagination>
    </CCardBody>
  </CCard>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const orderHelper = createNamespacedHelpers("order");

const today = new Date();

export default {
  name: "OrderList",
  components: {},
  data: function() {
    return {
      isBusy: false,
      perPage: 10,
      pageOptions: [
        { value: 10, label: "10 개씩 보기" },
        { value: 20, label: "20 개씩 보기" },
        { value: 30, label: "30 개씩 보기" },
        { value: 50, label: "50 개씩 보기" }
      ],
      currentPage: 1,
      sort: [
        { id: "idx", desc: "desc" },
        { id: "createdAt", desc: "desc" }
      ],
      sortBy: "",
      sortDesc: false,
      // 검색
      searchSelected: "",
      searchOptions: [
        { value: "", label: "전체" },
        { value: "keywordMoid", label: "주문번호" },
        { value: "keywordGoodsName", label: "작품명" },
        { value: "keywordIdxArtist", label: "작가번호" },
        { value: "keywordIdxMember", label: "회원번호" }
      ],
      selectedAllPaytype: true,
      searchPaytypeOptions: [
        { value: "CARD", text: "간편결제(CARD)" },
        { value: "VBANK", text: "가상계좌(VBANK)" },
        { value: "TRANSFER", text: "계좌이체(TRANSFER)" },
        { value: "ETH", text: "이더(ETH)" },
        { value: "KLAY", text: "클레이(KLAY)" },
        { value: "SSX", text: "썸씽토큰(SSX)" }
      ],
      selectedAllStatus: true,
      searchStatusOptions: [
        { value: "USER_ORDER", text: "사용자 주문완료(입금전)" },
        { value: "USER_PAY_SUCCESS", text: "사용자 결제(입금)완료" },
        { value: "USER_PAY_FAIL", text: "사용자 결제(입금)실패" },
        { value: "USER_ORDER_RESERVE", text: "사용자 주문중" },
        { value: "USER_ORDER_CANCEL", text: "결제전 주문취소" },
        { value: "AUTO_CANCEL", text: "미결제(입금) 자동취소" }
      ],
      selectedAllNftTransferStatus: true,
      searchNftTransferStatusOptions: [
        { value: "TX_STATUS_S", text: "전송대기" },
        { value: "TX_STATUS_P", text: "전송중" },
        { value: "TX_STATUS_P_S", text: "전송완료" },
        { value: "TX_STATUS_P_F", text: "전송실패" }
      ],
      selectedAllSettleCoinStatus: true,
      searchSettleCoinStatusOptions: [
        { value: "SETTLE_S", text: "정산대기" },
        { value: "SETTLE_P", text: "정산중" },
        { value: "SETTLE_P_S", text: "정산완료" },
        { value: "SETTLE_P_F", text: "정산실패" }
      ],
      searchDateSelected: "",
      searchDateOptions: [
        { value: "", label: "전체" },
        { value: "LAST_1_WEEK", label: "최근 1주일" },
        { value: "LAST_1_MONTH", label: "최근 1개월" },
        { value: "CUSTOM", label: "기간지정" }
      ],
      externalParams: this.getInitExternalParams()
    };
  },
  computed: {
    ...orderHelper.mapState(["orderTableItems", "orderTableFields"])
  },
  mounted() {
    this.requestOrderItems();
  },
  methods: {
    ...orderHelper.mapActions(["getOrders"]),
    onPaging() {
      this.requestOrderItems();
    },
    onChangeSearchOption() {
      this.setSearchConditions(this.externalParams.searchKeyword);
    },
    onChangeSearchKeyword(keyword) {
      this.setSearchConditions(keyword);
    },
    onChangeSearchDate(value) {
      // TODO 동작 안함
      this.$log.info("onChangeSearchDate() ", value);
      if (this.searchDateSelected === "") {
        this.externalParams.keywordUpdatedAtBgn = "";
        this.externalParams.keywordUpdatedAtEnd = "";
      } else if (this.searchDateSelected === "LAST_1_WEEK") {
        this.externalParams.keywordUpdatedAtBgn = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDay()
        ).toLocaleDateString();
      } else if (this.searchDateSelected === "LAST_1_MONTH") {
      }
    },
    setSearchConditions(keyword) {
      this.externalParams = this.getInitExternalParams();
      if (this.searchSelected === "") {
        this.externalParams.searchKeyword = keyword;
      } else if (this.searchSelected === "keywordMoid") {
        this.externalParams.keywordMoid = keyword;
      } else if (this.searchSelected === "keywordGoodsName") {
        this.externalParams.keywordGoodsName = keyword;
      } else if (this.searchSelected === "keywordIdxArtist") {
        this.externalParams.keywordIdxArtist = keyword;
      } else if (this.searchSelected === "keywordIdxMember") {
        this.externalParams.keywordIdxMember = keyword;
      }
    },
    resetSearchConditions() {
      this.searchSelected = "";
      this.externalParams = this.getInitExternalParams();
    },
    getInitExternalParams() {
      return {
        searchKeyword: "",
        keywordMoid: "",
        keywordGoodsName: "",
        keywordIdxArtist: "",
        keywordIdxMember: "",
        keywordPayType: [],
        keywordStatus: [],
        keywordNftTransferStatus: [],
        keywordSettleCoinStatus: []
      };
    },
    onClickSearch() {
      this.requestOrderItems();
    },
    onClickReset() {
      this.resetSearchConditions();
      this.requestOrderItems();
    },
    requestOrderItems() {
      this.$log.info("requestOrderItems() this.externalParams=", this.externalParams);
      this.isBusy = true;
      this.getOrders(
        this.getPagingParams(
          {
            page: this.currentPage - 1,
            pageSize: this.perPage,
            sorted: this.sort,
            filtered: []
          },
          this.externalParams
        )
      )
        .then(data => {
          this.isBusy = false;
        })
        .catch(error => {
          this.isBusy = false;
          this.$log.error(error);
        });
    },
    onClickOrderName(idx) {
      this.$router.push("/management/order-detail/" + idx);
    },
    onClickModify(idx) {
      this.$router.push("/management/order-detail/" + idx);
    },
    getPagingParams({ page, pageSize, sorted, filtered }, externalParams) {
      const params = {
        page,
        size: pageSize,
        ...externalParams
      };

      // sorting parameter
      let sorts = [];
      for (let i = 0, length = sorted.length; i < length; i++) {
        const sort = sorted[i];
        sorts.push(`${sort.id},${sort.desc ? "desc" : "asc"}`);
      }
      params["sort"] = sorts;

      // filtering parameter
      for (let i = 0, length = filtered.length; i < length; i++) {
        const filter = filtered[i];
        params[filter.id] = filter.value;
      }

      return params;
    },
    toggleAllPaytype(checked) {
      this.externalParams.keywordPayType = checked ? this.searchPaytypeOptions.map(val => val.value) : [];
    },
    toggleAllStatus(checked) {
      this.externalParams.keywordStatus = checked ? this.searchStatusOptions.map(val => val.value) : [];
    },
    toggleAllNftTransferStatus(checked) {
      this.externalParams.keywordNftTransferStatus = checked ? this.searchNftTransferStatusOptions.map(val => val.value) : [];
    },
    toggleAllSettleCoinStatus(checked) {
      this.externalParams.keywordSettleCoinStatus = checked ? this.searchSettleCoinStatusOptions.map(val => val.value) : [];
    },
    replaceKoOrderStatus(payType, status) {
      let obj = {};
      // 가상계좌 결제
      if (status === 'USER_ORDER') {
        obj = { class: "text-success", text: "사용자 주문완료" };
      } else if (status === 'USER_PAY_SUCCESS') {
        if (payType === 'VBANK') {
          obj = {class: "text-success", text: "사용자 입금완료"};
        } else {
          obj = {class: "text-success", text: "사용자 결제완료"};
        }
      } else if (status === 'USER_PAY_FAIL') {
        if (payType === 'VBANK') {
          obj = { class: "text-danger", text: "사용자 입금실패" };
        } else {
          obj = { class: "text-danger", text: "사용자 결제실패" };
        }
      } else if (status === 'AUTO_CANCEL') {
        if (payType === 'VBANK') {
          obj = { class: "text-info", text: "미입금 자동취소" };
        } else {
          obj = { class: "text-info", text: "미결제 자동취소" };
        }
      } else if (status === 'USER_CANCEL') {
        obj = { class: "text-black", text: "취소/환불 요청" };
      } else if (status === 'MANAGER_CANCEL_SUCCESS') {
        obj = { class: "text-black", text: "취소/환불 성공" };
      } else if (status === 'MANAGER_CANCEL_FAIL') {
        obj = { class: "text-danger", text: "취소/환불 실패" };
      // 클레이 결제
      } else if (status === 'USER_ORDER_RESERVE') {
        obj = { class: "text-info", text: "사용자 주문중" };
      } else if (status === 'USER_ORDER_CANCEL') {
        obj = { class: "text-info", text: "결제전 주문취소" };
      } else {
        obj = { class: "text-black", text: status };
      }
      return obj;
    },
    replaceKoDeliveryStatus(data) {
      let obj = {};
      switch (data) {
        case "TX_STATUS_S":
          obj = { class: "text-success", text: "전송대기" };
          break;
        case "TX_STATUS_P":
          obj = { class: "text-info", text: "전송중" };
          break;
        case "TX_STATUS_P_S":
          obj = { class: "text-black", text: "전송완료" };
          break;
        case "TX_STATUS_P_F":
          obj = { class: "text-danger", text: "전송실패" };
          break;
        default:
          obj = { class: "text-black", text: data };
      }
      return obj;
    },
    replaceKoSettleStatus(status, settleStatus) {
      let obj = {};
      if (settleStatus === 'SETTLE_S') {
        obj = { class: "text-info", text: "정산대기" };
      } else if (settleStatus === 'SETTLE_S_M') {
        obj = { class: "text-info", text: "정산대기(수동)" };
      } else if (settleStatus === 'SETTLE_P') {
        obj = { class: "text-info", text: "정산중" };
      } else if (settleStatus === 'SETTLE_P_S') {
        obj = { class: "text-black", text: "정산완료" };
      } else if (settleStatus === 'SETTLE_P_S_M') {
        obj = { class: "text-black", text: "정산완료(수동)" };
      } else if (settleStatus === 'SETTLE_P_F') {
        obj = { class: "text-danger", text: "정산실패" };
      } else {
        if (status === 'USER_PAY_SUCCESS') {
          obj = { class: "text-danger", text: "확인필요" };
        } else {
          obj = { class: "text-black", text: settleStatus };
        }
      }
      return obj;
    }
  },
  filters: {
    currency: value => {
      if (!value) return "";
      return parseInt(value)
        .toFixed(0)
        .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    },
    currencyCoin: value => {
      if (!value) return "";
      return value.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    }
  },
  watch: {
    externalParams: {
      deep: true,
      handler(newVal) {
        // 결제구분
        if (newVal.keywordPayType.length === 0) {
          this.selectedAllPaytype = false;
        } else if (newVal.keywordPayType.length === this.searchPaytypeOptions.length) {
          this.selectedAllPaytype = true;
        } else {
          this.selectedAllPaytype = false;
        }
        // 주문상태
        if (newVal.keywordStatus.length === 0) {
          this.selectedAllStatus = false;
        } else if (newVal.keywordStatus.length === this.searchStatusOptions.length) {
          this.selectedAllStatus = true;
        } else {
          this.selectedAllStatus = false;
        }
        // NFT전송
        if (newVal.keywordNftTransferStatus.length === 0) {
          this.selectedAllNftTransferStatus = false;
        } else if (newVal.keywordNftTransferStatus.length === this.searchNftTransferStatusOptions.length) {
          this.selectedAllNftTransferStatus = true;
        } else {
          this.selectedAllNftTransferStatus = false;
        }
        // 코인정산
        if (newVal.keywordSettleCoinStatus.length === 0) {
          this.selectedAllSettleCoinStatus = false;
        } else if (newVal.keywordSettleCoinStatus.length === this.searchSettleCoinStatusOptions.length) {
          this.selectedAllSettleCoinStatus = true;
        } else {
          this.selectedAllSettleCoinStatus = false;
        }
      }
    }
  }
};
</script>
