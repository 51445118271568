<template>
  <div>
    <CCard>
      <CCardBody>
        <CRow>
          <CCol class="mb-3"><h5>아트풀 라운드</h5></CCol>
        </CRow>
        <div>
          <b-tabs
            active-nav-item-class="bg-info text-light font-weight-bold"
            content-class="mt-3"
            v-model="tabIndex"
            disabled
            @input="onClickTab"
          >
            <b-tab title="Ethereum">
              <CForm>
                <CRow>
                  <CCol col="10">
                    <b-table-simple>
                      <colgroup>
                        <col style="width:15%;" />
                        <col style="width:85%;" />
                      </colgroup>
                      <b-tbody>
                        <b-tr>
                          <b-th variant="secondary">아트풀 번호</b-th>
                          <b-td>
                            {{ artpoolInfo.idx || "(정보없음)" }}
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-th variant="secondary">
                            아트풀 제목(영문) <span class="text-danger font-lg">*</span>
                          </b-th>
                          <b-td>
                            <div class="row align-items-center">
                              <div class="col-8">
                                <CInput
                                  class="mb-0"
                                  disabled
                                  v-model="artpoolInfo.titleEn"
                                  :value.sync="$v.artpoolInfo.titleEn.$model"
                                  :isValid="checkIfValid('titleEn')"
                                  invalidFeedback="필수 정보입니다."
                                />
                              </div>
                              <div class="col-4">
                                <CIcon name="cil-info" class="text-info mr-1" />
                                <span class="text-info">아트풀 수정페이지에서 변경</span>
                              </div>
                            </div>
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-th variant="secondary">라운드</b-th>
                          <b-td>
                            {{ artpoolInfo.roundNoErc || "시작 안됨" }}
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-th variant="secondary">
                            전체락업 <span class="text-danger font-lg">*</span>
                          </b-th>
                          <b-td>
                            <div class="row">
                              <div class="col-4">
                                <b-form-radio-group
                                  id="voted"
                                  name="voted"
                                  :options="yesOrNoOptions"
                                  v-model="roundInfo.fullLockup"
                                  :value.sync="$v.roundInfo.fullLockup.$model"
                                  :isValid="checkIfValid('fullLockup')"
                                  invalidFeedback="필수 정보입니다."
                                />
                              </div>
                              <div class="col-8">
                                <CIcon name="cil-info" class="text-info mr-1" />
                                <span class="text-info">"예"인 경우 풀 종료전까지 "언스테이크"가 불가능합니다.</span>
                              </div>
                            </div>
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-th variant="secondary">
                            락업 기간(초) <span class="text-danger font-lg">*</span>
                          </b-th>
                          <b-td>
                            <div class="row">
                              <div class="col-4">
                                <CInput
                                  class="mb-0"
                                  :disabled="artpoolInfo.roundNoErc > 0 || roundInfo.fullLockup"
                                  v-model="roundInfo.lockupTimestamp"
                                  :value.sync="$v.roundInfo.lockupTimestamp.$model"
                                  :isValid="checkIfValid('lockupTimestamp')"
                                  invalidFeedback="필수 정보입니다."
                                />
                              </div>
                              <div class="col-8">
                                <CIcon name="cil-info" class="text-info mr-1" />
                                <span class="text-info">락업기간이 1 이상일 경우 해당 기간만큼 락업</span>
                              </div>
                            </div>
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-th variant="secondary">
                            일별 리워드 <span class="text-danger font-lg">*</span>
                          </b-th>
                          <b-td>
                            <div class="row align-items-center">
                              <div class="col-4">
                              <CInput
                                class="mb-0"
                                type="number"
                                :disabled="artpoolInfo.roundNoErc > 0"
                                v-model="roundInfo.rewardPerDay"
                                :value.sync="$v.roundInfo.rewardPerDay.$model"
                                :isValid="checkIfValid('rewardPerDay')"
                                invalidFeedback="필수 정보입니다."
                              />
                              </div>
                              <div class="col-8">
                                <CIcon name="cil-info" class="text-info mr-1" />
                                <span class="text-info">1개 단위로 입력</span>
                              </div>
                            </div>
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-th variant="secondary">
                            시작 시간 <span class="text-danger font-lg">*</span>
                          </b-th>
                          <b-td>
                            <div class="row align-items-center">
                              <div class="col-8">
                                <CInput
                                  class="mb-0"
                                  disabled
                                  v-model="artpoolInfo.startDate"
                                  :value.sync="$v.artpoolInfo.startDate.$model"
                                  :isValid="checkIfValid('startDate')"
                                  invalidFeedback="필수 정보입니다."
                                />
                              </div>
                              <div class="col-4">
                                <CIcon name="cil-info" class="text-info mr-1" />
                                <span class="text-info">아트풀 수정페이지에서 변경</span>
                              </div>
                            </div>
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-th variant="secondary">
                            운영기간(일) <span class="text-danger font-lg">*</span>
                          </b-th>
                          <b-td>
                            <div class="row align-items-center">
                              <div class="col-8">
                                <CInput
                                  class="mb-0"
                                  disabled
                                  v-model="artpoolInfo.duration"
                                  :value.sync="$v.artpoolInfo.duration.$model"
                                  :isValid="checkIfValid('duration')"
                                  invalidFeedback="필수 정보입니다."
                                />
                              </div>
                              <div class="col-4">
                                <CIcon name="cil-info" class="text-info mr-1" />
                                <span class="text-info">아트풀 수정페이지에서 변경</span>
                              </div>
                            </div>
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-th variant="secondary">
                            최대 투표 수 <span class="text-danger font-lg">*</span>
                          </b-th>
                          <b-td>
                            <div class="row align-items-center">
                              <div class="col-4">
                                <CInput
                                  class="mb-0"
                                  :disabled="artpoolInfo.roundNoErc > 0"
                                  v-model="roundInfo.maxVote"
                                  :value.sync="$v.roundInfo.maxVote.$model"
                                  :isValid="checkIfValid('maxVote')"
                                  invalidFeedback="필수 정보입니다."
                                />
                              </div>
                              <div class="col-8">
                                <CIcon name="cil-info" class="text-info mr-1" />
                                <span class="text-info">1개 단위로 입력</span>
                              </div>
                            </div>
                          </b-td>
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol col="10" class="text-center my-4" v-if="!artpoolInfo.roundNoErc">
                    <span class="text-danger">라운드 시작시 블록에 기록되므로 이후에 값을 변경 할 수 없습니다.</span>
                  </CCol>
                  <CCol col="10" class="text-center my-4">
                    <CButton color="light" @click="onClickCancel" class="mr-2">
                      <CIcon name="cil-arrow-circle-left" class="mr-2" />목록
                    </CButton>
                    <CButton color="dark" @click="onClickStart" class="text-white" :disabled="artpoolInfo.roundNoErc > 0">
                      <CIcon name="cil-save" class="mr-2" />라운드 시작
                    </CButton>
                  </CCol>
                </CRow>
              </CForm>
            </b-tab>
            <b-tab title="Klaytn">
              <CForm>
                <CRow>
                  <CCol col="10">
                    <b-table-simple>
                      <colgroup>
                        <col style="width:15%;" />
                        <col style="width:85%;" />
                      </colgroup>
                      <b-tbody>
                        <b-tr>
                          <b-th variant="secondary">아트풀 번호</b-th>
                          <b-td>
                            {{ artpoolInfo.idx || "(정보없음)" }}
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-th variant="secondary">
                            아트풀 제목(영문) <span class="text-danger font-lg">*</span>
                          </b-th>
                          <b-td>
                            <div class="row align-items-center">
                              <div class="col-8">
                                <CInput
                                  class="mb-0"
                                  disabled
                                  v-model="artpoolInfo.titleEn"
                                  :value.sync="$v.artpoolInfo.titleEn.$model"
                                  :isValid="checkIfValid('titleEn')"
                                  invalidFeedback="필수 정보입니다."
                                />
                              </div>
                              <div class="col-4">
                                <CIcon name="cil-info" class="text-info mr-1" />
                                <span class="text-info">아트풀 수정페이지에서 변경</span>
                              </div>
                            </div>
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-th variant="secondary">라운드</b-th>
                          <b-td>
                            {{ artpoolInfo.roundNoKip || "시작 안됨" }}
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-th variant="secondary">
                            전체락업 <span class="text-danger font-lg">*</span>
                          </b-th>
                          <b-td>
                            <div class="row">
                              <div class="col-4">
                                <b-form-radio-group
                                  id="voted"
                                  name="voted"
                                  :options="yesOrNoOptions"
                                  v-model="roundInfo.fullLockup"
                                  :value.sync="$v.roundInfo.fullLockup.$model"
                                  :isValid="checkIfValid('fullLockup')"
                                  invalidFeedback="필수 정보입니다."
                                />
                              </div>
                              <div class="col-8">
                                <CIcon name="cil-info" class="text-info mr-1" />
                                <span class="text-info">"예"인 경우 풀 종료전까지 "언스테이크"가 불가능합니다.</span>
                              </div>
                            </div>
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-th variant="secondary">
                            락업 기간(초) <span class="text-danger font-lg">*</span>
                          </b-th>
                          <b-td>
                            <div class="row">
                              <div class="col-4">
                                <CInput
                                  class="mb-0"
                                  :disabled="artpoolInfo.roundNoKip > 0 || roundInfo.fullLockup"
                                  v-model="roundInfo.lockupTimestamp"
                                  :value.sync="$v.roundInfo.lockupTimestamp.$model"
                                  :isValid="checkIfValid('lockupTimestamp')"
                                  invalidFeedback="필수 정보입니다."
                                />
                              </div>
                              <div class="col-8">
                                <CIcon name="cil-info" class="text-info mr-1" />
                                <span class="text-info">락업기간이 1 이상일 경우 해당 기간만큼 락업</span>
                              </div>
                            </div>
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-th variant="secondary">
                            일별 리워드 <span class="text-danger font-lg">*</span>
                          </b-th>
                          <b-td>
                            <div class="row align-items-center">
                              <div class="col-4">
                                <CInput
                                  class="mb-0"
                                  type="number"
                                  :disabled="artpoolInfo.roundNoKip > 0"
                                  v-model="roundInfo.rewardPerDay"
                                  :value.sync="$v.roundInfo.rewardPerDay.$model"
                                  :isValid="checkIfValid('rewardPerDay')"
                                  invalidFeedback="필수 정보입니다."
                                />
                              </div>
                              <div class="col-8">
                                <CIcon name="cil-info" class="text-info mr-1" />
                                <span class="text-info">1개 단위로 입력</span>
                              </div>
                            </div>
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-th variant="secondary">
                            시작 시간 <span class="text-danger font-lg">*</span>
                          </b-th>
                          <b-td>
                            <div class="row align-items-center">
                              <div class="col-8">
                                <CInput
                                  class="mb-0"
                                  disabled
                                  v-model="artpoolInfo.startDate"
                                  :value.sync="$v.artpoolInfo.startDate.$model"
                                  :isValid="checkIfValid('startDate')"
                                  invalidFeedback="필수 정보입니다."
                                />
                              </div>
                              <div class="col-4">
                                <CIcon name="cil-info" class="text-info mr-1" />
                                <span class="text-info">아트풀 수정페이지에서 변경</span>
                              </div>
                            </div>
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-th variant="secondary">
                            운영기간(일) <span class="text-danger font-lg">*</span>
                          </b-th>
                          <b-td>
                            <div class="row align-items-center">
                              <div class="col-8">
                                <CInput
                                  class="mb-0"
                                  disabled
                                  v-model="artpoolInfo.duration"
                                  :value.sync="$v.artpoolInfo.duration.$model"
                                  :isValid="checkIfValid('duration')"
                                  invalidFeedback="필수 정보입니다."
                                />
                              </div>
                              <div class="col-4">
                                <CIcon name="cil-info" class="text-info mr-1" />
                                <span class="text-info">아트풀 수정페이지에서 변경</span>
                              </div>
                            </div>
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-th variant="secondary">
                            최대 투표 수 <span class="text-danger font-lg">*</span>
                          </b-th>
                          <b-td>
                            <CInput
                              class="mb-0"
                              :disabled="artpoolInfo.roundNoKip > 0"
                              v-model="roundInfo.maxVote"
                              :value.sync="$v.roundInfo.maxVote.$model"
                              :isValid="checkIfValid('maxVote')"
                              invalidFeedback="필수 정보입니다."
                            />
                          </b-td>
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol col="10" class="text-center my-4" v-if="!artpoolInfo.roundNoKip">
                    <span class="text-danger">라운드 시작시 블록에 기록되므로 이후에 값을 변경 할 수 없습니다.</span>
                  </CCol>
                  <CCol col="10" class="text-center my-4">
                    <CButton color="light" @click="onClickCancel" class="mr-2">
                      <CIcon name="cil-arrow-circle-left" class="mr-2" />목록
                    </CButton>
                    <CButton color="dark" @click="onClickKipStart" class="text-white" :disabled="artpoolInfo.roundNoKip > 0">
                      <CIcon name="cil-save" class="mr-2" />라운드 시작
                    </CButton>
                  </CCol>
                </CRow>
              </CForm>
            </b-tab>
          </b-tabs>
        </div>
      </CCardBody>
    </CCard>
    <b-modal
      id="message-modal"
      ref="message-modal"
      hide-header
      hide-footer
      centered
      no-close-on-esc
      no-close-on-backdrop
      body-class="my-4 text-dark d-flex flex-column"
    >
      <div class="mt-3 text-center">
        {{ message }}<br/>
      </div>
      <div class="mt-3 d-flex justify-content-center" v-if="!submitted">
        <div class="sk-wave">
          <div class="sk-wave-rect"></div>
          <div class="sk-wave-rect"></div>
          <div class="sk-wave-rect"></div>
          <div class="sk-wave-rect"></div>
          <div class="sk-wave-rect"></div>
        </div>
      </div>
      <div class="w-100 text-center mt-5">
        <b-button
          variant="primary"
          squared
          class="text-white px-5 py-2"
          @click="hideMessageModal"
          :disabled="!submitted"
        >
          확인
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import {validationMixin} from "vuelidate";
import {numeric, required} from "vuelidate/lib/validators";
const artpoolHelper = createNamespacedHelpers("artpoolManager");

export default {
  name: "ArtpoolRound",
  components: {},
  props: {
    idxArtpool: {
      type: String,
      default: "0"
    }
  },
  mixins: [validationMixin],
  validations() {
    return {
      artpoolInfo: {
        titleEn: { required },
        startDate: { required },
        duration: { required },
      },
      roundInfo: {
        fullLockup: { required },
        lockupTimestamp: { required },
        rewardPerDay: { required, numeric },
        maxVote: { required, numeric },
      }
    };
  },
  data: function() {
    return {
      artpoolInfo: {},
      roundInfo: this.getInitRoundInfo(),
      isBusy: true,
      tabIndex: 0,
      submitted: false,
      message: "처리중입니다.",
      yesOrNoOptions: [
        { value: true, text: "예" },
        { value: false, text: "아니오" }
      ],
    };
  },
  computed: {
    isValid() {
      return !this.$v.roundInfo.$invalid && !this.$v.artpoolInfo.$invalid;
    },
    isDirty() {
      return this.$v.roundInfo.$anyDirty || this.$v.artpoolInfo.$anyDirty;
    }
  },
  mounted() {
    this.requestArtpoolItem();
  },
  methods: {
    ...artpoolHelper.mapActions(["getArtpool","getErcRound", "initErcRound", "getKipRound", "initKipRound"]),
    requestArtpoolItem() {
      if (this.idxArtpool && this.idxArtpool !== "0") {
        this.getArtpool(this.idxArtpool).then(data => {
          this.$log.info(this.artpoolInfo); //TODO
          this.artpoolInfo = Object.assign({}, this.artpoolInfo, data);
          this.requestErcRoundInfo();
        })
          .catch(error => {
            alert("아트풀 정보 조회 오류가 발생하였습니다. 관리자에게 문의하세요.");
            this.$log.error(error);
          });
      }
      this.isBusy = false;
    },
    requestErcRoundInfo() {
      if(this.idxArtpool && this.idxArtpool !== "0" && this.artpoolInfo.roundNoErc > 0){
        this.getErcRound(this.idxArtpool).then(data => {
          this.roundInfo = Object.assign({}, this.roundInfo, data);
        })
          .catch(error => {
            alert("아트풀 라운드 정보 조회 오류가 발생하였습니다. 관리자에게 문의하세요.");
            this.$log.error(error);
          })
      }
    },
    requestKipRoundInfo() {
      if(this.idxArtpool && this.idxArtpool !== "0" && this.artpoolInfo.roundNoKip > 0){
        this.getKipRound(this.idxArtpool).then(data => {
          this.roundInfo = Object.assign({}, this.roundInfo, data);
        })
          .catch(error => {
            alert("아트풀 라운드 정보 조회 오류가 발생하였습니다. 관리자에게 문의하세요.");
            this.$log.error(error);
          })
      }
    },
    onClickTab() {
      this.roundInfo = this.getInitRoundInfo();
      if(this.tabIndex === 0 ){
        this.requestErcRoundInfo();
      }else {
        this.requestKipRoundInfo();
      }
    },
    getInitRoundInfo() {
      return {
        idx: this.idxArtpool,
        fullLockup: null,
        lockupTimestamp: 0,
        rewardPerDay: null,
        startDate: null,
        endDate: null,
        maxVote : null,
        voteStartDate: null,
        voteEndDate: null,
        duration : null,
      };
    },
    onClickCancel() {
      this.$router.push("/management/artpool-list");
    },
    onClickStart() {
      if (this.isValid) {
        if (confirm("Ethereum 라운드를 시작하시겠습니까?")) {
          const date = new Date(this.artpoolInfo.startDate);

          const payload = {
            idxArtpool: this.idxArtpool,
            poolName: this.artpoolInfo.titleEn,
            fullLockup: this.roundInfo.fullLockup,
            lockupTimestamp: this.roundInfo.lockupTimestamp,
            rewardPerDay: this.roundInfo.rewardPerDay,
            year: date.getUTCFullYear(),
            month: date.getUTCMonth() + 1,
            day: date.getUTCDate(),
            hour: date.getUTCHours(),
            duration: this.artpoolInfo.duration,
            voteMax: this.roundInfo.maxVote,
          }

          this.submitted = false;
          this.$refs["message-modal"].show();

          this.initErcRound(payload).then(data => {
            if (data != null) {
              this.message = "Ethereum round가 정상적으로 시작되었습니다.";
              this.submitted = true;
              this.roundInfo = Object.assign({}, this.roundInfo, data);
              this.requestArtpoolItem();
            } else {
              this.message = "Ethereum round 시작 오류가 발생하였습니다. 관리자에게 문의하세요.";
              this.submitted = true;
            }
          })
            .catch(error => {
              this.message = "Ethereum round 시작 오류가 발생하였습니다. 관리자에게 문의하세요.\n" + error;
              this.submitted = true;
              this.$log.error(error);
            });
        }
      } else {
        this.$v.$touch();
      }
    },
    onClickKipStart() {
      if (this.isValid) {
        if (confirm("Kaia 라운드를 시작하시겠습니까?")) {
          const date = new Date(this.artpoolInfo.startDate);

          const payload = {
            idxArtpool: this.idxArtpool,
            poolName: this.artpoolInfo.titleEn,
            fullLockup: this.roundInfo.fullLockup,
            lockupTimestamp: this.roundInfo.lockupTimestamp,
            rewardPerDay: this.roundInfo.rewardPerDay,
            year: date.getUTCFullYear(),
            month: date.getUTCMonth() + 1,
            day: date.getUTCDate(),
            hour: date.getUTCHours(),
            duration: this.artpoolInfo.duration,
            voteMax: this.roundInfo.maxVote,
          }

          this.submitted = false;
          this.$refs["message-modal"].show();

          this.initKipRound(payload).then(data => {
            if (data != null) {
              this.message = "Kaia round가 정상적으로 시작되었습니다.";
              this.submitted = true;
              this.roundInfo = Object.assign({}, this.roundInfo, data);
              this.requestArtpoolItem();
            } else {
              this.message = "Kaia round 시작 오류가 발생하였습니다. 관리자에게 문의하세요.";
              this.submitted = true;
            }
          })
            .catch(error => {
              this.message = "Kaia round 시작 오류가 발생하였습니다. 관리자에게 문의하세요.\n" + error;
              this.submitted = true;
              this.$log.error(error);
            });
        }
      } else {
        this.$v.$touch();
      }
    },
    hideMessageModal(){
      this.$refs["message-modal"].hide();
    },
    checkIfValid(fieldName) {
      const field = this.$v.artpoolInfo[fieldName] || this.$v.roundInfo[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
  },
};
</script>
