<template>
  <CCard>
    <CCardBody>
      <CRow>
        <CCol class="mb-3"><h5>법인 등록/수정</h5></CCol>
      </CRow>
      <CForm>
        <CRow>
          <CCol col="12">
            <b-table-simple>
              <colgroup>
                <col style="width:15%;" />
                <col style="width:25%;" />
                <col style="width:15%;" />
                <col style="width:25%;" />
              </colgroup>
              <b-tbody>
                <b-tr>
                  <b-th rowspan="2" variant="secondary">법인명</b-th>
                  <b-td rowspan="2">
                    <div class="row align-items-center">
                      <div class="col-lg-2 col-md-2 pr-0">
                        영문&nbsp;<span class="text-danger font-lg">*</span>
                      </div>
                      <div class="col-10">
                        <CInput
                          class="mb-0"
                          placeholder="법인명(영문)을 입력하세요."
                          :lazy="false"
                          :value.sync="$v.companyInfo.companyNameEn.$model"
                          :isValid="checkIfValid('companyNameEn')"
                          invalidFeedback="필수 정보입니다."
                        />
                      </div>
                    </div>
                    <div class="row align-items-center mt-1">
                      <div class="col-lg-2 col-md-2 pr-0">
                        한글&nbsp;<span class="text-danger font-lg">*</span>
                      </div>
                      <div class="col-10">
                        <CInput
                          class="mb-0"
                          placeholder="법인명을 입력하세요."
                          :lazy="false"
                          :value.sync="$v.companyInfo.companyName.$model"
                          :isValid="checkIfValid('companyName')"
                          invalidFeedback="필수 정보입니다."
                        />
                      </div>
                    </div>
                    <!--
                    <CInput
                      class="mb-0"
                      placeholder="법인명을 입력하세요."
                      :lazy="false"
                      :value.sync="$v.companyInfo.companyName.$model"
                      :isValid="checkIfValid('companyName')"
                      invalidFeedback="필수 정보입니다."
                    />
                    -->
                  </b-td>
                  <b-th variant="secondary">법인 번호</b-th>
                  <b-td>{{ companyInfo.idx || "(정보없음)" }}</b-td>
                </b-tr>
                <b-tr>
                  <!--
                  <b-th>법인명(영문) <span class="text-danger font-lg">*</span></b-th>
                  <b-td>
                    <CInput
                      class="mb-0"
                      placeholder="법인명(영문)을 입력하세요."
                      :lazy="false"
                      :value.sync="$v.companyInfo.companyNameEn.$model"
                      :isValid="checkIfValid('companyNameEn')"
                      invalidFeedback="필수 정보입니다."
                    />
                  </b-td>
                  -->
                  <b-th variant="secondary">대표자명 <span class="text-danger font-lg">*</span></b-th>
                  <b-td>
                    <CInput
                      class="mb-0"
                      placeholder="대표자명을 입력하세요.(예: 홍길동, 이순신)"
                      :lazy="false"
                      :value.sync="$v.companyInfo.bossName.$model"
                      :isValid="checkIfValid('bossName')"
                      invalidFeedback="필수 정보입니다."
                    />
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-th rowspan="2" variant="secondary">사업자 등록번호 <span class="text-danger font-lg">*</span></b-th>
                  <b-td>
                    <CInput
                      class="mb-0"
                      placeholder="사업자 등록번호를 입력하세요.(예: 168-87-01868)"
                      :lazy="false"
                      :value.sync="$v.companyInfo.corporationNumber.$model"
                      :isValid="checkIfValid('corporationNumber')"
                      invalidFeedback="필수 정보입니다."
                    />
                  </b-td>
                  <b-th variant="secondary" class="border-bottom">연락처 <span class="text-danger font-lg">*</span></b-th>
                  <b-td class="border-bottom">
                    <CInput
                      class="mb-0"
                      placeholder="연락처를 입력하세요.(예: 02-1234-1234)"
                      :lazy="false"
                      :value.sync="$v.companyInfo.phoneNumber.$model"
                      :isValid="checkIfValid('phoneNumber')"
                      invalidFeedback="필수 정보입니다."
                    />
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td colspan="3" class="border-top-0">
                    <CIcon name="cil-info" class="text-danger mr-2" />
                    <span class="text-danger">법인명(한글), 대표자명, 사업자 등록번호, 연락처 정보는 <u>현금영수증</u> 발행시 사용됩니다.</span>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-th variant="secondary">주소</b-th>
                  <b-td colspan="3">
                    <div class="row align-items-center">
                      <div class="col-lg-1 col-md-1 pr-0">
                      </div>
                      <div class="col-11">
                        <CInput
                          class="mb-0 custom-width-120"
                          placeholder="우편번호"
                          v-model="companyInfo.zipCode"
                        />
                      </div>
                    </div>
                    <div class="row align-items-center mt-1">
                      <div class="col-lg-1 col-md-1 pr-0">
                        영문
                      </div>
                      <div class="col-11">
                        <CInput
                          class="mb-0"
                          placeholder="주소(영문)를 입력하세요."
                          v-model="companyInfo.addressEn"
                        />
                      </div>
                    </div>
                    <div class="row align-items-center mt-1">
                      <div class="col-lg-1 col-md-1 pr-0">
                        한글
                      </div>
                      <div class="col-11">
                        <CInput
                          class="mb-0"
                          placeholder="주소를 입력하세요."
                          v-model="companyInfo.address"
                        />
                      </div>
                    </div>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-th variant="secondary">은행 계좌 <span class="text-info font-lg">*</span></b-th>
                  <b-td colspan="3">
                    <CSelect
                      class="col-2 float-left mb-0 px-0"
                      :options="bankCodeOptions"
                      :value.sync="companyInfo.bankCode"
                    />
                    <CInput
                      class="col-10 float-left mb-0 pr-0"
                      placeholder="정산 받으실 은행 계좌를 입력하세요."
                      v-model="companyInfo.bankAccount"
                    />
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-th variant="secondary">카이아 지갑주소 <span class="text-info font-lg">*</span></b-th>
                  <b-td colspan="3">
                    <CInput
                      class="mb-0"
                      placeholder="카이아 지갑주소를 입력하세요."
                      v-model="companyInfo.klaytnAddr"
                    />
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-th variant="secondary">이더리움 지갑주소 <span class="text-info font-lg">*</span></b-th>
                  <b-td colspan="3">
                    <CInput
                      class="mb-0"
                      placeholder="이더리움 지갑주소를 입력하세요."
                      v-model="companyInfo.ethereumAddr"
                    />
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-th variant="secondary">폴리곤 지갑주소 <span class="text-info font-lg">*</span></b-th>
                  <b-td colspan="3">
                    <CInput
                      class="mb-0"
                      placeholder="폴리곤 지갑주소를 입력하세요."
                      v-model="companyInfo.polygonAddr"
                    />
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-th variant="secondary">BTC 지갑주소 <span class="text-info font-lg">*</span></b-th>
                  <b-td colspan="3">
                    <CInput
                      class="mb-0"
                      placeholder="BTC 지갑주소를 입력하세요."
                      v-model="companyInfo.btcAddr"
                    />
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-th variant="secondary">
                    판매수수료 비율 <span class="text-info font-lg">*</span><br/>(총 100%)
                  </b-th>
                  <b-td colspan="3">
                    <CRow>
                      <CCol col="2" class="pr-0">
                        <CInput
                          label="작가(법인)"
                          type="number"
                          class="mb-0"
                          placeholder="90"
                          v-model="companyInfo.salesFeeRatioArtist"
                        />
                      </CCol>
                      <CCol col="2" class="pr-0">
                        <CInput
                          label="세번째공간"
                          type="number"
                          class="mb-0"
                          placeholder="10"
                          v-model="companyInfo.salesFeeRatioThreespace"
                        />
                      </CCol>
                    </CRow>
                    <CRow class="mt-2">
                      <CCol>
                        <CIcon name="cil-info" class="text-info mr-2" />
                        <span class="text-info">은행 계좌, 카이아 지갑주소, 이더리움 지갑주소, BTC 지갑주소, 판매수수료 비율 정보 변경 시, <u>소속 작가 정보</u>도 일괄 변경됩니다.</span>
                      </CCol>
                    </CRow>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-th variant="secondary" class="border-bottom">비고</b-th>
                  <b-td colspan="3" class="border-bottom">
                    <CTextarea
                      class="mb-0"
                      rows="4"
                      v-model="companyInfo.remarks"
                    />
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </CCol>
        </CRow>
        <CRow>
          <CCol col="12" class="text-center my-4">
            <CButton color="light" @click="onClickCancel()" class="mr-2">
              <CIcon name="cil-x-circle" class="mr-2" />취소
            </CButton>
            <CButton color="secondary" @click="onClickDelete()" class="mr-2">
              <CIcon name="cil-trash" class="mr-2" />삭제
            </CButton>
            <CButton color="dark" @click="onClickSave()" class="text-white">
              <CIcon name="cil-save" class="mr-2" />저장
            </CButton>
          </CCol>
        </CRow>
      </CForm>
    </CCardBody>
  </CCard>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { createNamespacedHelpers } from "vuex";
const companyHelper = createNamespacedHelpers("company");

export default {
  name: "CompanySave",
  components: {},
  props: {
    idxCompany: {
      type: String,
      default: "0"
    }
  },
  data: function() {
    return {
      companyInfo: {
        companyName: "",
        companyNameEn: "",
        bossName: "",
        corporationNumber: "",
        phoneNumber: ""
      },
      // TODO
      bankCodeOptions: [
        { value: "", label: "선택" },
        { value: "003", label: "기업은행" },
        { value: "004", label: "국민은행" },
        { value: "020", label: "우리은행" },
        { value: "081", label: "하나은행" },
        { value: "088", label: "신한은행" },
        { value: "027", label: "씨티은행" },
        { value: "011", label: "NH농협은행" },
        { value: "090", label: "카카오뱅크" },
        { value: "092", label: "토스뱅크" },
      ]
    };
  },
  computed: {
    isValid() {
      return !this.$v.companyInfo.$invalid;
    },
    isDirty() {
      return this.$v.companyInfo.$anyDirty;
    }
  },
  mixins: [validationMixin],
  validations: {
    companyInfo: {
      companyName: { required },
      companyNameEn: { required },
      bossName: { required },
      corporationNumber: { required },
      phoneNumber: { required }
    }
  },
  mounted() {
    this.requestCompanyItem();
  },
  methods: {
    ...companyHelper.mapActions([ "getCompany", "addCompany", "updateCompany", "deleteCompany" ]),
    requestCompanyItem() {
      if (this.idxCompany && this.idxCompany !== "0") {
        this.getCompany(this.idxCompany).then(data => {
          this.companyInfo = data;
          this.$v.$reset();
          //this.$log.info(this.companyInfo);//TODO
        });
      }
    },
    onClickCancel() {
      this.$router.push("/management/company-list");
    },
    onClickDelete() {
      if (confirm("법인에 소속된 작가가 존재하는 경우 삭제되지 않을 수 있습니다.\n정말 삭제하시겠습니까?")) {
        this.deleteCompany({ idx: this.idxCompany })
          .then(() => {
            alert("삭제 되었습니다.");
            this.$router.push("/management/company-list");
          })
          .catch(error => {
            alert("법인 삭제 오류가 발생하였습니다. 관리자에게 문의하세요.");
            this.$log.error(error);
          });
      }
    },
    onClickSave() {
      this.$v.$touch();
      if (this.isValid) {
        // 판매수수료 비율
        let salesFeeRatioArtist =
          this.companyInfo.salesFeeRatioArtist !== undefined && this.companyInfo.salesFeeRatioArtist !== "" ? this.companyInfo.salesFeeRatioArtist : 0;
        let salesFeeRatioThreespace =
          this.companyInfo.salesFeeRatioThreespace !== undefined && this.companyInfo.salesFeeRatioThreespace !== "" ? this.companyInfo.salesFeeRatioThreespace : 0;
        const ratio = parseInt(salesFeeRatioArtist) + parseInt(salesFeeRatioThreespace);
        if (ratio !== 0 && ratio !== 100) {
          alert("판매수수료 비율은 총 100%로 설정해주세요.");
          return false;
        }

        // 법인 정보 저장
        if (this.idxCompany && this.idxCompany !== "0") {
          // 법인 수정
          this.updateCompany(this.companyInfo).then(() => {
            alert("수정 되었습니다.");
            this.onClickCancel();
          });
        } else {
          // 법인 등록
          this.addCompany(this.companyInfo).then(() => {
            alert("저장 되었습니다.");
            this.onClickCancel();
          });
        }
      }
    },
    checkIfValid(fieldName) {
      const field = this.$v.companyInfo[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    }
  }
};
</script>
