var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(
      _vm.galleryProductItems.content === undefined ||
      _vm.galleryProductItems.content === null ||
      _vm.galleryProductItems.content.length === 0
    )?[_c('div',{key:"0",staticClass:"custom-tabs--undefined"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t("mypage.noHistoryNFT"))+" ")])])]:[_c('div',{staticClass:"custom-tabs__wrapper"},_vm._l((_vm.galleryProductItems.content),function(item,i){return _c('article',{key:i,ref:'cartItem' + item.idx,refInFor:true,staticClass:"item"},[_c('div',{staticClass:"card_list_item cursor-pointer",attrs:{"id":'galleryItem' + item.idx},on:{"click":function($event){return _vm.showProductDetail(item.idx)}}},[_c('v-lazy',{attrs:{"options":{ threshold: 0.5 },"transition":"fade-transition"}},[_c('div',{staticClass:"img-box",class:{ isLoading: _vm.isBusy },on:{"contextmenu":function($event){$event.preventDefault();$event.stopPropagation();}}},[[(_vm.isProductFileTypeImage(item.displayCardFileType))?[_c('b-img',{attrs:{"src":_vm.replaceDisplayCardUrl(item)}})]:[_c('video',{attrs:{"poster":_vm.replaceDisplayPosterUrl(item),"src":_vm.replaceDisplayCardUrl(item),"type":"video/webm","muted":"","loop":"","autoplay":"","playsinline":""},domProps:{"muted":true}})]],_c('b-skeleton-img',{attrs:{"no-aspect":"","width":"100%","height":"100%"}})],2)]),_c('section',{staticClass:"card-info"},[(!_vm.isBusy)?_c('section',{staticClass:"card-info__title"},[_c('p',{staticClass:"artist_name"},[(
                    _vm.activeLanguage !== undefined && _vm.activeLanguage === 'ko'
                  )?[_vm._v(" "+_vm._s(item.artistName || item.artistNameEn)+" ")]:[_vm._v(" "+_vm._s(item.artistNameEn || item.artistName)+" ")]],2),_c('div',{staticClass:"icon-wrap"},[_c('FavoritedButton',{attrs:{"favoritedItem":item},on:{"onClickFavoritedButton":_vm.onClickFavoritedButton}})],1)]):_c('section',{staticClass:"card-info__title"},[_c('b-skeleton',{staticClass:"float-left mt-2 ml-2",attrs:{"width":"100px"}})],1),(!_vm.isBusy)?_c('h3',{staticClass:"card-info__name"},[(_vm.activeLanguage !== undefined && _vm.activeLanguage === 'ko')?[_vm._v(" "+_vm._s(item.productName || item.productNameEn)+" ")]:[_vm._v(" "+_vm._s(item.productNameEn || item.productName)+" ")]],2):_c('h3',{staticClass:"card-info__name"},[_c('b-skeleton',{staticClass:"float-left mt-2 ml-2",attrs:{"width":"200px"}})],1),(!_vm.isBusy)?_c('section',{staticClass:"card-info__price",class:{ soldout: item.status === 'SOLDOUT' }},[_c('p',{staticClass:"price"},[_vm._v("price")]),_c('p',{staticClass:"amount"},[(item.status === 'SOLDOUT')?[_vm._v(" "+_vm._s(item.buyerNickname !== undefined && item.buyerNickname !== "" ? "Owned by @" + item.buyerNickname : _vm.$t("product.statusSoldOut"))+" ")]:(item.status === 'EXHIBIT')?[_vm._v(" "+_vm._s(_vm.$t("product.statusExhibit"))+" ")]:(
                    _vm.activeLanguage !== undefined &&
                    _vm.activeLanguage === 'en' &&
                    _vm.isCoinPriceNonZero(item.nftType) &&
                    _vm.isAcceptableNftType(item.nftType)
                  )?[_vm._v(" "+_vm._s(_vm.getProductCoinPrice(item.productPrice, item.nftType))+" "+_vm._s(_vm.getCoinCurrencyByNftType(item.nftType))+" ")]:[_vm._v(" "+_vm._s(_vm._f("currency")(item.productPrice))+" "+_vm._s(item.productPrice !== undefined && item.productPrice > 0 ? "KRW" : _vm.$t("product.statusExhibit"))+" ")]],2)]):_c('section',{staticClass:"card-info__price"},[_c('span',[_c('b-skeleton',{attrs:{"width":"50%","height":"25px"}})],1)])])],1)])}),0),_c('div',{staticClass:"row pt-5"},[_c('div',{staticClass:"col-12 text-right"},[_c('b-pagination',{staticClass:"float-right gallery_pagination",attrs:{"total-rows":_vm.galleryProductItems.totalElements,"per-page":_vm.perPage,"hide-ellipsis":"","limit":"10","aria-controls":"role-function-list"},on:{"change":_vm.onPaging},scopedSlots:_vm._u([{key:"first-text",fn:function(){return [_c('img',{staticClass:"page_icon rotate_page_icon",attrs:{"src":"/img/threespace/icon/pagination_arrow2_icon.png"}})]},proxy:true},{key:"prev-text",fn:function(){return [_c('img',{staticClass:"page_icon rotate_page_icon",attrs:{"src":"/img/threespace/icon/pagination_arrow_icon.png"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('img',{staticClass:"page_icon",attrs:{"src":"/img/threespace/icon/pagination_arrow_icon.png"}})]},proxy:true},{key:"last-text",fn:function(){return [_c('img',{staticClass:"page_icon",attrs:{"src":"/img/threespace/icon/pagination_arrow2_icon.png"}})]},proxy:true},{key:"page",fn:function({ page }){return [_c('span',{class:{ active_num: page === _vm.currentPage }},[_vm._v(" "+_vm._s(page)+" ")])]}}]),model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)])]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }