<template>
  <div>
    <b-modal
      id="payment-klip-modal-2"
      ref="payment-klip-modal-2"
      centered
      hide-header
      hide-footer
      body-class="text-black"
      @hide="closeModal"
    >
      <CRow>
        <CCol class="col-12">
          <div class="d-flex justify-content-end">
            <img
              class="cursor-pointer"
              src="/img/threespace/icon/close.png"
              alt="close"
              @click="$refs['payment-klip-modal-2'].hide()"
            />
          </div>
        </CCol>
      </CRow>

      <!-- 카카오톡 클립(PC)으로 연결 -->
      <template v-if="form.isKakaoKlipPc">
        <div
          class="w-100 d-flex justify-content-center align-items-center mb-2"
        >
          <p
            style="
              color: #000;
              font-size: 20px;
              font-weight: bold;
              margin-bottom: 6px;
            "
          >
            {{ $t("wallet.modal.klipQRTitle") }}
          </p>
        </div>
        <div
          style="
            background-color: #f7f7f7;
            padding: 10px 20px;
            margin-bottom: 20px;
          "
        >
          <div class="w-100 d-flex justify-content-center">
            <img :src="qrcode" width="160" height="160" class="border-1" />
          </div>
          <div class="w-100 text-center mt-2">
            <p style="font-size: 12px">
              <span class="text-black mr-2 font-weight-bold">{{
                $t("wallet.modal.remainTime")
              }}</span>
              <span class="text-danger font-weight-bold">{{ time }}</span>
            </p>
          </div>
          <div
            style="font-size: 12px; color: #000"
            class="w-100 text-center mt-2"
          >
            <p>
              {{ $t("wallet.modal.klipQRdescription") }}
            </p>
            <p>
              {{ $t("wallet.modal.klipQRdescription2") }}
            </p>
          </div>
          <div
            class="row d-flex justify-content-center align-items-center mt-3"
          >
            <div class="col-2">
              <img src="/img/threespace/payment/kakaotalk-logo.svg" />
            </div>
            <div class="col-1">
              <img src="/img/threespace/payment/pointer-right.svg" />
            </div>
            <div class="col-2">
              <img src="/img/threespace/payment/kakaotalk-search.svg" />
            </div>
            <div class="col-1">
              <img src="/img/threespace/payment/pointer-right.svg" />
            </div>
            <div class="col-2">
              <img src="/img/threespace/payment/kakaotalk-scan.svg" />
            </div>
          </div>
          <div
            class="row d-flex justify-content-center align-items-center text-center mt-2"
          >
            <div class="col-4">{{ $t("wallet.klip.exeKakao") }}</div>
            <div class="col-3">{{ $t("wallet.klip.search") }}</div>
            <div class="col-4">{{ $t("wallet.klip.scanCode") }}</div>
          </div>
        </div>
      </template>
      <!-- 카카오톡 클립(모바일)으로 연결 -->
      <template v-if="form.isKakaoKlipMobile">
        <div class="w-100 text-center my-5">
          <p>
            <span class="text-gray mr-2">{{
              $t("wallet.modal.remainTime")
            }}</span>
            <span class="text-danger">{{ time }}</span>
          </p>
        </div>
      </template>
    </b-modal>

    <PaymentKlip3 :payment-result-info="paymentResultInfo" ref="paymentKlip3" />
  </div>
</template>

<script>
import { prepare, request, getResult } from "klip-sdk";
import QRCode from "qrcode";
import { createNamespacedHelpers, mapActions, mapState } from "vuex";
const paymentHelper = createNamespacedHelpers("payment");

import PaymentKlip3 from "@/views/threespace/payment/PaymentKlip3";

export default {
  name: "PaymentKlip2",
  components: {
    PaymentKlip3,
  },
  props: {
    klayPaymentInfo: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      form: this.getInitPage(),
      qrcode: null,
      isTimeout: false,
      date: this.$moment(60 * 3 * 1000),
      paymentResultInfo: {},
      paymentType: "",
      resultStatus: "",
    };
  },
  mounted() {},
  computed: {
    ...mapState("gallery", ["paymentProductInfo"]),
    ...mapState("util", ["slackInfo"]),
    time: function () {
      return this.date.format("mm : ss");
    },
    isMobile() {
      return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    },
  },
  methods: {
    ...paymentHelper.mapActions([
      "updatePaymentKlay",
      "completePaymentKlay",
      "completePaymentToken",
    ]),
    ...mapActions("util", ["sendSlack"]),
    showKlipPayment() {
      this.qrcode = null;
      const bappName = "3space Art";
      const from = this.klayPaymentInfo.fromAddr;
      const to = this.klayPaymentInfo.toAddr;
      const amount = this.klayPaymentInfo.amountCoin;
      // Somesing Token (SSX) contract address
      const contract = "0xdcd62c57182e780e23d2313c4782709da85b9d6c";

      if (this.isMobile) {
        if (this.paymentType === "KLAY") {
          // 모바일
          prepare
            .sendKLAY({ bappName, from, to, amount })
            .then((data) => {
              this.sendNextMobile(data);
            })
            .catch((error) => {
              this.$log.error(error);
              alert(
                "요청에 실패하였습니다. 이용중인 서비스에서 확인 후 다시 시도해주세요."
              );
            });
        } else if (this.paymentType === "SSX") {
          // 모바일
          prepare
            .sendToken({ bappName, from, to, amount, contract })
            .then((data) => {
              this.sendNextMobile(data);
            })
            .catch((error) => {
              this.$log.error(error);
              alert(
                "요청에 실패하였습니다. 이용중인 서비스에서 확인 후 다시 시도해주세요."
              );
            });
        }
      } else {
        if (this.paymentType === "KLAY") {
          // PC
          prepare
            .sendKLAY({ bappName, from, to, amount })
            .then((data) => {
              this.sendNextPC(data);
            })
            .catch((error) => {
              this.$log.error(error);
              alert(
                "요청에 실패하였습니다. 이용중인 서비스에서 확인 후 다시 시도해주세요."
              );
            });
        } else if (this.paymentType === "SSX") {
          // PC
          prepare
            .sendToken({ bappName, from, to, amount, contract })
            .then((data) => {
              this.sendNextPC(data);
            })
            .catch((error) => {
              this.$log.error(error);
              alert(
                "요청에 실패하였습니다. 이용중인 서비스에서 확인 후 다시 시도해주세요."
              );
            });
        }
      }
    },
    sendNextPC(data) {
      //this.$log.log("showKlipPayment() data=", data);
      if (data.err) {
        if (data.code === 6412) {
          alert("요청에 실패하였습니다. 클레이 수량을 확인해주세요.");
        } else {
          alert(
            "요청에 실패하였습니다. 이용중인 서비스에서 확인 후 다시 시도해주세요.\n[" +
              data.code +
              "] " +
              data.err
          );
        }
        this.hideReserveCancel();
      } else {
        if (data.request_key) {
          // 타이머
          this.isTimeout = false;
          this.date = this.$moment(60 * 3 * 1000); // 3분
          clearInterval(this.timer);
          clearInterval(this.totalTimer);

          // QR 코드 생성
          this.createQRCode(
            "https://klipwallet.com/?target=/a2a?request_key=" +
              data.request_key
          );
          if (this.qrcode != null) {
            this.$refs["payment-klip-modal-2"].show();
          }

          // 결과 조회 전체 타이머(QR 남은 시간)
          this.setTotalTimer();
          // 결과 조회
          this.setResultTimer(data.request_key);
        } else {
          alert(
            "카카오 QR코드 생성에 실패하였습니다. 잠시후 다시 시도해 주세요."
          );
        }
      }
    },
    sendNextMobile(data) {
      //this.$log.log("showKlipPayment() data=", data);
      if (data.err) {
        if (data.code === 6412) {
          alert("요청에 실패하였습니다. 클레이 수량을 확인해주세요.");
        } else {
          alert(
            "요청에 실패하였습니다. 이용중인 서비스에서 확인 후 다시 시도해주세요.\n[" +
              data.code +
              "] " +
              data.err
          );
        }
        this.hideReserveCancel();
      } else {
        if (data.request_key) {
          // 타이머
          this.isTimeout = false;
          this.date = this.$moment(60 * 3 * 1000); // 3분
          clearInterval(this.timer);
          clearInterval(this.totalTimer);

          // 카카오톡 클립 > 정보제공 요청
          request(data.request_key, true);
          this.form = this.getInitPage();
          this.form.isKakaoKlipMobile = true;

          // 결과 조회 전체 타이머
          this.setTotalTimer();
          // 결과 조회
          this.setResultTimer(data.request_key);
        } else {
          alert("요청에 실패하였습니다. 잠시후 다시 시도해 주세요.");
        }
      }
    },
    setTotalTimer() {
      if (!this.isTimeout) {
        this.totalTimer = setInterval(() => {
          this.date = this.date.clone().subtract(1, "seconds");
          if (this.time === "00 : 00") {
            clearInterval(this.timer);
            clearInterval(this.totalTimer);
            this.$log.log("timeout..."); //TODO
            if (this.resultStatus === "pending") {
              this.slackInfo = {
                type: "PAYMENT", // PAYMENT, ORDER, ARTWORK
                header: "코인-결제지연",
                productName: this.paymentProductInfo.productName,
                artistName: this.paymentProductInfo.artistName,
                job: "> Klip API result.status: pending",
              };
              this.sendSlack(slackInfo);
              this.updateReservePending();
              alert(this.$t("payment.payPendingMsg"));
            } else {
              this.hideReserveCancel();
            }
          }
        }, 1000);
      }
    },
    setResultTimer(_requestKey) {
      if (!this.isTimeout) {
        this.timer = setInterval(() => {
          getResult(_requestKey)
            .then((data) => {
              if (data.result != undefined && data.result.status != undefined) {
                this.resultStatus = data.result.status;
              }
              // this.$log.log("getResult() data=", data);
              if (data.err) {
                alert(
                  "요청에 실패하였습니다. 이용중인 서비스에서 확인 후 다시 시도해주세요."
                );
                this.hideReserveCancel();
              } else {
                if (data.status === "completed") {
                  if (data.result.status === "success") {
                    clearInterval(this.timer);
                    this.isTimeout = true;
                    this.requestComplate();
                  } else if (data.result.status === "pending") {
                    // pending : success될때까지 실행
                    this.isTimeout = false;
                  } else {
                    // fail : 종료
                    clearInterval(this.timer);
                    this.isTimeout = true;
                    this.slackInfo = {
                      type: "PAYMENT", // PAYMENT, ORDER, ARTWORK
                      header: "코인-결제지연",
                      productName: this.paymentProductInfo.productName,
                      artistName: this.paymentProductInfo.artistName,
                      job: "> Klip API result.status: fail",
                    };
                    this.sendSlack(slackInfo);
                    this.updateReservePending();
                    alert(this.$t("payment.payFailMsg"));
                  }
                } else if (data.status === "canceled") {
                  clearInterval(this.timer);
                  this.isTimeout = true;
                  this.hideConfirm();
                }
              }
            })
            .catch((error) => {
              this.$log.error(error);
            });
        }, 3000);
      }
    },
    requestComplate() {
      if (this.paymentType === "KLAY") {
        // NFT 전송 (klay)
        this.completePaymentKlay(this.klayPaymentInfo)
          .then((data) => {
            //this.$log.log("[completePaymentKlay] >> ", data);
            this.paymentResultInfo = data;
            this.$refs["paymentKlip3"].show();
            this.$refs["payment-klip-modal-2"].hide();

            // 판매 법인 정보 삭제
            localStorage.removeItem(
              "referralCorpSeller_" + this.paymentResultInfo.idxProduct
            );
          })
          .catch((error) => {
            this.$log.error(error);
            this.$refs["payment-klip-modal-2"].hide();
            alert(
              "NFT 전송 시간이 지연되고 있습니다. 잠시후 다시 확인해주세요."
            );
          });
      } else {
        // NFT 전송 (tokens)
        this.completePaymentToken(this.klayPaymentInfo)
          .then((data) => {
            this.paymentResultInfo = data;
            this.$refs["paymentKlip3"].show();
            this.$refs["payment-klip-modal-2"].hide();

            // 판매 법인 정보 삭제
            localStorage.removeItem(
              "referralCorpSeller_" + this.paymentResultInfo.idxProduct
            );
          })
          .catch((error) => {
            this.$log.error(error);
            this.$refs["payment-klip-modal-2"].hide();
            alert(
              "NFT 전송 시간이 지연되고 있습니다. 잠시후 다시 확인해주세요."
            );
          });
      }
    },
    createQRCode(_klipURL) {
      QRCode.toDataURL(_klipURL, async (err, url) => {
        this.form = this.getInitPage();
        if (err) {
          this.qrcode = null;
        } else {
          this.form.isKakaoKlipPc = true;
          this.qrcode = url;
        }
      });
    },
    getInitPage() {
      return {
        isKakaoKlipPc: false,
        isKakaoKlipMobile: false,
      };
    },
    show(paymentType) {
      this.form = this.getInitPage();
      this.isTimeout = false;
      this.paymentType = paymentType;
      this.showKlipPayment();
    },
    hideReserveCancel() {
      const requestInfo = {
        idxProduct: this.klayPaymentInfo.idxProduct,
        idxOrderPayment: this.klayPaymentInfo.idxOrderPayment,
        status: "RESERVE_CANCEL",
      };

      // 작품 상태(예약취소, 판매중) 업데이트
      this.updatePaymentKlay(requestInfo)
        .then((data) => {
          //this.$log.log("[updatePaymentKlay] >> ", data);
          this.$refs["payment-klip-modal-2"].hide();
        })
        .catch((error) => {
          this.$log.error(error);
          this.$refs["payment-klip-modal-2"].hide();
        });
    },
    // klip 결제 상태가 pending, fail 일때 statCd 10(처리중)으로 update
    updateReservePending() {
      const requestInfo = {
        idxProduct: this.klayPaymentInfo.idxProduct,
        idxOrderPayment: this.klayPaymentInfo.idxOrderPayment,
        status: "RESERVE_PENDING",
      };

      // 작품 상태(예약중 & 처리중 & 실패) 업데이트
      this.updatePaymentKlay(requestInfo)
        .then((data) => {
          //this.$log.log("[updatePaymentKlay] >> ", data);
          this.$refs["payment-klip-modal-2"].hide();
        })
        .catch((error) => {
          this.$log.error(error);
          this.$refs["payment-klip-modal-2"].hide();
        });
    },
    hideConfirm() {
      if (confirm(this.$t("payment.payCancelMsg"))) {
        this.hideReserveCancel();
      }
    },
    closeModal() {
      clearInterval(this.timer);
      clearInterval(this.totalTimer);
    },
  },
  destroyed() {
    clearInterval(this.timer);
    clearInterval(this.totalTimer);
  },
};
</script>
