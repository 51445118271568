<template>
  <div id="MyPageUpdate">
    <article
      class="banner"
      :class="{
        is_banner:
          memberInfo.bannerImage !== undefined &&
          memberInfo.bannerImage !== null &&
          memberInfo.bannerImage !== '',
      }"
    >
      <!-- background image uplopad -->
      <div class="upload-btn" @click="$refs.bannerInputFile.click()">
        <input
          type="file"
          ref="bannerInputFile"
          accept="image/jpg, image/jpeg, image/png, image/gif"
          @change="onClickBannerImage"
        />
        <div class="btn_content">
          <img src="/img/threespace/mypage/banner_upload_icon.svg" />
          <p
            v-if="
              memberInfo.bannerImage === undefined ||
              memberInfo.bannerImage === null ||
              memberInfo.bannerImage === ''
            "
            v-html="$t('mypage.addBannerImage')"
          ></p>
        </div>
      </div>
      <div
        class="banner__image"
        :style="
          memberInfo.bannerImageUrl
            ? {
                backgroundImage: `url(${memberInfo.bannerImageUrl})`,
              }
            : {
                border: '10px solid white',
                outline: '1px solid #343434',
                padding: '10px',
                background:
                  'repeating-linear-gradient(-45deg, #d9d9d9, #d9d9d9 1px, #fff 1px, #fff 10px)',
                backgroundClip: 'padding-box',
              }
        "
      ></div>
      <article
        :class="[
          'profile-image',
          {
            'profile-image__default':
              memberInfo.profileImageName === undefined ||
              memberInfo.profileImageName === null ||
              memberInfo.profileImageName === '',
          },
        ]"
      >
        <div class="img-box">
          <template
            v-if="
              memberInfo.profileImageName !== undefined &&
              memberInfo.profileImageName !== null &&
              memberInfo.profileImageName !== ''
            "
          >
            <img :src="memberInfo.profileImageUrl" />
            <!-- <img src="/img/threespace/icon/drop-x.png" /> -->
          </template>
        </div>
        <v-file-input
          accept="image/jpg, image/jpeg, image/png, image/gif"
          hide-input
          prepend-icon="mdi-square-edit-outline"
          v-model="profileImage"
          @change="onClickProfileImage"
        ></v-file-input>
      </article>
    </article>

    <article class="mypage-tabs">
      <b-tabs align="center" class="custom_tabs" v-model="selectedTab">
        <!-- (Tab 0) 프로필 -->
        <b-tab>
          <template #title>
            <p>{{ $t("mypage.profile") }}</p>
          </template>
          <MyPageUpdateProfile v-if="!isCorporation" />
          <MyPageUpdateProfileCorporation v-else />
        </b-tab>
        <!-- (Tab 1) 작가정보 -->
        <b-tab v-if="isArtist">
          <template #title>
            <p>{{ $t("mypage.artistInfo") }}</p>
          </template>
          <MyPageUpdateArtist :isArtist="isArtist" />
        </b-tab>
        <!-- (Tab 2) 비밀번호 변경 -->
        <b-tab v-if="memberInfo.snsType === 'THREESPACE'">
          <template #title>
            <p>{{ $t("mypage.changePassword") }}</p>
          </template>
          <MyPageUpdatePassword />
        </b-tab>
        <!-- (Tab 3) 설정 -->
        <b-tab>
          <template #title>
            <p>{{ $t("mypage.settings") }}</p>
          </template>
          <MyPageUpdateSettings :isArtist="isArtist" />
        </b-tab>
      </b-tabs>
    </article>
    <!-- ETC ================================ -->
    <!-- 완료 팝업 -->
    <b-modal
      ref="save-message-modal"
      hide-header
      hide-footer
      centered
      no-close-on-esc
      no-close-on-backdrop
      body-class="text-center text-black py-5"
    >
      <b-img src="/img/threespace/logo/logo_100x100.png" width="70"></b-img>
      <p class="mt-3">{{ completeMessage }}</p>
      <b-button
        variant="primary"
        size="lg"
        squared
        class="mt-3 px-5"
        @click="hideSaveMessageModal"
      >
        <span class="text-white">{{ $t("button.confirm") }}</span>
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import MyPageUpdateArtist from "./mypage/MyPageUpdateArtist.vue";
import MyPageUpdatePassword from "./mypage/MyPageUpdatePassword.vue";
import MyPageUpdateProfile from "./mypage/MyPageUpdateProfile.vue";
import MyPageUpdateProfileCorporation from "./mypage/MyPageUpdateProfileCorporation.vue";
import MyPageUpdateSettings from "./mypage/MyPageUpdateSettings.vue";
const profileHelper = createNamespacedHelpers("profile");
const authHelper = createNamespacedHelpers("auth");

export default {
  name: "MyPageUpdate",
  components: {
    MyPageUpdateProfile,
    MyPageUpdatePassword,
    MyPageUpdateSettings,
    MyPageUpdateArtist,
    MyPageUpdateProfileCorporation,
  },
  props: {
    activeTab: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      selectedTab: 0,
      // (Tab 1) 프로필
      errorMessage: null,
      profileImage: [],
      bannerImage: [],
      clipboardCopied: false,
      listOrderSelected: "LATEST",
      listOrderOptions: [
        { text: "최신순", value: "LATEST" },
        { text: "낮은가격순", value: "LOW_PRICE" },
        { text: "높은가격순", value: "HIGH_PRICE" },
      ],
      completeMessage: null,
      acceptDeleteMember: false,
      acceptErrorMessage: null,
      acceptCheckTotal: false,
      selectedWallet: null,
      walletAddress: {
        klaytn: null,
        ethereum: null,
      },
    };
  },
  computed: {
    ...profileHelper.mapState(["memberInfo"]),
    ...authHelper.mapState(["me", "isCorporation"]),
    isArtist() {
      if (this.memberInfo.roles != undefined) {
        let artistIndex = this.memberInfo.roles.findIndex((el) => {
          return el.code === "ROLE_ARTIST";
        });
        if (artistIndex === -1) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    isValid() {
      return !this.$v.memberInfo.$invalid;
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.activeTab !== undefined && this.activeTab !== null) {
        this.selectedTab = this.activeTab;
      }
    });
  },
  methods: {
    ...profileHelper.mapActions([
      "updateProfile",
      "updateProfileImage",
      "updateBannerImage",
      "deleteMember",
    ]),
    ...authHelper.mapActions(["logout"]),
    onUpdateProfileImage() {
      this.completeMessage = "";
      // 프로필 이미지 파일 업로드
      //this.$log.info(this.profileImage)
      if (this.profileImage) {
        const formData = new FormData();
        formData.append("idxMember", this.me.idx);
        formData.append("profileImage", this.profileImage);
        this.updateProfileImage(formData)
          .then((data) => {
            this.memberInfo.profileImageUrl = data.profileImageUrl;
            // this.$router.go(0)
          })
          .catch((error) => {
            this.$log.error(error);
            this.completeMessage = this.$t("mypage.errorProfile");
            this.$refs["save-message-modal"].show();
          });
      }
    },
    onUpdateBannerImage() {
      this.completeMessage = "";
      // 프로필 이미지 파일 업로드
      if (this.bannerImage) {
        const formData = new FormData();
        formData.append("idxMember", this.me.idx);
        formData.append("bannerImage", this.bannerImage);
        this.updateBannerImage(formData)
          .then((data) => {
            this.memberInfo.bannerImageUrl = data.bannerImageUrl;
            // this.$router.go(0)
          })
          .catch((error) => {
            this.$log.error(error);
            this.completeMessage = this.$t("mypage.errorProfile");
            this.$refs["save-message-modal"].show();
          });
      }
    },
    onClickProfileImage() {
      if (this.profileImage.size > 5000000) {
        alert(this.$t("mypage.profileImageSizeLimit"));
      } else {
        this.onUpdateProfileImage();
      }
    },
    onClickBannerImage() {
      this.bannerImage = this.$refs["bannerInputFile"].files[0];

      if (this.bannerImage.size > 5000000) {
        alert(this.$t("mypage.bannerImageSizeLimit"));
      } else {
        this.onUpdateBannerImage();
      }
    },
    replaceNewLine(str) {
      if (str !== undefined && str !== null && str !== "") {
        return str.replace(/(?:\r\n|\r|\n)/g, "<br />");
      } else {
        return "";
      }
    },
    onCheckAccept() {
      if (!this.acceptDeleteMember) {
        this.acceptErrorMessage = this.$t("validation.required");
      } else {
        this.acceptErrorMessage = "";
      }
    },
    onClickToLeave() {
      this.completeMessage = "";
      if (!this.acceptDeleteMember) {
        this.acceptErrorMessage = this.$t("validation.required");
      } else {
        if (this.me.idx && this.me.idx !== "0") {
          this.deleteMember({ idxMember: this.me.idx })
            .then(() => {
              this.logout().then(() => {
                this.completeMessage = this.$t("mypage.memberLeave.complete");
                this.$refs["delete-message-modal"].show();
                this.$refs["delete-confirm-modal"].hide();
              });
            })
            .catch((error) => {
              this.$log.error(error);
              this.completeMessage = this.$t("mypage.memberLeave.error");
              this.$refs["delete-message-modal"].show();
              this.$refs["delete-confirm-modal"].hide();
            });
        }
      }
    },
    hideSaveMessageModal() {
      this.$refs["save-message-modal"].hide();
    },
    hideDeleteMessageModal() {
      this.$refs["delete-message-modal"].hide();
      this.$router.push({ name: "Drops" }).catch(() => {});
    },
  },
  filters: {
    currency: (value) => {
      if (!value) return "";
      return parseInt(value)
        .toFixed(0)
        .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    },
  },
};
</script>
