import ApiService from "@/services/api.service";
import logger from "@/logger";

// action types
export const GET_FAVORITEDS_BY_MEMBER = "getFavoritedsByMember"
export const ADD_FAVORITED = "addFavorited";
export const DELETE_FAVORITED = "deleteFavorited";
export const TOGGLE_FAVORITED = "toggleFavorited";
export const ADD_FAVORITED_STORE = "addFavoritedStore";
export const DELETE_FAVORITED_STORE = "deleteFavoritedStore";

// mutation types
export const SET_FAVORITED = "setFavorited";
export const SET_FAVORITEDS_BY_MEMBER = "setFavoritedsByMember"

const state = {
  errors: null,
  favoritedCollectionList: {
    content: [],
    first: true,
    last: false,
    number: 0,
    numberOfElements: 0,
    size: 0,
    sort: {
      orders: []
    },
    total: 0,
    totalElements: 0,
    totalPages: 1
  }
};

const getters = {};

const actions = {
  [GET_FAVORITEDS_BY_MEMBER](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("/threespace/v1/favoriteds/" + payload.idxMember, payload)
        .then(({ data }) => {
          context.commit(SET_FAVORITEDS_BY_MEMBER, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [ADD_FAVORITED](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("/threespace/v1/favoriteds", payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(response => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [DELETE_FAVORITED](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.delete("/threespace/v1/favoriteds", payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(response => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [TOGGLE_FAVORITED](context, payload) {
    context.commit(SET_FAVORITED, payload);
  },
  [ADD_FAVORITED_STORE](context, payload) {
    context.commit(ADD_FAVORITED_STORE, payload);
  },
  [DELETE_FAVORITED_STORE](context, payload) {
    context.commit(DELETE_FAVORITED_STORE, payload);
  },
};

const mutations = {
  [SET_FAVORITED](state, favoritedItem) {
    if (favoritedItem.favorited) {
      favoritedItem.favoritedCnt--
    } else {
      favoritedItem.favoritedCnt++
    }
    favoritedItem.favorited = !favoritedItem.favorited;
  },
  [SET_FAVORITEDS_BY_MEMBER](state, favoritedCollectionList) {
    state.favoritedCollectionList = favoritedCollectionList;
  },
  [ADD_FAVORITED_STORE](state, favoritedItem) {
    state.favoritedCollectionList.content.push(favoritedItem)
  },
  [DELETE_FAVORITED_STORE](state, favoritedItem) {
    state.favoritedCollectionList.content = state.favoritedCollectionList.content.filter(el => el.idx !== favoritedItem.idx);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
