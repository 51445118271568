<template>
  <CCard>
    <CCardBody>
      <CRow>
        <CCol class="mb-3"><h5>공통코드 관리</h5></CCol>
      </CRow>
      <CRow>
        <CCol class="pt-2">
          <table class="table">
            <tbody>
              <tr>
                <th class="border-bottom bg-light text-dark">검색</th>
                <td class="border-bottom">
                  <CSelect
                    class="col-2 float-left px-0 mb-0"
                    :options="searchOptions"
                    :value.sync="searchSelected"
                    @change="onChangeSearchOption"
                  />
                  <CInput
                    class="col-6 float-left mb-0"
                    placeholder="검색어를 입력해주세요."
                    v-model="externalParams.searchKeyword"
                    @input="onChangeSearchKeyword"
                    @keypress.enter.prevent="onClickSearch"
                  />
                  <CButton color="dark" class="text-white" @click="onClickSearch">
                    <CIcon name="cil-search" class="mr-2" />검색
                  </CButton>
                  <CButton
                    variant="outline"
                    color="secondary"
                    class="ml-2"
                    @click="onClickReset"
                  >
                    <CIcon name="cil-filter-x" />
                  </CButton>
                </td>
              </tr>
            </tbody>
          </table>
        </CCol>
      </CRow>
      <CRow>
        <CCol class="col-auto mr-auto align-self-center">
          총&nbsp;<strong>{{ codeTableItems.totalElements }}</strong>건&nbsp;({{ currentPage }}&nbsp;/&nbsp;{{ codeTableItems.totalPages }}&nbsp;페이지)
        </CCol>
        <CCol class="col-auto">
          <CSelect
            class="float-left mr-2 mb-2"
            :options="pageOptions"
            :value.sync="perPage"
            @change="onPaging"
          />
          <CButton color="dark" class="text-white" @click="onClickRegister">
            <CIcon name="cil-library-add" class="mr-2" />신규등록
          </CButton>
        </CCol>
      </CRow>
      <b-table
        id="codeTable"
        thead-class="text-center"
        :busy="isBusy"
        :items="codeTableItems.content"
        :fields="codeTableFields"
        :items-per-page="perPage"
        show-empty
        empty-text="조회된 내역이 없습니다."
      >
        <!-- NO -->
        <template #cell(index)="data">
          {{ data.index + 1 + (currentPage - 1) * perPage }}
        </template>

        <!-- 코드명 -->
        <template #cell(codeName)="data">
          <a
            class="text-info cursor-pointer"
            @click="onClickCodeName(data.item.idx)"
          >
            {{ data.item.codeName }}
          </a>
        </template>

        <!-- 사용여부 -->
        <template #cell(display)="data">
          {{ data.value === true ? "Y" : "N" }}
        </template>

        <!-- Actions -->
        <template v-slot:cell(actions)="data">
          <!--TODO
          <CButton
            variant="outline"
            color="dark"
            size="sm"
            @click="onClickDelete(data.item.idx)"
          >
            <CIcon name="cil-trash" />
          </CButton>
          -->
          <CButton
            variant="outline"
            color="dark"
            size="sm"
            class="mx-2"
            @click="onClickModify(data.item.idx)"
          >
            <CIcon name="cil-pencil" />
          </CButton>
        </template>
      </b-table>
      <b-pagination
        aria-controls="codeTable"
        align="center"
        hide-ellipsis
        limit="10"
        v-model="currentPage"
        :total-rows="codeTableItems.totalElements"
        :per-page="perPage"
        :current-page="currentPage"
        v-on:input="onPaging"
      ></b-pagination>
    </CCardBody>
  </CCard>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const codeHelper = createNamespacedHelpers("code");

export default {
  name: "CodeList",
  components: {},
  data: function() {
    return {
      isBusy: false,
      perPage: 10,
      pageOptions: [
        { value: 10, label: "10 개씩 보기" },
        { value: 20, label: "20 개씩 보기" },
        { value: 30, label: "30 개씩 보기" },
        { value: 50, label: "50 개씩 보기" }
      ],
      currentPage: 1,
      sort: [
        { id: "idx", desc: "desc" },
        { id: "updatedAt", desc: "desc" }
      ],
      sortBy: "",
      sortDesc: false,
      // 검색
      searchSelected: "",
      searchOptions: [
        { value: "", label: "전체" },
        { value: "keywordCode", label: "코드" },
        { value: "keywordCodeName", label: "코드명" },
        { value: "keywordCodeValue", label: "코드값" }
      ],
      externalParams: {
        searchKeyword: "",
        keywordCode: "",
        keywordCodeName: "",
        keywordCodeValue: ""
      }
    };
  },
  computed: {
    ...codeHelper.mapState(["codeTableItems", "codeTableFields"])
  },
  mounted() {
    this.requestCodeItems();
  },
  methods: {
    ...codeHelper.mapActions(["getCommonCodes"]),
    onPaging() {
      this.requestCodeItems();
    },
    onChangeSearchOption() {
      this.setSearchConditions(this.externalParams.searchKeyword);
    },
    onChangeSearchKeyword(keyword) {
      this.setSearchConditions(keyword);
    },
    setSearchConditions(keyword) {
      if (this.searchSelected === "") {
        this.externalParams.keywordCode = "";
        this.externalParams.keywordCodeName = "";
        this.externalParams.keywordCodeValue = "";
      } else if (this.searchSelected === "keywordCode") {
        this.externalParams.keywordCode = keyword;
        this.externalParams.keywordCodeName = "";
        this.externalParams.keywordCodeValue = "";
      } else if (this.searchSelected === "keywordCodeName") {
        this.externalParams.keywordCode = "";
        this.externalParams.keywordCodeName = keyword;
        this.externalParams.keywordCodeValue = "";
      } else if (this.searchSelected === "keywordCodeValue") {
        this.externalParams.keywordCode = "";
        this.externalParams.keywordCodeName = "";
        this.externalParams.keywordCodeValue = keyword;
      }
    },
    resetSearchConditions() {
      this.searchSelected = "";
      this.externalParams.searchKeyword = "";
      this.externalParams.keywordCode = "";
      this.externalParams.keywordCodeName = "";
      this.externalParams.keywordCodeValue = "";
    },
    onClickSearch() {
      this.requestCodeItems();
    },
    onClickReset() {
      this.resetSearchConditions();
      this.requestCodeItems();
    },
    requestCodeItems() {
      this.isBusy = true;
      this.getCommonCodes(
        this.getPagingParams(
          {
            page: this.currentPage - 1,
            pageSize: this.perPage,
            sorted: this.sort,
            filtered: []
          },
          this.externalParams
        )
      )
        .then(data => {
          this.isBusy = false;
        })
        .catch(error => {
          this.isBusy = false;
          this.$log.error(error);
        });
    },
    onClickRegister() {
      this.$router.push("/management/code-save/0");
    },
    onClickModify(idx) {
      this.$router.push("/management/code-save/" + idx);
    },
    onClickDelete(idx) {
      this.$log.info("click onClickDelete() idx:{}", idx);
    },
    onClickCodeName(idx) {
      this.$router.push("/management/code-detail/" + idx);
    },
    getPagingParams({ page, pageSize, sorted, filtered }, externalParams) {
      const params = {
        page,
        size: pageSize,
        ...externalParams
      };

      // sorting parameter
      let sorts = [];
      for (let i = 0, length = sorted.length; i < length; i++) {
        const sort = sorted[i];
        sorts.push(`${sort.id},${sort.desc ? "desc" : "asc"}`);
      }
      params["sort"] = sorts;

      // filtering parameter
      for (let i = 0, length = filtered.length; i < length; i++) {
        const filter = filtered[i];
        params[filter.id] = filter.value;
      }

      return params;
    }
  }
};
</script>
