<template>
  <div>
    <CCard>
      <CCardBody>
        <CRow>
          <CCol class="mb-3"><h5>작품 NFT 발행/전송</h5></CCol>
        </CRow>
        <div>
          <b-tabs
            active-nav-item-class="bg-info text-light font-weight-bold"
            content-class="mt-3"
            v-model="tabIndex"
            @input="onClickTab"
          >
            <b-tab title="Ethereum NFT">
              <CRow class="mt-2">
                <CCol class="col-8">
                  <table class="table" style="table-layout: fixed;">
                    <thead>
                      <tr>
                        <th class="col-2 text-center bg-gray-100 text-dark">
                          작품
                        </th>
                        <th class="col-2 text-center bg-gray-100 text-dark">
                          메타데이터 항목
                        </th>
                        <th class="col-8 text-center bg-gray-100 text-dark">
                          내용
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>작품명(영문) <span class="text-danger font-lg">*</span></th>
                        <td>name</td>
                        <td>
                          {{ productInfo.productNameEn }}
                          <template v-if="productInfo.productNameEn === undefined || productInfo.productNameEn === ''">
                            <span class="text-danger font-weight-bold">(정보없음)</span>
                          </template>
                        </td>
                      </tr>
                      <tr>
                        <th>작품 설명(영문) <span class="text-danger font-lg">*</span></th>
                        <td>description</td>
                        <td>
                          <span v-html="replaceNewLine(productInfo.productDescriptionEn)"></span>
                          <template v-if="productInfo.productMoreDescriptionEn !== undefined && productInfo.productMoreDescriptionEn !== ''">
                            <br/><br/><span v-html="replaceNewLine(productInfo.productMoreDescriptionEn)"></span>
                          </template>
                          <template v-if="productInfo.productDescriptionEn === undefined || productInfo.productDescriptionEn === ''">
                            <span class="text-danger font-weight-bold">(정보없음)</span>
                          </template>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          작품 이미지(포스터)<br/>/작품 영상 URL <span class="text-danger font-lg">*</span>
                        </th>
                        <td>image</td>
                        <td>
                          <b-form-radio-group
                            id="threespaceImageEthereum"
                            name="threespaceImageEthereum"
                            :options="productIpfsOptions"
                            v-model="productInfo.threespaceImage"
                            @change="onClickThreespaceIPFS('PRODUCT-IMAGE')"
                          />
                          <CRow class="bg-gray-100 mt-2 mr-1 py-2">
                            <CCol class="text-success">
                              {{ productInfo.threespaceImageUrl }}
                              <template v-if="productInfo.threespaceImageUrl === undefined || productInfo.threespaceImageUrl === null || productInfo.threespaceImageUrl === ''">
                                <span class="text-danger font-weight-bold">(정보없음)</span>
                              </template>
                            </CCol>
                          </CRow>
                          <CRow class="mt-2">
                            <CCol class="text-info">
                              <CIcon name="cil-info" class="text-info mr-1" />이미지 또는 영상이 카드에 바로 표시되도록 하는 경우 설정합니다.
                            </CCol>
                          </CRow>
                        </td>
                      </tr>
                      <tr>
                        <th>작품 영상 URL</th>
                        <td>animation_url</td>
                        <td>
                          <b-form-radio-group
                            id="threespaceAnimationEthereum"
                            name="threespaceAnimationEthereum"
                            :options="productVideoIpfsOptions"
                            v-model="productInfo.threespaceAnimation"
                            @change="onClickThreespaceIPFS('PRODUCT-FILE')"
                          />
                          <CRow class="bg-gray-100 mt-2 mr-1 py-2">
                            <CCol class="text-success">{{ productInfo.threespaceAnimationUrl || "(정보없음)" }}</CCol>
                          </CRow>
                        </td>
                      </tr>
                      <tr>
                        <th>속성</th>
                        <td>attributes</td>
                        <td>
                          <template v-for="(tsAttr, index) in productInfo.threespaceAttributes">
                            <CRow :key="'tsAttr' + index">
                              <CCol md="3" class="pr-0">
                                <template v-if="index === 0">trait_type</template>
                                <CInput
                                  class="mb-0"
                                  placeholder="속성 이름"
                                  v-model="tsAttr.traitType"
                                />
                              </CCol>
                              <CCol class="pr-0">
                                <template v-if="index === 0">value</template>
                                <CTextarea
                                  class="mb-0"
                                  placeholder="속성 값"
                                  rows="1"
                                  v-model="tsAttr.value"
                                />
                              </CCol>
                              <CCol md="1" class="justify-content-end pl-0">
                                <template v-if="index === 0"><br /></template>
                                <CButton
                                  variant="ghost"
                                  color="right"
                                  @click="onClickThreespaceAttributeDelete(tsAttr.idx)"
                                >
                                  <CIcon name="cil-x" />
                                </CButton>
                              </CCol>
                            </CRow>
                          </template>
                          <CRow class="mt-1">
                            <CCol>
                              <CButton
                                color="light"
                                @click="onClickThreespaceAttributeAdd"
                              >
                                <CIcon name="cil-plus" class="mr-2" />속성추가
                              </CButton>
                            </CCol>
                          </CRow>
                        </td>
                      </tr>
                      <tr>
                        <th>메타데이터 미리보기<br />(편집/다운로드)</th>
                        <td colspan="2">
                          <div>
                            <CIcon name="cil-info" class="text-info mr-1" />
                            <span class="text-info">위 입력한 정보로 메타데이터(json)를 생성 및 파일로 저장 할 수 있습니다.</span>
                          </div>
                          <div class="text-right mt-1">
                            <CButton
                              color="light"
                              class="ml-2"
                              @click="onClickThreespaceMetadataPreview"
                            >
                              <CIcon name="cil-loop-circular" class="mr-2" />메타데이터 생성
                            </CButton>
                            <CButton
                              color="light"
                              class="ml-2"
                              @click="onClickMetadataDownload"
                            >
                              <CIcon name="cil-data-transfer-down" class="mr-2" />다운로드
                            </CButton>
                          </div>
                          <div class="mt-2">
                            <VueCodeMirror
                              ref="threespaceCodeMirror"
                              :options="editorOptions"
                              v-model="threespaceMetadata"
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th class="border-bottom">
                          메타데이터 URL <span class="text-danger font-lg">*</span><br />(IPFS URL)
                        </th>
                        <td colspan="2" class="border-bottom">
                          <CIcon name="cil-info" class="text-danger mr-1" />
                          <span class="text-danger">NFT 발행을 위한 필수정보입니다.</span>
                          <CTextarea
                            class="mt-2 mb-0"
                            placeholder="메타데이터 URL을 입력하세요."
                            rows="3"
                            v-model="productInfo.threespaceMetadataUrl"
                          />
                          <div class="mt-1">
                            <CIcon name="cil-star" class="text-info mr-1" />
                            <span class="text-info">IPFS 도메인 "https://ipfs.io/"는 "https://ipfs.3space.art/"로 자동 변경되어 저장됩니다.</span>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </CCol>
                <CCol class="col-4">
                  <div class="font-weight-bold">작품 이미지</div>
                  <div class="mt-2">
                    <template v-if="productInfo.threespaceImageUrl !== null && productInfo.threespaceImageUrl !== ''">
                      <template v-if="isResourceFileImage(productInfo.threespaceImageUrl)">
                        <b-img-lazy
                          fluid
                          width="300px"
                          height="auto"
                          :src="productInfo.threespaceImageUrl"
                        />
                      </template>
                      <template v-else>
                        <video
                          type="video/mp4"
                          width="300px"
                          height="auto"
                          :src="productInfo.threespaceImageUrl"
                          controls
                          playsinline
                        ></video>
                      </template>
                    </template>
                    <template v-else>(정보없음)</template>
                  </div>
                  <div class="font-weight-bold mt-4">
                    작품 영상
                  </div>
                  <div class="mt-2">
                    <template v-if="productInfo.threespaceAnimationUrl !== null && productInfo.threespaceAnimationUrl !== ''">
                      <video
                        type="video/mp4"
                        width="300px"
                        height="auto"
                        :src="productInfo.threespaceAnimationUrl"
                        controls
                        playsinline
                      ></video>
                    </template>
                    <template v-else>(정보없음)</template>
                  </div>
                  <div class="font-weight-bold mt-4">NFT 이력</div>
                  <div class="mt-2">
                    <template v-if="productInfo.threespaceReceipts !== undefined && productInfo.threespaceReceipts.length > 0">
                      <CToaster
                        v-for="(item, key) in productInfo.threespaceReceipts"
                        position="static"
                        :key="'items' + key"
                        class="px-0"
                      >
                        <CToast :key="'item' + item.idx" :show="true">
                          <table>
                            <tr>
                              <td colspan="2">
                                <b :class="{ 'text-danger': item.tokenId === 0 }">
                                  {{ item.nftType }}(SPACE) #{{ item.tokenId }} / {{ item.state }}
                                </b>
                                {{ item.createdAt }}
                              </td>
                            </tr>
                            <tr>
                              <th>From</th>
                              <td class="pl-1">
                                {{ item.fromAddr }}
                              </td>
                            </tr>
                            <tr>
                              <th>To</th>
                              <td class="pl-1">{{ item.toAddr }}</td>
                            </tr>
                            <tr>
                              <td colspan="2">
                                <b class="mr-2">Etherscan</b>
                                <a :href="item.klaytnScopeUrl" target="_blank">
                                  <CIcon name="cil-external-link" />
                                </a>
                              </td>
                            </tr>
                          </table>
                        </CToast>
                      </CToaster>
                      <div class="mt-3 text-danger">
                        <CIcon name="cil-info" class="mr-2" />반드시 이더리움 주소를 확인후 사용자를 선택해주세요.
                      </div>
                      <div class="mt-3">
                        <b-form-radio-group
                          id="ethereumNftTransferState"
                          name="ethereumNftTransferState"
                          :options="transferOptions"
                          v-model="nftInfo.state"
                        />
                      </div>
                      <CInput
                        placeholder="NFT 수신자를 선택해주세요."
                        :lazy="false"
                        :readonly="true"
                        :value.sync="$v.nftInfo.nickname.$model"
                        :isValid="checkIfValid('nickname')"
                        v-model="nftInfo.nickname"
                        invalidFeedback="필수 정보입니다."
                        class="mt-2"
                      >
                        <template #prepend>
                          <CButton size="sm" color="primary" @click="onClickMemberSelect">
                            <CIcon name="cil-magnifying-glass" />
                          </CButton>
                        </template>
                        <template #append>
                          <CButton size="sm" color="info" @click="onClickTransferEthereumNFT">
                            <CIcon name="cil-send" class="mr-1" /><span class="font-md">NFT 전송</span>
                          </CButton>
                        </template>
                      </CInput>
                    </template>
                    <template v-else>(조회된 내역이 없습니다)</template>
                  </div>
                </CCol>
              </CRow>
              <CRow>
                <CCol class="text-center my-4">
                  <CButton color="light" @click="onClickCancel" class="mr-2">
                    <CIcon name="cil-arrow-circle-left" class="mr-2" />목록
                  </CButton>
                  <template v-if="productInfo.receipts === undefined || productInfo.receipts === null || productInfo.receipts.length === 0">
                    <CButton color="info" class="mr-2 text-white" @click="onClickSaveEthereumNFT">
                      <CIcon name="cil-save" class="mr-2" />저장
                    </CButton>
                    <CButton color="primary" class="text-white" @click="onClickMintEthereumNFT">
                      <CIcon name="cil-loop-circular" class="mr-2" />저장/NFT 발행
                    </CButton>
                  </template>
                </CCol>
              </CRow>
            </b-tab>
            <b-tab title="Polygon NFT">
              <CRow class="mt-2">
                <CCol class="col-8">
                  <table class="table" style="table-layout: fixed;">
                    <thead>
                    <tr>
                      <th class="col-2 text-center bg-gray-100 text-dark">
                        작품
                      </th>
                      <th class="col-2 text-center bg-gray-100 text-dark">
                        메타데이터 항목
                      </th>
                      <th class="col-8 text-center bg-gray-100 text-dark">
                        내용
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <th>작품명(영문) <span class="text-danger font-lg">*</span></th>
                      <td>name</td>
                      <td>
                        {{ productInfo.productNameEn }}
                        <template v-if="productInfo.productNameEn === undefined || productInfo.productNameEn === ''">
                          <span class="text-danger font-weight-bold">(정보없음)</span>
                        </template>
                      </td>
                    </tr>
                    <tr>
                      <th>작품 설명(영문) <span class="text-danger font-lg">*</span></th>
                      <td>description</td>
                      <td>
                        <span v-html="replaceNewLine(productInfo.productDescriptionEn)"></span>
                        <template v-if="productInfo.productMoreDescriptionEn !== undefined && productInfo.productMoreDescriptionEn !== ''">
                          <br/><br/><span v-html="replaceNewLine(productInfo.productMoreDescriptionEn)"></span>
                        </template>
                        <template v-if="productInfo.productDescriptionEn === undefined || productInfo.productDescriptionEn === ''">
                          <span class="text-danger font-weight-bold">(정보없음)</span>
                        </template>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        작품 이미지(포스터)<br/>/작품 영상 URL <span class="text-danger font-lg">*</span>
                      </th>
                      <td>image</td>
                      <td>
                        <b-form-radio-group
                          id="polygonImageEthereum"
                          name="polygonImageEthereum"
                          :options="productIpfsOptions"
                          v-model="productInfo.threespaceImage"
                          @change="onClickThreespaceIPFS('PRODUCT-IMAGE')"
                        />
                        <CRow class="bg-gray-100 mt-2 mr-1 py-2">
                          <CCol class="text-success">
                            {{ productInfo.threespaceImageUrl }}
                            <template v-if="productInfo.threespaceImageUrl === undefined || productInfo.threespaceImageUrl === null || productInfo.threespaceImageUrl === ''">
                              <span class="text-danger font-weight-bold">(정보없음)</span>
                            </template>
                          </CCol>
                        </CRow>
                        <CRow class="mt-2">
                          <CCol class="text-info">
                            <CIcon name="cil-info" class="text-info mr-1" />이미지 또는 영상이 카드에 바로 표시되도록 하는 경우 설정합니다.
                          </CCol>
                        </CRow>
                      </td>
                    </tr>
                    <tr>
                      <th>작품 영상 URL</th>
                      <td>animation_url</td>
                      <td>
                        <b-form-radio-group
                          id="polygonAnimationEthereum"
                          name="polygonAnimationEthereum"
                          :options="productVideoIpfsOptions"
                          v-model="productInfo.threespaceAnimation"
                          @change="onClickThreespaceIPFS('PRODUCT-FILE')"
                        />
                        <CRow class="bg-gray-100 mt-2 mr-1 py-2">
                          <CCol class="text-success">{{ productInfo.threespaceAnimationUrl || "(정보없음)" }}</CCol>
                        </CRow>
                      </td>
                    </tr>
                    <tr>
                      <th>속성</th>
                      <td>attributes</td>
                      <td>
                        <template v-for="(tsAttr, index) in productInfo.threespaceAttributes">
                          <CRow :key="'tsAttr' + index">
                            <CCol md="3" class="pr-0">
                              <template v-if="index === 0">trait_type</template>
                              <CInput
                                class="mb-0"
                                placeholder="속성 이름"
                                v-model="tsAttr.traitType"
                              />
                            </CCol>
                            <CCol class="pr-0">
                              <template v-if="index === 0">value</template>
                              <CTextarea
                                class="mb-0"
                                placeholder="속성 값"
                                rows="1"
                                v-model="tsAttr.value"
                              />
                            </CCol>
                            <CCol md="1" class="justify-content-end pl-0">
                              <template v-if="index === 0"><br /></template>
                              <CButton
                                variant="ghost"
                                color="right"
                                @click="onClickThreespaceAttributeDelete(tsAttr.idx)"
                              >
                                <CIcon name="cil-x" />
                              </CButton>
                            </CCol>
                          </CRow>
                        </template>
                        <CRow class="mt-1">
                          <CCol>
                            <CButton
                              color="light"
                              @click="onClickThreespaceAttributeAdd"
                            >
                              <CIcon name="cil-plus" class="mr-2" />속성추가
                            </CButton>
                          </CCol>
                        </CRow>
                      </td>
                    </tr>
                    <tr>
                      <th>메타데이터 미리보기<br />(편집/다운로드)</th>
                      <td colspan="2">
                        <div>
                          <CIcon name="cil-info" class="text-info mr-1" />
                          <span class="text-info">위 입력한 정보로 메타데이터(json)를 생성 및 파일로 저장 할 수 있습니다.</span>
                        </div>
                        <div class="text-right mt-1">
                          <CButton
                            color="light"
                            class="ml-2"
                            @click="onClickThreespaceMetadataPreview"
                          >
                            <CIcon name="cil-loop-circular" class="mr-2" />메타데이터 생성
                          </CButton>
                          <CButton
                            color="light"
                            class="ml-2"
                            @click="onClickMetadataDownload"
                          >
                            <CIcon name="cil-data-transfer-down" class="mr-2" />다운로드
                          </CButton>
                        </div>
                        <div class="mt-2">
                          <VueCodeMirror
                            ref="threespaceCodeMirror"
                            :options="editorOptions"
                            v-model="threespaceMetadata"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th class="border-bottom">
                        메타데이터 URL <span class="text-danger font-lg">*</span><br />(IPFS URL)
                      </th>
                      <td colspan="2" class="border-bottom">
                        <CIcon name="cil-info" class="text-danger mr-1" />
                        <span class="text-danger">NFT 발행을 위한 필수정보입니다.</span>
                        <CTextarea
                          class="mt-2 mb-0"
                          placeholder="메타데이터 URL을 입력하세요."
                          rows="3"
                          v-model="productInfo.threespaceMetadataUrl"
                        />
                        <div class="mt-1">
                          <CIcon name="cil-star" class="text-info mr-1" />
                          <span class="text-info">IPFS 도메인 "https://ipfs.io/"는 "https://ipfs.3space.art/"로 자동 변경되어 저장됩니다.</span>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </CCol>
                <CCol class="col-4">
                  <div class="font-weight-bold">작품 이미지</div>
                  <div class="mt-2">
                    <template v-if="productInfo.threespaceImageUrl !== null && productInfo.threespaceImageUrl !== ''">
                      <template v-if="isResourceFileImage(productInfo.threespaceImageUrl)">
                        <b-img-lazy
                          fluid
                          width="300px"
                          height="auto"
                          :src="productInfo.threespaceImageUrl"
                        />
                      </template>
                      <template v-else>
                        <video
                          type="video/mp4"
                          width="300px"
                          height="auto"
                          :src="productInfo.threespaceImageUrl"
                          controls
                          playsinline
                        ></video>
                      </template>
                    </template>
                    <template v-else>(정보없음)</template>
                  </div>
                  <div class="font-weight-bold mt-4">
                    작품 영상
                  </div>
                  <div class="mt-2">
                    <template v-if="productInfo.threespaceAnimationUrl !== null && productInfo.threespaceAnimationUrl !== ''">
                      <video
                        type="video/mp4"
                        width="300px"
                        height="auto"
                        :src="productInfo.threespaceAnimationUrl"
                        controls
                        playsinline
                      ></video>
                    </template>
                    <template v-else>(정보없음)</template>
                  </div>
                  <div class="font-weight-bold mt-4">NFT 이력</div>
                  <div class="mt-2">
                    <template v-if="productInfo.threespaceReceipts !== undefined && productInfo.threespaceReceipts.length > 0">
                      <CToaster
                        v-for="(item, key) in productInfo.threespaceReceipts"
                        position="static"
                        :key="'items' + key"
                        class="px-0"
                      >
                        <CToast :key="'item' + item.idx" :show="true">
                          <table>
                            <tr>
                              <td colspan="2">
                                <b :class="{ 'text-danger': item.tokenId === 0 }">
                                  {{ item.nftType }}(SPACE) #{{ item.tokenId }} / {{ item.state }}
                                </b>
                                {{ item.createdAt }}
                              </td>
                            </tr>
                            <tr>
                              <th>From</th>
                              <td class="pl-1">
                                {{ item.fromAddr }}
                              </td>
                            </tr>
                            <tr>
                              <th>To</th>
                              <td class="pl-1">{{ item.toAddr }}</td>
                            </tr>
                            <tr>
                              <td colspan="2">
                                <b class="mr-2">Polygonscan</b>
                                <a :href="item.klaytnScopeUrl" target="_blank">
                                  <CIcon name="cil-external-link" />
                                </a>
                              </td>
                            </tr>
                          </table>
                        </CToast>
                      </CToaster>
                      <div class="mt-3 text-danger">
                        <CIcon name="cil-info" class="mr-2" />반드시 폴리곤 주소를 확인후 사용자를 선택해주세요.
                      </div>
                      <div class="mt-3">
                        <b-form-radio-group
                          id="polygonNftTransferState"
                          name="polygonNftTransferState"
                          :options="transferOptions"
                          v-model="nftInfo.state"
                        />
                      </div>
                      <CInput
                        placeholder="NFT 수신자를 선택해주세요."
                        :lazy="false"
                        :readonly="true"
                        :value.sync="$v.nftInfo.nickname.$model"
                        :isValid="checkIfValid('nickname')"
                        v-model="nftInfo.nickname"
                        invalidFeedback="필수 정보입니다."
                        class="mt-2"
                      >
                        <template #prepend>
                          <CButton size="sm" color="primary" @click="onClickMemberSelect">
                            <CIcon name="cil-magnifying-glass" />
                          </CButton>
                        </template>
                        <template #append>
                          <CButton size="sm" color="info" @click="onClickTransferPolygonNFT">
                            <CIcon name="cil-send" class="mr-1" /><span class="font-md">NFT 전송</span>
                          </CButton>
                        </template>
                      </CInput>
                    </template>
                    <template v-else>(조회된 내역이 없습니다)</template>
                  </div>
                </CCol>
              </CRow>
              <CRow>
                <CCol class="text-center my-4">
                  <CButton color="light" @click="onClickCancel" class="mr-2">
                    <CIcon name="cil-arrow-circle-left" class="mr-2" />목록
                  </CButton>
                  <template v-if="productInfo.receipts === undefined || productInfo.receipts === null || productInfo.receipts.length === 0">
                    <CButton color="info" class="mr-2 text-white" @click="onClickSavePolygonNFT">
                      <CIcon name="cil-save" class="mr-2" />저장
                    </CButton>
                    <CButton color="primary" class="text-white" @click="onClickMintPolygonNFT">
                      <CIcon name="cil-loop-circular" class="mr-2" />저장/NFT 발행
                    </CButton>
                  </template>
                </CCol>
              </CRow>
            </b-tab>
            <b-tab title="Klip NFT">
              <CRow class="pt-3">
                <CCol>
                  <CIcon name="cil-info" /> Klip Partners를 통해 NFT를 발행합니다. 입력한 정보로 메타데이터를 자동 생성합니다.
                </CCol>
              </CRow>
              <CRow class="mt-2">
                <CCol class="col-8">
                  <table class="table" style="table-layout: fixed;">
                    <thead>
                      <tr>
                        <th class="col-2 text-center bg-gray-100 text-dark">
                          작품
                        </th>
                        <th class="col-2 text-center bg-gray-100 text-dark">
                          메타데이터 항목
                        </th>
                        <th class="col-8 text-center bg-gray-100 text-dark">
                          내용
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>
                          카드 이름(작품명) <span class="text-danger font-lg">*</span>
                        </th>
                        <td>name</td>
                        <td>
                          {{ productInfo.productNameEn }}
                          <template v-if="productInfo.productNameEn === undefined || productInfo.productNameEn === ''">
                            <span class="text-danger font-weight-bold">(정보없음)</span>
                          </template>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          카드 설명(작품 설명) <span class="text-danger font-lg">*</span>
                        </th>
                        <td>description</td>
                        <td>
                          <span v-html="replaceNewLine(productInfo.productDescriptionEn)"></span>
                          <template v-if="productInfo.productMoreDescriptionEn !== undefined && productInfo.productMoreDescriptionEn !== ''">
                            <br/><br/><span v-html="replaceNewLine(productInfo.productMoreDescriptionEn)"></span>
                          </template>
                          <template v-if="productInfo.productDescriptionEn === undefined || productInfo.productDescriptionEn === ''">
                            <span class="text-danger font-weight-bold">(정보없음)</span>
                          </template>
                        </td>
                      </tr>
                      <tr>
                        <th rowspan="3">카드 이미지</th>
                        <td>image</td>
                        <td>
                          <div class="font-weight-bold">
                            대표이미지 <span class="text-danger font-lg">*</span>
                          </div>
                          <div class="mt-2 text-success">
                            {{ productInfo.klipImage }}
                            <template v-if="productInfo.klipImage === undefined || productInfo.klipImage === null || productInfo.klipImage === ''">
                              <span class="text-danger font-weight-bold">(정보없음)</span>
                            </template>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>background_color</td>
                        <td>
                          <div class="font-weight-bold">
                            배경색 선택 <span class="text-danger font-lg">*</span>
                          </div>
                          <div class="mt-2">
                            <b-form-radio-group
                              id="backgroundColor"
                              name="backgroundColor"
                              :options="backgroundColorOptions"
                              v-model="productInfo.klipBackgroundColor"
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>animation_url</td>
                        <td>
                          <div class="font-weight-bold">
                            NFT 리소스(작품 영상)
                          </div>
                          <div class="mt-2 text-success">
                            {{ productInfo.klipAnimationUrl || "(정보없음)" }}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th rowspan="3">
                          그룹 <span class="text-danger font-lg">*</span>
                        </th>
                        <!--
                        <td></td>
                        <td>
                          <b-form-radio-group
                            id="klipGroup"
                            name="klipGroup"
                            class="float-left"
                            :options="groupOptions"
                            v-model="productInfo.klipGroup"
                          />
                          <CButton
                            color="light"
                            class="float-right"
                            v-if="productInfo.klipGroup === 'true'"
                            @click="onClickArtistGroupSelect"
                          >
                            <CIcon name="cil-search" class="mr-2" />그룹선택
                          </CButton>
                        </td>
                        -->
                      </tr>
                      <tr>
                        <td>group_name</td>
                        <td>
                          <div class="font-weight-bold">
                            그룹 이름
                            <CButton
                              color="light"
                              class="float-right mb-1"
                              @click="onClickArtistGroupSelect"
                            >
                              <CIcon name="cil-search" class="mr-2" />그룹선택
                            </CButton>
                          </div>
                          <div class="mt-2">
                            <CInput
                              class="mb-0"
                              placeholder="그룹이름을 입력해주세요."
                              :readonly="true"
                              v-model="productInfo.klipGroupName"
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>group_icon</td>
                        <td>
                          <div class="font-weight-bold">
                            그룹 대표이미지
                          </div>
                          <div class="mt-2">
                            <CInput
                              class="mb-0"
                              placeholder="그룹 대표이미지를 입력해주세요."
                              :readonly="true"
                              v-model="productInfo.klipGroupIcon"
                            />
                          </div>
                          <div class="mt-2 text-danger">{{ groupIconMessage }}</div>
                        </td>
                      </tr>
                      <tr>
                        <th>속성 <span class="text-danger font-lg">*</span></th>
                        <td>attributes</td>
                        <td>
                          <template v-for="(klipAttr, index) in productInfo.klipAttributes">
                            <CRow :key="'klipAttr' + index">
                              <CCol md="3" class="pr-0">
                                <template v-if="index === 0">trait_type</template>
                                <CInput
                                  class="mb-0"
                                  placeholder="속성 이름"
                                  v-model="klipAttr.traitType"
                                />
                              </CCol>
                              <CCol class="pr-0">
                                <template v-if="index === 0">value</template>
                                <CTextarea
                                  class="mb-0"
                                  placeholder="속성 값"
                                  rows="1"
                                  v-model="klipAttr.value"
                                />
                              </CCol>
                              <CCol md="1" class="justify-content-end pl-0">
                                <template v-if="index === 0"><br /></template>
                                <CButton
                                  variant="ghost"
                                  color="right"
                                  @click="onClickKlipAttributeDelete(klipAttr.idx)"
                                >
                                  <CIcon name="cil-x" />
                                </CButton>
                              </CCol>
                            </CRow>
                          </template>
                          <CRow class="mt-1">
                            <CCol>
                              <CButton
                                color="light"
                                @click="onClickKlipAttributeAdd"
                              >
                                <CIcon name="cil-plus" class="mr-2" />속성추가
                              </CButton>
                            </CCol>
                          </CRow>
                        </td>
                      </tr>
                      <tr>
                        <th>외부링크(선택)</th>
                        <td>external_link</td>
                        <td>
                          <CInput
                            class="mb-0"
                            placeholder="외부링크를 입력해주세요."
                            v-model="productInfo.klipExternalLink"
                          />
                        </td>
                      </tr>
                      <!--
                      <tr>
                        <th>태그(선택)</th>
                        <td>hashtags</td>
                        <td><span class="text-danger">개발중</span></td>
                      </tr>
                      -->
                      <tr>
                        <th>전송 가능 여부</th>
                        <td>sendable</td>
                        <td>
                          <b-form-radio-group
                            id="klipSendable"
                            name="klipSendable"
                            :options="yesOrNoOptions"
                            v-model="productInfo.klipSendable"
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>친구에게만 전송</th>
                        <td>send_friend_only</td>
                        <td>
                          <b-form-radio-group
                            id="klipSendFriendOnly"
                            name="klipSendFriendOnly"
                            :options="yesOrNoOptions"
                            v-model="productInfo.klipSendFriendOnly"
                          />
                        </td>
                      </tr>
                      <tr>
                        <th class="border-bottom">
                          메타데이터 미리보기
                        </th>
                        <td colspan="2" class="border-bottom">
                          <div class="text-right">
                            <CIcon name="cil-info" class="text-info mr-1" />
                            <span class="text-info">위 입력한 정보로 메타데이터(json)를 확인할 수 있습니다.</span>
                            <CButton
                              color="light"
                              class="ml-2"
                              @click="onClickKlipMetadataPreview"
                            >
                              <CIcon
                                name="cil-loop-circular"
                                class="mr-2"
                              />메타데이터 보기
                            </CButton>
                          </div>
                          <div class="mt-2">
                            <VueCodeMirror
                              ref="klipCodeMirror"
                              :options="editorOptions"
                              v-model="klipMetadata"
                            />
                          </div>
                          <div class="mt-2">
                            <CIcon name="cil-info" class="text-info mr-1" />
                            <span class="text-info">Klip 메타데이터는 입력된 정보를 기반으로 Klip API를 통해 자동 생성됩니다. (위 정보는 참고용)</span>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </CCol>
                <CCol class="col-4">
                  <div class="font-weight-bold">대표이미지</div>
                  <div class="mt-2">
                    <template v-if="productInfo.klipImage !== undefined && productInfo.klipImage !== null && productInfo.klipImage !== ''">
                      <b-img-lazy
                        fluid
                        width="300px"
                        height="auto"
                        :src="productInfo.klipImage"
                      />
                    </template>
                    <template v-else>(정보없음)</template>
                  </div>
                  <div class="font-weight-bold mt-4">
                    NFT 리소스(작품 영상)
                  </div>
                  <div class="mt-2">
                    <template v-if="productInfo.klipAnimationUrl !== undefined && productInfo.klipAnimationUrl !== null && productInfo.klipAnimationUrl !== ''">
                      <video
                        type="video/mp4"
                        width="300px"
                        height="auto"
                        :src="productInfo.klipAnimationUrl"
                        controls
                        playsinline
                      ></video>
                    </template>
                    <template v-else>(정보없음)</template>
                  </div>
                  <div class="font-weight-bold mt-4">NFT 이력</div>
                  <div class="mt-2">
                    <template
                      v-if="
                        productInfo.klipReceipts !== undefined &&
                          productInfo.klipReceipts.length > 0
                      "
                    >
                      <CToaster
                        v-for="(item, key) in productInfo.klipReceipts"
                        position="static"
                        :key="'items' + key"
                        class="px-0"
                      >
                        <CToast :key="'item' + item.idx" :show="true">
                          <table>
                            <tr>
                              <td colspan="2">
                                <b>
                                  {{ item.nftType }}(SPACE) #{{ item.tokenId }} / {{ item.state }}
                                </b>
                                {{ item.createdAt }}
                              </td>
                            </tr>
                            <tr>
                              <th>From</th>
                              <td class="pl-1">
                                {{ item.fromAddr }}
                              </td>
                            </tr>
                            <tr>
                              <th>To</th>
                              <td class="pl-1">{{ item.toAddr }}</td>
                            </tr>
                            <tr>
                              <td colspan="2">
                                <b class="mr-2">Klaytnscope</b>
                                <a :href="item.klaytnScopeUrl" target="_blank">
                                  <CIcon name="cil-external-link" />
                                </a>
                              </td>
                            </tr>
                          </table>
                        </CToast>
                      </CToaster>
                      <div class="mt-3 text-info">
                        <CIcon name="cil-info" class="mr-2" />NFT는 관리자가 소유하고 있는 경우에만 전송할 수 있습니다.<br />
                        <CIcon name="cil-info" class="mr-2" />수신자의 카이아 주소는 필수정보입니다.
                      </div>
                      <CInput
                        placeholder="NFT 수신자를 선택해주세요."
                        :lazy="false"
                        :readonly="true"
                        :value.sync="$v.nftInfo.nickname.$model"
                        :isValid="checkIfValid('nickname')"
                        v-model="nftInfo.nickname"
                        invalidFeedback="필수 정보입니다."
                        class="mt-2"
                      >
                        <template #prepend>
                          <CButton size="sm" color="primary" @click="onClickMemberSelect">
                            <CIcon name="cil-magnifying-glass" />
                          </CButton>
                        </template>
                        <template #append>
                          <CButton size="sm" color="info" @click="onClickTransferKlipNFT">
                            <CIcon name="cil-send" class="mr-1" /><span class="font-md">NFT 전송</span>
                          </CButton>
                        </template>
                      </CInput>
                      <div class="mt-5">
                        <b-form-checkbox
                          id="enableBurnKlipNFT"
                          name="enableBurnKlipNFT"
                          value="true"
                          unchecked-value="false"
                          v-model="enableBurnKlipNFT"
                          class="text-danger"
                        >
                          NFT는 관리자가 소유하고 있는 경우에만 소각할 수 있습니다.
                        </b-form-checkbox>
                        <CButton
                          color="danger"
                          :disabled="enableBurnKlipNFT === 'false'"
                          @click="onClickBurnKlipNFT"
                          class="mt-2"
                        >
                          <CIcon name="cil-burn" class="mr-1" />NFT 소각
                        </CButton>
                      </div>
                    </template>
                    <template v-else>(조회된 내역이 없습니다)</template>
                  </div>
                </CCol>
              </CRow>
              <CRow>
                <CCol class="text-center my-4">
                  <CButton color="light" @click="onClickCancel" class="mr-2">
                    <CIcon name="cil-arrow-circle-left" class="mr-2" />목록
                  </CButton>
                  <template v-if="productInfo.receipts === undefined || productInfo.receipts === null || productInfo.receipts.length === 0">
                    <CButton color="info" class="mr-2 text-white" @click="onClickSaveKlipCard">
                      <CIcon name="cil-save" class="mr-2" />저장
                    </CButton>
                    <CButton color="primary" class="text-white" @click="onClickRequestMintKlipNFT">
                      <CIcon name="cil-loop-circular" class="mr-2" />저장/NFT 발행
                    </CButton>
                  </template>
                </CCol>
              </CRow>
            </b-tab>
            <b-tab title="*Klaytn NFT(Test)">
              <CRow class="mt-2">
                <CCol class="col-8">
                  <table class="table" style="table-layout: fixed;">
                    <thead>
                    <tr>
                      <th class="col-2 text-center bg-gray-100 text-dark">
                        작품
                      </th>
                      <th class="col-2 text-center bg-gray-100 text-dark">
                        메타데이터 항목
                      </th>
                      <th class="col-8 text-center bg-gray-100 text-dark">
                        내용
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <th>작품명(영문) <span class="text-danger font-lg">*</span></th>
                      <td>name</td>
                      <td>
                        {{ productInfo.productNameEn }}
                        <template v-if="productInfo.productNameEn === undefined || productInfo.productNameEn === ''">
                          <span class="text-danger font-weight-bold">(정보없음)</span>
                        </template>
                      </td>
                    </tr>
                    <tr>
                      <th>작품 설명(영문) <span class="text-danger font-lg">*</span></th>
                      <td>description</td>
                      <td>
                        <span v-html="replaceNewLine(productInfo.productDescriptionEn)"></span>
                        <template v-if="productInfo.productMoreDescriptionEn !== undefined && productInfo.productMoreDescriptionEn !== ''">
                          <br/><br/><span v-html="replaceNewLine(productInfo.productMoreDescriptionEn)"></span>
                        </template>
                        <template v-if="productInfo.productDescriptionEn === undefined || productInfo.productDescriptionEn === ''">
                          <span class="text-danger font-weight-bold">(정보없음)</span>
                        </template>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        작품 이미지(포스터)<br/>/작품 영상 URL <span class="text-danger font-lg">*</span>
                      </th>
                      <td>image</td>
                      <td>
                        <b-form-radio-group
                          id="threespaceImageKlaytn"
                          name="threespaceImageKlaytn"
                          :options="productIpfsOptions"
                          v-model="productInfo.threespaceImage"
                          @change="onClickThreespaceIPFS('PRODUCT-IMAGE')"
                        />
                        <CRow class="bg-gray-100 mt-2 mr-1 py-2">
                          <CCol class="text-success">
                            {{ productInfo.threespaceImageUrl }}
                            <template v-if="productInfo.threespaceImageUrl === undefined || productInfo.threespaceImageUrl === null || productInfo.threespaceImageUrl === ''">
                              <span class="text-danger font-weight-bold">(정보없음)</span>
                            </template>
                          </CCol>
                        </CRow>
                        <CRow class="mt-2">
                          <CCol class="text-info">
                            <CIcon name="cil-info" class="text-info mr-1" />이미지 또는 영상이 카드에 바로 표시되도록 하는 경우 설정합니다.
                          </CCol>
                        </CRow>
                      </td>
                    </tr>
                    <tr>
                      <th>작품 영상 URL</th>
                      <td>animation_url</td>
                      <td>
                        <b-form-radio-group
                          id="threespaceAnimationKlaytn"
                          name="threespaceAnimationKlaytn"
                          :options="productVideoIpfsOptions"
                          v-model="productInfo.threespaceAnimation"
                          @change="onClickThreespaceIPFS('PRODUCT-FILE')"
                        />
                        <CRow class="bg-gray-100 mt-2 mr-1 py-2">
                          <CCol class="text-success">{{ productInfo.threespaceAnimationUrl || "(정보없음)" }}</CCol>
                        </CRow>
                      </td>
                    </tr>
                    <tr>
                      <th>속성</th>
                      <td>attributes</td>
                      <td>
                        <template v-for="(tsAttr, index) in productInfo.threespaceAttributes">
                          <CRow :key="'tsAttr' + index">
                            <CCol md="3" class="pr-0">
                              <template v-if="index === 0">trait_type</template>
                              <CInput
                                class="mb-0"
                                placeholder="속성 이름"
                                v-model="tsAttr.traitType"
                              />
                            </CCol>
                            <CCol class="pr-0">
                              <template v-if="index === 0">value</template>
                              <CTextarea
                                class="mb-0"
                                placeholder="속성 값"
                                rows="1"
                                v-model="tsAttr.value"
                              />
                            </CCol>
                            <CCol md="1" class="justify-content-end pl-0">
                              <template v-if="index === 0"><br /></template>
                              <CButton
                                variant="ghost"
                                color="right"
                                @click="onClickThreespaceAttributeDelete(tsAttr.idx)"
                              >
                                <CIcon name="cil-x" />
                              </CButton>
                            </CCol>
                          </CRow>
                        </template>
                        <CRow class="mt-1">
                          <CCol>
                            <CButton
                              color="light"
                              @click="onClickThreespaceAttributeAdd"
                            >
                              <CIcon name="cil-plus" class="mr-2" />속성추가
                            </CButton>
                          </CCol>
                        </CRow>
                      </td>
                    </tr>
                    <tr>
                      <th>메타데이터 미리보기<br />(편집/다운로드)</th>
                      <td colspan="2">
                        <div>
                          <CIcon name="cil-info" class="text-info mr-1" />
                          <span class="text-info">위 입력한 정보로 메타데이터(json)를 생성 및 파일로 저장 할 수 있습니다.</span>
                        </div>
                        <div class="text-right mt-1">
                          <CButton
                            color="light"
                            class="ml-2"
                            @click="onClickThreespaceMetadataPreview"
                          >
                            <CIcon name="cil-loop-circular" class="mr-2" />메타데이터 생성
                          </CButton>
                          <CButton
                            color="light"
                            class="ml-2"
                            @click="onClickMetadataDownload"
                          >
                            <CIcon name="cil-data-transfer-down" class="mr-2" />다운로드
                          </CButton>
                        </div>
                        <div class="mt-2">
                          <VueCodeMirror
                            ref="threespaceCodeMirror"
                            :options="editorOptions"
                            v-model="threespaceMetadata"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th class="border-bottom">
                        메타데이터 URL <span class="text-danger font-lg">*</span><br />(IPFS URL)
                      </th>
                      <td colspan="2" class="border-bottom">
                        <CIcon name="cil-info" class="text-danger mr-1" />
                        <span class="text-danger">NFT 발행을 위한 필수정보입니다.</span>
                        <CTextarea
                          class="mt-2 mb-0"
                          placeholder="메타데이터 URL을 입력하세요."
                          rows="3"
                          v-model="productInfo.threespaceMetadataUrl"
                        />
                        <div class="mt-1">
                          <CIcon name="cil-star" class="text-info mr-1" />
                          <span class="text-info">IPFS 도메인 "https://ipfs.io/"는 "https://ipfs.3space.art/"로 자동 변경되어 저장됩니다.</span>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </CCol>
                <CCol class="col-4">
                  <div class="font-weight-bold">작품 이미지</div>
                  <div class="mt-2">
                    <template v-if="productInfo.threespaceImageUrl !== null && productInfo.threespaceImageUrl !== ''">
                      <template v-if="isResourceFileImage(productInfo.threespaceImageUrl)">
                        <b-img-lazy
                          fluid
                          width="300px"
                          height="auto"
                          :src="productInfo.threespaceImageUrl"
                        />
                      </template>
                      <template v-else>
                        <video
                          type="video/mp4"
                          width="300px"
                          height="auto"
                          :src="productInfo.threespaceImageUrl"
                          controls
                          playsinline
                        ></video>
                      </template>
                    </template>
                    <template v-else>(정보없음)</template>
                  </div>
                  <div class="font-weight-bold mt-4">
                    작품 영상
                  </div>
                  <div class="mt-2">
                    <template v-if="productInfo.threespaceAnimationUrl !== null && productInfo.threespaceAnimationUrl !== ''">
                      <video
                        type="video/mp4"
                        width="300px"
                        height="auto"
                        :src="productInfo.threespaceAnimationUrl"
                        controls
                        playsinline
                      ></video>
                    </template>
                    <template v-else>(정보없음)</template>
                  </div>
                  <div class="font-weight-bold mt-4">NFT 이력</div>
                  <div class="mt-2">
                    <template v-if="productInfo.threespaceReceipts !== undefined && productInfo.threespaceReceipts.length > 0">
                      <CToaster
                        v-for="(item, key) in productInfo.threespaceReceipts"
                        position="static"
                        :key="'items' + key"
                        class="px-0"
                      >
                        <CToast :key="'item' + item.idx" :show="true">
                          <table>
                            <tr>
                              <td colspan="2">
                                <b :class="{ 'text-danger': item.tokenId === 0 }">
                                  {{ item.nftType }}(SPACE) #{{ item.tokenId }} / {{ item.state }}
                                </b>
                                {{ item.createdAt }}
                              </td>
                            </tr>
                            <tr>
                              <th>From</th>
                              <td class="pl-1">
                                {{ item.fromAddr }}
                              </td>
                            </tr>
                            <tr>
                              <th>To</th>
                              <td class="pl-1">{{ item.toAddr }}</td>
                            </tr>
                            <tr>
                              <td colspan="2">
                                <b class="mr-2">klaytnScope</b>
                                <a :href="item.klaytnScopeUrl" target="_blank">
                                  <CIcon name="cil-external-link" />
                                </a>
                              </td>
                            </tr>
                          </table>
                        </CToast>
                      </CToaster>
                      <div class="mt-3 text-danger">
                        <CIcon name="cil-info" class="mr-2" />반드시 카이아 주소를 확인후 사용자를 선택해주세요.
                      </div>
                      <div class="mt-3">
                        <b-form-radio-group
                          id="klaytnNftTransferState"
                          name="klaytnNftTransferState"
                          :options="transferOptions"
                          v-model="nftInfo.state"
                        />
                      </div>
                      <CInput
                        placeholder="NFT 수신자를 선택해주세요."
                        :lazy="false"
                        :readonly="true"
                        :value.sync="$v.nftInfo.nickname.$model"
                        :isValid="checkIfValid('nickname')"
                        v-model="nftInfo.nickname"
                        invalidFeedback="필수 정보입니다."
                        class="mt-2"
                      >
                        <template #prepend>
                          <CButton size="sm" color="primary" @click="onClickMemberSelect">
                            <CIcon name="cil-magnifying-glass" />
                          </CButton>
                        </template>
                        <template #append>
                          <CButton size="sm" color="info" @click="onClickTransferKlaytnNFT">
                            <CIcon name="cil-send" class="mr-1" /><span class="font-md">NFT 전송</span>
                          </CButton>
                        </template>
                      </CInput>
                    </template>
                    <template v-else>(조회된 내역이 없습니다)</template>
                  </div>
                </CCol>
              </CRow>
              <CRow>
                <CCol class="text-center my-4">
                  <CButton color="light" @click="onClickCancel" class="mr-2">
                    <CIcon name="cil-arrow-circle-left" class="mr-2" />목록
                  </CButton>
                  <template v-if="productInfo.receipts === undefined || productInfo.receipts === null || productInfo.receipts.length === 0">
                    <CButton color="info" class="mr-2 text-white" @click="onClickSaveKlaytnNFT">
                      <CIcon name="cil-save" class="mr-2" />저장
                    </CButton>
                    <CButton color="primary" class="text-white" @click="onClickMintKlaytnNFT">
                      <CIcon name="cil-loop-circular" class="mr-2" />저장/NFT 발행
                    </CButton>
                  </template>
                </CCol>
              </CRow>
            </b-tab>
            <b-tab title="Ordinals">
              <CRow class="mt-2">
                <CCol class="col-8">
                  <table class="table" style="table-layout: fixed;">
                    <thead>
                    <tr>
                      <th class="col-2 text-center bg-gray-100 text-dark">
                        작품
                      </th>
                      <th class="col-2 text-center bg-gray-100 text-dark">
                        메타데이터 항목
                      </th>
                      <th class="col-8 text-center bg-gray-100 text-dark">
                        내용
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <th>작품명(영문) <span class="text-danger font-lg">*</span></th>
                      <td>name</td>
                      <td>
                        {{ productInfo.productNameEn }}
                        <template v-if="productInfo.productNameEn === undefined || productInfo.productNameEn === ''">
                          <span class="text-danger font-weight-bold">(정보없음)</span>
                        </template>
                      </td>
                    </tr>
                    <tr>
                      <th>작품 설명(영문) <span class="text-danger font-lg">*</span></th>
                      <td>description</td>
                      <td>
                        <span v-html="replaceNewLine(productInfo.productDescriptionEn)"></span>
                        <template v-if="productInfo.productMoreDescriptionEn !== undefined && productInfo.productMoreDescriptionEn !== ''">
                          <br/><br/><span v-html="replaceNewLine(productInfo.productMoreDescriptionEn)"></span>
                        </template>
                        <template v-if="productInfo.productDescriptionEn === undefined || productInfo.productDescriptionEn === ''">
                          <span class="text-danger font-weight-bold">(정보없음)</span>
                        </template>
                      </td>
                    </tr>
                    <tr>
                      <th>속성</th>
                      <td>attributes</td>
                      <td>
                        <template v-for="(tsAttr, index) in productInfo.threespaceAttributes">
                          <CRow :key="'tsAttr' + index">
                            <CCol md="3" class="pr-0">
                              <template v-if="index === 0">trait_type</template>
                              <CInput
                                class="mb-0"
                                placeholder="속성 이름"
                                v-model="tsAttr.traitType"
                              />
                            </CCol>
                            <CCol class="pr-0">
                              <template v-if="index === 0">value</template>
                              <CTextarea
                                class="mb-0"
                                placeholder="속성 값"
                                rows="1"
                                v-model="tsAttr.value"
                              />
                            </CCol>
                            <CCol md="1" class="justify-content-end pl-0">
                              <template v-if="index === 0"><br /></template>
                              <CButton
                                variant="ghost"
                                color="right"
                                @click="onClickThreespaceAttributeDelete(tsAttr.idx)"
                              >
                                <CIcon name="cil-x" />
                              </CButton>
                            </CCol>
                          </CRow>
                        </template>
                        <CRow class="mt-1">
                          <CCol>
                            <CButton
                              color="light"
                              @click="onClickThreespaceAttributeAdd"
                            >
                              <CIcon name="cil-plus" class="mr-2" />속성추가
                            </CButton>
                          </CCol>
                        </CRow>
                      </td>
                    </tr>
                    <tr>
                      <th>인스크립션 ID <span class="text-danger font-lg">*</span></th>
                      <td>Inscription ID</td>
                      <td>
                        <CTextarea
                          class="mb-0"
                          placeholder="dbf481b14df0c56998f977dc71254e371648fa8aa05665e597c518f3e0a6151ei0"
                          rows="1"
                          v-model="productInfo.inscriptionId"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>인스크립션 넘버 <span class="text-danger font-lg">*</span></th>
                      <td>Inscription Number</td>
                      <td>
                        <CTextarea
                          class="mb-0"
                          placeholder="4713892"
                          rows="1"
                          v-model="productInfo.inscriptionNumber"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>판매가 <span class="text-danger font-lg">*</span></th>
                      <td>판매가격(Satoshi)</td>
                      <td>
                        <CTextarea
                          class="mb-0"
                          placeholder="4713892"
                          rows="1"
                          :value="getSatoshiPrice"
                          disabled
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>판매 확인 Psbt <span class="text-danger font-lg">*</span></th>
                      <td>Selling Signed Psbt</td>
                      <td>
                        <CTextarea
                          class="mb-0"
                          placeholder="Sign이 필요합니다 (cHNidP8BA......AAA==)"
                          rows="1"
                          disabled
                          v-model="productInfo.sellingSignedPsbt"
                        />
                        <CButton
                          class="mt-2"
                          color="light"
                          @click="onClickListingOrdinals"
                        >
                          <CIcon name="cil-pen" class="mr-2" />Sign
                        </CButton>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </CCol>
                <CCol class="col-4">
                  <div class="font-weight-bold">작품 이미지</div>
                  <div class="mt-2">
                    <template v-if="productInfo.displayCardUrlThreespace !== null && productInfo.displayCardUrlThreespace !== ''">
                      <template v-if="isResourceFileImage(productInfo.displayCardUrl)">
                        <b-img-lazy
                          fluid
                          width="300px"
                          height="auto"
                          :src="productInfo.displayCardUrlThreespace"
                        />
                      </template>
                    </template>
                    <template v-else>(정보없음)</template>
                  </div>
                </CCol>
              </CRow>
              <CRow>
                <CCol class="text-center my-4">
                  <CButton color="light" @click="onClickCancel" class="mr-2">
                    <CIcon name="cil-arrow-circle-left" class="mr-2" />목록
                  </CButton>
                  <template v-if="productInfo.receipts === undefined || productInfo.receipts === null || productInfo.receipts.length === 0">
                    <CButton color="info" class="mr-2 text-white" @click="onClickSaveOrdinals">
                      <CIcon name="cil-save" class="mr-2" />연결
                    </CButton>
                  </template>
                </CCol>
              </CRow>
            </b-tab>
          </b-tabs>
        </div>
      </CCardBody>

      <MemberSelectPopup
        ref="member-select-popup"
        @member-selected="selectedMemberInfo"
      />

      <ArtistSelectPopup
        ref="artist-select-popup"
        @artist-selected="selectedArtistMemberInfo"
      />
    </CCard>

    <!-- 메시지 팝업 -->
    <b-modal
      id="message-modal"
      ref="message-modal"
      hide-header
      hide-footer
      centered
      no-close-on-esc
      no-close-on-backdrop
      body-class="my-4 text-dark d-flex flex-column"
    >
      <div class="mt-3 text-center">
        {{ message }}<br/>{{ time }}
      </div>
      <div class="mt-3 d-flex justify-content-center" v-if="!submitted">
        <div class="sk-wave">
          <div class="sk-wave-rect"></div>
          <div class="sk-wave-rect"></div>
          <div class="sk-wave-rect"></div>
          <div class="sk-wave-rect"></div>
          <div class="sk-wave-rect"></div>
        </div>
      </div>
      <div class="w-100 text-center mt-5">
        <b-button
          variant="primary"
          squared
          class="text-white px-5 py-2"
          @click="hideMessageModal"
          :disabled="!submitted"
        >
          확인
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { codemirror } from "vue-codemirror";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";
import "codemirror/mode/javascript/javascript";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { createNamespacedHelpers } from "vuex";
const productHelper = createNamespacedHelpers("product");
const transactionHelper = createNamespacedHelpers("transaction");
import MemberSelectPopup from "@/views/management/member/MemberSelectPopup";
import ArtistSelectPopup from "@/views/management/artist/ArtistSelectPopup";
import * as bitcoin from "bitcoinjs-lib";
import { MarketPlace, Inscription } from "ordinalsbot";
import {getAddress, signTransaction} from "sats-connect";

export default {
  name: "ProductNFT",
  components: { MemberSelectPopup, VueCodeMirror: codemirror, ArtistSelectPopup },
  props: {
    idxProduct: {
      type: String,
      default: "0"
    }
  },
  data: function() {
    return {
      collectionUploadInfo: {
        idx: null
      },
      submitted: false,
      message: "처리중입니다.",
      productInfo: {
        idx: null,
        productNameEn: null,
        productDescriptionEn: null,
        // 3space(Ethereum/Klayten) NFT info
        threespaceImage: null,
        threespaceImageUrl: null,
        threespaceAnimation: "",
        threespaceAnimationUrl: null,
        threespaceMetadata: null,
        threespaceMetadataUrl: null,
        threespaceAttributes: [],
        threespaceReceipts: [],
        // Klip NFT info
        klipName: null,
        klipDescription: null,
        klipImage: null,
        klipBackgroundColor: "#f4f6f7",
        klipAnimationUrl: null,
        klipGroup: "true",
        klipGroupName: "",
        klipGroupIcon: null,
        klipExternalLink: "",
        klipHashtags: null,
        klipSendable: "true",
        klipSendFriendOnly: "false",
        klipAttributes: [],
        klipReceipts: [],
        inscriptionId: "",
        inscriptionNumber: 1,
        sellingSignedPsbt: "",
        productCoinPrice:"",
        productCoinCurrency: "",
      },
      // 3space(Ethereum/Klayten) NFT info
      productIpfsOptions: [
        { value: "IMAGE", text: "작품 이미지/IPFS" },
        { value: "PREVIEW", text: "작품 영상/IPFS" },
        { value: "ORIGINAL", text: "작품 영상(대용량)/IPFS" }
      ],
      productVideoIpfsOptions: [
        { value: "", text: "미사용" },
        { value: "IMAGE", text: "작품 이미지/IPFS" },
        { value: "PREVIEW", text: "작품 영상/IPFS" },
        { value: "ORIGINAL", text: "작품 영상(대용량)/IPFS" }
      ],
      productIpfsMap: null,
      indexThreespaceAttribute: 0,
      threespaceMetadata: null,
      // Klip NFT info
      backgroundColorOptions: [{ value: "#f4f6f7", text: "#f4f6f7" }],
      groupOptions: [
        { value: "false", text: "그룹 없음" },
        { value: "true", text: "그룹 있음" }
      ],
      yesOrNoOptions: [
        { value: "true", text: "예" },
        { value: "false", text: "아니오" }
      ],
      transferOptions: [
        { value: "TRANSFER", text: "전송(TRANSFER)" },
        { value: "PURCHASE", text: "구매(PURCHASE)" }
      ],
      indexKlipAttribute: 0,
      klipMetadata: null,
      enableBurnKlipNFT: "false",
      groupIconMessage: "",
      // 공통
      nftInfo: this.getInitNftInfo(),
      // Tab
      tabIndex: 0,
      // NFT Mint > Async
      isTimeout: false,
      date: this.$moment(60 * 10 * 1000) // 10분
    };
  },
  computed: {
    threespaceCodemirrorInstance() {
      return this.$refs.threespaceCodeMirror.codemirror;
    },
    klipCodemirrorInstance() {
      return this.$refs.klipCodeMirror.codemirror;
    },
    editorOptions() {
      return {
        tabSize: 4,
        styleActiveLine: true,
        lineNumbers: true,
        lineWrapping: true,
        line: true,
        mode: "javascript", //application/json
        theme: "material",
        autofocus: false,
      };
    },
    getSatoshiPrice() {
      return parseFloat(Math.floor(this.productInfo.productCoinPrice * 1000) / 1000)
        .toFixed(0)
        .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,") + " " + this.productInfo.productCoinCurrency;
    },
    isValid() {
      return !this.$v.nftInfo.$invalid;
    },
    isDirty() {
      return this.$v.nftInfo.$anyDirty;
    },
    time() {
      return this.date.format("mm : ss");
    }
  },
  mixins: [validationMixin],
  validations: {
    nftInfo: {
      nickname: { required }
    }
  },
  mounted() {
    if (this.threespaceCodemirrorInstance.options.autofocus) {
      this.threespaceCodemirrorInstance.setSize("100%", "30vh");
      this.threespaceCodemirrorInstance.focus();
    }
    if (this.klipCodemirrorInstance.options.autofocus) {
      this.klipCodemirrorInstance.setSize("100%", "30vh");
      this.klipCodemirrorInstance.focus();
    }
    this.productIpfsMap = new Map();
    this.requestProductItem();
  },
  methods: {
    ...productHelper.mapActions([
      "getProduct",
      "updateProductEthereumNFT",
      "mintEthereumNFT",
      "transferEthereumNFT",
      "updateProductPolygonNFT",
      "mintPolygonNFT",
      "transferPolygonNFT",
      "updateProductKlaytnNFT",
      "mintKlaytnNFT",
      "transferKlaytnNFT",
      "updateProductKlipCard",
      "mintKlipNFT",
      "transferKlipNFT",
      "burnKlipNFT",
      "requestMintKlipNFT",
      "collectionUpdateUpload",
      "updateProductOrdinalsNFT"
    ]),
    ...transactionHelper.mapActions(["getTxResult"]),
    requestProductItem() {
      this.$log.info("idxProduct=", this.idxProduct);
      if (this.idxProduct && this.idxProduct !== "0") {
        this.getProduct(this.idxProduct).then(data => {
          this.$log.info(data); //TODO
          if (Object.keys(data).length !== 0 && JSON.stringify(data) !== JSON.stringify({})) {
            // 작품 기본정보
            this.productInfo = data;
            if (this.productInfo.nftType !== undefined && this.productInfo.nftType !== null && this.productInfo.nftType !== '') {
              switch (this.productInfo.nftType) {
                case "ETHEREUM":
                  this.tabIndex = 0;
                  break;
                case "POLYGON":
                  this.tabIndex = 1;
                  break;
                case "KLIP":
                  this.tabIndex = 2;
                  break;
                case "KLAYTN":
                  this.tabIndex = 3;
                  break;
                case "ORDINALS":
                  this.tabIndex = 4;
                  break;
                default:
                  this.tabIndex = 0;
              }
            }

            // 3space(Ethereum/Klayten) NFT - 속성
            if (
              data.threespaceAttributes !== undefined &&
              data.threespaceAttributes !== null &&
              data.threespaceAttributes.length > 0
            ) {
              this.indexThreespaceAttribute = data.threespaceAttributes[data.threespaceAttributes.length - 1].idx;
            } else {
              // 초기값 설정
              this.productInfo.threespaceAttributes.push({idx: this.getIndexThreespaceAttribute(), traitType: "Artist", value: data.artistNameEn});
              this.productInfo.threespaceAttributes.push({idx: this.getIndexThreespaceAttribute(), traitType: "Dimension", value: data.productSizeX + " X " + data.productSizeY});
              this.productInfo.threespaceAttributes.push({idx: this.getIndexThreespaceAttribute(), traitType: "YearCreated", value: data.paintedAt});
              this.productInfo.threespaceAttributes.push({idx: this.getIndexThreespaceAttribute(), traitType: "Edition", value: data.productEdition});
            }

            // 3space(Ethereum/Klayten) NFT - 작품 리소스 - IPFS URL
            data.resources.forEach(item => {
              if (item.resourceType === "PRODUCT_IMAGE") {
                this.productIpfsMap.set("IMAGE", this.replaceIpfsUrl(item.ipfsUrl));

                // Klip NFT - image URL
                if (item.klipUrl !== undefined && item.klipUrl !== null && item.klipUrl !== '') {
                  this.productInfo.klipImage = item.klipUrl;
                }
              } else if (item.resourceType === "PRODUCT_PREVIEW") {
                this.productIpfsMap.set("PREVIEW", this.replaceIpfsUrl(item.ipfsUrl));

                // Klip NFT - animation URL
                if (item.klipUrl !== undefined && item.klipUrl !== null && item.klipUrl !== '') {
                  this.productInfo.klipAnimationUrl = item.klipUrl;
                }
              } else if (item.resourceType === "PRODUCT_ORIGINAL") {
                this.productIpfsMap.set("ORIGINAL", this.replaceIpfsUrl(item.ipfsUrl));
              }
            });
            if (
              this.productInfo.threespaceImage === undefined ||
              this.productInfo.threespaceImage === null ||
              this.productInfo.threespaceImage === ""
            ) {
              this.productInfo.threespaceImage = "IMAGE";
              this.onClickThreespaceIPFS("PRODUCT-IMAGE");
            } else {
              this.productInfo.threespaceImageUrl = this.replaceIpfsUrl(this.productInfo.threespaceImageUrl);
            }
            if (
              this.productInfo.threespaceAnimation === undefined ||
              this.productInfo.threespaceAnimation === null ||
              this.productInfo.threespaceAnimation === ""
            ) {
              this.productInfo.threespaceAnimation = "";
              this.onClickThreespaceIPFS("");
            } else {
              this.productInfo.threespaceAnimationUrl = this.replaceIpfsUrl(this.productInfo.threespaceAnimationUrl);
            }

            // 3space(Ethereum/Klayten) NFT 메타데이터 미리보기
            if (
              this.productInfo.threespaceMetadata !== undefined &&
              this.productInfo.threespaceMetadata !== null &&
              this.productInfo.threespaceMetadata !== ""
            ) {
              this.threespaceMetadata = this.productInfo.threespaceMetadata;
            }

            // Klip NFT - 기본정보
            this.productInfo.klipBackgroundColor = data.klipBackgroundColor !== undefined ? data.klipBackgroundColor : "#f4f6f7";
            //TODO 필수로 변경 this.productInfo.klipGroup = data.klipGroup === false ? "false" : "true";
            this.productInfo.klipSendable = data.klipSendable === false ? "false" : "true";
            this.productInfo.klipSendFriendOnly = data.klipSendFriendOnly === true ? "true" : "false";

            // Klip NFT - 그룹
            if (data.klipGroup === false) {
              this.productInfo.klipGroup = "true";
              this.productInfo.klipGroupName = data.artistNameEn;
              this.productInfo.klipGroupIcon = data.groupIcon;
            }

            // Klip NFT - 속성
            if (
              data.klipAttributes !== undefined &&
              data.klipAttributes !== null &&
              data.klipAttributes.length > 0
            ) {
              this.indexKlipAttribute = data.klipAttributes[data.klipAttributes.length - 1].idx;
            } else {
              // 초기값 설정
              this.productInfo.klipAttributes.push({idx: this.getIndexKlipAttribute(), traitType: "Artist", value: data.artistNameEn});
              this.productInfo.klipAttributes.push({idx: this.getIndexKlipAttribute(), traitType: "Dimension", value: data.productSizeX + " X " + data.productSizeY});
              this.productInfo.klipAttributes.push({idx: this.getIndexKlipAttribute(), traitType: "YearCreated", value: data.paintedAt});
              this.productInfo.klipAttributes.push({idx: this.getIndexKlipAttribute(), traitType: "Edition", value: data.productEdition});
              this.productInfo.klipAttributes.push({idx: this.getIndexKlipAttribute(), traitType: "About", value: data.artistIntroduceEn});
            }

            // Klip NFT - 외부링크
            this.productInfo.klipExternalLink = data.klipExternalLink !== undefined ? data.klipExternalLink : "https://3space.art/art/" + this.idxProduct;
          }
        });
      }
    },
    // 3space(Ethereum/Klayten) NFT >>> Start
    getIndexThreespaceAttribute() {
      this.indexThreespaceAttribute = this.indexThreespaceAttribute + 1;
      return this.indexThreespaceAttribute;
    },
    onClickThreespaceAttributeAdd() {
      this.productInfo.threespaceAttributes.push({
        idx: this.getIndexThreespaceAttribute(),
        traitType: "",
        value: ""
      });
      this.onClickThreespaceMetadataPreview();
    },
    onClickThreespaceAttributeDelete(idx) {
      const _idx = this.productInfo.threespaceAttributes.findIndex(
        item => item.idx === idx
      );
      if (_idx > -1) this.productInfo.threespaceAttributes.splice(_idx, 1);
      this.onClickThreespaceMetadataPreview();
    },
    onClickThreespaceMetadataPreview() {
      let data = new Object();
      data.name = this.productInfo.productNameEn;

      let desc = this.productInfo.productDescriptionEn;
      if (this.productInfo.productMoreDescriptionEn !== undefined && this.productInfo.productMoreDescriptionEn !== '') {
        desc = desc + "\n\n" + this.productInfo.productMoreDescriptionEn;
      }
      data.description = desc;
      data.image = this.productInfo.threespaceImageUrl;
      if (
        this.productInfo.threespaceAnimationUrl !== undefined &&
        this.productInfo.threespaceAnimationUrl !== null &&
        this.productInfo.threespaceAnimationUrl !== ""
      ) {
        data.animation_url = this.productInfo.threespaceAnimationUrl;
      }
      if (
        this.productInfo.threespaceAttributes !== undefined &&
        this.productInfo.threespaceAttributes !== null &&
        this.productInfo.threespaceAttributes.length > 0
      ) {
        let attributes = new Array();
        this.productInfo.threespaceAttributes.forEach(item => {
          if (
            item.traitType !== undefined &&
            item.traitType !== null &&
            item.traitType !== "" &&
            item.value !== undefined &&
            item.value !== null &&
            item.value !== ""
          ) {
            let attr = new Object();
            attr.trait_type = item.traitType;
            attr.value = item.value;
            attributes.push(attr);
          }
        });
        if (attributes.length > 0) {
          data.attributes = attributes;
        }
      }
      this.threespaceMetadata = JSON.stringify(data, null, 4);
      this.productInfo.threespaceMetadata = this.threespaceMetadata;
    },
    onClickMetadataDownload() {
      const url = window.URL.createObjectURL(
        new Blob([this.threespaceMetadata])
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "metadata.json");
      document.body.appendChild(link);
      link.click();
    },
    onClickThreespaceIPFS(type) {
      if (type === "PRODUCT-IMAGE") {
        this.productInfo.threespaceImageUrl = this.productIpfsMap.get(this.productInfo.threespaceImage);
      } else if (type === "PRODUCT-FILE") {
        this.productInfo.threespaceAnimationUrl = this.productIpfsMap.get(this.productInfo.threespaceAnimation);
      }
      this.onClickThreespaceMetadataPreview();
    },
    onClickSavePolygonNFT() {
      this.$log.info("click onClickSaveEthereumNFT()", this.productInfo);
      // Ethereum NFT 정보 저장
      if (this.idxProduct && this.idxProduct !== "0") {
        if (
          this.productInfo.threespaceMetadataUrl !== undefined &&
          this.productInfo.threespaceMetadataUrl !== null &&
          this.productInfo.threespaceMetadataUrl !== ""
        ) {
          this.productInfo.threespaceMetadataUrl = this.replaceIpfsUrl(this.productInfo.threespaceMetadataUrl);
        }

        this.updateProductPolygonNFT(this.productInfo)
          .then(() => {
            alert("저장되었습니다.");
            this.$router.go(this.$router.currentRoute);
          })
          .catch(error => {
            alert("Polygon NFT 정보 저장 오류가 발생하였습니다. 관리자에게 문의하세요.");
            this.$log.error(error);
          });
      }
    },
    onClickMintPolygonNFT() {
      if (
        this.productInfo.threespaceMetadataUrl !== undefined &&
        this.productInfo.threespaceMetadataUrl !== null &&
        this.productInfo.threespaceMetadataUrl !== ""
      ) {
        this.productInfo.threespaceMetadataUrl = this.replaceIpfsUrl(this.productInfo.threespaceMetadataUrl);
        if (confirm("Polygon NFT를 발행하시겠습니까?")) {

          this.submitted = false;
          this.$refs["message-modal"].show();
          this.mintPolygonNFT(this.productInfo)
            .then(data => {
              this.$log.log("onClickMintPolygonNFT() ", data);
              if (Object.keys(data).length !== 0 && JSON.stringify(data) !== JSON.stringify({})) {
                this.message = "Polygon NFT가 정상 발행되었습니다.";
                this.submitted = true;
              } else {
                this.message = "Polygon NFT 발행 오류가 발생하였습니다. 관리자에게 문의하세요.";
                this.submitted = true;
              }
            })
            .catch(error => {
              this.message = "Polygon NFT 발행 오류가 발생하였습니다. 관리자에게 문의하세요.\n" + error;
              this.submitted = true;
              this.$log.error(error);
            });
        }
      } else {
        alert("[메타데이터 URL]은 필수 정보입니다.");
      }
    },
    onClickTransferPolygonNFT() {
      this.$v.$touch();
      if (this.isValid) {
        if (confirm("Polygon NFT를 전송하시겠습니까?")) {

          this.submitted = false;
          this.$refs["message-modal"].show();
          this.transferPolygonNFT(this.nftInfo)
            .then(data => {
              if (Object.keys(data).length !== 0 && JSON.stringify(data) !== JSON.stringify({})) {
                this.message = "Polygon NFT가 정상 전송되었습니다.";
                this.submitted = true;
              } else {
                this.message = "Polygon NFT 전송 오류가 발생하였습니다. 관리자에게 문의하세요.";
                this.submitted = true;
              }
            })
            .catch(error => {
              this.message = "Polygon NFT 전송 오류가 발생하였습니다. 관리자에게 문의하세요.\n" + error;
              this.submitted = true;
              this.$log.error(error);
            });
        }
      }
    },
    // Ethereum NFT >>> Start
    onClickSaveEthereumNFT() {
      this.$log.info("click onClickSaveEthereumNFT()", this.productInfo);
      // Ethereum NFT 정보 저장
      if (this.idxProduct && this.idxProduct !== "0") {
        if (
          this.productInfo.threespaceMetadataUrl !== undefined &&
          this.productInfo.threespaceMetadataUrl !== null &&
          this.productInfo.threespaceMetadataUrl !== ""
        ) {
          this.productInfo.threespaceMetadataUrl = this.replaceIpfsUrl(this.productInfo.threespaceMetadataUrl);
        }

        this.updateProductEthereumNFT(this.productInfo)
          .then(() => {
            alert("저장되었습니다.");
            this.$router.go(this.$router.currentRoute);
          })
          .catch(error => {
            alert("Ethereum NFT 정보 저장 오류가 발생하였습니다. 관리자에게 문의하세요.");
            this.$log.error(error);
          });
      }
    },
    onClickMintEthereumNFT() {
      if (
        this.productInfo.threespaceMetadataUrl !== undefined &&
        this.productInfo.threespaceMetadataUrl !== null &&
        this.productInfo.threespaceMetadataUrl !== ""
      ) {
        this.productInfo.threespaceMetadataUrl = this.replaceIpfsUrl(this.productInfo.threespaceMetadataUrl);

        if (confirm("Ethereum NFT를 발행하시겠습니까?")) {

          this.submitted = false;
          this.$refs["message-modal"].show();
          this.mintEthereumNFT(this.productInfo)
            .then(data => {
              this.$log.log("onClickMintEthereumNFT() ", data);
              if (Object.keys(data).length !== 0 && JSON.stringify(data) !== JSON.stringify({})) {
                this.message = "Ethereum NFT가 정상 발행되었습니다.";
                this.submitted = true;
              } else {
                this.message = "Ethereum NFT 발행 오류가 발생하였습니다. 관리자에게 문의하세요.";
                this.submitted = true;
              }
            })
            .catch(error => {
              this.message = "Ethereum NFT 발행 오류가 발생하였습니다. 관리자에게 문의하세요.\n" + error;
              this.submitted = true;
              this.$log.error(error);
            });
        }
      } else {
        alert("[메타데이터 URL]은 필수 정보입니다.");
      }
    },
    onClickSaveOrdinals(){
      this.$log.info("click onClickSaveOrdinalsNFT()", this.productInfo);
      // Ethereum NFT 정보 저장
      if (this.idxProduct && this.idxProduct !== "0") {

        this.updateProductOrdinalsNFT(this.productInfo)
          .then(() => {
            alert("저장되었습니다.");
            this.$router.go(this.$router.currentRoute);
          })
          .catch(error => {
            alert("Ordinals NFT 정보 저장 오류가 발생하였습니다. 관리자에게 문의하세요.");
            this.$log.error(error);
          });
      }
    },
    onClickTransferEthereumNFT() {
      this.$v.$touch();
      if (this.isValid) {
        if (confirm("Ethereum NFT를 전송하시겠습니까?")) {

          this.submitted = false;
          this.$refs["message-modal"].show();
          this.transferEthereumNFT(this.nftInfo)
            .then(data => {
              if (Object.keys(data).length !== 0 && JSON.stringify(data) !== JSON.stringify({})) {
                this.message = "Ethereum NFT가 정상 전송되었습니다.";
                this.submitted = true;
              } else {
                this.message = "Ethereum NFT 전송 오류가 발생하였습니다. 관리자에게 문의하세요.";
                this.submitted = true;
              }
            })
            .catch(error => {
              this.message = "Ethereum NFT 전송 오류가 발생하였습니다. 관리자에게 문의하세요.\n" + error;
              this.submitted = true;
              this.$log.error(error);
            });
        }
      }
    },
    // Klaytn NFT >>> Start
    onClickSaveKlaytnNFT() {
      this.$log.info("click onClickSaveKlaytnNFT()", this.productInfo);
      // Klaytn NFT 정보 저장
      if (this.idxProduct && this.idxProduct !== "0") {
        if (
          this.productInfo.threespaceMetadataUrl !== undefined &&
          this.productInfo.threespaceMetadataUrl !== null &&
          this.productInfo.threespaceMetadataUrl !== ""
        ) {
          this.productInfo.threespaceMetadataUrl = this.replaceIpfsUrl(this.productInfo.threespaceMetadataUrl);
        }

        this.updateProductKlaytnNFT(this.productInfo)
          .then(() => {
            alert("저장되었습니다.");
            this.$router.go(this.$router.currentRoute);
          })
          .catch(error => {
            alert("Kaia NFT 정보 저장 오류가 발생하였습니다. 관리자에게 문의하세요.");
            this.$log.error(error);
          });
      }
    },
    onClickMintKlaytnNFT() {
      if (
        this.productInfo.threespaceMetadataUrl !== undefined &&
        this.productInfo.threespaceMetadataUrl !== null &&
        this.productInfo.threespaceMetadataUrl !== ""
      ) {
        this.productInfo.threespaceMetadataUrl = this.replaceIpfsUrl(this.productInfo.threespaceMetadataUrl);

        if (confirm("Kaia NFT를 발행하시겠습니까?")) {
          this.mintKlaytnNFT(this.productInfo)
            .then(data => {
              if (
                Object.keys(data).length !== 0 &&
                JSON.stringify(data) !== JSON.stringify({})
              ) {
                alert("Kaia NFT가 정상 발행되었습니다.");
                this.$router.go(this.$router.currentRoute);
              } else {
                alert("Kaia NFT 발행 오류가 발생하였습니다. 관리자에게 문의하세요.");
                this.$router.go(this.$router.currentRoute);
              }
            })
            .catch(error => {
              alert("Kaia NFT 발행 오류가 발생하였습니다. 관리자에게 문의하세요.");
              this.$router.go(this.$router.currentRoute);
              this.$log.error(error);
            });
        }
      } else {
        alert("[메타데이터 URL]은 필수 정보입니다.");
      }
    },
    onClickTransferKlaytnNFT() {
      this.$v.$touch();
      if (this.isValid) {
        if (confirm("Klaytn NFT를 전송하시겠습니까?")) {
          this.transferKlaytnNFT(this.nftInfo)
            .then(data => {
              this.$log.log(data);
              if (
                Object.keys(data).length !== 0 &&
                JSON.stringify(data) !== JSON.stringify({})
              ) {
                alert("Klaytn NFT가 정상 전송되었습니다.");
                this.$router.go(this.$router.currentRoute);
              } else {
                alert("Klaytn NFT 전송 오류가 발생하였습니다. 관리자에게 문의하세요.");
                this.$router.go(this.$router.currentRoute);
              }
            })
            .catch(error => {
              this.$log.error(error);
              alert("Klaytn NFT 전송 오류가 발생하였습니다. 관리자에게 문의하세요.");
              this.$router.go(this.$router.currentRoute);
            });
        }
      }
    },
    // Klip NFT >>> Start
    getIndexKlipAttribute() {
      this.indexKlipAttribute = this.indexKlipAttribute + 1;
      return this.indexKlipAttribute;
    },
    onClickKlipAttributeAdd() {
      this.productInfo.klipAttributes.push({
        idx: this.getIndexKlipAttribute(),
        traitType: "",
        value: ""
      });
      this.onClickKlipMetadataPreview();
    },
    onClickKlipAttributeDelete(idx) {
      const _idx = this.productInfo.klipAttributes.findIndex(item => item.idx === idx);
      if (_idx > -1) this.productInfo.klipAttributes.splice(_idx, 1);
      this.onClickKlipMetadataPreview();
    },
    onClickKlipMetadataPreview() {
      let data = new Object();
      data.name = this.productInfo.productNameEn;

      let desc = this.productInfo.productDescriptionEn;
      if (this.productInfo.productMoreDescriptionEn !== undefined && this.productInfo.productMoreDescriptionEn !== '') {
        desc = desc + "\n\n" + this.productInfo.productMoreDescriptionEn;
      }
      data.description = desc;
      data.image = this.productInfo.klipImage;
      if (
        this.productInfo.klipAnimationUrl !== undefined &&
        this.productInfo.klipAnimationUrl !== null &&
        this.productInfo.klipAnimationUrl !== ""
      ) {
        data.animation_url = this.productInfo.klipAnimationUrl;
      }
      data.background_color = this.productInfo.klipBackgroundColor;
      data.sendable = this.productInfo.klipSendable;
      data.send_friend_only = this.productInfo.klipSendFriendOnly;
      if (this.productInfo.klipGroup === "true") {
        data.grop_name = this.productInfo.klipGroupName;
        data.group_icon = this.productInfo.klipGroupIcon;
      }
      if (
        this.productInfo.klipExternalLink !== undefined &&
        this.productInfo.klipExternalLink !== null &&
        this.productInfo.klipExternalLink !== ""
      ) {
        data.external_link = this.productInfo.klipExternalLink;
      }
      if (
        this.productInfo.klipHashtags !== undefined &&
        this.productInfo.klipHashtags !== null &&
        this.productInfo.klipHashtags !== ""
      ) {
        data.hashtags = this.productInfo.klipHashtags;
      }
      if (
        this.productInfo.klipAttributes !== undefined &&
        this.productInfo.klipAttributes !== null &&
        this.productInfo.klipAttributes.length > 0
      ) {
        let attributes = new Array();
        this.productInfo.klipAttributes.forEach(item => {
          if (
            item.traitType !== undefined &&
            item.traitType !== null &&
            item.traitType !== "" &&
            item.value !== undefined &&
            item.value !== null &&
            item.value !== ""
          ) {
            let attr = new Object();
            attr.trait_type = item.traitType;
            attr.value = item.value;
            attributes.push(attr);
          }
        });
        if (attributes.length > 0) {
          data.attributes = attributes;
        }
      }
      this.klipMetadata = JSON.stringify(data, null, 4);
    },
    onClickArtistGroupSelect() {
      this.$refs["artist-select-popup"].show();
    },
    onClickSaveKlipCard() {
      this.$log.info("click onClickSaveKlipCard()", this.productInfo);
      // Klip NFT 정보 저장
      if (this.idxProduct && this.idxProduct !== "0") {
        this.updateProductKlipCard(this.productInfo)
          .then(() => {
            alert("저장되었습니다.");
            this.$router.go(this.$router.currentRoute);
          })
          .catch(error => {
            alert("Klip NFT 정보 저장 오류가 발생하였습니다. 관리자에게 문의하세요.");
            this.$log.error(error);
          });
      }
    },
    onClickMintKlipNFT_bk() {
      this.$log.info("click onClickMintKlipNFT()", this.productInfo);
      if (confirm("Klip NFT를 발행하시겠습니까?")) {

        this.submitted = false;
        this.$refs["message-modal"].show();
        this.mintKlipNFT(this.productInfo)
          .then(data => {
            if (
              Object.keys(data).length !== 0 &&
              JSON.stringify(data) !== JSON.stringify({})
            ) {
              this.message = "Klip NFT가 정상 발행되었습니다.";
              this.submitted = true;
            } else {
              this.message = "Klip NFT 발행 오류가 발생하였습니다. 관리자에게 문의하세요.";
              this.submitted = true;
            }
          })
          .catch(error => {
            this.message = "Klip NFT 발행 오류가 발생하였습니다. 관리자에게 문의하세요.\n" + error;
            this.submitted = true;
            this.$log.error(error);
          });
      }
    },
    // Klip NFT 발행 요청 후 결과 Polling
    onClickRequestMintKlipNFT() {
      this.$log.info("click onClickRequestMintKlipNFT()", this.productInfo);
      if (confirm("Klip NFT를 발행하시겠습니까?")) {

        this.submitted = false;
        this.$refs["message-modal"].show();

        this.requestMintKlipNFT(this.productInfo)
          .then(data => {
            this.$log.log("onClickRequestMintKlipNFT() data=", data); //TODO
            if (data.status !== undefined && data.status !== null && data.status === "requested") {
              // 결과 조회
              this.isTimeout = false;
              this.date = this.$moment(60 * 10 * 1000); // 10분
              clearInterval(this.timer);
              clearInterval(this.totalTimer);

              // 결과 조회 전체 타이머
              this.setTotalTimer();
              // 결과 조회
              this.setResultTimer(data.requestKey);

              this.message = "Klip NFT 발행이 요청되었습니다. NFT 발행중입니다.";
              this.submitted = false;
            } else {
              this.message = "Klip NFT 발행 요청 오류가 발생하였습니다. 관리자에게 문의하세요.";
              this.submitted = true;
            }
          })
          .catch(error => {
            this.message = "Klip NFT 발행 요청 오류가 발생하였습니다. 관리자에게 문의하세요.\n" + error;
            this.submitted = true;
            this.$log.error(error);
          });
      }
    },
    setTotalTimer() {
      if (!this.isTimeout) {
        this.totalTimer = setInterval(() => {
          this.date = this.date.clone().subtract(1, "seconds");
          if (this.time === "00 : 00") {
            clearInterval(this.timer);
            clearInterval(this.totalTimer);

            this.message = "Klip NFT 발행 처리가 지연되고 있습니다. 관리자에게 문의하세요.";
            this.submitted = true;
          }
        }, 1000);
      }
    },
    setResultTimer(_requestKey) {
      if (!this.isTimeout) {
        this.timer = setInterval(() => {
          this.getTxResult(_requestKey)
            .then(data => {
              this.$log.log("getTxResult() data=", data); //TODO
              if (data.status === "completed") {
                if (data.resultStatus === "success") {
                  clearInterval(this.timer);
                  this.isTimeout = true;

                  this.message = "Klip NFT 발행이 정상 완료되었습니다. 이어서 Collectin 업로드를 시작합니다.";
                  this.submitted = true;
                  this.collectionUpdateUpload(this.productInfo)
                    .then(() => {
                      this.message = "Klip NFT 발행이 정상 완료되었습니다. > Collection 업로드가 완료되었습니다.";
                      this.submitted = true;
                    })
                    .catch((error) => {
                      this.$log.error("collection 저장 & S3 upload", error)
                      this.message = "Klip NFT 발행이 정상 완료되었습니다. > Collection 업로드가 실패했습니다.";
                      this.submitted = true;
                    })

                } else if (data.resultStatus === "fail") {
                  clearInterval(this.timer);
                  this.isTimeout = true;

                  this.message = "Klip NFT 발행 오류가 발생하였습니다. 관리자에게 문의하세요.\n" + data.result;
                  this.submitted = true;
                }
              }
            })
            .catch(error => {
              this.$log.error(error);
            });
        }, 5000);
      }
    },
    onClickTransferKlipNFT() {
      this.$v.$touch();
      if (this.isValid) {
        if (confirm("Klip NFT를 전송하시겠습니까?")) {

          this.submitted = false;
          this.$refs["message-modal"].show();
          this.transferKlipNFT(this.nftInfo)
            .then(data => {
              if (
                Object.keys(data).length !== 0 &&
                JSON.stringify(data) !== JSON.stringify({})
              ) {
                this.message = "Klip NFT가 정상 전송되었습니다.";
                this.submitted = true;
              } else {
                this.message = "Klip NFT 전송 오류가 발생하였습니다. 관리자에게 문의하세요.";
                this.submitted = true;
              }
            })
            .catch(error => {
              this.message = "Klip NFT 전송 오류가 발생하였습니다. 관리자에게 문의하세요.\n" + error;
              this.submitted = true;
              this.$log.error(error);
            });
        }
      }
    },
    onClickBurnKlipNFT() {
      if (confirm("Klip NFT를 소각하시겠습니까?")) {

        this.submitted = false;
        this.$refs["message-modal"].show();
        this.burnKlipNFT(this.nftInfo)
          .then(data => {
            if (
              Object.keys(data).length !== 0 &&
              JSON.stringify(data) !== JSON.stringify({})
            ) {
              this.message = "Klip NFT가 정상 소각되었습니다.";
              this.submitted = true;
            } else {
              this.message = "Klip NFT 소각 오류가 발생하였습니다. 관리자에게 문의하세요.";
              this.submitted = true;
            }
          })
          .catch(error => {
            this.message = "Klip NFT 소각 오류가 발생하였습니다. 관리자에게 문의하세요.\n" + error;
            this.submitted = true;
            this.$log.error(error);
          });
      }
    },
    async getXverseAddress() {
      const network = process.env.NODE_ENV === 'production' ? 'Mainnet' : 'Testnet'

      let address = "";
      const getAddressOptions = {
        payload: {
          purposes: ['ordinals', 'payment'],
          message: 'Address for receiving Ordinals and payments',
          network: {
            type: network
          },
        },
        onFinish: (response) => {
          const paymentAddress = response.addresses.find(address => address.purpose === 'payment');
          const ordinalsAddress = response.addresses.find(address => address.purpose === 'ordinals');
          address = {
            paymentAddress,
            ordinalsAddress
          }
        },
        onCancel: () => alert('Request canceled'),
      }

      await getAddress(getAddressOptions);
      return address
    },
    async onClickListingOrdinals() {
      const network = process.env.NODE_ENV === 'production' ? 'Mainnet' : 'Testnet'
      const environment = process.env.NODE_ENV === "production" ? "live" : "dev"
      console.log(environment)
      const marketplace = new MarketPlace(process.env.VUE_APP_ORDINALS_BOT_API_KEY, environment)

      const address = await this.getXverseAddress()


      const listingRequest = {
        sellerOrdinals: [{
          id: this.productInfo.inscriptionId, // ordinals inscription id
          price: this.productInfo.productCoinPrice, // satoshi
        }],
        sellerPaymentAddress: address.paymentAddress.address,
        sellerOrdinalPublicKey: address.ordinalsAddress.publicKey,
        sellerOrdinalAddress: address.ordinalsAddress.address,
      }

      console.log(listingRequest)

      const { psbt } = await marketplace.createListing(listingRequest)

      const signPsbtOptions = {
        payload: {
          network: {
            type: network
          },
          message: 'Sign Seller Transaction',
          psbtBase64: psbt,
          broadcast: false,
          inputsToSign: [{
            address: address.ordinalsAddress.address,
            signingIndexes: [0],
            sigHash: bitcoin.Transaction.SIGHASH_SINGLE | bitcoin.Transaction.SIGHASH_ANYONECANPAY
          }],
        },
        onFinish: (response) => {
          this.productInfo.sellingSignedPsbt = response.psbtBase64;
          this.$forceUpdate();
        },
        onCancel: () => alert('Canceled'),
      }

      await signTransaction(signPsbtOptions);


    },
    async createListing() {
      const psbt = "cHNidP8BAFICAAAAAVY1lBXCYZVvhcSXFz2EJdNuVh6u75ofEcUhNQ4Nj03qAAAAAAD/////AQD6AAAAAAAAFgAUOXSa9cx6l7zdzvB1IekPqJEjnDcAAAAAAAEA/TQDAQAAAAABAQRY+TUCHQgn17FJYEUOlOLYc7RAKQYEluPp0PKXRUrIAAAAAAD9////ARAnAAAAAAAAIlEgtgRNoZ4CAwLOAthJ4nz2IZbY8Vs+RTpnJH/OujIpO0YDQFtmhwsKL6354Wl9yTIG7Nvd+xVOTEA2FAktUbK6hX+n+qqmuq91/kYpGKRoJ462kZGdGdZQahTtfZ8Evyj3atb9bQIgw4+qP/fnwVIR8HPhc2zVie9pDQj+0cDpdPLGKKr1HPqsAGMDb3JkAQEJaW1hZ2UvcG5nAQUbomRuYW1lZjNzcGFjZWZhcnRpc3RmM3NwYWNlAE0IAolQTkcNChoKAAAADUlIRFIAAAARAAAAEQgGAAAAO21H+gAAAAlwSFlzAAALEwAACxMBAJqcGAAAAAFzUkdCAK7OHOkAAAAEZ0FNQQAAsY8L/GEFAAABqklEQVR4AZ2TzU7CQBCAZ7pEkIgpiokQE3uEk/gE4hPoG8DRm3LySH0C4xOIT6CPwNEbvRg5loPKgegaSSgoHWdLJW2tAZxkd/Zn9tudn0WISG6nWHFdqBKBoeYIZLmEV7LXseEPwZ+Bvl00EOmWl8qAKIHI8rcqniFC011x6tK2ZRSSmAE0bAOB5BccypfHVhDOqsatgaNkmfV+FCJUt5rJtVk5HqDXsYIGzqAvubVSa7kuIp6k13M4/Oi3QpTNQuk4my8S31iDObJRKDaz+dKbbhh6cF0joCP2WPILmvMgHHC2IT3hpMphiJcFsmAxsVVHYpq5GQSQONqoL4QQk1g7DUnrsjaifsYyQHhuTECEXq65RHfKT22cOpsH4Zg0WNny6SEEEZw+O53ZyhLAOev34aB/HwfgzFyzqnAM63wmBPGKzU0mTRyPDojoko33NA1uvhLTIIpP4G9Ap3zYc4X3dqMXYHDCtWJyeVd5aITNyCLCunoJ7ze4ma/PnYtYSABWET5oIoQVjIF/0S/Q0qJAqsrZdRXo6d9ZVvy/pLwwVTL+BQmCCNH5Bjr2qtpijdUNWgAAAABJRU5ErkJggmghwcOPqj/358FSEfBz4XNs1YnvaQ0I/tHA6XTyxiiq9Rz6AAAAAAEBKxAnAAAAAAAAIlEgtgRNoZ4CAwLOAthJ4nz2IZbY8Vs+RTpnJH/OujIpO0YBAwSDAAAAARcgOca3g3xZddXEAfdBBCGwHESXd5l0V0MAXJ5xNs+iT4wAAA=="

      const params = {
        hex: bitcoin.Psbt.fromBase64(psbt, {network: bitcoin.networks.testnet}).toHex(),
        allowedSighash: [bitcoin.Transaction.SIGHASH_SINGLE , bitcoin.Transaction.SIGHASH_ANYONECANPAY],
        signAtIndex: [0],
        network: "testnet",
      }

      console.log(params)
      const result = await window.btc.request('signPsbt', params);

      console.log(bitcoin.Psbt.fromBase64(psbt, {network: bitcoin.networks.testnet}).txInputs)
      console.log(bitcoin.Psbt.fromBase64(psbt, {network: bitcoin.networks.testnet}).txOutputs)

      return result.result.hex;
    },
    // 공통 >>> Start
    onClickTab() {
      this.nftInfo = this.getInitNftInfo();
      console.log(this.threespaceCodemirrorInstance.state);
    },
    onClickMemberSelect() {
      this.$refs["member-select-popup"].show(this.productInfo.nftType);
    },
    selectedMemberInfo(item) {
      this.$log.info("selectedMemberInfo() item: {}", item);

      const nftType = this.productInfo.nftType;
      let address;

      if(nftType === "ETHEREUM") {
        address = item.ethereumAddr;
      } else if(nftType === "KLAYTN" || nftType === "KLIP") {
        address = item.klaytnAddr;
      } else if(nftType === "POLYGON") {
        address = item.polygonAddr;
      }

      if(address){
        this.nftInfo.idxMember = item.idx;
        this.nftInfo.nickname = "(" + item.nickname + ")" + address;
      } else {
        alert(`${nftType} 지갑 주소를 확인해주세요.`)
      }
    },
    isResourceFileImage(url) {
      if (url === undefined || url === null || url === '') {
        return false;
      } else {
        if (url.endsWith(".jpg") || url.endsWith(".jpeg") || url.endsWith(".png") || url.endsWith(".gif")) {
          return true;
        } else {
          return false;
        }
      }
    },
    getInitNftInfo() {
      return {
        idx: this.idxProduct,
        idxMember: null,
        nickname: null,
        state: "TRANSFER",
      };
    },
    onClickCancel() {
      this.$router.push({name: "ProductList", params: { linkType: "comeback"}});
    },
    checkIfValid(fieldName) {
      const field = this.$v.nftInfo[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    replaceIpfsUrl(_url) {
      if (_url !== undefined && _url !== null && _url.startsWith("https://ipfs.io/")) {
        return _url.replace("https://ipfs.io/", "https://ipfs.3space.art/");
      }
      return _url;
    },
    replaceNewLine(str) {
      if (str !== undefined && str !== null && str !== "") {
        return str.replace(/(?:\r\n|\r|\n)/g, "<br />");
      } else {
        return "";
      }
    },
    hideMessageModal(){
      clearInterval(this.timer);
      clearInterval(this.totalTimer);
      this.$refs["message-modal"].hide();
      this.$router.go(this.$router.currentRoute);
    },
    selectedArtistMemberInfo(member) {
      this.$log.info("selectedArtistMemberInfo() member: {}", member);
      this.productInfo.klipGroupName = member.artistNameEn;
      this.productInfo.klipGroupIcon = member.groupIcon;
      if (this.productInfo.klipGroupIcon === undefined || this.productInfo.klipGroupIcon === null || this.productInfo.klipGroupIcon === '') {
        this.groupIconMessage = "그룹 대표이미지가 존재하지 않는 경우, 작가 관리에서 등록해주세요.";
      } else {
        this.groupIconMessage = "";
      }
      this.$forceUpdate();
    }
  },
  destroyed() {
    clearInterval(this.timer);
    clearInterval(this.totalTimer);
  }
};
</script>

<style src="spinkit/spinkit.min.css"></style>
