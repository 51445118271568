import ApiService from "@/services/api.service";
import logger from "@/logger";

// action types
export const GET_TX_RESULT = "getTxResult";

// mutation types
export const SET_TX_RESULT = "setTxResult";

const state = {
  errors: null,
  transactionInfo: null
};

const getters = {};

const actions = {
  [GET_TX_RESULT](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("/transaction/v1/result/" + payload)
        .then(({ data }) => {
          context.commit(SET_TX_RESULT, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  }
};

const mutations = {
  [SET_TX_RESULT](state, transactionInfo) {
    state.transactionInfo = transactionInfo;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
