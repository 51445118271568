<template>
  <CCard>
    <CCardBody>
      <CRow>
        <CCol class="mb-3"><h5>게시글 등록/수정</h5></CCol>
      </CRow>
      <CForm>
        <CRow>
          <CCol col="8">
            <table class="table">
              <tbody>
              <tr>
                <th>상단 고정</th>
                <td>
                  <b-form-radio-group
                      id="top"
                      name="top"
                      :options="articleTopOptions"
                      v-model="articleInfo.top"
                  />
                </td>
              </tr>
              <tr>
                <th class="col-md-2">분류 <span class="text-danger">*</span></th>
                <td class="col-md-4">
                  <CSelect
                      class="col-3 float-left px-0 mb-0"
                      :options="articleTypeOptions"
                      :value.sync="articleInfo.type"
                      :isValid="checkIfValid('type')"
                      invalidFeedback="필수 정보입니다."
                  />
                </td>
              </tr>
              <tr>
                <th class="col-md-3">
                  게시글 제목&nbsp;<span class="text-danger">*</span>
                </th>
                <td class="col-md-9">
                  <CInput
                      class="mb-0"
                      placeholder="제목을 입력하세요."
                      :lazy="false"
                      :value.sync="$v.articleInfo.title.$model"
                      :isValid="checkIfValid('title')"
                      invalidFeedback="필수 정보입니다."
                  />
                </td>
              </tr>
              <tr>
                <th class="col-md-3">
                  게시글 제목(영문) <span class="text-danger">*</span>
                </th>
                <td class="col-md-9">
                  <CInput
                      class="mb-0"
                      placeholder="제목(영문)을 입력하세요."
                      :lazy="false"
                      :value.sync="$v.articleInfo.titleEn.$model"
                      :isValid="checkIfValid('titleEn')"
                      invalidFeedback="필수 정보입니다."
                  />
                </td>
              </tr>
              <tr>
                <th class="border-bottom">작가(그룹)</th>
                <td class="border-bottom">
                  <CButton color="light" @click="onClickArtistMemberSelect">
                    <CIcon name="cil-plus" class="mr-2" />작가선택
                  </CButton>
                  <table class="table mt-2">
                    <thead>
                    <tr>
                      <th class="text-center bg-gray-100 text-dark py-2">
                        작가번호
                      </th>
                      <th class="text-center bg-gray-100 text-dark py-2">
                        작가명
                      </th>
                      <th class="text-center bg-gray-100 text-dark py-2">
                        작가 사용자이름(닉네임)
                      </th>
                      <th class="bg-gray-100"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-for="(item, index) in articleInfo.artists">
                      <tr :key="'item' + index">
                        <td class="py-0">{{ item.idx }}</td>
                        <td class="py-0">{{ item.artistName }}</td>
                        <td class="py-0">{{ item.nickname }}</td>
                        <td class="py-0">
                          <CButton
                              variant="ghost"
                              color="right"
                              @click="onClickArtistMemberDelete(item.idx)"
                          >
                            <CIcon name="cil-x" />
                          </CButton>
                        </td>
                      </tr>
                    </template>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <th>큐레이터</th>
                <td>
                  <CInput
                      class="mb-0"
                      placeholder="큐레이터를 입력하세요."
                      v-model="articleInfo.curator"
                  />
                </td>
              </tr>
              <tr>
                <th>위치</th>
                <td>
                  <CInput
                      class="mb-0"
                      placeholder="위치를 입력하세요."
                      v-model="articleInfo.location"
                  />
                </td>
              </tr>
              <tr>
                <th class="border-bottom">게시여부</th>
                <td class="border-bottom">
                  <b-form-radio-group
                      size="sm"
                      v-model="articleInfo.display"
                      :options="displayOptions"
                  ></b-form-radio-group>
                </td>
              </tr>
              </tbody>
            </table>
          </CCol>

          <CCol col="4">
            <div>
              <strong>대표 이미지 <span class="text-danger">*</span></strong>
              <b-form-file
                  v-model="image"
                  placeholder="대표 이미지를 선택하세요."
                  accept=".jpg, .jpeg, .png, .gif"
                  class="mt-2"
                  @change="onClickImage"
              ></b-form-file>
              <div class="mt-2">
                <template v-if="articleInfo.image !== undefined">
                  {{ articleInfo.image }}<br />
                  <CImg
                      width="100%"
                      height="100%"
                      :src="articleInfo.imageUrl"
                      class="mt-1"
                  />
                </template>
                <template v-else>(정보없음)</template>
              </div>
            </div>
          </CCol>

          <CCol col="12">
            <b-tabs
                active-nav-item-class="bg-info text-light font-weight-bold"
                content-class="mt-3"
                v-model="tabIndex"
            >
              <b-tab title="한글">
                <h6><strong>내용</strong></h6>
                <CIcon name="cil-info" /> 내용을 입력 후 텍스트를 블록 선택하면 편집기가 표시됩니다.<br/>
                <CIcon name="cil-info" /> 내용에 삽입 할 이미지는 가로 1024px 또는 512px 사이즈의 JPG 파일로 준비합니다.(선택)<br/>
                <CIcon name="cil-info" /> 폰트는 디폴트 18px, 블랙컬러를 기본으로 설정 후, 용도에 따라 조정해주세요.(PC/모바일 동일사이즈로 표시됨)<br/>
                <CIcon name="cil-info" /> 이미지가 포함된 단락을 개행하고자 하는 경우 빈 테이블을 활용하세요.(임시 - table/cell border: solid, white 로 설정)<br/>
                <CIcon name="cil-info" /> 영문(또는 한글) 내용이 없는 경우, 한글(영문)에 설정된 동일한 정보가 표시됩니다.
                <div class="article-editor mt-2">
                  <ckeditor :editor="editor" v-model="articleInfo.content" :config="editorConfig"></ckeditor>
                </div>
              </b-tab>
              <b-tab title="영어">
                <h6><strong>내용</strong></h6>
                <div class="article-editor">
                  <ckeditor :editor="editor" v-model="articleInfo.contentEn" :config="editorConfig"></ckeditor>
                </div>
              </b-tab>
            </b-tabs>
          </CCol>
        </CRow>

        <CRow>
          <CCol col="12" class="text-center my-4">
            <CButton color="light" @click="onClickCancel()" class="mr-2">
              <CIcon name="cil-x-circle" class="mr-2" />취소
            </CButton>
            <CButton color="dark" @click="onClickDelete()" class="mr-2 text-white">
              <CIcon name="cil-trash" class="mr-2" />삭제
            </CButton>
            <CButton color="info" @click="onClickSave()" class="text-white">
              <CIcon name="cil-save" class="mr-2" />저장
            </CButton>
          </CCol>
        </CRow>
      </CForm>

      <ArtistSelectPopup
          ref="artistSelectPopup"
          @artist-selected="selectedArtistMemberInfo"
      />
    </CCardBody>
  </CCard>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { createNamespacedHelpers } from "vuex";
const articleHelper = createNamespacedHelpers("article");

import ArtistSelectPopup from "@/views/management/artist/ArtistSelectPopup";

import CKEditor from "@ckeditor/ckeditor5-vue2";
import BalloonEditor from "@ckeditor/ckeditor5-editor-balloon/src/ballooneditor";
import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials";
import Autoformat from "@ckeditor/ckeditor5-autoformat/src/autoformat";
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import BlockQuote from "@ckeditor/ckeditor5-block-quote/src/blockquote";
import FontBackgroundColor from "@ckeditor/ckeditor5-font/src/fontbackgroundcolor";
import FontColor from "@ckeditor/ckeditor5-font/src/fontcolor";
import FontSize from "@ckeditor/ckeditor5-font/src/fontsize";
import FontFamily from "@ckeditor/ckeditor5-font/src/fontfamily";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import Image from "@ckeditor/ckeditor5-image/src/image";
import ImageCaption from "@ckeditor/ckeditor5-image/src/imagecaption";
import ImageResizeButtons from '@ckeditor/ckeditor5-image/src/imageresize/imageresizebuttons';
import ImageResizeEditing from '@ckeditor/ckeditor5-image/src/imageresize/imageresizeediting';
import ImageResizeHandles from '@ckeditor/ckeditor5-image/src/imageresize/imageresizehandles';
import ImageStyle from "@ckeditor/ckeditor5-image/src/imagestyle";
import ImageToolbar from "@ckeditor/ckeditor5-image/src/imagetoolbar";
import ImageUpload from "@ckeditor/ckeditor5-image/src/imageupload";
import Indent from "@ckeditor/ckeditor5-indent/src/indent";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
import Link from "@ckeditor/ckeditor5-link/src/link";
import List from "@ckeditor/ckeditor5-list/src/list";
import MediaEmbed from "@ckeditor/ckeditor5-media-embed/src/mediaembed";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import Table from "@ckeditor/ckeditor5-table/src/table";
import TableCellProperties from "@ckeditor/ckeditor5-table/src/tablecellproperties";
import TableProperties from "@ckeditor/ckeditor5-table/src/tableproperties";
import TableToolbar from "@ckeditor/ckeditor5-table/src/tabletoolbar";
import Underline from "@ckeditor/ckeditor5-basic-styles/src/underline";
import Alignment from "@ckeditor/ckeditor5-alignment/src/alignment";
// Custom Plugin
import ImageUploadAdapterPlugin from "@/utils/editor/ImageUploadAdapter";

export default {
  name: "ArticleSave",
  components: {
    ArtistSelectPopup,
    ckeditor: CKEditor.component
  },
  props: {
    idxArticle: {
      type: String,
      default: "0"
    }
  },
  data: function() {
    return {
      editor: BalloonEditor,
      editorConfig: {
        plugins: [
          Essentials,
          Autoformat,
          Bold,
          Italic,
          Underline,
          BlockQuote,
          FontBackgroundColor,
          FontColor,
          FontSize,
          FontFamily,
          Heading,
          Image,
          ImageCaption,
          ImageResizeButtons,
          ImageResizeEditing,
          ImageResizeHandles,
          ImageStyle,
          ImageToolbar,
          ImageUpload,
          Indent,
          Link,
          List,
          MediaEmbed,
          Paragraph,
          Table,
          TableCellProperties,
          TableProperties,
          TableToolbar,
          Alignment
        ],
        toolbar: {
          items: [
            "heading",
            "|",
            "bold", "italic", "underline", "fontBackgroundColor", "fontColor", "fontSize", "fontFamily", "link",
            "|",
            "bulletedList", "numberedList", "alignment", "outdent", "indent",
            "|",
            "blockQuote", "uploadImage", "insertTable",
            "|",
            "undo", "redo"
          ]
        },
        alignment: {
          options: ['left', 'center', 'right', 'justify'],
        },
        fontFamily: {
          supportAllValues: true
        },
        fontSize: {
          //options: [ "tiny", "small", "default", "big", "huge" ]
          options: [ 8, 10, 12, 14, 16, 18, 20, 22, 24, 28, 32, 36, 40, 44 ]
        },
        image: {
          styles: ['alignCenter', 'alignLeft', 'alignRight'],
          resizeOptions: [
            {
              name: 'resizeImage:original',
              label: 'Original',
              value: null
            },
            {
              name: 'resizeImage:25',
              label: '25%',
              value: '25'
            },
            {
              name: 'resizeImage:30',
              label: '30%',
              value: '30'
            },
            {
              name: 'resizeImage:40',
              label: '40%',
              value: '40'
            },
            {
              name: 'resizeImage:50',
              label: '50%',
              value: '50'
            },
            {
              name: 'resizeImage:75',
              label: '75%',
              value: '75'
            },
            {
              name: 'resizeImage:90',
              label: '90%',
              value: '90'
            },
            {
              name: 'resizeImage:100',
              label: '100%',
              value: '100'
            }
          ],
          toolbar: [
            'imageTextAlternative', 'toggleImageCaption', '|',
            'imageStyle:inline', 'imageStyle:wrapText', 'imageStyle:breakText', 'imageStyle:side', '|',
            'resizeImage'
          ]
        },
        table: {
          contentToolbar: [
            'tableColumn',
            'tableRow',
            'mergeTableCells',
            'tableProperties',
            'tableCellProperties'
          ]
        },
        tableProperties: {
        },
        tableCellProperties: {
        },
        extraPlugins: [ImageUploadAdapterPlugin],
        language: "ko",
        placeholder: "내용을 입력하세요..."
      },
      articleInfo: {
        artists: [],
        top: false,
        title: "",
        titleEn: "",
        curator: "",
        location: "",
        content: "",
        contentEn: "",
        display: true,
      },
      image: null,
      articleTopOptions: [
        { value: "false", text: "미해당" },
        { value: "true", text: "해당" }
      ],
      articleTypeOptions: [
        { value: "", label: "선택" },
        { value: "FeaturedArtist", label: "Featured artist" },
        { value: "WEB3", label: "Web 3.0" },
        { value: "NEWS", label: "News" },
        { value: "EVENT", label: "Event" },
      ],
      displayOptions: [
        { text: '노출', value: true },
        { text: '미노출', value: false }
      ],
      tabIndex: 0,
    };
  },
  computed: {
    isValid() {
      return !this.$v.articleInfo.$invalid;
    },
    isDirty() {
      return this.$v.articleInfo.$anyDirty;
    }
  },
  mixins: [validationMixin],
  validations() {
    return {
      articleInfo: {
        title: { required },
        type: { required },
        titleEn: { required }
      }
    };
  },
  mounted() {
    this.requestArticleItem();
  },
  methods: {
    ...articleHelper.mapActions([
      "addArticle",
      "updateArticle",
      "deleteArticle",
      "getArticle",
      "updateImageUpload"
    ]),
    requestArticleItem() {
      this.$log.info("idxArticle=", this.idxArticle);
      if (this.idxArticle && this.idxArticle !== "0") {
        this.getArticle(this.idxArticle).then(data => {
          this.$log.info(data);
          this.articleInfo = data;
        });
      }
    },
    onClickArtistMemberSelect() {
      this.$refs["artistSelectPopup"].show();
    },
    selectedArtistMemberInfo(artist) {
      this.$log.info("selectedArtistMemberInfo() artist: {}", artist);
      let isExist = false;
      for (let i = 0; i < this.articleInfo.artists.length; i++) {
        const item = this.articleInfo.artists[i];
        this.$log.info(item.idx, artist.idx);
        if (item.idx === artist.idx) {
          isExist = true;
          break;
        }
      }
      if (!isExist) {
        this.articleInfo.artists.push({
          idx: artist.idx,
          artistName: artist.artistName,
          nickname: artist.nickname
        });
      }
    },
    onClickArtistMemberDelete(idx) {
      const _idx = this.articleInfo.artists.findIndex(
          item => item.idx === idx
      );
      if (_idx > -1) this.articleInfo.artists.splice(_idx, 1);
    },
    onClickCancel() {
      this.$router.push("/management/article-list");
    },
    onClickDelete() {
      if (this.idxArticle && this.idxArticle !== "0") {
        this.deleteArticle(this.idxArticle).then(() => {
          alert("삭제되었습니다.");
          this.$router.push("/management/article-list");
        });
      }
    },
    onClickSave() {
      this.$log.info("click onClickSave()");
      this.$v.$touch();
      if (this.isValid) {
        this.$log.info("this.articleInfo=", this.articleInfo);

        if (this.idxArticle && this.idxArticle !== "0") {
          this.updateArticle(this.articleInfo).then(() => {
            this.updateImage(this.idxArticle)
          });
        } else {
          this.addArticle(this.articleInfo).then(data => {
            this.updateImage(data.idx)
          });
        }
      }
    },
    updateImage(idxArticle) {
      this.$log.info("idxArticle={}, image={}", idxArticle, this.image);
      if (!!this.image) {
        const formData = new FormData();
        formData.append("idxArticle", idxArticle);
        formData.append("image", this.image);
        this.updateImageUpload(formData)
            .then(() => {
              alert("저장되었습니다.");
              this.$router.push("/management/article-list");
            })
            .catch(error => {
              alert("게시글 수정 오류가 발생하였습니다. 관리자에게 문의하세요.");
              this.$log.error(error);
            });
      } else {
        alert("저장되었습니다.");
        this.$router.push("/management/article-list");
      }
    },
    onClickImage(event) {
      this.image = event.target.files[0];
      this.$log.info("click onClickImage()", this.image);
    },
    checkIfValid(fieldName) {
      const field = this.$v.articleInfo[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    }
  }
};
</script>
