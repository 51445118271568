<template>
  <li :class="{ active: isActive }">
    <div class="header-hover__main">
      <span>3SPACE</span>
      <img src="/img/threespace/icon/chevron.svg" />
    </div>
    <div class="header-hover__menu">
      <router-link to="/about">
        {{ $t("menu.3spaceSub.about") }}
      </router-link>
      <router-link to="/art-pool/pace2.0"> $PACE </router-link>
      <a href="https://docs.3space.art" target="_blank">
        {{ $t("menu.3spaceSub.whitePaper") }}
      </a>
      <router-link to="/features">
        {{ $t("menu.3spaceSub.features") }}
      </router-link>
      <router-link to="/guide">
        {{ $t("menu.3spaceSub.guide") }}
      </router-link>
    </div>
  </li>
</template>

<script>
export default {
  name: "HeaderDropdownAbout",
  data() {
    return {};
  },
  computed: {
    isActive() {
      const activeLink = ["/about", "/art-pool/pace2.0", "/features", "/guide"];
      return activeLink.includes(this.$route.path);
    },
  },
  methods: {},
};
</script>
