<template>
  <CCard>
    <CCardBody>
      <CRow>
        <CCol class="mb-3"><h5>게시글</h5></CCol>
      </CRow>
      <CForm>
        <CRow>
          <CCol col="8">
            <table class="table">
              <tbody>
                <tr>
                  <th>상단 고정</th>
                  <td>
                    <b-form-radio-group
                      id="artistGroup"
                      name="artistGroup"
                      :options="articleTopOptions"
                      v-model="articleInfo.top"
                      disabled-field="notEnabled"
                    />
                  </td>
                </tr>
                <tr>
                  <th class="col-md-2">분류</th>
                  <td class="col-md-4">{{ articleInfo.type }}</td>
                </tr>
                <tr>
                  <th class="col-md-3">게시글 제목</th>
                  <td class="col-md-9">{{ articleInfo.title }}</td>
                </tr>
                <tr>
                  <th>게시글 제목(영문)</th>
                  <td>{{ articleInfo.titleEn }}</td>
                </tr>
                <tr>
                  <th class="border-bottom">작가(그룹)</th>
                  <td class="border-bottom">
                    <table class="table mt-2">
                      <thead>
                        <tr>
                          <th class="text-center bg-gray-100 text-dark py-2">
                            회원번호
                          </th>
                          <th class="text-center bg-gray-100 text-dark py-2">
                            작가명
                          </th>
                          <th class="text-center bg-gray-100 text-dark py-2">
                            작가 사용자이름(닉네임)
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <template v-for="(item, index) in articleInfo.artists">
                          <tr :key="'item' + index">
                            <td>{{ item.idx }}</td>
                            <td>{{ item.artistName }}</td>
                            <td>{{ item.nickname }}</td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <th>큐레이터</th>
                  <td>{{ articleInfo.curator }}</td>
                </tr>
                <tr>
                  <th>위치</th>
                  <td>{{ articleInfo.location }}</td>
                </tr>
              </tbody>
            </table>
          </CCol>
          <CCol col="4">
            <div>
              <strong>대표 이미지</strong>
              <div>
                {{ articleInfo.image }}<br />
                <template v-if="articleInfo.image !== undefined">
                  <CImg
                    width="100%"
                    height="100%"
                    :src="articleInfo.imageUrl"
                    class="mt-1"
                  />
                </template>
              </div>
            </div>
          </CCol>

          <CCol col="12">
            <b-tabs
              active-nav-item-class="bg-info text-light font-weight-bold"
              content-class="mt-3"
              v-model="tabIndex"
            >
              <b-tab title="한글">
                <h6><strong>내용</strong></h6>
                <div v-html="articleInfo.content" class="ck ck-content article-content"></div>
              </b-tab>
              <b-tab title="영어">
                <h6><strong>내용</strong></h6>
                <div v-html="articleInfo.contentEn" class="ck ck-content article-content"></div>
              </b-tab>
            </b-tabs>
          </CCol>
        </CRow>
        <CRow>
          <CCol col="12" class="text-center my-4">
            <CButton color="light" @click="onClickConfirm()" class="mr-2">
              <CIcon name="cil-x-circle" class="mr-2" />확인
            </CButton>
            <CButton color="info" @click="onClickModify()" class="text-white">
              <CIcon name="cil-save" class="mr-2" />수정
            </CButton>
          </CCol>
        </CRow>
      </CForm>
    </CCardBody>
  </CCard>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const articleHelper = createNamespacedHelpers("article");

import "/src/assets/scss/vendors/editor/content-styles.css";

export default {
  name: "ArticleDetail",
  components: {},
  props: {
    idxArticle: {
      type: String,
      default: "0"
    }
  },
  data: function() {
    return {
      articleTopOptions: [
        { value: "false", text: "미해당", notEnabled: "true" },
        { value: "true", text: "해당", notEnabled: "true" }
      ],
      articleTypeOptions: [
        { value: "", label: "선택" },
        { value: "FeaturedArtist", label: "Featured artist" },
        { value: "WEB3", label: "Web 3.0" },
        { value: "NEWS", label: "News" },
        { value: "EVENT", label: "Event" },
      ],
      tabIndex: 0
    };
  },
  computed: {
    ...articleHelper.mapState(["articleInfo"])
  },
  mounted() {
    this.requestArtistItem();
  },
  methods: {
    ...articleHelper.mapActions(["getArticle"]),
    requestArtistItem() {
      this.$log.info("idxArticle=", this.idxArticle);
      if (this.idxArticle && this.idxArticle !== "0") {
        this.getArticle(this.idxArticle).then(data => {
          this.$log.info(data);
        });
      }
    },
    onClickConfirm() {
      this.$router.push("/management/article-list");
    },
    onClickModify() {
      this.$router.push("/management/article-save/" + this.idxArticle);
    },
  }
};
</script>
