<template>
  <div id="ArtistDetail">
    <div class="artist_detail_wrap pb-5">

      <div class="payment_complete_modal">
        <template v-if="message !== undefined && message === 'USER_PAY_SUCCESS'">
          <div class="title">
            <p v-html="$t('payment.completePayment')"></p>
          </div>
          <div class="content">
            <b-table-simple borderless>
              <!-- 작품명 -->
              <tr>
                <th>{{ $t("payment.artwork.title") }}</th>
                <td>
                  <template v-if="activeLanguage !== undefined && activeLanguage === 'ko'">
                    {{ productName || productNameEn }}
                  </template>
                  <template v-else>
                    {{ productNameEn || productName }}
                  </template>
                </td>
              </tr>
              <!-- 소유주 -->
              <tr>
                <th>{{ $t("payment.easypay.ownership") }}</th>
                <td>
                  <template v-if="!submitted">
                    <p v-html="$t('payment.easypay.messageTransfer')"></p>
                  </template>
                  <template v-else>
                    <a
                      v-if="paymentResultInfo.klaytnScopeUrl !== ''"
                      :href="paymentResultInfo.klaytnScopeUrl"
                      target="_blank"
                    >
                      <template v-if="nftType === 'ETHEREUM'">
                        {{ $t("payment.easypay.etherscan") }}
                      </template>
                      <template v-else>
                        {{ $t("payment.easypay.klaytnScope") }}
                      </template>
                    </a>
                    <p v-else>
                      <template v-if="nftType === 'ETHEREUM'">
                        {{ $t("payment.easypay.etherscan") }}
                      </template>
                      <template v-else>
                        {{ $t("payment.easypay.klaytnScope") }}
                      </template>
                    </p>
                  </template>
                </td>
              </tr>
              <!-- 가격 -->
              <tr>
                <th>{{ $t("payment.artwork.price") }}</th>
                <td>
                  {{ productPrice | currency }} KRW
                </td>
              </tr>
              <!-- 주문일 -->
              <tr>
                <th>{{ $t("payment.easypay.orderDate") }}</th>
                <td>
                  {{ orderDate }}
                </td>
              </tr>
            </b-table-simple>
          </div>
        </template>
        <template v-else>
          <div class="title">
            <p>
              <span>요청에 실패하였습니다.</span><br/>
              이용중인 서비스에서 확인 후 다시 시도해주세요.
            </p>
          </div>
        </template>
        <div class="btn_box">
          <b-button
            class="goCollection"
            @click="goMyPage"
          >
            {{ $t("payment.goto.myPage") }}
          </b-button>
          <b-button
            class="goArtpool"
            @click="goArtpool"
            v-if="message !== undefined && message === 'USER_PAY_SUCCESS'"
          >
            {{ $t("payment.goArtPool") }}
          </b-button>
          <b-button
            class="goArtpool"
            @click="goDrops"
            v-else
          >
            {{ $t("payment.goto.back") }}
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const authHelper = createNamespacedHelpers("auth");
const paymentHelper = createNamespacedHelpers("payment");

export default {
  data() {
    return {
      submitted: false,
      message: null,
      productName: null,
      productNameEn: null,
      productPrice: null,
      nftType: null,
      orderDate: null,
      idxOrderPayment: null,
      paymentResultInfo: {}
    };
  },
  computed: {
    ...authHelper.mapState(["me"]),
    ...paymentHelper.mapState(["transferNFTStatus"]),
    activeLanguage() {
      return this.getActiveLanguage();
    }
  },
  mounted() {
    this.preventBack();

    this.message = this.$route.query.message;
    this.productName = this.$route.query.productName;
    this.productNameEn = this.$route.query.productNameEn;
    this.productPrice = this.$route.query.productPrice;
    this.nftType = this.$route.query.nftType;
    this.orderDate = this.$route.query.orderDate;
    this.idxOrderPayment = this.$route.query.idxOrderPayment;

    this.$nextTick(() => {
      if (!this.transferNFTStatus.includes(this.idxOrderPayment)) {
        this.requestTransferNFT();
      } else {
        alert("유효기간이 초과되었습니다. 이용중인 서비스에서 확인 후 다시 시도해주세요.");
      }
    });
  },
  methods: {
    ...paymentHelper.mapActions(["transferNFT"]),
    preventBack() {
      const thisObject = this;
      history.pushState(null, null, location.href);
      window.onbeforeunload = null;

      window.onpopstate = function () {
        history.go(1);
      };
    },
    requestTransferNFT() {
      if (this.message !== undefined && this.message === "USER_PAY_SUCCESS" && this.idxOrderPayment && !this.submitted) {

        // NFT 전송 >> TODO 폴링 방식으로 변경 필요!!
        this.transferNFT({ idxOrderPayment: this.idxOrderPayment })
          .then(data => {
            //this.$log.log("[completePaymentCardMobile] >> ", data);
            this.paymentResultInfo = data;
            this.submitted = true;

            // 판매 법인 정보 삭제
            localStorage.removeItem("referralCorpSeller_" + this.paymentResultInfo.idxProduct);
          })
          .catch(error => {
            this.$log.error(error);
            if (error.data.reason === "BAD_REQUEST") {
              if (error.data.resource === "NFT_TRANSFER") {
                alert("요청에 실패하였습니다. 이용중인 서비스에서 확인 후 다시 시도해주세요.");
              } else if (error.data.resource === "NFT_TRANSFER_PROGRESS") {
                alert("NFT 전송 시간이 지연되고 있습니다. 잠시후 다시 확인해주세요.");
              } else {
                alert("NFT 전송 오류가 발생하였습니다. 관리자에게 문의하세요.");
              }
            } else {
              alert("NFT 전송 오류가 발생하였습니다. 관리자에게 문의하세요.");
            }
          });
      }
    },
    goMyPage() {
      if (this.me.idx > 0) {
        this.$router.push({
          name: "MyPage",
          params: { idxMember: this.me.idx, activeTab: 2 }
        });
      }
    },
    goDrops() {
      this.$router.push({ name: "Drops" });
    },
    goArtpool() {
      this.$router.push({ name: "NftPool" });
    },
    getActiveLanguage() {
      return localStorage.getItem("language") || "en";
    }
  },
  filters: {
    currency: value => {
      if (!value) return "";
      return parseInt(value)
        .toFixed(0)
        .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    }
  }
};
</script>
