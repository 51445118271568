<template>
  <div v-if="!isAuthenticated" class="wallet-connect">
    <button
      class="wallet-connect__btn"
      :class="{ white_border: $store.state.allWhiteTextHeader }"
      @click="showWalletPopupModal"
    >
      {{ $t("wallet.connect") }}
    </button>
    <WalletPopup ref="walletPopupModal" @wallet-info="onComplete" />
  </div>
  <div v-else class="wallet-connect__wrapper">
    <button @click.stop="checkBalance" class="wallet-connect__btn--connected">
      <img
        @error="replaceByDefault"
        :src="me.profileImageUrl"
        v-if="me.profileImageUrl != undefined || me.profileImageUrl !== ''"
      />
      <p>
        {{
          me.nickname === undefined
            ? "Connected!"
            : "@" + truncateNickname(me.nickname, 8)
        }}
      </p>
    </button>
    <div
      class="wallet_connect_list"
      :class="{ open: visibleWalletList }"
      v-click-outside:showWalletList="false"
    >
      <!-- profile image / nickname -->
      <div class="wallet_connect_item big account_info" @click="onClickMe">
        <div>
          <div class="img_box">
            <img
              @error="replaceByDefault"
              :src="me.profileImageUrl"
              v-if="
                me.profileImageUrl != undefined || me.profileImageUrl !== ''
              "
            />
          </div>
          <div class="text_box">
            <p class="text_semi_bold">
              {{
                me.nickname === undefined
                  ? "My Page"
                  : "@" + truncateNickname(me.nickname)
              }}
            </p>
          </div>
        </div>
        <button>
          <img src="/img/threespace/icon/setting.svg" />
        </button>
      </div>
      <section
        v-if="me.btcAddr || me.ethereumAddr || me.klaytnAddr"
        class="wallet_connect_item_wrap"
      >
        <!-- btc connect -->
        <p>Your balance</p>
        <section
          class="wallet_connect_item btc_connect big"
          v-if="me.btcAddr"
          @click="onClickBtcScan(wallet.balance.btc.simpleAddr)"
        >
          <div class="img_box">
            <img src="/img/threespace/company/bitcoin.png" />
          </div>
          <div class="text_box">
            <p>
              {{ wallet.balance.btc.simpleQuantity }}&nbsp;
              {{ wallet.balance.btc.symbol }}
              <span>
                {{ wallet.balance.btc.simpleAddr }}
              </span>
            </p>
          </div>
        </section>
        <!-- ethereum connect -->
        <section
          class="wallet_connect_item eth_connect big"
          v-if="me.ethereumAddr"
          @click="onClickEtherScan(wallet.balance.ethereum.simpleAddr)"
        >
          <div class="img_box">
            <img src="/img/threespace/company/ethereum-eth-logo.svg" />
          </div>
          <div class="text_box">
            <p>
              {{ wallet.balance.ethereum.simpleQuantity }}&nbsp;
              {{ wallet.balance.ethereum.symbol }}
              <span>
                {{ wallet.balance.ethereum.simpleAddr }}
              </span>
            </p>
          </div>
        </section>
        <!-- klay connect -->
        <section
          class="wallet_connect_item klay_connect big"
          v-if="me.klaytnAddr"
          @click="onClickKlaytnScope(wallet.balance.klaytn.simpleAddr)"
        >
          <div class="img_box">
            <img src="/img/threespace/icon/kaia-black.png" />
          </div>
          <div class="text_box">
            <p>
              {{ wallet.balance.klaytn.simpleQuantity }}&nbsp;
              {{ wallet.balance.klaytn.symbol }}
              <span>
                {{ wallet.balance.klaytn.simpleAddr }}
              </span>
            </p>
          </div>
        </section>
      </section>
      <section class="wallet_connect_item" @click="onClickClaim">
        <div class="img_box">
          <img src="/img/threespace/icon/claim.svg" />
        </div>
        <div class="text_box">
          <p>{{ $t("menu.accountSub.claim") }}</p>
        </div>
      </section>
      <!-- ethereum claim connect -->
      <!-- <div
        class="wallet_connect_item"
        v-if="me.ethereumAddr"
        @click="$router.push('/claim')"
      >
        <div class="img_box">
          <CImg src="/img/threespace/company/pace_logo.png" />
        </div>
        <div class="text_box">
          <p>
            {{ wallet.balance.pace.simpleQuantity }}&nbsp;
            {{ wallet.balance.pace.symbol }}
            <span>
              {{ wallet.balance.ethereum.simpleAddr }}
            </span>
          </p>
        </div>
      </div> -->
      <!-- klay claim connect -->
      <!-- <div
        class="wallet_connect_item"
        v-if="me.klaytnAddr"
        @click="$router.push('/claim')"
      >
        <div class="img_box">
          <img src="/img/threespace/company/opace_logo.png" />
        </div>
        <div class="text_box">
          <p>
            {{ wallet.balance.kpace.simpleQuantity }}&nbsp;
            {{ wallet.balance.kpace.symbol }}
            <span>
              {{ wallet.balance.klaytn.simpleAddr }}
            </span>
          </p>
        </div>
      </div> -->
      <!-- admin connect -->
      <section
        class="wallet_connect_item"
        @click="onClickAdminPage"
        v-if="isAdminRole || isCorpAdminRole"
      >
        <div class="img_box">
          <img src="/img/threespace/company/pace_logo.png" />
        </div>
        <div class="text_box">
          <p>Admin</p>
        </div>
      </section>
      <!-- disconnected (logout) -->
      <section
        class="wallet_connect_item disconnect_btn"
        @click="onClickLogout"
      >
        <div class="img_box">
          <img src="/img/threespace/icon/disconnect.svg" />
        </div>
        <div class="text_box">
          <p>{{ $t("menu.accountSub.disconnect") }}</p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import {
  createNamespacedHelpers,
  mapActions,
  mapGetters,
  mapState,
} from "vuex";
import WalletPopup from "@/views/threespace/WalletPopup";
const profileHelper = createNamespacedHelpers("profile");
const authHelper = createNamespacedHelpers("auth");

export default {
  name: "HeaderDropdownWallet",
  components: {
    WalletPopup,
  },
  data() {
    return {
      defaultLanguage: "en",
      errorMessage: null,
      visibleWalletList: false,
    };
  },
  directives: {
    "click-outside": {
      bind: function (el, binding, vnode) {
        // create event
        el.eventClickOutside = function (event) {
          if (!(el == event.target || el.contains(event.target))) {
            vnode.context[binding.arg](binding.value);
          }
        };
        // event binding
        document.body.addEventListener("click", el.eventClickOutside);
      },
      unbind: function (el) {
        document.body.removeEventListener("click", el.eventClickOutside);
      },
    },
  },
  computed: {
    ...mapGetters("auth", ["isAuthenticated"]),
    ...profileHelper.mapState(["memberInfo"]),
    ...mapState(["wallet"]),
    ...mapState("auth", ["loginWallet"]),
    ...mapState({
      me: (state) => {
        return state.auth.me || {};
      },
    }),
    activeLanguage() {
      return this.getActiveLanguage();
    },
    currentPath() {
      return this.$route.path;
    },
    isAdminRole() {
      if (
        this.me.roles !== undefined &&
        this.me.roles.length > 0 &&
        (this.me.roles.includes("ROLE_SUPERVISOR") ||
          this.me.roles.includes("ROLE_OPERATOR"))
      ) {
        return true;
      } else {
        return false;
      }
    },
    isCorpAdminRole() {
      if (
        this.me.roles !== undefined &&
        this.me.roles.length > 0 &&
        this.me.roles.includes("ROLE_CORP_ADMIN")
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    ...authHelper.mapActions(["verifyTokenCallback"]),
    ...profileHelper.mapActions(["getProfile", "updateProfile"]),
    ...mapActions("wallet", [
      "walletLogin",
      "walletLogout",
      "getBalance",
      "getBtcBalance",
      "getEthereumBalance",
      "getKlaytnBalance",
    ]),
    ...mapActions("auth", ["logout"]),
    showWalletPopupModal() {
      if (!this.isAuthenticated) {
        this.$refs["walletPopupModal"].login();
      } else {
        this.$refs["walletPopupModal"].showChangeWallet();
      }
    },
    checkBalance() {
      this.showWalletList(!this.visibleWalletList);
      if (!this.wallet.balance.ethereum.addr && this.me.ethereumAddr) {
        this.getEthereumBalance();
      }
      if (!this.wallet.balance.klaytn.addr && this.me.klaytnAddr) {
        this.getKlaytnBalance();
      }
      if (!this.wallet.balance.btc.addr && this.me.btcAddr) {
        this.getBtcBalance();
      }
    },
    onComplete(data) {
      if (!this.isAuthenticated) {
        this.walletLogin(data);
      } else {
        this.changeWallet(data);
      }
    },
    async changeWallet(data) {
      const wallet = data.network.toLowerCase() + "Addr";

      let memberInfo = this.memberInfo;

      if (!this.memberInfo.idx) {
        memberInfo = await this.getProfile(this.me.idx);
      }
      if (wallet) {
        memberInfo[wallet] = data.addr;
      }

      this.updateProfile(memberInfo)
        .then(() => {
          // 정보 업데이트
          this.getBalance(memberInfo[wallet]);
          this.me[wallet] = data.addr;
          this.$forceUpdate();
          this.getProfile(this.me.idx);
        })
        .catch((response) => {
          memberInfo[wallet] = null;
          this.$log.error(response);
          let errorMessage = null;
          if (response.data.error === "BAD_REQUESTED") {
            if (response.data.resource === "EXISTS_NICKNAME") {
              errorMessage = this.$t("validation.exist.username");
            } else if (response.data.resource === "EXISTS_EMAIL") {
              errorMessage = this.$t("validation.exist.email");
            } else if (response.data.resource === "EXISTS_KLAYTN_ADDR") {
              errorMessage = this.$t("validation.exist.klaytnWalletAddr");
            } else if (response.data.resource === "EXISTS_ETHEREUM_ADDR") {
              errorMessage = this.$t("validation.exist.ethereumWalletAddr");
            } else if (response.data.resource === "EXISTS_WALLET_ADDR") {
              errorMessage = this.$t("validation.exist.walletAddr");
            }
          } else {
            errorMessage = this.$t("mypage.errorProfile");
          }
          alert(errorMessage);
        });
    },
    onClickBtcScan(simpleAddr) {
      this.visibleWalletList = false;
      if (simpleAddr) {
        const url = `https://www.blockchain.com/explorer/addresses/btc/${this.me.btcAddr}`;
        window.open(url, "_blank");
      }
    },
    onClickEtherScan(simpleAddr) {
      this.visibleWalletList = false;
      if (simpleAddr) {
        const url =
          process.env.VUE_APP_ETHERSCAN_URL +
          "/address/" +
          this.me.ethereumAddr;
        window.open(url, "_blank");
      }
    },
    onClickKlaytnScope(simpleAddr) {
      this.visibleWalletList = false;
      if (simpleAddr) {
        const url =
          process.env.VUE_APP_KLAYTNSCOPE_URL +
          "/account/" +
          this.me.klaytnAddr;
        window.open(url, "_blank");
      }
    },
    replaceByDefault(e) {
      e.target.src = "/img/threespace/common/default_profile_background.png";
    },
    onClickMe() {
      this.visibleWalletList = false;
      if (this.me.idx > 0) {
        this.$parent.showMobileMenu = false;
        this.$router.push({
          name: "MyPage",
          params: { idxMember: this.me.idx },
        });
      }
    },
    onClickLogout() {
      this.walletLogout();
      this.logout().then(() => {
        this.$router.push({ name: "Drops" }).catch(() => {});
      });
    },
    showWalletList(value) {
      if (typeof value === "boolean") this.visibleWalletList = value;
      else {
        this.visibleWalletList = !this.visibleWalletList;
      }
    },
    onClickClaim() {
      this.visibleWalletList = false;
      this.$router.push("/claim");
    },
    onClickAdminPage() {
      this.visibleWalletList = false;
      if (this.me.idx !== undefined && this.me.idx > 0) {
        if (this.isAdminRole) {
          this.$router.push("/management");
        } else {
          this.$router.push("/corp-management");
        }
      }
    },
    truncateNickname(nickname, length = 10) {
      if (length !== 8 && length !== 10) {
        return nickname;
      }

      if (length === 8) {
        if (nickname.length <= 8) {
          return nickname;
        }
        return `${nickname.slice(0, 3)}...${nickname.slice(-2)}`;
      }

      if (length === 10) {
        if (nickname.length <= 10) {
          return nickname;
        }
        return `${nickname.slice(0, 4)}...${nickname.slice(-3)}`;
      }
    },
  },
};
</script>
