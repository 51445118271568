<template>
  <div>
    <b-modal
      id="payment-xverse-modal-1"
      ref="payment-xverse-modal-1"
      centered
      hide-header
      hide-footer
      body-class="text-black"
      @hide="closeModal"
    >
      <CRow>
        <CCol class="col-12">
          <div class="d-flex justify-content-end">
            <img
              class="cursor-pointer"
              src="/img/threespace/icon/close.png"
              alt="close"
              @click="$refs['payment-xverse-modal-1'].hide()"
            />
          </div>
        </CCol>
      </CRow>

      <div class="w-100 d-flex justify-content-center align-items-center">
        <!-- <img
          src="/img/threespace/company/xverse.png"
          width="34"
          class="float-left mr-1"
        /> -->
        <p
          style="
            color: #000;
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 6px;
          "
        >
          {{ $t("payment.xverse.connectTitle") }}
        </p>
        <!-- <h5 class="ml-2 mb-0">
          {{ $t("payment.xverse.connectTitle") }}
        </h5> -->
      </div>
      <div
        style="background-color: #f7f7f7; padding: 10px 20px"
        class="mt-3 px-4"
      >
        <p
          v-html="$t('payment.xverse.connectDesc')"
          style="word-break: keep-all"
        ></p>
        <!-- <p>
          {{ $t("payment.xverse.connectDesc") }}
        </p> -->
      </div>
      <div class="d-flex justify-content-center">
        <div
          class="mt-3"
          style="width: 170px; max-width: 170px; margin-right: 10px"
        >
          <button
            style="
              width: 100%;
              border: 1px solid #000;
              padding: 10px;
              height: 31px;
            "
            class="text-black"
            @click="$refs['payment-xverse-modal-1'].hide()"
          >
            {{ $t("button.cancel") }}
          </button>
        </div>
        <div class="mt-3" style="width: 170px; max-width: 170px">
          <Button
            :label="$t('button.yesPayment')"
            :onClick="showPaymentXverse2"
            :buttonStyle="{ height: '31px' }"
            :style="{ height: '31px' }"
          />
        </div>
      </div>
      <!-- <div class="my-4 d-flex justify-content-center">
        <b-button squared class="px-4" @click="hide">
          <span>{{ $t("button.cancel") }}</span>
        </b-button>
        <b-button
          variant="primary"
          squared
          class="ml-3 px-4"
          @click="showPaymentXverse2"
          :disabled="timeout"
        >
          <span class="text-white">{{ $t("button.yesPayment") }}</span>
        </b-button>
      </div> -->
    </b-modal>

    <PaymentXverse2 :payment-info="paymentInfo" ref="paymentXverse2" />
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import PaymentXverse2 from "./PaymentXverse2";
import { getProviderOrThrow, getAddress } from "sats-connect";
import Button from "@/views/threespace/components/common/Button.vue";
const paymentHelper = createNamespacedHelpers("payment");

export default {
  name: "PaymentXverse1",
  components: {
    PaymentXverse2,
    Button,
  },
  props: {
    orderPaymentInfo: {
      type: Object,
      default: () => {},
    },
    paymentProductInfo: {
      type: Object,
      default: () => {},
    },
    memberInfo: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      paymentInfo: this.getInitData(),
      time: 60,
      timeout: false,
      paymentType: "",
    };
  },
  mounted() {},
  computed: {
    isMobile() {
      return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    },
  },
  methods: {
    ...paymentHelper.mapActions(["updatePaymentBtc"]),
    show(paymentType) {
      this.time = 60;
      this.timeout = false;
      this.setTotalTimer();
      if (paymentType != undefined) {
        this.paymentType = paymentType;
      }
      this.paymentInfo = this.getInitData();
      this.$refs["payment-xverse-modal-1"].show();
    },
    async showPaymentXverse2() {
      let memberAddress = "";
      let chainId = 0;
      if (this.paymentType === "BTC") {
        memberAddress = this.memberInfo.btcAddr;
      }

      let address = null;
      try {
        await getProviderOrThrow();

        const network =
          process.env.NODE_ENV === "production" ? "Mainnet" : "Testnet";
        const getAddressOptions = {
          payload: {
            purposes: ["ordinals", "payment"],
            message: "Address for receiving Ordinals and payments",
            network: {
              type: network,
            },
          },
          onFinish: (response) => {
            const paymentAddress = response.addresses.find(
              (address) => address.purpose === "payment"
            );
            const ordinalsAddress = response.addresses.find(
              (address) => address.purpose === "ordinals"
            );
            address = {
              paymentAddress,
              ordinalsAddress,
            };
          },
          onCancel: () => alert("Request canceled"),
        };

        await getAddress(getAddressOptions);
        console.log(address);
      } catch (error) {
        alert("We can't found Xverse wallet");
      }

      console.log(address.paymentAddress.address, memberAddress);
      if (address !== null) {
        if (
          address.paymentAddress.address.toLowerCase() !==
          memberAddress.toLowerCase()
        ) {
          alert("Check your connected wallet address again");
          return;
        }
      } else {
        alert("Check your connected wallet address again");
        return;
      }

      this.paymentInfo.idxProduct = this.orderPaymentInfo.idxProduct;
      this.paymentInfo.idxMember = this.orderPaymentInfo.idxMember;
      this.paymentInfo.idxOrderPayment = this.orderPaymentInfo.idxOrderPayment;

      if (this.paymentType === "BTC") {
        // 작품 상태(예약중) 업데이트
        this.updatePaymentBtc(this.paymentInfo)
          .then((data) => {
            console.log(data);
            this.paymentInfo.fromAddr = data.fromAddr;
            this.paymentInfo.toAddr = data.toAddr;
            this.paymentInfo.amountCoin = data.amountCoin;

            clearInterval(this.totalTimer);
            this.$refs["paymentXverse2"].show(this.paymentType, address);
            this.$refs["payment-xverse-modal-1"].hide();
          })
          .catch((error) => {
            this.$log.error(error);
            alert(
              "요청에 실패하였습니다. 이용중인 서비스에서 확인 후 다시 시도해주세요."
            );
            clearInterval(this.totalTimer);
            this.cancelPayment();
          });
      }
    },
    hide() {
      if (confirm(this.$t("payment.payCancelMsg"))) {
        clearInterval(this.totalTimer);
        this.$refs["payment-xverse-modal-1"].hide();
      }
    },
    closeModal() {
      clearInterval(this.totalTimer);
    },
    getInitData() {
      return {
        idxProduct: null,
        idxMember: null,
        idxOrderPayment: null,
        status: "RESERVE",
        fromAddr: null,
        toAddr: null,
        amountCoin: null,
        coinCurrencyType: this.paymentType,
      };
    },
    cancelPayment() {
      const requestInfo = {
        idxProduct: this.paymentInfo.idxProduct,
        idxOrderPayment: this.paymentInfo.idxOrderPayment,
        status: "RESERVE_CANCEL",
      };
      // 작품 상태(예약취소, 판매중) 업데이트
      if (this.paymentType === "BTC") {
        this.updatePaymentBtc(requestInfo);
      }
      this.$refs["payment-xverse-modal-1"].hide();
    },
    setTotalTimer() {
      this.totalTimer = setInterval(() => {
        this.time--;
        if (this.time === 0) {
          clearInterval(this.totalTimer);
          this.timeout = true;
        }
      }, 1000);
    },
  },
};
</script>
