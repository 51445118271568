<template>
  <div id="CommonBoardsWrap">
    <div class="top">
      <div class="title">FEATURES</div>
      <div class="menu-wrap">
        <template v-for="(item, i) in articleTypeOptions">
          <div
            class="menu"
            :key="i"
            :class="{ active: item.value === currentArticleType }"
            @click="onClickMenu(item.value)"
          >
            {{ item.label }}
          </div>
        </template>
      </div>
    </div>
    <div class="masonry-grid">
      <template v-for="(item, i) in articleItems.content">
        <div class="masonry-item" :key="i">
          <div class="item-wrapper" @click="onClickFeaturesList(item.idx)">
            <img :src="item.imageUrl" :alt="item.title" />
            <div class="overlay">
              <div class="content">
                <h3 class="title">
                  {{ activeLanguage === "ko" ? item.title : item.titleEn }}
                </h3>
                <div class="artist">
                  <div>
                    Artist : {{ item.artists[0]?.nickname || "Unknown" }}
                  </div>
                  <div class="category">3spaceArt</div>
                </div>
                <button class="read-more">read more</button>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <!-- <transition name="feature" mode="out-in">
      <div class="contents-wrap" v-if="articleItems.content.length > 0">
        <template v-for="(item, i) in articleItems.content">
          <div class="content" :key="i">
            <div class="img-box" @click="onClickFeaturesList(item.idx)">
              <img :src="item.imageUrl" />
            </div>
            <div class="title">{{ activeLanguage === "ko" ? item.title : item.titleEn }}</div>
            <div class="bottom">
              <div class="left">
                <div class="text-wrap"  v-if="item.artists.length > 0">
                  <div class="type">FEATURED ARTISTS</div>
                  <div class="value">
                  <span v-for="(artist, j) in item.artists" :key="j">
                    {{ artist.nickname }}
                    <span v-if="item.artists.length - 1 !== j">&nbsp;/&nbsp;</span>
                  </span>
                  </div>
                </div>
                <div class="text-wrap" v-if="!(item.curator === undefined || item.curator === '')">
                  <div class="type">CURATORS</div>
                  <div class="value">
                    {{ item.curator }}
                  </div>
                </div>
              </div>
              <div class="read-more">
                READ MORE
              </div>
            </div>
          </div>
        </template>
      </div>
      <div class="cb_list" v-else>
        <h5 class="text-center py-5 text-gray">
          {{ $t("features.noResult") }}
        </h5>
      </div>
    </transition> -->
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const featuresHelper = createNamespacedHelpers("features");

export default {
  name: "FeatureList",
  data() {
    return {
      currentArticleType: "FeaturedArtist",
      articleTypeOptions: [
        { value: "FeaturedArtist", label: "Featured artist" },
        { value: "WEB3", label: "Web 3.0" },
        { value: "NEWS", label: "News" },
        { value: "EVENT", label: "Event" },
      ],
      isBusy: false,
      perPage: 10,
      pageOptions: [
        { value: 10, label: "10 개씩 보기" },
        { value: 20, label: "20 개씩 보기" },
        { value: 30, label: "30 개씩 보기" },
        { value: 50, label: "50 개씩 보기" },
      ],
      currentPage: 1,
      sort: [
        { id: "createdAt", desc: "desc" },
        { id: "idx", desc: "desc" },
      ],
      sortBy: "",
      sortDesc: false,
      requestParams: {
        type: "",
      },
    };
  },
  computed: {
    ...featuresHelper.mapState(["articleItems", "articleItemsContent"]),
    activeLanguage() {
      return this.getActiveLanguage();
    },
  },
  mounted() {
    if (this.$route.params.articleType !== undefined) {
      this.currentArticleType = this.$route.params.articleType;
    }
    this.requestFeatureItems();
  },
  methods: {
    ...featuresHelper.mapActions(["getArticles"]),
    requestFeatureItems() {
      this.isBusy = true;
      this.requestParams.type = this.currentArticleType;
      this.getArticles(
        this.getPagingParams(
          {
            page: this.currentPage - 1,
            pageSize: this.perPage,
            sorted: this.sort,
            filtered: [],
          },
          this.requestParams
        )
      )
        .then((data) => {
          //this.$log.log(data);
          //this.currentPage++;
          this.isBusy = false;
        })
        .catch((error) => {
          this.isBusy = false;
          this.$log.error(error);
        });
    },
    getPagingParams({ page, pageSize, sorted, filtered }, externalParams) {
      const params = {
        page,
        size: pageSize,
        ...externalParams,
      };

      // sorting parameter
      let sorts = [];
      for (let i = 0, length = sorted.length; i < length; i++) {
        const sort = sorted[i];
        sorts.push(`${sort.id},${sort.desc ? "desc" : "asc"}`);
      }
      params["sort"] = sorts;

      // filtering parameter
      for (let i = 0, length = filtered.length; i < length; i++) {
        const filter = filtered[i];
        params[filter.id] = filter.value;
      }

      return params;
    },
    onClickFeaturesList(idx) {
      this.$router.push("/features/" + idx); //TODO
    },
    getActiveLanguage() {
      return localStorage.getItem("language") || "en";
    },
    onClickMenu(value) {
      this.currentArticleType = value;
      this.requestFeatureItems();
      // value request
    },
  },
};
</script>

<style scoped>
.feature-item {
  display: inline-block;
}
.feature-enter-active,
.feature-leave-active {
  transition: all 1s;
}
.feature-enter,
.feature-leave-to {
  opacity: 0;
}
</style>
