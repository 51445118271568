<template>
  <div>
    <b-modal
      id="order-payment-modal-3"
      ref="order-payment-modal-3"
      size="md"
      centered
      hide-header
      hide-footer
      body-class="pb-4 text-dark"
    >
      <div class="d-flex justify-content-end">
        <img
          class="cursor-pointer"
          src="/img/threespace/icon/close.png"
          alt="close"
          @click="$refs['order-payment-modal-3'].hide()"
        />
      </div>

      <CRow class="">
        <CCol class="col-12 text-center">
          <p
            style="
              color: #000;
              font-size: 20px;
              font-weight: bold;
              margin-bottom: 6px;
            "
          >
            {{ $t("payment.vbank3.completeOrder") }}
          </p>
          <p style="font-size: 13px; margin-bottom: 12px; color: #000">
            {{ $t("payment.vbank3.completeMsgFirst") }}
            <a
              class="d-inline-block text-dark cursor-pointer"
              @click="goMyPage"
            >
              <b
                ><u>{{ $t("payment.vbank3.completeMsgLink") }}</u></b
              > </a
            >{{ $t("payment.vbank3.completeMsgLast") }}
          </p>
          <div class="d-flex justify-content-center">
            <div
              style="
                background-color: #f7f7f7;
                padding: 10px 20px;
                border: 1px solid #000;
                width: 86%;
              "
            >
              <p class="text-left" style="font-size: 12px; color: #000">
                {{ $t("payment.vbank3.orderId") }} : {{ paymentResultInfo.moid
                }}<br />
                {{ $t("payment.vbank3.orderDate") }} :
                {{ paymentResultInfo.createdAt }}
              </p>
            </div>
          </div>
        </CCol>
      </CRow>

      <CRow class="mt-4">
        <CCol style="display: flex; flex-direction: column" class="col-5 pl-5">
          <div style="font-size: 12px; font-weight: bold; color: #000">
            {{ $t("payment.artwork.title") }}
          </div>
          <p style="font-size: 12px">
            <template
              v-if="activeLanguage !== undefined && activeLanguage === 'ko'"
            >
              {{
                paymentResultInfo.productName || paymentResultInfo.productNameEn
              }}
            </template>
            <template v-else>
              {{
                paymentResultInfo.productNameEn || paymentResultInfo.productName
              }}
            </template>
          </p>
          <div
            style="
              font-size: 12px;
              font-weight: bold;
              color: #000;
              margin-top: auto;
            "
          >
            {{ $t("payment.artwork.price") }}
          </div>
          <p style="font-size: 12px">
            {{ paymentResultInfo.amt | currency }} KRW
          </p>
        </CCol>
        <CCol style="display: flex; flex-direction: column" class="col-7 pr-5">
          <div style="font-size: 12px; font-weight: bold; color: #000">
            {{ $t("payment.payMethod") }}
          </div>
          <p style="font-size: 12px; color: #000">
            {{ $t("payment.vbank3.vbank") }}<br />{{
              $t("payment.vbank2.account")
            }}
            : {{ paymentResultInfo.fnName }}
            <b>{{ paymentResultInfo.vbankNum }}</b>
          </p>
          <p style="font-size: 10px; color: #666; margin-top: 4px">
            <template
              v-if="activeLanguage !== undefined && activeLanguage === 'ko'"
            >
              <b>{{ paymentResultInfo.vbankExpDatetime }}</b>
            </template>
            {{ $t("payment.vbank3.deadlineMsg") }}
            <template v-if="activeLanguage !== 'ko'">
              : <b>{{ paymentResultInfo.vbankExpDatetime }}</b>
            </template>
          </p>
          <p style="margin-top: auto">
            <b style="font-size: 12px; font-weight: bold">{{
              $t("payment.vbank2.cashReceipt")
            }}</b>
            <br />
            <span style="font-size: 12px" v-html="getReceiptInfo()"></span>
          </p>
        </CCol>
      </CRow>
      <div class="d-flex justify-content-center mt-4">
        <div style="border-bottom: 1px solid #000; height: 1px; width: 85%" />
      </div>

      <div class="d-flex justify-content-center mt-4">
        <div class="mt-3 mr-3" style="width: 170px; max-width: 170px">
          <Button :label="$t('payment.goto.myPage')" :onClick="goMyPage" />
        </div>
        <div class="mt-3" style="width: 170px; max-width: 170px">
          <Button :label="$t('payment.goArtPool')" :onClick="goArtpool" />
        </div>
      </div>

      <!-- <CRow class="mt-5 mb-4 justify-content-center d-flex">
        <CCol class="col-3">
          <b-button
            variant="secondary"
            size="lg"
            squared
            class="btn-block mr-2"
            @click="goMyPage"
          >
            {{ $t("payment.goto.myPage") }}
          </b-button>
        </CCol>
        <CCol class="col-3">
          <b-button
            variant="primary"
            size="lg"
            squared
            class="btn-block ml-2"
            @click="goArtpool"
          >
            <span class="text-white">{{ $t("payment.goArtPool") }}</span>
          </b-button>
        </CCol>
      </CRow> -->
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Button from "@/views/threespace/components/common/Button.vue";

export default {
  name: "PaymentVBank3",
  components: {
    Button,
  },
  props: {
    paymentResultInfo: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      defaultLanguage: "en",
    };
  },
  computed: {
    ...mapState({
      me: (state) => {
        return state.auth.me || {};
      },
    }),
    activeLanguage() {
      return this.getActiveLanguage();
    },
  },
  mounted() {},
  methods: {
    getReceiptInfo() {
      //this.$log.log("[getReceiptInfo] >> ", this.paymentResultInfo);
      let receiptInfo = this.$t("payment.vbank3.noData");
      if (
        this.paymentResultInfo.receiptType !== undefined &&
        this.paymentResultInfo.receiptType !== null &&
        this.paymentResultInfo.receiptType !== ""
      ) {
        if (this.paymentResultInfo.receiptType === "0") {
          receiptInfo = this.$t("payment.vbank3.notIssued");
        } else if (this.paymentResultInfo.receiptType === "1") {
          receiptInfo =
            this.$t("payment.vbank2.incomeDeduction") +
            " " +
            this.paymentResultInfo.receiptTypeNo;
        } else if (this.paymentResultInfo.receiptType === "2") {
          receiptInfo =
            this.$t("payment.vbank2.proopOfExpense") +
            " " +
            this.paymentResultInfo.receiptTypeNo;
        }
      }
      return receiptInfo;
    },
    show() {
      this.$refs["order-payment-modal-3"].show();
    },
    hide() {
      this.$router.push({ name: "Drops" });
      this.$refs["order-payment-modal-3"].hide();
    },
    goMyPage() {
      if (this.me.idx > 0) {
        //this.$router.push("/mypage/" + this.me.idx).catch(() => {});
        this.$router.push({
          name: "MyPage",
          params: { idxMember: this.me.idx, activeTab: 2 },
        });
      } else {
        this.$router.push({ name: "Drops" });
      }
      this.$refs["order-payment-modal-3"].hide();
    },
    goDrops() {
      this.$router.push({ name: "Drops" });
      this.$refs["order-payment-modal-3"].hide();
    },
    goArtpool() {
      this.$router.push({ name: "NftPool" });
      this.$refs["order-payment-modal-3"].hide();
    },
    getActiveLanguage() {
      return localStorage.getItem("language") || this.defaultLanguage;
    },
  },
  filters: {
    currency: (value) => {
      if (!value) return "";
      return parseInt(value)
        .toFixed(0)
        .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    },
  },
};
</script>
