<template>
  <b-modal
    id="nftApproveModal"
    ref="nftApproveModal"
    hide-header
    hide-footer
    centered
    body-class="text-center text-black py-3"
  >
    <div class="d-flex justify-content-end">
      <img
        class="cursor-pointer"
        src="/img/threespace/icon/close.png"
        alt="close"
        @click="$refs['nftApproveModal'].hide()"
      />
    </div>
    <!-- <b-img
      class="mb-3"
      src="/img/threespace/logo/logo_100x100.png"
      width="55"
    ></b-img> -->
    <h5 class="my-3" style="font-size: 20px">
      {{ $t("stake.staking.requiredApprove") }}
    </h5>
    <div class="d-flex justify-content-center mb-3">
      <div
        class="mt-3"
        style="width: 170px; max-width: 170px; margin-right: 10px"
      >
        <Button :label="$t('button.close')" :onClick="hide" />
      </div>
      <div class="mt-3" style="width: 170px; max-width: 170px">
        <Button :label="$t('button.approve')" :onClick="onClickNftApproveBtn" />
      </div>
    </div>
    <!-- <div class="w-100 text-center mt-5">
      <b-button
        squared
        variant="secondary"
        class="text-primary px-4 py-2 mr-3"
        @click="hide"
      >
        {{ $t("button.close") }}
      </b-button>
      <b-button
        squared
        variant="primary"
        class="text-white px-4 py-2 mr-3"
        @click="onClickNftApproveBtn"
      >
        {{ $t("button.approve") }}
      </b-button>
    </div> -->
  </b-modal>
</template>

<script>
import Button from "@/views/threespace/components/common/Button.vue";
export default {
  name: "ArtPoolApproveModal",
  components: {
    Button,
  },
  data: function () {
    return {};
  },
  methods: {
    show() {
      this.$refs["nftApproveModal"].show();
    },
    hide() {
      this.$refs["nftApproveModal"].hide();
    },
    onClickNftApproveBtn() {
      this.$emit("onClickNftApproveBtn");
    },
  },
};
</script>
