import ApiService from "@/services/api.service";
import logger from "@/logger";

// action types
export const GET_TRENDING_ARTISTS = "getTrendingArtists";
export const GET_ARTISTS = "getArtists";
export const UPDATE_ARTIST_FOLLOW = "updateArtistFollow";

// mutation types
export const SET_TRENDING_ARTISTS = "setTrendingArtists";
export const SET_ARTISTS = "setArtists";
export const RESET_ARTISTS = "resetArtists";

const state = {
  errors: null,
  trendingArtistItems: [],
  artistItems: {
    content: [],
    first: true,
    last: false,
    number: 0,
    numberOfElements: 0,
    size: 0,
    sort: {
      orders: []
    },
    total: 0,
    totalElements: 0,
    totalPages: 1
  },
  artistItemsContent: []
};

const getters = {};

const actions = {
  [GET_TRENDING_ARTISTS](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("/threespace/v1/artists/trending", payload)
        .then(({ data }) => {
          context.commit(SET_TRENDING_ARTISTS, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [GET_ARTISTS](context, payload) {
    if (payload.page === 0) {
      context.commit(RESET_ARTISTS);
    }
    return new Promise((resolve, reject) => {
      ApiService.get("/threespace/v1/artists", payload)
        .then(({ data }) => {
          context.commit(SET_ARTISTS, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  },
  [UPDATE_ARTIST_FOLLOW](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("/threespace/v1/artist/follow", payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    });
  }
};

const mutations = {
  [SET_TRENDING_ARTISTS](state, artistList) {
    state.trendingArtistItems = artistList;
  },
  [SET_ARTISTS](state, artists) {
    state.artistItems = artists;
    state.artistItemsContent.push(...artists.content);
  },
  [RESET_ARTISTS](state) {
    state.artistItemsContent = [];
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
