<template>
  <b-modal
    id="pacePoolStartImageModal"
    modal-class="artpool-user-guide-modal pace_pool"
    ref="pacePoolStartImageModal"
    hide-header
    hide-footer
    centered
    body-class="text-center text-black my-4"
    @hide="modalEvent(false)"
    @show="modalEvent(true)"
  >
    <div class="user_guide_modal">
      <img src="/img/threespace/artpool/pace_pool_popup.jpg" />
      <div class="close_btn" @click="hide()">close btn</div>
      <div class="not_seen_week_btn" @click="onClickNotSeenBtn()">일주일동안 보지않기</div>
    </div>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: "PacePoolStartImageModal",
  props: {},
  data: function () {
    return {
    }
  },
  methods: {
    show() {
      this.$refs["pacePoolStartImageModal"].show();
    },
    hide() {
      this.$refs["pacePoolStartImageModal"].hide();
    },
    onClickNotSeenBtn() {
      this.$emit("onClickPopupNoneSeenWeekBtn");
    },
    modalEvent(isVisible) {
      if (isVisible) {
        // open event hook
      } else {
        // close event hook ***>> modal data init here
        this.unstakingAmount = "";
      }
    }
  },
}
</script>
