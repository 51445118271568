<template>
  <CCard>
    <CCardBody>
      <CRow>
        <CCol class="mb-3"><h5>NFT 등록/수정</h5></CCol>
      </CRow>

      <CRow>
        <CCol col="12">
          <b-table-simple>
            <colgroup>
              <col style="width:12%;" />
              <col style="width:38%;" />
              <col style="width:12%;" />
              <col style="width:38%;" />
            </colgroup>
            <b-tbody>
              <b-tr>
                <b-th variant="secondary">이름&nbsp;<span class="text-danger">*</span></b-th>
                <b-td>
                  <CInput
                    class="mb-0"
                    placeholder="이름을 입력해주세요."
                    :lazy="false"
                    :value.sync="$v.nftInfo.name.$model"
                    :isValid="checkIfValid('name')"
                    invalidFeedback="필수 정보입니다."
                  />
                </b-td>
                <b-th variant="secondary">심볼&nbsp;<span class="text-danger">*</span></b-th>
                <b-td>
                  <CInput
                    class="mb-0"
                    placeholder="심볼을 입력해주세요."
                    :lazy="false"
                    :value.sync="$v.nftInfo.symbol.$model"
                    :isValid="checkIfValid('symbol')"
                    invalidFeedback="필수 정보입니다."
                  />
                </b-td>
              </b-tr>
              <b-tr>
                <b-th variant="secondary">컨트랙트 주소&nbsp;<span class="text-danger">*</span></b-th>
                <b-td>
                  <CInput
                    class="mb-0"
                    placeholder="컨트랙트 주소을 입력해주세요."
                    :lazy="false"
                    :value.sync="$v.nftInfo.contractAddr.$model"
                    :isValid="checkIfValid('contractAddr')"
                    invalidFeedback="필수 정보입니다."
                  />
                </b-td>
                <b-th variant="secondary">네트워크&nbsp;<span class="text-danger">*</span></b-th>
                <b-td>
                  <b-form-radio-group
                    id="network"
                    name="network"
                    :options="networkOptions"
                    v-model="nftInfo.network"
                  />
                </b-td>
              </b-tr>
              <b-tr>
                <b-th variant="secondary">아이콘이미지</b-th>
                <b-td>
                  <div class="img_box">
                    <template v-if="nftInfo.iconImage !== undefined && nftInfo.iconImage !== null && nftInfo.iconImage !== ''">
                      <img width="100" :src="nftInfo.iconImageUrl" />
                    </template>
                  </div>
                  <v-file-input
                    accept="image/jpg, image/jpeg, image/png, image/gif"
                    v-model="iconImage"
                    @change="onClickIconImage"
                  ></v-file-input>
                </b-td>
                <b-th variant="secondary">웹사이트</b-th>
                <b-td>{{ nftInfo.officialSite }}</b-td>
              </b-tr>
              <b-tr class="border-bottom">
                <b-th variant="secondary">비고</b-th>
                <b-td colspan="3">
                  <CTextarea
                    rows="3"
                    v-model="nftInfo.remarks"
                    class="mb-0"
                  />
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </CCol>
      </CRow>

      <CRow>
        <CCol col="12" class="text-center my-4">
          <CButton color="light" @click="onClickCancel()" class="mr-2">
            <CIcon name="cil-x-circle" class="mr-2" />취소
          </CButton>
          <!--
          <CButton color="secondary" @click="onClickDelete()" class="mr-2">
            <CIcon name="cil-trash" class="mr-2" />삭제
          </CButton>
          -->
          <CButton color="dark" @click="onClickSave()" class="text-white">
            <CIcon name="cil-save" class="mr-2" />저장
          </CButton>
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { createNamespacedHelpers } from "vuex";
const nftHelper = createNamespacedHelpers("nftManager");

export default {
  name: "NftSave",
  props: {
    idxNft: {
      type: String,
      default: "0"
    }
  },
  data() {
    return {
      networkOptions: [
        { value: "KLAYTN", text: "Kaia"},
        { value: "ETHEREUM", text: "Ethereum"},
        { value: "POLYGON", text: "Polygon"}
      ],
      iconImage: []
    };
  },
  computed: {
    ...nftHelper.mapState(["nftInfo"]),
    isValid() {
      return !this.$v.nftInfo.$invalid;
    },
    isDirty() {
      return this.$v.nftInfo.$anyDirty;
    }
  },
  mixins: [validationMixin],
  validations: {
    nftInfo: {
      name: { required },
      symbol: { required },
      contractAddr: { required }
    }
  },
  mounted() {
    this.requestNftItem();
  },
  methods: {
    ...nftHelper.mapActions(["resetNft", "getNft", "addNft", "updateNft", "uploadNftIconImage"]),
    requestNftItem() {
      this.resetNft();
      if (this.idxNft && this.idxNft !== "0") {
        this.getNft(this.idxNft)
          .then(() => {
            this.$log.log(this.nftInfo); //TODO
          });
      }
    },
    onClickCancel() {
      this.$router.push("/management/nft-list");
    },
    onClickDelete() {
      alert("개발중");
    },
    onClickSave() {

      if (this.isValid) {

        // rfc setting
        if(this.nftInfo.network){
          if (this.nftInfo.network === "KLAYTN"){
            this.nftInfo.rfc = "KIP-17";
          } else if (this.nftInfo.network === "ETHEREUM" || this.nftInfo.network === "POLYGON") {
            this.nftInfo.rfc = "ERC-721";
          }
        }

        // NFT 정보 저장
        if (this.idxNft && this.idxNft !== "0") {
          // NFT 수정
          this.updateNft(this.nftInfo)
            .then(() => {
              alert("저장되었습니다.");
              this.$router.push("/management/nft-list");
            })
            .catch(error => {
              alert("NFT 저장 오류가 발생하였습니다. 관리자에게 문의하세요.");
              this.$log.error(error);
            });
        } else {
          // NFT 등록
          this.addNft(this.nftInfo)
            .then(() => {
              alert("저장되었습니다.");
              this.$router.push("/management/nft-list");
            })
            .catch(error => {
              alert("NFT 저장 오류가 발생하였습니다. 관리자에게 문의하세요.");
              this.$log.error(error);
            });
        }
      } else {
        this.$v.$touch();
      }
    },
    onClickIconImage() {
      if (this.iconImage.size > 5000000) {
        alert(this.$t("mypage.profileImageSizeLimit"));
      } else {
        // 프로필 이미지 파일 업로드
        if (!!this.iconImage) {
          const formData = new FormData();
          formData.append("idxNft", this.nftInfo.idx);
          formData.append("iconImage", this.iconImage);
          this.uploadNftIconImage(formData)
            .then(data => {
              this.nftInfo.iconImageUrl = data.iconImageUrl;
              // this.$router.go(0)
            })
            .catch(error => {
              this.$log.error(error);
              alert("upload 실패")
            });
        }
      }
    },
    checkIfValid(fieldName) {
      const field = this.$v.nftInfo[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    }
  }
};
</script>
