<template>
  <div class="bg-white" id="LoginWrap">
    <div class="login_btn_box m-auto text-center">
      <CLink to="/home">
        <CImg src="/img/threespace/logo/space_black.png" width="70px" />
      </CLink>

      <CRow class="justify-content-center sns_box">
        <div class="col-auto text-center">
          <div class="c-avatar c-avatar-xl mr-5" style="border: 1px solid #000">
            <img
              class="c-avatar-img cursor-pointer"
              src="/img/threespace/company/kakao_logo_100x100.png"
              @click="onLoginKakao()"
            />
          </div>
          <div class="c-avatar c-avatar-xl mr-5" style="border: 1px solid #000">
            <img
              class="c-avatar-img cursor-pointer"
              src="/img/threespace/company/naver_logo_180x180.png"
              @click="onLoginNaver()"
            />
          </div>
          <div class="c-avatar c-avatar-xl" style="border: 1px solid #000">
            <img
              class="c-avatar-img cursor-pointer"
              src="/img/threespace/company/google_logo_100x100.jpg"
              @click="onLoginGoogle()"
            />
          </div>
        </div>
      </CRow>

      <div class="or_line">
        <div class="text">OR</div>
      </div>

      <CForm @keypress.enter.prevent="onLoginSubmit()">
        <b-row class="justify-content-center mb-1">
          <b-col class="col-12 text-left">
            <div
              style="
                background-color: #f7f7f7;
                padding: 6px;
                margin-bottom: 10px;
              "
            >
              <CInput
                :lazy="false"
                :value.sync="$v.form.account.$model"
                :isValid="checkIfValid('account')"
                :placeholder="$t('login.email')"
                autocomplete="email"
                :invalidFeedback="$t('validation.requiredEmail')"
              />
            </div>
            <div style="background-color: #f7f7f7; padding: 6px">
              <CInput
                type="password"
                :lazy="false"
                :value.sync="$v.form.password.$model"
                :isValid="checkIfValid('password')"
                :placeholder="$t('login.password')"
                autocomplete="curent-password"
                :invalidFeedback="$t('validation.requiredPassword')"
              />
            </div>
          </b-col>
        </b-row>
        <div class="mb-4 overflow-hidden text-dark">
          <b-form-checkbox
            custom
            :checked.sync="isSaveAccount"
            class="float-left"
          >
            {{ $t("login.rememberMe") }}
          </b-form-checkbox>
          <a class="cursor-pointer float-right" @click="onClickFindAccount()">
            <u>{{ $t("login.findIdPw") }}</u>
          </a>
        </div>

        <p class="text-danger mt-3" v-if="errorMessage !== null">
          {{ errorMessage }}
        </p>

        <!-- <Button :label="$t('login.logIn')" :onClick="onLoginSubmit" /> -->
        <b-button block class="space_btn" @click="onLoginSubmit()">
          {{ $t("login.logIn") }}
        </b-button>
      </CForm>
      <template v-if="!isEventEnd">
        <a
          class="pt-3 d-inline-block text text-dark cursor-pointer"
          @click="join()"
        >
          <u style="text-decoration: none">[{{ $t("login.signUp") }}]</u>
        </a>
      </template>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { v4 as uuidv4 } from "uuid";
import { createNamespacedHelpers, mapActions, mapState } from "vuex";
import Button from "@/views/threespace/components/common/Button.vue";
const eventHelper = createNamespacedHelpers("event");

export default {
  name: "login",
  components: {
    Button,
  },
  metaInfo() {
    return {
      title: this.$t("meta.login.title") + " | 3space Art",
      meta: [
        {
          vmid: "title",
          name: "title",
          content: this.$t("meta.login.title") + " | 3space Art",
        },
        {
          vmid: "description",
          name: "description",
          content: this.$t("meta.login.description"),
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: this.$t("meta.login.title") + " | 3space Art",
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: this.$t("meta.login.description"),
        },
        {
          vmid: "og:url",
          property: "og:url",
          content: window.location.origin + this.$route.path,
        },
      ],
    };
  },
  data() {
    return {
      form: {
        account: "",
        password: "",
      },
      isBusy: false,
      errorMessage: null,
      isSaveAccount: false,
      eventForm: {
        idxEvent: 1,
      },
      isEventEnd: false,
    };
  },
  computed: {
    ...mapState({
      errors: (state) => {
        return state.auth.errors || {};
      },
    }),
    ...eventHelper.mapState(["eventInfo"]),
    isValid() {
      return !this.$v.form.$invalid;
    },
    isDirty() {
      return this.$v.form.$anyDirty;
    },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      account: { required },
      password: { required },
    },
  },
  mounted() {
    //this.requestEvent(); //TODO 이벤트 기간동안 이벤트 화면으로 이동
    this.$nextTick(() => {
      if (this.$cookies.isKey("threespaceAccount")) {
        const _account = this.$cookies.get("threespaceAccount");
        this.form.account =
          _account !== undefined && _account !== null && _account !== "null"
            ? _account
            : "";
        this.isSaveAccount = true;
      }
    });
  },
  methods: {
    ...mapActions("auth", ["login", "logout"]),
    ...eventHelper.mapActions(["getEvent"]),
    onClickFindAccount() {
      this.$router.push({ path: "/find-account" });
    },
    join() {
      this.$router.push({ path: "/join" });
      //this.$router.push({ path: "/event" }); //TODO 이벤트 기간동안 이벤트 화면으로 이동
    },
    onLoginSubmit() {
      // set cookie
      if (this.isSaveAccount) {
        this.$cookies.set("threespaceAccount", this.form.account);
      } else {
        this.$cookies.remove("threespaceAccount");
      }

      this.$v.$touch();
      if (this.isValid) {
        this.isBusy = true;
        this.logout(); // 로그아웃

        this.login(this.form)
          .then(() => {
            this.isBusy = false;
            this.errorMessage = null;
            this.$router.push({ name: "Home" });
          })
          .catch(() => {
            this.isBusy = false;
            if (
              this.errors.error === "MISMATCHED" &&
              this.errors.resource === "PASSWORD"
            ) {
              this.errorMessage = this.$t("login.validation.password");
            } else if (
              this.errors.error === "NOT_FOUND" &&
              this.errors.resource === "ENTITY"
            ) {
              this.errorMessage = this.$t("login.validation.email");
            } else {
              this.errorMessage = this.$t("login.error.login");
            }
          });
      }
    },
    onLoginNaver() {
      const redirect_uri =
        process.env.VUE_APP_BACKEND_API_URL +
        "/threespace/v1/join/naver/callback";
      const state = uuidv4();
      const url = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=9kK4tH7jbFwIgYMBleCG&redirect_uri=${redirect_uri}&state=${state}`;

      window.location.href = url;
    },
    onLoginKakao() {
      const redirect_uri =
        process.env.VUE_APP_BACKEND_API_URL +
        "/threespace/v1/join/kakao/callback";
      const url = `https://kauth.kakao.com/oauth/authorize?client_id=80e3176f5940dbb2f27f3b44d5fd124d&redirect_uri=${redirect_uri}&response_type=code`;

      window.location.href = url;
    },
    onLoginGoogle() {
      const redirect_uri =
        process.env.VUE_APP_BACKEND_API_URL +
        "/threespace/v1/join/google/callback";
      const url =
        `https://accounts.google.com/o/oauth2/v2/auth?client_id=713701466995-jef2uanlhjor7gbe5e15c20fodnar88u.apps.googleusercontent.com&redirect_uri=${redirect_uri}&response_type=code` +
        `&scope=openid%20email%20profile%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuser.phonenumbers.read&access_type=offline`;

      window.location.href = url;
    },
    onLoginNaver_bk() {
      const redirect_uri =
        process.env.VUE_APP_BACKEND_API_URL +
        "/threespace/v1/login/naver/callback";
      const state = uuidv4();
      const url = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=9kK4tH7jbFwIgYMBleCG&redirect_uri=${redirect_uri}&state=${state}`;
      window.app = this;
      window.open(
        url,
        "popup",
        "top=10, left=10, width=800, height=700, status=no, menubar=no, toolbar=no, resizable=no"
      );
    },
    onLoginKakao_bk() {
      const redirect_uri =
        process.env.VUE_APP_BACKEND_API_URL +
        "/threespace/v1/login/kakao/callback";
      const url = `https://kauth.kakao.com/oauth/authorize?client_id=80e3176f5940dbb2f27f3b44d5fd124d&redirect_uri=${redirect_uri}&response_type=code`;
      window.app = this;
      window.open(
        url,
        "popup",
        "top=10, left=10, width=500, height=600, status=no, menubar=no, toolbar=no, resizable=no"
      );
    },
    checkIfValid(fieldName) {
      const field = this.$v.form[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    requestEvent() {
      // 이벤트 정보 조회
      this.getEvent(this.eventForm).then(() => {
        if (
          this.eventInfo.status !== undefined &&
          this.eventInfo.status === "EVENT_END"
        ) {
          this.isEventEnd = true;
        }
      });
    },
  },
};
</script>
