<template>
  <div class="tease">
    <p>{{ countdownMessage }}</p>
    <button @click="signIn">SIGN IN</button>

    <div class="marquees">
      <section class="marquee" style="--char-count: 18">
        <div class="marquee--inner">
          <p>UNDER CONSTRUCTION</p>
          <p aria-hidden="true">UNDER CONSTRUCTION</p>
          <p aria-hidden="true">UNDER CONSTRUCTION</p>
          <p aria-hidden="true">UNDER CONSTRUCTION</p>
          <p aria-hidden="true">UNDER CONSTRUCTION</p>
          <p aria-hidden="true">UNDER CONSTRUCTION</p>
          <p aria-hidden="true">UNDER CONSTRUCTION</p>
          <p aria-hidden="true">UNDER CONSTRUCTION</p>
          <p aria-hidden="true">UNDER CONSTRUCTION</p>
          <p aria-hidden="true">UNDER CONSTRUCTION</p>
        </div>
      </section>

      <section class="marquee" style="--char-count: 18">
        <div class="marquee--inner">
          <p>UNDER CONSTRUCTION</p>
          <p aria-hidden="true">UNDER CONSTRUCTION</p>
          <p aria-hidden="true">UNDER CONSTRUCTION</p>
          <p aria-hidden="true">UNDER CONSTRUCTION</p>
          <p aria-hidden="true">UNDER CONSTRUCTION</p>
          <p aria-hidden="true">UNDER CONSTRUCTION</p>
          <p aria-hidden="true">UNDER CONSTRUCTION</p>
          <p aria-hidden="true">UNDER CONSTRUCTION</p>
          <p aria-hidden="true">UNDER CONSTRUCTION</p>
          <p aria-hidden="true">UNDER CONSTRUCTION</p>
        </div>
      </section>

      <section class="marquee" style="--char-count: 18">
        <div class="marquee--inner">
          <p>UNDER CONSTRUCTION</p>
          <p aria-hidden="true">UNDER CONSTRUCTION</p>
          <p aria-hidden="true">UNDER CONSTRUCTION</p>
          <p aria-hidden="true">UNDER CONSTRUCTION</p>
          <p aria-hidden="true">UNDER CONSTRUCTION</p>
          <p aria-hidden="true">UNDER CONSTRUCTION</p>
          <p aria-hidden="true">UNDER CONSTRUCTION</p>
          <p aria-hidden="true">UNDER CONSTRUCTION</p>
          <p aria-hidden="true">UNDER CONSTRUCTION</p>
          <p aria-hidden="true">UNDER CONSTRUCTION</p>
        </div>
      </section>

      <section class="marquee" style="--char-count: 18">
        <div class="marquee--inner">
          <p>UNDER CONSTRUCTION</p>
          <p aria-hidden="true">UNDER CONSTRUCTION</p>
          <p aria-hidden="true">UNDER CONSTRUCTION</p>
          <p aria-hidden="true">UNDER CONSTRUCTION</p>
          <p aria-hidden="true">UNDER CONSTRUCTION</p>
          <p aria-hidden="true">UNDER CONSTRUCTION</p>
          <p aria-hidden="true">UNDER CONSTRUCTION</p>
          <p aria-hidden="true">UNDER CONSTRUCTION</p>
          <p aria-hidden="true">UNDER CONSTRUCTION</p>
          <p aria-hidden="true">UNDER CONSTRUCTION</p>
        </div>
      </section>
      <section class="marquee" style="--char-count: 18">
        <div class="marquee--inner">
          <p>UNDER CONSTRUCTION</p>
          <p aria-hidden="true">UNDER CONSTRUCTION</p>
          <p aria-hidden="true">UNDER CONSTRUCTION</p>
          <p aria-hidden="true">UNDER CONSTRUCTION</p>
          <p aria-hidden="true">UNDER CONSTRUCTION</p>
          <p aria-hidden="true">UNDER CONSTRUCTION</p>
          <p aria-hidden="true">UNDER CONSTRUCTION</p>
          <p aria-hidden="true">UNDER CONSTRUCTION</p>
          <p aria-hidden="true">UNDER CONSTRUCTION</p>
          <p aria-hidden="true">UNDER CONSTRUCTION</p>
        </div>
      </section>

      <section class="marquee" style="--char-count: 18">
        <div class="marquee--inner">
          <p>UNDER CONSTRUCTION</p>
          <p aria-hidden="true">UNDER CONSTRUCTION</p>
          <p aria-hidden="true">UNDER CONSTRUCTION</p>
          <p aria-hidden="true">UNDER CONSTRUCTION</p>
          <p aria-hidden="true">UNDER CONSTRUCTION</p>
          <p aria-hidden="true">UNDER CONSTRUCTION</p>
          <p aria-hidden="true">UNDER CONSTRUCTION</p>
          <p aria-hidden="true">UNDER CONSTRUCTION</p>
          <p aria-hidden="true">UNDER CONSTRUCTION</p>
          <p aria-hidden="true">UNDER CONSTRUCTION</p>
        </div>
      </section>

      <section class="marquee" style="--char-count: 18">
        <div class="marquee--inner">
          <p>UNDER CONSTRUCTION</p>
          <p aria-hidden="true">UNDER CONSTRUCTION</p>
          <p aria-hidden="true">UNDER CONSTRUCTION</p>
          <p aria-hidden="true">UNDER CONSTRUCTION</p>
          <p aria-hidden="true">UNDER CONSTRUCTION</p>
          <p aria-hidden="true">UNDER CONSTRUCTION</p>
          <p aria-hidden="true">UNDER CONSTRUCTION</p>
          <p aria-hidden="true">UNDER CONSTRUCTION</p>
          <p aria-hidden="true">UNDER CONSTRUCTION</p>
          <p aria-hidden="true">UNDER CONSTRUCTION</p>
        </div>
      </section>

      <section class="marquee" style="--char-count: 18">
        <div class="marquee--inner">
          <p>UNDER CONSTRUCTION</p>
          <p aria-hidden="true">UNDER CONSTRUCTION</p>
          <p aria-hidden="true">UNDER CONSTRUCTION</p>
          <p aria-hidden="true">UNDER CONSTRUCTION</p>
          <p aria-hidden="true">UNDER CONSTRUCTION</p>
          <p aria-hidden="true">UNDER CONSTRUCTION</p>
          <p aria-hidden="true">UNDER CONSTRUCTION</p>
          <p aria-hidden="true">UNDER CONSTRUCTION</p>
          <p aria-hidden="true">UNDER CONSTRUCTION</p>
          <p aria-hidden="true">UNDER CONSTRUCTION</p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "TeaseView",
  data() {
    return {
      countdown: 10,
    };
  },
  computed: {
    countdownMessage() {
      const isMobile = window.innerWidth <= 768;
      if (isMobile) {
        return `Redirecting to\n3space Art\nin ${this.countdown} seconds...`;
      } else {
        return `Redirecting to 3space Art\nin ${this.countdown} seconds...`;
      }
    },
  },
  methods: {
    startCountdown() {
      const timer = setInterval(() => {
        this.countdown--;
        if (this.countdown === 0) {
          clearInterval(timer);
          this.redirectToMain();
        }
      }, 1000);
    },
    redirectToMain() {
      window.location.href = "/";
    },
    signIn() {
      window.location.href = "/";
    },
  },
  mounted() {
    this.startCountdown();
  },
};
</script>
