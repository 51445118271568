<template>
  <div>
    <section class="mypage-form artist">
      <section class="information">
        <p class="title">{{ $t("mypage.artistInfoCon.artistInfo") }}</p>
        <p class="content">
          {{ $t("mypage.artistInfoCon.artistInfo1") }} <br />
          {{ $t("mypage.artistInfoCon.artistInfo2") }} <br />
          {{ $t("mypage.artistInfoCon.artistInfo3") }}
        </p>
      </section>
      <section class="form">
        <section class="left">
          <!-- 작가명 -->
          <section class="input-box-wrapper">
            <p class="title">{{ $t("mypage.artistName") }}</p>
            <div class="input-box">
              <p>{{ $t("mypage.eng") }}<span>*</span></p>
              <input
                type="text"
                v-model="artistInfo.artistNameEn"
                :placeholder="$t('mypage.required')"
                :disabled="isCorporation"
              />
            </div>
            <div class="input-box">
              <p>{{ $t("mypage.kor") }}</p>
              <input
                type="text"
                v-model="artistInfo.artistName"
                :disabled="isCorporation"
              />
            </div>
          </section>
          <!-- 작가소개 -->
          <section class="input-box-wrapper">
            <p class="title">{{ $t("mypage.artistAbout") }}</p>
            <div class="input-box text-area">
              <p>{{ $t("mypage.eng") }}<span>*</span></p>
              <textarea
                v-model="artistInfo.introduceEn"
                :placeholder="$t('mypage.required')"
                :disabled="isCorporation"
              ></textarea>
            </div>
            <div class="input-box text-area">
              <p>{{ $t("mypage.kor") }}</p>
              <textarea
                v-model="artistInfo.introduce"
                :disabled="isCorporation"
              ></textarea>
            </div>
          </section>
        </section>
        <section class="right">
          <!-- 작가이력 -->
          <p class="title">{{ $t("mypage.artistBio") }}</p>
          <section class="input-box-wrapper" style="margin-top: 0">
            <div class="input-box text-area">
              <p>{{ $t("mypage.eng") }}<span>*</span></p>
              <textarea
                v-model="artistInfo.careerEn"
                :disabled="isCorporation"
              ></textarea>
            </div>

            <div class="input-box text-area">
              <p>{{ $t("mypage.kor") }}</p>
              <textarea
                v-model="artistInfo.career"
                :disabled="isCorporation"
              ></textarea>
            </div>
          </section>
          <div class="save-button">
            <p class="text-danger text-center">{{ errorMsg }}</p>

            <Button
              :onClick="() => onClickSaveArtist('default')"
              :label="$t('mypage.save')"
              :disabled="isCorporation"
            />
          </div>
        </section>
      </section>
      <template v-if="!isCorporation">
        <article class="artist-info-box">
          <p class="title">{{ $t("mypage.artistInfoCon.artistSettle") }}</p>
          <div class="info_list">
            <p>{{ $t("mypage.artistInfoCon.artistSettle1") }}</p>
            <p>- {{ $t("mypage.artistInfoCon.artistSettle2") }}</p>
            <p>- {{ $t("mypage.artistInfoCon.artistSettle3") }}</p>
          </div>
          <div class="info_list">
            <p>{{ $t("mypage.artistInfoCon.artistSettle4") }}</p>
            <p>- {{ $t("mypage.artistInfoCon.artistSettle5") }}</p>
            <p>- {{ $t("mypage.artistInfoCon.artistSettle6") }}</p>
            <p>- {{ $t("mypage.artistInfoCon.artistSettle7") }}</p>
          </div>
        </article>
      </template>
    </section>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Button from "@/views/threespace/components/common/Button.vue";

export default {
  name: "MyPageUpdateArtist",
  components: {
    Button,
  },
  props: {
    isArtist: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      artistInfo: {},
      bankSelected: "",
      errorMsg: "",
      bankCodeOptions: [
        { value: "", text: "선택" },
        { value: "003", label: "기업은행" },
        { value: "004", label: "국민은행" },
        { value: "020", label: "우리은행" },
        { value: "081", label: "하나은행" },
        { value: "088", label: "신한은행" },
        { value: "027", label: "씨티은행" },
        { value: "011", label: "NH농협은행" },
        { value: "090", label: "카카오뱅크" },
        { value: "092", label: "토스뱅크" },
      ],
    };
  },
  computed: {
    ...mapState("profile", ["memberInfo"]),
    ...mapState("auth", ["me", "isCorporation"]),
  },
  mounted() {
    this.$nextTick(() => {
      this.requestArtistInfo();
    });
  },
  methods: {
    ...mapActions("profile", ["updateProfileArtist"]),
    ...mapActions("gallery", ["getGalleryArtist"]),
    requestArtistInfo() {
      if (this.isArtist) {
        this.getGalleryArtist(this.memberInfo.idxArtist).then((data) => {
          if (
            Object.keys(data).length !== 0 &&
            JSON.stringify(data) !== JSON.stringify({})
          ) {
            this.artistInfo = data;
          }
        });
      }
    },
    onClickSaveArtist(type) {
      this.errorMsg = "";
      this.artistInfo.saveType = type; // default: 기본정보만 저장, settle: 정산정보만 저장
      this.artistInfo.bankCode = this.bankSelected;
      if (
        this.artistInfo.artistNameEn === undefined ||
        this.artistInfo.artistNameEn === null ||
        this.artistInfo.artistNameEn === ""
      ) {
        this.errorMsg = this.$t("mypage.requiredArtistNameEn");
        return;
      }
      if (
        this.artistInfo.introduceEn === undefined ||
        this.artistInfo.introduceEn === null ||
        this.artistInfo.introduceEn === ""
      ) {
        this.errorMsg = this.$t("mypage.requiredIntroduceEn");
        return;
      }
      this.updateProfileArtist(this.artistInfo)
        .then(() => {
          // alert(this.$t("mypage.updatedProfile"));
          this.$router.push({
            name: "MyPageDetail",
            params: { updateComplete: true },
          });
        })
        .catch(() => {
          alert("요청에 실패하였습니다. 관리자에게 문의하세요.");
        });
    },
  },
};
</script>
