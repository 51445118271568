<template>
  <div id="V2About">
    <div class="about">
      <div class="top">
        <p class="title" v-html="$t('about.aboutPage.title')"></p>
        <p class="description">
          {{ $t("about.aboutPage.description") }}
        </p>
      </div>
      <section class="content">
        <div class="background">
          <video
            :key="i"
            poster="/img/threespace/media/about_movement.png"
            autoplay
            muted
            loop
            playsinline
          >
            <source
              src="/img/threespace/media/about_movement.mp4"
              type="video/mp4"
            />
            video 요소를 지원하지 않는 브라우저입니다. 동영상은 다운로드 후 볼
            수 있습니다.
          </video>
        </div>
        <section class="cards">
          <section class="about-card">
            <div class="title">
              <p>{{ $t("about.aboutCard.nftTitle") }}</p>
            </div>
            <p class="content">
              {{ $t("about.aboutCard.nftDescription") }}
            </p>
          </section>
          <section class="about-card">
            <div class="title">
              <p>
                {{ $t("about.aboutCard.artpoolTitle") }}
              </p>
              <Button
                :onClick="onClickArtpool"
                :label="$t('button.learnMore')"
              />
            </div>
            <p class="content">
              {{ $t("about.aboutCard.artpoolDescription") }}
            </p>
          </section>
          <section class="about-card">
            <div class="title">
              <p>{{ $t("about.aboutCard.exhibitTitle") }}</p>
            </div>
            <p class="content">
              {{ $t("about.aboutCard.exhibitDescription") }}
            </p>
          </section>
          <section class="about-card">
            <div class="title">
              <p>{{ $t("about.aboutCard.paceTitle") }}</p>
              <Button
                :onClick="onClickWhitePaper"
                :label="$t('button.learnMore')"
              />
            </div>
            <p class="content">
              {{ $t("about.aboutCard.paceDescription") }}
            </p>
          </section>
        </section>
      </section>
      <article class="partners team">
        <p class="title">{{ $t("product.partnersTitle") }}</p>
        <div class="content">
          <template v-for="(item, i) in partners">
            <div :key="i">
              <b-img :src="`/img/threespace/partners/` + item.fileName" />
            </div>
          </template>
        </div>
      </article>
      <article class="backers team">
        <p class="title">BACKERS</p>
        <div class="content">
          <template v-for="(item, i) in backers">
            <div :key="i">
              <b-img :src="`/img/threespace/partners/` + item.fileName" />
            </div>
          </template>
        </div>
      </article>
    </div>
  </div>
</template>

<script>
import Button from "@/views/threespace/components/common/Button.vue";

export default {
  name: "About",
  components: {
    Button,
  },
  metaInfo() {
    return {
      title: this.$t("meta.about.title") + " | 3space Art",
      meta: [
        {
          vmid: "title",
          name: "title",
          content: this.$t("meta.about.title") + " | 3space Art",
        },
        {
          vmid: "description",
          name: "description",
          content: this.$t("meta.about.description"),
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: this.$t("meta.about.title") + " | 3space Art",
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: this.$t("meta.about.description"),
        },
        {
          vmid: "og:url",
          property: "og:url",
          content: window.location.origin + this.$route.path,
        },
      ],
    };
  },
  data() {
    return {
      partners: [
        { fileName: "shinhan_card.png" },
        { fileName: "LX.png" },
        { fileName: "HDC_Labs.png" },
        { fileName: "HyundaiOilbank.png" },
        { fileName: "komsco.png" },
        { fileName: "ncc_logo-variations-final_.png" },
        { fileName: "groundx.png" },
        { fileName: "polygon.png" },
        { fileName: "korbit.png" },
        { fileName: "HashKeyNFT.png" },
        { fileName: "NFTAsia.png" },
        { fileName: "HAVAH.png" },
        { fileName: "SOMESING.svg" },
        { fileName: "BLUE_CANVAS.png" },
        { fileName: "neopin.png" },
        { fileName: "ym-star-entertainment.png" },
        { fileName: "tae-young.svg" },
        { fileName: "art-dubai.png" },
        { fileName: "bnk.png" },
      ],
      backers: [
        { fileName: "MODORI.png" },
        { fileName: "ALPHA_NONCE_xbg.png" },
        { fileName: "499BLOCK.png" },
        { fileName: "FBG_Capital.png" },
        { fileName: "intellicode.png" },
        { fileName: "kaia-combination-black.png" },
        { fileName: "kibo.png" },
      ],
      teams: [
        { fileName: "1.png", name: "Uz Jang", role: "PRODUCT MANAGER / KOR" },
        { fileName: "2.png", name: "Hanna Joo", role: "MANAGEMENT / KOR" },
        {
          fileName: "3.png",
          name: "Yeonhee Cho",
          role: "FULL STACK DEVELOPER / KOR",
        },
        { fileName: "4.png", name: "Yoon Kim", role: "FOUNDER / SGP" },
        { fileName: "5.png", name: "Michale Chung", role: "FINANCE / KOR" },
        {
          fileName: "6.png",
          name: "Daniel Aldridge",
          role: "SOFTWARE ENGINEER / USA",
        },
        { fileName: "7.png", name: "Hiroshi Muto", role: "CURATOR / JPN" },
        { fileName: "8.png", name: "Sunny An", role: "HEAD OF DESIGN / KOR" },
        { fileName: "9.png", name: "Julius N'point", role: "CURATOR / DEU" },
        { fileName: "10.png", name: "Kyle Kim", role: "STRATEGY / BKK" },
        {
          fileName: "11.png",
          name: "Khaye Duze",
          role: "SYSTEM INTEGRATION / ZAF",
        },
        {
          fileName: "12.png",
          name: "Luka Lee",
          role: "BLOCKCHAIN ENGINEER / KOR",
        },
      ],
      defaultLanguage: "en",
    };
  },
  computed: {
    activeLanguage() {
      return this.getActiveLanguage();
    },
  },
  methods: {
    getActiveLanguage() {
      return localStorage.getItem("language") || this.defaultLanguage;
    },
    onClickWhitePaper() {
      window.open("https://docs.3space.art/", "_blank");
    },
    onClickArtpool() {
      this.$router.push("/art-pool/nft");
    },
  },
};
</script>
