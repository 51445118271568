<template>
  <div class="mobile_mypage_info">
    <!-- location -->
    <p class="location">
      <img src="/img/threespace/mypage/location_icon.png" />
      <template v-if="!showCollection">
        {{
          _memberInfo.location !== undefined &&
          _memberInfo.location !== null &&
          _memberInfo.location !== "" ?
          _memberInfo.location : "Where you at?"
        }}
      </template>
      <template v-else>
        {{
          _memberInfo.location !== undefined &&
          _memberInfo.location !== null &&
          _memberInfo.location !== "" ?
          _memberInfo.location : "Empty location"
        }}
      </template>
    </p>
    <!-- snsLink -->
    <div class="sns_link">
      <b-button
        :disabled="_memberInfo.instagram === undefined || _memberInfo.instagram === ''"
        v-c-tooltip="{ content: 'Instagram', placement: 'top' }"
        variant="link"
        class="p-0"
        @click="onClickSnsBtn(_memberInfo.instagram, 'instagram')"
      >
        <img src="/img/threespace/mypage/insta_icon.png" />
      </b-button>
      <b-button
        :disabled="_memberInfo.twitter === undefined || _memberInfo.twitter === ''"
        v-c-tooltip="{ content: 'Twitter', placement: 'top' }"
        variant="link"
        class="p-0"
        @click="onClickSnsBtn(_memberInfo.twitter, 'twitter')"
      >
        <img src="/img/threespace/mypage/twitter_icon.png" />
      </b-button>
      <b-button
        :disabled="_memberInfo.homepage === undefined || _memberInfo.homepage === ''"
        v-c-tooltip="{ content: 'Website', placement: 'top' }"
        variant="link"
        class="p-0"
        @click="onClickSnsBtn(_memberInfo.homepage, 'homepage')"
      >
        <img src="/img/threespace/mypage/homepage_icon.png" />
      </b-button>
    </div>

    <!-- follower, following -->
    <div class="follower d-flex flex-column align-items-center">
      <div>
        <p class="mb-1 cursor-pointer" @click.stop="onClickFollowers(_memberInfo.idx)">
          {{ _memberInfo.followers | currency }}<br />
          <span>Followers</span>
        </p>
        <p class="mb-1 cursor-pointer" @click.stop="onClickFollowing(_memberInfo.idx)">
          {{ _memberInfo.following | currency }}<br />
          <span>Following</span>
        </p>
      </div>
      <button @click.stop="onClickMemberFollow()" :class="{ 'followed': _memberInfo.isFollow }">
        {{ _memberInfo.isFollow !== undefined && _memberInfo.isFollow ? 'Unfollow' : 'Follow' }}
      </button>
    </div>

    <div class="introduce_wrap">
      <!-- introduce -->
      <div class="introduce" v-if="isArtist">
        <template v-if="activeLanguage !== undefined && activeLanguage === 'ko'">
          <p
              v-html="
                artistInfo.introduce !== undefined && artistInfo.introduce !== '' ?
                replaceNewLine(artistInfo.introduce) :
                replaceNewLine(artistInfo.introduceEn)"
          ></p>
        </template>
        <template v-else>
          <p
            v-html="
              artistInfo.introduceEn !== undefined && artistInfo.introduceEn !== '' ?
              replaceNewLine(artistInfo.introduceEn) :
              replaceNewLine(artistInfo.introduce)"
          ></p>
        </template>
      </div>
      <div class="introduce" v-else>
        <p
          v-if="_memberInfo.introduce !== undefined &&
                _memberInfo.introduce !== null &&
                _memberInfo.introduce !== ''"
          v-html="replaceNewLine(_memberInfo.introduce)"
        ></p>
        <p v-else>
          {{ showCollection ? $t("mypage.emptyBioMessage") : $t("mypage.bioMessage") }}
        </p>
      </div>

      <!-- career -->
      <div class="introduce" v-if="isArtist">
        <template v-if="activeLanguage !== undefined && activeLanguage === 'ko'">
          <p
              v-html="
                artistInfo.career !== undefined && artistInfo.career !== '' ?
                replaceNewLine(artistInfo.career) :
                replaceNewLine(artistInfo.careerEn)"
          ></p>
        </template>
        <template v-else>
          <p
            v-html="
              artistInfo.careerEn !== undefined && artistInfo.careerEn !== '' ?
              replaceNewLine(artistInfo.careerEn) :
              replaceNewLine(artistInfo.career)"
          ></p>
        </template>
      </div>
    </div>

    <FollowModal ref="followModal" />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

import FollowModal from "@/views/threespace/components/follow/FollowModal";

export default {
  name: "MyPageDetailInfoMobile",
  components: {
    FollowModal
  },
  props: {
    isArtist: {
      type: Boolean,
      default: false,
    },
    artistInfo: {
      type: Object,
      default: () => {}
    },
    showCollection: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  computed: {
    ...mapState("profile", ["memberInfo", "memberSimpleInfo"]),
    ...mapState("auth", ["me"]),
    ...mapState(["wallet"]),
    _memberInfo() {
      if (this.showCollection !== undefined && this.showCollection) {
        return this.memberSimpleInfo;
      } else {
        return this.memberInfo
      }
    },
    activeLanguage() {
      return this.getActiveLanguage();
    },
    isMobile() {
      return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    },
  },
  methods: {
    ...mapActions("profile", ["updateMemberFollow"]),
    getActiveLanguage() {
      return localStorage.getItem("language") || "en";
    },
    onClickSnsBtn(snsLink, snsType) {
      if (snsType === "instagram") {
        window.open("https://www.instagram.com/" + snsLink, '_blank');
      } else if (snsType === "twitter") {
        window.open("https://twitter.com/" + snsLink, '_blank');
      } else {
        window.open(snsLink, '_blank');
      }
    },
    onClickMemberFollow() {
      // Follow/Unfollow
      const follow = this._memberInfo.isFollow !== undefined && this._memberInfo.isFollow === true ? false : true;
      const param = { idxMember: this._memberInfo.idx, follow: follow };
      this.updateMemberFollow(param)
          .then(data => {
            if (Object.keys(data).length !== 0 && JSON.stringify(data) !== JSON.stringify({})) {
              this._memberInfo.isFollow = data.isFollow;
              this._memberInfo.followers = data.followers;
              this._memberInfo.following = data.following;
            }
          })
          .catch(error => {
            this.$log.error(error);
            alert(this.$t("artist.failTryAgain"));
          });
    },
    onClickFollowers(_idxMember) {
      this.$refs["followModal"].show(0, _idxMember);
    },
    onClickFollowing(_idxMember) {
      this.$refs["followModal"].show(1, _idxMember);
    },
    replaceNewLine(str) {
      if (str !== undefined && str !== null && str !== "") {
        return str.replace(/(?:\r\n|\r|\n)/g, "<br />");
      } else {
        return "";
      }
    },
  },
  filters: {
    currency: value => {
      if (!value) return 0;
      return value.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    }
  }
};
</script>
