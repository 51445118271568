<template>
  <CCard>
    <CCardBody>
      <CRow>
        <CCol class="mb-3"><h5>컬렉션 관리</h5></CCol>
      </CRow>
      <CRow>
        <CCol>
          <table class="table">
            <tbody>
            <tr>
              <th class="border-bottom bg-light text-dark">검색</th>
              <td class="border-bottom">
                <CSelect
                  class="col-2 float-left px-0 mb-0 mr-2"
                  :options="searchTypeOptions"
                  :value.sync="externalParams.searchTypeOption"
                />
                <CInput
                  class="col-6 float-left mb-0"
                  placeholder="검색어를 입력해주세요."
                  v-model="externalParams.searchKeyword"
                  @keypress.enter="onClickSearch"
                />
                <CButton color="dark" class="text-white" @click="onClickSearch">
                  <CIcon name="cil-search" class="mr-2" />검색
                </CButton>
                <CButton
                  variant="outline"
                  color="secondary"
                  class="ml-2"
                  @click="onClickReset"
                >
                  <CIcon name="cil-filter-x" />
                </CButton>
              </td>
            </tr>
            </tbody>
          </table>
        </CCol>
      </CRow>
      <CRow>
        <CCol class="col-auto mr-auto align-self-center">
          총&nbsp;<strong>{{ collectionTableItems.totalElements }}</strong>건&nbsp;({{ currentPage }}&nbsp;/&nbsp;{{ collectionTableItems.totalPages }}&nbsp;페이지)
        </CCol>
        <CCol class="col-auto">
          <CSelect
            class="float-left mr-2 mb-2"
            :options="pageOptions"
            :value.sync="perPage"
            @change="onPaging"
          />
          <CButton color="dark" class="text-white" @click="onClickImportNft">
            <CIcon name="cil-library-add" class="mr-2" />외부 NFT 가져오기
          </CButton>
        </CCol>
      </CRow>


      <b-table
        id="collectionTable"
        thead-class="text-center"
        :busy="isBusy"
        :items="collectionTableItems.content"
        :fields="collectionTableFields"
        :items-per-page="perPage"
        show-empty
        empty-text="조회된 내역이 없습니다."
      >
        <!-- NO -->
        <template #cell(index)="data">
          {{ data.index + 1 + (currentPage - 1) * perPage }}
        </template>

        <!-- 작품명 -->
        <template #cell(title)="data">
          <a
            class="text-info"
            style="cursor: pointer;"
            @click="onClickCollectionTitle(data.item.idx)"
          >
            {{ data.item.title }}
          </a>
        </template>
        
        <!-- 소유주 -->
        <template #cell(owner)="data">
          <template v-if="data.value != undefined">
            {{ data.value }}
            <a
              @click="onClickMember(data.item.idxMember)"
              :id="'show-owner-info' + data.index"
              class="text-primary cursor-pointer"
              v-if="
                data.value != undefined &&
                data.value !== '' &&
                data.item.idxMember != undefined && 
                data.item.idxMember !== 0
              "
              title="소유주 정보 보기"
            ><v-icon small>mdi-open-in-new</v-icon></a>
            <b-tooltip :target="'show-owner-info' + data.index">소유주 정보 보기</b-tooltip>
          </template>
          <template v-else>
            <span class="text-gray">소유주 없음</span>
          </template>
        </template>

        <!-- Actions -->
        <template v-slot:cell(actions)="data">
          <CButton
            variant="outline"
            color="info"
            size="sm"
            class="mx-2"
            @click="onClickModify(data.item.idx)"
          >
            <CIcon name="cil-pencil" />
          </CButton>
        </template>
      </b-table>
      <b-pagination
        aria-controls="productTable"
        align="center"
        hide-ellipsis
        limit="10"
        v-model="currentPage"
        :total-rows="collectionTableItems.totalElements"
        :per-page="perPage"
        :current-page="currentPage"
        v-on:input="onPaging"
      ></b-pagination>
    </CCardBody>

    <!-- import nft modal -->
    <CollectionImportNftModal 
      ref="collectionImportNftModal" 
      @importNftSuccess="requestCollectionItems"
    />

  </CCard>
</template>

<script>
  import { mapState, mapActions } from "vuex";
import CollectionImportNftModal from './CollectionImportNftModal.vue';

  export default {
    name: "CollectionList",
    components: {
      CollectionImportNftModal,
    },
    data: function() {
      return {
        isBusy: false,
        perPage: 10,
        pageOptions: [
          { value: 10, label: "10 개씩 보기" },
          { value: 20, label: "20 개씩 보기" },
          { value: 30, label: "30 개씩 보기" },
          { value: 50, label: "50 개씩 보기" }
        ],
        currentPage: 1,
        sort: [
          { id: "idx", desc: "desc" }
        ],
        sortBy: "",
        sortDesc: false,
        // 검색
        searchTypeOptions: [
          { value: null, label: "전체" },
          { value: "TITLE", label: "작품명" },
          { value: "OWNER", label: "소유주" },
          { value: "RFC", label: "RFC" },
          { value: "NFT", label: "NFT명" },
        ],
        externalParams: {
          searchKeyword: "",
          searchTypeOption: null,
          isChainEthereum: true,
          isChainKlaytn: true,
          isFileTypeGif: true,
          isFileTypeImage: true,
          isFileTypeMp4: true,
          isVisibleAll: true,
          isAdmin: true
        }
      };
    },
    computed: {
      ...mapState("collectionManager", ["collectionTableItems", "collectionTableFields"]),
      ...mapState("nftManager", ["nftTableItems"])
    },
    mounted() {
      this.requestCollectionItems();
    },
    methods: {
      ...mapActions("collectionManager", ["getCollections"]),
      ...mapActions("nftManager", ["getNfts"]),
      onPaging() {
        this.requestCollectionItems();
      },
      resetSearchConditions() {
        this.currentPage = 1;
        this.externalParams.searchKeyword = ""
        this.externalParams.searchTypeOption = null
        this.externalParams.isChainEthereum = true
        this.externalParams.isChainKlaytn = true
        this.externalParams.isFileTypeGif = true
        this.externalParams.isFileTypeImage = true
        this.externalParams.isFileTypeMp4 = true
        this.externalParams.isVisibleAll = true
      },
      onClickSearch() {
        this.requestCollectionItems();
      },
      onClickReset() {
        this.resetSearchConditions();
        this.requestCollectionItems();
      },
      requestCollectionItems() {
        this.isBusy = true;
        this.getCollections(
          this.getPagingParams(
            {
              page: this.currentPage - 1,
              pageSize: this.perPage,
              sorted: this.sort,
              filtered: []
            },
            this.externalParams
          )
        )
          .then(data => {
            this.$log.log(data);
            this.isBusy = false;
          })
          .catch(error => {
            this.isBusy = false;
            this.$log.error(error);
          });
      },
      onClickImportNft() {
        this.getNfts(
          this.getPagingParams(
            {
              page: this.currentPage - 1,
              pageSize: 50,
              sorted: this.sort,
              filtered: []
            },
            {}
          )
        )
          .then(() => {
            this.$refs["collectionImportNftModal"].show();
          })
          .catch(error => {
            this.$log.error(error);
          });
      },
      onClickMember(idxMember) {
        let routeData = this.$router.resolve({name: 'MemberSave', params: {idxMember: idxMember}});
        window.open(routeData.href, '_blank');
      },
      onClickModify(idx) {
        this.$router.push("/management/collection-detail/" + idx);
      },
      onClickCollectionTitle(idx) {
        this.$router.push("/management/collection-detail/" + idx);
      },
      getPagingParams({ page, pageSize, sorted, filtered }, externalParams) {
        const params = {
          page,
          size: pageSize,
          ...externalParams
        };
        // sorting parameter
        let sorts = [];
        for (let i = 0, length = sorted.length; i < length; i++) {
          const sort = sorted[i];
          sorts.push(`${sort.id},${sort.desc ? "desc" : "asc"}`);
        }
        params["sort"] = sorts;

        // filtering parameter
        for (let i = 0, length = filtered.length; i < length; i++) {
          const filter = filtered[i];
          params[filter.id] = filter.value;
        }

        return params;
      }
    }
  };
</script>
