<template>
  <CCard>
    <CCardBody>
      <CRow>
        <CCol class="mb-3"><h5>사용자</h5></CCol>
      </CRow>
      <CForm>
        <CRow>
          <CCol col="12">
            <b-table-simple>
              <colgroup>
                <col style="width:13%;" />
                <col style="width:auto;" />
                <col style="width:13%;" />
                <col style="width:30%;" />
              </colgroup>
              <b-tbody>
                <b-tr>
                  <b-th variant="secondary">회원번호</b-th>
                  <b-td>{{ memberInfo.idx }}</b-td>
                  <b-th variant="secondary" rowspan="3">프로필 이미지<br/>/배너 이미지</b-th>
                  <b-td rowspan="3">
                    <b-row>
                      <b-col>
                        <span>{{ memberInfo.profileImageName || '(정보없음)' }}</span>
                        <div v-if="memberInfo.profileImageName !== undefined">
                          <CImg
                            height="100px"
                            :src="memberInfo.profileImageUrl"
                            class="mt-1"
                          />
                        </div>
                      </b-col>
                      <b-col>
                        <span>{{ memberInfo.bannerImage || '(정보없음)' }}</span>
                        <div v-if="memberInfo.bannerImage !== undefined">
                          <CImg
                            height="100px"
                            :src="memberInfo.bannerImageUrl"
                            class="mt-1"
                          />
                        </div>
                      </b-col>
                    </b-row>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-th variant="secondary">이름</b-th>
                  <b-td>{{ memberInfo.name || "(정보없음)" }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th variant="secondary">사용자이름(닉네임)</b-th>
                  <b-td>{{ memberInfo.nickname || "(정보없음)" }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th variant="secondary">연락처</b-th>
                  <b-td>{{ memberInfo.mobile || "(정보없음)" }}</b-td>
                  <b-th variant="secondary">본인인증</b-th>
                  <b-td>{{ memberInfo.identityVerify === true ? "Y" : "N" }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th variant="secondary">이메일계정</b-th>
                  <b-td>{{ memberInfo.email || "(정보없음)" }}</b-td>
                  <b-th variant="secondary">위치</b-th>
                  <b-td>{{ memberInfo.location }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th variant="secondary">카이아 지갑주소</b-th>
                  <b-td>{{ memberInfo.klaytnAddr || "(정보없음)" }} {{ memberInfo.klaytnProvider !== undefined && memberInfo.klaytnProvider !== "" ? "("+memberInfo.klaytnProvider+")" : "" }}</b-td>
                  <b-th variant="secondary" rowspan="3">SNS</b-th>
                  <b-td rowspan="3">
                    <CButton
                      name="instagram"
                      color="instagram"
                      size="sm"
                      :disabled="true"
                      class="cursor-default mr-2"
                    >
                      <CIcon name="cib-instagram" size="sm" />
                    </CButton>
                    <span v-if="memberInfo.instagram !== undefined && memberInfo.instagram !== null">
                      https://instagram.com/{{ memberInfo.instagram }}/
                    </span>
                    <div class="mt-1" />
                    <CButton
                      name="twitter"
                      color="twitter"
                      size="sm"
                      :disabled="true"
                      class="cursor-default mr-2"
                    >
                      <CIcon name="cib-twitter" size="sm" />
                    </CButton>
                    <span v-if="memberInfo.twitter !== undefined && memberInfo.twitter !== null">
                      https://twitter.com/{{ memberInfo.twitter }}
                    </span>
                    <div class="mt-1" />
                    <CButton
                      name="homepage"
                      color="secondary"
                      size="sm"
                      :disabled="true"
                      class="cursor-default mr-2"
                    >
                      <CIcon name="cil-home" />
                    </CButton>
                    <span v-if="memberInfo.homepage !== undefined && memberInfo.homepage !== null">
                      {{ memberInfo.homepage }}
                    </span>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-th variant="secondary">이더리움 지갑주소</b-th>
                  <b-td>{{ memberInfo.ethereumAddr || "(정보없음)" }} {{ memberInfo.ethereumProvider !== undefined && memberInfo.ethereumProvider !== "" ? "("+memberInfo.ethereumProvider+")" : "" }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th variant="secondary">폴리곤 지갑주소</b-th>
                  <b-td>{{ memberInfo.polygonAddr || "(정보없음)" }} {{ memberInfo.polygonProvider !== undefined && memberInfo.polygonProvider !== "" ? "("+memberInfo.polygonProvider+")" : "" }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th variant="secondary">BTC 지갑주소</b-th>
                  <b-td>{{ memberInfo.btcAddr || "(정보없음)" }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th variant="secondary">자기소개</b-th>
                  <b-td colspan="3">
                    <div v-if="memberInfo.introduce !== undefined" v-html="replaceNewLine(memberInfo.introduce)"></div>
                    <div v-else>(정보없음)</div>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-th variant="secondary">서비스 이용동의</b-th>
                  <b-td>
                    <b-row>
                      <b-col>서비스 이용동의</b-col>
                      <b-col>{{ memberInfo.acceptTermsService === true ? "Y" : "N" }}</b-col>
                    </b-row>
                    <b-row>
                      <b-col>개인정보 수집 이용동의</b-col>
                      <b-col>{{ memberInfo.acceptPrivacyPolicy === true ? "Y" : "N" }}</b-col>
                    </b-row>
                    <b-row>
                      <b-col>프로모션 수신동의</b-col>
                      <b-col>{{ memberInfo.acceptPromotion === true ? "Y" : "N" }}</b-col>
                    </b-row>
                    <!--
                    서비스 이용동의: {{ memberInfo.acceptTermsService === true ? "Y" : "N" }}<br/>
                    개인정보 수집 이용동의: {{ memberInfo.acceptPrivacyPolicy === true ? "Y" : "N" }}<br/>
                    프로모션 수신동의: {{ memberInfo.acceptPromotion === true ? "Y" : "N" }}-->
                  </b-td>
                  <b-th variant="secondary">이메일 알림</b-th>
                  <b-td>
                    <b-row>
                      <b-col>새 작품 알림</b-col>
                      <b-col>{{ memberInfo.noticeArtwork === true ? "Y" : "N" }}</b-col>
                    </b-row>
                    <b-row>
                      <b-col>오프라인 전시</b-col>
                      <b-col>{{ memberInfo.noticeExhibit === true ? "Y" : "N" }}</b-col>
                    </b-row>
                    <!--
                    새 작품 알림: {{ memberInfo.noticeArtwork === true ? "Y" : "N" }}<br/>
                    오프라인 전시: {{ memberInfo.noticeExhibit === true ? "Y" : "N" }}-->
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-th variant="secondary">가입채널</b-th>
                  <b-td>{{ memberInfo.snsType }} {{ memberInfo.snsId !== undefined && memberInfo.snsId !== '' ? ' / ' + memberInfo.snsId : '' }}</b-td>
                  <b-th variant="secondary">가입일</b-th>
                  <b-td>{{ memberInfo.createdAt }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th variant="secondary">활성여부</b-th>
                  <b-td>{{ memberInfo.enabled === true ? "Y" : "N" }}</b-td>
                  <b-th variant="secondary">레퍼럴</b-th>
                  <b-td>{{ memberInfo.referral }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th variant="secondary" class="border-bottom">사용자 역할 <span class="text-danger">*</span></b-th>
                  <b-td class="border-bottom">
                    <v-select
                      multiple
                      :options="roleOptions"
                      v-model="selectedRole"
                      placeholder="사용자 역할을 선택하세요."
                    />
                    <span class="text-info">
                      법인관리자는 "법인 관리자", "아티스트" 모두 설정해주세요.
                    </span>
                  </b-td>
                  <b-th variant="secondary" class="border-bottom">최근 수정일</b-th>
                  <b-td class="border-bottom">{{ memberInfo.updatedAt }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </CCol>
        </CRow>
        <CRow>
          <CCol col="12" class="text-center my-4">
            <CButton color="light" @click="onClickCancel()" class="mr-2">
              <CIcon name="cil-x-circle" class="mr-2" />취소
            </CButton>
            <CButton color="secondary" @click="onClickDelete()" class="mr-2">
              <CIcon name="cil-trash" class="mr-2" />삭제
            </CButton>
            <CButton color="dark" @click="onClickSave()" class="text-white">
              <CIcon name="cil-save" class="mr-2" />저장
            </CButton>
          </CCol>
        </CRow>
      </CForm>
    </CCardBody>
  </CCard>
</template>

<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { createNamespacedHelpers } from "vuex";
const memberHelper = createNamespacedHelpers("member");

export default {
  name: "MemberSave",
  components: {
    vSelect
  },
  props: {
    idxMember: {
      type: String,
      default: "0"
    }
  },
  data: function() {
    return {
      selectedRole: null,
      roleOptions: [
        { value: "ROLE_SUPERVISOR", label: "시스템 관리자" },
        { value: "ROLE_OPERATOR", label: "시스템 운영자" },
        { value: "ROLE_CORP_ADMIN", label: "법인 관리자" },
        { value: "ROLE_ARTIST", label: "아티스트" },
        { value: "ROLE_USER", label: "일반 사용자" }
      ]
    };
  },
  computed: {
    ...memberHelper.mapState(["memberInfo"])
  },
  mounted() {
    this.requestMemberItem();
  },
  methods: {
    ...memberHelper.mapActions(["updateMemberRole", "getMember", "deleteMember"]),
    requestMemberItem() {
      if (this.idxMember && this.idxMember !== "0") {
        this.getMember(this.idxMember).then(data => {
          //this.$log.log(data);
          if (Object.keys(data).length !== 0 && JSON.stringify(data) !== JSON.stringify({})) {
            // 사용자 역할
            let roles = new Array();
            if (data.roles !== null && data.roles.length > 0) {
              data.roles.forEach(r => {
                roles.push(JSON.parse('{"value": "' + r.code + '", "label": "' + r.name + '"}'));
              });
              this.selectedRole = roles;
            }
          }
        });
      }
    },
    onClickCancel() {
      this.$router.push("/management/member-list");
    },
    onClickDelete() {
      if (confirm("정말 삭제하시겠습니까?")) {
        this.deleteMember({ idxMember: this.idxMember })
          .then(() => {
            alert("삭제 되었습니다.");
            this.$router.push("/management/member-list");
          })
          .catch(error => {
            alert("사용자 삭제 오류가 발생하였습니다. 관리자에게 문의하세요.");
            this.$log.error(error);
          });
      }
    },
    onClickSave() {
      this.$log.info("click onClickSave() this.memberInfo=", this.memberInfo);

      // 사용자 정보 저장
      if (this.idxMember && this.idxMember !== "0") {
        if (Object.keys(this.selectedRole).length !== 0) {
          this.memberInfo.roles = this.selectedRole.map(o => o.value);
        } else {
          this.memberInfo.roles = new Array();
        }
        // 사용자 수정
        this.updateMemberRole(this.memberInfo)
          .then(() => {
            alert("저장되었습니다.");
            this.$router.push("/management/member-list");
          })
          .catch(error => {
            alert("사용자 수정 오류가 발생하였습니다. 관리자에게 문의하세요.");
            this.$log.error(error);
          });
      }
    },
    replaceNewLine(description) {
      if (
        description !== undefined &&
        description !== null &&
        description !== ""
      ) {
        return description.replace(/(?:\r\n|\r|\n)/g, "<br />");
      } else {
        return "";
      }
    }
  }
};
</script>
