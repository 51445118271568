<template>
  <div :class="{ 'c-dark-theme': $store.state.darkMode }" class="c-app">
    <CContainer class="d-flex align-items-center min-vh-100">
      <CRow class="w-100 justify-content-center">
        <CCol md="6">
          <div class="clearfix">
            <h1 class="float-left display-3 mr-4">500</h1>
            <h4 class="pt-3">
              요청 처리 과정에서 예외가 발생했습니다. <br />관리자에게
              문의하세요.
            </h4>
            <p class="text-muted">
              The page you are looking for is temporarily unavailable.
            </p>
          </div>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
export default {
  name: "Page500"
};
</script>
