<template>
  <CCard>
    <CCardBody>
      <CRow>
        <CCol class="mb-3"><h5>공통코드 등록/수정</h5></CCol>
      </CRow>
      <table class="table">
        <tbody>
          <tr>
            <th class="col-md-2">상위 코드번호</th>
            <td class="col-md-4">
              <CInput class="mb-0" v-model="codeInfo.idxParent" />
            </td>
            <th class="col-md-2">
              코드&nbsp;<span class="text-danger">*</span>
            </th>
            <td class="col-md-4">
              <CInput
                class="mb-0"
                placeholder="코드를 입력해주세요."
                :lazy="false"
                :value.sync="$v.codeInfo.code.$model"
                :isValid="checkIfValid('code')"
                v-model="codeInfo.code"
                invalidFeedback="필수 정보입니다."
              />
            </td>
          </tr>
          <tr>
            <th>코드명&nbsp;<span class="text-danger">*</span></th>
            <td>
              <CInput
                class="mb-0"
                placeholder="코드명을 입력해주세요."
                :lazy="false"
                :value.sync="$v.codeInfo.codeName.$model"
                :isValid="checkIfValid('codeName')"
                v-model="codeInfo.codeName"
                invalidFeedback="필수 정보입니다."
              />
            </td>
            <th>코드값</th>
            <td>
              <CInput
                class="mb-0"
                placeholder="코드값을 입력해주세요."
                v-model="codeInfo.codeValue"
                invalidFeedback="필수 정보입니다."
              />
            </td>
          </tr>
          <tr>
            <th>코드명(영문)</th>
            <td>
              <CInput
                class="mb-0"
                placeholder="코드명(영문)을 입력해주세요."
                v-model="codeInfo.codeNameEn"
              />
            </td>
            <th>
              정렬순서&nbsp;<span class="text-danger">*</span>
            </th>
            <td>
              <CInput
                class="mb-0"
                placeholder="정렬순서를 입력해주세요."
                :lazy="false"
                :value.sync="$v.codeInfo.orderNum.$model"
                :isValid="checkIfValid('orderNum')"
                v-model="codeInfo.orderNum"
                invalidFeedback="필수 정보입니다."
              />
            </td>
          </tr>
          <tr>
            <th>트리레벨</th>
            <td class="border-bottom">
              <CInput class="mb-0" v-model="codeInfo.level" disabled />
            </td>
            <th class="border-bottom">
              사용여부&nbsp;<span class="text-danger">*</span>
            </th>
            <td class="border-bottom">
              <b-form-radio-group
                id="display"
                name="display"
                :options="yesOrNoOptions"
                v-model="codeInfo.display"
                class="mt-2"
              />
            </td>
          </tr>
          <tr>
            <th>코드 설명</th>
            <td colspan="3">
              <b-form-radio-group
                id="codeDesc"
                name="codeDesc"
                v-model="selected"
                :options="options"
              ></b-form-radio-group>
              <div class="mt-1">
                <CIcon name="cil-info" class="text-info mr-2" />
                <span class="text-info">"Editor"를 선택한 경우, HTML 태그가 포함되어 저장됩니다.</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <template v-if="selected === 'TEXT_AREA'">
        <CTextarea
          class="mb-0"
          placeholder="코드 설명을 입력하세요."
          rows="10"
          v-model="codeInfo.codeDesc"
        />
      </template>
      <template v-else>
        <quill-editor :content="codeInfo.codeDesc" v-model="codeInfo.codeDesc" />
      </template>

      <CRow>
        <CCol col="12" class="text-center my-4">
          <CButton color="light" @click="onClickCancel()" class="mr-2">
            <CIcon name="cil-x-circle" class="mr-2" />취소
          </CButton>
          <CButton color="secondary" @click="onClickDelete()" class="mr-2">
            <CIcon name="cil-trash" class="mr-2" />삭제
          </CButton>
          <CButton color="dark" @click="onClickSave()" class="text-white">
            <CIcon name="cil-save" class="mr-2" />저장
          </CButton>
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
import Vue from "vue";
import VueQuillEditor from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { createNamespacedHelpers } from "vuex";
const codeHelper = createNamespacedHelpers("code");

Vue.use(VueQuillEditor);

export default {
  name: "CodeSave",
  props: {
    idxCode: {
      type: String,
      default: "0"
    }
  },
  data() {
    return {
      yesOrNoOptions: [
        { value: "true", text: "예" },
        { value: "false", text: "아니오" }
      ],
      selected: "TEXT_AREA",
      options: [
        { text: "Text Area", value: "TEXT_AREA" },
        { text: "Editor", value: "TEXT_EDITOR" },
      ]
    };
  },
  computed: {
    ...codeHelper.mapState(["codeInfo"]),
    isValid() {
      return !this.$v.codeInfo.$invalid;
    },
    isDirty() {
      return this.$v.codeInfo.$anyDirty;
    }
  },
  mixins: [validationMixin],
  validations: {
    codeInfo: {
      code: { required },
      codeName: { required },
      orderNum: { required }
    }
  },
  mounted() {
    this.requestCodeItem();
  },
  methods: {
    ...codeHelper.mapActions(["resetCommonCode", "getCommonCode", "addCommonCode", "updateCommonCode"]),
    requestCodeItem() {
      this.resetCommonCode();
      if (this.idxCode && this.idxCode !== "0") {
        this.getCommonCode(this.idxCode).then(data => {
          if (
            Object.keys(data).length !== 0 &&
            JSON.stringify(data) !== JSON.stringify({})
          ) {
            if (data.display === undefined || data.display ===  null || data.display === "") {
              this.codeInfo.display = "true";
            } else {
              this.codeInfo.display = data.display === false ? "false" : "true";
            }
          }
        });
      } else {
        this.codeInfo.display = "true";
      }
    },
    onClickCancel() {
      this.$router.push("/management/code-list");
    },
    onClickDelete() {
      alert("개발중");
    },
    onClickSave() {
      this.$v.$touch();
      if (this.isValid) {

        // 공통코드 정보 저장
        if (this.idxCode && this.idxCode !== "0") {
          // 공통코드 수정
          this.updateCommonCode(this.codeInfo)
            .then(() => {
              alert("저장되었습니다.");
              this.$router.push("/management/code-list");
            })
            .catch(error => {
              alert(
                "공통코드 저장 오류가 발생하였습니다. 관리자에게 문의하세요."
              );
              this.$log.error(error);
            });
        } else {
          // 공통코드 등록
          this.addCommonCode(this.codeInfo)
            .then(() => {
              alert("저장되었습니다.");
              this.$router.push("/management/code-list");
            })
            .catch(error => {
              alert(
                "공통코드 저장 오류가 발생하였습니다. 관리자에게 문의하세요."
              );
              this.$log.error(error);
            });
        }
      }
    },
    checkIfValid(fieldName) {
      const field = this.$v.codeInfo[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    }
  }
};
</script>
